














import Vue from "vue"

export default Vue.extend({
    props: {
        staysLoading: {
            required: true,
            type: Boolean
        },
        stays: {
            required: true
        },
    },
})

import { concat, find, forEach, map } from 'lodash-es'
import * as moment from 'moment'
import utils from 'utils'
import { freeTextSubHtmlBreaks } from './helpers'
import { genericQuestionsText, formatQuestionAnswerAndNotes } from './question'
import { TextDetails } from './interfaces'
import { Question, QuestionCategory } from "models/med_templates/question"
import stays from '@store/stays'
import store from 'store'


export function presentingComplaint({ stay, title, hideTitle }: TextDetails): string {
    if (!stay) return ""

    let lines: string[] = []

    if (stay.presentation && stay.presentation.last_seen_well) {
        const lsw = stay.presentation.last_seen_well
        let lswText = null

        let lswTime = ""
        if (stay.presentation.lsw_time) {
            let dateStr = stay.presentation.lsw_time
            const momentised = moment(dateStr)
            if (momentised.isValid())
                dateStr = momentised.format(" [at] h:mma [on] Do MMM YYYY")
            else
                dateStr = ` at ${dateStr}`
            lswTime = dateStr
        }

        if (lsw === "LT_24_HOURS") {
            lswText = `Last seen well${lswTime ? lswTime : " < 24 hours ago"}.`
        } else if (lsw === "WAKE_UP") {
            lswText = `Woke up with symptoms. Last seen well ${lswTime}.`
        } else if (lsw === "GT_24_HOURS") {
            lswText = `Greater than 24 hours since last seen well${lswTime}.`
        }

        if (lswText) lines.push(lswText)
    }

    // Add Presenting Complaint free text summary
    if (stay.presentation && stay.presentation.summary) {
        lines.push(freeTextSubHtmlBreaks(stay.presentation.summary))
    }

    if (!hideTitle && lines.length) {
        title = title || "Presenting complaint"
        lines.unshift(`**${title}:**`)
    }

    return lines.join("<br/>") + "\n\n"
}


export function background(
    { stay }: TextDetails,
    { updatedAfter = '' }: { updatedAfter?: string } = {}
): string {
    if (!stay) return ''

    const syndrome = find(store.direct.getters.templates.allSyndromes, ['id', stay.syndrome])

    let questions: Question[] = []

    if (syndrome)
        forEach(syndrome.question_groups, group => {
            if (group.category === QuestionCategory.BACKGROUND)
                questions = concat(questions, map(group.questions, 'question'))
        })

    const updatedAfter_m = moment(updatedAfter)
    const checkDate = updatedAfter_m.isValid()

    let recent = false
    let negatives: string[] = []
    let negativesWithNotes: string[] = []
    let positives: string[] = []

    forEach(questions, question => {
        const res = formatQuestionAnswerAndNotes(
            stay.id, question,
            undefined, undefined,
            true
        )

        if (!res)
            return

        if (checkDate) {
            const qUpdated_m = moment(res.updated_at)
            if (qUpdated_m.isValid()) {
                if (qUpdated_m.isSameOrAfter(updatedAfter_m))
                    recent = true
            }
            else {
                utils.messageSentry(`Stay ${stay.id} bg question.updated_at is invalid. (q_id: ${question.id}, value: ${qUpdated_m.creationData().input})`)
            }
        }

        if (res.booleanLike && !res.positive) {
            if (res.hasNotes)
                negativesWithNotes.push(res.text)
            else
                negatives.push(res.text)
        }
        else
            positives.push(res.text)
    })

    if (checkDate) {
        const bgUpdated_m = moment(stay.background?.updated_at)
        if (bgUpdated_m.isValid()) {
            if (bgUpdated_m.isSameOrAfter(updatedAfter_m))
                recent = true
        }
        else {
            utils.messageSentry(`Stay ${stay.id} background.updated_at is invalid. (value: ${bgUpdated_m.creationData().input})`)
        }
    }

    if (checkDate && !recent)
        return ''

    // compile lines
    let lines: string[] = []

    if (positives.length) {
        const positiveText = positives.join("  \n")
        lines.push(positiveText)
    }

    if (stay.background && stay.background.notes) {
        lines.push(freeTextSubHtmlBreaks(stay.background.notes))
    }

    if (lines.length && (negativesWithNotes.length || negatives.length)) {
        // Push empty string to add a line break before negatives
        lines.push("")
    }

    if (negativesWithNotes.length) {
        const noHistoryText = map(negativesWithNotes, line => `No history of ${line}`)
        lines.push(noHistoryText.join("  \n"))
    }

    if (negatives.length) {
        const negativesText = `No history of:  \n${negatives.join(" / ")}`
        lines.push(negativesText)
    }

    if (!lines.length)
        return ''

    const text = lines.join("  \n")
    const sectionTitle = "Past Medical/Surgical History"
    return `**${sectionTitle}:**  \n${text}\n\n`
}


export function updatedBackground({ stay }: TextDetails): string {
    if (!(stay && stay.ward_round)) return ""
    const wardRoundCreated = stay.ward_round.created_at
    return background({ stay }, { updatedAfter: wardRoundCreated })
}

export function socialHistory(
    { stay }: TextDetails,
    { topLineOnly = false, updatedAfter = '' }: { topLineOnly?: boolean, updatedAfter?: string } = {}
): string {
    if (!stay || !stay.social_history) return ""
    const socialHistory = stay.social_history

    const updatedAfter_m = moment(updatedAfter)
    const checkDate = updatedAfter_m.isValid()
    let recent = false

    if (checkDate) {
        const lastUpdated_m = moment(socialHistory.updated_at)
        if (lastUpdated_m.isValid()) {
            if (lastUpdated_m.isSameOrAfter(updatedAfter_m))
                recent = true
        }
        else {
            utils.messageSentry(`Stay ${stay.id} social_history.updated_at is invalid. (value: ${lastUpdated_m.creationData().input})`)
        }
    }

    let lines = []

    // Living situation
    switch (socialHistory.living_situation) {
        case "NURSING_HOME":
            lines.push("Nursing home resident")
            break
        case "HOME":
            let homeText = "Lives at home"
            if (socialHistory.lives_with) {
                homeText += ` ${socialHistory.lives_with.toLowerCase().replace(/_/g, " ")}`
            }
            lines.push(homeText)
            break
        case "HOSTEL":
            lines.push("Lives in a hostel")
            break
    }

    // Mobility
    const mobilityStatus = socialHistory.mobility_status
    const needsAssistance = socialHistory.mobility_requires_assistance
    let mobilityText = ""

    if (needsAssistance) {
        mobilityText += "Needs assistance from others to mobilise"
    } else if (needsAssistance === false) {
        mobilityText += "Independently mobile"
    }

    if (mobilityStatus) {
        const statusText = mobilityStatus.toLowerCase().replace(/_/g, " ")
        mobilityText += " " + statusText
    }

    if (mobilityText) {
        lines.push(mobilityText)
    }

    // Cooking, finance, shopping
    const cfs = socialHistory.cook_finance_shop
    if (cfs && cfs !== null) {
        // Convert label to title case
        const label = cfs[0] + cfs.slice(1).toLowerCase()
        lines.push(`${label} with cooking, finance, shopping`)
    }

    // Washing, dressing, toileting
    const wdt = socialHistory.wash_dress_toilet
    if (wdt && wdt !== null) {
        // Convert label to title case
        const label = wdt[0] + wdt.slice(1).toLowerCase()
        lines.push(`${label} with washing, dressing, toileting`)
    }

    // Driving
    const drives = socialHistory.driving
    if (drives) {
        lines.push("Drives")
    } else if (drives === false) {
        lines.push("Doesn't drive")
    }

    // Employment
    const employment = socialHistory.employment
    if (employment) {
        const label = employment[0] + employment.slice(1).toLowerCase().replace(/_/g, " ")
        lines.push(label)
    }

    const syndrome = find(store.direct.getters.templates.allSyndromes, ['id', stay.syndrome])

    let questions: Question[] = []

    if (syndrome)
        forEach(syndrome.question_groups, group => {
            if (group.category === QuestionCategory.SOCIAL_HISTORY)
                questions = concat(questions, map(group.questions, 'question'))
        })

    forEach(questions, question => {
        const res = formatQuestionAnswerAndNotes(
            stay.id, question,
            undefined, undefined,
            true
        )

        if (!res)
            return

        if (checkDate) {
            const qUpdated_m = moment(res.updated_at)
            if (qUpdated_m.isValid()) {
                if (qUpdated_m.isSameOrAfter(updatedAfter_m))
                    recent = true
            }
            else {
                utils.messageSentry(`Stay ${stay.id} social history question.updated_at is invalid. (q_id: ${question.id}, value: ${qUpdated_m.creationData().input})`)
            }
        }

        lines.push(res.text)
    })

    if (checkDate && !recent)
        return ''

    // Notes
    if (socialHistory.notes) {
        lines.push(freeTextSubHtmlBreaks(socialHistory.notes))
    }

    if (!lines.length)
        return ''

    if (topLineOnly) {
        let topLine = lines[0]
        const res = topLine.match(/^(?:(?!<br>).)*/)
        if (res && res.length > 0)
            return res[0]
        return topLine
    }

    const text = lines.join("  \n")
    const sectionTitle = "Social history"
    return `**${sectionTitle}:**  \n${text}\n\n`
}

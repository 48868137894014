

















import Vue from 'vue'
import utils from 'utils'
import DiagnosisHistory from './DiagnosisHistory.vue'

export default Vue.extend({
    components: {
        DiagnosisHistory,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        add_mb: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            uid: utils.getUID(),
            visible: false,
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
    }
})













import Vue from 'vue'
import { get } from 'lodash-es'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import TextArea from '@shared/inputs/TextArea.vue'


export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        isRapidAssessment: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TextArea,
    },
    computed: {
        fieldName(): keyof Stay {
            return this.isRapidAssessment ? 'rapid_assessment_diagnosis_summary' : 'diagnosis_summary'
        },
        diagnosisSummary: {
            get(): string {
                return (get(stays.state.stays, [this.stay_id, this.fieldName]) as string)
            },
            set(summary: string) {
                stays.extActSetPathValue(this.stay_id, this.fieldName, summary)
            }
        }
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    _vm._l(_vm.questionGroups, function(group) {
      return _c("checkbox", {
        key: group.id,
        staticClass: "d-block",
        attrs: { title: group.title, cb_value: group.id },
        model: {
          value: _vm.stayQuestionGroups,
          callback: function($$v) {
            _vm.stayQuestionGroups = $$v
          },
          expression: "stayQuestionGroups"
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
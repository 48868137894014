import { Stage, stageDef } from '../_enums'
import { Location, locationDef } from './location'
import { Patient, patientDef } from './patient'
import { array, boolean as dboolean, number as dnumber, nullable, exact, guard } from 'decoders'

export interface SidebarStay {
    id: number
    active: boolean
    location: Location | null
    patient: Patient
    rapid_assessment_syndrome: number | null
    speciality: number | null
    stage: Stage
}

const sidebarStayDef = exact({
    id: dnumber,
    active: dboolean,
    location: nullable(locationDef),
    patient: patientDef,
    rapid_assessment_syndrome: nullable(dnumber),
    speciality: nullable(dnumber),
    stage: stageDef,
})

const sidebarStaysDef = array(sidebarStayDef)

export const sidebarStayDecoder = guard(sidebarStayDef, { style: 'simple' })
export const sidebarStaysDecoder = guard(sidebarStaysDef, { style: 'simple' })

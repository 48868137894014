var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.reasons, function(reason) {
        return _c(
          "div",
          { key: reason, staticClass: "col-12 col-lg-6" },
          [
            _c("b-overlay", {
              attrs: { variant: "transparent", "no-wrap": "" }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "text-center mt-2 mb-0 chart-title" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.reasonLabel(reason)) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("canvas", {
              ref: reason,
              refInFor: true,
              staticClass: "mt-2 mb-4"
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
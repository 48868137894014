













































import Vue from 'vue'
import utils from 'utils'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { SummaryStay } from 'models/dataviz'
import { anonymisedPatient } from 'models/data/patient'
import CurrentMedications from '@ward/CurrentMedications.vue'
import StayTextSummary from '../StayTextSummary.vue'

export default Vue.extend({
    components: {
        CurrentMedications,
        StayTextSummary,
    },
    props: {
        stay: {
            type: Object as () => SummaryStay,
            required: true
        },
        field: {},
        textVersion: {
            type: String,
            required: false,
            default: 'discharge',
            validator: (value) => {
                return ['discharge', 'hyperacute', 'strokeUnitSummary'].indexOf(value) !== -1
            },
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            uid,
            collapseId: `${uid}___summary_${this.stay.id}`,
            editModalId: `viz-stay-edit-${this.stay.id}`,
        }
    },
    computed: {
        fullStay(): Stay | undefined {
            return stays.state.stays[this.stay.id]
        },
        anonymise(): boolean {
            return this.$store.direct.state.user.anonymise
        },
        patientSummary(): string {
            if (this.anonymise) {
                const p = anonymisedPatient(null)
                return `${p.first_name} ${p.last_name} (MRN: ${p.mrn})`
            }
            return this.stay.patient_summary
        },
    },
    methods: {
        loadOnShow() {
            if (!this.fullStay) {
                stays.actPullStayFromUrl({
                    url: '/viz/stay/',
                    stay_id: this.stay.id
                })
            }
        },
        editStay() {
            this.$store.direct.commit.session.setDataVizStayID(this.stay.id)
            this.$nextTick(() => {
                this.$root.$bvModal.show(`viz-stay-edit-modal`)
            })
        }
    }
})

import { InvestigationStatus } from 'models/med_templates/enums'
import { Investigation as InvSchema } from "models/med_templates/investigation"

/** NOTE We use an enum because we cannot check if an arbitrary
 * string is a part of a string literal at runtime
 */
export enum InvSection {
    ACTIVES = "investigations",
    BASELINE = "baseline_investigations"
}

export interface Investigation {
    id: number
    /** the base investigation id */
    investigation: number
    status: number
    status_label: InvestigationStatus
    title: string
    notes: string | null
    deleted?: boolean
    performed?: boolean | null
    results: number[]
    updated_at: string
    done: boolean
    other?: boolean
    created_at: string
    extra_data: {
        [key: string]: any
        core?: number | null
        pen?: number | null
    }
}

export interface BaseInvInfo {
    inv?: Investigation
    schema: InvSchema
}
export interface InvInfo extends BaseInvInfo {
    inv: Investigation
}






















import Vue from 'vue'
import draggable from 'vuedraggable'

import { filter, find, forEach } from 'lodash-es'

import utils from 'utils'
import stays from '@store/stays'
import { Issue, OtherIssue } from 'models/data/issue'
import { IssueStatus, IssueLabels } from 'models/med_templates/issue'

import IssueItem from './Issue.vue'

export default Vue.extend({
    components: {
        draggable,
        IssueItem
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        issueStatus: {
            type: String as () => IssueStatus,
            required: true
        },
        emptyText: {
            type: String,
            default: 'No issues.'
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            htmlID: `${uid}___issues_${this.issueStatus}`,
        }
    },
    computed: {
        titleText(): string {
            const labelInfo = find(IssueLabels, { id: this.issueStatus })
            return labelInfo ? `${labelInfo.title} Issues` : "Issues"
        },
        issues: {
            get(): (Issue | OtherIssue)[] {
                const allIssues = stays.getAllIssues(this.stay_id)
                return filter(allIssues, ['status', this.issueStatus])
            },
            set(issues: (Issue | OtherIssue)[]) {
                const stayID = this.stay_id
                forEach(issues, function(issue, index) {
                    // Set issue's `order` to the issue's index in the array
                    const data = { id: issue.id, other: issue.other, order: index }
                    stays.extActUpdateIssue(stayID, data)
                })
            },
        }
    },
    methods: {
        issueKey(issue: Issue) {
            if (issue.other) return `other__${issue.id}`
            return issue.id
        }
    }
})

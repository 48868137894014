import { exact, guard } from 'decoders'
import { ChoiceItem, choiceItems } from 'models/med_templates/choices'
import { Doctor } from 'models/data/doctor'

export interface AllChoices {
    care_classification: ChoiceItem[]
    nbm: ChoiceItem[]
    ward_round_exam: ChoiceItem[]
}

export const allChoicesDecoder = guard(exact({
    care_classification: choiceItems,
    nbm: choiceItems,
    ward_round_exam: choiceItems,
}), { style: 'simple' })


export interface DoctorExt extends Doctor {
    hoverText: string
}

export interface SpecialityDoctors {
    id: number
    title: string
    doctors: DoctorExt[]
}

// sanity checks that decoders/guards are not missing properties
try { const _allChoices: AllChoices = allChoicesDecoder({}) } catch(e) {}

import { genericQuestionsText } from './question'
import { TextDetails } from './interfaces'
import { QuestionCategory } from "models/med_templates/question"
import stays from '@store/stays'


export function positivesAndNegatives({ stay }: TextDetails): string {
    if (!stay) return ''
    const text = genericQuestionsText({
        stay,
        category: QuestionCategory.POSITIVES_NEGATIVES,
        getQuestionGroupsFrom: stays.getSyndrome(stay.id),
    })
    return text ? `${text}\n\n` : ''
}

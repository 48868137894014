import { Question, question } from 'models/uxadmin/question'
import { Stage, stageDef } from 'models/_enums'
import { string as dstring, number as dnumber, boolean as dboolean, nullable, array, exact, guard } from 'decoders'

export interface Speciality {
    id: number
    title: string
    code: string
    auto_gen: boolean
    admission_summary_placeholder: string | null
    stage: Stage
    questions: Question[]
}

const speciality = exact({
    id: dnumber,
    title: dstring,
    code: dstring,
    auto_gen: dboolean,
    admission_summary_placeholder: nullable(dstring),
    stage: stageDef,
    questions: array(question),
})

const simpleSpeciality = exact({
    id: dnumber,
    title: dstring,
    code: dstring,
    auto_gen: dboolean,
    admission_summary_placeholder: nullable(dstring),
})

export const specialityDecoder = guard(speciality, { style: 'simple' })
export const simpleSpecialityDecoder = guard(simpleSpeciality, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _speciality: Speciality = guard(speciality)({}) } catch(e) {}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("text-base", {
    attrs: {
      stay_id: _vm.stay_id,
      markup: _vm.markup,
      modalOkTitle: "Mark Admission Complete",
      tag: "adm",
      customMarkComplete: _vm.markAdmissionComplete,
      showExtras: _vm.showExtras
    },
    scopedSlots: _vm._u([
      {
        key: "modalContent",
        fn: function() {
          return [
            _c("p", [_vm._v("Do you want to mark the admission as complete?")])
          ]
        },
        proxy: true
      },
      {
        key: "extras",
        fn: function() {
          return [
            _c("attending", {
              attrs: { stay_id: _vm.stay_id, section: "adm_present" }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
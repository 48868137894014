

















import Vue from 'vue'
import utils from 'utils'
import ChangePassword from '@misc/AccountChangePassword.vue'

export default Vue.extend({
    components: {
        ChangePassword,
    },
    data() {
        return {
            htmlId: `${utils.getUID()}___change_pw`,
        }
    },
    computed: {
        passwordExpired(): boolean | null {
            return this.$store.direct.state.user.pwd_expired
        },
    },
    watch: {
        passwordExpired: {
            immediate: true,
            handler: function(val) {
                if (val === null) return
                if (val) {
                    if (this.$store.direct.state.user.is_temp)
                        this.logout()
                    else
                        this.$bvModal.show(this.htmlId)
                }
            }
        }
    },
    methods: {
        closeModal() {
            this.$root.$emit('bv::hide::modal', this.htmlId)
        },
        logout() {
            this.$store.direct.dispatch.session.logout()
        },
    }
})

















import Vue from 'vue'

import { diagnosisFeatures, diagnosisKeyPoints, diagnosisAddNotes } from 'utils/text/diagnosis'
import { Stay } from 'models/data/stay'

import EditDiagnosis from '@inputs/diagnosis/EditDiagnosis.vue'


export default Vue.extend({
    components: {
        EditDiagnosis,
    },
    props: {
        stay: {
            type: Object as () => Stay,
            required: true,
        }
    },
    computed: {
        diagnosis(): string {
            return this.stay?.diagnosis_title_ext || 'Nil'
        },
        keyPoints(): string {
            return diagnosisKeyPoints({ stay: this.stay })
        },
        features(): string {
            return diagnosisFeatures({ stay: this.stay })
        },
        addNotes(): string {
            return diagnosisAddNotes({ stay: this.stay })
        },
        extraHtml(): boolean {
            return !!this.keyPoints || !!this.features || !!this.addNotes
        },
    }
})














import Vue from 'vue'
import { find } from 'lodash-es'
import stays from '@store/stays'

import { QuestionCategory } from 'models/med_templates/question'
import { Speciality } from 'models/med_templates/speciality'

import Questions from '@stayinputs/Questions.vue'


export default Vue.extend({
    components: {
        Questions,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            QuestionCategory,
        }
    },
    computed: {
        speciality(): Speciality | undefined {
            return stays.getSpeciality(this.stay_id)
        },
        syndromeDischargePlanIDs(): number[] {
            const stay = stays.state.stays[this.stay_id]
            if (!stay) return []
            const syndrome = find(this.$store.direct.getters.templates.allSyndromes, ['id', stay.syndrome])
            if (!syndrome) return []
            return syndrome.discharge_plans
        },
    },
})











import Vue from 'vue'
import { Space } from 'models/med_templates/space'
import FilterBase from './FilterBase.vue'


export default Vue.extend({
    components: {
        FilterBase,
    },
    computed: {
        spaces(): Space[] {
            return this.$store.direct.state.templates.spaces
        },
        hasSpaces(): boolean {
            return this.spaces.length > 0
        },
        selectedSpaces: {
            get(): number[] {
                return this.$store.direct.state.user.filters.spaces
            },
            set(spaceIDs: number[]) {
                this.$store.direct.dispatch.user.updateFilters({'spaces': spaceIDs})
                this.$emit('update')
            }
        },
    },
})






















import Vue from 'vue'
import { map, pickBy } from 'lodash-es'
import { Site, Sites } from 'models/asap/meta'

export default Vue.extend({
    data() {
        return {
            loading: false,
        }
    },
    created() {
        this.loading = true
        this.$store.direct.dispatch.asap.pullASAPMeta()
        .then(res => {
            this.loading = false
        })
    },
    computed: {
        sites(): Sites {
            return this.$store.direct.state.asap.asapMeta.sites
        },
    },
    methods: {
        siteEnabled(site: Site): boolean {
            if (site.enabled === undefined) return true
            return site.enabled
        },
        generateParams(site: Site): string {
            const downloadSchema = this.downloadSchema(site)
            const params = {
                site_dl: downloadSchema === false ? downloadSchema : undefined,
            }
            const params_s = map(pickBy(params, v => v !== undefined), (v, k) => `${k}=${v}`).join('&')
            return params_s ? `?${params_s}` : ''
        },
        generateUrl(site: Site): string {
            return `standalone/app/asap-${site.id}${this.generateParams(site)}`
        },
        generateAltUrl(site: Site): string {
            return window.scrawl.baseURL + `/?next=standalone/app/asap-${site.id}${this.generateParams(site)}`
        },
        downloadSchema(site: Site): boolean {
            return site.schema_dl !== undefined ? site.schema_dl : true
        },
    }
})
















































import mixins from 'vue-typed-mixins'
import InputMixin from '@mixins/Input.vue'
import { each, clone, map } from 'lodash-es'
import utils from 'utils'
import { Stay } from 'models/data/stay'
import { MDTPerson } from 'models/med_templates/team'

export default mixins(InputMixin).extend({
    props: {
        stays: {
            type: Array as () => Stay[]
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            mdtHtmlId: `${uid}___mdt`,
            mdtOpened: false,
            mdtHelpHtmlId: `${uid}___mdt_help`,
        }
    },
    computed: {
        specialities(): number[] {
            return this.$store.direct.state.user.filters.specialities
        },
        canMDT(): boolean {
            return this.specialities.length === 1
        },
        mdtPeople(): MDTPerson[] {
            if (!this.canMDT)
                return []
            return this.$store.direct.getters.templates.mdtPeopleBySpeciality[this.specialities[0]] || []
        },
        mdtPresent: {
            get(): number[] {
                return this.$store.direct.state.session.mdtPresent
            },
            set(ids: number[]) {
                this.$store.direct.commit.session.setMDTPresent(ids)
            }
        },
        teamPresentDesc(): string {
            return map(this.mdtPresent || [], id => this.$store.direct.getters.templates.mdtPeopleDict[id].title).join(', ')
        },
        descOpen: {
            get(): boolean {
                return !this.mdtOpened && !!this.teamPresentDesc
            },
            set() {}
        },
    },
    watch: {
        mdtOpened: {
            handler: function(val: boolean) {
                this.$emit('visible', val)
            },
            immediate: true
        },
    },
    mounted() {
        this.$emit('section-id', this.mdtHtmlId)
    },
    methods: {
        setStaysMDTPresent(): void {
            each(this.stays, stay => {
                if (!utils.isEqual(stay.mdt.people_present, this.mdtPresent))
                    this.setFieldVal(stay.id, 'mdt.people_present', clone(this.mdtPresent))
            })

        }
    }
})

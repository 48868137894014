var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.diagnosisSummary
    ? _c("text-area", {
        attrs: {
          label: "Key Points",
          placeholder: "A one-line summary of the diagnosis",
          maxlength: "200",
          rows: "1"
        },
        model: {
          value: _vm.diagnosisSummary,
          callback: function($$v) {
            _vm.diagnosisSummary = $$v
          },
          expression: "diagnosisSummary"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row pb-5" },
    [
      _vm.betaEnabled
        ? _c(
            "b-btn",
            {
              staticClass: "position-absolute scrwl-sb-side-btn px-3",
              attrs: { size: "sm" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.toggleSidebar.apply(null, arguments)
                }
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.sidebarBtnText) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.betaEnabled && _vm.notesBtnVisible
        ? _c(
            "b-btn",
            {
              staticClass: "position-absolute nav-drop-r-btn px-3",
              attrs: { size: "sm" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.toggleNotesDocked.apply(null, arguments)
                }
              }
            },
            [_vm._v("\n        Notes\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.sidebarVisible,
              expression: "sidebarVisible"
            }
          ],
          staticClass:
            "col-md-3 vh100-nav overflow-auto border-right scrwl-sidebar"
        },
        [
          _c("sidebar", {
            staticClass: "pt-2",
            attrs: { viewMode: _vm.viewMode },
            on: { "sidebar-visible-toggle": _vm.toggleSidebar }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.ready
          ? _c(
              "div",
              {
                staticClass: "col pt-2 vh100-nav overflow-auto",
                attrs: { id: _vm.htmlID }
              },
              [
                _c("stay-summary", {
                  attrs: { stay_id: _vm.stay_id, viewMode: _vm.viewMode }
                }),
                _vm._v(" "),
                _c("router-view", {
                  staticClass: "mt-2",
                  attrs: {
                    stay_id: _vm.stay_id,
                    viewMode: _vm.viewMode,
                    notesDocked: _vm.notesDocked
                  },
                  on: {
                    "toggle-notes-docked": _vm.toggleNotesDocked,
                    "show-notes-btn": function($event) {
                      _vm.notesBtnVisible = true
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "b-alert",
                  {
                    staticClass:
                      "position-fixed fixed-bottom m-0 rounded-0 text-center",
                    staticStyle: { "z-index": "2000", opacity: "80%" },
                    attrs: { variant: "info", dismissible: "" },
                    model: {
                      value: _vm.canScrollDown,
                      callback: function($$v) {
                        _vm.canScrollDown = $$v
                      },
                      expression: "canScrollDown"
                    }
                  },
                  [
                    _vm._v("\n                More content "),
                    _c("fnt-a-icon", {
                      attrs: { icon: ["fal", "angle-double-down"] }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm.loaded
          ? _c("div", { staticClass: "col pt-2" }, [
              _c("div", { staticClass: "d-flex justify-content-center" }, [
                _c("h1", { staticClass: "mt-big" }, [_vm._v("No stay data.")])
              ])
            ])
          : _c("div", { staticClass: "col pt-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center"
                },
                [
                  _c("h1", { staticClass: "mt-big" }, [
                    _c("em", { staticClass: "loading-dots" }, [
                      _vm._v("Loading"),
                      _c("span", [_vm._v(".")]),
                      _c("span", [_vm._v(".")]),
                      _c("span", [_vm._v(".")])
                    ])
                  ])
                ]
              )
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-end" },
        [
          _c("doctors-filter", {
            model: {
              value: _vm.selectedDoctors,
              callback: function($$v) {
                _vm.selectedDoctors = $$v
              },
              expression: "selectedDoctors"
            }
          }),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: _vm.mdtHtmlId,
                  expression: "mdtHtmlId"
                }
              ],
              staticClass: "ml-2"
            },
            [
              _vm._v("\n            MDT "),
              _c("collapse-indicator", {
                staticClass: "ml-1",
                attrs: { opened: _vm.mdtOpened }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("mdt-present", {
        attrs: { stays: _vm.stays },
        on: {
          visible: function($event) {
            _vm.mdtOpened = $event
          },
          "section-id": function($event) {
            _vm.mdtHtmlId = $event
          }
        }
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "mt-2" }, [_vm._v("Stroke Unit")]),
      _vm._v(" "),
      _c("overview-table", {
        staticClass: "mt-2",
        attrs: {
          stays: _vm.strokeUnitStays,
          showSurgery: _vm.showSurgery,
          showDaysInSU: true,
          highlightLocation: ""
        }
      }),
      _vm._v(" "),
      _c("h3", { staticClass: "mt-5" }, [_vm._v("Other Stroke Patients")]),
      _vm._v(" "),
      _c("overview-table", {
        attrs: {
          stays: _vm.nonStrokeUnitStays,
          showDaysInSU: true,
          highlightLocation: "",
          sortBy: "length_of_stay"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mainNav
        ? _c(
            "b-nav-item",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.htmlId,
                  expression: "htmlId"
                }
              ]
            },
            [_vm._v("ver. " + _vm._s(_vm.version))]
          )
        : _c(
            "b-dd-item",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.htmlId,
                  expression: "htmlId"
                }
              ]
            },
            [_c("em", [_vm._v("ver. " + _vm._s(_vm.version))])]
          ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { size: "lg", scrollable: "", id: _vm.htmlId, "ok-only": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c("h5", { staticClass: "mb-0 mr-auto" }, [
                    _vm._v("Current version - "),
                    _c("em", [_vm._v(_vm._s(_vm.version))])
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.changelogHtml) } }),
          _vm._v(" "),
          _c("hr"),
          _vm._v("\n        Changelog:\n        "),
          _c(
            "b-btn",
            {
              on: {
                click: function($event) {
                  _vm.changelogVer = null
                }
              }
            },
            [_vm._v("Current")]
          ),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              on: {
                click: function($event) {
                  _vm.changelogVer = "2021"
                }
              }
            },
            [_vm._v("2021")]
          ),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              on: {
                click: function($event) {
                  _vm.changelogVer = "2020"
                }
              }
            },
            [_vm._v("2020")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
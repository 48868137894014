var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [_vm._v("Audit")]),
      _vm._v(" "),
      _c("h5", [_vm._v("Stay 999 - SMITH, John (MRN: 999123456)")]),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          bordered: "",
          striped: "",
          small: "",
          fields: _vm.fields,
          items: _vm.items
        },
        scopedSlots: _vm._u([
          {
            key: "cell(actions)",
            fn: function(data) {
              return [
                _vm._l(data.value, function(line, index) {
                  return [
                    _c("span", { key: index }, [_vm._v(_vm._s(line))]),
                    _c("br")
                  ]
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("follow-up", {
        attrs: { stay_id: _vm.stay_id, showInstructions: _vm.showInstructions }
      }),
      _vm._v(" "),
      _c("discharge-plans", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _c("discharge-notes", {
        staticClass: "mt-2",
        attrs: { stay_id: _vm.stay_id }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
















































import Vue from 'vue'
import { find } from 'lodash-es'
import utils from 'utils'
import { issueFullText } from 'utils/text/issue'

import stays from '@store/stays'
import { Issue as IssueModel } from 'models/data/issue'
import { Issue, IssueStatus } from 'models/med_templates/issue'

import IssueEditor from './IssueEditor.vue'

export default Vue.extend({
    components: {
        IssueEditor,
    },    
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        issue: {
            type: Object as () => IssueModel,
            required: true
        },
    },
    data() {
        const uid = utils.getUID()
        const otherS = this.issue.other ? '_other' : ''

        return {
            htmlID: `${uid}___issue${otherS}_${this.issue.id}`,
            opened: false,
        }
    },
    computed: {
        baseIssue(): Issue | undefined {
            return find(this.$store.direct.getters.templates.allIssues, ["id", this.issue.issue])
        },
        isActive(): boolean {
            return this.issue.status === IssueStatus.ACTIVE
        },
        fullText(): string {
            const stay = stays.state.stays[this.stay_id]
            if (!stay) return ''
            return this.issue ? issueFullText({ stay, issue: this.issue, ignoreStacked: true }) : ''
        }
    },
    methods: {
        setOpened() {
            if (window.scrawl.isIE11) {
                // this allows the loading icon to be shown since rendering is slow in IE11
                window.setTimeout(() => {
                    this.opened = true
                }, 300)
            }
            else
                this.opened = true
        }
    }
})

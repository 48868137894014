










































import Vue from "vue"
import { filter } from "lodash-es"

import stays from '@store/stays'
import { ASMT_NURSE_SYNDROME, ASMT_SYNDROME } from 'routers/constants'
import { Speciality } from "models/med_templates/speciality"
import { Syndrome } from "models/med_templates/syndrome"
import { Stay } from 'models/data/stay'
import { ViewMode } from 'models/meta'

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        viewMode: {
            required: false,
            default: ViewMode.normal,
        },
    },
    data() {
        return {
            hovering: -1,
            selected: -1,
        }
    },
    computed: {
        normalMode(): boolean {
            return this.viewMode === ViewMode.normal
        },
        specialities(): Speciality[] {
            return this.$store.direct.state.templates.specialities
        },
        syndromePage(): string {
            switch (this.viewMode) {
                case ViewMode.rapid_asmt:
                    return ASMT_NURSE_SYNDROME
                default:
                    return ASMT_SYNDROME
            }
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        staySpeciality(): number | null {
            return this.stay ? this.stay.speciality : null
        },
    },
    watch: {
        staySpeciality: {
            handler: function(val: number | null) {
                // auto-route to syndrome selection page if stay has speciality
                if (!!val)
                    this.goToSyndromePage(val)
            },
            immediate: true
        },
        selected(val: number) {
            this.goToSyndromePage(val)
        }
    },
    methods: {
        hovered(specialityID: number): boolean {
            return this.hovering === specialityID
        },
        specialitySyndromes(specialityID: number) {
            const templateGetters = this.$store.direct.getters.templates
            const syndromes: readonly Syndrome[] = this.normalMode ? templateGetters.allSyndromes : templateGetters.rapidAsmtSyndromes
            return filter(syndromes, { speciality: specialityID })
        },
        goToSyndromePage(id: number) {
            if (id > 0)
                this.$router.push({
                    name: this.syndromePage,
                    params: { stay_id: `${this.stay_id}`, speciality_id: `${id}` }
                })
        },
    },
})

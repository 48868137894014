













import Vue from "vue"
import { get } from 'lodash-es'

import stays from "@store/stays"
import { Stay } from 'models/data/stay'

import AdmissionExam from "../sections/AdmissionExam.vue"
import Nihss from '../sections/nihss/v2/NIHSS.vue'
import NihssSelect from '@sections/nihss/v2/NIHSSSelect.vue'

export default Vue.extend({
    components: {
        AdmissionExam,
        Nihss,
        NihssSelect,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        nihssScore(): string | number {
            return get(stays.state.stays[this.stay_id] || {}, 'admission_nihss.score', '?')
        },
        nihssTitle(): string {
            return `Neuro (${this.nihssScore})`
        },
        isNeurologyStay(): boolean {
            if (!this.stay) return false
            const speciality = get(this.stay, 'speciality_title', '')
            return speciality.toLowerCase().trim() === 'neurology'
        },
    },
})

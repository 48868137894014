var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "fieldset",
        [
          _c("h4", [_vm._v("Strength")]),
          _vm._v(" "),
          _c("generic", {
            attrs: {
              stay_id: _vm.stay_id,
              groups: _vm.strengthGroups,
              "override-msg": _vm.statusMsg,
              labelClass: "h6"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12" },
      [_c("mrc", { staticClass: "mb-2" })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 mt-4" }, [
      _c(
        "fieldset",
        [
          _c("h4", [_vm._v("Ataxia")]),
          _vm._v(" "),
          _c("generic", {
            attrs: {
              stay_id: _vm.stay_id,
              groups: _vm.ataxiaGroups,
              "override-msg": _vm.statusMsg,
              labelClass: "h6"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row my-3" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c(
          "b-tabs",
          { attrs: { "nav-class": "mb-2", pills: "", lazy: "" } },
          [
            _c(
              "b-tab",
              { attrs: { active: _vm.tab === "round", title: "Ward Round" } },
              [_c("ward-round", { attrs: { stay_id: _vm.stay_id } })],
              1
            ),
            _vm._v(" "),
            _c(
              "b-tab",
              {
                attrs: {
                  active: _vm.tab === "investigations",
                  title: "Investigations/Tasks"
                }
              },
              [_c("all-investigations", { attrs: { stay_id: _vm.stay_id } })],
              1
            ),
            _vm._v(" "),
            !_vm.telestrokeMode
              ? [
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        active: _vm.tab === "discharge",
                        title: "Discharge Plan"
                      }
                    },
                    [_c("discharge", { attrs: { stay_id: _vm.stay_id } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        active: _vm.tab === "summary",
                        title: "Interim Summary"
                      }
                    },
                    [_c("ward-summary", { attrs: { stay_id: _vm.stay_id } })],
                    1
                  )
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("data-capture-tab", {
              attrs: { stay_id: _vm.stay_id, tab: _vm.tab }
            }),
            _vm._v(" "),
            !_vm.notesDocked
              ? _c(
                  "b-tab",
                  {
                    attrs: { active: _vm.tab === "notes", title: "EMR Notes" }
                  },
                  [_c("ward-text", { attrs: { stay_id: _vm.stay_id } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.telestrokeMode
              ? _c(
                  "b-tab",
                  { attrs: { title: "Add. Print Notes" } },
                  [
                    _c("text-area-enhanced", {
                      attrs: {
                        stay_id: _vm.stay_id,
                        path: "print_text",
                        label: "Additional Notes for Print List"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm.notesDocked
      ? _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("ward-text", {
              attrs: { stay_id: _vm.stay_id, showExtras: false }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    { attrs: { title: "Support Details", id: _vm.htmlId, "ok-only": "" } },
    [
      _c("h5", [_vm._v("Contact options")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n        Email: "),
        _c("a", { attrs: { href: _vm.emailLink } }, [
          _vm._v("support@scrawlmedical.com")
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("p", [
        _c("em", [_vm._v("Server:")]),
        _vm._v(" " + _vm._s(_vm.documentTitle)),
        _c("br"),
        _vm._v(" "),
        _c("em", [_vm._v("Version:")]),
        _vm._v(" " + _vm._s(_vm.version) + "\n    ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body" }, [
    _c("fieldset", [
      _c("legend", { staticClass: "col-form-label pb-1" }, [
        _vm._v("\n            Patient location\n        ")
      ]),
      _vm._v(" "),
      _c(
        "fieldset",
        _vm._l(_vm.hospitals, function(hospital) {
          return _c(
            "div",
            {
              key: hospital.id,
              staticClass: "pretty p-svg p-plain p-bigger my-2"
            },
            [
              _c("radio", {
                attrs: { "target-val": hospital.id },
                model: {
                  value: _vm.stayHospital,
                  callback: function($$v) {
                    _vm.stayHospital = $$v
                  },
                  expression: "stayHospital"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "state p-info-o" },
                [
                  _c("fnt-a-icon", {
                    staticClass: "svg",
                    attrs: { icon: ["fal", "check"] }
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v(_vm._s(hospital.title))])
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import Vue from 'vue'
import { each, get, includes, keyBy, set } from 'lodash-es'
import utils from 'utils'
import stays from '@store/stays'
import { BaseInvInfo, InvSection } from "models/data/investigation"
import { InvestigationResult } from 'models/med_templates/investigation'

export default Vue.extend({
    props: {
        accordionID: String,
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String as () => InvSection,
            required: true
        },
        invInfo: {
            type: Object as () => BaseInvInfo,
            required: true
        }
    },
    data() {
        const fieldIds: { [k: string]: string[] } = {}
        const selection: object = {}
        return {
            fieldIds,
            selection,
        }
    },
    computed: {
        fieldSchemaLookup(): { [k: string]: InvestigationResult } {
            return keyBy(this.invInfo.schema.results, 'field_id')
        },
        /** a dictionary mapping field labels to InvestigationResult ids */
        fieldToIdMap(): { [k: string]: number } {
            const dict: { [k: string]: number } = {}
            each(this.fieldSchemaLookup, (schema, field) => {
                dict[field] = schema.id
            })
            return dict
        },
        fieldToTitleMap(): { [k: string]: string } {
            const dict: { [k: string]: string } = {}
            each(this.fieldSchemaLookup, (schema, field) => {
                dict[field] = schema.title
            })
            return dict
        },
        hasInv(): boolean {
            return this.invInfo.inv !== undefined
        },
        performed(): boolean | null {
            return this.invInfo.inv && this.invInfo.inv.performed !== undefined ? this.invInfo.inv.performed : null
        },
        localResults(): number[] {
            return []
        },
        results: {
            get(): number[] {
                return this.hasInv ? this.invInfo.inv!.results : []
            },
            set(val: number[]) {
                if (this.hasInv)
                    stays.extActUpdateInv(this.stay_id, this.invInfo.inv!, this.section, { results: val })
            }
        },
        resultsSynced(): boolean {
            return utils.isEqual(this.localResults, this.results)
        },
        notes: {
            get(): string {
                return this.hasInv ? (this.invInfo.inv!.notes || '') : ''
            },
            set(val: string) {
                if (this.hasInv)
                    stays.extActUpdateInv(this.stay_id, this.invInfo.inv!, this.section, { notes: val })
            }
        },
    },
    watch: {
        localResults(val: number[]) {
            if (this.resultsSynced) return
            this.results = val
        },
        results: {
            immediate: true,
            handler: function(results: number[]) {
                if (this.resultsSynced) return

                each(this.fieldIds, (sectionFields, section) => {
                    const selected: number[] = []
                    const sectionIsArray = Array.isArray(get(this.selection, section))

                    each(sectionFields, field => {
                        const resultId = this.fieldToIdMap[field]
                        if (includes(results, resultId)) {
                            if (sectionIsArray)
                                selected.push(resultId)
                            else
                                set(this.selection, section, resultId)
                        }
                    })

                    if (sectionIsArray)
                        set(this.selection, section, selected)
                })
            }
        },
    },
    methods: {
        clearNotes(): void {
            this.notes = ''
        }
    }
})

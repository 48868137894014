




























import mixins from 'vue-typed-mixins'
import { get } from 'lodash-es'

import stays from '@store/stays'
import { Item } from 'models/base'

import InputMixin from '@mixins/Input.vue'
import Dropdown from '@shared/inputs/Dropdown.vue'


export default mixins(InputMixin).extend({
    components: {
        Dropdown
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            OTHER_AIMING_FOR_VALUE: -9
        }
    },
    computed: {
        aimingFor: {
            get(): number {
                const val = this.getFieldVal(this.stay_id, 'discharge.aiming_for')
                if (val === null && this.hasOtherText) return this.OTHER_AIMING_FOR_VALUE
                return val
            },
            set(val: number) {
                if (val && val === this.OTHER_AIMING_FOR_VALUE) {
                    this.setFieldVal(this.stay_id, 'discharge.aiming_for', null)
                    this.otherAimingFor = this.otherAimingFor || ''
                } else {
                    this.setFieldVal(this.stay_id, 'discharge.aiming_for', val)
                    this.setFieldVal(this.stay_id, 'discharge.other_aiming_for', '')
                }
            }
        },
        aimingForChoices(): Item[] {
            const speciality = stays.getSpeciality(this.stay_id)
            return get(speciality, 'aiming_for_choices') || []
        },
        otherAimingFor: {
            get(): string {
                return this.getFieldVal(this.stay_id, 'discharge.other_aiming_for')
            },
            set(otherText: string) {
                this.setFieldVal(this.stay_id, 'discharge.other_aiming_for', otherText)
            }
        },
        hasOtherText(): boolean {
            return this.otherAimingFor !== null
        },
    },
})























import Vue from 'vue'
import { get } from 'lodash-es'
import * as commonmark from 'commonmark'
import * as moment from 'moment'

import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { dischargeMarkup }  from 'utils/text/discharge'
import NeuroAcuteCareSummary from 'components/data-entry/views/NeuroAcuteCareSummary.vue'


const reader = new commonmark.Parser()
const writer = new commonmark.HtmlRenderer()

function formatDate(dateString: Date | string): string {
    if (!dateString) return '-'
    return moment(dateString).format('LL')
}

function formatDays(number: string | number): string {
    // Remove any trailing zeroes after the decimal point
    if (number === null) return ''
    return `${Number(number)}`
}

export default Vue.extend({
    components: {
        NeuroAcuteCareSummary,
    },
    props: {
        stay_id: {
            type: Number,
            required: true,
        },
        textVersion: {
            type: String,
            required: false,
            default: 'discharge',
            validator: (value) => {
                return ['discharge', 'hyperacute', 'strokeUnitSummary'].indexOf(value) !== -1
            },
        },
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        stay(): Stay | undefined {
            return get(stays.state.stays, this.stay_id, undefined)
        },
        dischargeText(): string {
            const parsed = reader.parse(dischargeMarkup(this.stay, this.$store.direct.state.user.anonymise))
            return writer.render(parsed)
        },
        strokeUnitSummary(): ({ title: string, value: string })[] {
            if (!this.stay) return []
            
            let palliativeText = 'No'
            if (this.stay.care_classification && this.stay.care_classification.classification === 'PALLIATIVE_PATHWAY') {
                palliativeText = `Yes (Set: ${formatDate(this.stay.care_classification.date)})`
            }

            return [
                { title: 'Admission date', value: formatDate(this.stay.admission_time) },
                { title: 'Discharge date',  value: formatDate(this.stay.discharge_time) },
                { title: 'Length of stay',  value: formatDays(this.stay.length_of_stay) },
                { title: 'Days in stroke unit', value: formatDays(this.stay.days_in_stroke_unit!) },
                { title: 'Palliative', value: palliativeText },
            ]
        },
    },
})






import Vue from 'vue'
import { dischargeMarkup } from 'utils/text/discharge'
import stays from '@store/stays'
import Editor from '@shared/inputs/TextEditor.vue'


export default Vue.extend({
    components: {
        Editor
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay() {
            return stays.state.stays[this.stay_id]
        },
        markup(): string {
            return dischargeMarkup(this.stay, this.$store.direct.state.user.anonymise)
        },
    },
    methods: {
        updateDischargeEMR(text: string) {
            // stays.extActSetPathValue(this.stay_id, 'discharge.emr_notes', text)
        }
    },
})

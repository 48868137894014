var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.diagnosisHistory
    ? _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { class: _vm.columnClasses }, [
              _c("h3", [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(_vm._s(_vm.patient.last_name))
                ]),
                _vm._v(
                  ", " +
                    _vm._s(_vm.patient.first_name) +
                    " (" +
                    _vm._s(_vm.patient.age) +
                    ")"
                )
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("MRN: " + _vm._s(_vm.patient.mrn))]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("h5", [_vm._v("Diagnosis History")]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "timeline" },
                _vm._l(_vm.timelineEvents, function(record) {
                  return _c(
                    "li",
                    { key: record.id },
                    [
                      _c("p", [_vm._v(_vm._s(record.dt_s))]),
                      _vm._v(" "),
                      _vm._l(record.changes, function(change, index) {
                        return _c("div", { key: index }, [
                          _c("em", {
                            domProps: { innerHTML: _vm._s(change.title) }
                          }),
                          _vm._v(" "),
                          change.desc
                            ? _c("p", {
                                domProps: { innerHTML: _vm._s(change.desc) }
                              })
                            : _vm._e()
                        ])
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          false
            ? [
                _c("hr"),
                _vm._v(" "),
                _c("pre", [_vm._v(_vm._s(_vm.diagnosisHistory))])
              ]
            : _vm._e()
        ],
        2
      )
    : _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-center h-100"
        },
        [_vm._m(0)]
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("em", { staticClass: "loading-dots" }, [
        _vm._v("\n            Loading"),
        _c("span", [_vm._v(".")]),
        _c("span", [_vm._v(".")]),
        _c("span", [_vm._v(".")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
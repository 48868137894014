var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.speciality
    ? _c("div", { staticClass: "row mt-2" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("h3", [_vm._v("Find")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "b-form",
              {
                attrs: { inline: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.findAll.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-input", {
                      attrs: {
                        id: _vm.htmlIDs.search,
                        placeholder: "MRN",
                        autofocus: ""
                      },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "b-input-group-append",
                      [
                        _c("b-btn", { attrs: { type: "submit" } }, [
                          _vm._v("Search")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("b-table", {
              attrs: {
                striped: "",
                hover: "",
                fields: _vm.fields,
                items: _vm.items,
                busy: !_vm.ready
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "head(stays_raw)",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          { staticClass: "progress-bar-striped px-2" },
                          [_vm._v("JSON [superuser]")]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(stays_raw)",
                    fn: function(data) {
                      return [
                        _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: "p_" + data.item.patient.id + "_data",
                                expression: "`p_${data.item.patient.id}_data`"
                              }
                            ],
                            attrs: { size: "sm" }
                          },
                          [_vm._v("Show/Hide")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-collapse",
                          {
                            attrs: { id: "p_" + data.item.patient.id + "_data" }
                          },
                          [_c("pre", [_vm._v(_vm._s(data.item.stays))])]
                        )
                      ]
                    }
                  },
                  {
                    key: "head(stays)",
                    fn: function() {
                      return [
                        _c("span", [
                          _vm._v("Stays [" + _vm._s(_vm.orderingText) + "]")
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover",
                                modifiers: { hover: true }
                              }
                            ],
                            staticClass: "py-0 ml-1",
                            attrs: { size: "sm", title: "Change ordering" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm.ascOrdering = !_vm.ascOrdering
                              }
                            }
                          },
                          [
                            _c("fnt-a-icon", {
                              attrs: { icon: ["far", "repeat-alt"] }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(stays)",
                    fn: function(data) {
                      return [
                        _c(
                          "div",
                          { staticClass: "scrawl-grouped" },
                          [
                            _vm._l(data.value, function(combined) {
                              return [
                                combined.sstay
                                  ? [
                                      _c(
                                        "b-dd",
                                        {
                                          key: "sstay_" + combined.sstay.id,
                                          attrs: {
                                            size: "sm",
                                            split: "",
                                            "split-to": _vm.dataEntryRoute(
                                              combined.sstay
                                            ),
                                            text: _vm.dataEntryLabel(
                                              combined.sstay
                                            )
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "button-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.dataEntryLabel(
                                                            combined.sstay
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                    combined.sstay.active
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-success"
                                                          },
                                                          [
                                                            _c("fnt-a-icon", {
                                                              staticClass:
                                                                "svg",
                                                              attrs: {
                                                                icon: [
                                                                  "fas",
                                                                  "play-circle"
                                                                ]
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-warning"
                                                          },
                                                          [
                                                            _c("fnt-a-icon", {
                                                              staticClass:
                                                                "svg",
                                                              attrs: {
                                                                icon: [
                                                                  "fas",
                                                                  "stop-circle"
                                                                ]
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          combined.sstay.primary
                                            ? _c("b-dd-item", [
                                                _vm._v(
                                                  "\n                                    Active: " +
                                                    _vm._s(
                                                      combined.sstay.active
                                                        ? "Yes"
                                                        : "No"
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                    Consultation for:"
                                                ),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "pl-3" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Speciality: " +
                                                        _vm._s(
                                                          combined.sstay.primary
                                                            .speciality_title
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                        Admitted: " +
                                                        _vm._s(
                                                          _vm.formatDT(
                                                            combined.sstay
                                                              .primary
                                                              .admission_time
                                                          )
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                        Discharged: " +
                                                        _vm._s(
                                                          _vm.formatDT(
                                                            combined.sstay
                                                              .primary
                                                              .discharge_time
                                                          )
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                        Visit ID: " +
                                                        _vm._s(
                                                          combined.sstay.primary
                                                            .ext_id
                                                        ) +
                                                        "\n                                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("hr"),
                                                _vm._v(
                                                  "\n                                    Speciality: " +
                                                    _vm._s(
                                                      combined.sstay
                                                        .speciality_title
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                    Started: " +
                                                    _vm._s(
                                                      _vm.formatDT(
                                                        combined.sstay
                                                          .created_at
                                                      )
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                    Last updated: " +
                                                    _vm._s(
                                                      _vm.formatDT(
                                                        combined.sstay
                                                          .updated_at
                                                      )
                                                    ) +
                                                    "\n                                "
                                                )
                                              ])
                                            : _c("b-dd-item", [
                                                _vm._v(
                                                  "\n                                    Active: " +
                                                    _vm._s(
                                                      combined.sstay.active
                                                        ? "Yes"
                                                        : "No"
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                    Speciality: " +
                                                    _vm._s(
                                                      combined.sstay
                                                        .speciality_title
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                    Admitted: " +
                                                    _vm._s(
                                                      _vm.formatDT(
                                                        combined.sstay
                                                          .admission_time
                                                      )
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                    Discharged: " +
                                                    _vm._s(
                                                      _vm.formatDT(
                                                        combined.sstay
                                                          .discharge_time
                                                      )
                                                    )
                                                ),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                    Visit ID: " +
                                                    _vm._s(
                                                      combined.sstay.ext_id
                                                    ) +
                                                    "\n                                "
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    ]
                                  : combined.pstay
                                  ? [
                                      _c(
                                        "b-dd",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal",
                                              value: _vm.consultHtmlID(
                                                combined.pstay
                                              ),
                                              expression:
                                                "consultHtmlID(combined.pstay)"
                                            }
                                          ],
                                          key: "pstay_" + combined.pstay.id,
                                          attrs: {
                                            size: "sm",
                                            split: "",
                                            text: "Consult"
                                          }
                                        },
                                        [
                                          _c("b-dd-item", [
                                            _vm._v(
                                              "\n                                    Speciality: " +
                                                _vm._s(
                                                  combined.pstay.speciality_code
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                    Admitted: " +
                                                _vm._s(
                                                  _vm.formatDT(
                                                    combined.pstay
                                                      .admission_time
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                    Discharged: " +
                                                _vm._s(
                                                  _vm.formatDT(
                                                    combined.pstay
                                                      .discharge_time
                                                  )
                                                )
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                    Visit ID: " +
                                                _vm._s(combined.pstay.ext_id) +
                                                "\n                                "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-modal",
                                        {
                                          attrs: {
                                            id: _vm.consultHtmlID(
                                              combined.pstay
                                            ),
                                            title: "Consultation",
                                            "cancel-title": "Close",
                                            "ok-disabled": !_vm.canConsult(
                                              data.item
                                            ),
                                            "ok-title": "Start",
                                            "ok-variant": "success"
                                          },
                                          on: {
                                            ok: function($event) {
                                              return _vm.startConsult(
                                                data.item.patient,
                                                combined
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm.canConsult(data.item)
                                            ? [
                                                _c(
                                                  "p",
                                                  { staticClass: "px-0" },
                                                  [
                                                    _vm._v(
                                                      "\n                                        Name: " +
                                                        _vm._s(
                                                          _vm.formatPatient(
                                                            data.item.patient
                                                          )
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                        MRN: " +
                                                        _vm._s(
                                                          data.item.patient.mrn
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                        Speciality: " +
                                                        _vm._s(
                                                          combined.pstay
                                                            .speciality_code
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                        Admitted: " +
                                                        _vm._s(
                                                          _vm.formatDT(
                                                            combined.pstay
                                                              .admission_time
                                                          )
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                        Discharged: " +
                                                        _vm._s(
                                                          _vm.formatDT(
                                                            combined.pstay
                                                              .discharge_time
                                                          )
                                                        )
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                                        Visit ID: " +
                                                        _vm._s(
                                                          combined.pstay.ext_id
                                                        ) +
                                                        "\n                                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _c("p", [
                                                _vm._v(
                                                  "\n                                    There is currently an active stay/consultation in Scrawl for " +
                                                    _vm._s(
                                                      _vm.formatPatient(
                                                        data.item.patient
                                                      )
                                                    ) +
                                                    " (MRN: " +
                                                    _vm._s(
                                                      data.item.patient.mrn
                                                    ) +
                                                    "). Please use that record instead.\n                                "
                                                )
                                              ])
                                        ],
                                        2
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1473733261
              )
            })
          ],
          1
        )
      ])
    : _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-center h-100 mt-5"
        },
        [_vm._m(0)]
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("em", [
        _vm._v(
          "Please select a speciality on the home page to access this functionality."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }





import Vue from 'vue'
import { defaults, map } from 'lodash-es'
import FlatPickr from 'flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import utils from 'utils'
import { BaseOptions } from 'flatpickr/dist/types/options'

export default Vue.extend({
    props: {
        value: {},
        config: {
            type: Object as () => FlatPickr.Options.Options,
            default: () => ({ enableTime: true } as FlatPickr.Options.Options)
        },
        monthPicker: {
            type: Boolean,
            default: false
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            fp: null as FlatPickr.Instance | null,
            htmlIds: {
                second: `${uid}___datepicker_end`
            }
        }
    },
    methods: {
        onClose(dates: Date[], currentDateString: string, self: FlatPickr.Instance, data?: any) {
            this.$emit('closed')
            setTimeout(() => {
                if (this.fp) {
                    if (this.fp.selectedDates.length > 0) {
                        let value: Date[] | Date | string[] | string = this.fp.selectedDates

                        // Format the datetime using the dateFormat config parameter if it's set
                        const dateFormat = this.config.dateFormat
                        if (dateFormat) {
                            const formatDate  = this.fp.formatDate
                            value = map(value, function(date: Date) {
                                return formatDate(date, dateFormat)
                            })
                        }

                        if (this.config.mode !== 'range') {
                            value = value[0]
                        }
                        this.$emit('input', value)
                    }
                    else
                        this.$emit('input', null)

                }
            }, 0)
        },
        onChange(dates: Date[], currentDateString: string, self: FlatPickr.Instance, data?: any) {
            setTimeout(() => {
                if (this.fp && this.fp.selectedDates.length === 0)
                    this.$emit('input', null)
            }, 0)
        },
        onOpen() {
            setTimeout(() => {
                this.$emit('opened')
            }, 0)
        },
        emitDateFormat() {
            if (this.fp)
                this.$emit('flatpickr-date-format', this.fp.config.dateFormat)
        }
    },
    computed: {
        elem(): HTMLElement {
            return (this.config.wrap ? this.$el.parentElement! : this.$el) as HTMLElement
        }
    },
    watch: {
        value(val: any) {
            if (val === (this.$el as HTMLInputElement).value)
                return

            if (this.fp)
                this.fp.setDate(val, true)
        },
        fp(val: any) {
            this.emitDateFormat()
        },
        'config.dateFormat': function(val: any) {
            this.emitDateFormat()
        }
    },
    mounted() {
        if (this.fp)
            return

        const defaultConfig: Partial<BaseOptions> = {
            defaultDate: this.value as string | number | Date | undefined,
            altInput: true,
            altFormat: 'd M Y, H:i',
            onClose: this.onClose,
            onChange: this.onChange,
            onOpen: this.onOpen,
            time_24hr: true,
        }

        const config_: Partial<BaseOptions> = defaults(this.config, defaultConfig)

        if (this.monthPicker) {
            config_.plugins = [
                monthSelectPlugin({
                    dateFormat: 'Y-m',
                }),
            ]
        }
        this.fp = FlatPickr(this.elem, config_) as FlatPickr.Instance
    },
    destroyed() {
        if (this.fp)
            this.fp.destroy()
    }
})

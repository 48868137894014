








































import Vue from 'vue'
import { find, includes, map } from 'lodash-es'
import utils from 'utils'
import stays from '@store/stays'
import { ASMT_NURSE_SPECIALITY } from 'routers/constants'
import { VizData } from 'models/dataviz'
import { Syndrome } from 'models/med_templates/syndrome'

interface StayNR {
    id: number
    label: string
    assessed: boolean
}

export default Vue.extend({
    props: {
        data: {
            type: Object as () => VizData,
            required: true
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            modalId: `${uid}___viz_nr_list`,
            useAltLabel: false,
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        hasStays(): boolean {
            return this.data.all_stays.length > 0
        },
        summaryText(): string {
            return `${this.data.nel_nr_assessed_by_nurse_yes.length}/${this.data.all_stays.length}`
        },
        stays(): StayNR[] {
            return map(this.data.stays, stay => ({
                id: stay.id,
                label: stay.patient_summary,
                assessed: includes(this.data.nel_nr_assessed_by_nurse_yes, stay.id),
            }))
        },
        label(): string {
            if (this.isPowerUser && this.useAltLabel)
                return '90 day mRS complete' 
            return 'Nurse reviews'
        },
    },
    methods: {
        hasRapidAsmtSyndrome(stay_id: number): boolean {
            const stay = stays.state.stays[stay_id]
            return !!stay && !!stay.rapid_assessment_syndrome
        },
        setRapidAsmtSyndrome(stay_id: number): boolean {
            const stay = stays.state.stays[stay_id]

            if(!stay) return false
            if (!!stay.rapid_assessment_syndrome) return true

            const speciality = this.$store.direct.getters.templates.speciality(stay.speciality)

            if (!speciality) return false

            const syndromes = speciality.rapid_asmt_syndromes
            let syndrome: Syndrome | undefined
            if (syndromes.length === 1)
                syndrome = syndromes[0]
            else
                syndrome = find(syndromes, s => s.title.toLowerCase().trim() === 'nurse review')

            if (!syndrome) return false

            stays.extActUpdateRapidAssessmentSyndrome(stay_id, syndrome.id)
            return true
        },
        setEditMode(stay_id: number) {
            this.$store.direct.commit.session.setDataVizEditMode({ id: stay_id, mode: 'rapid_asmt' })
            this.$store.direct.commit.session.setDataVizStayID(stay_id)
        },
        setSyndromeAndEdit(stay_id: number) {
            let showEdit = true
            if (!this.hasRapidAsmtSyndrome(stay_id))
                showEdit = this.setRapidAsmtSyndrome(stay_id)

            if (showEdit) {
                this.setEditMode(stay_id)
                this.$nextTick(() => {
                    this.$root.$bvModal.show(`viz-stay-edit-modal`)
                })
            }
            else
                this.$router.push({ name: ASMT_NURSE_SPECIALITY, params: { stay_id: `${stay_id}` } })
        }
    }
})



















import mixins from "vue-typed-mixins"
import stays from "@store/stays"

import { DisplayList, LayoutGroup } from "models/layout"
import { LayoutGroupExt, PropExtInfo } from "models"
import { NestedProp } from "models/schema"
import { QuestionCategory } from 'models/med_templates/question'
import { Syndrome } from 'models/med_templates/syndrome'

import DisplayMixin from "../mixins/Display.vue"
import Generic from "../sections/Generic.vue"
import Questions from '@stayinputs/Questions.vue'
import { FullSchema } from 'models/schema/stay'


const displayInfo: DisplayList = [
    {
        field: "respiratory_rate",
        fnt_a_icon: ['fal', 'lungs'],
        icon_color: 'aquamarine',
        unit: "/min"
    },
    {
        field: "heart_rate",
        fnt_a_icon: ['fal', 'heartbeat'],
        icon_color: 'red',
        unit: "/min"
    },
    {
        field: "temperature",
        fnt_a_icon: ['fal', 'thermometer'],
        icon_color: 'orange',
        unit: "° C"
    },
    "blood_pressure_systolic",
    "blood_pressure_diastolic",
    {
        field: "blood_sugar_level",
        unit: "mmol/L"
    },
    {
        field: "oxygen_saturations",
        unit: "%"
    },
]

// TODO create custom component for blood pressure input
const layout: LayoutGroup[] = [
    { fields: ["heart_rate", "blood_pressure_systolic", "blood_pressure_diastolic"] },
    { fields: ["respiratory_rate", "oxygen_saturations"] },
    { fields: ["temperature", "blood_sugar_level"] },
]

export default mixins(DisplayMixin).extend({
    components: {
        Generic,
        Questions,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        const section: keyof FullSchema = 'admissionexam'
        return {
            section,
            QuestionCategory,
        }
    },
    computed: {
        syndrome(): Syndrome | undefined {
            return stays.getSyndrome(this.stay_id)
        },
        schema(): NestedProp | undefined {
            return this.getSectionSchema(this.section)
        },
        fields(): PropExtInfo[] {
            if (this.schema)
                return this.generatePropExtInfo(this.section, this.schema.children, displayInfo)
            return []
        },
        groups(): LayoutGroupExt[] | undefined {
            return this.generateGroups(this.section, this.fields, layout)
        },
        errorMsg(): string {
            return this.schema ? "" : "Schema loading..."
        }
    },
})

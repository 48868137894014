import { string as dstring, number as dnumber, positiveInteger, boolean as dboolean, constant, oneOf, nullable, array, either, exact, guard, optional } from 'decoders'

export interface Site {
    id: string
    title: string
    schema_dl?: boolean
    enabled?: boolean
}

export type Sites = Site[]

export interface Meta {
    sites: Sites
    schema: string
    ask_reason: boolean
    mrn: boolean
    criteria: number[]
    referral_advisory: boolean
    asap_pathways: number[] | 'all'
}

const site = exact({
    id: dstring,
    title: dstring,
    schema_dl: optional(dboolean),
    enabled: optional(dboolean),
})

const meta = exact({
    sites: array(site),
    schema: dstring,
    ask_reason: dboolean,
    mrn: dboolean,
    criteria: array(dnumber),
    referral_advisory: dboolean,
    asap_pathways: either(constant('all' as 'all'), array(dnumber)),
})

const partialMeta = exact({
    sites: optional(array(site)),
    schema: optional(dstring),
    ask_reason: optional(dboolean),
    mrn: optional(dboolean),
    criteria: optional(array(dnumber)),
    referral_advisory: optional(dboolean),
    asap_pathways: optional(either(constant('all' as 'all'), array(dnumber))),
})

export const metaDecoder = guard(meta, { style: 'simple' })
export const partialMetaDecoder = guard(partialMeta, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _site: Site = guard(site)({}) } catch(e) {}
try { const _meta: Meta = metaDecoder({}) } catch(e) {}
try { const _partialMeta: Partial<Meta> = partialMetaDecoder({}) } catch(e) {}

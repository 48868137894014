



















import Vue from "vue"
import { get, includes, keys, map, values } from "lodash-es"

import stays from "@store/stays"
import { Stay } from "models/data/stay"

import StateProp from "./StateProp.vue"

export default Vue.extend({
    data() {
        return {
            contentVisible: false,
            selected: -1
        }
    },
    methods: {
        toggleVisibility() {
            this.contentVisible = !this.contentVisible
        },
        propVal(key: string) {
            if (this.stay)
                return get(this.stay, key)
        }
    },
    computed: {
        toggleText(): string {
            return this.contentVisible ? "Hide" : "Show"
        },
        stays(): Stay[] {
            return values(stays.state.stays)
        },
        staysSelectArray(): any {
            return map(this.stays, function(stay) {
                return { value: stay.id, text: "Stay #" + stay.id }
            })
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.selected]
        },
        keys(): string[] {
            if (this.stay)
                return keys(this.stay).sort()
            return []
        }
    },
    watch: {
        'stays': {
            immediate: true,
            handler: function() {
                if (this.stays.length) {
                    if (!includes(map(this.stays, 'id'), this.selected))
                        this.selected = this.stays[0].id
                }
                else {
                    this.selected = -1
                }
            }
        }
    },
    components: {
        StateProp,
    }
})

import { OrderedItem } from '../base'
import { QuestionGroup, questionGroup } from './question'
import { string as dstring, number as dnumber, array, exact, guard } from 'decoders'


export interface AsapPathway extends OrderedItem {
    speciality: number
    question_groups: QuestionGroup[]
}

export const asapPathway = exact({
    id: dnumber,
    order: dnumber,
    question_groups: array(questionGroup),
    speciality: dnumber,
    title: dstring,
})

const asapPathwayDecoder = guard(asapPathway, { style: 'simple' })
export const asapPathwaysDecoder = guard(array(asapPathway), { style: 'simple' })

try { const _asapPathway: AsapPathway = asapPathwayDecoder({}) } catch(e) {}





























import mixins from 'vue-typed-mixins'
import { filter, isFinite } from 'lodash-es'
import { Stay } from 'models/data/stay'
import { SpecialityDoctors } from 'models/store/templates'
import InputMixin from '@mixins/Input.vue'
import Dropdown from '@shared/inputs/Dropdown.vue'

export default mixins(InputMixin).extend({
    components: {
        Dropdown,
    },
    props: {
        stay: {
            type: Object as () => Stay,
            required: true
        },
        label: {
            type: String,
            default: 'Doctor'
        },
    },
    computed: {
        groupedDoctors(): readonly SpecialityDoctors[] {
            return this.$store.direct.getters.templates.groupedDoctors
        },
        filteredGroups(): readonly SpecialityDoctors[] {
            if (this.stay.speciality !== null)
                return filter(this.groupedDoctors, group => group.id === this.stay.speciality)
            return this.groupedDoctors
        },
        atLeastOne(): boolean {
            return this.$store.direct.getters.templates.atLeastOneDoctor
        },
        stayDoctor: {
            get(): number {
                return this.getFieldVal(this.stay.id, 'doctor')
            },
            set(val: number | null) {
                this.setFieldVal(this.stay.id, 'doctor', val)
            }
        },
        selectedText(): string {
            if (isFinite(this.stayDoctor)) {
                const doctorId: number = this.stay.doctor as number
                const doctor = this.$store.direct.getters.templates.doctor(doctorId)
                if (doctor)
                    return doctor.last_name
            }
            return '[Choose one]'
        },
    },
    methods: {
        isSelected(id: number) {
            return this.stayDoctor === id
        },
        select(id: number) {
            if (this.stayDoctor === id) this.stayDoctor = null
            else this.stayDoctor = id
        },
    }
})

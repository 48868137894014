





















import Vue from 'vue'
import PrintDocx from './PrintListDocx.vue'
import PrintHeader from 'components/misc/SpecialityPrintListHeader.vue'
import PrintPdf from './PrintListPDF.vue'

export default Vue.extend({
    components: {
        PrintDocx,
        PrintHeader,
        PrintPdf,
    },
    props: {
        stay_ids: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            default: 'Print'
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
        showPrintHeader: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        canAddHeader(): boolean {
            const header = this.$store.direct.getters.user.speciality?.print_header
            return !!header
        },
        printHeader: {
            get(): boolean { return this.$store.direct.state.user.print_header },
            set(val: boolean) { this.$store.direct.dispatch.user.togglePrintHeader() }
        },
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex d-inline-block" }, [
    _c("fieldset", { staticClass: "form-group mb-0" }, [
      _vm.showLabel
        ? _c("legend", { staticClass: "col-form-label py-0" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-items-end" },
        [
          _vm.showPrintHeader ? _c("print-header") : _vm._e(),
          _vm._v(" "),
          _c("print-pdf", { attrs: { stay_ids: _vm.stay_ids } }),
          _vm._v(" "),
          _c("print-docx", { attrs: { stay_ids: _vm.stay_ids } }),
          _vm._v(" "),
          _vm.canAddHeader
            ? _c(
                "b-check",
                {
                  staticClass: "scrawl-checkbox ml-3",
                  attrs: {
                    size: "lg",
                    title:
                      "When ticked, adds header to print list. Only affects current user."
                  },
                  model: {
                    value: _vm.printHeader,
                    callback: function($$v) {
                      _vm.printHeader = $$v
                    },
                    expression: "printHeader"
                  }
                },
                [_vm._v("\n                Add header\n            ")]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { filter, includes, isFinite, map, meanBy, reject, round, sortBy } from 'lodash-es'
import { SummaryStay } from 'models/dataviz'

import staysStore from '@store/stays'
import store from 'store'

function median(arr: any[]): number | undefined {
    arr = arr.slice(0) // create copy
    arr = reject(arr, function(n) { return n === null })
    if (!arr.length) return

    const middle = (arr.length + 1) / 2
    const sorted = sortBy(arr)
    return (sorted.length % 2) ? sorted[middle - 1] : (sorted[middle - 1.5] + sorted[middle - 0.5]) / 2
}

function medianDaysInClassification(field: string) {
    return function (stays: SummaryStay[]): string | undefined {
        const medianDays = median(map(stays, field))
        if (medianDays === undefined) return
        return ` (Median ${medianDays} days)`
    }
}

function meanDaysInClassification(field: string) {
    return function (stays: SummaryStay[]): string | undefined {
        const meanDays = round(meanBy(stays, field), 1)
        if (!isFinite(meanDays)) return
        return ` (Mean ${meanDays} days)`
    }
}

function medianDaysInSU(stays: SummaryStay[]) {
    const medianDays = median(map(stays, 'days_in_stroke_unit'))
    if (medianDays === undefined) return
    return ` (Median ${medianDays} days in SU)`
}

function meanDaysInSU(stays: SummaryStay[]) {
    if (!stays.length) return
    const mean = round(meanBy(stays, 'days_in_stroke_unit'), 1)
    return ` (Mean ${mean} days in SU)`
}

function fromAmbulance(stay: SummaryStay): boolean {
    if (!stay.first_contact_from) return false
    return includes(stay.first_contact_from.toLowerCase(), 'ambulance')
}

function fromED(stay: SummaryStay): boolean {
    if (!stay.first_contact_from) return false
    return (
        includes(stay.first_contact_from.toLowerCase(), 'emergency dept') ||
        includes(stay.first_contact_from.toLowerCase(), 'acute presentation')
    )

}

function fromAmbulanceOrED(stay: SummaryStay): boolean {
    return fromAmbulance(stay) || fromED(stay)
}

function fromOther(stay: SummaryStay): boolean {
    return Boolean(stay.first_contact_from) && !fromAmbulanceOrED(stay)
}

function listValues(stays: SummaryStay[], field: keyof SummaryStay): string {
    const values = map(stays, stay => {
        const value = stay[field] || '?'
        return `<span title="${stay.patient_summary}\n${value}">${value}</span>`
    })
    if (values.length) {
        return values.join(', ')
    }
    return '-'
}

function stayIsHyperacute(stay: SummaryStay) {
    if (store.direct.state.user.telestroke_mode) return true
    const storeStay = staysStore.state.stays[stay.id] as any
    if (storeStay) return storeStay.hyperacute_review
    return false
}

function hyperacuteStays(stays: SummaryStay[]) {
    return filter(stays, stay => stayIsHyperacute(stay))
}

const tables = {
    service: [
        {
            textVersion: 'hyperacute',
            fields: [
                { key: 'ais_no_reperfusion', title: 'AIS no reperfusion' },
                { key: 'ais_lysis', title: 'AIS lysis' },
                { key: 'ais_lysis_ecr', title: 'AIS lysis + ECR' },
                { key: 'ais_ecr', title: 'AIS ECR' },
                { key: 'ich', title: 'ICH' },
            ],
        },    
    ],
    hyperacuteService: function(isTelestrokeMode: boolean) {
        return [
            {
                textVersion: 'hyperacute',
                fields: [
                    { 
                        key: 'ais_no_reperfusion',
                        title: 'AIS no reperfusion',
                        calculation: function(stays: SummaryStay[]) {
                            if (isTelestrokeMode) return stays.length
                            return hyperacuteStays(stays).length
                        },
                        infoFilter: function(stay: SummaryStay) {
                            if (isTelestrokeMode) return true
                            return stayIsHyperacute(stay)
                        },
                    },
                    {
                        key: 'ais_lysis',
                        title: 'AIS lysis',
                        calculation: function(stays: SummaryStay[]) {
                            if (isTelestrokeMode) return stays.length
                            return hyperacuteStays(stays).length
                        },
                        infoFilter: function(stay: SummaryStay) {
                            if (isTelestrokeMode) return true
                            return stayIsHyperacute(stay)
                        },
                    },
                    {
                        key: 'ais_lysis_ecr',
                        title: 'AIS lysis + ECR',
                        calculation: function(stays: SummaryStay[]) {
                            if (isTelestrokeMode) return stays.length
                            return hyperacuteStays(stays).length
                        },
                        infoFilter: function(stay: SummaryStay) {
                            if (isTelestrokeMode) return true
                            return stayIsHyperacute(stay)
                        },
                    },
                    {
                        key: 'ais_ecr',
                        title: 'AIS ECR',
                        calculation: function(stays: SummaryStay[]) {
                            if (isTelestrokeMode) return stays.length
                            return hyperacuteStays(stays).length
                        },
                        infoFilter: function(stay: SummaryStay) {
                            if (isTelestrokeMode) return true
                            return stayIsHyperacute(stay)
                        },
                    },
                    {
                        key: 'ich',
                        title: 'ICH',
                        calculation: function(stays: SummaryStay[]) {
                            if (isTelestrokeMode) return stays.length
                            return hyperacuteStays(stays).length
                        },
                        infoFilter: function(stay: SummaryStay) {
                            if (isTelestrokeMode) return true
                            return stayIsHyperacute(stay)
                        },
                    },
                ],
            },    
        ]
    },
    doorToNeedle: [
        {
            textVersion: 'hyperacute',
            fields: [
                {
                    key: 'lysis_total',
                    title: 'Door to needle (median)',
                    calculation: function(stays: SummaryStay[]) {
                        // Calculate the median door_to_needle_time
                        const emergencyStays = filter(stays, fromAmbulanceOrED)
                        const medianTime = median(map(emergencyStays, 'door_to_needle'))
                        if (medianTime === undefined) return '-'
                        return `${medianTime} minutes`
                    },
                },
                {
                    key: 'lysis_total',
                    title: 'Door to needle times',
                    calculation: function(stays: SummaryStay[]) {
                        const emergencyStays = filter(stays, fromAmbulanceOrED)
                        return listValues(emergencyStays, 'door_to_needle')
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return stay.door_to_needle === null
                    },
                    infoTitle: 'Stays with no door to needle times',
                },
            ]
        },
    ],
    serviceExtra: [
        {
            textVersion: 'hyperacute',
            fields: [
                {
                    key: 'not_admitted_to_stroke_unit',
                    title: 'Admitted to stroke unit',
                    calculation: function(stays: SummaryStay[], allStays: SummaryStay[]) {
                        if (!(allStays && allStays.length)) return '-'

                        const admittedCount = allStays.length - stays.length
                        return `${admittedCount}/${allStays.length}`
                    },
                    infoTitle: 'Stays not admitted to the stroke unit',
                },
                {
                    key: 'all_stays',
                    title: 'Discharged on correct meds',
                    calculation: function(stays: SummaryStay[]) {
                        if (!(stays && stays.length)) return '-'

                        const validStays = filter(stays, (stay) => {
                            return stay.active === false && !stay.transferred_back && !stay.palliative
                        })
                        if (!validStays.length) return '-'

                        // Stays with `on_correct_meds = true` or `on_correct_meds = null`
                        // are both ok, we want to show how many stays have `on_correct_meds = false`
                        const onIncorrectMedsCount = filter(validStays, function(stay) {
                            return stay.on_correct_meds === false
                        }).length
                        const notOnInorrectMedsCount = validStays.length - onIncorrectMedsCount
                        return `${notOnInorrectMedsCount}/${validStays.length}`
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return (
                            !stay.active &&
                            !stay.palliative &&
                            !stay.transferred_back &&
                            stay.on_correct_meds === false
                        )
                    },
                    infoTitle: 'Stays not discharged on correct medications',
                    showCurrentMeds: true,
                },
            ],    
        },
    ],
    hyperacute: [
        {
            title: 'Hyperacute stroke assessments',
            textVersion: 'hyperacute',
            fields: [
                { key: 'hyperacute_stays', title: 'Total' },
                {
                    key: 'hyperacute_stays',
                    title: 'Call from Ambulance',
                    calculation: function(stays: SummaryStay[]) {
                        if (!stays.length) return '-'

                        const ambulanceStays = filter(stays, fromAmbulance)
                        return ambulanceStays.length
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return fromAmbulance(stay)
                    },
                },
                {
                    key: 'hyperacute_stays',
                    title: 'Call from Emergency Dept',
                    calculation: function(stays: SummaryStay[]) {
                        if (!stays.length) return '-'

                        const edStays = filter(stays, fromED)
                        return edStays.length
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return fromED(stay)
                    },
                },
                {
                    key: 'hyperacute_stays',
                    title: 'Call from Other',
                    calculation: function(stays: SummaryStay[]) {
                        if (!stays.length) return '-'

                        const otherStays = filter(stays, fromOther)
                        return otherStays.length
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return stay.first_contact_from && !fromAmbulanceOrED(stay)
                    },
                },
                {
                    key: 'hyperacute_stays',
                    title: 'ASAP tool documented by ED MO: Yes',
                    calculation: function(stays: SummaryStay[]) {
                        if (!stays.length) return '-'
                        return filter(stays, stay => {
                            return stay.asap_documented
                        }).length 
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return stay.asap_documented
                    },
                    infoTitle: 'Stays with ASAP tool documented',
                },
                {
                    key: 'hyperacute_stays',
                    title: 'ASAP tool documented by ED MO: No',
                    calculation: function(stays: SummaryStay[]) {
                        if (!stays.length) return '-'
                        return filter(stays, stay => {
                            return stay.asap_documented === false
                        }).length 
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return stay.asap_documented === false
                    },
                    infoTitle: 'Stays with no ASAP tool documented',
                },
                {
                    key: 'hyperacute_stays',
                    title: 'ASAP tool documented by ED MO: ?',
                    calculation: function(stays: SummaryStay[]) {
                        if (!stays.length) return '-'
                        return filter(stays, stay => stay.asap_documented === null).length 
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return !stay.asap_documented
                    },
                    infoTitle: 'Stays where ASAP tool info missing',
                },
                {
                    key: 'hyperacute_stays',
                    title: 'Door to alert: Ambulance (median)',
                    calculation: function(stays: SummaryStay[]) {
                        const ambulanceStays = filter(stays, fromAmbulance)
                        const medianTime = median(map(ambulanceStays, 'triage_to_alert'))
                        if (medianTime === undefined) return '-'
                        return `${medianTime} minutes`
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return fromAmbulance(stay)
                    },
                },
                {
                    key: 'hyperacute_stays',
                    title: 'Door to alert times: Ambulance',
                    calculation: function(stays: SummaryStay[]) {
                        const ambulanceStays = filter(stays, fromAmbulance)
                        return listValues(ambulanceStays, 'triage_to_alert')
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return (
                            fromAmbulance(stay) &&
                            stay.triage_to_alert === null
                        )
                    },
                    infoTitle: 'Ambulance Stays with missing Door to Alert times',
                },
                {
                    key: 'hyperacute_stays',
                    title: 'Door to alert: ED (median)',
                    calculation: function(stays: SummaryStay[]) {
                        const edStays = filter(stays, fromED)
                        const medianTime = median(map(edStays, 'triage_to_alert'))
                        if (medianTime === undefined) return '-'
                        return `${medianTime} minutes`
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return fromED(stay)
                    },
                    infoTitle: 'ED Stays',
                },
                {
                    key: 'hyperacute_stays',
                    title: 'Door to alert times: ED',
                    calculation: function(stays: SummaryStay[]) {
                        const edStays = filter(stays, fromED)
                        return listValues(edStays, 'triage_to_alert')
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return (
                            fromED(stay) &&
                            stay.triage_to_alert === null
                        )
                    },
                    infoTitle: 'ED Stays with missing Door to Alert times',
                },
                {
                    key: 'all_stays',
                    title: 'First contact made prior to CT',
                    calculation: function(stays: SummaryStay[]) {
                        const emergencyStays = filter(stays, fromAmbulanceOrED)
                        if (!emergencyStays.length) return '-'
                        const firstContactMadePriorToCT = filter(emergencyStays, ['first_contact_made_prior_to_ct', true])
                        return `${firstContactMadePriorToCT.length}/${emergencyStays.length}`
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return fromAmbulanceOrED(stay) && !stay.first_contact_made_prior_to_ct
                    },
                    infoTitle: 'Stays where first contact was not made prior to CT',
                },
                {
                    key: 'all_stays',
                    title: 'Door to CT (median)',
                    calculation: function(stays: SummaryStay[]) {
                        const emergencyStays = filter(stays, fromAmbulanceOrED)
                        const medianTime = median(map(emergencyStays, 'triage_to_ct'))
                        if (medianTime === undefined) return '-'
                        return `${medianTime} minutes`
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return fromAmbulanceOrED(stay)
                    },
                },
                {
                    key: 'all_stays',
                    title: 'Door to CT times',
                    calculation: function(stays: SummaryStay[]) {
                        const emergencyStays = filter(stays, fromAmbulanceOrED)
                        return listValues(emergencyStays, 'triage_to_ct')
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return fromAmbulanceOrED(stay) && !stay.triage_to_ct
                    },
                    infoTitle: 'Stays with missing Door to CT times',
                },
                {
                    key: 'all_stays',
                    title: 'Door to lysis decision (median)',
                    calculation: function(stays: SummaryStay[]) {
                        const emergencyStays = filter(stays, fromAmbulanceOrED)
                        const medianTime = median(map(emergencyStays, 'door_to_lysis_decision'))
                        if (medianTime === undefined) return '-'
                        return `${medianTime} minutes`
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return fromAmbulanceOrED(stay)
                    },
                },
                {
                    key: 'all_stays',
                    title: 'Door to lysis decision times',
                    calculation: function(stays: SummaryStay[]) {
                        const emergencyStays = filter(stays, fromAmbulanceOrED)
                        return listValues(emergencyStays, 'door_to_lysis_decision')
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return fromAmbulanceOrED(stay) && !stay.door_to_lysis_decision
                    },
                    infoTitle: 'Stays with missing Door to lysis decision times',
                },
                {
                    key: 'all_stays',
                    title: 'Door to ECR decision (median)',
                    calculation: function(stays: SummaryStay[]) {
                        // Calculate the median door_to_ecr_decision time
                        const emergencyStays = filter(stays, fromAmbulanceOrED)
                        const medianTime = median(map(emergencyStays, 'door_to_ecr_decision'))
                        if (medianTime === undefined) return '-'
                        return `${medianTime} minutes`
                    },
                },
                {
                    key: 'all_stays',
                    title: 'Door to ECR decision times',
                    calculation: function(stays: SummaryStay[]) {
                        const emergencyStays = filter(stays, fromAmbulanceOrED)
                        return listValues(emergencyStays, 'door_to_ecr_decision')
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return stay.door_to_ecr_decision === null
                    },
                    infoTitle: 'Stays with no door to ECR decision times',
                },
                {
                    key: 'lysis_total',
                    title: 'Door to needle (median)',
                    calculation: function(stays: SummaryStay[]) {
                        const medianTime = median(map(stays, 'door_to_needle'))
                        if (medianTime === undefined) return '-'
                        return `${medianTime} minutes`
                    },
                },
                {
                    key: 'lysis_total',
                    title: 'Door to needle times',
                    calculation: function(stays: SummaryStay[]) {
                        return listValues(stays, 'door_to_needle')
                    },
                    infoFilter: function(stay: SummaryStay) {
                        return !stay.door_to_needle
                    },
                    infoTitle: 'Stays missing door to needle time',
                },
            ],
        },
    ],
    strokeCare: [
        {
            title: 'Stroke unit access',
            fields: [
                { key: 'all_stays', title: 'Total stays' },
                { key: 'admitted_to_stroke_unit', title: 'Admitted to Stroke Unit' },
                { key: 'palliative_in_first_24_hours', title: 'Palliative in first 24 hours of care' },
                { key: 'isolated_for_infectious_disease_risk', title: 'Isolated for ID risk' },
                { key: 'returned_after_1_week', title: 'Returned from other hospital >1 week after stroke onset' },
                { key: 'not_admitted_to_stroke_unit', title: 'Not admitted to Stroke Unit' },
            ],    
        },
        {
            title: 'Stroke unit dosage',
            textVersion: 'strokeUnitSummary',
            fields: [
                {
                    key: 'currently_in_stroke_unit',
                    title: 'Currently in stroke unit',
                    extra: medianDaysInSU,
                },
                {
                    key: 'in_stroke_unit_gt_72_hours',
                    title: 'Left stroke unit >72 hours',
                    extra: meanDaysInSU,
                },
                {
                    key: 'discharged_from_su_lt_72_hours',
                    title: 'Discharged home within 72 hours direct from stroke unit (Or same day)',
                    extra: medianDaysInSU,
                },
                {
                    key: 'palliative_in_72_hours_in_su',
                    title: 'Made palliative 1st 72 hours while in stroke unit',
                    extra: medianDaysInSU,
                },
                {
                    key: 'left_su_for_ward_lt_72_hours',
                    title: 'Left stroke unit <72 hours still acute',
                    extra: meanDaysInSU,
                },
            ],
        },
        {
            title: 'Type care change',
            fields: [
                {
                    key: 'palliative',
                    title: 'Palliative',
                    extra: meanDaysInClassification('days_in_palliative')
                },
                {
                    key: 'rehab',
                    title: 'Rehab',
                    extra: meanDaysInClassification('days_in_rehab')
                },
                {
                    key: 'maintenance',
                    title: 'Maintenance',
                    extra: meanDaysInClassification('days_in_maintenance')
                },
            ],
        },
    ],
}

export default tables

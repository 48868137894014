import Vue from "vue"
import { BareActionContext, getStoreBuilder } from "vuex-typex"
import store, { RootState } from ".."

import {
    assign,
    cloneDeep,
    concat,
    each,
    filter,
    find,
    findIndex,
    get,
    has,
    includes,
    isArray,
    isEmpty,
    isEqual,
    isEqualWith,
    keys,
    map,
    pickBy,
    set,
    sortBy,
    values
} from "lodash-es"

import utils from 'utils'
import { safeDecoding } from 'utils/store'

import { Stay, stayDecoder } from "models/data/stay"
import { OtherNextStep, OtherReadyWhen } from "models/data/discharge"
import { Investigation, InvSection } from "models/data/investigation"
import { SyndromeFeature, SyndromePosNeg } from "models/data/syndrome"
import {
    ListedObjectValues,
    InvChangesBase,
    NewInvData,
    PathValue,
    RapidAssessmentSyndrome as RapidAssessmentSyndromePayload,
    Syndrome as SyndromePayload
} from "models/payload"
import { NewIssue, NewOtherIssue, IssueChanges } from "models/payload/issue"
import { IssueStatus } from 'models/med_templates/issue'
import { QuestionCategory, QuestionGroup, QuestionGroupContainer } from "models/med_templates/question"
import { Issue, OtherIssue } from 'models/data/issue'

interface ChangeInfo {
    at: Date
    val: any
}

interface StayChanges {
    /** index is the field path */
    [k: string]: ChangeInfo
}

interface ClearChanges {
    stay_id: number
    /** changes up to and including this time is cleared. Ignored if paths is set. */
    at: Date
    /** an explicit list of paths to clear */
    paths?: string[]
}

export interface Stays {
    stays: { [index: number]: Stay }
    changes: { [index: number]: StayChanges }
    errors: { [index: number]: PathValue[] }
    monitor: boolean
    stayChanged: boolean
    updateURL: string
    /** flag indicating stays currently being retrieved from server */
    staysLoading: boolean
    /** property to trigger a call to load stays */
    loadStays: number
    mdtPeoplePresent: number[] | null
}

const initialState: Stays = {
    stays: {},
    changes: {},
    errors: {},
    monitor: true,
    stayChanged: false,
    updateURL: '/stay/full/',
    staysLoading: false,
    loadStays: 0,
    mdtPeoplePresent: null,
}

const builder = getStoreBuilder<RootState>().module("stays", initialState)

function sparseComparer(val1: any, val2: any): boolean {
    if (val2 === undefined)
        return true

    return isEqual(val1, val2)
}

namespace getters {
    export const stateGetter = builder.state()

    export const specialityGetter = builder.read(function getSpeciality(state) {
        const speciality = function(stay_id: number) {
            const stay = state.stays[stay_id]
            if (stay && stay.speciality)
                return find(store.direct.state.templates.specialities, ['id', stay.speciality])
            return
        }
        return speciality
    })

    export const isOrthoStayGetter = builder.read(function isOrthoStay(state) {
        const isOrthoStay = function(stay_id: number) {
            const speciality = getters.specialityGetter()(stay_id)
            return speciality ? speciality.title.toLowerCase() === 'orthopaedics' : false
        }
        return isOrthoStay
    })

    export const syndromeGetter = builder.read(function getSyndrome(state) {
        const syndrome = function(stay_id: number) {
            const stay = state.stays[stay_id]
            if (stay && stay.syndrome) {
                return find(store.direct.getters.templates.allSyndromes, ['id', stay.syndrome])
            }
            return
        }
        return syndrome
    })

    export const diagnosisGetter = builder.read(function getDiagnosis(state) {
        const diagnosis = function(stay_id: number) {
            const stay = state.stays[stay_id]
            if (stay && stay.diagnosis) {
                return find(store.direct.getters.templates.allDiagnoses, ['id', stay.diagnosis])
            }
            return
        }
        return diagnosis
    })

    export const answerGetter = builder.read(function getAnswer(state) {
        const answerValue = function(stay_id: number, questionID: number) {
            const stay = state.stays[stay_id]
            if (stay && stay._answers[`q_${questionID}`]) {
                return stay._answers[`q_${questionID}`].answer
            }
        }
        return answerValue
    })

    export const answerNotesGetter = builder.read(function getAnswerNotes(state) {
        const answerNotesValue = function(stay_id: number, questionID: number) {
            const stay = state.stays[stay_id]
            if (stay && stay._answers[`q_${questionID}`]) {
                return stay._answers[`q_${questionID}`].notes
            }
            return null
        }
        return answerNotesValue
    })

    export const questionGroupsGetter = builder.read(function getQuestionGroups(state) {
        const questionGroups = function(
            { stay_id, category, parentObject }:
            { stay_id: number, category: QuestionCategory, parentObject: QuestionGroupContainer }
        ): QuestionGroup[] {
            const stay = state.stays[stay_id]
            if (!stay || !parentObject) return []

            return filter(parentObject.question_groups, ['category', category])
        }
        return questionGroups
    })

    export const investigationsGetter = builder.read(function getinvestigations(state) {
        const investigationsArray = function(stay_id: number) {
            const stay = state.stays[stay_id]
            const investigations = get(stay, 'investigations') || []
            const otherinvestigations = get(stay, 'other_investigations') || []
            const allinvestigations = sortBy(concat(investigations, otherinvestigations), 'created_at')
            return allinvestigations
        }
        return investigationsArray
    })

    export const issuesGetter = builder.read(function getIssues(state) {
        const issuesArray = function(stay_id: number) {
            const stay = state.stays[stay_id]
            const issues = get(stay, 'issues') || []
            const otherIssues = get(stay, 'other_issues') || []
            const allIssues = sortBy(concat<Issue|OtherIssue>(issues, otherIssues), 'order')
            return allIssues
        }
        return issuesArray
    })

    export const activeIssuesGetter = builder.read(function getActiveIssues(state) {
        const issuesArray = function(stay_id: number) {
            const allIssues = issuesGetter()(stay_id)
            return filter(allIssues, ['status', IssueStatus.ACTIVE])
        }
        return issuesArray
    })

    export const resolvedIssuesGetter = builder.read(function getResolvedIssues(state) {
        const issuesArray = function(stay_id: number) {
            const allIssues = issuesGetter()(stay_id)
            return filter(allIssues, ['status', IssueStatus.RESOLVED])
        }
        return issuesArray
    })

    export const isThrombolysisGetter = builder.read(function isThrombolysis(state) {
        const isThrombolysis = function(stay_id: number) {
            const stay = state.stays[stay_id]
            if (!stay) return false

            const diagnosis = find(store.direct.getters.templates.allDiagnoses, ['id', stay.diagnosis])
            if (!diagnosis) return false

            return diagnosis.is_thrombolysis || false
        }
        return isThrombolysis
    })

    export const mdtPeoplePresentGetter = builder.read(function getMDTPeoplePresent(state) {
        const mdtPeoplePresent = function() {
            return state.mdtPeoplePresent || []
        }
        return mdtPeoplePresent
    })

}

namespace mutations {
    export function markStayChanged(state: Stays) {
        state.stayChanged = true
    }

    export function markStaySynced(state: Stays) {
        state.stayChanged = false
    }

    /**
     * addStay is identical to replaceStay, we have separate functions
     * so that the mutation labels are more semantically correct.
     */
    export function addStay(state: Stays, stay: Stay) {
        if (stay.id) {
            safeDecoding(stay, stayDecoder, 'store.stays.addStay')
            Vue.set(state.stays, stay.id.toString(), stay)
        }
    }

    /**
     * replaceStay is identical to addStay, we have separate functions
     * so that the mutation labels are more semantically correct.
     */
    export function replaceStay(state: Stays, stay: Stay) {
        addStay(state, stay)
    }

    export function addStays(state: Stays, stays: Stay[]) {
        each(stays, stay => {
            if (stay.id) {
                safeDecoding(stay, stayDecoder, 'store.stays.addStays')
                Vue.set(state.stays, stay.id.toString(), stay)
            }
        })
    }

    export function clearStay(state: Stays, stay_id: number) {
        if (state.stays[stay_id])
            Vue.delete(state.stays, stay_id.toString())
    }

    export function clearStays(state: Stays) {
        state.stays = {}
    }

    /** An empty mutation that we use to trigger actions in sync-stays plugin */
    export function updateAsap(state: Stays) {}

    export function pathValue(state: Stays, pathval: PathValue) {
        set(state.stays[pathval.id], pathval.path, pathval.val)
    }

    /** Has no checks, as we should only be calling this from the related action */
    export function listedObj(state: Stays, payload: ListedObjectValues) {
        const array = get(state.stays[payload.id], payload.path)
        const obj = find(array, [payload.id_field || 'id', payload.obj_id])

        if (obj)
            for (let key of keys(payload.values))
                Vue.set(obj, key, payload.values[key])
        else
            array.push(payload.values)
    }

    export function addChanges(state: Stays, pathval: PathValue) {
        const changeInfo = { at: new Date(), val: pathval.val }
        if (state.changes[pathval.id]) {
            Vue.set(state.changes[pathval.id], pathval.path, changeInfo)
        }
        else {
            const changes: StayChanges = {}
            changes[pathval.path] = changeInfo
            Vue.set(state.changes, pathval.id.toString(), changes)
        }
    }

    /** Note: assumes payload.values contains identifier field */
    export function addListedObjChange(state: Stays, payload: ListedObjectValues) {
        const stayChanges = state.changes[payload.id]
        if (stayChanges) {
            const pathChanges = get(stayChanges, payload.path)
            if (pathChanges) {
                if (isArray(pathChanges.val)) {
                    pathChanges.at = new Date()
                    const obj = find(pathChanges.val, [payload.id_field || 'id', payload.obj_id])
                    if (obj) // if changes exist, update object
                        for (let key of keys(payload.values))
                            Vue.set(obj, key, payload.values[key])
                    else
                        pathChanges.val.push(payload.values)
                }
                else {
                    // TODO report as error
                }
            }
            else {
                Vue.set(stayChanges, payload.path, {
                    at: new Date(),
                    val: [ payload.values ]    
                })    
            }
        }
        else {
            const changes: StayChanges = {}
            changes[payload.path] = {
                at: new Date(),
                val: [ payload.values ]
            }
            Vue.set(state.changes, payload.id.toString(), changes)    
        }
    }

    export function clearChanges(state: Stays, payload?: ClearChanges) {
        if (!payload) {
            console.log("Clearing all changes")
            state.changes = {}
        }
        else {
            console.log(`Clearing changes for stay ${payload.stay_id} <= ${payload.at}`)
            const changes = state.changes[payload.stay_id]
            if (changes) {
                const updatedChanges = pickBy(changes, (changeInfo, path) => {
                    if (payload.paths)
                        return !includes(payload.paths, path)
                    return changeInfo.at > payload.at
                })
                if (isEmpty(updatedChanges))
                    Vue.delete(state.changes, payload.stay_id.toString())
                else
                    Vue.set(state.changes, payload.stay_id.toString(), updatedChanges)
            }
        }
    }

    export function addError(state: Stays, pathError: PathValue) {
        let errors = state.errors[pathError.id]
        if (!errors) {
            errors = [pathError]
            Vue.set(state.errors, pathError.id.toString(), errors)
        }
        else {
            const i = findIndex(errors, ["path", pathError.path])
            if (i === -1)
                errors.push(pathError)
            else
                errors.splice(i, 1, pathError)
        }

        const changes = state.changes[pathError.id]
        if (changes && changes[pathError.path])
            Vue.delete(changes, pathError.path)
    }

    export function clearError(state: Stays, pathError: PathValue) {
        const errors = state.errors[pathError.id]
        if (errors) {
            const index = findIndex(errors, ["path", pathError.path])
            if (index !== -1)
                errors.splice(index, 1)
            if (!errors.length)
                Vue.delete(state.errors, pathError.id.toString())
        }
    }

    export function monitor(state: Stays, val: boolean) {
        state.monitor = val
    }

    export function clearAll(state: Stays) {
        state.stays = {}
        state.errors = {}
    }

    export function setUpdateURL(state: Stays, url: string) {
        if (url.indexOf('?') !== -1)
            window.alert('stay update URL contains query parameter. This will break updates. URL: ' + url)
        state.updateURL = url
    }

    export function markStaysLoading(state: Stays) {
        state.staysLoading = true
    }

    export function markStaysLoaded(state: Stays) {
        state.staysLoading = false
    }

    export function triggerLoadStays(state: Stays) {
        state.loadStays++
    }

    export function setMDTPeoplePresent(state: Stays, mdtPeoplePresent: number[]) {
        state.mdtPeoplePresent = mdtPeoplePresent
    }
}

namespace actions {
    interface ActionContext extends BareActionContext<Stays, RootState> {}

    export function pullStays(
        context: ActionContext,
        { url, params = {}, useStore = true }: { url: string, params?: {[k: string]: any}, useStore?: boolean }
    ) {
        if (useStore) {
            storeModule.mutMarkStaysLoading()
            if (context.state.updateURL != url)
                storeModule.mutSetUpdateURL(url)
        }
        return utils.request
            .get(url, params)
            .then(function(response) {
                if (useStore) {
                    storeModule.mutClearStays()
                    storeModule.mutAddStays(response.body)
                }
                return Promise.resolve(response)
            })
            .catch(err => {
                utils.handleRequestError(err)
                return Promise.resolve()
            })
            .then(res => {
                if (useStore) storeModule.mutMarkStaysLoaded()
                return Promise.resolve(res)
            })
    }

    export function pullStayFromUrl(context: ActionContext,
        { url, stay_id }: { url: string, stay_id: number }) {
        if (context.state.updateURL != url)
            storeModule.mutSetUpdateURL(url)

        return utils.request.get(`${url}${stay_id}/`)
        .then(res => {
            Vue.toasted.success("Synced latest changes from server.")
            storeModule.mutAddStay(res.body)
        })
        .catch(err => {
            utils.handleRequestError(err)
        })
    }

    export function pullStay(context: ActionContext, stay_id: number) {
        return pullStayFromUrl(context, { url: '/stay/full/', stay_id })
    }

    export function deleteStay(context: ActionContext, stay_id: number) {
        return utils.request.delete(`/stay/simple/${stay_id}/`)
                    .then(res => {
                        Vue.toasted.success("Stay deleted.")
                        storeModule.mutClearStay(stay_id)
                    })
                    .catch((err: any) => {
                        Vue.toasted.error("Error deleting stay.")
                    })
    }

    export function setPathValue(context: ActionContext, pathval: PathValue) {
        const stay = context.state.stays[pathval.id]
        let changed = false

        if (stay) {
            if (has(stay, pathval.path)) {
                if (get(stay, pathval.path) !== pathval.val) {
                    storeModule.mutPathValue(pathval)
                    changed = true
                }
            }
            else {  // this is so a fully reactive stay object is created
                // we use cloneDeep which (should) ignores getters/setters, so that these are all recreated
                // otherwise when we add paths where the parent has existing getters/setters, the new
                // paths are ignored and do not have reactivity initialised.
                const updatedStay = cloneDeep(stay)
                set(updatedStay, pathval.path, pathval.val)
                storeModule.mutReplaceStay(updatedStay)
                changed = true                
            }
        }
        if (changed)
            storeModule.mutAddChanges(pathval)

        return Promise.resolve()
    }

    export function setListedObj(context: ActionContext, payload: ListedObjectValues) {
        const stay = context.state.stays[payload.id]
        let changed = false

        if (stay && has(stay, payload.path)) {
            const array = get(stay, payload.path)

            if (!isArray(array))
                return

            const obj = find(array, [payload.id_field || 'id', payload.obj_id])

            if (!obj || (obj && !isEqualWith(obj, payload.values, sparseComparer))) {
                storeModule.mutListedObj(payload)
                changed = true
            }
        }

        if (changed)
            storeModule.mutAddListedObjChange(payload)
    }

    export function setSyndrome(context: ActionContext, payload: SyndromePayload): Promise<void> {
        const stay = context.state.stays[payload.id]
        if (stay && stay.syndrome !== payload.syndrome) {
            return storeModule.actSetPathValue({
                id: payload.id,
                path: 'syndrome',
                val: payload.syndrome })
            .then(res => {
                storeModule.mutPathValue({ id: payload.id, path: 'syndrome', val: -1})

                if (payload.syndrome) {
                    const syndrome = find(store.direct.getters.templates.allSyndromes, ['id', payload.syndrome])
                    if (syndrome) {
                        // add any default issues that are not part of current active issues                    
                        if (syndrome.issues?.length) {
                            const activeIssues = storeModule.getActiveIssues(payload.id)
                            const activeIds = map(activeIssues, issue => {
                                return issue.other ? undefined : issue.issue
                            })
                            const missingIds = filter(syndrome.issues, id => !includes(activeIds, id))
                            const data = map(missingIds, id => { return { issue: id } })
                            addInvsOrIssues(context, { id: payload.id, path: 'issues', val: data }, 'issue')
                        }
                    }
                }

                storeModule.mutUpdateAsap()
                return Promise.resolve()
            })
            .catch(err => {
                utils.handleRequestError(err)
                return Promise.resolve()
            })
        }
        return Promise.resolve()
    }

    export function setRapidAssessmentSyndrome(context: ActionContext, payload: RapidAssessmentSyndromePayload): Promise<void> {
        const stay = context.state.stays[payload.id]
        if (stay && stay.rapid_assessment_syndrome !== payload.rapid_assessment_syndrome) {
            return storeModule.actSetPathValue({
                id: payload.id,
                path: 'rapid_assessment_syndrome',
                val: payload.rapid_assessment_syndrome })
            .then(res => {
                storeModule.mutPathValue({ id: payload.id, path: 'rapid_assessment_syndrome', val: -1})
                storeModule.mutUpdateAsap()
                return Promise.resolve()
            })
            .catch(err => {
                utils.handleRequestError(err)
                return Promise.resolve()
            })
        }
        return Promise.resolve()
    }

    /** 
     * This function only updates the stay's changeset, and immediately pushes those changes to
     * the server. We do this because investigations/issues are complex objects, and it's easier
     * if we delegate that heavy lifting to the backend.
     * 
     * NOTE: Investigations and issues are different from most other arrayed objects, because
     * there can be multiple instances of the same investigation/issue. In contrast, arrayed objects
     * like features and positive-negatives have a 1-to-1 correlation with their 'base' version,
     * and so we use the base id as the instance id in those cases.
     * 
     * @param idProp the property that uniquely identifies each new investigation/issue. 
     */
    function addInvsOrIssues(context: ActionContext, pathval: PathValue, idProp: string) {
        if (!isArray(pathval.val)) {
            // TODO report this to Sentry
            console.error("Payload is incorrectly structured", pathval)
            return
        }

        const val = pathval.val as any[]
        if (val.length === 0)
            return

        const changes = context.state.changes[pathval.id]
        if (changes && has(changes, pathval.path)) {
            const sectionChanges = get(changes, pathval.path)
            const updatedChanges = cloneDeep(sectionChanges.val) as any[]

            each(val, obj => {
                const index = findIndex(updatedChanges, [idProp, obj[idProp]])
                if (index !== -1 && !has(updatedChanges[index], "id"))  {
                    // if changes matching this object exist, and those changes are not
                    // part of an already-created object, remove the current changes, so
                    // we don't falsely create multiple instances of this object
                    updatedChanges.splice(index, 1)                    
                }
                updatedChanges.push(obj)
            })
            storeModule.mutAddChanges({
                id: pathval.id,
                path: pathval.path,
                val: updatedChanges
            })
        }
        else
            storeModule.mutAddChanges(pathval)
    }

    function addInvsOrIssuesAsap(context: ActionContext, pathval: PathValue, idProp: string) {
        addInvsOrIssues(context, pathval, idProp)
        storeModule.mutUpdateAsap()
    }

    export function addInv(context: ActionContext, pathval: PathValue) {
        if (!includes(values(InvSection), pathval.path))
            return
        addInvsOrIssuesAsap(context, pathval, 'investigation')
    }

    export function addOtherInvestigation(context: ActionContext, pathval: PathValue) {
        storeModule.mutAddChanges(pathval)
        storeModule.mutUpdateAsap()
    }

    export function addIssue(context: ActionContext, pathval: PathValue) {
        addInvsOrIssuesAsap(context, pathval, 'issue')
    }

    export function addOtherIssue(context: ActionContext, pathval: PathValue) {
        storeModule.mutAddChanges(pathval)
        storeModule.mutUpdateAsap()
    }

    export function addOtherNextStep(context: ActionContext, stayID: number) {
        const stay = context.state.stays[stayID]
        if (!stay.discharge) return
        const otherNextSteps: OtherNextStep[] = get(stay.discharge, 'other_next_steps') || []
        const newStep = { title: '' }
        const newSteps = concat(otherNextSteps, [newStep])
        storeModule.mutAddChanges({ id: stayID, path: 'discharge.other_next_steps', val: newSteps })
        storeModule.mutUpdateAsap()
    }

    export function addOtherReadyWhen(context: ActionContext, stayID: number) {
        const stay = context.state.stays[stayID]
        if (!stay.discharge) return
        const otherReadyWhen: OtherReadyWhen[] = get(stay.discharge, 'other_ready_when') || []
        const newStep = { title: '' }
        const newSteps = concat(otherReadyWhen, [newStep])
        storeModule.mutAddChanges({ id: stayID, path: 'discharge.other_ready_when', val: newSteps })
        storeModule.mutUpdateAsap()
    }
}

const storeModule = {
    //getters
    get state() { return getters.stateGetter() },
    get getSpeciality() { return getters.specialityGetter() },
    get isOrthoStay() { return getters.isOrthoStayGetter() },
    get getSyndrome() { return getters.syndromeGetter() },
    get getDiagnosis() { return getters.diagnosisGetter() },
    get getAnswer() { return getters.answerGetter() },
    get getAnswerNotes() { return getters.answerNotesGetter() },
    get getQuestionGroups() { return getters.questionGroupsGetter() },
    get getInvestigations() { return getters.investigationsGetter() },
    get getAllIssues() { return getters.issuesGetter() },
    get getActiveIssues() { return getters.activeIssuesGetter() },
    get getResolvedIssues() { return getters.resolvedIssuesGetter() },
    get isThrombolysis() { return getters.isThrombolysisGetter() },
    get mdtPeoplePresent() { return getters.mdtPeoplePresentGetter() },

    //mutations
    mutAddStay: builder.commit(mutations.addStay),
    mutReplaceStay: builder.commit(mutations.replaceStay),
    mutAddStays: builder.commit(mutations.addStays),
    mutClearStay: builder.commit(mutations.clearStay),
    mutClearStays: builder.commit(mutations.clearStays),
    mutUpdateAsap: builder.commit(mutations.updateAsap),
    mutPathValue: builder.commit(mutations.pathValue),
    mutListedObj: builder.commit(mutations.listedObj),
    mutAddChanges: builder.commit(mutations.addChanges),
    mutAddListedObjChange: builder.commit(mutations.addListedObjChange),
    mutClearChanges: builder.commit(mutations.clearChanges),
    mutAddError: builder.commit(mutations.addError),
    mutClearError: builder.commit(mutations.clearError),
    mutMonitor: builder.commit(mutations.monitor),
    mutClearAll: builder.commit(mutations.clearAll),
    mutMarkStayChanged: builder.commit(mutations.markStayChanged),
    mutMarkStaySynced: builder.commit(mutations.markStaySynced),
    mutSetUpdateURL: builder.commit(mutations.setUpdateURL),
    mutMarkStaysLoading: builder.commit(mutations.markStaysLoading),
    mutMarkStaysLoaded: builder.commit(mutations.markStaysLoaded),
    mutTriggerLoadStays: builder.commit(mutations.triggerLoadStays),
    mutSetMDTPeoplePresent: builder.commit(mutations.setMDTPeoplePresent),

    //actions
    /** pull a single stay's data. always sets & pulls from provided endpoint  */
    actPullStayFromUrl: builder.dispatch(actions.pullStayFromUrl),
    /** pull a single stay's data. always sets & pulls from endpoint /stay/full/  */
    actPullStay: builder.dispatch(actions.pullStay),
    actPullStays: builder.dispatch(actions.pullStays),
    actDeleteStay: builder.dispatch(actions.deleteStay),
    actSetPathValue: builder.dispatch(actions.setPathValue),
    actSetListedObj: builder.dispatch(actions.setListedObj),
    actSetSyndrome: builder.dispatch(actions.setSyndrome),
    actSetRapidAssessmentSyndrome: builder.dispatch(actions.setRapidAssessmentSyndrome),
    actAddInv: builder.dispatch(actions.addInv),
    actAddOtherInvestigation: builder.dispatch(actions.addOtherInvestigation),
    actAddIssue: builder.dispatch(actions.addIssue),
    actAddOtherIssue: builder.dispatch(actions.addOtherIssue),
    actAddOtherNextStep: builder.dispatch(actions.addOtherNextStep),
    actAddOtherReadyWhen: builder.dispatch(actions.addOtherReadyWhen),

    // simplified actions
    extActSetPathValue(stay_id: number, path: string, val: any) {
        return this.actSetPathValue({ id: stay_id, path: path, val: val })
    },
    extActUpdateSyndrome(stay_id: number, val: number | null) {
        return this.actSetSyndrome({ id: stay_id, syndrome: val })
    },
    extActUpdateRapidAssessmentSyndrome(stay_id: number, val: number | null) {
        return this.actSetRapidAssessmentSyndrome({ id: stay_id, rapid_assessment_syndrome: val })
    },
    extActUpdateFeature(stay_id: number, feature_id: number, val: boolean | null) {
        const feature: Partial<SyndromeFeature> = {
            id: feature_id,
            value: val
        }
        return this.actSetListedObj({
            id: stay_id,
            path: 'syndrome_features',
            obj_id: feature_id,
            values: feature
        })
    },
    extActUpdatePosNeg(stay_id: number, posneg_id: number, val?: boolean | null, notes?: string) {
        const posneg: Partial<SyndromePosNeg> = { id: posneg_id }
        if (val !== undefined) posneg.value = val
        if (notes !== undefined) posneg.notes = notes

        return this.actSetListedObj({
            id: stay_id,
            path: 'syndrome_positive_negatives',
            obj_id: posneg_id,
            values: posneg
        })
    },
    extActAddInv(stay_id: number, section: InvSection, data: NewInvData | NewInvData[]) {
        this.actAddInv({ id: stay_id, path: section, val: isArray(data) ? data : [data] })
    },
    extActAddOtherInvestigation(stay_id: number, data: {title: string}) {
        this.actAddOtherInvestigation({ id: stay_id, path: 'other_investigations', val: [data] })
    },
    extActAddOtherIssue(stay_id: number, data: NewOtherIssue[]) {
        this.actAddOtherIssue({ id: stay_id, path: 'other_issues', val: data })
    },
    extActAddIssues(stay_id: number, data: NewIssue[]) {
        this.actAddIssue({ id: stay_id, path: 'issues', val: data })
    },
    extActUpdateInv(stay_id: number, inv: Investigation, section: InvSection, changes: InvChangesBase) {
        const path = inv.other ? 'other_investigations' : section
        return this.actSetListedObj({
            id: stay_id,
            path: path,
            obj_id: inv.id,
            values: assign({ id: inv.id }, changes)
        })
    },
    extActUpdateIssue(stay_id: number, changes: IssueChanges) {
        const path = changes.other ? 'other_issues' : 'issues'
        return this.actSetListedObj({
            id: stay_id,
            path,
            obj_id: changes.id,
            values: changes
        })
    },
    extActAddNihssByLabel(stay_id: number, nihss_label: number) {
        const payload: ListedObjectValues = { id: stay_id, path: 'nihss_set', obj_id: -1, values: { label: nihss_label }}
        this.mutAddListedObjChange(payload)
        this.mutUpdateAsap()
    },
    /** adds an arbitrary NIHSS record, likely to be identified by the assessed time */
    extActAddNihss(stay_id: number) {
        const payload: ListedObjectValues = { id: stay_id, path: 'nihss_set', obj_id: -1, values: { assessed_at: (new Date()).toISOString() }}
        this.mutAddListedObjChange(payload)
        this.mutUpdateAsap()
    },
    setAnswer(stay_id: number, question_id: number, answer: any) {
        const path = `_answers.q_${question_id}.answer`
        this.extActSetPathValue(stay_id, path, answer)
    },
    setAnswerNotes(stay_id: number, question_id: number, notes: string) {
        const path = `_answers.q_${question_id}.notes`
        this.extActSetPathValue(stay_id, path, notes)
    }
}

export default storeModule

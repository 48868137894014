



































import Vue from "vue"
import utils from 'utils'

export default Vue.extend({
    data() {
        return {
            form: {
                old_password: '',
                new_password: '',
                confirm_password: '',
            },
        }
    },
    methods: {
        changePassword(event: Event) {
            event.preventDefault()
            if (!this.passwordsMatch) {
                Vue.toasted.error("Passwords don't match")
                return
            }
            utils.request.post('/account/change-password/', false, this.form)
            .then(res => {
                Vue.toasted.success(res.body)
                this.$store.direct.dispatch.user.pull()
                this.$emit('success')
            })
            .catch(err => {
                if (err.status === 400 || err.status === 401)
                    Vue.toasted.error(err.response.body || 'Error updating password')
                else
                    utils.handleRequestError(err)
            })
        },
    },
    computed: {
        passwordsMatch(): Boolean | null {
            if (this.form.confirm_password.length)
                return this.form.confirm_password === this.form.new_password
            return null
        }
    }
})

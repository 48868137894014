





























import Vue from 'vue'
import utils from 'utils'
import store from 'store'
import { Speciality } from 'models/med_templates/speciality'
import Dropdown from '@shared/inputs/Dropdown.vue'

export default Vue.extend({
    components: {
        Dropdown,
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        isDeveloper(): boolean {
            return this.$store.direct.getters.user.isDeveloper
        },
        isClientUserAdmin(): boolean {
            return this.$store.direct.getters.user.isClientUserAdmin
        },
        isOnlyClientUserAdmin(): boolean {
            const userGetters = this.$store.direct.getters.user
            return userGetters.isClientUserAdmin && !this.isPowerUser && !userGetters.isTeamsEditor
        },
        canAdminTeams(): boolean {
            return this.isPowerUser || this.$store.direct.getters.user.isTeamsEditor
        },
        specialities(): Speciality[] {
            return this.$store.direct.state.templates.specialities
        },
        speciality: {
            get(): number | null {
                return this.$store.direct.state.session.adminSpeciality
            },
            set(val: number | null) {
                this.$store.direct.commit.session.setAdminSpeciality(val)
            }
        },
        userSpeciality(): number | null {
            return this.$store.direct.state.user.speciality
        },
    },
    beforeRouteEnter(to, from, next) {
        const canAdmin = (
            store.direct.getters.user.isClientUserAdmin
            || store.direct.getters.user.isTeamsEditor
            || store.direct.getters.user.isPowerUser
        )
        if (!canAdmin) next(false)
        else next()
    },
    created() {
        this.pullMetadata()
    },
    watch: {
        userSpeciality: {
            handler: function(val: any) {
                if (!this.isPowerUser)
                    this.speciality = this.userSpeciality
            },
            immediate: true
        },
    },
    methods: {
        pullMetadata() {
            utils.request.get('/uxadmin/metadata/')
            .then(res => {
                this.$store.direct.commit.uxadmin.setMetadata(res.body)
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
        },
    }
})























import Vue from 'vue'
import { filter, includes, map, xor, clone } from 'lodash-es'
import utils from 'utils'
import { Hospital } from 'models/med_templates/hospital'

const MAX_CHARS = 30

export default Vue.extend({
    data() {
        return {
            selected: [] as number[],
            selecting: false,
            /** describes whether the dropdown is opened/closed */
            opened: false,

        }
    },
    computed: {
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        hospitals(): Hospital[] {
            return this.$store.direct.state.templates.hospitals
        },
        selectedHospitals: {
            get(): number[] {
                return this.$store.direct.state.user.filters.hospitals
            },
            set(ids: number[]) {
                if (!utils.isEqual(this.selectedHospitals, ids)) {
                    this.$store.direct.dispatch.user.updateFilters({ hospitals: ids })
                    this.$emit('update')
                }
            }
        },
        selectedText(): string {
            const selected = filter(this.hospitals, d => includes(this.selected, d.id))
            const labels = map(selected, opt => opt.title)
            return labels.join(", ") || "[All]"
        },
        selectedTextShort(): string {
            if (this.selectedText.length < MAX_CHARS)
                return this.selectedText
            return `${this.selectedText.slice(0, MAX_CHARS)}...`
        }
    },
    watch: {
        selectedHospitals: {
            handler: function(val: any) {
                if (!utils.isEqual(val, this.selected))
                    this.selected = clone(val)
            },
            immediate: true
        },
        opened(val: any) {
            if (!val)
                this.selectedHospitals = this.selected
        }
    },
    methods: {
        showHandler(event: any) {
            this.opened = true
        },
        hideHandler(event: any) {
            if (this.selecting) {
                // Don't close the dropdown if we're currently selecting items
                event.preventDefault()
            } else {
                this.opened = false
            }
            this.selecting = false
        },
        clearSelections() {
            this.selected = []
        },
        toggleSelect(id: number) {
            this.selecting = true
            this.selected = xor(this.selected, [id])
        },
        isSelected(id: number) {
            return includes(this.selected, id)
        },
    },
})

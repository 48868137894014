var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group-item",
    { class: { "stay-inactive": !_vm.active } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.collapseId,
              expression: "collapseId"
            }
          ],
          staticClass: "row d-flex align-items-center",
          class: { "r-clickable": _vm.canAccessSummary }
        },
        [
          _c("div", { staticClass: "col-2 d-flex align-items-center" }, [
            _c("div", { staticClass: "d-inline-block" }, [
              _c("div", { staticClass: "pretty p-svg p-plain p-bigger" }, [
                _c("input", {
                  attrs: { type: "checkbox" },
                  domProps: { checked: _vm.inHiddenIDs(_vm.stay.id) },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.toggleHide(_vm.stay.id)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "state p-info-o" },
                  [
                    _c("fnt-a-icon", {
                      staticClass: "svg",
                      attrs: { icon: ["fal", "check"] }
                    }),
                    _vm._v(" "),
                    _c("label")
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ml-4" }, [
              _vm.showSpeciality
                ? _c("div", [_c("small", [_vm._v(_vm._s(_vm.specialityText))])])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _vm.showSite && _vm.hospitalText
                  ? _c(
                      "p",
                      { staticClass: "mb-0" },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.hospitalText) +
                            "\n                            "
                        ),
                        _vm.stay.site_only
                          ? _c("fnt-a-icon", {
                              staticClass: "svg ml-1",
                              attrs: { icon: ["fal", "hospital-symbol"] }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.showLocation || _vm.stay.site_only) && _vm.locationText
                  ? _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.locationText))
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-2" },
            [
              _vm._v("\n            " + _vm._s(_vm.patientText) + " "),
              _c("neuro-acute-care-summary", {
                staticClass: "d-inline",
                attrs: { stay_id: _vm.stay.id }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-secondary" }, [
                _vm._v(_vm._s(_vm.identifierText_c))
              ]),
              _vm._v(" "),
              _vm.showExtId
                ? _c("div", { staticClass: "text-secondary" }, [
                    _c("em", [
                      _vm._v("Visit: " + _vm._s(_vm.stay.ext_id || "-"))
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [
            _vm._v("\n            " + _vm._s(_vm.timeText) + "\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-1" },
            [
              _vm.showDoctors
                ? _c("stay-doctor", { attrs: { stay_id: _vm.stay.id } })
                : _c(
                    "span",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.doctorText) +
                          "\n                "
                      ),
                      _vm.canChangeDoctor
                        ? _c(
                            "b-btn",
                            {
                              staticClass: "scrwl-hover-show",
                              attrs: {
                                variant: "link",
                                size: "sm",
                                title: "Change doctor"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.showDoctors = true
                                }
                              }
                            },
                            [
                              _c("fnt-a-icon", {
                                staticClass: "svg text-white-50",
                                attrs: { icon: ["fas", "edit"] }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _vm.triageMode
            ? _c(
                "div",
                {
                  class: {
                    "col-2": _vm.actionsVisible,
                    "col-3": !_vm.actionsVisible
                  }
                },
                [
                  _vm.stay.rapid_assessment_diagnosis_title
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.stay.rapid_assessment_diagnosis_title)
                        )
                      ])
                    : _c("em", [_vm._v("Awaiting nurse review")])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                "col-2": _vm.triageMode && _vm.actionsVisible,
                "col-3": _vm.triageMode && !_vm.actionsVisible,
                "col-4": !_vm.triageMode
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.stay.diagnosis_title_ext || "-") +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.actionsVisible,
                  expression: "actionsVisible"
                }
              ],
              staticClass: "col-2"
            },
            [
              _c("actions", {
                attrs: { stay: _vm.stay, displayContext: _vm.displayContext },
                on: {
                  "actions-visible": function($event) {
                    _vm.actionsVisible = $event
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.canAccessSummary
            ? _c(
                "p",
                {
                  staticClass:
                    "position-absolute scrwl-btm w-100 text-center text-white-50 scrwl-hover-show mb-0"
                },
                [
                  _vm.opened
                    ? _c(
                        "em",
                        [
                          _vm._v("Close "),
                          _c("fnt-a-icon", {
                            attrs: { icon: ["fal", "angle-double-up"] }
                          })
                        ],
                        1
                      )
                    : _c(
                        "em",
                        [
                          _vm._v("Expand "),
                          _c("fnt-a-icon", {
                            attrs: { icon: ["fal", "angle-double-down"] }
                          })
                        ],
                        1
                      )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.canAccessSummary
        ? _c(
            "b-collapse",
            {
              attrs: { id: _vm.collapseId },
              on: {
                show: function($event) {
                  _vm.opened = true
                },
                hide: function($event) {
                  _vm.opened = false
                }
              }
            },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.opened
                    ? _c("stay-summary", {
                        staticClass: "mt-2",
                        attrs: { stay: _vm.stay }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
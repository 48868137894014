









import Vue from 'vue'
import { get } from 'lodash-es'

import { Stay } from 'models/data/stay'
import stays from '@store/stays'
import TextArea from '@shared/inputs/TextArea.vue'

export default Vue.extend({
    components: {
        TextArea
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        notes: {
            get(): string {
                return get(this.stay, 'diagnosis_notes') || ''
            },
            set(text: string) {
                stays.extActSetPathValue(this.stay_id, 'diagnosis_notes', text)
            }
        }
    }
})

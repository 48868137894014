var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("diagnosis-input", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _c("diagnosis-summary", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _c("diagnosis-features", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _c("diagnosis-notes", { attrs: { stay_id: _vm.stay_id } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
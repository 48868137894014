var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("dropdown", {
        staticClass: "col-6",
        attrs: {
          items: _vm.careClassificationChoices,
          label: "Care classification",
          "text-field": "label",
          "value-field": "value"
        },
        model: {
          value: _vm.careClassification,
          callback: function($$v) {
            _vm.careClassification = $$v
          },
          expression: "careClassification"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("label", { staticClass: "mb-0" }, [
          _vm._v("Care classification set")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-group" },
          [
            _c("date-time-picker", {
              attrs: {
                config: {
                  wrap: true,
                  enableTime: false,
                  altFormat: "d M Y",
                  dateFormat: "Y-m-d"
                }
              },
              model: {
                value: _vm.careClassificationDate,
                callback: function($$v) {
                  _vm.careClassificationDate = $$v
                },
                expression: "careClassificationDate"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "input-group-append" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-info",
                  attrs: { type: "button", "data-toggle": "" }
                },
                [_c("fnt-a-icon", { attrs: { icon: ["fal", "calendar"] } })],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-danger",
                  attrs: { type: "button", "data-clear": "" }
                },
                [_c("fnt-a-icon", { attrs: { icon: ["fal", "times"] } })],
                1
              )
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }







import Vue from 'vue'
import { Stay } from 'models/data/stay'
import stays from '@store/stays'
import { followUpWith } from 'utils/text/plan'
import EditModal from '../EditModal.vue'
import FollowUpDetails from '@ward/discharge/FollowUpDetails.vue'


export default Vue.extend({
    components: {
        EditModal,
        FollowUpDetails,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        summaryText(): string {
            return followUpWith({ stay: this.stay }, false, false)
        },
    },
})

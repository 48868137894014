





































import Vue from 'vue'
import * as moment from 'moment'
import * as commonmark from 'commonmark'
import { find } from 'lodash-es'
import utils from 'utils'
import { freeTextSubHtmlBreaks } from 'utils/text/helpers'
import { nihss } from 'utils/text/nihss'
import stays from '@store/stays'
import { NIHSS as NIHSS_i, NIHSS_24_72_HRS_TITLE } from 'models/data/nihss'
import { Stay } from 'models/data/stay'
import { LineItem } from 'models/summary'

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            htmlId: `${utils.getUID()}___nel_acute_care`
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        cmReader() {
            return new commonmark.Parser()
        },
        cmWriter() {
            return new commonmark.HtmlRenderer()
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        modifiedRankin(): string {
            return this.stay?.acute_care_summary?.modified_rankin || '-'
        },
        inHospital(): boolean {
            if (!this.stay || !this.stay.acute_care_summary)
                return false // doesn't actually matter
            const presentation = this.stay.acute_care_summary.present_type
            if (presentation)
                return presentation.toLowerCase().indexOf('in hospital') !== -1
            return this.telestrokeMode ? false : true
        },
        followUpNihss(): NIHSS_i | undefined {
            const labels = this.$store.direct.getters.templates.speciality(this.stay?.speciality)?.nihss_labels || []
            const followUpLabel = find(labels, label => label.title === NIHSS_24_72_HRS_TITLE)
            if (!followUpLabel)
                return
            return find(this.stay?.nihss_set || [], nihss => nihss.label === followUpLabel.id)
        },
        clinicalSummary(): LineItem[] {
            if (!this.stay || !this.stay.acute_care_summary)
                return []

            const summary = this.stay.acute_care_summary

            const consultTime = moment(summary.consult_time)
            const admMgmtNotes = this.markupOrDash(summary.adm_mgmt_notes)

            let nihssDeficits = nihss({ stay: this.stay, hideTitle: true }, { nihssKey: 'admission_nihss', heading: '', deficitsOnly: true })
            nihssDeficits = this.cmWriter.render(this.cmReader.parse(nihssDeficits))

            let followUpNihssScore_s = '-'
            if (this.followUpNihss === undefined) {
                if (summary.follow_up_nihss !== null)
                    followUpNihssScore_s = summary.follow_up_nihss
            }
            else {
                followUpNihssScore_s = `${this.followUpNihss.score}`
            }

            const info: LineItem[] = [
                ['Patient details', `${summary.name} (${summary.age}) mRS ${this.modifiedRankin}`, null],
                ['Location', summary.location, null],
                ['Doctors involved', summary.doctors_involved, null],
                ['Consult time', consultTime.isValid() ? consultTime.format('HH:mm, D MMM YYYY') : '-', null],
                ['LSW to alert', summary.lsw_to_consult, null],
                ['Presentation type', summary.present_type, null],
                ['NIHSS deficits', nihssDeficits, 'html'],
                ['Diagnosis', summary.diagnosis, null],
                ['Management notes', admMgmtNotes, 'html'],
                ['Follow up NIHSS', followUpNihssScore_s, null],
                ['Clinical follow up summary (Local follow up)', this.markupOrDash(summary.clinic_follow_up_notes), 'html'],
                ['Follow up Diagnosis notes (Telestroke doctor)', this.markupOrDash(summary.follow_up_diagnosis_notes), 'html'],
            ]

            return info
        },
        decisionSummary(): LineItem[] {
            if (!this.stay || !this.stay.acute_care_summary)
                return []

            const summary = this.stay.acute_care_summary

            const info: LineItem[] = [
                ['Clinically and/or radiologically a possible ischaemic stroke', summary.possible_isch_stroke, null],
                ['Reasons not thrombolysed', summary.reason_not_thromb, null],
                ['Summary not lysed', summary.summary_not_lysed, null],
                ['Reason not sent for ECR', summary.reason_not_ecr, null],
            ]

            return info
        },
        processSummary(): LineItem[] {
            if (!this.stay || !this.stay.acute_care_summary)
                return []

            const summary = this.stay.acute_care_summary
            let info: LineItem[]
            
            if (!this.inHospital) {
                info = [
                    ['ASAP used', summary.asap_documented, null],
                    ['Call before CT', summary.contact_before_ct, null],
                    ['Door to alert', summary.door_to_alert, null],
                    ['Door to CT', summary.door_to_ct, null],
                    ['Door to lysis decision', summary.door_to_lysis_decision, null],
                    ['Door to ECR decision', summary.door_to_ecr_decision, null],
                    ['Door to needle', summary.door_to_needle, null],
                    ['Process comments (Telestroke doctor)', this.markupOrDash(summary.process_comments_doctor), 'html'],
                    ['Process comments (Local follow up)', this.markupOrDash(summary.process_comments_site), 'html'],
                ]
            }
            else {
                info = [
                    ['ASAP used', summary.asap_documented, null],
                    ['Call before CT', summary.contact_before_ct, null],
                    ['Alert time', summary.raw_alert, null],
                    ['CT time', summary.raw_ct, null],
                    ['Lysis decision time', summary.raw_lysis_decision, null],
                    ['ECR decision time', summary.raw_ecr_decision, null],
                    ['Needle time', summary.raw_needle_time, null],
                    ['Process comments (Telestroke doctor)', this.markupOrDash(summary.process_comments_doctor), 'html'],
                    ['Process comments (Local follow up)', this.markupOrDash(summary.process_comments_site), 'html'],
                ]
            }

            return info
        },
    },
    methods: {
        markupOrDash(text: string | null): string {
            return text ? freeTextSubHtmlBreaks(text) : '-'
        },
    }
})





















import Vue from 'vue'
import utils from 'utils'
import { safeDecoding } from 'utils/store'
import stays from '@store/stays'
import { VizData, vizDataDecoder } from 'models/dataviz'

import DatesControls from '../OpsDatesControls.vue'
import DoctorsFilter from '@dashboards/DoctorsFilterIgnorePrefs.vue'
import HospitalFilter from '@home/telestroke/HospitalFilter.vue'
import HyperacuteReview from '../HyperacuteReview.vue'

export default Vue.extend({
    components: {
        DatesControls,
        DoctorsFilter,
        HospitalFilter,
        HyperacuteReview,
    },
    props: {
        start: {
            type: String,
            required: false,
        },
        end: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            loading: false,
            data: null as VizData | null,
            doctors: [] as number[],
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        hospitals(): number[] {
            return this.$store.direct.state.user.filters.hospitals
        },
        presentationTypes(): string[] {
            return this.data?.presentation_types || []
        },
    },
    watch: {
        // refetch data when the route path changes
        // (ignore query parameters which are used to store which modal is open)
        $route(newRoute, oldRoute) {
            if (oldRoute.path === newRoute.path) return 
            this.pull()
        },
        doctors() {
            this.pull()
        },
    },
    created() {
        const params = this.$route.params
        if (params.start && params.end)
            this.pull()
    },
    beforeDestroy() {
        stays.mutClearStays()
    },
    methods: {
        navigateTo(start: string, end: string) {
            this.$router
            .push({ name: this.$route.name, params: { start, end }})
            .catch(err => {
                if (err.name !== 'NavigationDuplicated')
                    utils.handleRequestError(err)
            })
        },
        pull() {
            this.loading = true
            utils
                .request
                .get(`/viz/data/${this.start}/${this.end}/`, {
                    h: this.hospitals,
                    d: this.doctors,
                })
                .then(response => {
                    this.data = safeDecoding(response.body, vizDataDecoder, 'v2/Operations.pull')
                    this.loading = false
                })
                .catch(err => {
                    utils.handleRequestError(err)
                    this.loading = false
                })
                .then(this.loadStays)
        },
        loadStays() {
            if (!this.data)
                return

            const stayIDs = this.data.all_stays
            if (!stayIDs.length) {
                stays.mutClearStays()
                return
            }

            stays.actPullStays({
                url: '/viz/stay/',
                params: { s: stayIDs }
            })
        },
    }
})

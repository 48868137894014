var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("h3", [_vm._v("\n            Speciality selection \n        ")]),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "fade" } },
          [
            _c(
              "b-card-group",
              { staticClass: "row" },
              _vm._l(_vm.specialities, function(speciality) {
                return _c(
                  "div",
                  { key: speciality.id, staticClass: "col-6 mb-4" },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "syndrome-card",
                        class: {
                          "border border-success selected": _vm.hovered(
                            speciality.id
                          )
                        },
                        attrs: {
                          "header-bg-variant": _vm.hovered(speciality.id)
                            ? "success"
                            : ""
                        },
                        on: {
                          mouseover: function($event) {
                            _vm.hovering = speciality.id
                          },
                          mouseleave: function($event) {
                            _vm.hovering = -1
                          },
                          click: function($event) {
                            _vm.selected = speciality.id
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c("span", { staticClass: "h4" }, [
                                    _vm._v(_vm._s(speciality.title))
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "card-text" },
                          [
                            _vm.specialitySyndromes(speciality.id).length
                              ? [
                                  _c(
                                    "ul",
                                    { staticClass: "pl-2" },
                                    _vm._l(
                                      _vm.specialitySyndromes(speciality.id),
                                      function(syndrome) {
                                        return _c("li", { key: syndrome.id }, [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(syndrome.title) +
                                              "\n                                    "
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              : [_c("p", [_vm._v("No syndromes")])]
                          ],
                          2
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
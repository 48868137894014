var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canPrintDocx
    ? _c("print-base", {
        attrs: {
          stay_ids: _vm.stay_ids,
          urlSuffix: "print-docx",
          labelBase: "Word",
          buttonClasses: "progress-bar-striped"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    [
      _c(
        "b-navbar",
        {
          style: { opacity: _vm.navOpacity, "z-index": _vm.navZIndex },
          attrs: {
            toggleable: "md",
            fixed: "top",
            type: "dark",
            variant: "dark"
          }
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav_collapse" } }),
          _vm._v(" "),
          _c(
            "b-navbar-brand",
            { attrs: { href: _vm.rootURL, title: _vm.version } },
            [
              _c("img", {
                attrs: {
                  id: "brand-image",
                  src: "static/images/scrawl-ribbon.png",
                  alt: _vm.documentTitle
                }
              }),
              _vm._v(
                "\n            " + _vm._s(_vm.documentTitle) + "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { "is-nav": "", id: "nav_collapse" } },
            [
              _vm.loggedIn
                ? _c(
                    "b-navbar-nav",
                    [
                      _c(
                        "b-nav-item-dd",
                        { attrs: { text: "Home" } },
                        [
                          _c("b-dd-item", { attrs: { to: { name: "home" } } }, [
                            _vm._v("Home")
                          ]),
                          _vm._v(" "),
                          _vm.isPowerUser
                            ? _c(
                                "b-dd-item",
                                {
                                  staticClass: "progress-bar-striped",
                                  attrs: { to: { name: "home-nurse" } }
                                },
                                [_vm._v("Nurse")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isTempUser && !_vm.telestrokeMode
                        ? [
                            _c(
                              "b-nav-item-dd",
                              { attrs: { text: "Dashboards" } },
                              [
                                _c(
                                  "b-dd-item",
                                  {
                                    attrs: {
                                      to: { name: "overview-dashboard" }
                                    }
                                  },
                                  [_vm._v("Overview")]
                                ),
                                _vm._v(" "),
                                _vm.neurologySelected
                                  ? [
                                      _c(
                                        "b-dd-item",
                                        {
                                          attrs: {
                                            to: { name: "stroke-dashboard" }
                                          }
                                        },
                                        [_vm._v("Stroke")]
                                      )
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.orthoSelected
                                  ? _c(
                                      "b-dd-item",
                                      {
                                        attrs: {
                                          to: { name: "ortho-dashboard" }
                                        }
                                      },
                                      [_vm._v("JMOs")]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canExportData
                        ? _c(
                            "b-nav-item",
                            { attrs: { to: { name: "data" } } },
                            [_vm._v("Data")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isTempUser
                        ? _c(
                            "b-nav-item-dd",
                            { attrs: { text: "Viz" } },
                            [
                              _c(
                                "b-dd-item",
                                { attrs: { to: { name: "viz-charts" } } },
                                [_vm._v("Charts")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dd-item",
                                { attrs: { to: { name: "viz-ops" } } },
                                [_vm._v("Operations")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("admin-menu"),
                      _vm._v(" "),
                      _vm.hasSandboxFrom
                        ? _c(
                            "b-nav-item",
                            {
                              staticClass: "bg-info progress-bar-striped",
                              attrs: { href: _vm.sandboxFrom }
                            },
                            [_vm._v("Prod")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasSandboxTo
                        ? _c(
                            "b-nav-item",
                            {
                              staticClass: "bg-info progress-bar-striped",
                              attrs: { href: _vm.sandboxTo }
                            },
                            [_vm._v("Sandbox")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasSeniorPrivileges
                        ? _c(
                            "b-nav-item",
                            {
                              staticClass: "no-break",
                              attrs: { to: { path: "/standalone" } }
                            },
                            [_vm._v("ASAP Tool")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canUseAuxSite
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                href: _vm.auxURL,
                                target: "_blank",
                                title: "Auxiliary Operations"
                              }
                            },
                            [_vm._v("Aux")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.betaEnabled && _vm.altURL
                        ? _c(
                            "b-nav-item",
                            {
                              attrs: {
                                href: _vm.altURL,
                                target: "_blank",
                                title: "Alternate UX"
                              }
                            },
                            [_vm._v("Alt UX")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loggedIn ? _c("changes-indicator") : _vm._e(),
              _vm._v(" "),
              _vm.hasSyncErrors
                ? _c("fnt-a-icon", {
                    staticClass: "svg ml-2 r-clickable",
                    attrs: {
                      icon: ["fal", "exclamation"],
                      title: "Sync errors"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.toggleSyncErrors.apply(null, arguments)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loggedIn
                ? _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      !_vm.telestrokeMode
                        ? _c("search-stays", {
                            staticClass: "mr-2",
                            attrs: { useAltText: "", showClearBtn: "" }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isPowerUser
                        ? [
                            _c("sync-control"),
                            _vm._v(" "),
                            _c("store-control"),
                            _vm._v(" "),
                            _c("choose-speciality")
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-nav-item",
                        {
                          staticClass: "bg-secondary",
                          attrs: {
                            title: "Temporarily hide the navigation bar"
                          },
                          on: { click: _vm.hideNav }
                        },
                        [_vm._v("Hide")]
                      ),
                      _vm._v(" "),
                      _c("user-menu", {
                        attrs: { helpModalId: _vm.helpModalId }
                      })
                    ],
                    2
                  )
                : _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _c(
                        "b-nav-item",
                        {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal",
                              value: _vm.helpModalId,
                              expression: "helpModalId"
                            }
                          ]
                        },
                        [_vm._v("Help")]
                      ),
                      _vm._v(" "),
                      _c("changelog", { attrs: { mainNav: true } })
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("help-modal", {
        on: {
          "support-modal-id": function($event) {
            _vm.helpModalId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }










import Vue from 'vue'
import { difference, filter, includes, intersection, map, union } from 'lodash-es'
import { Item } from 'models/base'
import FilterBase from './FilterBase.vue'


export default Vue.extend({
    components: {
        FilterBase,
    },
    data() {
        return {
            selectedIDs: [] as number[]
        }
    },
    computed: {
        allAcutesID(): number {
            return 999
        },
        allCareTypes() {
            return this.$store.direct.state.templates.choices.care_classification
        },
        acuteCareIDs(): number[] {
            return map(filter(this.allCareTypes, opt => opt.label.indexOf('Acute Care') !== -1), opt => opt.id)
        },
        careOptions(): Item[] {
            let options = [ { id: this.allAcutesID, title: '[All Acute Care]' } ]
            options = options.concat(map(
                this.allCareTypes,
                opt => ({ id: opt.id, title: opt.label })
            ))
            return options
        },
        careTypes: {
            get(): number[] {
                return this.$store.direct.state.user.filters.cares
            },
            set(careIDs: number[]) {
                const current = this.$store.direct.state.user.filters.cares

                if (includes(current, this.allAcutesID) && !includes(careIDs, this.allAcutesID)) {
                    // Acute Care [all] deselected, uncheck all acute cares
                    careIDs = filter(careIDs, id => !includes(this.acuteCareIDs, id))
                }
                else if (!includes(current, this.allAcutesID) && includes(careIDs, this.allAcutesID)) {
                    // Acute Care [all] selected, check all acute cares
                    careIDs = union(careIDs, this.acuteCareIDs)
                }

                const hasAllAcutes = intersection(careIDs, this.acuteCareIDs).length === this.acuteCareIDs.length
                if (includes(careIDs, this.allAcutesID) && !hasAllAcutes) {
                    // if not all acute cares selected, deselect Acute Care [all]
                    careIDs = filter(careIDs, id => id !== this.allAcutesID)
                }
                else if (!includes(careIDs, this.allAcutesID) && hasAllAcutes) {
                    // if all acute cares selected, ensure Acute Care [all] selected
                    careIDs.push(this.allAcutesID)
                }

                this.$store.direct.dispatch.user.updateFilters({ cares: careIDs })
                this.$emit('update')
            }
        },
        selectedText(): string | undefined {
            if (includes(this.careTypes, this.allAcutesID)) {
                const textIDs = difference(this.selectedIDs, this.acuteCareIDs)
                return map(filter(this.careOptions, opt => includes(textIDs, opt.id)), opt => opt.title).join(', ')
            }

            return undefined
        },
    },
})

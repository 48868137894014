var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "b-btn",
        {
          class: { "mb-1": _vm.add_mb },
          attrs: { size: "sm", variant: "info" },
          on: { click: _vm.getTempUser }
        },
        [_vm._v("\n        Temp User\n    ")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            "ok-only": "",
            size: "lg",
            id: _vm.modalId,
            title: "Single Stay User"
          }
        },
        [
          _c("p", [
            _vm._v("\n            Patient: "),
            _c("strong", [_vm._v(_vm._s(_vm.patientText))])
          ]),
          _vm._v(" "),
          _c(
            "h4",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tempUser.msg,
                  expression: "tempUser.msg"
                }
              ]
            },
            [_vm._v(_vm._s(_vm.tempUser.msg))]
          ),
          _vm._v(" "),
          [
            _c("b-textarea", {
              attrs: { rows: "5", readonly: "" },
              model: {
                value: _vm.tempUserText,
                callback: function($$v) {
                  _vm.tempUserText = $$v
                },
                expression: "tempUserText"
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-info btn-sm mt-2",
                attrs: { type: "button", id: _vm.copyBtnId }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.copyBtnLabel) +
                    "\n            "
                )
              ]
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group-item",
    { staticClass: "stay-details p-0", attrs: { role: "tab" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.collapseId,
              expression: "collapseId"
            }
          ],
          staticClass:
            "d-flex justify-content-between align-items-center r-clickable px-4 py-2"
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.patientSummary))]),
          _vm._v(" "),
          _c("fnt-a-icon", {
            staticClass: "show-more",
            attrs: { icon: ["fas", "chevron-down"] }
          }),
          _vm._v(" "),
          _c("fnt-a-icon", {
            staticClass: "show-less",
            attrs: { icon: ["fas", "chevron-up"] }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "pt-2",
          attrs: { id: _vm.collapseId, role: "tabpanel" },
          on: { show: _vm.loadOnShow }
        },
        [
          _vm.fullStay
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between bg-dark pt-2 px-2"
                },
                [
                  _vm.field.showCurrentMeds
                    ? _c("current-medications", {
                        attrs: { stay_id: _vm.stay.id, showEditButton: false }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.field.hideTextSummary
                    ? _c("stay-text-summary", {
                        staticStyle: { "flex-grow": "1" },
                        attrs: {
                          textVersion: _vm.textVersion,
                          stay_id: _vm.stay.id
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "b-btn",
                        {
                          attrs: { size: "sm", variant: "info" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.editStay.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("\n                    Edit\n                ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
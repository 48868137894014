var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("text-area", {
    attrs: { label: "Long answer", "non-unique-id": "discharge-notes" },
    model: {
      value: _vm.dischargeNotes,
      callback: function($$v) {
        _vm.dischargeNotes = $$v
      },
      expression: "dischargeNotes"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { get } from 'lodash-es'
import { freeTextSubHtmlBreaks } from './helpers'
import { genericQuestionsText } from './question'
import { TextDetails } from './interfaces'
import { QuestionCategory } from "models/med_templates/question"
import store from 'store'
import stays from '@store/stays'


/**
 * Returns HTML for a stay's diagnosis features.
 */
export function diagnosisFeatures({ stay }: TextDetails, dashboard?: boolean): string {
    if (!stay) return ""

    return genericQuestionsText({
        stay,
        category: QuestionCategory.DIAGNOSIS_FEATURE,
        getQuestionGroupsFrom: stays.getDiagnosis(stay.id),
    }, dashboard)
}

/**
 * Returns HTML.
 * This is generated from the list of checkboxes and *Additional Notes* under Working Diagnosis
 */
export function diagnosisSummary({ stay }: TextDetails): string {
    if (!stay) return ""

    let summaryLines: string[] = []
    const featuresText = diagnosisFeatures({ stay })
    if (featuresText.length) summaryLines.push(featuresText)

    const notes = diagnosisAddNotes({ stay })
    if (notes.length) summaryLines.push(notes)

    return summaryLines.join('<br>')
}

/**
 * Returns HTML-ready text for a stay's *Key Points* notes.
 * Note: this is only available in older stays. New stays can no longer enter this data
 */
export function diagnosisKeyPoints({ stay }: TextDetails): string {
    if (stay && stay.diagnosis_summary)
        return freeTextSubHtmlBreaks(stay.diagnosis_summary)
    return ''
}

export function diagnosisAddNotes({ stay }: TextDetails): string {
    if (stay && stay.diagnosis_notes)
        return freeTextSubHtmlBreaks(stay.diagnosis_notes)
    return ''
}

/**
 * 
 * @param all if true, will also display the text from checkboxes and *Additional Notes*
 * @param boldTitle
 */
export function diagnosisWithSummaries({ stay }: TextDetails, all: boolean = true, boldTitle: boolean = false): string {
    if (!stay) return ''

    const diagnosisTitle = stay.diagnosis_title_ext || '-'

    let titleText = boldTitle ? `**${diagnosisTitle}**` : diagnosisTitle

    const oneLineSummaryText = diagnosisKeyPoints({ stay })
    if (oneLineSummaryText)
        titleText += `: ${oneLineSummaryText}`

    if (all) {
        const summary = diagnosisSummary({ stay })
        return `${titleText}  \n${summary}`
    }

    return titleText
}

export function oneLineRapidAsmtDiagnosisSummary({ stay }: TextDetails): string {
    if (!stay) return ""
    if (stay.rapid_assessment_diagnosis_summary) {
        return freeTextSubHtmlBreaks(stay.rapid_assessment_diagnosis_summary) + "  \n"
    }
    return ""
}

export function primaryDiagnosis({ stay, hideTitle }: TextDetails): string {
    if (!stay) return ""

    const diagnosis = get(stay, 'diagnosis_title_ext')
    if (!diagnosis) return ""

    let text = `${diagnosis || "unknown"}`
    if (!hideTitle) {
        text = '**Primary diagnosis:** ' + text
    }
    return text
}

export function primaryDiagnosisWithKeyPoints({ stay }: TextDetails): string {
    // Returns text in the form:
    // "Primary diagnosis: $DIAGNOSIS_TITLE: $KEY_POINTS"
    if (!stay) return ""

    let diagnosisText = primaryDiagnosis({ stay })
    if (!diagnosisText) return ""

    const oneLineSummary = diagnosisKeyPoints({ stay })
    if (oneLineSummary.length) {
        diagnosisText += `: ${oneLineSummary}`
    }
    return diagnosisText + "\n\n"
}


export function diagnosis({ stay }: TextDetails): string {
    if (!stay) return ""

    let lines: string[] = []
    lines.push(`**Working diagnosis - ${stay.diagnosis_title_ext || "unknown"}**`)

    if (stay.management_plan) {
        const managementPlan = stay.management_plan
        if (managementPlan.summary) {
            lines.push(`${freeTextSubHtmlBreaks(managementPlan.summary)}`)
        }

        if (managementPlan.neurologist) {
            const doctor = store.direct.getters.templates.doctor(managementPlan.neurologist)
            if (doctor) {
                const name = `${doctor.last_name}, ${doctor.first_name}`
                lines.push(`  \nAdmit under ${name}`)
            }
        }
    }

    if (lines.length) {
        return lines.join("  \n") + "\n\n"
    }
    return ""
}

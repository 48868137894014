import { find, forEach, get, map } from 'lodash-es'

import { freeTextSubHtmlBreaks } from './helpers'
import { diagnosisWithSummaries } from './diagnosis'
import { genericQuestionsText } from './question'

import { Issue, OtherIssue } from 'models/data/issue'
import { Stay } from 'models/data/stay'
import { QuestionCategory } from 'models/med_templates/question'
import { TextDetails } from './interfaces'

import stays from '@store/stays'
import store from 'store'

interface issueFullTextParams {
    stay: Stay
    issue: Issue | OtherIssue
    ignoreStacked?: boolean
}
export function issueFullText({ stay, issue, ignoreStacked }: issueFullTextParams): string {
    let lines: string[] = []

    const dbIssue = find(store.direct.getters.templates.allIssues, ["id", issue.issue])

    // Get title of all selected results (if any)
    if (dbIssue) {
        const text = genericQuestionsText({
            stay,
            category: QuestionCategory.ISSUE_RESULT,
            getQuestionGroupsFrom: dbIssue,
            answers: issue.answers,
            ignoreStacked,
        })
        if (text.length) lines.push(text)
    }

    // Add free text summary
    lines.push(freeTextSubHtmlBreaks(issue.summary || ""))

    return lines.join("<br/>")
}

/**
 * Takes a list of issues and generates the appropriate summary text
 * incuding checked IssueResults and any notes
 * @param issues 
 */
function _issuesWithSummary({ stay, issues }: { stay: Stay, issues: (Issue | OtherIssue)[] }): {title: string, summary: string}[] {
    return map(issues, issue => ({
        title: issue.title,
        summary: issueFullText({ stay, issue })
    }))
}

function activeIssuesWithSummary({stay}: TextDetails): {title: string, summary: string}[] {
    if (!stay) return []

    const activeIssues = stays.getActiveIssues(stay.id)
    return _issuesWithSummary({ stay, issues: activeIssues })
}

export function resolvedIssuesWithSummary({stay}: TextDetails): {title: string, summary: string}[] {
    if (!stay) return []

    const resolvedIssues = stays.getResolvedIssues(stay.id)
    return _issuesWithSummary({ stay, issues: resolvedIssues })
}

export function activeIssuesSummary({ stay, hideTitle, title }: TextDetails): string {
    if (!stay) return ""

    const activeIssues = activeIssuesWithSummary({ stay })

    if (!activeIssues.length) return ""

    let lines: string[] = []

    // Active issues
    if (activeIssues.length && !hideTitle) {
        title = title || "Issues"
        lines.push(`**${title}**\n\n`)
    }

    forEach(activeIssues, (entry, index) => {
        lines.push(
            `${index + 1}\\. ${entry.title}  \n${entry.summary}\n`
        )
    })

    if (!lines.length) return ""
    return lines.join("\n") + "\n\n"
}

export function resolvedIssuesList({ stay }: TextDetails, inline: boolean): string {
    if (!stay) return ''

    const resolvedIssues = stays.getResolvedIssues(stay.id)
    if (!resolvedIssues.length) return ''

    const heading = `\n\n**Resolved Issues**${ inline ? ':' : '\n' }`
    const headingSep = inline ? ' ' : '  \n'
    const titleSep = inline ? ' / ' : '  \n'

    const lines = [heading]
    const titles = map(resolvedIssues, issue => issue.title)
    const titlesLine = titles.join(titleSep)
    lines.push(titlesLine)

    return lines.join(headingSep) + "\n\n"
}

export function resolvedIssuesWithDetail({ stay, hideTitle, title }: TextDetails): string {
    if (!stay) return ""

    const resolvedIssues = resolvedIssuesWithSummary({ stay })

    if (!resolvedIssues.length) return ""

    let lines: string[] = []

    // resolved issues
    if (resolvedIssues.length && !hideTitle) {
        title = title  || "Resolved Issues"
        lines.push(`**${title}**  \n`)
    }

    forEach(resolvedIssues, (entry, index) => {
        lines.push(
            `${entry.title}  \n${entry.summary}\n`
        )
    })

    if (!lines.length) return ""
    return lines.join("\n") + "\n\n"
}

export function otherIssues({ stay }: TextDetails): string {
    const text = get(stay, "other_issues_text")
    if (!text) return ""
    
    return "**Other issues**<br/>" + text + "\n\n"
}

export function wardIssues({ stay }: TextDetails): string {
    if (!stay) return ""

    let lines: string[] = ["**Issues**\n\n"]

    const diagnosis = diagnosisWithSummaries({ stay })
    if (diagnosis)
        lines.push(`1\\. ${diagnosis}\n`)

    // Get all other issues
    const issueEntries = activeIssuesWithSummary({ stay })

    if (!diagnosis && !issueEntries.length)
        return ""

    forEach(issueEntries, entry => {
        lines.push(
            `${lines.length}\\. ${entry.title}  \n${entry.summary}\n`
        )
    })

    const resolvedIssues = resolvedIssuesList({ stay }, true)
    if (resolvedIssues.length)
        lines.push(`\n\n${resolvedIssues}`)

    return lines.join("\n") + "\n\n"
}

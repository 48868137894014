var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-btn",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: _vm.htmlID,
              expression: "htmlID"
            }
          ],
          attrs: { variant: "info" }
        },
        [_vm._v("MRC Grading")]
      ),
      _vm._v(" "),
      _c("b-modal", { attrs: { id: _vm.htmlID, title: "Coming soon" } }, [
        _c("div", { staticClass: "card-body" })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }










import Vue from 'vue'

import Baselines from './Baselines.vue'
import StandardInvestigationsList from './StandardInvestigationsList.vue'

export default Vue.extend({
    components: {
        Baselines,
        StandardInvestigationsList,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
})

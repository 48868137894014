var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-btn",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: _vm.attendingHtmlId,
              expression: "attendingHtmlId"
            }
          ],
          attrs: { variant: "info" }
        },
        [_vm._v("Edit Attending")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.attendingHtmlId,
            size: "lg",
            title: "Attending",
            "ok-only": "",
            "ok-title": "Close"
          }
        },
        [_c("team", { attrs: { stay_id: _vm.stay_id, section: _vm.section } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
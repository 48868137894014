

































import Vue from 'vue'
import { findIndex, includes, map } from 'lodash-es'
import * as moment from 'moment'
import utils from 'utils'
import { DiagHistory } from 'models/data/history'
import { Patient, anonymisedPatient } from 'models/data/patient'


interface TimelineEvent {
    id: string
    dt: moment.Moment
    dt_s: string
    changes: {
        title: string
        desc: string
    }[]
}

const fieldLabels: {[k:string]: string} = {
    'rapid_assessment_diagnosis_id': 'Rapid Assessment Diagnosis',
    'rapid_assessment_other_diagnosis_selected': 'Rapid Assessment Diagnosis (Other)',
    'rapid_assessment_other_diagnosis_title': 'Rapid Assessment Diagnosis (Other)',
    'diagnosis_id': 'Diagnosis',
    'other_diagnosis_selected': 'Diagnosis (Other)',
    'other_diagnosis_title': 'Diagnosis (Other)',
}

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        embedded: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            diagnosisHistory: null as DiagHistory | null,
        }
    },
    computed: {
        anonymise(): boolean {
            return this.$store.direct.state.user.anonymise
        },
        columnClasses(): string {
            return this.embedded ? 'col' : 'col-md-6 offset-md-3'
        },
        patient(): Patient | null {
            if (this.diagnosisHistory)
                return this.anonymise ? anonymisedPatient(this.diagnosisHistory.patient) : this.diagnosisHistory.patient
            return null
        },
        timelineEvents(): TimelineEvent[] {
            if (!this.diagnosisHistory) return []

            const dtFormat = 'ddd D MMM YYYY HH:mm:ss'
            let timeline: TimelineEvent[] = []

            const admitted = this.diagnosisHistory.stay.admission_time != null
            const startTitle = `Stay ${admitted ? 'Admitted' : 'Created'}`
            const startDT = admitted ? this.diagnosisHistory.stay.admission_time! : this.diagnosisHistory.stay.created_at
            const startMoment = moment(startDT)
            const startEvent: TimelineEvent = {
                id: 's_1',
                dt: startMoment,
                dt_s: moment(startDT).format(dtFormat),
                changes: [
                    { title: startTitle, desc: ''}
                ]
            }
            timeline.push(startEvent)

            const events = map(this.diagnosisHistory.history, record => {
                const changes = map(record.changes, change => {
                    let label = fieldLabels[change.field] || change.field
                    const descLines: string[] = []

                    if (includes(['rapid_assessment_other_diagnosis_selected', 'other_diagnosis_selected'], change.field)) {
                        let prefix = ''
                        if (change.to === false)
                            prefix = 'Disabled '
                        else if (change.to === true)
                            prefix = 'Enabled '
                        label = `${prefix}${label}`
                    }
                    else {
                        if (change.from === null)
                            label = `Set ${label}`
                        else if (change.from && change.to)
                            label = `Updated ${label}`

                        if (change.from !== null)
                            descLines.push(`<em>From:</em> ${change.from_title || change.from}`)

                        if (change.to !== null) {
                            const prefix = change.from === null ? '' : '<em>To:</em> '
                            descLines.push(`${prefix}${change.to_title || change.to}`)
                        }
                    }
                    return {
                        title: label,
                        desc: descLines.join('<br/>'),
                    }
                })

                const dt = moment(record.history_date)

                const diffDuration = moment.duration(dt.diff(startMoment))
                let diffLines: string[] = []
                const diffDays = Math.floor(diffDuration.asDays())
                if (diffDays > 0)
                    diffLines.push(`${diffDays} day${this.pluralSuffix(diffDays)}`)
                const diffDurationNoDays = diffDuration.subtract(diffDays, 'days')
                const diffHrs = diffDurationNoDays.hours()
                const diffMins = diffDurationNoDays.minutes()
                diffLines.push(`${diffHrs} hr${this.pluralSuffix(diffHrs)} ${diffMins} min${this.pluralSuffix(diffMins)}`)
                const diffDesc = diffLines.join(' ')

                return {
                    id: `h_${record.history_id}`,
                    dt,
                    dt_s: `${moment(record.history_date).format(dtFormat)} [+${diffDesc}]`,
                    changes,
                }
            })
            timeline = timeline.concat(events)

            const sixHrsLater = moment(startDT).add(6, 'hours')
            const sixHrsAfterStart: TimelineEvent = {
                id: 's_2',
                dt: sixHrsLater,
                dt_s: sixHrsLater.format(dtFormat),
                changes: [
                    { title: `Six Hours After ${admitted ? 'Admission' : 'Creation'}`, desc: ''}
                ]
            }

            let i = findIndex(timeline, event => event.dt.isSameOrAfter(sixHrsLater))
            if (i === -1)
                i = timeline.length  // put at the end of the list
            timeline.splice(i, 0, sixHrsAfterStart)

            return timeline
        },
    },
    created() {
        utils.request.get(`/stay/audit_trail/diagnosis/${this.stay_id}/`)
        .then(res => {
            this.diagnosisHistory = res.body
        })
        .catch(utils.handleRequestError)
    },
    methods: {
        pluralSuffix(num: number) {
            return num === 1 ? '' : 's'
        },
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay && _vm.stay.acute_care_summary
    ? _c("div", [
        _c("h5", [_vm._v("Clinical Summary")]),
        _vm._v(" "),
        _c("table", { staticClass: "table" }, [
          _c(
            "tbody",
            _vm._l(_vm.clinicalSummary, function(item, index) {
              return _c("tr", { key: index }, [
                _c("td", { staticClass: "w-40 text-right" }, [
                  _vm._v(_vm._s(item[0]))
                ]),
                _vm._v(" "),
                item[2] === "html"
                  ? _c("td", { domProps: { innerHTML: _vm._s(item[1]) } })
                  : _c("td", [_vm._v(_vm._s(item[1]))])
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("h5", [_vm._v("Decision Process")]),
        _vm._v(" "),
        _c("table", { staticClass: "table" }, [
          _c(
            "tbody",
            _vm._l(_vm.decisionSummary, function(item, index) {
              return _c("tr", { key: index }, [
                _c("td", { staticClass: "w-40 text-right" }, [
                  _vm._v(_vm._s(item[0]))
                ]),
                _vm._v(" "),
                item[2] === "html"
                  ? _c("td", { domProps: { innerHTML: _vm._s(item[1]) } })
                  : _c("td", [_vm._v(_vm._s(item[1]))])
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("h5", [_vm._v("Process Summary")]),
        _vm._v(" "),
        _c("table", { staticClass: "table" }, [
          _c(
            "tbody",
            _vm._l(_vm.processSummary, function(item, index) {
              return _c("tr", { key: index }, [
                _c("td", { staticClass: "w-40 text-right" }, [
                  _vm._v(_vm._s(item[0]))
                ]),
                _vm._v(" "),
                item[2] === "html"
                  ? _c("td", { domProps: { innerHTML: _vm._s(item[1]) } })
                  : _c("td", [_vm._v(_vm._s(item[1]))])
              ])
            }),
            0
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
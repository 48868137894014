var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-hover" }, [
    _c("tbody", [
      _c("tr", [
        _c("td", { staticClass: "text-right w-50" }, [
          _c(
            "span",
            {
              staticClass: "r-clickable",
              on: {
                click: function($event) {
                  _vm.useAltLabel = !_vm.useAltLabel
                }
              }
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        ]),
        _vm._v(" "),
        _c(
          "td",
          { staticClass: "w-50" },
          [
            _vm._v(
              "\n                " +
                _vm._s(_vm.summaryText) +
                "\n                "
            ),
            _vm.hasStays
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal",
                        value: _vm.modalId,
                        expression: "modalId"
                      }
                    ],
                    staticClass: "r-clickable ml-2",
                    attrs: { title: "Show stay details" }
                  },
                  [
                    _c("fnt-a-icon", {
                      attrs: { icon: ["fal", "info-circle"] }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.modalId,
                  size: "lg",
                  title: "Reviewed by nurse",
                  "ok-only": ""
                }
              },
              [
                _c(
                  "b-list-group",
                  { attrs: { role: "tablist" } },
                  _vm._l(_vm.stays, function(stay) {
                    return _c(
                      "b-list-group-item",
                      { key: stay.id, attrs: { role: "tab" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center"
                          },
                          [
                            _c("span", [_vm._v(_vm._s(stay.label))]),
                            _vm._v(" "),
                            _c(
                              "span",
                              [
                                _vm._v(
                                  "\n                                    Assessed: " +
                                    _vm._s(stay.assessed ? "Yes" : "No") +
                                    "\n                                    "
                                ),
                                _c(
                                  "b-btn",
                                  {
                                    staticClass: "ml-1",
                                    attrs: { variant: "info", size: "sm" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setSyndromeAndEdit(stay.id)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        Nurse review\n                                    "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        [
          _c(
            "b-dd-item",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.htmlId,
                  expression: "htmlId"
                }
              ]
            },
            [_vm._v("Signature")]
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: { title: "User Signature", id: _vm.htmlId, "ok-only": "" }
            },
            [
              _c("h5", [_vm._v("Instructions")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("When the "),
                _c("em", [_vm._v("Append signature")]),
                _vm._v(
                  " box is ticked, your signature will be automatically appended to EMR notes you export."
                )
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Signature")]),
              _vm._v(" "),
              _c("text-area", {
                staticClass: "text-monospace light-ph",
                attrs: {
                  id: _vm.inputHtmlId,
                  placeholder: _vm.placeholderText,
                  rows: 3
                },
                model: {
                  value: _vm.signature,
                  callback: function($$v) {
                    _vm.signature = $$v
                  },
                  expression: "signature"
                }
              }),
              _vm._v(" "),
              _c(
                "b-tooltip",
                { attrs: { target: _vm.inputHtmlId, placement: "right" } },
                [
                  _c("p", { staticClass: "text-info text-left" }, [
                    _vm._v(
                      "\n                If desired, your signature can be formatted as follows:"
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("ul", { staticStyle: { "padding-left": "1rem" } }, [
                      _c("li", [
                        _c("em", [_vm._v("italics")]),
                        _vm._v(" - wrap with asterisks - eg "),
                        _c("span", { staticClass: "text-monospace" }, [
                          _vm._v("*Name*")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("strong", [_vm._v("bold")]),
                        _vm._v(" - wrap with double asterisks - eg "),
                        _c("span", { staticClass: "text-monospace" }, [
                          _vm._v("**Name**")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "line break - add a backslash at the end of the line - eg "
                        ),
                        _c("span", { staticClass: "text-monospace" }, [
                          _vm._v("Name\\")
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-info text-left" }, [
                    _vm._v("\n                Full example:"),
                    _c("br"),
                    _vm._v("\n                Signature"),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-monospace" }, [
                      _vm._v("*LastName*, FirstName\\"),
                      _c("br"),
                      _vm._v("**Provider#** 234567")
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v("\n                Preview"),
                    _c("br"),
                    _vm._v(" "),
                    _c("em", [_vm._v("LastName")]),
                    _vm._v(", FirstName"),
                    _c("br"),
                    _c("strong", [_vm._v("Provider#")]),
                    _vm._v(" 234567\n            ")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("b-btn", { attrs: { variant: "info", size: "sm" } }, [
                _vm._v("Preview")
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "border rounded pt-3 px-3 my-3",
                domProps: { innerHTML: _vm._s(_vm.markedUp) }
              }),
              _vm._v(" "),
              _c("checkbox", {
                attrs: { title: "Append signature", cb_value: true },
                model: {
                  value: _vm.appendSig,
                  callback: function($$v) {
                    _vm.appendSig = $$v
                  },
                  expression: "appendSig"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }










import Vue from 'vue'

export default Vue.extend({
    computed: {
        telestrokeMode: {
            get() {
                return this.$store.direct.state.user.telestroke_mode
            },
            set(val: boolean) {
                this.$store.direct.dispatch.user.toggleTSMode()
            }
        }
    }
})

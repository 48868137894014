
import Vue from 'vue'
import { max, toInteger } from 'lodash-es'
import { Chart, ChartData, ChartOptions } from 'chart.js'

interface updateChartOpts {
    /** the ref label for the target canvas */
    title: string
    data: ChartData
    options: ChartOptions
    chartType: string
}

export default Vue.extend({
    data() {
        return {
            charts: {} as {[k: string]: Chart}
        }        
    },
    methods: {
        updateChart({title, data, options, chartType}: updateChartOpts): void {
            // Try and get the chart object stored in this.charts
            let chart = this.charts[title]
            if (!chart) {
                // If it doesn't exist we try and create it.
                // If there's no matching $ref we ignore it
                // (this happens e.g. for a chart shown only in telestroke mode when we're in non-telestroke mode)
                if (!this.$refs[title]) return

                // Create the new Chart and store it in this.charts
                this.charts[title] = new Chart(this.$refs[title] as HTMLCanvasElement, { type: chartType })
                chart = this.charts[title]
            }
            chart.data = data
            chart.options = options
            chart.update()
        },
        removeChart(refName: string) {
            const chart = this.charts[refName]
            if (chart) {
                chart.destroy()
                delete this.charts[refName]
            }
        },
        calcChartStepMax(dataset: number[]) {
            const sections = 4
            const maxVal = max(dataset) || 11
            let yMax = maxVal + 1
            while (yMax % sections != 0) yMax++
            const stepSize = toInteger(yMax / sections)
            return { stepSize, yMax }
        },
    }
})

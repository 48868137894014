
































import Vue from 'vue'
import mixins from 'vue-typed-mixins'
import { cloneDeep } from 'lodash-es'
import utils from 'utils'

import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { Location } from 'models/data/location'
import InputMixin from '@mixins/Input.vue'

export default mixins(InputMixin).extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            editing: false,
            location: {
                ward: '',
                room: '',
                bed: ''
            } as Location
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        debugText(): string {
            return JSON.stringify(this.location, null, 2)
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        stayLocation(): Location | null {
            return this.stay ? this.stay.location : null
        }
    },
    watch: {
        'stay.location': {
            deep: true,
            immediate: true,
            handler: function(val: any, old: any) {
                if (this.stayLocation) {
                    if (this.stayLocation.ward !== this.location.ward
                        || this.stayLocation.room !== this.location.room
                        || this.stayLocation.bed !== this.location.bed) {
                        Vue.set(this, 'location', cloneDeep(this.stayLocation))
                    }
                }
            }
        },
        editing(val: boolean) {
            if (!val) {
                if (!utils.isEqual(this.stayLocation, this.location))
                    this.setFieldVal(this.stay_id, 'location', cloneDeep(this.location))
            }
        }
        /*
        'location': {
            deep: true,
            handler: function(val: any, old: any) {
                if (this.stayLocation) {
                    if (this.stayLocation.ward !== this.location.ward
                        || this.stayLocation.room !== this.location.room
                        || this.stayLocation.bed !== this.location.bed) {
                        this.setFieldVal(this.stay_id, 'location', cloneDeep(this.location))
                    }
                }
                else {
                    this.setFieldVal(this.stay_id, 'location', cloneDeep(this.location))
                }
            }
        }
        */
    },
    methods: {
        cancelEdit() {
            Vue.set(this, 'location', cloneDeep(this.stayLocation))
            this.editing = false
        },
    }
})

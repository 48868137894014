var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    [
      _c(
        "b-btn",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: _vm.dischargeId,
              expression: "dischargeId"
            }
          ],
          class: { "mb-1": _vm.add_mb },
          attrs: {
            size: "sm",
            variant: _vm.stay.active ? "danger" : "success"
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [_c("span", [_vm._v(_vm._s(_vm.actionLabel))])]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.dischargeId,
            centered: "",
            "hide-header": "",
            "ok-title": "Yes",
            "ok-variant": _vm.stay.active ? "danger" : "success"
          },
          on: {
            ok: function($event) {
              return _vm.toggleActiveStatus(_vm.stay)
            }
          }
        },
        [
          _c("p", { staticClass: "my-3" }, [
            _vm._v(_vm._s(_vm.actionLabel) + " "),
            _c("strong", [_vm._v(_vm._s(_vm.patientText))]),
            _vm._v("?")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue"
import { Store } from "vuex"
import { SESH_changesMaxAge, SESH_syncMonitorInterval } from '../constants'
import { RootState } from ".."
import { isEmpty, each, flatMap, values, map, set, some, includes, get, findIndex, keys, isArray, isPlainObject, isString, isObject } from "lodash-es"
import * as moment from "moment"
import * as Sentry from '@sentry/vue'

import utils from 'utils'
import stays from "../modules/stays"

import { Stay } from 'models/data/stay'
import { PathValue } from "models/payload"

function pathErrors(stay_id: number, obj: any, parentKey?: string): PathValue[] {
    if (isPlainObject(obj)) {
        return flatMap<any, PathValue>(obj, function(val, key) {
            const path = parentKey ? `${parentKey}.${key}` : key
            if (isPlainObject(val)) {
                return pathErrors(stay_id, val, path)
            }
            const valIsArray = isArray(val)
            let parsedVal: any = val
            if (valIsArray && val.length > 0) {
                const valArray: any[] = val
                if (isString(valArray[0]))
                    parsedVal = valArray.join(' ')
                else
                    parsedVal = map(valArray, (val_, index) => pathErrors(index, val_))
            }
            return { id: stay_id, path: path, val: parsedVal }
        })
    }
    return []
}

export default function syncStays(store: Store<RootState>): any {
    let changesMaxAge = SESH_changesMaxAge
    let monitorInterval = SESH_syncMonitorInterval

    let timer: number
    let syncing = false
    let asap = false

    const arrayedObjSections = [
        "data_capture_steps",
        "features",
        "investigations",
        "baseline_investigations",
        "issues",
        'nihss_set',
    ]

    function afterPatchHandler(stay_id: number, patchTime?: Date) {
        return (res: any) => {
            if (patchTime)
                stays.mutClearChanges({ stay_id: stay_id, at: patchTime })

            const stay: Stay = res.body
            const localStay = stays.state.stays[stay.id]

            if (localStay) {
                const pendingChanges = stays.state.changes[stay_id]
                
                if (!isEmpty(pendingChanges)) {
                    Vue.toasted.info("Pending changes exist...")

                    each(pendingChanges, function(change, path) {

                        // sync arrayed objects sections
                        if (includes(arrayedObjSections, path)) {
                            const section = get(stay, path)
                            for (let data of change.val) {
                                const i = findIndex(section, ['id', get(data, 'id')])
                                if (i !== -1)
                                    for (let key of keys(data))
                                        Vue.set(section[i], key, get(data, key))
                            }
                        }
                        else
                            set(stay, path, change.val)
                    })
                }

                Vue.toasted.success("Synced.")
                console.log(`stay ${stay.id} updated with latest server data`)

                if (localStay.stage !== stay.stage)
                    stays.mutTriggerLoadStays()

                stays.mutReplaceStay(stay)
            }

            return Promise.resolve(true)
        }
    }

    function monitor() {
        if (timer) return

        timer = window.setInterval(() => {
            if (syncing)
                return

            if (isEmpty(store.state.stays.changes))
                return

            // There are changes so mark the stay as changed
            stays.mutMarkStayChanged()

            const changes = flatMap(store.state.stays.changes, changes => values(changes))

            if (isEmpty(changes))
                return

            const now = moment()
            let oldest = moment.max()
            let latest = moment.min()

            each(changes, (change) => {
                const changedAt = moment(change.at)

                if (oldest > changedAt)
                    oldest = changedAt

                if (latest < changedAt)
                    latest = changedAt
            })

            if (!asap && oldest.isAfter(now.subtract(changesMaxAge, "ms")))
                return

            if (asap)
                asap = !asap
            syncing = true

            const latestDT = latest.toDate()

            const requests = map(store.state.stays.changes, (changes, k) => {
                const stay_id = parseInt(k)

                const data: { [k: string]: any } = {}
                each(changes, function(change, path) {
                    set(data, path, change.val)
                })
    
                const dataJSON = JSON.stringify(data)
                console.log("syncWithServer: ", data, dataJSON)
    
                const updateURL = stays.state.updateURL
                const url = `${updateURL}${stay_id}/`

                return utils.request
                .patch(url, false, data)
                .then(afterPatchHandler(stay_id, latestDT))
                .catch(err => {
                    let clearAllChanges = true

                    if (err.status === 403) {
                        Vue.toasted.error('Record is read-only, server not updated.')
                    }
                    else if (err.status === 401) {
                        Vue.toasted.error("You must be logged in to update a record.", { duration: 5000 })
                    }
                    else {
                        if (err.status === 400) {
                            if (err.response.body) {
                                const errors = pathErrors(stay_id, err.response.body)
                                console.log("syncWithServer/error", err.response.body, errors)

                                if (some(errors, err => {
                                    const isMRN = err.path === 'patient.mrn'
                                    if (!isMRN)
                                        return false
                                    const errVal: string = err.val
                                    const mrnDuplicated = errVal.indexOf("patient with this medical record number already exists.") > -1
                                    return isMRN && mrnDuplicated
                                })) {
                                    store.direct.commit.session.setErrorMsg("Cannot use that MRN, since it is assigned to another patient record. Please contact support to have this manually resolved.")
                                }

                                for (let error of errors) {
                                    stays.mutAddError(error)

                                    Vue.toasted.error(`Sync error - ${error.path}: ${error.val}`, { duration: 10000 })
                                    const advError = {
                                        at: moment().toISOString(true),
                                        id: error.id,
                                        path: error.path,
                                        val: get(data, error.path) || null,
                                        msg: error.val,
                                    }
                                    store.direct.commit.session.addSyncError(JSON.stringify(advError, null, 2))

                                    const advErrorSentried = {
                                        at: advError.at,
                                        instance: window.scrawl.instance,
                                        id: advError.id,
                                        path: advError.path,
                                        val: (isObject(advError.val) || isArray(advError.val)) ? JSON.stringify(advError.val) : advError.val,
                                        msg: isString(advError.msg) ? advError.msg : JSON.stringify(advError.msg),
                                    }
                                    Sentry.withScope(function(scope) {
                                        scope.setExtra('err_info', advErrorSentried)
                                        Sentry.captureMessage(`${err.status}: ${advErrorSentried.msg}`)
                                    })
                                }

                                if (errors.length > 0) {
                                    clearAllChanges = false
                                    stays.mutClearChanges({ stay_id, at: latestDT, paths: map(errors, pErr => pErr.path) })    
                                }
                            }
                        }
                        else {
                            Vue.toasted.error("Sync error :(")
                            console.error("syncWithServer/error", err)

                            // Send data keys and status to Sentry
                            Sentry.withScope(function(scope) {
                                try { scope.setExtra('data', JSON.stringify(data)) } catch(e) { scope.setExtra('data', data) }

                                if (err.status) {
                                    scope.setExtra("status", err.status)
                                    if (err.response?.body)
                                        try { scope.setExtra("response", JSON.stringify(err.response.body)) } catch(e) { scope.setExtra("response", err.response.body) }
                                    Sentry.captureMessage(`${window.scrawl.instance}: stay ${stay_id}: ${err.status}`)
                                }
                                else {
                                    try { scope.setExtra('err', JSON.stringify(err)) } catch(e) { scope.setExtra('err', err) }
                                    Sentry.captureMessage(`${window.scrawl.instance}: stay ${stay_id}: sync error`)
                                }
                            })
                        }
                    }

                    if (clearAllChanges)
                        stays.mutClearChanges({ stay_id, at: latestDT })

                    return Promise.resolve(true)
                })
            })

            Promise.all(requests)
            .then(function() {
                if (store.direct.state.user.debug_mode)
                    Vue.toasted.info("Sync unlocked")
                stays.mutMarkStaySynced()
                syncing = false
                return Promise.resolve(true)
            })

        }, monitorInterval)
    }

    function unmonitor() {
        if (timer) {
            window.clearInterval(timer)
            timer = 0
        }
    }

    monitor()

    store.subscribe((mutation, state) => {
        switch(mutation.type) {
            case "stays/updateAsap":
                Vue.toasted.show("Syncing now.")
                asap = true
                break
            case "stays/monitor":
                mutation.payload ? monitor() : unmonitor()
                break
            case "session/changesMaxAge":
                changesMaxAge = mutation.payload
                break
            case "session/syncMonitorInterval":
                monitorInterval = mutation.payload
                if (timer) {
                    unmonitor()
                    setTimeout(() => {
                        monitor()                        
                    }, 0)
                }
                break
            default:
                break
        }
    })
}

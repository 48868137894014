import { find, get } from "lodash-es"
import * as moment from "moment"
import { fieldText, freeTextSubHtmlBreaks } from './helpers'
import { patientDetails, patientDetailsWithHospital } from './patient'
import { presentingComplaint } from './clinic_history'
import {
    visualFields,
    visualInattention,
    nihss,
} from './nihss'
import {
    baselineInvestigations,
    admissionInvestigationsSummary,
    completedInvestigationsSummary,
    newInvestigationResults,
    outstandingInvestigations,
} from './investigation'
import {
    activeIssuesSummary,
    otherIssues,
    resolvedIssuesList,
    resolvedIssuesWithDetail,
} from './issue'
import {
    diagnosis,
    primaryDiagnosis,
    primaryDiagnosisWithKeyPoints,
} from './diagnosis'
import { managementPlan, RAMgmtPlan } from './mgmtPlan'

import { TextDetails } from './interfaces'

import store from 'store'


const text = {
    freeTextSubHtmlBreaks,
    fieldText,

    // patient details
    patientDetails,
    patientDetailsWithHospital,

    // clinic history
    presentingComplaint,

    // NIHSS
    visualFields,
    visualInattention,
    nihss,

    // investigations
    baselineInvestigations,
    admissionInvestigationsSummary,
    completedInvestigationsSummary,
    outstandingInvestigations,
    newInvestigationResults,

    // issues
    activeIssuesSummary,
    otherIssues,
    resolvedIssuesList,
    resolvedIssuesWithDetail,

    // diagnosis
    diagnosis,
    primaryDiagnosis,
    primaryDiagnosisWithKeyPoints,

    thrombolysis({stay, hideTitle}: TextDetails): string {
        if (!(stay && stay.diagnosis))
            return ""

        const diagnosisInfo = find(store.direct.getters.templates.allDiagnoses, { id: stay.diagnosis })
        if (!(diagnosisInfo && diagnosisInfo.is_thrombolysis && stay.thrombolysis)) return ""

        const bolus = stay.thrombolysis.bolus
        const bolus_over_60_minutes = stay.thrombolysis.bolus_over_60_minutes

        if (!(bolus && bolus_over_60_minutes)) return ""

        const bolus_time = stay.thrombolysis.bolus_time
        const time = moment(bolus_time)
        let dateStr = ""
        if (time.isValid()) {
            dateStr = time.format("h:mma [on] Do MMM YYYY")
        } else {
            dateStr =  `${bolus_time}`
        }

        const text = `IV tPA dose: bolus ${Number(bolus)}mg given at ${dateStr} followed by ${Number(bolus_over_60_minutes)}mg infusion over 60 mins`
        if (hideTitle) {
            return text + "\n\n"
        }
        return `\n**Thrombolysis:** \\\n ${text} \n\n`
    },

    managementPlan,
    RAMgmtPlan,

    careClassification({stay, hideTitle}: TextDetails): string {
        if (!(stay && stay.care_classification)) return ""

        const schema = store.direct.state.stays_v2.fullSchema
        if (!schema) return ""

        const careClassification = stay.care_classification
        const careClassificationSchema = schema.care_classification.children.classification.choices

        const careClassificationObject = find(careClassificationSchema, ["value", careClassification.classification])
        const careClassificationLabel = get(careClassificationObject, "display_name", "?")

        const careClassificationDate = careClassification.date
        let formattedDate = "?"

        if (careClassificationDate) {
            const date = moment(careClassificationDate)
            if (date.isValid()) {
                formattedDate = date.format("Do MMM YYYY")
            }
        }

        const careText = `${careClassificationLabel} (set: ${formattedDate})<br/>`

        if (hideTitle) return careText
        return `Care Classification: ${careText}`
    },

    /** Post-Op Day text */
    podText({ stay, hideTitle }: TextDetails): string {
        if (!stay) return ''

        let text = ''

        if (stay.surgery_pod !== null)
            text = `${stay.surgery_pod}`
        else {
            const schema = store.direct.state.stays_v2.fullSchema
            if (schema) {
                const choice = find(schema.surgery_stage.choices, choice => choice.value === stay.surgery_stage)
                if (choice)
                    text = choice.display_name
            }    
        }

        if (!text) text = '-'
        if (!hideTitle) text = `POD: ${text}`
        return text
    },
}

export default text

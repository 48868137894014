var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _vm.hasPlan
        ? _c(
            "div",
            { staticClass: "col" },
            [
              _vm._l(_vm.managementPlan, function(section, index) {
                return [
                  _c("div", { key: "title_" + index }, [
                    _c("span", { staticClass: "h5" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(section.title) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    section.link
                      ? _c("span", [
                          _vm._v("\n                        ["),
                          _c(
                            "a",
                            {
                              staticClass: "text-info",
                              attrs: { href: section.link, target: "_blank" }
                            },
                            [_vm._v("Link")]
                          ),
                          _vm._v("]\n                    ")
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  section.help_text
                    ? _c(
                        "p",
                        {
                          key: "help_text_" + index,
                          staticClass: "help-text",
                          staticStyle: { "margin-top": "-0.2rem" }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(section.help_text) +
                              "\n                "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { key: "steps_" + index },
                    [
                      _vm._l(section.steps, function(step) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !step.hide || _vm.isSelected(step.id),
                                expression: "!step.hide || isSelected(step.id)"
                              }
                            ],
                            key: "sec_" + index + "_step_" + step.id,
                            staticClass: "my-2"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pretty p-svg p-plain p-bigger mr-0"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedSteps,
                                      expression: "selectedSteps"
                                    }
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value: step.id,
                                    checked: Array.isArray(_vm.selectedSteps)
                                      ? _vm._i(_vm.selectedSteps, step.id) > -1
                                      : _vm.selectedSteps
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.selectedSteps,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = step.id,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.selectedSteps = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.selectedSteps = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.selectedSteps = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "state p-info-o" },
                                  [
                                    _c("fnt-a-icon", {
                                      staticClass: "svg",
                                      attrs: { icon: ["fal", "check"] }
                                    }),
                                    _vm._v(" "),
                                    _c("label", [_vm._v(_vm._s(step.title))])
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            step.link
                              ? [
                                  _vm._v("\n                            ["),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-info",
                                      attrs: {
                                        target: "_blank",
                                        href: step.link
                                      }
                                    },
                                    [_vm._v("Link")]
                                  ),
                                  _vm._v("]\n                        ")
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            step.help_text
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "ml-4 help-text",
                                    staticStyle: {
                                      "margin-top": "-0.2rem",
                                      opacity: "0.7"
                                    }
                                  },
                                  [
                                    _c("p", { staticClass: "ml-1" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(step.help_text) +
                                          "\n                            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      _vm._v(" "),
                      _c("hr")
                    ],
                    2
                  )
                ]
              })
            ],
            2
          )
        : _c("div", { staticClass: "col" }, [_vm._m(0)])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("em", [_vm._v("No management plan provided.")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
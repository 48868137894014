var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("follow-up-details", {
    staticClass: "mt-4",
    attrs: { stay_id: _vm.stay_id }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-50 mx-auto mt-4" },
    [
      [
        _vm._m(0),
        _vm._v(" "),
        _c("ul", [
          _vm._m(1),
          _vm._v(" "),
          _vm.isPowerUser
            ? _c("li", [
                _vm._v("Exports tagged "),
                _c("em", [_vm._v("unstable")]),
                _vm._v(
                  " have columns that are subject to change with little to no notice."
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._l(_vm.specialities, function(speciality) {
          return _c(
            "b-card",
            {
              key: speciality.id + "-full",
              staticClass: "mt-2",
              attrs: { title: speciality.title }
            },
            [
              _c(
                "b-list-group",
                { staticClass: "mt-2" },
                [
                  _vm._l(_vm.ranges, function(info) {
                    return _c(
                      "b-list-group-item",
                      {
                        key: info.title,
                        staticClass:
                          "d-flex align-items-center justify-content-between"
                      },
                      [
                        _c("span", [_vm._v(_vm._s(info.title))]),
                        _vm._v(" "),
                        _vm.telestrokeMode
                          ? _c(
                              "div",
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      variant: "info",
                                      size: "sm",
                                      href: _vm.specialityDataUrl(
                                        speciality.id,
                                        info.start,
                                        info.end
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            CSV\n                        "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      variant: "warning",
                                      size: "sm",
                                      href: _vm.specialityDataUrlPII(
                                        speciality.id,
                                        info.start,
                                        info.end
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            CSV ["
                                    ),
                                    _c("em", [_vm._v("incl. PII")]),
                                    _vm._v("]\n                        ")
                                  ]
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      variant: "info",
                                      size: "sm",
                                      href: _vm.specialityDataUrlFull(
                                        speciality.id,
                                        info.start,
                                        info.end
                                      )
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            CSV\n                        "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "b-list-group-item",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _c("span", [
                        _vm._v("ALL "),
                        _c("em", [
                          _vm._v("(this may take a while to generate)")
                        ])
                      ]),
                      _vm._v(" "),
                      _vm.telestrokeMode
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: {
                                    variant: "info",
                                    size: "sm",
                                    href: _vm.specialityDataUrlAll(
                                      speciality.id
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            CSV\n                        "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-btn",
                                {
                                  attrs: {
                                    variant: "warning",
                                    size: "sm",
                                    href: _vm.specialityDataUrlAllPII(
                                      speciality.id
                                    )
                                  }
                                },
                                [
                                  _vm._v("\n                            CSV ["),
                                  _c("em", [_vm._v("incl. PII")]),
                                  _vm._v("]\n                        ")
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: {
                                    variant: "info",
                                    size: "sm",
                                    href: _vm.specialityDataUrlAllFull(
                                      speciality.id
                                    )
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                            CSV\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                    ]
                  )
                ],
                2
              )
            ],
            1
          )
        }),
        _vm._v(" "),
        _c("hr")
      ],
      _vm._v(" "),
      _c(
        "b-list-group",
        [
          _c(
            "b-list-group-item",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: _vm.syndromesHtmlID,
                  expression: "syndromesHtmlID"
                }
              ],
              staticClass:
                "d-flex align-items-center justify-content-between r-clickable"
            },
            [
              _c("span", [
                _vm._v("Exports by syndrome "),
                _c("em", [_vm._v("(deprecated)")])
              ]),
              _vm._v(" "),
              _vm.syndromesVisible
                ? _c("fnt-a-icon", { attrs: { icon: ["fas", "chevron-down"] } })
                : _c("fnt-a-icon", { attrs: { icon: ["fas", "chevron-up"] } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { id: _vm.syndromesHtmlID },
          model: {
            value: _vm.syndromesVisible,
            callback: function($$v) {
              _vm.syndromesVisible = $$v
            },
            expression: "syndromesVisible"
          }
        },
        _vm._l(_vm.specialities, function(speciality) {
          return _c(
            "b-card",
            {
              key: speciality.id + "-stays",
              staticClass: "mt-2",
              attrs: { title: speciality.title }
            },
            [
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("h5", [_vm._v("Rapid Assessment Syndromes")]),
                  _vm._v(" "),
                  !speciality.rapid_asmt_syndromes.length
                    ? _c("p", [_vm._v("None")])
                    : _c(
                        "b-list-group",
                        _vm._l(speciality.rapid_asmt_syndromes, function(
                          syndrome
                        ) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: syndrome.id,
                              staticClass:
                                "d-flex align-items-center justify-content-between"
                            },
                            [
                              _c("span", [_vm._v(_vm._s(syndrome.title))]),
                              _vm._v(" "),
                              _c(
                                "b-btn",
                                {
                                  attrs: {
                                    variant: "warning",
                                    size: "sm",
                                    href: _vm.csvLink(syndrome.id)
                                  }
                                },
                                [
                                  _vm._v("\n                            CSV ["),
                                  _c("em", [_vm._v("incl. PII")]),
                                  _vm._v("]\n                        ")
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("h5", [_vm._v("Syndromes")]),
                  _vm._v(" "),
                  !speciality.syndromes.length
                    ? _c("p", [_vm._v("None")])
                    : _c(
                        "b-list-group",
                        _vm._l(speciality.syndromes, function(syndrome) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: syndrome.id,
                              staticClass:
                                "d-flex align-items-center justify-content-between"
                            },
                            [
                              _c("span", [_vm._v(_vm._s(syndrome.title))]),
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        variant: "warning",
                                        size: "sm",
                                        href: _vm.csvLink(syndrome.id)
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                CSV v1 ["
                                      ),
                                      _c("em", [_vm._v("incl. PII")]),
                                      _vm._v("]\n                            ")
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        variant: "warning",
                                        size: "sm",
                                        href: _vm.csvLink_v2(syndrome.id)
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                CSV v2 ["
                                      ),
                                      _c("em", [_vm._v("incl. PII")]),
                                      _vm._v("]\n                            ")
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        1
                      )
                ],
                1
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.showAsapExport
        ? [
            _c("hr"),
            _vm._v(" "),
            _c(
              "b-list-group",
              [
                _c(
                  "b-list-group-item",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-between"
                  },
                  [
                    _c("span", [_vm._v("ASAP Assessments")]),
                    _vm._v(" "),
                    _c(
                      "b-btn",
                      {
                        attrs: {
                          variant: "info",
                          size: "sm",
                          href: _vm.asapCSVUrl
                        }
                      },
                      [_vm._v("\n                    CSV\n                ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showABFExport
        ? [
            _c("hr"),
            _vm._v(" "),
            _c("p", { staticClass: "p-2" }, [
              _vm._v("XLSX files containing stays ABF data")
            ]),
            _vm._v(" "),
            _vm._l(_vm.specialities, function(speciality) {
              return _c(
                "b-card",
                {
                  key: speciality.id + "-abf",
                  staticClass: "mt-2",
                  attrs: { title: speciality.title }
                },
                [
                  _c(
                    "b-list-group",
                    { staticClass: "mt-2" },
                    _vm._l(_vm.ranges, function(info) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: info.title,
                          staticClass:
                            "d-flex align-items-center justify-content-between"
                        },
                        [
                          _c("span", [_vm._v(_vm._s(info.title))]),
                          _vm._v(" "),
                          _c(
                            "b-btn",
                            {
                              attrs: {
                                variant: "warning",
                                size: "sm",
                                href: _vm.abfDataUrl(
                                  speciality.id,
                                  info.start,
                                  info.end
                                )
                              }
                            },
                            [
                              _vm._v("\n                        XLSX ["),
                              _c("em", [_vm._v("incl. PII")]),
                              _vm._v("]\n                    ")
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mb-5" })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "p-2 mb-0" }, [
      _vm._v(
        "\n            CSV files containing stays data for each speciality\n            "
      ),
      _c("br"),
      _vm._v(" "),
      _c("strong", [_vm._v("Notes:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("Exports have additional labelling indicating whether "),
      _c("em", [_vm._v("Personally Identifiable Information (PII)")]),
      _vm._v(" is explicitly included.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dd-item",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: _vm.htmlId,
              expression: "htmlId"
            }
          ]
        },
        [_vm._v("Change Password")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            title: "Change password",
            id: _vm.htmlId,
            "no-close-on-backdrop": _vm.passwordExpired,
            "no-close-on-esc": _vm.passwordExpired,
            "hide-header-close": _vm.passwordExpired,
            lazy: "",
            "hide-footer": ""
          }
        },
        [
          _vm.passwordExpired
            ? _c("p", [
                _c("em", [
                  _vm._v(
                    "Your password has expired. Please set a new one to continue."
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("change-password", { on: { success: _vm.closeModal } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
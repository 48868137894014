import { sample } from 'lodash-es'
import {
    string as dstring, boolean as dboolean, number as dnumber, iso8601,
    null_,
    nullable, array as darray, either4, constant, exact, guard
} from 'decoders'
import { Stage, stageDef } from './_enums'

export const AdmissionState_ = ['UNKNOWN', 'ADMITTED', 'MANUAL', 'IGNORED']

export type AdmissionState = 'UNKNOWN' | 'ADMITTED' | 'MANUAL' | 'IGNORED'

export const admissionState = either4(
    constant('UNKNOWN' as 'UNKNOWN'),
    constant('ADMITTED' as 'ADMITTED'),
    constant('MANUAL' as 'MANUAL'),
    constant('IGNORED' as 'IGNORED')
)


export interface Patient {
    id: number
    mrn: string
    first_name: string
    last_name: string
    dob: string | null
}

export interface StayBase {
    id: number
    ext_id: string
    ext_ids: string[]
    active: boolean
    admission_time: string | null
    discharge_time: string | null
    speciality_title: string | null
}

export interface PaddockStay extends StayBase {
    admit_state: AdmissionState
    first_run: boolean
    s_stay: number | null
    speciality_code: string | null
}

export interface PatientStayBase extends StayBase {
    created_at: string
    updated_at: string
    speciality: number | null
    sp_auto_gen: boolean | null
    stage: Stage
}

export interface PrimaryStay extends PatientStayBase {
    primary: null
}

export interface PatientStay extends PatientStayBase {
    primary: PrimaryStay | null
}

export interface Combined {
    pstay: PaddockStay | null
    sstay: PatientStay | null
    admitted_at: string
    /** encounter class */
    enc: string
}

export interface PatientData {
    patient: Patient
    stays: Combined[]
}

export interface PatientDataExt extends PatientData {
    olds: Combined[]
    news: Combined[]
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz'
const alphabetU = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export function anonymisedPatient(p: Patient): Patient {
    return {
        id: p.id,
        mrn: `${Math.floor(Math.random() * 1000000)}x`,
        first_name: sample(alphabet) || '?',
        last_name: sample(alphabetU) || '?',
        dob: '?'
    }
}

const patient = exact({
    id: dnumber,
    mrn: dstring,
    first_name: dstring,
    last_name: dstring,
    dob: nullable(dstring),
})

const paddockStay = exact({
    id: dnumber,
    ext_id: dstring,
    ext_ids: darray(dstring),
    active: dboolean,
    admit_state: admissionState,
    admission_time: nullable(dstring),
    discharge_time: nullable(dstring),
    first_run: dboolean,
    s_stay: nullable(dnumber),
    speciality_code: nullable(dstring),
    speciality_title: nullable(dstring),
})

const patientStayBaseDef = {
    id: dnumber,
    ext_id: dstring,
    ext_ids: darray(dstring),
    active: dboolean,
    created_at: dstring,
    updated_at: dstring,
    admission_time: nullable(dstring),
    discharge_time: nullable(dstring),
    speciality: nullable(dnumber),
    speciality_title: nullable(dstring),
    sp_auto_gen: nullable(dboolean),
    stage: stageDef,
}

const primaryStay = exact({
    ...patientStayBaseDef,
    primary: null_,
})

const patientStay = exact({
    ...patientStayBaseDef,
    primary: nullable(primaryStay),
})

const patientData = exact({
    patient,
    stays: darray(exact({
        pstay: nullable(paddockStay),
        sstay: nullable(patientStay),
        admitted_at: dstring,
        enc: dstring,
    }))
})

export const patientDataDecoder = guard(darray(patientData), { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _patient: Patient = guard(patient)({}) } catch(e) {}
try { const _patientStay: PatientStay = guard(patientStay)({}) } catch(e) {}
try { const _paddockStay: PaddockStay = guard(paddockStay)({}) } catch(e) {}
try { const _patientData: PatientData[] = patientDataDecoder({}) } catch(e) {}

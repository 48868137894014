var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          staticClass: "mb-0",
          attrs: {
            label: "Right internal carotid artery",
            "label-cols-sm": "4",
            "label-class": "pt-0"
          }
        },
        _vm._l(_vm.fieldIds.right_artery, function(fieldId) {
          return _c(
            "b-check",
            {
              key: fieldId,
              staticClass: "scrawl-checkbox",
              attrs: {
                size: "lg",
                value: _vm.fieldToIdMap[fieldId],
                "unchecked-value": null
              },
              model: {
                value: _vm.selection.right_artery,
                callback: function($$v) {
                  _vm.$set(_vm.selection, "right_artery", $$v)
                },
                expression: "selection.right_artery"
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.fieldToTitleMap[fieldId]) +
                  "\n        "
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          staticClass: "mt-3 mb-0",
          attrs: {
            label: "Left internal carotid artery",
            "label-cols-sm": "4",
            "label-class": "pt-0"
          }
        },
        _vm._l(_vm.fieldIds.left_artery, function(fieldId) {
          return _c(
            "b-check",
            {
              key: fieldId,
              staticClass: "scrawl-checkbox",
              attrs: {
                size: "lg",
                value: _vm.fieldToIdMap[fieldId],
                "unchecked-value": null
              },
              model: {
                value: _vm.selection.left_artery,
                callback: function($$v) {
                  _vm.$set(_vm.selection, "left_artery", $$v)
                },
                expression: "selection.left_artery"
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.fieldToTitleMap[fieldId]) +
                  "\n        "
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          staticClass: "mt-3 mb-0",
          attrs: {
            label: "Intracranial Occlusion",
            "label-cols-sm": "4",
            "label-class": "pt-0"
          }
        },
        _vm._l(_vm.fieldIds.intra_occ, function(fieldId) {
          return _c(
            "b-check",
            {
              key: fieldId,
              staticClass: "scrawl-checkbox",
              attrs: {
                size: "lg",
                value: _vm.fieldToIdMap[fieldId],
                "unchecked-value": null
              },
              model: {
                value: _vm.selection.intra_occ,
                callback: function($$v) {
                  _vm.$set(_vm.selection, "intra_occ", $$v)
                },
                expression: "selection.intra_occ"
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.fieldToTitleMap[fieldId]) +
                  "\n        "
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.isIntraOcclusion
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Side",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.fieldIds.side, function(fieldId) {
                  return _c(
                    "b-check",
                    {
                      key: fieldId,
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: _vm.fieldToIdMap[fieldId],
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.selection.side,
                        callback: function($$v) {
                          _vm.$set(_vm.selection, "side", $$v)
                        },
                        expression: "selection.side"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.fieldToTitleMap[fieldId]) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.isIntraOcclusion
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Site",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.fieldIds.sites, function(fieldId) {
                  return _c(
                    "b-check",
                    {
                      key: fieldId,
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: _vm.fieldToIdMap[fieldId],
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.selection.sites,
                        callback: function($$v) {
                          _vm.$set(_vm.selection, "sites", $$v)
                        },
                        expression: "selection.sites"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.fieldToTitleMap[fieldId]) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Additional findings" } },
        [
          _c("b-form-textarea", {
            attrs: { rows: "3" },
            model: {
              value: _vm.notes,
              callback: function($$v) {
                _vm.notes = $$v
              },
              expression: "notes"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { map } from 'lodash-es'
import { number as dnumber, string as dstring, jsonObject, exact, oneOf, constant, either3, guard } from 'decoders'
import { enumKeys } from 'models/_helpers'

export enum Outcome {
    INCOMPLETE = 'incomplete',
    NORMAL = 'normal',
    HYPERACUTE = 'hyperacute'
}

export const Outcome_keys = enumKeys(Outcome)
export const Outcome_values = map(Outcome_keys, k => Outcome[k])

export const Outcome_labels: { [x in Outcome]: string } = {
    'incomplete': 'Incomplete',
    'normal': 'Not for hyperacute review',
    'hyperacute': 'Hyperacute'
}

// export type Reason = 'unknown' | 'demo_train' | 'care'

export enum Reason {
    unknown = 'unknown',
    care = 'care',
    demo_train = 'demo_train',
}

export const Reason_labels: { [x in Reason]: string } = {
    unknown: 'Unknown',
    demo_train: 'Demonstration & education purposes',
    care: 'Patient assessment'
}

export const Reason_keys = enumKeys(Reason)
export const Reason_values = map(Reason_keys, k => Reason[k])

export interface Assessment {
    id: number
    uid: string
    data: object
    outcome: Outcome
    reason: Reason
    site: string
    meta: object
}

const reasonDef = either3(
    constant('unknown' as 'unknown'),
    constant('demo_train' as 'demo_train'),
    constant('care' as 'care')
)

const assessmentDef = exact({
    id: dnumber,
    uid: dstring,
    data: jsonObject,
    outcome: oneOf(Outcome_values),
    reason: oneOf(Reason_values),
    site: dstring,
    meta: jsonObject,
})

export const assessmentDecoder = guard(assessmentDef, { style: 'simple' })

try { const _a: Assessment = assessmentDecoder({}) } catch(e) {}

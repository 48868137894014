
import Vue from 'vue'
import { some, includes, isArray } from 'lodash-es'

export default Vue.extend({
    computed: {
        showSurgery(): boolean {
            const specialities = this.$store.direct.getters.user.stayFilters.specialities || []
            const allSpecialities = this.$store.direct.state.templates.specialities
            if (specialities === 'all')
                return some(allSpecialities, sp => sp.has_surgery)
            if (isArray(specialities))
                return some(allSpecialities, sp => (includes(specialities, sp.id) && sp.has_surgery))
            return false
        },
    }
})






























import mixins from 'vue-typed-mixins'
import { isFinite } from 'lodash-es'
import StayMixin from './mixins/Stay.vue'

import Diagnosis from '@inputs/diagnosis/Diagnosis.vue'
import RapidAsmtDiagnosis from '@inputs/RapidAsmtDiagnosis.vue'
import Doctor from '@stayinputs/Doctor.vue'
import LocationInput from '@inputs/Location.vue'
import StandardInvestigationsList from '@sections/investigations/StandardInvestigationsList.vue'

export default mixins(StayMixin).extend({
    components: {
        Diagnosis,
        Doctor,
        LocationInput,
        RapidAsmtDiagnosis,
        StandardInvestigationsList,
    },
    computed: {
        isConsult(): boolean {
            return !!this.stay.primary
        },
        isNurseUser(): boolean {
            return this.$store.direct.getters.user.isNurseUser
        },
        canChangeDoctor(): boolean {
            return this.stay.doctor === null || isFinite(this.stay.doctor)
        },
    }
})







































































































import Vue from 'vue'
import { findIndex, forEach, map, reduce, round, } from 'lodash-es'
import { getABFScore } from 'utils/abf'
import { locationClass } from '@dashboards/utils'
import { BvTableFieldArray } from 'bootstrap-vue'

import { Stay } from 'models/data/stay'
import { Location as LocationModel } from 'models/data/location'

import Discharge from '@ward/Discharge.vue'
import MdtPresent from '@shared/stay-inputs/MDTPresent.vue'
import MdtQuestions from 'components/data-entry/views/MDTQuestions.vue'
import MdtText from '@text/MDT.vue'

import AcuteCareStatus from './columns/AcuteCareStatus.vue'
import AimingFor from './columns/AimingFor.vue'
import DiagnosisAndSummary from './columns/DiagnosisAndSummary.vue'
import FollowUp from './columns/FollowUp.vue'
import Investigations from './columns/Investigations.vue'
import Issues from './columns/Issues.vue'
import Location from './columns/Location.vue'
import NeuroSummary from './columns/NeuroSummary.vue'
import NextSteps from './columns/NextSteps.vue'
import Patient from './columns/Patient.vue'
import PrintNotes from './columns/AddPrintNotes.vue'
import ReadyWhen from './columns/ReadyWhen.vue'
import SocialHistory from './columns/SocialHistory.vue'
import SurgeryTime from './columns/SurgeryTime.vue'

import TableBase from './TableBase.vue'


interface StayProxy {
    id: number
    _showDetails: boolean
    days_in_stroke_unit?: number
    good?: boolean | null
    length_of_stay: number
}

interface ScopedVar<T> {
    index: number
    item: T
    detailsShowing: boolean
    toggleDetails: () => void
    rowSelected: boolean
}

export default Vue.extend({
    components: {
        AcuteCareStatus,
        AimingFor,
        DiagnosisAndSummary,
        Discharge,
        FollowUp,
        Investigations,
        Issues,
        Location,
        MdtPresent,
        MdtQuestions,
        MdtText,
        NeuroSummary,
        NextSteps,
        Patient,
        PrintNotes,
        ReadyWhen,
        SocialHistory,
        SurgeryTime,
        TableBase,
    },
    props: {
        stays: {
            type: Array as () => Stay[]
        },
        sortBy: {
            type: String
        },
        showSurgery: {
            type: Boolean,
            default: false
        },
        showDaysInSU: {
            type: Boolean,
            default: false
        },
        /** if true, this will attempt to use an opaque set of rules to set the location display as good/bad */
        highlightLocation: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            expandedStayID: false as number | false,
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        pauseOverview(): boolean {
            return this.$store.direct.state.session.pauseOverview
        },
        onlyNeuro(): boolean {
            return this.$store.direct.getters.user.onlyNeuro
        },
        canMDT(): boolean {
            return this.$store.direct.state.user.filters.specialities.length === 1
        },
        fields(): BvTableFieldArray {
            const vm = this
            const fields: BvTableFieldArray = [
                {
                    key: 'location',
                    tdClass: function(value: any, key: string, stay: any): string | undefined {
                        // We apply the class here to ensure it's set on the `td` element
                        // rather than the `div` within the `td`.
                        return vm.highlightLocation ?  locationClass(stay) : undefined
                    },
                    formatter: (location: LocationModel) => {
                        if (location) return `${location.ward} - ${location.bed}`
                        return null
                    },
                    sortable: true,
                    sortByFormatted: true,
                },
                {
                    key: 'patient'
                },
                {
                    key: 'los_abf',
                    sortable: true
                },
                {
                    key: 'days_in_stroke_unit',
                    label: 'Days in SU'
                },
                {
                    key: 'diagnosis',
                    label: 'Diagnosis'
                },
                {
                    key: 'surgery',
                    label: 'Surgery POD'
                },
                {
                    key: 'neuro_summary',
                    label: 'Stroke Summary'
                },
                {
                    key: 'issues',
                    label: 'Other Issues'
                },
                {
                    key: 'social'
                },
                {
                    key: 'investigations_outstanding'
                },
                {
                    key: 'next_steps',
                    label: 'Next steps to progress discharge'
                },
                {
                    key: 'aiming_for'
                },
                {
                    key: 'required_for_discharge',
                    label: 'Discharge once',
                },
                {
                    key: 'follow_up_plan'
                },
                {
                    key: 'care_classification'
                },
                {
                    key: 'print_notes',
                    label: 'Add. Notes'
                }
            ]

            const propColumnDict = [
                { flag: this.showSurgery, key: 'surgery' },
                { flag: this.showDaysInSU, key: 'days_in_stroke_unit' },
                { flag: this.$store.direct.getters.user.onlyNeuro, key: 'neuro_summary' },
            ]

            forEach(propColumnDict, info => {
                if (!info.flag) {
                    const index = findIndex(fields, ['key', info.key])
                    if (index > -1)
                        fields.splice(index, 1)
                }
            })

            return fields
        },
        stayProxies(): StayProxy[] {
            // Add a `_showDetails` field to every stay, set to `false` if the stay's details aren't visible.
            // We need the stay objects to have this value so we can hide all open details rows later.
            // If the `_showDetails` field doesn't exist on the object already, the reactivity doesn't work.
            const proxies = map(this.stays, stay => ({
                id: stay.id,
                _showDetails: stay.id === this.expandedStayID,
                good: stay.good,
                days_in_stroke_unit: stay.days_in_stroke_unit,
                length_of_stay: stay.length_of_stay,
            }))
            return proxies
        },
        staysAcuteDays() {
            const days: { [index: number]: number | null } = {}
            forEach(this.stays, stay => {
                days[stay.id] = stay.care_days?.acute || null
            })
            return days
        },
        showABF(): boolean {
            return this.$store.direct.state.session.ux_abf
        },
        staysABFScores() {
            const scores: { [index: number]: number } = {}
            forEach(this.stays, stay => {
                scores[stay.id] = getABFScore(stay.id)
            })
            return scores
        },
        abfTotal(): number {
            return reduce(this.staysABFScores, (sum, n) => sum + n, 0)
        },
        totalAcuteCareDays(): number {
            return reduce(this.stays, (sum, stay) => sum + (stay.care_days?.acute || 0), 0)
        },
        totalCareDays(): number {
            return reduce(this.stays, (sum, stay) => sum + stay.length_of_stay, 0)
        },
        abfEfficiencyDenom(): number {
            return this.onlyNeuro ? this.totalAcuteCareDays : this.totalCareDays
        },
        abfEfficiency(): string {
            return this.abfEfficiencyDenom ? `${round(this.abfTotal / this.abfEfficiencyDenom, 1)}` : ''
        },
        abfEfficiencyHelp(): string {
            const denomDesc = this.onlyNeuro ? 'acute care' : 'care'
            return `Mean derived from ${this.abfTotal} ABF points divided by ${round(this.abfEfficiencyDenom, 1)} days of ${denomDesc}`
        }
    },
    methods: {
        hideAllDetails() {
            // Collapse all details rows
            forEach(this.stayProxies, (stay) => {
                stay._showDetails = false
            })
        },
        toggleMDT(data: ScopedVar<StayProxy>) {
            if (!data.detailsShowing) {
                // We're about to show a stay's MDT section, hide all the others
                this.hideAllDetails()
            }
            data.toggleDetails()

            // Keep track of whether or not a details view is being shown so we style
            // the non-expanded rows
            this.expandedStayID = !data.detailsShowing && data.item.id
        },
        abfScore(stayID: number): number {
            return this.staysABFScores[stayID]
        },
        acuteDaysDesc(stayID: number): string {
            const days = this.staysAcuteDays[stayID]
            return days === null ? '' : `${round(days, 1)} days in acute care`
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "b-form",
        { staticClass: "mb-0 row scrwl-hovr-hi-light" },
        [
          _vm.showLabel
            ? _c(
                "label",
                {
                  staticClass: "col-sm-4 mb-0",
                  class: {
                    "scrwl-sub-q": _vm.doIndent,
                    "col-sm-12": !_vm.inline
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.question.title) + "\n        "
                  ),
                  _vm.question.link
                    ? [
                        _vm._v("\n            ["),
                        _c(
                          "a",
                          {
                            staticClass: "text-info",
                            attrs: { target: "_blank", href: _vm.question.link }
                          },
                          [_vm._v("Link")]
                        ),
                        _vm._v("]\n        ")
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showNotes
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value: _vm.htmlIds.notes,
                              expression: "htmlIds.notes"
                            }
                          ],
                          staticClass: "ml-1 r-clickable"
                        },
                        [
                          _vm.notes
                            ? _c("span", {
                                staticClass:
                                  "badge badge-primary text-left text-wrap scrwl-q-notes",
                                domProps: { innerHTML: _vm._s(_vm.notesHTML) }
                              })
                            : _c("fnt-a-icon", {
                                attrs: { icon: ["fas", "plus"] }
                              })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showDashboardToggle
                    ? _c(
                        "span",
                        {
                          staticClass: "ml-1 r-clickable",
                          attrs: { title: _vm.dIconHelpText },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.toggleDashboard.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("fnt-a-icon", {
                            class: _vm.dIconClasses,
                            attrs: { icon: ["fal", "notes-medical"] }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.helpText
                    ? _c(
                        "p",
                        { staticClass: "font-weight-light text-white-50 m-0" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.helpText) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-sm-8 d-flex flex-wrap",
              class: {
                "col-sm-12": !_vm.inline,
                "align-items-start flex-column": _vm.stackedChoices,
                "align-items-center": !_vm.stackedChoices
              }
            },
            [
              _vm.question.type === _vm.QuestionType.CHOICE ||
              _vm.question.type === _vm.QuestionType.LIST
                ? _vm._l(_vm.question.choices, function(choice) {
                    return _c(
                      "b-form-checkbox",
                      {
                        key: choice.id,
                        staticClass: "scrawl-checkbox mr-3",
                        attrs: {
                          size: "lg",
                          value: choice.id,
                          "unchecked-value": null
                        },
                        model: {
                          value: _vm.answer,
                          callback: function($$v) {
                            _vm.answer = $$v
                          },
                          expression: "answer"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(choice.title) +
                            "\n            "
                        )
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.question.type === _vm.QuestionType.BOOLEAN
                ? _vm._l(_vm.booleanChoices, function(choice) {
                    return _c(
                      "b-form-checkbox",
                      {
                        key: _vm.question.id + "__" + choice.value,
                        staticClass: "scrawl-checkbox mr-3",
                        attrs: {
                          size: "lg",
                          value: choice.value,
                          "unchecked-value": null
                        },
                        model: {
                          value: _vm.answer,
                          callback: function($$v) {
                            _vm.answer = $$v
                          },
                          expression: "answer"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(choice.title) +
                            "\n            "
                        )
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.question.type === _vm.QuestionType.TEXT
                ? [
                    _c("b-input", {
                      staticClass: "col-md-8 h-75",
                      model: {
                        value: _vm.answer,
                        callback: function($$v) {
                          _vm.answer = $$v
                        },
                        expression: "answer"
                      }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.question.type === _vm.QuestionType.LONG_TEXT
                ? [
                    _c("text-area", {
                      staticClass: "col-md-8 p-0",
                      attrs: { rows: "4" },
                      model: {
                        value: _vm.answer,
                        callback: function($$v) {
                          _vm.answer = $$v
                        },
                        expression: "answer"
                      }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.question.type === _vm.QuestionType.NUMBER
                ? [
                    _c("b-input", {
                      staticClass: "scrwl-num",
                      attrs: { type: "number", autofocus: _vm.autofocus },
                      model: {
                        value: _vm.answer,
                        callback: function($$v) {
                          _vm.answer = $$v
                        },
                        expression: "answer"
                      }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.question.type === _vm.QuestionType.TIMESTAMP
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "input-group px-0",
                        staticStyle: { "max-width": "20rem" }
                      },
                      [
                        _c("date-time-picker", {
                          attrs: { config: { wrap: true, enableTime: true } },
                          model: {
                            value: _vm.answer,
                            callback: function($$v) {
                              _vm.answer = $$v
                            },
                            expression: "answer"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-info",
                              attrs: { type: "button", "data-toggle": "" }
                            },
                            [
                              _c("fnt-a-icon", {
                                attrs: { icon: ["fal", "calendar"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-danger",
                              attrs: { type: "button", "data-clear": "" }
                            },
                            [
                              _c("fnt-a-icon", {
                                attrs: { icon: ["fal", "times"] }
                              })
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.question.type === _vm.QuestionType.DATE
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "input-group px-0",
                        staticStyle: { "max-width": "20rem" }
                      },
                      [
                        _c("date-time-picker", {
                          attrs: {
                            config: {
                              wrap: true,
                              enableTime: false,
                              altFormat: "d M Y",
                              dateFormat: "Y-m-d"
                            }
                          },
                          model: {
                            value: _vm.answer,
                            callback: function($$v) {
                              _vm.answer = $$v
                            },
                            expression: "answer"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-info",
                              attrs: { type: "button", "data-toggle": "" }
                            },
                            [
                              _c("fnt-a-icon", {
                                attrs: { icon: ["fal", "calendar"] }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-outline-danger",
                              attrs: { type: "button", "data-clear": "" }
                            },
                            [
                              _c("fnt-a-icon", {
                                attrs: { icon: ["fal", "times"] }
                              })
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.showNotes
            ? _c(
                "b-collapse",
                {
                  staticClass: "col-sm-8",
                  class: _vm.inline ? "ml-auto" : "",
                  attrs: { id: _vm.htmlIds.notes }
                },
                [
                  _c("text-area", {
                    staticClass: "col-md-8 p-0",
                    attrs: { label: "Notes", rows: "3", autofocus: "" },
                    model: {
                      value: _vm.notes,
                      callback: function($$v) {
                        _vm.notes = $$v
                      },
                      expression: "notes"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: _vm.htmlIds.notes,
                          expression: "htmlIds.notes"
                        }
                      ],
                      attrs: { size: "sm" }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
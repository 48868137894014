var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row pb-5" },
    [
      _c(
        "div",
        {
          staticClass:
            "bg-secondary col-12 d-flex justify-content-between py-2 align-items-center"
        },
        [
          _c("h2", [_vm._v("Editing Stay")]),
          _vm._v(" "),
          _vm.prevRoute
            ? _c(
                "b-button",
                { attrs: { to: _vm.prevRoute, variant: "success" } },
                [_vm._v("\n            Done\n        ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.ready
          ? _c(
              "div",
              { staticClass: "col pt-2" },
              [
                _c("stay-summary", { attrs: { stay_id: _vm.stay_id } }),
                _vm._v(" "),
                _c(_vm.editComponent, {
                  tag: "component",
                  staticClass: "mt-2",
                  attrs: { stay_id: _vm.stay_id }
                })
              ],
              1
            )
          : _c("div", { staticClass: "col pt-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center h-100"
                },
                [
                  _c("h1", [
                    _c("em", { staticClass: "loading-dots" }, [
                      _vm._v("Loading"),
                      _c("span", [_vm._v(".")]),
                      _c("span", [_vm._v(".")]),
                      _c("span", [_vm._v(".")])
                    ])
                  ])
                ]
              )
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
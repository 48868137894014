var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("diagnosis", { attrs: { stay_id: _vm.stay.id } }),
      _vm._v(" "),
      _vm.isNurseUser
        ? _c("rapid-asmt-diagnosis", { attrs: { stay_id: _vm.stay.id } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isConsult && _vm.canChangeDoctor
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("doctor", {
                  attrs: { label: "Consulting doctor", stay: _vm.stay }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-lg-9 col-xl-6" },
          [
            _c("standard-investigations-list", {
              attrs: {
                stay_id: _vm.stay.id,
                hideCompleted: true,
                hideAdd: true
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("location-input", { attrs: { stay_id: _vm.stay.id } }),
      _vm._v(" "),
      _vm.isActive
        ? [
            _c(
              "b-btn",
              {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal",
                    value: _vm.dischargeModalID,
                    expression: "dischargeModalID"
                  }
                ],
                attrs: { variant: "danger" }
              },
              [_vm._v("Discharge")]
            ),
            _vm._v(" "),
            _c(
              "b-modal",
              {
                attrs: {
                  id: _vm.dischargeModalID,
                  title: "Confirm discharge",
                  "ok-variant": "danger"
                },
                on: { ok: _vm.dischargeStay }
              },
              [_c("p", [_vm._v("Discharge " + _vm._s(_vm.patientName) + "?")])]
            )
          ]
        : _c(
            "b-btn",
            { attrs: { variant: "info" }, on: { click: _vm.undischargeStay } },
            [_vm._v("Undischarge")]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
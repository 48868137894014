

















import Vue from 'vue'

export default Vue.extend({
    props: {
        title: {
            type: String,
            required: true
        },
        /** the value to set when this checkbox is ticked */
        cb_value: {
            required: true
        },
        un_value: {
            default: null
        },
        value: {
            required: true
        }
    },
    methods: {
        onChange() {
            if (this.state) {
                this.uncheck()
            } else {
                this.check()
            }
        },
        check() {
            if (Array.isArray(this.value)) {
                const value = this.value.slice(0) // copy array

                value.push(this.cb_value)
                this.$emit('input', value)

                return
            }

            this.$emit('input', this.cb_value)
        },
        uncheck() {
            if (Array.isArray(this.value)) {
                const value = this.value.slice(0)

                value.splice(value.indexOf(this.cb_value), 1)
                this.$emit('input', value)

                return
            }

            this.$emit('input', this.un_value)
        }
    },
    computed: {
        state(): boolean {
            const value = this.value

            if (Array.isArray(value)) {
                return value.indexOf(this.cb_value) > -1
            }

            return value === this.cb_value
        }
    }
})

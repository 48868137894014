









import mixins from 'vue-typed-mixins'
import InputMixin from '@mixins/Input.vue'
import TextArea from '@shared/inputs/TextArea.vue'

export default mixins(InputMixin).extend({
    components: {
        TextArea
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        path: {
            type: String,
            required: true
        },
        label: {
            type: String
        },
        charLimit: {
            type: Number,
            required: false,
            default: -1,
        },
        collapsible: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        nonUniqueID(): string {
            return `${this.path}_text`
        },
        field: {
            get(): string {
                const val = this.getFieldVal(this.stay_id, this.path)
                return val === undefined ? '' : val
            },
            set(notes: string) {
                this.setFieldVal(this.stay_id, this.path, notes)
            }
        },
        overCharLimit(): boolean {
            if (this.charLimit === -1) return false
            return this.field === null ? false : this.field.length > this.charLimit
        },
        enhancedLabel(): string {
            return this.overCharLimit ? `${this.label} (please keep under ${this.charLimit} characters)` : this.label
        },
    }
})

import { RouteConfig } from 'vue-router'

/*
import AdminUser from '@admin/User.vue'
import AdminUserStafflink from '@admin/UserStafflink.vue'
import UserBatchAdd from '@admin/UserBatchAdd.vue'
import AdminGroup from '@admin/Group.vue'
import AdminTeam from '@admin/Team.vue'
import AdminTDoc from '@admin/TeamDoctor.vue'
import AdminCTM from '@admin/TeamMember.vue'
import Specialities from '@admin/Specialities.vue'
import AdminSpeciality from '@admin/Speciality.vue'
*/

const User = () => import(/* webpackChunkName: "admin" */ '@admin/User.vue')
const UserStafflink = () => import(/* webpackChunkName: "admin" */ '@admin/UserStafflink.vue')
const UserBatchAdd = () => import(/* webpackChunkName: "admin" */ '@admin/UserBatchAdd.vue')
const Group = () => import(/* webpackChunkName: "admin" */ '@admin/Group.vue')

const AsapPathway = () => import(/* webpackChunkName: "admin" */ '@admin/AsapPathway.vue')
const Team = () => import(/* webpackChunkName: "admin" */ '@admin/Team.vue')
const TeamDoctor = () => import(/* webpackChunkName: "admin" */ '@admin/TeamDoctor.vue')
const TeamMember = () => import(/* webpackChunkName: "admin" */ '@admin/TeamMember.vue')

const Specialities = () => import(/* webpackChunkName: "admin" */ '@admin/Specialities.vue')
const Speciality = () => import(/* webpackChunkName: "admin" */ '@admin/Speciality.vue')

const routes: RouteConfig[] = [
    {
        path: 'apw',
        name: 'admin-apw',
        component: AsapPathway
    },
    {
        path: 'team',
        name: 'admin-team',
        component: Team
    },
    {
        path: 'tdoc',
        name: 'admin-tdoc',
        component: TeamDoctor
    },
    {
        path: 'ctm',
        name: 'admin-ctm',
        component: TeamMember
    },
    {
        path: 'users-adv',
        name: 'admin-users',
        component: User
    },
    {
        path: 'add-users',
        name: 'admin-add-users',
        component: UserBatchAdd
    },
    {
        path: 'users',
        name: 'client-users',
        component: UserStafflink,
    },
    {
        path: 'groups',
        name: 'admin-groups',
        component: Group
    },
    {
        path: 'specialities',
        name: 'admin-specialities',
        component: Specialities
    },
    {
        path: 'speciality/:speciality_id',
        name: 'admin-speciality',
        component: Speciality,
        props: route => {
            return {
                id: +route.params.speciality_id,
            }
        }
    },
]

export default routes

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mt-4" }, [
    _c(
      "div",
      { staticClass: "col-12 col-lg-6" },
      [
        _c("b-overlay", {
          attrs: { show: !_vm.data, variant: "transparent", "no-wrap": "" }
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "text-center mt-2 mb-0",
            staticStyle: { "font-size": "1.5rem" }
          },
          [_vm._v("\n            Stroke Nursing Care KPIs\n        ")]
        ),
        _vm._v(" "),
        _c("canvas", { ref: "KPIs", staticClass: "mt-2 mb-4" })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 col-lg-6" },
      [
        _c("b-overlay", {
          attrs: { show: !_vm.data, variant: "transparent", "no-wrap": "" }
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "text-center mt-2 mb-0",
            staticStyle: { "font-size": "1.5rem" }
          },
          [_vm._v("\n            Strokes - % Nurse Reviewed\n        ")]
        ),
        _vm._v(" "),
        _c("canvas", {
          ref: "StrokesPctNurseReviewed",
          staticClass: "mt-2 mb-4"
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 col-lg-6" },
      [
        _c("b-overlay", {
          attrs: { show: !_vm.data, variant: "transparent", "no-wrap": "" }
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "text-center mt-2 mb-0",
            staticStyle: { "font-size": "1.5rem" }
          },
          [_vm._v("\n            Stroke Nurse Cases Reviewed\n        ")]
        ),
        _vm._v(" "),
        _c("canvas", { ref: "CasesReviewed", staticClass: "mt-2 mb-4" })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 col-lg-6" },
      [
        _c("b-overlay", {
          attrs: { show: !_vm.data, variant: "transparent", "no-wrap": "" }
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "text-center mt-2 mb-0",
            staticStyle: { "font-size": "1.5rem" }
          },
          [_vm._v("\n            NBM\n        ")]
        ),
        _vm._v(" "),
        _c("canvas", { ref: "NBM", staticClass: "mt-2 mb-4" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
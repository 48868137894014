










import Vue from 'vue'
import utils from 'utils'
import VersionChecker from 'utils/CheckVersion.vue'
import TopBar from '@standalone/TopBar.vue'


export default Vue.extend({
    components: {
        TopBar,
        VersionChecker,
    },
    mounted() {
        utils.hashChangeIE11(this)
    },
})






















import mixins from 'vue-typed-mixins'
import StaysMixin from '../mixins/Stays.vue'
import utils from 'utils'
import { Stay } from 'models/data/stay'

export default mixins(StaysMixin).extend({
    props: {
        stay: {
            type: Object as () => Stay,
            required: true
        },
        add_mb: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const uid = utils.getUID()
        const baseId = `s${this.stay.id}`
        return {
            deleteId: `${uid}___${baseId}_del_confirm`,
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        identifierText_c(): string {
            return this.identifierText(this.stay)
        },
        patientText(): string {
            return this.patientNameAndAge(this.stay)
        },
    },
    methods: {
        confirmStayDeletion() {
            this.$bvModal.show(this.deleteId)
        },
    }
})



















import mixins from "vue-typed-mixins"
import { debounce, each, filter, find, map, max, reduce, toInteger } from "lodash-es"
// import { draw } from 'patternomaly'
import utils from "utils"
import { safeDecoding } from 'utils/store'
import { usageDataDecoder } from 'models/asap/charts'
import {
    Outcome,
    Outcome_values,
    Outcome_labels,
    Reason,
    Reason_keys,
    Reason_labels,
} from "models/asap/assessment"
import UpdateChartMixin from "components/data-viz/UpdateChartMixin.vue"

// TODO type checks for incoming data

interface SiteCounts {
    total: number
    by_reason: {
        [r in Reason]: {
            [r in Outcome]: number
        }
    }
}

interface AsmtChartsData {
    /** A dictionary of counts by site */
    counts: { [key: string]: SiteCounts }
    /** An ISO-formatted datetime string */
    start: string
    /** An ISO-formatted datetime string */
    end: string
}

interface Data {
    data: AsmtChartsData
    reasons: typeof Reason_keys
    colors: string[]
    patterns: ('weave' | 'dot' | 'cross')[]
}

export default mixins(UpdateChartMixin).extend({
    props: {
        start: {
            type: String,
            required: true
        },
        end: {
            type: String,
            required: true
        },
    },
    data() {
        const _data: Data = {
            data: {
                counts: {},
                start: "",
                end: ""
            },
            reasons: filter(Reason_keys, key => key !== "unknown"),
            colors: [
                "#ffa62b",
                "#2274a5",
                "#a7acd9",
                "#f05d5e",
                "#73eedc",
                "#41521f",
                "#f5fdc6",
                "#aa4465",
                "#cff27e",
                "#6c7d47"
            ],
            patterns: ['weave', 'dot', 'cross'],
        }
        return _data
    },
    computed: {
        debouncedPull(): any {
            return debounce(this.pull, 300, { leading: false, trailing: true })
        },
        counts(): any {
            return this.data.counts
        },
        allSitesIds(): string[] {
            return map(this.$store.direct.state.asap.asapMeta.sites, site => site.id)
        },
        sites(): string[] {
            return this.$store.direct.state.user.asapVizSites
        },
        /** some sites are selected */
        someSitesOnly(): boolean {
            return this.sites.length > 0
        },
        sitesToDisplay(): string[] {
            return this.someSitesOnly ? this.sites : this.allSitesIds
        },
    },
    watch: {
        sites() {
            this.debouncedPull()
        },
        start() {
            this.debouncedPull()
        },
        end() {
            this.debouncedPull()
        },
    },
    created() {
        this.$store.direct.dispatch.asap.pullASAPMeta()
        .then(res => {
            this.pull()
        })
    },
    methods: {
        pull(): Promise<void> {
            const url = `/asap/asmt_charts/v2/${this.start}/${this.end}/`

            return utils.request.get(url, { s: this.sites }).then(res => {
                this.data = safeDecoding(res.body, usageDataDecoder, 'asap/UsageChartv2/pull')

                let maxCount = 0

                const siteCountsByReason: { [key in Reason]: number[][] } = {
                    unknown: [], care: [], demo_train: []
                }
                each(this.reasons, reason => {
                    const counts = map(this.sitesToDisplay, site => {
                        const siteCounts = this.data.counts[site]
                        return map(Outcome_values, outcome => {
                            const count = siteCounts ? siteCounts.by_reason[reason][outcome] : 0
                            if (count > maxCount)
                                maxCount = count
                            return count
                        })
                    })
                    siteCountsByReason[reason] = counts
                })

                const combinedByReason: { [key in Reason]: number[][] } = {
                    unknown: [], care: [], demo_train: []
                }
                if (!this.someSitesOnly) {
                    each(this.reasons, reason => {
                        const countsSets = siteCountsByReason[reason]
                        const combinedCounts = reduce(countsSets, (res, counts) => {
                            each(counts, (count, index) => { res[index] += count })
                            return res
                        }, map(Outcome_values, _ => 0))
                        combinedByReason[reason] = [combinedCounts]
                        const localMax = max(combinedCounts) || 0
                        if (localMax > maxCount)
                            maxCount = localMax
                    })
                }

                const finalCountsByReason = this.someSitesOnly ? siteCountsByReason : combinedByReason

                const sections = 4
                let yMax = maxCount + 1
                while (yMax % sections != 0) yMax++
                const stepSize = toInteger(yMax / sections)

                each(this.reasons, reason => {
                    let countsSets = finalCountsByReason[reason]

                    this.updateChart({
                        title: reason,
                        chartType: "bar",
                        data: {
                            labels: map(Outcome_values, outcome => Outcome_labels[outcome]),
                            datasets: map(countsSets, (counts, index) => {
                                const color = this.colors[
                                    index % this.colors.length
                                ]
                                return {
                                    label: this.someSitesOnly ? this.siteLabel(this.sitesToDisplay[index]) : '[All]',
                                    data: counts,
                                    minBarLength: 2,
                                    backgroundColor: color,
                                    // backgroundColor: map(Outcome_values, (_, i2) => draw(this.patterns[i2], color)),
                                    borderColor: color
                                }
                            })
                        },
                        options: {
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                            max: yMax,
                                            stepSize,
                                            fontColor: "white",
                                            fontSize: 14
                                        }
                                    }
                                ]
                            }
                        }
                    })
                })
            })
        },
        reasonLabel(reason: Reason) {
            return Reason_labels[reason]
        },
        siteLabel(site: string): string {
            const siteInfo = find(
                this.$store.direct.state.asap.asapMeta.sites,
                ["id", site]
            )
            return siteInfo ? siteInfo.title : site
        }
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mt-2" }, [
    _c(
      "div",
      { staticClass: "col-12 col-lg-6" },
      [
        _c("b-overlay", {
          attrs: { show: !_vm.data, variant: "transparent", "no-wrap": "" }
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "text-center mt-2 mb-0",
            staticStyle: { "font-size": "1.5rem" }
          },
          [_vm._v("\n            All cases\n        ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mx-5 mt-2 summary-table" }, [
          _c("table", { staticClass: "table table-sm text-center" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [_vm._v(" " + _vm._s(_vm.totalCases))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.ischaemicStrokeTotal))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.ichTotal))])
              ]),
              _vm._v(" "),
              _vm._m(1)
            ])
          ])
        ]),
        _vm._v(" "),
        _c("canvas", { ref: "strokeChart", staticClass: "mt-3 mb-4" })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 col-lg-6" },
      [
        _c("b-overlay", {
          attrs: { show: !_vm.data, variant: "transparent", "no-wrap": "" }
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "text-center mt-2 mb-0",
            staticStyle: { "font-size": "1.5rem" }
          },
          [_vm._v("\n            Reperfusion cases\n        ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mx-5 mt-2 summary-table" }, [
          _vm.data
            ? _c("table", { staticClass: "table table-sm text-center m-0" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.reperfusionTotal))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.lysedTotal))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.ecrTotal))])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.percentage(
                            _vm.reperfusionTotal,
                            _vm.ischaemicStrokeTotal
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.percentage(
                            _vm.lysedTotal,
                            _vm.ischaemicStrokeTotal
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm.percentage(_vm.ecrTotal, _vm.ischaemicStrokeTotal)
                        )
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("canvas", { ref: "reperfusionChart", staticClass: "mt-3 mb-4" })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("td", [_vm._v("Total")]),
      _vm._v(" "),
      _c("td", [_vm._v("Ischaemic strokes")]),
      _vm._v(" "),
      _c("td", [_vm._v("ICH")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", [_vm._v(" ")]), _c("td"), _c("td")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("td", [_vm._v("Total")]),
        _vm._v(" "),
        _c("td", [_vm._v("Lysed")]),
        _vm._v(" "),
        _c("td", [_vm._v("ECR")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
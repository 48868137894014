var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-8" },
      [
        _c("questions", {
          attrs: {
            stay_id: _vm.stay_id,
            category: _vm.QuestionCategory.WARD_EXAM,
            parentObject: _vm.speciality,
            firstItemTopBorder: false
          }
        }),
        _vm._v(" "),
        _c("text-area", {
          attrs: {
            "non-unique-id": "ward-exam-notes",
            rows: "8",
            label: "Exam notes"
          },
          model: {
            value: _vm.wardExamNotes,
            callback: function($$v) {
              _vm.wardExamNotes = $$v
            },
            expression: "wardExamNotes"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-4" },
      [
        _vm.isNeurologyStay
          ? [
              _vm.isPowerUser
                ? _c(
                    "b-check",
                    {
                      staticClass: "progress-bar-striped mb-2",
                      attrs: { switch: "" },
                      model: {
                        value: _vm.altLayout,
                        callback: function($$v) {
                          _vm.altLayout = $$v
                        },
                        expression: "altLayout"
                      }
                    },
                    [_vm._v("Layout")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal",
                      value: _vm.htmlIDs.nihss,
                      expression: "htmlIDs.nihss"
                    },
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: _vm.htmlIDs.nihss,
                      expression: "htmlIDs.nihss"
                    }
                  ],
                  attrs: { variant: "info" }
                },
                [
                  _vm._v("\n                NIHSS\n                "),
                  _vm.nihssV1
                    ? [
                        _vm.nihssComplete
                          ? _c("span", [
                              _vm._v("(" + _vm._s(_vm.nihssScore) + ")")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          { class: _vm.nihssCompleteColorClass(_vm.nihssObj) },
                          [
                            _c("fnt-a-icon", {
                              attrs: {
                                icon: _vm.nihssCompleteIcon(_vm.nihssObj)
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              !_vm.altLayout
                ? _c(
                    "b-modal",
                    {
                      attrs: {
                        id: _vm.htmlIDs.nihss,
                        title: "NIHSS",
                        size: "xl",
                        "ok-only": ""
                      }
                    },
                    [
                      _vm.nihssV1
                        ? _c("nihss", {
                            attrs: {
                              stay_id: _vm.stay_id,
                              prefix: "ward_round"
                            }
                          })
                        : _c("nihss-select", {
                            attrs: { stay_id: _vm.stay_id }
                          })
                    ],
                    1
                  )
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _vm.isNeurologyStay
      ? _c(
          "div",
          { staticClass: "col-8" },
          [
            _vm.altLayout
              ? _c(
                  "b-collapse",
                  { attrs: { id: _vm.htmlIDs.nihss } },
                  [
                    _c("h5", [_vm._v("NIHSS")]),
                    _vm._v(" "),
                    _vm.nihssV1
                      ? _c("nihss", {
                          attrs: { stay_id: _vm.stay_id, prefix: "ward_round" }
                        })
                      : _c("nihss-select", { attrs: { stay_id: _vm.stay_id } })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { capitalize, concat, filter, find, forEach, get, includes, isNil, lowerFirst, map, upperFirst } from 'lodash-es'
import { freeTextSubHtmlBreaks } from './helpers'
import { TextDetails } from './interfaces'
import { InvestigationStatus } from 'models/med_templates/enums'
import { Investigation } from 'models/data/investigation'
import { Investigation as InvSchema, NonContrastCTBrain_v2, CTPerfusion_v2, CTAngiography_v2, MRIBrain_v2 } from "models/med_templates/investigation"
import stays from '@store/stays'
import store from 'store'

export function ctAngiography_v1(stayInvestigation: Investigation, investigation: InvSchema): string {
    const selectedResults = stayInvestigation.results
    const allResults = investigation.results
    const selectedResultsSchema = filter(allResults, function(result) {
        return includes(selectedResults, result.id)
    })

    const fieldIDSelected = function(fieldID: string) {
        return find(selectedResultsSchema, ["field_id", fieldID]) !== undefined
    }

    let lines: string[] = []

    // Intracranial occlusion
    const noIntracranialOcclusion = fieldIDSelected("normal")
    const hasIntracranialOcclusion = fieldIDSelected("io")

    if (noIntracranialOcclusion) {
        lines.push("No intracranial occlusion.")
    } else if (hasIntracranialOcclusion) {
        let line: string[] = []

        const sideFields = ["right", "left", "basilar"]
        forEach(sideFields, function(field) {
            if (fieldIDSelected(field))
                line.push(field)
        })

        const siteFields = ['car_t', 'm1_mid_ca', 'm2_mid_ca', 'dist_mid_ca', 'ant_ca', 'post_ca']
        let siteText: string[] = []
        forEach(siteFields, function(field) {
            const fieldSchema = find(selectedResultsSchema, ["field_id", field])
            if (fieldSchema)
                siteText.push(fieldSchema.title)
        })

        if (siteText.length) {
            line.push(siteText.join(", "))
        }

        line.push("intracranial occlusion")
        let text = line.join(" ")
        // Captialise first letter and add "."
        text = upperFirst(text) + "."
        lines.push(text)
    }
    
    // Right internal carotid artery
    const rightFields = ["maj_sten_r", "no_sten_r", "all_oc_r"]
    const selectedRightField = find(selectedResultsSchema, function(res) { return includes(rightFields, res.field_id) })
    if (selectedRightField) {
        lines.push(`Right internal carotid artery - ${selectedRightField.title}.`)
    }

    // Left internal carotid artery
    const leftFields = ["maj_sten_l", "no_sten_l", "all_oc_l"]
    const selectedLeftField = find(selectedResultsSchema, function(res) { return includes(leftFields, res.field_id) })
    if (selectedLeftField) {
        lines.push(`Left internal carotid artery - ${selectedLeftField.title}.`)
    }

    return lines.join(" ")

}

export function nonContrastCTBrain_v1(stayInvestigation: Investigation, investigation: InvSchema): string {
    const selectedResults = stayInvestigation.results
    const allResults = investigation.results
    const selectedResultsSchema = filter(allResults, function(result) {
        return includes(selectedResults, result.id)
    })

    if (!selectedResultsSchema.length) return ""

    const fieldIDSelected = function(fieldID: string) {
        return typeof find(selectedResultsSchema, ["field_id", fieldID]) !== "undefined"
    }

    const basicFields = ["normal", "tih"]
    const selectedBasicField = find(selectedResultsSchema, function(res) { return includes(basicFields, res.field_id) })
    if (selectedBasicField) {
        return selectedBasicField.title
    }

    let lines: string[] = []

    let sideText: string[] = []
    const rightField = find(selectedResultsSchema, ["field_id", "right"])
    if (rightField) sideText.push(rightField.title)

    const leftField = find(selectedResultsSchema, ["field_id", "left"])
    if (leftField) sideText.push(leftField.title)

    if (sideText.length) {
        lines.push(sideText.join(", "))
    }

    const acuteInfarction = find(selectedResultsSchema, ["field_id", "ai"])
    if (acuteInfarction) {
        const aiSiteFields = ["ai_lacune", "ai_anterior", "ai_posterior", "ai_brainstem", "ai_cerebellum"]
        const selectedSites = filter(selectedResultsSchema, function(res) {
            return includes(aiSiteFields, res.field_id)
        })
        if (selectedSites.length) lines.push(map(selectedSites, "title").join(", "))
        lines.push(acuteInfarction.title)
    }

    const intracerebralHaemorrhage = find(selectedResultsSchema, ["field_id", "ih"])
    if (intracerebralHaemorrhage) {
        const ihSiteFields = ["ih_basal", "ih_lobar", "ih_brainstem", "ih_cerebellum"]
        const selectedSites = filter(selectedResultsSchema, function(res) {
            return includes(ihSiteFields, res.field_id)
        })
        if (selectedSites.length) lines.push(map(selectedSites, "title").join(", "))
        lines.push(intracerebralHaemorrhage.title)
    }

    return capitalize(lines.join(" "))

}

export function ctPerfusion_v1(stayInvestigation: Investigation, investigation: InvSchema): string {
    const selectedResults = stayInvestigation.results
    const allResults = investigation.results
    const selectedResultsSchema = filter(allResults, function(result) {
        return includes(selectedResults, result.id)
    })

    let line: string[] = []

    const sideFields = ["right", "left"]
    const sides = filter(selectedResultsSchema, function(res) { return includes(sideFields, res.field_id) })
    if (sides.length) line.push(map(sides, "title").join(", "))

    const siteFields = ["apl_anterior", "apl_posterior"]
    const site = find(selectedResultsSchema, function(res) { return includes(siteFields, res.field_id) })
    if (site) line.push(site.title)
    
    const findingFields = ["normal", "apl"]
    const finding = find(selectedResultsSchema, function(res) { return includes(findingFields, res.field_id) })
    if (finding) line.push(finding.title)

    if (line.length) {
        return capitalize(line.join(" "))
    }
    return ""
}

function ctPerfusionWithVolumes_v1(stayInvestigation: Investigation, investigation: InvSchema, stayId: number): string {
    let line = ctPerfusion_v1(stayInvestigation, investigation)

    const aplInvSchema = find(investigation.results, res => res.field_id === 'apl')

    if (!aplInvSchema) {
        console.error("CTP schema does not have 'apl' field")
        return line
    }

    if (includes(stayInvestigation.results, aplInvSchema.id)) {
        const coreQ = find(store.direct.state.templates.custom_qs, ['help_text', 'ctp_lesion_core_mls'])
        const coreId = coreQ ? coreQ.id : -1
        let coreVol = stays.getAnswer(stayId, coreId)
        if (isNil(coreVol))
            coreVol = '?'
        const penQ = find(store.direct.state.templates.custom_qs, ['help_text', 'ctp_lesion_pen_mls'])
        const penId = penQ ? penQ.id : -1
        let penVol = stays.getAnswer(stayId, penId)
        if (isNil(penVol))
            penVol = '?'
        line += `, Perfusion volumes: Core ${coreVol} mLs Penumbra ${penVol} mLs`
        return line
    }

    return line
}

export function ctAngiography_v2(stayInvestigation: Investigation, investigation: InvSchema, lineBreak?: 'text' | 'markup'): string {
    const selectedResults = stayInvestigation.results
    const allResults = investigation.results
    const selectedResultsSchema = filter(allResults, res => includes(selectedResults, res.id))

    let lines: string[] = []
    let text: string

    const noOcclusion = find(selectedResultsSchema, ["field_id", "io_no"])
    const occlusion = find(selectedResultsSchema, ["field_id", "io_yes"])
    const noAtheroma = find(selectedResultsSchema, ["field_id", "sia_no"])
    const atheroma = find(selectedResultsSchema, ["field_id", "sia_yes"])

    if (noOcclusion) {
        lines.push("No intracranial occlusion.")
    }
    else if (occlusion) {
        let line: string[] = []

        const sideFields = CTAngiography_v2.io_sides
        const sides = filter(selectedResultsSchema, res => includes(sideFields, res.field_id))
        if (sides.length) line.push(map(sides, "title").join(", "))
    
        const siteFields = CTAngiography_v2.io_sites
        const selectedSites = filter(selectedResultsSchema, res => includes(siteFields, res.field_id))
        if (selectedSites.length) line.push(map(selectedSites, "title").join(", "))
    
        line.push("intracranial occlusion.")
        text = line.join(' ')
        text = upperFirst(text)
        lines.push(text)
    }
    
    if (noAtheroma) {
        lines.push("No significant intracranial atheroma.")
    }
    else if (atheroma) {
        let line: string[] = []

        const sideFields = CTAngiography_v2.sia_sides
        const sides = filter(selectedResultsSchema, res => includes(sideFields, res.field_id))
        if (sides.length) line.push(map(sides, "title").join(", "))
    
        const siteFields = CTAngiography_v2.sia_sites
        const selectedSites = filter(selectedResultsSchema, res => includes(siteFields, res.field_id))
        if (selectedSites.length) line.push(map(selectedSites, "title").join(", "))
    
        line.push("significant intracranial atheroma.")
        text = line.join(' ')
        text = upperFirst(text)
        lines.push(text)
    }

    const carotidLines: string[] = []

    // Right internal carotid artery
    const rightFields = CTAngiography_v2.right_artery
    const selectedRightField = find(selectedResultsSchema, function(res) { return includes(rightFields, res.field_id) })
    if (selectedRightField)
        carotidLines.push(`Right internal carotid artery - ${selectedRightField.title}.`)

    // Left internal carotid artery
    const leftFields = CTAngiography_v2.left_artery
    const selectedLeftField = find(selectedResultsSchema, function(res) { return includes(leftFields, res.field_id) })
    if (selectedLeftField)
        carotidLines.push(`Left internal carotid artery - ${selectedLeftField.title}.`)

    if (carotidLines.length) {
        let carotidLine = carotidLines.join(' ')
        lines.push(carotidLine)
    }

    if (lineBreak === 'text') return lines.join('\n')
    else if (lineBreak === 'markup') return lines.join('  \n&nbsp;&nbsp;')
    return lines.join(" ")

}

export function nonContrastCTBrain_v2(stayInvestigation: Investigation, investigation: InvSchema): string {
    const selectedResults = stayInvestigation.results
    const allResults = investigation.results
    const selectedResultsSchema = filter(allResults, res => includes(selectedResults, res.id))

    if (!selectedResultsSchema.length) return ""

    const basicFields = ["normal"]
    const selectedBasicField = find(selectedResultsSchema, res => includes(basicFields, res.field_id))
    if (selectedBasicField) {
        return selectedBasicField.title
    }

    let lines: string[] = []

    const sideFields = NonContrastCTBrain_v2.sides
    const sides = filter(selectedResultsSchema, res => includes(sideFields, res.field_id))
    if (sides.length) lines.push(map(sides, "title").join(", "))


    const acuteInfarction = find(selectedResultsSchema, ["field_id", "ai"])
    if (acuteInfarction) {
        const selectedSites = filter(selectedResultsSchema, function(res) {
            return includes(NonContrastCTBrain_v2.ai, res.field_id)
        })
        if (selectedSites.length) lines.push(map(selectedSites, "title").join(", "))
        lines.push(acuteInfarction.title)
    }

    const intracranialHaemorrhage = find(selectedResultsSchema, ["field_id", "aih"])
    if (intracranialHaemorrhage) {
        const intracerebralHaemorrhage = find(selectedResultsSchema, ['field_id', 'aih_main_int_cereb'])
        if (intracerebralHaemorrhage) {
            const icSites = filter(selectedResultsSchema, res => includes(NonContrastCTBrain_v2.aih_ic, res.field_id))
            if (icSites.length) lines.push(map(icSites, 'title').join(', '))
        }

        const selectedSites = filter(selectedResultsSchema, res => 
            includes(NonContrastCTBrain_v2.aih_main, res.field_id))
        if (selectedSites.length) lines.push(map(selectedSites, "title").join(", "))

        lines.push('haemorrhage')

        const addSites = filter(selectedResultsSchema,
            res => includes(NonContrastCTBrain_v2.aih_add, res.field_id))
        if (addSites.length) {
            const line = 'with ' + map(addSites, 'title').join(', ') + ' blood'
            lines.push(line)
        }
    }

    return capitalize(lines.join(" "))

}

export function ctPerfusion_v2(stayInvestigation: Investigation, investigation: InvSchema, stayId: number): string {
    const selectedResults = stayInvestigation.results
    const allResults = investigation.results
    const selectedResultsSchema = filter(allResults, res => includes(selectedResults, res.id))

    let tokens: string[] = []

    const sideFields = CTPerfusion_v2.sides
    const sides = filter(selectedResultsSchema, res => includes(sideFields, res.field_id))
    if (sides.length) tokens.push(map(sides, "title").join(", "))

    const siteFields = CTPerfusion_v2.apl_sites
    const selectedSites = filter(selectedResultsSchema, res => includes(siteFields, res.field_id))
    if (selectedSites.length) tokens.push(map(selectedSites, "title").join(", "))
    
    const findingFields = CTPerfusion_v2.findings
    const finding = find(selectedResultsSchema, res => includes(findingFields, res.field_id))
    if (finding) tokens.push(finding.title)

    let line = tokens.length ? capitalize(tokens.join(" ")) : ''

    const aplInvSchema = find(investigation.results, res => res.field_id === 'apl')

    if (!aplInvSchema) {
        console.error("CTP schema does not have 'apl' field")
        return line
    }

    if (includes(stayInvestigation.results, aplInvSchema.id)) {
        let coreVol: number | string | null | undefined = null
        let penVol: number | string | null | undefined = null

        if (stayInvestigation.performed !== undefined) {
            const coreQ = find(store.direct.state.templates.custom_qs, ['help_text', 'ctp_lesion_core_mls'])
            const coreId = coreQ ? coreQ.id : -1
            coreVol = stays.getAnswer(stayId, coreId)
            const penQ = find(store.direct.state.templates.custom_qs, ['help_text', 'ctp_lesion_pen_mls'])
            const penId = penQ ? penQ.id : -1
            penVol = stays.getAnswer(stayId, penId)
        }
        else {
            coreVol = stayInvestigation.extra_data.core
            penVol = stayInvestigation.extra_data.pen
        }

        if (isNil(coreVol))
            coreVol = '?'
        if (isNil(penVol))
            penVol = '?'

        line += `. Perfusion volumes: Core ${coreVol} mLs Penumbra ${penVol} mLs`
    }

    const quality = find(selectedResultsSchema, res => includes(CTPerfusion_v2.quality, res.field_id))
    if (quality) line += `. Quality: ${quality.title}`

    return line
}

export function mriBrain_v2(stayInvestigation: Investigation, investigation: InvSchema): string {
    const selectedResults = stayInvestigation.results
    const allResults = investigation.results
    const selectedResultsSchema = filter(allResults, res => includes(selectedResults, res.id))

    if (!selectedResultsSchema.length) return ""

    const basicFields = ["normal"]
    const selectedBasicField = find(selectedResultsSchema, res => includes(basicFields, res.field_id))
    if (selectedBasicField) {
        return selectedBasicField.title
    }

    let lines: string[] = []

    const sideFields = MRIBrain_v2.sides
    const sides = filter(selectedResultsSchema, res => includes(sideFields, res.field_id))
    if (sides.length) lines.push(map(sides, "title").join(", "))


    const acuteInfarction = find(selectedResultsSchema, ["field_id", "ai"])
    if (acuteInfarction) {
        const selectedSites = filter(selectedResultsSchema, function(res) {
            return includes(MRIBrain_v2.ai, res.field_id)
        })
        if (selectedSites.length) lines.push(map(selectedSites, "title").join(", "))
        lines.push(acuteInfarction.title)
    }

    const intracranialHaemorrhage = find(selectedResultsSchema, ["field_id", "aih"])
    if (intracranialHaemorrhage) {
        const intracerebralHaemorrhage = find(selectedResultsSchema, ['field_id', 'aih_main_int_cereb'])
        if (intracerebralHaemorrhage) {
            const icSites = filter(selectedResultsSchema, res => includes(MRIBrain_v2.aih_ic, res.field_id))
            if (icSites.length) lines.push(map(icSites, 'title').join(', '))
        }

        const selectedSites = filter(selectedResultsSchema, res => 
            includes(MRIBrain_v2.aih_main, res.field_id))
        if (selectedSites.length) lines.push(map(selectedSites, "title").join(", "))

        lines.push('haemorrhage')

        const addSites = filter(selectedResultsSchema,
            res => includes(MRIBrain_v2.aih_add, res.field_id))
        if (addSites.length) {
            const line = 'with ' + map(addSites, 'title').join(', ') + ' blood'
            lines.push(line)
        }
    }

    return capitalize(lines.join(" "))

}

/** Return a text summary of a stay's investigation including results ticked and any notes */
function investigationSummary(stayInvestigation: Investigation, stayId: number): string {
    if (stayInvestigation.other)
        return stayInvestigation.notes || ""

    const investigation = find(store.direct.getters.templates.allInvs, ["id", stayInvestigation.investigation])
    if (!investigation) return ""

    let resultsText = ""

    const invsVersion = store.direct.state.session.invs_version
    const invsV2 = invsVersion === 'v2'

    if (invsV2) {
        switch (stayInvestigation.title) {
            case 'CT Angiography (Aortic arch to COW) v1':
                resultsText = ctAngiography_v1(stayInvestigation, investigation)
                break
            case 'Non-Contrast CT Brain v1':
                resultsText = nonContrastCTBrain_v1(stayInvestigation, investigation)
                break
            case 'CT Perfusion v1':
                resultsText = ctPerfusionWithVolumes_v1(stayInvestigation, investigation, stayId)
                break
            case 'CT Angiography (Aortic arch to COW)':
                resultsText = ctAngiography_v2(stayInvestigation, investigation, 'markup')
                break
            case 'Non-Contrast CT Brain':
                resultsText = nonContrastCTBrain_v2(stayInvestigation, investigation)
                break
            case 'CT Perfusion':
                resultsText = ctPerfusion_v2(stayInvestigation, investigation, stayId)
                break
            case 'MRI Brain':
                resultsText = mriBrain_v2(stayInvestigation, investigation)
                break
            default:
                const results = filter(investigation.results, result => includes(stayInvestigation.results, result.id))
                resultsText = map(results, "title").join(", ")
                break                                        
        }
    }
    else {
        switch (stayInvestigation.title) {
            case 'CT Angiography (Aortic arch to COW)':
                resultsText = ctAngiography_v1(stayInvestigation, investigation)
                break
            case 'Non-Contrast CT Brain':
                resultsText = nonContrastCTBrain_v1(stayInvestigation, investigation)
                break
            case 'CT Perfusion':
                resultsText = ctPerfusionWithVolumes_v1(stayInvestigation, investigation, stayId)
                break
            case 'CT Angiography (Aortic arch to COW) v2':
                resultsText = ctAngiography_v2(stayInvestigation, investigation, 'markup')
                break
            case 'Non-Contrast CT Brain v2':
                resultsText = nonContrastCTBrain_v2(stayInvestigation, investigation)
                break
            case 'CT Perfusion v2':
                resultsText = ctPerfusion_v2(stayInvestigation, investigation, stayId)
                break
            case 'MRI Brain v2':
                resultsText = mriBrain_v2(stayInvestigation, investigation)
                break
            default:
                const results = filter(investigation.results, result => includes(stayInvestigation.results, result.id))
                resultsText = map(results, "title").join(", ")
                break                                        
        }
    }

    if (stayInvestigation.notes)
        resultsText += `, ${stayInvestigation.notes}`

    return resultsText
}

export function baselineInvestigations({ stay, title }: TextDetails): string {
    const syndromeID = stay?.syndrome
    if (!(stay && syndromeID)) return ""

    const syndrome = find(store.direct.getters.templates.allSyndromes, { id: syndromeID })
    if (!syndrome || !syndrome.baseline_investigations) return ""

    const investigationIDs = syndrome.baseline_investigations
    const stayInvestigations: Investigation[] = stay.baseline_investigations

    let lines: string[] = []

    forEach(investigationIDs, investigationID => {
        // Loop through investigations (rather than stayInvestigations) to preserve order
        const stayInvestigation = find(stayInvestigations, ["investigation", investigationID])
        if (!stayInvestigation || !stayInvestigation.performed) return

        const resultsText = investigationSummary(stayInvestigation, stay.id)

        if (resultsText) {
            lines.push(`${stayInvestigation.title}: ${resultsText}`)
        }
    })

    if (stay.triage && stay.triage.investigations) {
        lines.push(freeTextSubHtmlBreaks(stay.triage.investigations))
    }

    if (lines.length) {
        title = title || "Baseline investigations"
        return `\n**${title}:**  \n${lines.join("  \n")}\n\n`
    }

    return ""
}

export function admissionInvestigationsSummary({stay, hideTitle}: TextDetails): string {
    if (!stay) return ""

    const stayInvestigations = stays.getInvestigations(stay.id)

    let lines = map(stayInvestigations, "title")

    if (!lines.length) return ""

    if (!hideTitle) lines.unshift("**Additional Investigations**")

    return lines.join("  \n") + "\n\n"
}

export function completedInvestigationsSummary({ stay, title }: TextDetails): string {
    if (!stay) return ""

    const investigations = stays.getInvestigations(stay.id)
    const completedInvestigations = filter(investigations, ["status_label", InvestigationStatus.DONE])

    title = title || "Completed investigations"
    let lines: string[] = [`**${title}:**`]

    forEach(completedInvestigations, function(investigation) {
        const summary = investigationSummary(investigation, stay.id)
        if (summary) {
            lines.push(`${investigation.title}: ${summary}`)
        }
    })
    return lines.join("  \n") + "\n\n"
}

export function newInvestigationResults({ stay }: TextDetails): string {
    if (!stay) return ""

    const investigations = concat(
        stay.baseline_investigations || [],
        stays.getInvestigations(stay.id),
    )

    function investigationComplete(investigation: Investigation): boolean {
        return investigation.status_label === InvestigationStatus.DONE || investigation.performed === true
    }

    const updatedInvestigations = filter(investigations, investigation => {
        // Complete investigations with an updated_at > ward_round created_at
        return (
            !!stay.ward_round &&
            investigationComplete(investigation) &&
            investigation.updated_at >= stay.ward_round.created_at
        )
    })

    if (!updatedInvestigations.length) return ""

    let lines: string[] = []
    forEach(updatedInvestigations, investigation => {
        const summary = investigationSummary(investigation, stay.id)
        if (summary)
            lines.push(`**${investigation.title}:** ${summary}`)
    })

    if (!lines.length) return ""

    lines.unshift("**New investigation results**")
    return lines.join("  \n") + "\n\n"
}

export function outstandingInvestigations({ stay }: TextDetails): string {
    if (!stay) return ""

    const outstandingStatuses = [
        InvestigationStatus.REQUIRED,
        InvestigationStatus.CHASE,
        InvestigationStatus.ORDERED,
    ]

    const outstandingInvestigations = filter(stays.getInvestigations(stay.id), function(investigation) {
        return includes(outstandingStatuses, investigation.status_label)
    })

    const speciality = stays.getSpeciality(stay.id)
    if (!speciality) return ""

    // const statusLabels = speciality.investigation_status_labels
    let lines: string[] = []

    forEach(outstandingInvestigations, function(investigation) {
        // const statusLabel = find(statusLabels, ["id", investigation.status]) || {title: ""}
        // const statusText = lowerFirst(statusLabel.title)
        lines.push(investigation.title)
    })

    if (lines.length) {
        return "**Outstanding investigations**  \n" + lines.join("  \n") + "\n\n"
    }
    return ""
}

export function outpatientInvestigations({ stay }: TextDetails): string {
    if (!stay) return ""

    const investigations = stays.getInvestigations(stay.id)
    const outpatientInvestigations = filter(investigations, ["status_label", InvestigationStatus.OUTPATIENT])

    const speciality = stays.getSpeciality(stay.id)
    if (!speciality) return ""

    const statusLabels = speciality.investigation_status_labels
    let lines: string[] = []

    forEach(outpatientInvestigations, function(investigation) {
        const statusLabel = find(statusLabels, ["id", investigation.status]) || {title: ""}
        const statusText = lowerFirst(statusLabel.title)
        lines.push(`${investigation.title} ${statusText}`)
    })

    if (lines.length) {
        return lines.join("<br/>")
    }
    return ""
}

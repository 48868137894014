var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm._l(_vm.sides, function(side) {
        return _c(
          "div",
          { key: side.label, staticClass: "eye col" },
          [
            _c("label", [_vm._v(_vm._s(side.label))]),
            _vm._v(" "),
            _vm._l(side.halves, function(half, idx) {
              return _c(
                "div",
                { key: idx, staticClass: "col-12" },
                _vm._l(half, function(i) {
                  return _c("div", { key: i, staticClass: "check" }, [
                    _c("input", {
                      attrs: {
                        type: "checkbox",
                        id: _vm.uid + "___" + _vm.field_name + "_" + i,
                        name: "" + _vm.field_name
                      },
                      domProps: { checked: _vm.quadrantValue(i) },
                      on: {
                        change: function($event) {
                          return _vm.toggleQuadrant(i)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", {
                      staticClass: "circle col-6",
                      class: _vm.quadrantClass(i),
                      attrs: { for: _vm.uid + "___" + _vm.field_name + "_" + i }
                    })
                  ])
                }),
                0
              )
            })
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm.debugMode
        ? _c("b-col", { attrs: { cols: "12" } }, [
            _c("hr"),
            _vm._v(" "),
            _c("p", { staticClass: "text-monospace" }, [
              _vm._v(" [" + _vm._s(_vm.debugLabelText) + "]")
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
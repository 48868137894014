import { map } from 'lodash-es'
import {
    allCapsSnakeToUpperFirst,
    fieldText,
    freeTextSubHtmlBreaks,
} from './helpers'
import { TextDetails } from './interfaces'
import { genericQuestionsText } from './question'
import { wardRoundsMedications } from './meds'
import { QuestionCategory } from 'models/med_templates/question'
import stays from '@store/stays'


export function wardExam({ stay }: TextDetails): string {
    if (!(stay && stay.ward_round)) return ""

    let lines: string[] = []

    const text = genericQuestionsText(
        {
            stay,
            category: QuestionCategory.WARD_EXAM,
            getQuestionGroupsFrom: stays.getSpeciality(stay.id),
        }
    )
    if (text.length)
        lines.push(text + '  \n')

    const notes = fieldText({ stay }, 'ward_round.exam_notes')
    if (notes)
        lines.push(notes)

    if (lines.length > 0) {
        lines.unshift('**Exam:**')
        return lines.join("  \n") + "\n\n"
    }

    return ''
}

export function wardProgress({ stay }: TextDetails): string {
    const text = fieldText({ stay }, 'ward_round.progress')
    if (!text) return ""
    
    return "**Progress:**<br/>" + text + "\n\n"
}

export function discussed({ stay }: TextDetails): string {
    if (!(stay && stay.ward_round)) return ""

    let lines: string[] = []

    const discussionChecklist = stay.ward_round.discussion_checklist || []
    const discussedText = map(discussionChecklist, allCapsSnakeToUpperFirst).join(", ")

    if (discussedText.length) lines.push(discussedText)

    const discussionNotes = stay.ward_round.patient_doctor_notes
    if (discussionNotes) lines.push(freeTextSubHtmlBreaks(discussionNotes))

    if (!lines.length) return ""

    return "**Discussed:**<br/>" + lines.join("<br/>") + "\n\n"
}

export { wardRoundsMedications }


























































































import Vue from 'vue'
import { assign, cloneDeep, find, get, includes, set } from 'lodash-es'

import utils from 'utils'
import { issueFullText } from 'utils/text/issue'

import stays from '@store/stays'
import { CustomAnswer } from 'models/data/answer'
import { Issue as StayIssue, OtherIssue } from 'models/data/issue'
import { Issue, IssueStatus } from 'models/med_templates/issue'
import { QuestionCategory } from 'models/med_templates/question'

import DateTimePicker from '@shared/inputs/DateTimePicker.vue'
import Questions from '@stayinputs/Questions.vue'
import TextArea from '@shared/inputs/TextArea.vue'

export default Vue.extend({
    components: {
        DateTimePicker,
        Questions,
        TextArea,
    },    
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        issue: {
            type: Object as () => StayIssue | OtherIssue,
            required: true
        },
    },
    data() {
        const uid = utils.getUID()
        const otherS = this.issue.other ? '_other' : ''

        return {
            htmlID: `${uid}___issue${otherS}_${this.issue.id}`,
            dtPickerText: '',
            QuestionCategory,
        }
    },
    computed: {
        resultsClasses(): string {
            return window.scrawl.isIE11 ? '' : 'd-flex flex-wrap'
        },
        issueMetadata(): Object {
            return {
                order: this.issue.order,
                summary: this.issue.summary,
                diagnosed: this.issue.diagnosed,
                status: this.issue.status,
            }
        },
        status: {
            get(): IssueStatus {
                return this.issue.status
            },
            set(status: IssueStatus) {
                this.updateIssue({ status })
            }
        },
        results: {
            get(): number[] {
                return this.issue.results || []
            },
            set(results: number[]) {
                if (!utils.isEqual(this.results, results))
                    this.updateIssue({ results })
            }
        },
        summary: {
            get(): string {
                return this.issue.summary || ""
            },
            set(summary: string) {
                this.updateIssue({ summary })
            }
        },
        diagnosedAt: {
            get(): any {
                return this.issue.diagnosed
            },
            set(diagnosed: any) {
                this.updateIssue({ diagnosed })
            }
        },
        issueSchema() {
            const schemas: readonly Issue[] = this.$store.direct.getters.templates.allIssues
            return find(schemas, ['id', this.issue.issue])
        },
        resultGroups(): any[] {
            return get(this.issueSchema, 'results_groups', [])
        },
        isActive(): boolean {
            return this.issue.status === IssueStatus.ACTIVE
        },
        isResolved(): boolean {
            return this.issue.status === IssueStatus.RESOLVED
        },
        issueTitle: {
            get(): string {
                return this.issue.title
            },
            set(title: string) {
                if (this.issue.other) {
                    this.updateIssue({ title })
                }
            }
        },
        issueGroups() {
            return this.$store.direct.getters.templates.issueGroups
        },
    },
    methods: {
        changeIssue(issue: number, title: string) {
            if (issue === this.issue.issue) return

            if (this.issue.other) {
                // Change Other issue to a standard issue
                const issueData = assign({ issue }, this.issueMetadata)
                stays.extActAddIssues(this.stay_id, [issueData])
                this.updateIssue({ deleted: true })
            } else {
                // Set title here to ensure the UI updates immediately, rather than waiting for sync
                this.updateIssue({ issue, title })
            }
        },
        changeToOtherIssue() {
            if (this.issue.other) return

            // Create new Other Issue with same metadata
            const issueData = assign({ title: '?' }, this.issueMetadata)
            stays.extActAddOtherIssue(this.stay_id, [issueData])

            // Delete existing StayIssue
            this.updateIssue({ deleted: true })
        },
        inputHtmlID(id: number) {
            return `${this.htmlID}_res_${id}`
        },
        deleteIssue() {
            this.updateIssue({ deleted: true })
        },
        toggleStatus() {
            switch (this.status) {
                case IssueStatus.ACTIVE:
                    this.status = IssueStatus.RESOLVED
                    break

                case IssueStatus.RESOLVED:
                    this.status = IssueStatus.ACTIVE
                    break
            }
        },
        updateIssue(newData: Partial<StayIssue>) {
            const data = { id: this.issue.id, other: this.issue.other }
            assign(data, newData)

            stays.extActUpdateIssue(this.stay_id, data)
            .then(res => {
                if (stays.isOrthoStay(this.stay_id) && this.issueSchema) {
                    // TODO change this to markup that's interpreted server-side, since ideally we don't print out HTML sent from a client
                    // simple check to avoid adding text for all issues
                    if (includes(['standard ortho instructions', 'pathology', 'past med hx'], this.issueSchema.title.toLowerCase())) {
                        const textData = { id: this.issue.id, other: this.issue.other }
                        assign(textData, { text: this.fullText(this.issue) })
                        stays.extActUpdateIssue(this.stay_id, textData)
                    }
                }
            })
        },
        getAnswer(stayID: number, questionID: number): CustomAnswer | undefined {
            return get(this.issue.answers, `q_${questionID}`)
        },
        setAnswer(stayID: number, questionID: number, changes: Partial<CustomAnswer>) {
            if (this.issue.answers === undefined)
                return

            const answerObj = this.getAnswer(stayID, questionID)
            if (answerObj) {
                let changed = false
                if (changes.answer !== undefined)
                    changed = changes.answer !== answerObj.answer
                if (changes.notes !== undefined)
                    changed = changed || changes.notes !== answerObj.notes
                if (!changed)
                    return
            }

            let answers = cloneDeep(this.issue.answers)
            let key: keyof CustomAnswer
            for (key in changes)
                set(answers, `q_${questionID}.${key}`, changes[key])
            this.updateIssue({ answers })
        },
        fullText(issue: StayIssue | OtherIssue): string {
            if (!issue) return ''

            const stay = stays.state.stays[this.stay_id]
            if (!stay) return ''

            return issueFullText({ stay, issue })
        },
        selectOtherTitle(): void {
            if (this.issueTitle === '?') {
                const issueTitleRef: any = this.$refs['otherIssueTitle']
                if (issueTitleRef)
                    issueTitleRef.select()
            }
        }
    },
})

































import Vue from 'vue'
import utils from 'utils'
import * as commonmark from "commonmark"
import Checkbox from '@shared/inputs/Checkbox.vue'
import TextArea from '@shared/inputs/TextArea.vue'
import { Speciality } from 'models/med_templates/speciality'

export default Vue.extend({
    components: {
        Checkbox,
        TextArea,
    },
    data() {
        const uid = utils.getUID()
        const htmlId = `${uid}___sp_print_header`
        return {
            uid,
            htmlId,
            inputHtmlId: `${htmlId}_input`,
            localMarkdown: '',
        }
    },
    computed: {
        speciality(): Speciality | undefined {
            return this.$store.direct.getters.user.speciality
        },
        show(): boolean {
            return this.$store.direct.getters.user.canEditPrintHeader && !!this.speciality
        },
        cmReader() {
            return new commonmark.Parser()
        },
        cmWriter() {
            return new commonmark.HtmlRenderer()
        },
        headerMarkdown: {
            get(): string {
                return this.speciality?.print_header || ''
            },
            set(val: string) {
                this.$store.direct.commit.templates.setSpecialityPrintHeader({ id: this.speciality?.id, print_header: val })
            }
        },
        markedUp(): string {
            return this.cmWriter.render(this.cmReader.parse(this.localMarkdown))
        },
    },
    watch: {
        localMarkdown(val: string) {
            if (!this.speciality) return
            if (utils.isEqual(val, this.headerMarkdown)) return

            utils.request
            .patch(`/uxadmin/speciality/${this.speciality.id}`, false, { print_header: this.localMarkdown })
            .then(res => {
                this.headerMarkdown = res.body.print_header
                this.$toasted.info('Updated print list header')
            })
            .catch(err => {
                utils.handleRequestError(err)
            })

        },
        headerMarkdown: {
            handler: function(val) {
                if (!utils.isEqual(val, this.localMarkdown))
                    this.localMarkdown = val
            },
            immediate: true
        },
    },
})










import Vue from "vue"
import mixins from "vue-typed-mixins"
import { clone, isArray, isEqual } from "lodash-es"

import utils from 'utils'
import { PropExtInfo } from "models"
import { DisplayInfo } from "models/layout"
import { FullSchema } from 'models/schema/stay'

import Eye from "./Eye.vue"
import RInput from "../Input.vue"
import DisplayMixin from "../../mixins/Display.vue"
import InputMixin from "../../mixins/Input.vue"
import textNihss from 'utils/text/nihss'

export default mixins(DisplayMixin, InputMixin).extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String as () => keyof FullSchema,
            required: true
        },
        field_name: {
            type: String,
            required: true,
            default: ""
        },
        title: {
            type: String,
            default: "Eye"
        }
    },
    data() {
        return {
            uid: utils.getUID(),
            fieldPath: `${this.section}.${this.field_name}`,
            normalField: `${this.field_name}_normal`,
            allNormalArray: [0, 0, 0, 0, 0, 0, 0, 0]
        }
    },
    computed: {
        normalPropExtInfo(): PropExtInfo {
            const display: DisplayInfo = {
                field: this.normalField,
                force_label: "Normal",
                booleanOptions: {
                    combined: true
                }
            }
            const schema = this.getSectionSchema(this.section)
            return this.generatePropExtInfo(this.section, schema ? schema.children : {}, [display])[0]
        },
        normalVal(): boolean | null | undefined {
            return this.getFieldVal(this.stay_id, this.normalPropExtInfo.path)
        },
        fieldVal(): Array<number> | null | undefined {
            return this.getFieldVal(this.stay_id, this.fieldPath)
        },
        textOutput(): string | undefined {
            switch (this.field_name) {
                case "visual_fields":
                    return textNihss.visualFields(this.getFieldVal(this.stay_id, this.fieldPath))
                case "visual_inattention":
                    return textNihss.visualInattention(this.getFieldVal(this.stay_id, this.fieldPath))
                default:
                    return
            }
        }
    },
    watch: {
        normalVal(val) {
            if (val)
                this.setFieldVal(this.stay_id, this.fieldPath, clone(this.allNormalArray))
        },
        fieldVal(val) {
            if (isArray(val) && !isEqual(val, this.allNormalArray))
                this.setFieldVal(this.stay_id, this.normalPropExtInfo.path, false)
        }
    },
    components: {
        RInput,
        Eye
    }
})


var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        title: "Username changed",
        id: _vm.htmlId,
        "no-close-on-backdrop": true,
        "no-close-on-esc": true,
        "hide-header-close": true,
        visible: _vm.isAlt,
        "hide-footer": _vm.maxedLogins,
        "ok-only": ""
      }
    },
    [
      _c("p", [
        _vm._v("Please use "),
        _c("em", { staticClass: "text-monospace" }, [
          _vm._v(_vm._s(_vm.username))
        ]),
        _vm._v(" as your username to login in the future.")
      ]),
      _vm._v(" "),
      _vm.maxedLogins
        ? [
            _c("p", [
              _vm._v("You will no longer be able to use "),
              _c("em", { staticClass: "text-monospace" }, [
                _vm._v(_vm._s(_vm.altUsername))
              ]),
              _vm._v(" to login.")
            ]),
            _vm._v(" "),
            _c(
              "b-form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.checkAndClose.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label:
                        "Please type in your new username and press OK to acknowledge this message.",
                      "label-for": "new-usrnam",
                      "invalid-feedback": "Input does not match new username",
                      state: _vm.confirmState
                    }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        name: "new-usrnam",
                        type: "text",
                        autocomplete: "new-password",
                        autofocus: ""
                      },
                      model: {
                        value: _vm.ackUsername,
                        callback: function($$v) {
                          _vm.ackUsername = $$v
                        },
                        expression: "ackUsername"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  { attrs: { type: "submit", variant: "primary" } },
                  [_vm._v("OK")]
                )
              ],
              1
            )
          ]
        : _c("p", [
            _vm._v("You may use "),
            _c("em", { staticClass: "text-monospace" }, [
              _vm._v(_vm._s(_vm.altUsername))
            ]),
            _vm._v(
              " up to " +
                _vm._s(_vm.remainingAltLogins) +
                " more times before it is disabled."
            )
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
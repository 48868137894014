








import Vue from 'vue'
import stays from '@store/stays'
import { QuestionCategory } from 'models/med_templates/question'
import Questions from '@stayinputs/Questions.vue'

export default Vue.extend({
    components: {
        Questions,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            QuestionCategory,
        }
    },
    computed: {
        syndrome() {
            return stays.getSyndrome(this.stay_id)
        }
    },
})

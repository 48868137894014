


























































import Vue from 'vue'
import * as moment from 'moment'
import utils from 'utils'
import DateTimePicker from '@shared/inputs/DateTimePicker.vue'

const DATE_FORMAT = 'YYYY-MM-DD'
type CUSTOM_RANGE = 'this_wk' | 'past_7d' | 'past_14d' | 'this_mnth' | 'roll_mnth'

function oneMonthAgo() {
    return moment().subtract(1, 'month').format(DATE_FORMAT)
}

function thisMonth() {
    return moment().startOf('month').format(DATE_FORMAT)
}

function twoWeeksAgo() {
    return moment().subtract(2, 'weeks').format(DATE_FORMAT)
}

function today() {
    return moment().format(DATE_FORMAT)
}

export default Vue.extend({
    components: {
        DateTimePicker,
    },
    props: {
        start: {
            type: String,
            required: false,
            default: twoWeeksAgo,
        },
        end: {
            type: String,
            required: false,
            default: today,
        },
    },
    data() {
        const jumps: { title: string, range: CUSTOM_RANGE }[] = []
        jumps.push({ title: 'This week', range: 'this_wk' })
        jumps.push({ title: 'Past 7 days', range: 'past_7d' })
        jumps.push({ title: 'Past 2 weeks', range: 'past_14d' })
        jumps.push({ title: 'This month', range: 'this_mnth' })
        jumps.push({ title: 'Past 30 days', range: 'roll_mnth' })

        return {
            jumps,
        }
    },
    computed: {
        startDate: {
            get() {
                return this.start
            },
            set(val: string | null) {
                if (val)
                    this.navigateTo(val, this.end)
            }
        },
        endDate: {
            get() {
                return this.end
            },
            set(val: string | null) {
                if (val)
                    this.navigateTo(this.start, val)
            }
        },
    },
    created() {
        const params = this.$route.params
        if (!params.start || !params.end)
            this.navigateTo(this.start, this.end)
    },
    methods: {
        navigateTo(start: string, end: string) {
            this.$router
            .push({ name: this.$route.name, params: { start, end }})
            .catch(err => {
                if (err.name !== 'NavigationDuplicated')
                    utils.handleRequestError(err)
            })
        },
        setCustomRange(val: CUSTOM_RANGE) {
            if (!val)
                return

            switch (val) {
                case 'this_wk':
                    this.navigateTo(moment().startOf('week').format(DATE_FORMAT), today())
                    break
                case 'past_7d':
                    this.navigateTo(moment().subtract(1, 'week').format(DATE_FORMAT), today())
                    break
                case 'past_14d':
                    this.navigateTo(twoWeeksAgo(), today())
                    break
                case 'this_mnth':
                    this.navigateTo(thisMonth(), today())
                    break
                case 'roll_mnth':
                    this.navigateTo(oneMonthAgo(), today())
                    break
                default:  // assume it's a YYYY string
                    const year = moment(val).startOf('year')
                    const followingYear = year.clone().add(11, 'months')
                    this.navigateTo(year.format(DATE_FORMAT), followingYear.format(DATE_FORMAT))
            }
        },
        shiftByMonths(numMonths: number) {
            const start = moment(this.start)
            const end = moment(this.end)
            start.add(numMonths, 'months')
            end.add(numMonths, 'months')
            this.navigateTo(start.format(DATE_FORMAT), end.format(DATE_FORMAT))
        },
        shiftByWeeks(numWeeks: number) {
            const start = moment(this.start)
            const end = moment(this.end)
            start.add(numWeeks, 'weeks')
            end.add(numWeeks, 'weeks')
            this.navigateTo(start.format(DATE_FORMAT), end.format(DATE_FORMAT))
        },
    }
})

import { string as dstring, number as dnumber, boolean as dboolean, array, exact, guard } from 'decoders'

export interface Item {
    id: number
    title: string
}

export const itemDef = {
    id: dnumber,
    title: dstring
}

export const item = exact(itemDef)

export const itemDecoder = guard(item, { style: 'simple' })
export const itemsDecoder = guard(array(item), { style: 'simple' })


export interface OrderedItem extends Item {
    order: number
}

export const orderedItemDef = {
    ...itemDef,
    order: dnumber
}

export const orderedItem = exact(orderedItemDef)


export interface ItemExt extends Item {
    active: boolean
}

export const itemExtDef = {
    ...itemDef,
    active: dboolean,
}

export const itemExt = exact(itemExtDef)


try { const _item: Item = itemDecoder({}) } catch(e) {}
try { const _orderedItem: OrderedItem = guard(orderedItem)({}) } catch(e) {}
try { const _itemExt: ItemExt = guard(itemExt)({}) } catch(e) {}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ml-3 d-flex align-items-center",
      staticStyle: { opacity: "0.7" }
    },
    [
      _vm.hasUnsavedChanges
        ? _c("small", [_vm._m(0)])
        : !_vm.showPendingOnly
        ? _c("small", [_c("u", [_vm._v("All changes saved")])])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("u", { staticClass: "loading-dots" }, [
      _vm._v("Saving"),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
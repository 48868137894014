












































































import Vue from 'vue'
import * as moment from 'moment'
import { Location } from 'vue-router'
import { some } from 'lodash-es'
import utils from 'utils'

import { ASMT_ADM, ASMT_SPECIALITY, ASMT_WARD } from 'routers/constants'
import {
    Patient, anonymisedPatient,
    PatientData, PatientStay, StayBase, Combined
} from 'models/paddock'
import StaySummary from './PaddockSearchStaySummary.vue'

export default Vue.extend({
    components: {
        StaySummary,
    },
    props: {
        patientData: {
            type: Object as () => PatientData,
            required: true
        },
        combined: {
            type: Object as () => Combined,
            required: true
        },
        /** if isOld is true, ackOld also needs to be true stay to be opened/consulted */
        isOld: {
            type: Boolean,
            default: false
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            uid,
            ackOldModalId: `${uid}___ack_old`,
            consultModalId: `${uid}___consult`,
            ackOld: false,
        }
    },
    computed: {
        /** the true stay to derive summary info from */
        refStay(): StayBase | null {
            if (this.combined.sstay)
                return this.combined.sstay.primary || this.combined.sstay
            return this.combined.pstay
        },
        admittedSince(): string {
            if (!this.refStay)
                return ''
            const now = moment()
            const admitted = moment(this.refStay.admission_time)
            return moment.duration(now.diff(admitted)).humanize()
        },
        oldAcked(): boolean {
            return this.isOld ? (this.isOld && this.ackOld) : true
        },
        label(): string {
            return this.combined.sstay ? this.dataEntryLabel_fn(this.combined.sstay) : 'Consult'
        },
        dataEntryRoute(): Location | undefined {
            if (!this.oldAcked) return
            return this.combined.sstay ? this.dataEntryRoute_fn(this.combined.sstay) : undefined
        },
        modalId(): string | undefined {
            if (!this.oldAcked)
                return this.ackOldModalId
            if (this.dataEntryRoute)
                return
            return this.consultModalId
        },
    },
    methods: {
        formatDT(dt_str?: string) {
            if (!dt_str)
                return '-'
            const m = moment(dt_str)
            return m.format("h:mma Do MMM YYYY")
        },
        formatPatient(origPatient: Patient) {
            const patient = this.$store.direct.state.user.anonymise ? anonymisedPatient(origPatient) : origPatient
            return `${patient.last_name}, ${patient.first_name}`
        },
        dataEntryRoute_fn(sstay: PatientStay): Location {
            switch (sstay.stage) {
                case 'UNKNOWN':
                    return {
                        name: ASMT_SPECIALITY,
                        params: { stay_id: `${sstay.id}` }
                    }
                case 'ADMISSION':
                    return {
                        name: ASMT_ADM,
                        params: { stay_id: `${sstay.id}` }
                    }
                case 'WARD':
                    return {
                        name: ASMT_WARD,
                        params: { stay_id: `${sstay.id}` }
                    }
            }
            return {}
        },
        dataEntryLabel_fn(sstay: PatientStay): string {
            let entryLabel = '?'
            switch (sstay.stage) {
                case 'ADMISSION':
                    entryLabel = 'Admission'
                    break
                case 'WARD':
                    entryLabel = 'Review'
                    break
                case 'UNKNOWN':
                    entryLabel = 'Start'
                    break
            }
            return entryLabel
        },
        canConsult(data: PatientData): boolean {
            if (some(data.stays, stayData => !!stayData.sstay && stayData.sstay.active))
                return false
            return true
        },
        startConsult(patient: Patient, combined: Combined) {
            this.$emit('start-consult', { patient, combined })
        },
    }
})


















































import mixins from 'vue-typed-mixins'
import { difference, get, filter, findIndex } from 'lodash-es'
import stays from '@store/stays'
import { OtherReadyWhen } from 'models/data/discharge'
import InputMixin from '@mixins/Input.vue'
import Checkbox from '@shared/inputs/Checkbox.vue'


export default mixins(InputMixin).extend({
    components: {
        Checkbox,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            updated: 0
        }
    },
    computed: {
        readyWhen: {
            get(): string[] {
                return this.getFieldVal(this.stay_id, 'discharge.ready_when') || []
            },
            set(choices: string[]) {
                const current = this.getFieldVal(this.stay_id, 'discharge.ready_when')
                if (current.length > 1) {
                    // only allow removing
                    if (choices.length < current.length)
                        this.setFieldVal(this.stay_id, 'discharge.ready_when', choices)
                    else
                        this.$toasted.info(
                            'This is now restricted to a single choice. Please reduce selection to one or zero to choose something different.',
                            { position: 'top-center', duration: 5000 }
                        )
                }
                else if (current.length === 1) {
                    if (choices.length === 0)
                        this.setFieldVal(this.stay_id, 'discharge.ready_when', choices)
                    else {
                        // changing new choice for old choice
                        const diff = difference(choices, current)
                        this.setFieldVal(this.stay_id, 'discharge.ready_when', diff)
                    }
                }
                else {
                    this.setFieldVal(this.stay_id, 'discharge.ready_when', choices)
                }
                this.updated++
            }
        },
        readyWhenChoices() {
            const speciality = stays.getSpeciality(this.stay_id)
            return get(speciality, 'ready_for_discharge_when') || []
        },
        otherReadyWhen: {
            get(): OtherReadyWhen[] {
                const steps = this.getFieldVal(this.stay_id, 'discharge.other_ready_when') || []
                return filter(steps, ['deleted', false])
            },
            set(steps: OtherReadyWhen[]) {
                this.setFieldVal(this.stay_id, 'discharge.other_ready_when', steps)
            }
        },
    },
    methods: {
        addOtherReadyWhen() {
            stays.actAddOtherReadyWhen(this.stay_id)
        },
        deleteOtherReadyWhen(step: OtherReadyWhen) {
            const stepIndex = findIndex(this.otherReadyWhen, ['id', step.id])
            const steps = this.otherReadyWhen.slice(0)
            steps[stepIndex].deleted = true
            this.otherReadyWhen = steps
        },
        updateOtherReadyWhen(step: OtherReadyWhen) {
            const stepIndex = findIndex(this.otherReadyWhen, ['id', step.id])
            const steps = this.otherReadyWhen.slice(0)
            steps[stepIndex] = step
            this.otherReadyWhen = steps
        }
    },
})

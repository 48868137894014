import text from 'utils/text'
import { diagnosisSummary } from 'utils/text/diagnosis'
import { background, socialHistory } from 'utils/text/clinic_history'
import { dischargePlanFinal } from 'utils/text/plan'
import { Stay } from 'models/data/stay'

export function dischargeMarkup(stay: Stay | undefined, anonymise: boolean) {
    if (!stay) return ''

    let markup = ''
    const textDetails = { stay, anonymise }

    markup += 'Patient details: ' + text.patientDetails(textDetails) + '\n\n'

    const admissionSummary = text.fieldText(textDetails, 'admission_summary')
    if (admissionSummary.length) markup += admissionSummary + "\n\n"

    markup += text.primaryDiagnosisWithKeyPoints(textDetails)

    markup += diagnosisSummary(textDetails) + '\n\n'
    markup += text.activeIssuesSummary({ stay, title: "Issues during admission" })
    markup += text.resolvedIssuesWithDetail(textDetails)
    markup += '\n' + background(textDetails)
    markup += socialHistory(textDetails)
    markup += text.baselineInvestigations({
        stay,
        title: "Summary baseline investigations (Formal reports below)"
    })
    markup += text.completedInvestigationsSummary({
        stay,
        title: "Summary inpatient investigations (Formal reports below)"
    })

    markup += text.outstandingInvestigations(textDetails)

    if (stay.discharge) {
        const meds_changed: string = stay.discharge.meds_changed
        if (meds_changed && meds_changed.length > 0)
            markup += `**Medications changed**  \n${text.freeTextSubHtmlBreaks(meds_changed)}\n\n`
    }
    markup += dischargePlanFinal(textDetails)

    return markup
}

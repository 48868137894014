











import Vue from 'vue'

import { Diagnosis } from 'models/med_templates/diagnosis'


export default Vue.extend({
    props: {
        diagnosis: {
            type: Object as () => Diagnosis,
            required: false,
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-6" },
        [
          _c("eyes", {
            attrs: {
              stay_id: _vm.stay_id,
              nihss_id: _vm.nihss_id,
              field_name: "visual_fields",
              title: "Visual Fields"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-sm-6" },
        [
          _c("eyes", {
            attrs: {
              stay_id: _vm.stay_id,
              nihss_id: _vm.nihss_id,
              field_name: "visual_inattention",
              title: "Visual Inattention"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "b-form-group",
            {
              staticClass: "mb-0",
              attrs: { label: "Best gaze" },
              scopedSlots: _vm._u(
                [
                  _vm.debugMode
                    ? {
                        key: "description",
                        fn: function() {
                          return [
                            _c("span", { staticClass: "text-monospace" }, [
                              _vm._v(_vm._s(_vm.gazeDebugText))
                            ])
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              )
            },
            [
              _vm._v(" "),
              _c("div", { staticClass: "row hover-effect" }, [
                _c("div", { staticClass: "col-6 col-sm-8" }, [
                  _c("div", { staticClass: "padded" }, [_vm._v("0: Normal")])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-3 offset-3 col-sm-3 offset-sm-1 py-2" },
                  [
                    _c("b-check", {
                      staticClass: "scrawl-checkbox",
                      attrs: { size: "lg", "unchecked-value": null },
                      model: {
                        value: _vm.gazeNormal,
                        callback: function($$v) {
                          _vm.gazeNormal = $$v
                        },
                        expression: "gazeNormal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6 col-sm-8" }),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 col-sm-2" }, [_vm._v("Right")]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 col-sm-2" }, [_vm._v("Left")])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "col-6 col-sm-8" }, [
                    _c("div", { staticClass: "padded" }, [
                      _vm._v("1: Unable to look completely to the ...")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.gazeEnums.unable, function(gEnum) {
                    return _c(
                      "div",
                      { key: gEnum, staticClass: "col-3 col-sm-2 py-2" },
                      [
                        _c("b-check", {
                          staticClass: "scrawl-checkbox",
                          attrs: {
                            size: "lg",
                            value: gEnum,
                            "unchecked-value": null
                          },
                          model: {
                            value: _vm.gaze,
                            callback: function($$v) {
                              _vm.gaze = $$v
                            },
                            expression: "gaze"
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("div", { staticClass: "col-6 col-sm-8" }, [
                    _c("div", { staticClass: "padded" }, [
                      _vm._v("2: Forced deviation to the ...")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.gazeEnums.forced, function(gEnum) {
                    return _c(
                      "div",
                      { key: gEnum, staticClass: "col-3 col-sm-2 py-2" },
                      [
                        _c("b-check", {
                          staticClass: "scrawl-checkbox",
                          attrs: {
                            size: "lg",
                            value: gEnum,
                            "unchecked-value": null
                          },
                          model: {
                            value: _vm.gaze,
                            callback: function($$v) {
                              _vm.gaze = $$v
                            },
                            expression: "gaze"
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }











import Vue from 'vue'
import { each } from 'lodash-es'
export default Vue.extend({
    data() {
        return {
            iconSets: {
                fas: [
                    // free solid
                    'bug',
                    'chevron-down',
                    'chevron-left',
                    'chevron-right',
                    'chevron-up',
                    'edit',
                    'plus',
                    'play-circle',
                    'plus',
                    'stop-circle',
                    // pro solid
                    'chevron-double-left',
                    'chevron-double-right',
                ],
                far: [
                    'arrow-to-bottom',
                    'arrow-to-right',
                    'arrows-alt-v',
                ],
                fal: [
                    // pro light
                    'angle-double-down',
                    'calendar',
                    'check',
                    'check-circle',
                    'exclamation',
                    'file-alt',
                    'files-medical',
                    'heartbeat',
                    'info-circle',
                    'lungs',
                    'minus',
                    'notes-medical',
                    'question-circle',
                    'search',
                    'sync-alt',
                    'thermometer',
                    'times',
                    'times-circle',
                ]
            }
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        icons(): [string, string][] {
            const icons: [string, string][] = []
            each(this.iconSets, (iconSet, setDesc) => {
                each(iconSet, iconDesc => {
                    icons.push([setDesc, iconDesc])
                })
            })
            return icons
        }
    }
})

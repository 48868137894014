var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stays.length
    ? _c(
        "span",
        { attrs: { title: "Show detailed breakdown" } },
        [
          _c("fnt-a-icon", {
            staticStyle: { cursor: "pointer" },
            attrs: { icon: ["fal", "info-circle"] },
            on: {
              click: function($event) {
                _vm.showModal = !_vm.showModal
              }
            }
          }),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                title: _vm.title,
                size: "lg",
                scrollable: "",
                okOnly: ""
              },
              model: {
                value: _vm.showModal,
                callback: function($$v) {
                  _vm.showModal = $$v
                },
                expression: "showModal"
              }
            },
            [
              _c(
                "b-list-group",
                { attrs: { role: "tablist" } },
                _vm._l(_vm.stays, function(stay) {
                  return _c(
                    "div",
                    { key: stay.id },
                    [
                      _c(
                        "b-list-group-item",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value: _vm.summaryId(stay.id),
                              expression: "summaryId(stay.id)"
                            }
                          ],
                          staticClass: "r-clickable px-4 stay-details",
                          attrs: { role: "tab" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.patientSummary(stay)) +
                                    "\n                        "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _c("span", { staticClass: "mr-3" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.title) +
                                        ": " +
                                        _vm._s(_vm.fieldValue(stay)) +
                                        "\n                            "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("fnt-a-icon", {
                                    staticClass: "show-more",
                                    attrs: { icon: ["fas", "chevron-down"] }
                                  }),
                                  _vm._v(" "),
                                  _c("fnt-a-icon", {
                                    staticClass: "show-less",
                                    attrs: { icon: ["fas", "chevron-up"] }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: _vm.summaryId(stay.id),
                            role: "tabpanel"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between bg-dark mb-2 py-2 px-2"
                            },
                            [
                              _c("stay-text-summary", {
                                staticStyle: { "flex-grow": "1" },
                                attrs: {
                                  "text-version": "hyperacute",
                                  stay_id: stay.id
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        size: "sm",
                                        variant: "info",
                                        to: {
                                          name: "edit-stay",
                                          params: { stay_id: stay.id }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Edit\n                            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
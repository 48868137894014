var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay
    ? _c(
        "edit-modal",
        { attrs: { stay_id: _vm.stay_id, text: _vm.label, scrollable: false } },
        [_c("care-classification", { attrs: { stay_id: _vm.stay_id } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }























































import mixins from 'vue-typed-mixins'
import { each, find } from 'lodash-es'
import { Question as QuestionSchema } from 'models/med_templates/question'

import AdvInvMixin from './AdvInvMixin.vue'
import Question from '@stayinputs/Question.vue'

interface Selection {
    findings: number | null
    sides: number[]
    apl_site: number | null
}

export default mixins(AdvInvMixin).extend({
    components: {
        Question
    },
    data() {
        const selection: Selection = {
            findings: null,
            sides: [],
            apl_site: null
        }

        return {
            fieldIds: {
                findings: ['normal', 'apl'],
                sides: ['right', 'left'],
                apl_site: ['apl_anterior', 'apl_posterior']
            },
            questionIds: ['ctp_lesion_core_mls', 'ctp_lesion_pen_mls'],
            selection,
        }
    },
    computed: {
        volumeQuestions(): QuestionSchema[] {
            const questions: QuestionSchema[] = []
            each(this.questionIds, qId => {
                const q = find(this.$store.direct.state.templates.custom_qs, {help_text: qId})
                if (q) questions.push(q)
            })
            return questions
        },
        isAPL(): boolean {
            return this.selection.findings === this.fieldToIdMap['apl']
        },
        localResults(): number[] {
            let results: number[] = []
            results = results.concat(this.selection.sides)
            if (this.selection.findings) results.push(this.selection.findings)
            if (this.selection.apl_site) results.push(this.selection.apl_site)
            return results
        },
    },
    watch: {
        'selection.findings': function(val: number | null) {
            if (val === this.fieldSchemaLookup['normal'].id) {
                this.selection.sides = []
                this.selection.apl_site = null
            }
        },
    }
})









import Vue from 'vue'
import PrintBase from './PrintListBase.vue'

export default Vue.extend({
    props: {
        stay_ids: {
            type: Array,
            required: true
        },
    },
    components: {
        PrintBase,
    },
})

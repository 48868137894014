



























import Vue from 'vue'
import mixins from 'vue-typed-mixins'
import { clone, each, filter, includes, isEqual, xor } from 'lodash-es'
import { Speciality } from 'models/med_templates/speciality'
import InputMixin from '@mixins/Input.vue'
import StayMixin from '@mixins/Stay.vue'

export default mixins(InputMixin, StayMixin).extend({
    data() {
        return {
            /** describes whether the dropdown is opened/closed */
            opened: false,
            selecting: false,
        }
    },
    computed: {
        specialities(): Speciality[] {
            const selectedIds = this.$store.direct.state.user.filters.specialities
            const allSpecialities = this.$store.direct.state.templates.specialities
            return selectedIds.length ?
                filter(allSpecialities, speciality => includes(selectedIds, speciality.id))
                : allSpecialities
        },
        groupedDoctors() {
            return this.$store.direct.getters.templates.groupedDoctors
        },
        /** computed showing there is at least one doctor available for selection */
        atLeastOne(): boolean {
            let atLeastOne = false
            each(this.groupedDoctors, group => {
                if (group.doctors.length) {
                    atLeastOne = true
                    return false
                }
                return
            })
            return atLeastOne
        },
        selectedDoctor: {
            get(): number | null {
                if (!this.stay)
                    return null
                return this.stay.doctor as number | null
            },
            set(val: number | null) {
                this.setFieldVal(this.stay_id, 'doctor', val)
            }
        },
        selectedText(): string {
            const doctor = this.$store.direct.getters.templates.doctor(this.selectedDoctor || -1)
            return doctor ? doctor.last_name : '[Select]'
        },
    },
    /*
    watch: {
        selectedDoctors: {
            handler: function(val: any) {
                if (!isEqual(val, this.selected))
                    this.selected = clone(val)
            },
            immediate: true
        },
        opened(val: any) {
            if (!val)
                this.selectedDoctors = this.selected
        }
    },
    */
    methods: {
        showHandler(event: any) {
            this.opened = true
        },
        hideHandler(event: any) {
            if (this.selecting) {
                // Don't close the dropdown if we're currently selecting items
                event.preventDefault()
            }
            else {
                this.opened = false
            }
            this.selecting = false
        },
        /*
        clearSelections() {
            this.selected = []
        },
        */
        toggleSelect(id: number) {
            this.selecting = true
            this.selectedDoctor = id
        },
        isSelected(id: number): boolean {
            return this.selectedDoctor === id
        },
    }
})


import mixins from 'vue-typed-mixins'
import * as moment from 'moment'
import { includes } from 'lodash-es'

import utils from 'utils'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'

import DisplayMixin from '@mixins/Display.vue'
import InputMixin from '@mixins/Input.vue'

export default mixins(DisplayMixin, InputMixin).extend({
    props: {
        stay: {
            type: Object as () => Stay,
            required: true
        },
    },
    data() {
        return {
            dischargeModalID: `${utils.getUID()}__${this.stay.id}_discharge_modal`,
            visible: false,
        }
    },
    computed: {
        isActive(): boolean {
            return this.stay.active
        },
        shouldHide(): boolean {
            return this.$store.direct.state.user.hide_stays
        },
        hiddenIds(): number[] {
            return this.$store.direct.state.user.hidden_stays
        },
        inHiddenIds(): boolean {
            return includes(this.hiddenIds, this.stay.id)
        },
        isHidden(): boolean {
            return (this.shouldHide && this.inHiddenIds)
        },
        patientName(): string {
            return utils.patientName(this.stay, this.$store.direct.state.user.anonymise)
        },
        diagnosisText(): string {
            return this.stay?.diagnosis_title_ext || ''
        },
        timeoutPeriod(): number {
            return this.$store.direct.state.session.changesMaxAge + 1000
        },
    },
    methods: {
        toggleHide() {
            this.$store.direct.dispatch.user.toggleStayHide(this.stay.id)
        },
        dischargeStay() {
            this.setFieldVal(this.stay.id, 'active', false)
            this.setFieldVal(this.stay.id, 'discharge_time', moment().toISOString())
            setTimeout(() => {
                stays.mutTriggerLoadStays()
            }, this.timeoutPeriod)
        },
        undischargeStay() {
            this.setFieldVal(this.stay.id, 'active', true)
            this.setFieldVal(this.stay.id, 'discharge_time', null)
            setTimeout(() => {
                stays.mutTriggerLoadStays()
            }, this.timeoutPeriod)
        }
    }
})

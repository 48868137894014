




















import mixins from 'vue-typed-mixins'
import StaysMixin from '../mixins/Stays.vue'
import utils from 'utils'
import { Stay } from 'models/data/stay'

export default mixins(StaysMixin).extend({
    props: {
        stay: {
            type: Object as () => Stay,
            required: true
        },
        add_mb: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const uid = utils.getUID()
        const baseId = `s${this.stay.id}`
        return {
            dischargeId: `${uid}___${baseId}_dsch_confirm`,
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        rapidAsmtActive(): boolean {
            return this.stay.rapid_asmt_discharge_time === null
        },
        actionLabel(): string {
            return this.rapidAsmtActive ? 'Nurse D/c' : 'Nurse Un-D/c'
        },
        patientText(): string {
            return this.patientNameAndAge(this.stay)
        },
    },
})

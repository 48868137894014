var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.questionGroups, function(group, index) {
        return _c(
          "div",
          [
            group.title
              ? _c(
                  "div",
                  {
                    staticClass: "mb-0 py-1",
                    class: {
                      "mt-5": index > 0,
                      "mt-3": index === 0,
                      "border-top":
                        (_vm.firstItemTopBorder && index === 0) || index > 0
                    }
                  },
                  [
                    _c("span", { class: { h5: !_vm.smallGroupTitle } }, [
                      _vm._v(_vm._s(group.title))
                    ]),
                    _vm._v(" "),
                    group.link
                      ? _c("span", [
                          _vm._v("\n                ["),
                          _c(
                            "a",
                            {
                              staticClass: "text-info",
                              attrs: { href: group.link, target: "_blank" }
                            },
                            [_vm._v("Link")]
                          ),
                          _vm._v("]\n            ")
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(group.questions, function(question) {
              return _c(
                "question",
                _vm._g(
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showQuestion(question),
                        expression: "showQuestion(question)"
                      }
                    ],
                    key: question.question.id,
                    staticClass: "py-2",
                    class: _vm.questionClass,
                    attrs: {
                      indentSub: "",
                      stay_id: _vm.stay_id,
                      oq: question,
                      question: question.question,
                      inline: _vm.inline,
                      setAnswer: _vm.setAnswer,
                      getAnswer: _vm.getAnswer
                    }
                  },
                  _vm.$listeners
                )
              )
            }),
            _vm._v(" "),
            _vm.showNIHSS(group)
              ? [
                  _c(
                    "b-btn",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal",
                          value: _vm.nihssModalId,
                          expression: "nihssModalId"
                        }
                      ],
                      attrs: { variant: "info" }
                    },
                    [
                      _vm._v("\n                NIHSS\n                "),
                      _vm.nihssV1
                        ? [
                            _vm.rapidAsmtNihssComplete
                              ? _c("span", [
                                  _vm._v(
                                    "(" + _vm._s(_vm.rapidAsmtNihssScore) + ")"
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class: _vm.nihssCompleteColorClass(
                                  _vm.rapidAsmtNIHSS
                                )
                              },
                              [
                                _c("fnt-a-icon", {
                                  attrs: {
                                    icon: _vm.nihssCompleteIcon(
                                      _vm.rapidAsmtNIHSS
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.nihssBtnText) +
                          "\n            "
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: _vm.nihssModalId,
                        title: "NIHSS",
                        size: "xl",
                        "ok-only": ""
                      }
                    },
                    [
                      _vm.nihssV1
                        ? _c("nihss", {
                            attrs: {
                              stay_id: _vm.stay_id,
                              prefix: "rapid_asmt"
                            }
                          })
                        : _c("nihss-select", {
                            attrs: { stay_id: _vm.stay_id }
                          })
                    ],
                    1
                  )
                ]
              : _vm._e()
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm.editVisibleGroups && _vm.specialityQuestionGroups.length
        ? _c(
            "div",
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal",
                      value: _vm.modalID,
                      expression: "modalID"
                    }
                  ],
                  attrs: { size: "sm", variant: "info" }
                },
                [
                  _vm._v(
                    "\n            More " +
                      _vm._s(_vm.addText.toLowerCase()) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: _vm.modalID,
                    title: _vm.addText,
                    centered: "",
                    "ok-only": "",
                    scrollable: ""
                  }
                },
                [
                  _c("add-question-groups", {
                    attrs: {
                      stay_id: _vm.stay_id,
                      questionGroups: _vm.specialityQuestionGroups
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-btn",
        _vm._b(
          {
            directives: [
              {
                name: "b-modal",
                rawName: "v-b-modal",
                value: _vm.modalID,
                expression: "modalID"
              }
            ],
            attrs: { variant: "info" }
          },
          "b-btn",
          _vm.$attrs,
          false
        ),
        [_vm._v("\n        " + _vm._s(_vm.addLabel) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            scrollable: "",
            id: _vm.modalID,
            title: _vm.addLabel,
            "ok-title": "Add",
            "ok-variant": "success"
          },
          on: { ok: _vm.addIssues }
        },
        [
          _vm._l(_vm.issueGroups, function(group) {
            return _c(
              "div",
              { key: group.id, staticClass: "mb-4" },
              [
                _c("h6", [_c("strong", [_vm._v(_vm._s(group.title))])]),
                _vm._v(" "),
                _vm._l(group.issues, function(ref) {
                  var id = ref.id
                  var title = ref.title
                  return _c(
                    "b-check",
                    {
                      key: id,
                      staticClass: "scrawl-checkbox",
                      attrs: { size: "lg", value: id },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " + _vm._s(title) + "\n            "
                      )
                    ]
                  )
                })
              ],
              2
            )
          }),
          _vm._v(" "),
          _c("h6", [_c("strong", [_vm._v("Other")])]),
          _vm._v(" "),
          _c(
            "b-check",
            {
              staticClass: "scrawl-checkbox d-inline-block",
              attrs: {
                size: "lg",
                value: _vm.otherValue,
                "unchecked-value": null
              },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            },
            [!_vm.otherTicked ? _c("span", [_vm._v("Other")]) : _vm._e()]
          ),
          _vm._v(" "),
          _vm.otherTicked
            ? _c("b-form-input", {
                ref: "otherIssueTitle",
                staticClass: "py-0 pl-2 d-inline-block",
                staticStyle: { width: "auto" },
                attrs: { type: "text", placeholder: "[title]", autofocus: "" },
                on: { focus: _vm.selectOtherTitle },
                model: {
                  value: _vm.otherLabel,
                  callback: function($$v) {
                    _vm.otherLabel = $$v
                  },
                  expression: "otherLabel"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4" })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    [
      _c("label", { staticClass: "mb-0" }, [
        _vm._v("\n        " + _vm._s(_vm.label) + "\n    ")
      ]),
      _vm._v(" "),
      _c("date-time-picker", {
        staticClass: "col-sm-4",
        attrs: { config: { wrap: true, enableTime: true } },
        model: {
          value: _vm.admissionTime,
          callback: function($$v) {
            _vm.admissionTime = $$v
          },
          expression: "admissionTime"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-body" },
    [
      _c("dropdown", {
        staticClass: "mb-0",
        attrs: { items: _vm.teams, "text-field": "title" },
        model: {
          value: _vm.teamID,
          callback: function($$v) {
            _vm.teamID = $$v
          },
          expression: "teamID"
        }
      }),
      _vm._v(" "),
      _c("fieldset", [
        _c("legend", { staticClass: "col-form-label pb-1" }, [
          _vm._v("Seen by")
        ]),
        _vm._v(" "),
        _c(
          "fieldset",
          [
            _vm._l(_vm.doctors, function(doctor) {
              return _c(
                "div",
                {
                  key: doctor.id,
                  staticClass: "pretty p-svg p-plain p-bigger my-2"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.doctorsPresent,
                        expression: "doctorsPresent"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      value: doctor.id,
                      checked: Array.isArray(_vm.doctorsPresent)
                        ? _vm._i(_vm.doctorsPresent, doctor.id) > -1
                        : _vm.doctorsPresent
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.doctorsPresent,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = doctor.id,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.doctorsPresent = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.doctorsPresent = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.doctorsPresent = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "state p-info-o" },
                    [
                      _c("fnt-a-icon", {
                        staticClass: "svg",
                        attrs: { icon: ["fal", "check"] }
                      }),
                      _vm._v(" "),
                      _c("label", [_vm._v(_vm._s(doctor.title))])
                    ],
                    1
                  )
                ]
              )
            }),
            _vm._v(" "),
            _vm.debugMode
              ? _c(
                  "small",
                  { staticClass: "form-text text-muted text-monospace" },
                  [_vm._v(_vm._s(_vm.doctorsDebugText))]
                )
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "fieldset",
          [
            _vm._l(_vm.teamMembers, function(member) {
              return _c(
                "div",
                {
                  key: member.id,
                  staticClass: "pretty p-svg p-plain p-bigger my-2"
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.teamPresent,
                        expression: "teamPresent"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      value: member.id,
                      checked: Array.isArray(_vm.teamPresent)
                        ? _vm._i(_vm.teamPresent, member.id) > -1
                        : _vm.teamPresent
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.teamPresent,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = member.id,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.teamPresent = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.teamPresent = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.teamPresent = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "state p-info-o" },
                    [
                      _c("fnt-a-icon", {
                        staticClass: "svg",
                        attrs: { icon: ["fal", "check"] }
                      }),
                      _vm._v(" "),
                      _c("label", [_vm._v(_vm._s(member.title))])
                    ],
                    1
                  )
                ]
              )
            }),
            _vm._v(" "),
            _vm.debugMode
              ? _c(
                  "small",
                  { staticClass: "form-text text-muted text-monospace" },
                  [_vm._v(_vm._s(_vm.membersDebugText))]
                )
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "col-form-label pb-1" }, [
            _vm._v("Patient & family present")
          ]),
          _vm._v(" "),
          _vm._l(_vm.people, function(person) {
            return _c(
              "div",
              {
                key: person.id,
                staticClass: "pretty p-svg p-plain p-bigger my-2"
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.peoplePresent,
                      expression: "peoplePresent"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: person.id,
                    checked: Array.isArray(_vm.peoplePresent)
                      ? _vm._i(_vm.peoplePresent, person.id) > -1
                      : _vm.peoplePresent
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.peoplePresent,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = person.id,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.peoplePresent = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.peoplePresent = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.peoplePresent = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "state p-info-o" },
                  [
                    _c("fnt-a-icon", {
                      staticClass: "svg",
                      attrs: { icon: ["fal", "check"] }
                    }),
                    _vm._v(" "),
                    _c("label", [_vm._v(_vm._s(person.title))])
                  ],
                  1
                )
              ]
            )
          }),
          _vm._v(" "),
          _vm.debugMode
            ? _c(
                "small",
                { staticClass: "form-text text-muted text-monospace" },
                [_vm._v(_vm._s(_vm.peopleDebugText))]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
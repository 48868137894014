import { string as dstring, array as darray, exact, guard } from 'decoders'
import { Item, itemDef } from "../base"

export interface Space extends Item {
    beds: string[]
}

const space = exact({
    ...itemDef,
    beds: darray(dstring),
})

export const spacesDecoder = guard(darray(space), { style: 'simple' })

try { const _spaces: Space[] = spacesDecoder({}) } catch(e) {}



































import mixins from 'vue-typed-mixins'
import { debounce, flatten, isFinite, isInteger, round } from 'lodash-es'
import * as moment from 'moment'
import utils from 'utils'
import { ChartOptions } from 'chart.js'
import UpdateChartMixin from '../UpdateChartMixin.vue'

const DATE_FORMAT = 'YYYY-MM'

interface NurseChartsData {
    meta: {
        fields: string[]
        types: string[]
        months: string[]
        raw: object
    },
    data: {
        nel_nr_assessed_by_nurse: number[]
        nel_nr_assessed_by_nurse_is_stroke: number[]
        pct_strokes_nel_nr_assessed_by_nurse: number[]
        pct_strokes_nel_nr_my_stroke_journey: number[]
        pct_strokes_nel_nr_mood_assessed: number[]
        pct_strokes_nel_nr_continence_assessed: number[]
        pct_strokes_nel_nr_nbm_asmt_within_24hrs: number[]
        pct_strokes_nel_nr_nbm_good: number[]
        pct_strokes_nel_nr_nbm_pass: number[]
    }
}

export default mixins(UpdateChartMixin).extend({
    props: {
        start: {
            type: String,
            required: false,
            default: function() {
                return moment().startOf('month').format(DATE_FORMAT)
            },
        },
        end: {
            type: String,
            required: false,
            default: function() {
                return moment().add(1, 'month').startOf('month').format(DATE_FORMAT)
            },
        },
    },
    data() {
        const percentageLineOptions: ChartOptions = {
            legend: { display: true, position: 'bottom' },
            scales: {
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: '%',
                        },
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 20,
                        },
                    },
                ]
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        let prefix: string | undefined
                        if (data.datasets && tooltipItem.datasetIndex !== undefined)
                            prefix = data.datasets[tooltipItem.datasetIndex].label
                        prefix = prefix ? `${prefix}: ` : ''

                        let valStr = '?'
                        if (isFinite(tooltipItem.yLabel))
                            valStr = `${round(tooltipItem.yLabel as number, 1)}`
                        return `${prefix}${valStr}%`
                    }
                },
            },
        }

        return {
            data: null as NurseChartsData | null,
            percentageLineOptions,
        }
    },
    computed: {
        debouncedPull: function(): any {
            return debounce(this.pull, 300, { leading: false, trailing: true }) 
        },
        debouncedRender: function(): any {
            return debounce(this.renderCharts, 300, { leading: false, trailing: true })
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        hospitals(): number[] {
            return this.$store.direct.state.user.filters.hospitals
        },
    },
    watch: {
        hospitals: function(newVal, oldVal) {
            if (utils.isEqual(newVal, oldVal)) return
            this.debouncedPull()
        },
        start() {
            this.debouncedPull()
        },
        end() {
            this.debouncedPull()
        },
        data() {
            this.debouncedRender()
        },
    },
    created() {
        this.pull()
    },
    beforeDestroy() {
        this.removeChart('KPIs')
        this.removeChart('StrokesPctNurseReviewed')
        this.removeChart('CasesReviewed')
        this.removeChart('NBM')
    },
    methods: {
        pull() {
            // return utils.request.get('/static/gosf-nel-nurse-charts.json', undefined, true)
            return utils.request.get(`/charts/nurse/${this.start}/${this.end}/`, { h: this.hospitals })
            .then(res => {
                this.data = res.body
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
        },
        renderCharts(): void {
            if (!this.data)
                return

            this.updateChart({
                title: 'KPIs',
                chartType: 'line',
                data: {
                    labels: this.data.meta.months,
                    datasets: [
                        {
                            label: 'My Stroke Journey',
                            data: this.data.data.pct_strokes_nel_nr_my_stroke_journey,
                            borderColor: '#ffa62b',
                            backgroundColor: '#ffa62b',
                            lineTension: 0.1,
                            fill: false,
                        },
                        {
                            label: 'Mood',
                            data: this.data.data.pct_strokes_nel_nr_mood_assessed,
                            borderColor: '#2274a5',
                            backgroundColor: '#2274a5',
                            lineTension: 0.1,
                            fill: false,
                        },
                        {
                            label: 'Continence',
                            data: this.data.data.pct_strokes_nel_nr_continence_assessed,
                            borderColor: '#a7acd9',
                            backgroundColor: '#a7acd9',
                            lineTension: 0.1,
                            fill: false,
                        },
                    ],
                },
                options: this.percentageLineOptions,
            })

            this.updateChart({
                title: 'StrokesPctNurseReviewed',
                chartType: 'line',
                data: {
                    labels: this.data.meta.months,
                    datasets: [
                        {
                            label: 'Strokes Reviewed',
                            data: this.data.data.pct_strokes_nel_nr_assessed_by_nurse,
                            borderColor: '#f05d5e',
                            backgroundColor: '#f05d5e',
                            lineTension: 0.1,
                            fill: false,
                        },
                    ],
                },
                options: this.percentageLineOptions,
            })


            // Cases Reviewed
            //////////////////
            const { stepSize, yMax } = this.calcChartStepMax(flatten([this.data.data.nel_nr_assessed_by_nurse, this.data.data.nel_nr_assessed_by_nurse_is_stroke]))

            const casesReviewedOptions: ChartOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                },
                scales: {
                    yAxes: [
                        {
                            scaleLabel: {
                                display: true,
                                fontColor: '#ccc',
                                labelString: 'Stays',
                            },
                            ticks: {
                                beginAtZero: true,
                                callback: function(value: number) {
                                    if (isInteger(value)) { return value }
                                    return ''
                                },
                                stepSize,
                                max: yMax,
                            },
                        },
                    ],
                },
            }

            this.updateChart({
                title: 'CasesReviewed',
                chartType: 'bar',
                data: {
                    labels: this.data.meta.months,
                    datasets: [
                        {
                            label: 'All',
                            data: this.data.data.nel_nr_assessed_by_nurse,
                            borderColor: '#4472c4',
                            backgroundColor: '#4472c4',
                            pointBorderWidth: 0,
                            minBarLength: 2,
                        },
                        {
                            label: 'Strokes',
                            data: this.data.data.nel_nr_assessed_by_nurse_is_stroke,
                            borderColor: '#ed7d31',
                            backgroundColor: '#ed7d31',
                            pointBorderWidth: 0,
                            minBarLength: 2,
                        },
                    ]
                },
                options: casesReviewedOptions,
            })

            // NBM
            this.updateChart({
                title: 'NBM',
                chartType: 'line',
                data: {
                    labels: this.data.meta.months,
                    datasets: [
                        {
                            label: 'Assessed within 24hrs',
                            data: this.data.data.pct_strokes_nel_nr_nbm_asmt_within_24hrs,
                            borderColor: '#ffa62b',
                            backgroundColor: '#ffa62b',
                            lineTension: 0.1,
                            fill: false,
                        },
                        {
                            label: 'Pass',
                            data: this.data.data.pct_strokes_nel_nr_nbm_pass,
                            borderColor: '#2274a5',
                            backgroundColor: '#2274a5',
                            lineTension: 0.1,
                            fill: false,
                        },
                        {
                            label: 'Good',
                            data: this.data.data.pct_strokes_nel_nr_nbm_good,
                            borderColor: '#a7acd9',
                            backgroundColor: '#a7acd9',
                            lineTension: 0.1,
                            fill: false,
                        },
                    ],
                },
                options: this.percentageLineOptions,
            })
        },
    }
})

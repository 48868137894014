var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-end",
          class: _vm.stacked ? "row col-12" : "col-3"
        },
        [
          _c(
            "b-form-group",
            {
              staticClass: "mb-0",
              class: { "col-6": _vm.stacked },
              attrs: { "label-class": "pb-0", label: "Weight" }
            },
            [
              _c(
                "b-input-group",
                { attrs: { append: "kg" } },
                [
                  _c("b-form-input", {
                    attrs: { type: "number", step: "any" },
                    on: { change: _vm.updateBolusFields },
                    model: {
                      value: _vm.weight,
                      callback: function($$v) {
                        _vm.weight = $$v
                      },
                      expression: "weight"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("generic", {
        class: _vm.stacked ? "col-12" : "col-9 mx-0",
        attrs: { stay_id: _vm.stay_id, groups: _vm.groups }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("h6", { staticClass: "subsection-title mb-1" }, [
        _vm._v("Thrombolysis tPA Calculator")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }












import Vue from 'vue'
import utils from 'utils'
import stays from '@store/stays'
import EditModal from '../EditModal.vue'
import TextAreaEnhanced from '@inputs/TextAreaEnhanced.vue'

export default Vue.extend({
    components: {
        EditModal,
        TextAreaEnhanced
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            uid: utils.getUID(),
        }
    },
    computed: {
        hasWardRound(): boolean {
            return !!stays.state.stays[this.stay_id]?.ward_round
        },
    },
})

import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import * as Stacktrace from 'stacktrace-js'
import { Guard } from 'decoders'
import { map } from 'lodash-es'


/**
 * Attempts to verify data against the provided decoder.
 * It will either return a verified value, or undefined if verification fails.
 * if the data fails verification, it will report the error.
 * 
 * @param data 
 * @param decoder 
 * @param callerDesc 
 */
export function safeDecodingStrict<T>(data: any, decoder: Guard<T>, callerDesc?: string): T | undefined {
    try {
        const ret = decoder(data)
        console.log(callerDesc + ' typecheck OK!')
        return ret
    }
    catch (err) {
        let descInsert = ''
        if (callerDesc)
            descInsert = `${callerDesc}:\n`

        Stacktrace.get()
        .then(frames => {
            const callStack = `call stack:\n${map(frames, frame => frame.toString()).join('\n')}`
            const msg = `safeDecoding error\n${descInsert}${err}\n\n${callStack}`
            if (window.scrawl.sentryActive === 'true')
                Sentry.captureMessage(msg, Sentry.Severity.Error)
            console.error(msg)

            const basicMsg = 'decoding error'
            const toastMsg = callerDesc ? `${callerDesc}: ${basicMsg}` : basicMsg
            Vue.toasted.error(toastMsg)
        })

        return
    }
}


/**
 * Attempts to verify data against the provided decoder.
 * It will either return a verified value, or the original data.
 * if the data fails verification, it will report the error.
 * 
 * @param data The unverified data to pass through the decoder
 * @param decoder 
 * @param callerDesc 
 * @param doTypeCheck if false, skips decoding
 */
export function safeDecoding<T>(data: any, decoder: Guard<T>, callerDesc?: string, doTypeCheck?: boolean): T {
    if (doTypeCheck === undefined) doTypeCheck = true
    if (doTypeCheck) return safeDecodingStrict(data, decoder, callerDesc) || data
    return data
}




















import Vue from 'vue'
import utils from 'utils'

export default Vue.extend({
    data() {
        return {
            documentTitle: document.title,
            version: window.scrawl.sentryRelease,
            htmlId: `${utils.getUID()}___support_info_modal`,
        }
    },
    computed: {
        supportText(): string {
            return encodeURIComponent(this.documentTitle + ' ' + this.version)
        },
        emailLink(): string {
            return `mailto:support@scrawlmedical.zendesk.com?subject=${this.supportText}`
        },
        smsLink(): string {
            return `sms:+61447465902;?&body=${this.supportText}`
        },
    },
    mounted() {
        this.$emit('support-modal-id', this.htmlId)
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          staticClass: "mb-0",
          attrs: {
            label: "Findings",
            "label-cols-sm": "4",
            "label-class": "pt-0"
          }
        },
        _vm._l(_vm.fieldIds.findings, function(fieldId) {
          return _c(
            "b-check",
            {
              key: fieldId,
              staticClass: "scrawl-checkbox",
              attrs: {
                size: "lg",
                value: _vm.fieldToIdMap[fieldId],
                "unchecked-value": null
              },
              model: {
                value: _vm.selection.findings,
                callback: function($$v) {
                  _vm.$set(_vm.selection, "findings", $$v)
                },
                expression: "selection.findings"
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.fieldToTitleMap[fieldId]) +
                  "\n        "
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.sideVisible
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Side",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.fieldIds.sides, function(fieldId) {
                  return _c(
                    "b-check",
                    {
                      key: fieldId,
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: _vm.fieldToIdMap[fieldId],
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.selection.sides,
                        callback: function($$v) {
                          _vm.$set(_vm.selection, "sides", $$v)
                        },
                        expression: "selection.sides"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.fieldToTitleMap[fieldId]) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.aihVisible
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Primary haemorrhage site",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.fieldIds.aih_main, function(fieldId) {
                  return _c(
                    "b-check",
                    {
                      key: fieldId,
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: _vm.fieldToIdMap[fieldId],
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.selection.aih_main,
                        callback: function($$v) {
                          _vm.$set(_vm.selection, "aih_main", $$v)
                        },
                        expression: "selection.aih_main"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.fieldToTitleMap[fieldId]) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.intraCerebralVisible
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Intracerebral haemorrhage site",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.fieldIds.aih_ic, function(fieldId) {
                  return _c(
                    "b-check",
                    {
                      key: fieldId,
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: _vm.fieldToIdMap[fieldId],
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.selection.aih_ic,
                        callback: function($$v) {
                          _vm.$set(_vm.selection, "aih_ic", $$v)
                        },
                        expression: "selection.aih_ic"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.fieldToTitleMap[fieldId]) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.aihVisible
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Additional haemorrhage sites",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.fieldIds.aih_add, function(fieldId) {
                  return _c(
                    "b-check",
                    {
                      key: fieldId,
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: _vm.fieldToIdMap[fieldId],
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.selection.aih_add,
                        callback: function($$v) {
                          _vm.$set(_vm.selection, "aih_add", $$v)
                        },
                        expression: "selection.aih_add"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.fieldToTitleMap[fieldId]) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.aiVisible
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Infarction site",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.fieldIds.ai, function(fieldId) {
                  return _c(
                    "b-check",
                    {
                      key: fieldId,
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: _vm.fieldToIdMap[fieldId],
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.selection.ai,
                        callback: function($$v) {
                          _vm.$set(_vm.selection, "ai", $$v)
                        },
                        expression: "selection.ai"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.fieldToTitleMap[fieldId]) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Notes" } },
        [
          _c("b-form-textarea", {
            attrs: { rows: "3" },
            model: {
              value: _vm.notes,
              callback: function($$v) {
                _vm.notes = $$v
              },
              expression: "notes"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.debugMode
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("small", { staticClass: "form-text text-muted" }, [
                  _c("span", { staticClass: "text-monospace" }, [
                    _vm._v(_vm._s(_vm.selection))
                  ])
                ]),
                _vm._v(" "),
                _c("small", { staticClass: "form-text text-muted" }, [
                  _c("span", { staticClass: "text-monospace" }, [
                    _vm._v("stay: " + _vm._s(_vm.results))
                  ])
                ]),
                _vm._v(" "),
                _c("small", { staticClass: "form-text text-muted" }, [
                  _c("span", { staticClass: "text-monospace" }, [
                    _vm._v("local: " + _vm._s(_vm.localResults))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "b-btn",
                  {
                    staticClass: "mt-2",
                    attrs: { variant: "outline-danger", size: "sm" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.clearResults.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("Clear Results")]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
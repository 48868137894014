import { TakingMedication, TakingMedication_values } from './enums'

import {
    string as dstring,
    number as dnumber,
    array,
    oneOf,
    exact, guard
} from 'decoders'

export interface Medication {
    id: number
    title: string
    category: number
    taking: TakingMedication
    order: number
}

export interface MedicationCategory {
    speciality: number
    id: number
    title: string
    order: number
    medications: Medication[]
}

const medication = exact({
    id: dnumber,
    title: dstring,
    category: dnumber,
    taking: oneOf(TakingMedication_values),
    order: dnumber
})

const medCategory = exact({
    speciality: dnumber,
    id: dnumber,
    title: dstring,
    order: dnumber,
    medications: array(medication)
})

export const medCategories = array(medCategory)

export const medicationDecoder = guard(medication, { style: 'simple' })
export const medCategoriesDecoder = guard(medCategories, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _medication: Medication = medicationDecoder({}) } catch(e) {}
try { const _medCategories: MedicationCategory[] = medCategoriesDecoder({}) } catch(e) {}

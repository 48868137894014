




import Vue from 'vue'
import { freeTextSubHtmlBreaks } from 'utils/text/helpers'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        printNotes(): string {
            return freeTextSubHtmlBreaks(this.stay?.print_text || '')
        },
    }
})

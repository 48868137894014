
















































import Vue from "vue"
import mixins from "vue-typed-mixins"

import utils from 'utils'
import { PropType } from "models/schema"

import DisplayMixin from "@mixins/Display.vue"
import InputMixin from "@mixins/Input.vue"
import Generic from "@sections/Generic.vue"
import Eyes from "@inputs/nihss/Eyes.vue"

export default mixins(DisplayMixin, InputMixin).extend({
    components: {
        Eyes,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            gazeEnums: {
                unable: ['UNABLE_TO_LOOK_RIGHT', 'UNABLE_TO_LOOK_LEFT'],
                forced: ['FORCED_DEVIATION_TO_RIGHT', 'FORCED_DEVIATION_TO_LEFT']
            }
        }
    },
    computed: {
        gaze: {
            get(): string | null | undefined {
                return this.getFieldVal(this.stay_id, `${this.section}.gaze`)
            },
            set(val: string | null) {
                this.setFieldVal(this.stay_id, `${this.section}.gaze`, val)
            }
        }
    },
})

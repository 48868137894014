








import Vue from 'vue'
import { IssueStatus } from 'models/med_templates/issue'

import AddIssues from './AddIssues.vue'
import Issues from './Issues.vue'

export default Vue.extend({
    components: {
        AddIssues,
        Issues,
    },    
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            IssueStatus,
        }
    }
})

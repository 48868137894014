var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block" },
    _vm._l(_vm.versions, function(ver) {
      return _c(
        "form",
        {
          key: ver,
          staticClass: "d-inline-block",
          attrs: { action: _vm.printListURL, method: "post", target: "_blank" }
        },
        [
          _c("input", {
            attrs: { hidden: "", name: "csrfmiddlewaretoken" },
            domProps: { value: _vm.csrfToken }
          }),
          _vm._v(" "),
          _vm._l(_vm.stay_ids, function(id) {
            return _c("input", {
              attrs: { hidden: "", name: "stays" },
              domProps: { value: id }
            })
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { hidden: "", name: "sp" },
            domProps: { value: _vm.specialityId }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { hidden: "", name: "ah" },
            domProps: { value: _vm.addHeader }
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { hidden: "", name: "v" },
            domProps: { value: ver }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm bg-info text-white",
              class: _vm.buttonClasses,
              attrs: { type: "submit" }
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.finalLabel(ver)) + "\n        "
              )
            ]
          )
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue"
import Vuex from "vuex"
import { getStoreBuilder } from "vuex-typex"

import "./modules/stays"
import { Stays } from "./modules/stays"
import syncStaysPlugin from "./plugins/sync-stays"

Vue.use(Vuex)

export interface RootState {
    stays: Stays
}

const typexStore = getStoreBuilder<RootState>().vuexStore({ plugins: [syncStaysPlugin] })

export default typexStore

import { createDirectStore } from "direct-vuex"
import asapModule from './modules/asap'
import sessionModule from './modules/session'
import staysModule from './modules/stays_v2'
import templatesModule from './modules/templates'
import userModule from './modules/user'
import uxadminModule from './modules/uxadmin'

typexStore.registerModule('asap', asapModule)
typexStore.registerModule('session', sessionModule)
typexStore.registerModule('stays_v2', staysModule)
typexStore.registerModule('templates', templatesModule)
typexStore.registerModule('user', userModule)
typexStore.registerModule('uxadmin', uxadminModule)

const {
    store,
    rootActionContext,
    moduleActionContext,
    rootGetterContext,
    moduleGetterContext
} = createDirectStore({
    modules: {
        asap: asapModule,
        session: sessionModule,
        stays_v2: staysModule,
        templates: templatesModule,
        user: userModule,
        uxadmin: uxadminModule,
    }
})

export {
    rootActionContext,
    moduleActionContext,
    rootGetterContext,
    moduleGetterContext
}

export type AppStore = typeof store
declare module "vuex" {
    interface Store<S> {
        direct: AppStore
    }
}

typexStore.direct = store

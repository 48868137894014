import { filter, find, forEach, fromPairs, isArray, map } from 'lodash-es'
import { rawAnswerAndNotes, formatQuestionAnswerAndNotes } from './question'
import { TextDetails } from './interfaces'
import { QuestionCategory, OrderedQuestion } from "models/med_templates/question"
import store from 'store'


interface LineInfo {
    mode: 'skip' | 'normal' | 'indent'
    is_boolean: boolean
    boolean_answer?: boolean
    text: string
}

/**
 * Displays each group as a block of questions & answers, each block headed by the group title.
 * Also adheres to a few extra rules:
 * - questions with no answers are not displayed
 * - uses a boolean question's `no_text_output` property to determine if text is generated
 * - indentation for any question that is "parented" by another question via `show_if`
 *   - only indent if the parent question has `no_text_output` == false
 *   - there is only one level of indentation, regardless of number of parents
 * - boolean questions that occur next to each other are grouped
 *   - within the group, the positive answers are displayed line by line
 *   - within the group, the negative answers are collated a displayed on a single line at the end
 */
export function rapidAsmtDataCapture({ stay }: TextDetails): string {
    if (!stay) return ""

    const syndrome = find(store.direct.getters.templates.rapidAsmtSyndromes, ["id", stay.rapid_assessment_syndrome])
    if (!syndrome) return ""

    const dataCaptureGroups = filter(syndrome.question_groups, ["category", QuestionCategory.DATA_CAPTURE])

    const lines: string[] = []

    forEach(dataCaptureGroups, group => {
        const answersDict = fromPairs(map(group.questions, oq => ([oq.question.id, rawAnswerAndNotes(stay.id, oq.question)])))

        const sectionLines: string[] = []
        const linesInfo: LineInfo[] = []

        forEach(group.questions, oq => {
            const question = oq.question

            if (question.no_text_output) {
                linesInfo.push({ mode: 'skip', is_boolean: false, text: '' })
                return
            }

            const res = formatQuestionAnswerAndNotes(stay.id, question, undefined, undefined, undefined, answersDict[question.id])

            if (!res)
                return

            const text = res.text

            const lineInfo: LineInfo = {
                mode: 'normal',
                is_boolean: res.booleanLike,
                boolean_answer: res.positive,
                text,
            }

            if (oq.show_if !== null) {
                const parentOQ: OrderedQuestion | undefined = find(group.questions, x => x.question.id === oq.show_if)
                if (parentOQ) {
                    if (!parentOQ.question.no_text_output) {
                        lineInfo.mode = 'indent'
                        if (!answersDict[parentOQ.question.id].answer)
                            lineInfo.mode = 'skip'
                    }
                }
                else {
                    // TODO report error. really shouldn't happen
                    // console.log(question.title, 'show_warn_parent_missing')
                }
            }

            linesInfo.push(lineInfo)
        })

        // scan and group questions
        const groupedLines: (LineInfo | LineInfo[])[] = []

        for (let i = 0; i < linesInfo.length; i++) {
            const line = linesInfo[i]
            if (line.is_boolean) {
                const numGrouped = groupedLines.length
                if (numGrouped > 0) {
                    const prevLineOrGroup = groupedLines[numGrouped-1]
                    if (isArray(prevLineOrGroup)) {  // is_boolean === true is implied
                        if (prevLineOrGroup[0].mode === line.mode) {
                            prevLineOrGroup.push(line)
                            continue
                        }
                    }
                    else {
                        if (prevLineOrGroup.is_boolean && prevLineOrGroup.mode === line.mode) {
                            const group = [prevLineOrGroup, line]
                            groupedLines.pop()
                            groupedLines.push(group)
                            continue
                        }
                    }
                }
            }
            groupedLines.push(line)
        }

        forEach(groupedLines, lineOrGroup => {
            if (isArray(lineOrGroup)) {  // is_boolean === true is implied
                const prefix = lineOrGroup[0].mode === 'indent' ? '`-` ' : ''
                const negatives: string[] = []

                forEach(lineOrGroup, line => {
                    if (line.mode === 'skip')
                        return
                    if (line.boolean_answer)
                        sectionLines.push(`${prefix}${line.text}`)
                    else
                        negatives.push(line.text)
                })

                if (negatives.length)
                    sectionLines.push(prefix + negatives.join(' / '))
            }
            else {
                switch (lineOrGroup.mode) {
                    case 'skip':
                        break
                    case 'indent':
                        sectionLines.push('`-` ' + lineOrGroup.text)
                        break
                    case 'normal':
                    default:
                        sectionLines.push(lineOrGroup.text)
                        break
                }
            }
        })

        let sectionText = sectionLines.join("  \n")
        if (sectionText) {
            if (group.title)
                sectionText = `**${group.title}**  \n${sectionText}`
            lines.push(sectionText)
        }
    })

    return lines.length ? lines.join('\n\n') : ''
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mt-4" }, [
    _c(
      "div",
      { staticClass: "row justify-content-center align-items-end" },
      [
        _vm.telestrokeMode
          ? _c("hospital-filter", {
              staticClass: "mr-4",
              on: { update: _vm.pull }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("doctors-filter", {
          staticClass: "mr-3",
          model: {
            value: _vm.doctors,
            callback: function($$v) {
              _vm.doctors = $$v
            },
            expression: "doctors"
          }
        }),
        _vm._v(" "),
        _c("dates-controls", {
          attrs: { start: _vm.start, end: _vm.end, useQuickControls: false }
        }),
        _vm._v(" "),
        _c(
          "b-overlay",
          { attrs: { show: _vm.loading } },
          [
            _c("hyperacute-review", {
              staticClass: "mt-2",
              attrs: {
                loading: _vm.loading,
                presentationTypes: _vm.presentationTypes
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-form",
    [
      _c(
        "b-button",
        {
          attrs: { size: "sm", variant: _vm.btnVariant },
          on: { click: _vm.toggleMonitor }
        },
        [_vm._v(_vm._s(_vm.btnText))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
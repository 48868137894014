
































































































































































import Vue from 'vue'
import utils from 'utils'
import { Speciality } from 'models/med_templates/speciality'

interface DateRange {
    title: string
    start: string
    end: string
}

export default Vue.extend({
    data() {
        const ranges: DateRange[] = [
            { title: 'FY 2018-19', start: '2018-07-01', end: '2019-06-30' },
            { title: 'FY 2019-20', start: '2019-07-01', end: '2020-06-30' },
            { title: 'FY 2020-21', start: '2020-07-01', end: '2021-06-30' },
            { title: 'FY 2021-22', start: '2021-07-01', end: '2022-06-30' },
        ]
        const uid = utils.getUID()
        return {
            syndromesHtmlID: `${uid}___syn_exports`,
            syndromesVisible: false,
            asapCSVUrl: `${window.scrawl.apiBaseURL}/asap/asmt_csv/`,
            dataBaseUrl: `${window.scrawl.apiBaseURL}/data/`,
            ranges,
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        specialities(): Speciality[] {
            return this.$store.direct.state.templates.specialities
        },
        showAsapExport(): boolean {
            return this.$store.direct.state.session.ux_asap_export
        },
        showABFExport(): boolean {
            return this.$store.direct.state.session.ux_abf
        },
    },
    methods: {
        csvLink(syndromeID: number) {
            return `${this.dataBaseUrl}syndrome/${syndromeID}/`
        },
        csvLink_v2(syndromeID: number) {
            return `${this.csvLink(syndromeID)}?v=2`
        },
        specialityDataUrl(specialityID: number, startS: string, endS: string): string {
            return `${this.dataBaseUrl}speciality/${specialityID}/${startS}/${endS}/?`
        },
        specialityDataUrlAll(specialityID: number): string {
            return `${this.dataBaseUrl}speciality/${specialityID}/`
        },
        specialityDataUrlPII(specialityID: number, startS: string, endS: string): string {
            const baseUrl = this.specialityDataUrl(specialityID, startS, endS)
            return `${baseUrl}?pii=true`
        },
        specialityDataUrlAllPII(specialityID: number): string {
            const baseUrl = this.specialityDataUrlAll(specialityID)
            return `${baseUrl}?pii=true`
        },
        specialityDataUrlFull(specialityID: number, startS: string, endS: string): string {
            const baseUrl = this.specialityDataUrl(specialityID, startS, endS)
            return `${baseUrl}?pii=true&stable=false`
        },
        specialityDataUrlAllFull(specialityID: number): string {
            const baseUrl = this.specialityDataUrlAll(specialityID)
            return `${baseUrl}?pii=true&stable=false`
        },
        abfDataUrl(specialityID: number, startS: string, endS: string): string {
            return `${this.dataBaseUrl}abf/${specialityID}/${startS}/${endS}/`
        },
    }
})























import mixins from 'vue-typed-mixins'
import StaysMixin from '../mixins/Stays.vue'
import utils from 'utils'
import { Stay } from 'models/data/stay'

export default mixins(StaysMixin).extend({
    props: {
        stay: {
            type: Object as () => Stay,
            required: true
        },
        add_mb: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const uid = utils.getUID()
        const baseId = `s${this.stay.id}`
        return {
            modalId: `${uid}___${baseId}_end_consult_confirm`,
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        actionLabel(): string {
            return this.stay.active ? 'End consult' : 'Re-open'
        },
        actionDesc(): string {
            return this.stay.active ? 'End consultation for' : 'Re-open consultation for'
        },
        patientText(): string {
            return this.patientNameAndAge(this.stay)
        },
    },
})

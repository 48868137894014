var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPowerUser
    ? _c(
        "div",
        { staticClass: "d-inline-block" },
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.dischargeId,
                  expression: "dischargeId"
                }
              ],
              staticClass: "btn btn-sm progress-bar-striped text-white",
              class: {
                "mb-1": _vm.add_mb,
                "bg-danger": _vm.rapidAsmtActive,
                "bg-success": !_vm.rapidAsmtActive
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [_c("span", [_vm._v(_vm._s(_vm.actionLabel))])]
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                id: _vm.dischargeId,
                centered: "",
                "hide-header": "",
                "ok-title": "Yes"
              },
              on: {
                ok: function($event) {
                  return _vm.toggleRapidAsmtDischarge(_vm.stay)
                }
              }
            },
            [
              _c("p", { staticClass: "my-3" }, [
                _vm._v(_vm._s(_vm.actionLabel) + " "),
                _c("strong", [_vm._v(_vm._s(_vm.patientText))]),
                _vm._v("?")
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
































import Vue from 'vue'
import { clone, debounce, each, filter, includes, map, max } from 'lodash-es'
import { Chart } from 'chart.js'
import { draw } from 'patternomaly'
import * as moment from 'moment'
import utils from 'utils'
import { Sites } from 'models/asap/meta'
import DateRangeControls from './DateRangeControls.vue'
import Dropdown from '@shared/inputs/Dropdown.vue'


interface Dict { [index: string]: number }

interface SiteCounts extends Dict {
    opened: number
    completed: number
    normal: number
    hyperacute: number
}

interface UsageData {
    status: string
    start: string
    end: string
    data: {
        spans: [string, string][]
        sites: string[]
        counts: { [index: string]: SiteCounts }[]
    }
}

const usageEvents = ['opened', 'normal', 'hyperacute']

export default Vue.extend({
    components: {
        DateRangeControls,
        Dropdown,
    },
    data() {
        return {
            chartColors: {
                blue: "rgb(54, 162, 235)",
                grey: "rgb(201, 203, 207)",
                green: "rgb(75, 192, 192)",
                orange: "rgb(255, 159, 64)",
                purple: "rgb(153, 102, 255)",
                red: "rgb(255, 99, 132)",​
                yellow: "rgb(255, 205, 86)",
            },
            patterns: ['weave', 'dot', 'cross'],
            allSites: [] as Sites,
            selectedSites_local: [] as string[],
            sitesSelectDone: 0,
            startDate: '',
            endDate: '',
            timeUnit: 'week', // 'hour' | 'day' | 'week' | 'month' | 'year'
            metrics: null as UsageData | null,
            charts: {} as { [k: string]: Chart },
            loading: false,
        }
    },
    computed: {
        debouncedFetchData: function(): any {
            const fn = debounce(this.fetchData, 1000, {trailing: true})
            return fn
        },
        sitesLabelsDict() {
            const dict = {} as { [index: string]: string }
            each(this.allSites, site => {
                dict[site.id] = site.title
            })
            return dict
        },
        sitesList(): Sites {
            return filter(this.allSites, site => {
                return site.enabled !== undefined ? site.enabled : true
            })
        },
        chartColorsList(): string[] {
            return map(this.chartColors, color => color)
        },
        labels(): string[] {
            if (!this.metrics) return []
            return map(this.metrics.data.spans, (span, index) => {
                // if hour: remove minute and less
                // if day: remove hour and less
                // if week: remove hour and less
                // if month: remove day and less
                // if year: remove month and less

                const formats: { [index: string]: string } = {
                    hour: 'hha',
                    day: 'MMM D',
                    week: 'MMM D',
                    month: 'MMM',
                    year: 'YYYY'
                }
                const showEnd: { [index: string]: boolean } = {
                    hour: false,
                    day: false,
                    week: true,
                    month: false,
                    year: false
                }

                const startDT = moment(span[0])
                const endDT = moment(span[1])
                const format = formats[this.timeUnit]

                return showEnd[this.timeUnit] ? `${startDT.format(format)} - ${endDT.format(format)}` : startDT.format(format)
            })
        },
        selectedSites: {
            get(): string[] {
                return this.selectedSites_local
            },
            set(val: string[]) {
                if (includes(val, 'all_sites'))
                    this.selectedSites_local = map(this.allSites, 'id')
                if (!utils.isEqual)
                    this.selectedSites_local = clone(val)
            }
        },
        datasetPaths() {
            const paths: [string, string][] = []
            if (this.metrics)
                each(this.metrics.data.sites, site_id => {
                    each(usageEvents, uEvent => {
                        paths.push([site_id, uEvent])
                    })
                })
            return paths
        },
        datasets(): any[] {
            if (!this.metrics) return []

            const uEventAxesMap: { [index: string]: string } = {
                'opened': 'y-axis-1',
                'normal': 'y-axis-2',
                'hyperacute': 'y-axis-2',
            }

            const uEventStackMap: any = {
                'opened': 'opened',
                'normal': 'complete',
                'hyperacute': 'complete',
            }

            // setup datasets
            const datasets = [] as any[]

            each(this.metrics.data.sites, (site_id, site_index) => {
                each(usageEvents, (uEvent, uEventIdx) => {
                    const dataset_id = `${site_id}-${uEvent}`
                    datasets.push({
                        label: `${this.getSiteLabel(site_id)} - ${uEvent}`,
                        backgroundColor: draw(this.patterns[uEventIdx] as any, this.chartColorsList[site_index]),
                        data: [],
                        stack: `${site_id}-${uEventStackMap[uEvent]}`,
                        yAxisID: uEventAxesMap[uEvent],
                        minBarLength: 2,
                    })
                })
            })

            each(this.metrics.data.counts, siteCounts => {
                each(this.datasetPaths, (path, index) => {
                    // site = path[0], usageEvent = path[1]
                    datasets[index].data.push(siteCounts[path[0]][path[1]] || 0)
                })
            })

            return datasets
        },
        maxCount(): number {
            let maxCount = 10
            if (this.metrics)
                each(this.metrics.data.counts, siteCounts => {
                    each(siteCounts, siteData => {
                        maxCount = max([maxCount, siteData.opened, siteData.completed])!
                    })
                })
            return maxCount
        },
    },
    watch: {
        datasets() {
            this.renderChart('asapUsage')
        },
        sitesSelectDone() {
            this.debouncedFetchData()
        },
        startDate(val, old) {
            if (val !== old)
                this.debouncedFetchData()
        },
        endDate(val, old) {
            if (val !== old)
                this.debouncedFetchData()
        },
        timeUnit(val, old) {
            if (val !== old)
                this.debouncedFetchData()
        },
    },
    created() {
        utils.request.get('static/asap/meta.json', undefined, true)
        .then(res => {
            this.allSites = res.body.sites
        })
    },
    mounted() {
        this.charts = {
            asapUsage: new Chart(this.$refs.asapUsage as any, { type: 'bar'})
        }
    },
    methods: {
        fetchData() {
            this.loading = true
            let queryParams = utils.objectToParams({ sites: this.selectedSites })
            queryParams = queryParams ? `?${queryParams}` : ''
            utils.request.get(`/metrics/ux/${this.startDate}/${this.endDate}/${this.timeUnit}/${queryParams}`)
            .then(res => {
                // TODO validate res.body has the same shape as interface
                this.metrics = res.body
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
            .then(res => {
                this.loading = false
            })
        },
        renderChart(chartRef: string) {
            const self = this
            const chart = this.charts[chartRef]
            if (!chart) return


            chart.data = {
                labels: this.labels,
                datasets: this.datasets
            }
            chart.options = {
                title: {
                    text: `ASAP Tool usage across sites by ${this.timeUnit}`,
                    display: true,
                },
                legend: {
                    position: 'left',
                    labels: {
                        generateLabels(chart) {
                            const options = chart.options.legend || {}
                            const usePointStyle = options.labels && options.labels.usePointStyle
                            const labels = map(self.metrics!.data.sites, (site_id, site_index) => {
                                const style = chart.getDatasetMeta(0).controller.getStyle(usePointStyle ? 0 : undefined)
                                return {
                                    text: self.getSiteLabel(site_id),
                                    fillStyle: self.chartColorsList[site_index],
                                    hidden: false,
                                    lineCap: style.borderCapStyle,
                                    lineDash: style.borderDash,
                                    lineDashOffset: style.borderDashOffset,
                                    lineJoin: style.borderJoinStyle,
                                    lineWidth: style.borderWidth,
                                    strokeStyle: style.borderColor,
                                    pointStyle: style.pointStyle,
                                    rotation: style.rotation,
                                }
                            })
                            return labels
                        },
                    }
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-1',
                            type: 'linear',
                            position: 'left',
                            ticks: {
                                beginAtZero: true,
                                stepSize: 5,
                                suggestedMin: 0,
                                suggestedMax: this.maxCount,
                            },
                            gridLines: {
                                color: 'rgba(255,255,255,0.5)',
                                zeroLineColor: 'rgba(255,255,255,0.5)',
                            },
                        },
                        {
                            id: 'y-axis-2',
                            type: 'linear',
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                                stepSize: 5,
                                suggestedMin: 0,
                                suggestedMax: this.maxCount,
                            },
                            display: false,
                        }
                    ]
                }
            }
            chart.update()
        },
        getSiteLabel(site_id: string): string {
            return this.sitesLabelsDict[site_id] || site_id
        },
    }
})

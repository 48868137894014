




















import Vue from 'vue'
import { each, find } from 'lodash-es'
import utils from 'utils'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { BaseInvInfo } from 'models/data/investigation'
import { Investigation as InvSchema } from 'models/med_templates/investigation'

import Investigation from './Investigation.vue'

export default Vue.extend({
    components: {
        Investigation
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
    },
    data() {
        const htmlID = `${utils.getUID()}___inv_list`
        return {
            htmlID,
            accordionID: `${htmlID}__accordion`
        }
    },
    computed: {
        baselines(): BaseInvInfo[] {
            if (this.stay) {
                const finalList: BaseInvInfo[] = []

                each(this.investigations, schema => {
                    if (schema.enabled) {
                        finalList.push({
                            inv: find(this.stay!.baseline_investigations, { investigation: schema.id }),
                            schema
                        })
                    }
                    else {
                        const stayInv = find(this.stay!.baseline_investigations, { investigation: schema.id })
                        if (stayInv) {
                            if (stayInv.performed !== undefined) {
                                finalList.push({
                                    inv: find(this.stay!.baseline_investigations, { investigation: schema.id }),
                                    schema
                                })
                            }
                        }
                    }
                })

                return finalList
            }
            return []
        },
        investigations(): InvSchema[] {
            if (!(this.stay && this.stay.syndrome)) return []

            const syndrome = find(this.$store.direct.getters.templates.allSyndromes, ['id', this.stay.syndrome])
            if (!syndrome) return []

            const baselineIDs = syndrome.baseline_investigations || []
            const baselineInvs: InvSchema[] = []
            each(baselineIDs, id => {
                const inv = find(this.$store.direct.getters.templates.allInvs, { id })
                if (inv) baselineInvs.push(inv)
            })

            return baselineInvs
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
    }  
})










import Vue from 'vue'

import DischargeNotes from './DischargeNotes.vue'
import DischargePlans from './DischargePlans.vue'
import FollowUp from './FollowUp.vue'


export default Vue.extend({
    components: {
        DischargeNotes,
        DischargePlans,
        FollowUp,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        showInstructions: {
            type: Boolean,
            default: true
        },
    },
})

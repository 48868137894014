






import Vue from 'vue'
import { Stay } from 'models/data/stay'
import stays from '@store/stays'
import { aimingFor } from 'utils/text/plan'
import AimingFor from '@ward/discharge/AimingFor.vue'
import EditModal from '../EditModal.vue'


export default Vue.extend({
    components: {
        AimingFor,
        EditModal,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        summaryText(): string {
            return aimingFor({ stay: this.stay })
        },
    },
})

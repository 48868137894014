
import Vue from 'vue'
export default Vue.extend({
    props: {
        value: {},
        targetVal: {}
    },
    data() {
        return {
            localVal: this.value as any
        }
    },
    computed: {
        $_isChecked(): boolean {
            return this.localVal === this.targetVal
        }
    },
    watch: {
        value(val: any) {
            this.localVal = val
        }
    },
    methods: {
        $_toggleState(event: any) {
            if (this.$_isChecked)
                this.localVal = null
            else
                this.localVal = this.targetVal
            this.$emit('input', this.localVal)
        }
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hideCompleted
        ? _c("investigation-list", {
            attrs: {
              title: "Completed Investigations",
              stay: _vm.stay,
              investigations: _vm.completedInvestigations
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideActive
        ? _c("investigation-list", {
            class: { "mt-4": !_vm.hideCompleted },
            attrs: {
              title: "Active Investigations",
              stay: _vm.stay,
              investigations: _vm.activeInvestigations
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideAdd
        ? _c("add-investigations", {
            staticClass: "mt-3",
            attrs: { stay_id: _vm.stay_id }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
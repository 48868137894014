var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-end" },
    [
      _c("dropdown", {
        staticClass: "mb-0",
        attrs: {
          items: _vm.aimingForChoices,
          label: "Aiming for",
          "text-field": "title",
          "value-field": "id",
          "add-null-item": "",
          "null-item-text": "Other",
          "null-item-value": _vm.OTHER_AIMING_FOR_VALUE
        },
        model: {
          value: _vm.aimingFor,
          callback: function($$v) {
            _vm.aimingFor = $$v
          },
          expression: "aimingFor"
        }
      }),
      _vm._v(" "),
      _vm.aimingFor === _vm.OTHER_AIMING_FOR_VALUE
        ? _c(
            "b-col",
            { staticClass: "mt-1", attrs: { sm: "6" } },
            [
              _c("b-form-input", {
                attrs: {
                  autofocus: !_vm.otherAimingFor,
                  placeholder: "[title]"
                },
                model: {
                  value: _vm.otherAimingFor,
                  callback: function($$v) {
                    _vm.otherAimingFor = $$v
                  },
                  expression: "otherAimingFor"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
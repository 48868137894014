var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "edit-modal",
    {
      attrs: {
        btnVariant: "info",
        btnSize: "sm",
        text: "Edit",
        modalTitle: "Additional Notes",
        size: "lg",
        okOnly: ""
      }
    },
    [
      _vm.hasWardRound
        ? _c("text-area-enhanced", {
            attrs: {
              stay_id: _vm.stay_id,
              path: "ward_round.progress",
              label: "Progress"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasWardRound
        ? _c("text-area-enhanced", {
            attrs: {
              stay_id: _vm.stay_id,
              path: "ward_round.plan_notes",
              label: "Plan"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("text-area-enhanced", {
        attrs: {
          stay_id: _vm.stay_id,
          charLimit: 100,
          path: "print_text",
          label: "Additional Notes"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
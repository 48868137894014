var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("date-range-controls", {
            attrs: { initialRange: "past_7d", useTimeUnit: false },
            on: {
              start_dt: function($event) {
                _vm.startDate = $event
              },
              end_dt: function($event) {
                _vm.endDate = $event
              },
              opened: _vm.opened,
              closed: _vm.closed
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col pl-5" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "h4",
            [
              _c("span", { staticClass: "mr-3" }, [
                _vm._v("Combined Timeline")
              ]),
              _vm._v(" "),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "scrawl-checkbox mr-2",
                  attrs: { size: "lg", inline: "" },
                  model: {
                    value: _vm.showAll,
                    callback: function($$v) {
                      _vm.showAll = $$v
                    },
                    expression: "showAll"
                  }
                },
                [_vm._v("\n                    Show All\n                ")]
              ),
              _vm._v(" "),
              _vm.loading
                ? _c("em", { staticClass: "small loading-dots" }, [
                    _vm._v("Loading"),
                    _c("span", [_vm._v(".")]),
                    _c("span", [_vm._v(".")]),
                    _c("span", [_vm._v(".")])
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", {
          ref: "vis_timeline",
          staticClass: "usage-activity-timeline"
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
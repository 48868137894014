var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-list-group-item",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.htmlID,
              expression: "htmlID"
            }
          ],
          staticClass: "d-flex align-items-start r-clickable",
          attrs: { role: "tab" }
        },
        [
          _c(
            "p",
            { staticClass: "mb-0" },
            [
              _c("fnt-a-icon", {
                staticClass: "svg drag-handle text-secondary scrwl-hover-show",
                attrs: { icon: ["far", "arrows-alt-v"] }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-2 flex-grow-1" },
            [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c("div", { staticClass: "flex-grow-1" }, [
                  _c("p", { staticClass: "issue-title m-0" }, [
                    _vm._v(_vm._s(_vm.issue.title))
                  ])
                ]),
                _vm._v(" "),
                _vm.baseIssue
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _vm.baseIssue.help_text
                          ? _c(
                              "p",
                              {
                                staticClass: "d-inline font-weight-light mb-0"
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.baseIssue.help_text) +
                                    "\n                    "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.baseIssue.link
                          ? [
                              _vm._v("\n                        ["),
                              _c(
                                "a",
                                {
                                  staticClass: "text-info",
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.baseIssue.link
                                  }
                                },
                                [_vm._v("Link")]
                              ),
                              _vm._v("]\n                    ")
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.isActive
                ? [
                    _vm.fullText
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.fullText) }
                        })
                      : _c("em", [_vm._v("No notes")])
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "bg-dark",
          attrs: { id: _vm.htmlID, role: "tabpanel" },
          on: {
            show: _vm.setOpened,
            hidden: function($event) {
              _vm.opened = false
            }
          }
        },
        [
          _c(
            "b-card-body",
            [
              !_vm.isActive
                ? _c("div", { staticClass: "pb-2 mb-3 border-bottom" }, [
                    _vm.fullText
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.fullText) }
                        })
                      : _c("em", [_vm._v("No notes")])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.opened
                ? _c("issue-editor", {
                    key: "editor",
                    attrs: { stay_id: _vm.stay_id, issue: _vm.issue }
                  })
                : _c(
                    "div",
                    {
                      key: "loading",
                      staticClass:
                        "d-flex align-items-center justify-content-center my-5"
                    },
                    [
                      _c("em", { staticClass: "loading-dots" }, [
                        _vm._v("Loading"),
                        _c("span", [_vm._v(".")]),
                        _c("span", [_vm._v(".")]),
                        _c("span", [_vm._v(".")])
                      ])
                    ]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    [
      _c("label", { staticClass: "mb-0" }, [
        _vm._v("\n        Surgery Time\n    ")
      ]),
      _vm._v(" "),
      _c("dt-picker-enhanced", {
        model: {
          value: _vm.surgeryTime,
          callback: function($$v) {
            _vm.surgeryTime = $$v
          },
          expression: "surgeryTime"
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.allStages, function(choice) {
        return _c(
          "b-radio",
          {
            key: "sstage_" + choice.value,
            staticClass: "scrawl-radio mt-2",
            attrs: {
              variant: "info",
              size: "lg",
              inline: "",
              value: choice.value
            },
            model: {
              value: _vm.surgeryStage,
              callback: function($$v) {
                _vm.surgeryStage = $$v
              },
              expression: "surgeryStage"
            }
          },
          [_vm._v("\n        " + _vm._s(choice.display_name) + "\n    ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }












import Vue from "vue"
import DataCapture from "@inputs/DataCapture.vue"

export default Vue.extend({
    components: {
        DataCapture,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        tab: {
            type: String,
            default: ''
        }
    },
    computed: {
        allowed(): boolean {
            return this.$store.direct.getters.user.canDataCapture
        },
    }
})














import Vue from 'vue'
import utils from 'utils'
import Team from '@sections/Team.vue'

export default Vue.extend({
    components: {
        Team,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            attendingHtmlId: `${utils.getUID}___attending`,
        }
    },
})













































import Vue from 'vue'

import DataCaptureTab from './DataCaptureTab.vue'
import Discharge from './ward/Discharge.vue'
import AllInvestigations from '@sections/investigations/AllInvestigations.vue'
import TextAreaEnhanced from '@inputs/TextAreaEnhanced.vue'
import WardRound from './ward/WardRound.vue'
import WardSummary from './ward/WardSummary.vue'
import WardText from '@text/Ward.vue'

export default Vue.extend({
    components: {
        DataCaptureTab,
        Discharge,
        AllInvestigations,
        TextAreaEnhanced,
        WardRound,
        WardSummary,
        WardText,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        tab: {
            type: String,
            default: ''
        },
        notesDocked: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
    },
    mounted() {
        this.$emit('show-notes-btn')
    },
})

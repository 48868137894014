














import Vue from 'vue'
import { map } from 'lodash-es'

import stays from '@store/stays'
import { Stay } from 'models/data/stay'

import IssuesOverview from '@sections/issues/IssuesOverview.vue'
import EditModal from '../EditModal.vue'

export default Vue.extend({
    components: {
        IssuesOverview,
        EditModal,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        size: {
            type: String
        },
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        issuesList(): string [] {
            const activeIssues = stays.getActiveIssues(this.stay_id)
            return map(activeIssues, 'title')
        }
    },
})

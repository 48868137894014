









































import mixins from 'vue-typed-mixins'
import InputMixin from '@mixins/Input.vue'
import StayMixin from '@mixins/Stay.vue'
import { find, flatMap } from 'lodash-es'

import { Diagnosis } from 'models/med_templates/diagnosis'
import { Speciality } from 'models/med_templates/speciality'
import { Syndromes } from 'models/med_templates/syndrome'

export default mixins(InputMixin, StayMixin).extend({
    props: {
        stacked: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        colClass(): string {
            return this.stacked ? 'col-12' : 'col'
        },
        speciality(): Speciality | undefined {
            return this.$store.direct.getters.templates.speciality(this.stay?.speciality)
        },
        /** rapid assessment syndromes */
        syndromes(): Syndromes {
            return this.speciality?.rapid_asmt_syndromes || []
        },
        diagnoses(): Diagnosis[] {
            return flatMap(this.syndromes, s => s.diagnoses)
        },
        otherDiagnosis() {
            return {
                id: -999,
                title: '[ Other Diagnosis ]'
            }
        },
        otherDiagnosisSelected: {
            get(): boolean {
                return this.stay?.rapid_assessment_other_diagnosis_selected || false
            },
            set(val: boolean) {
                this.setFieldVal(this.stay_id, 'rapid_assessment_other_diagnosis_selected', val)
            }
        },
        otherDiagnosisTitle: {
            get(): string | null {
                return this.stay?.rapid_assessment_other_diagnosis_title || null
            },
            set(title: string) {
                this.setFieldVal(this.stay_id, 'rapid_assessment_other_diagnosis_title', title)
            }
        },
        diagnosisID: {
            get(): number | null {
                const diagnosisID = this.stay?.rapid_assessment_diagnosis
                if (diagnosisID === null && this.otherDiagnosisSelected)
                    return this.otherDiagnosis.id
                return diagnosisID || null
            },
            set(val: number | null) {
                if (val === this.otherDiagnosis.id) {
                    this.setFieldVal(this.stay_id, 'rapid_assessment_diagnosis', null)
                    this.otherDiagnosisSelected = true
                } else {
                    this.otherDiagnosisSelected = false
                    this.setFieldVal(this.stay_id, 'rapid_assessment_other_diagnosis_title', null)
                    this.setFieldVal(this.stay_id, 'rapid_assessment_diagnosis', val)
                }
            }
        },
        diagnosis(): Diagnosis | undefined {
            return find(this.diagnoses, ['id', this.diagnosisID])
        },
        selectedText(): string {
            if (this.diagnosis)
                return this.diagnosis.alt_title || this.diagnosis.title
            if (this.diagnosisID === this.otherDiagnosis.id)
                return this.otherDiagnosis.title
            return '[ Choose diagnosis ]'
        }
        },
    methods: {
        isSelected(val: number | null): boolean {
            return this.diagnosisID === val
        },
        toggleSelect(val: any, event: Event) {
            this.diagnosisID = this.isSelected(val) ? null : val
        },
    }
})

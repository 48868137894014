import * as shortID from "shortid"
import * as Bowser from 'bowser'
import * as Sentry from '@sentry/vue'
import * as Stacktrace from 'stacktrace-js'
import * as moment from 'moment'
import { isArray, isEqual as _isEqual, map, round, union } from "lodash-es"
import { cookie, request } from './request'
import Vue from "vue"
import store from 'store'
import { Stay } from 'models/data/stay'
import { anonymisedPatient, dummyPatient, isPatient } from 'models/data/patient'
import { Settings, settingsDecoder } from 'models/meta'
import { safeDecoding } from './store'


declare global {
    interface Window {
        scrawl: {
            isIE11: boolean
            instance: string
            baseURL: string
            /** URL to alternative UX using the same backend */
            altURL: string
            apiBaseURL: string
            auxURL: string
            cookieIDBase: string
            cookieCSRFKey: string
            customBrandImg: string
            sentryActive: string
            sentryDSN: string
            sentryRelease: string
            sentrySampleRate: string
            asapAskReason: string
            editorDocX: string
            defaultShowSpeciality: string
            defaultFilterMode: string
        }
    }
}


/**
 * Uses lodash's isEqual for comparison, except treats arrays with the same items but different ordering as equal.
 * @param item1 
 * @param item2 
 */
function isEqual(item1: any, item2: any): boolean {
    if (!isArray(item1) || !isArray(item2))
        return _isEqual(item1, item2)
    if (item1.length !== item2.length)
        return false
    return union(item1, item2).length === item1.length
}

function ensureCookie() {
    if (cookie.get(window.scrawl.cookieCSRFKey))
        return Promise.resolve()

    return request.get('/account/cookiemonster/')
    .then(res => { return Promise.resolve() })
    .catch((err: any) => {
        Vue.toasted.error("Error connecting to server.")
        console.error('shared/ensureCookie error')
        console.error(err)
    })
}

function checkisIE11() {
    const browser = Bowser.getParser(window.navigator.userAgent)
    return browser.satisfies({
        windows: {
            'internet explorer': '~11'
        }
    })
}

function messageSentry(msg: string, level: Sentry.Severity = Sentry.Severity.Error): void {
    if (window.scrawl.sentryActive === 'true')
        Sentry.captureMessage(msg, level)
}

const utils = {
    cookie,
    request,

    // functions
    ensureCookie,

    init() {
        window.scrawl.isIE11 = checkisIE11() || false
        return Promise.resolve()
    },

    loggedinInit() {
        return store.direct.dispatch.user.pull()
            .then(res => {
                return request.get("/init_data/?v=2")
                // return request.get('/static/powh-stroke/init_data.json', undefined, true)
            })
            .then(result => {
                const templates = result.body.templates || result.body
                const settings: Settings = safeDecoding(result.body.settings, settingsDecoder, 'loggedinInit/settings')
                const templatesCommits = store.direct.commit.templates
                const sessionCommits = store.direct.commit.session

                templatesCommits.setAllChoices(templates.choices)
                templatesCommits.setCustomQs(templates.custom_qs)
                templatesCommits.setDoctors(templates.doctors)
                templatesCommits.setFollowUpWiths(templates.follow_up_with)
                templatesCommits.setHospitals(templates.hospitals)
                templatesCommits.setInvGroups(templates.investigation_groups)
                templatesCommits.setIssueGroups(templates.issues)
                templatesCommits.setMDTPeople(templates.mdt_people_present)
                templatesCommits.setNBM(templates.grouped_choices.nbm)
                templatesCommits.setSpaces(templates.spaces)
                templatesCommits.setSpecialities(templates.specialities)
                templatesCommits.setTeams(templates.teams)
                templatesCommits.setWardLabels(templates.ward_labels)
                templatesCommits.setStrokeDiagnoses(templates.stroke_diagnoses)

                sessionCommits.setFeedLogic(settings.feed_logic)
                sessionCommits.setHasPasFeed(settings.has_pas_feed)
                sessionCommits.updateSettings(settings)
                sessionCommits.setReady(true)

                return store.direct.dispatch.stays_v2.pullFullSchema()
            })
            .catch(err => {
                Vue.toasted.error("initial data retrieval error :(")
                console.error("utils.init error:", err)
            })
    },

    // singletons
    getUID(): string {
        return `R4_${shortID.generate()}`
    },

    isEqual,

    handleRequestError(err: any, popup?: boolean) {
        if (err.status === 401) {
            // Vue.toasted.error('Logged out. Please refresh the page and re-authenticate.')
            store.direct.dispatch.session.knock()
            return
        }

        const response = err.response || {}
        if (popup === undefined) popup = true

        Stacktrace.get()
        .then(frames => {
            let msg: string = `call stack:\n${map(frames, frame => frame.toString()).join('\n')}`
            if (response && response.error && response.error.message)
                msg = `error: ${response.error.message}\nserver sent: ${response.text}\n${msg}`
            messageSentry(msg)
        })

        if (response) {
            console.error('response:', response)
            if (response.error && response.error.message)
                console.error('error:', response.error.message)
        }

        if (popup)
            Vue.toasted.error(`Error received from server (status: ${response.statusText || response.statusCode || response.status || err.message})`)
    },

    messageSentry,

    objectToParams(obj: {[index: string]: any}): string {
        return map(obj, (val, key) => {
            if (isArray(val))
                return map(val, val2 => `${encodeURIComponent(key)}=${encodeURIComponent(val2)}`).join('&')
            return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
        }).join('&')

    },

    patientName(stay: Stay | null | undefined, anonymise: boolean): string {
        let patient = dummyPatient()
        if (stay && isPatient(stay.patient))
            patient = anonymise ? anonymisedPatient(stay.patient) : stay.patient
        return `${patient.last_name}, ${patient.first_name}`
    },
    
    patientNameAndAge(stay: Stay | null | undefined, anonymise: boolean): string {
        let patient = dummyPatient()
        if (stay && isPatient(stay.patient))
            patient = anonymise ? anonymisedPatient(stay.patient) : stay.patient
        return `${patient.last_name}, ${patient.first_name} (${patient.age || '-'})`
    },

    /**
     * Adds a listener for 'hashchange' in IE11 so a user can manually change the hash
     * in the URL bar and still trigger a route change in Vue.
     */
    hashChangeIE11(vueInst: any) {
        if (checkisIE11())
            window.addEventListener('hashchange', () => {
                const newPath = location.hash.slice(1)
                if (vueInst.$route.path !== newPath)
                    vueInst.$router.push(newPath)
            })
    },

    /** Calculates the percentage and rounds down. If the denominator is 0, return 0. */
    safePct(numerator: number, denominator: number, places?: number): number {
        if (!denominator) return 0
        if (places === undefined) places = 1
        return round(numerator*100/denominator, places)
    },

    niceDate(isoDate: string): string {
        return moment(isoDate).format('ddd YYYY-MMM-D h:mm:ss A')
    },

    /** returns a date formatted like `niceDate`, but without the seconds information. */
    niceDateShort(isoDate: string): string {
        return moment(isoDate).format('ddd YYYY-MMM-D h:mma')
    },
}

export default utils

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("label", { staticClass: "d-block" }, [_vm._v("Checklist")]),
          _vm._v(" "),
          _vm._l(_vm.checklistChoices, function(choice) {
            return _c("checkbox", {
              key: choice.value,
              attrs: { title: choice.display_name, cb_value: choice.value },
              model: {
                value: _vm.discussed,
                callback: function($$v) {
                  _vm.discussed = $$v
                },
                expression: "discussed"
              }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("text-area", {
        staticClass: "mt-3",
        attrs: {
          "non-unique-id": "patient-doctor-discussion-notes",
          rows: "8",
          label: "Notes"
        },
        model: {
          value: _vm.notes,
          callback: function($$v) {
            _vm.notes = $$v
          },
          expression: "notes"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
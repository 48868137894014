

















import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            backendLink: `${window.scrawl.apiBaseURL}/admin/?frontend=1`,
            spacesAdminLink: `${window.scrawl.apiBaseURL}/admin/stays/space/`,
        }
    },
    computed: {
        isDeveloper(): boolean {
            return this.$store.direct.getters.user.isDeveloper
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        isClientUserAdmin(): boolean {
            return this.$store.direct.getters.user.isClientUserAdmin
        },
        isOnlyClientUserAdmin(): boolean {
            const userGetters = this.$store.direct.getters.user
            return userGetters.isClientUserAdmin && !this.isPowerUser && !userGetters.isTeamsEditor
        },
        canAdmin(): boolean {
            const userGetters = this.$store.direct.getters.user
            return this.isPowerUser || this.isClientUserAdmin || userGetters.isTeamsEditor
        },
    }
})

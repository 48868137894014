var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("issues", {
        staticClass: "mb-3",
        attrs: {
          stay_id: _vm.stay_id,
          issueStatus: _vm.IssueStatus.ACTIVE,
          emptyText: "No active issues"
        }
      }),
      _vm._v(" "),
      _c("issues", {
        staticClass: "mb-3",
        attrs: {
          stay_id: _vm.stay_id,
          issueStatus: _vm.IssueStatus.RESOLVED,
          emptyText: "None"
        }
      }),
      _vm._v(" "),
      _c("add-issues", { staticClass: "mt-2", attrs: { stay_id: _vm.stay_id } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
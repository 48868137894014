var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "p",
          {
            class: { "mb-0": !_vm.extraHtml },
            staticStyle: { "font-size": "1.1rem" }
          },
          [_vm._v(_vm._s(_vm.diagnosis))]
        ),
        _vm._v(" "),
        _vm.keyPoints
          ? _c("div", {
              staticClass: "mb-3",
              domProps: { innerHTML: _vm._s(_vm.keyPoints) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.features
          ? _c("div", {
              staticClass: "mb-3",
              domProps: { innerHTML: _vm._s(_vm.features) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.addNotes
          ? _c("div", {
              staticClass: "mb-3",
              domProps: { innerHTML: _vm._s(_vm.addNotes) }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("edit-diagnosis", {
        staticClass: "mt-4 pt-2 px-1 border-top",
        attrs: { stay_id: _vm.stay.id }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }



























import Vue from 'vue'
import { each, toInteger } from 'lodash-es'
import utils from 'utils'
import stays from '@store/stays'

export default Vue.extend({
    data() {
        const uid = utils.getUID()
        return {
            htmlIDs: {
                table: `${uid}___table`
            },
            tableMonitor: -1,
            navHeight: 0
        }
    },
    computed: {
        busy(): boolean {
            return stays.state.staysLoading
        }
    },
    mounted() {
        this.$nextTick(() => {
            const rootElem = window.getComputedStyle(document.documentElement, null)
            let fontSize = '16'
            if (rootElem && rootElem.fontSize)
                fontSize = rootElem.fontSize.replace('px', '')
            this.navHeight = toInteger(fontSize) * 3.5  // bootstrap-slate's navbar height is 3.5rem

            // keeps table headers visible on the page
            if (this.tableMonitor === -1) {
                this.tableMonitor = window.setInterval(() => {
                    const row = document.querySelector(`#${this.htmlIDs.table} thead .sticky-row`)
                    let y = -1
                    if (row) {
                        const rect = row.getBoundingClientRect()
                        y = rect.top
                    }

                    const cells = document.querySelectorAll(`#${this.htmlIDs.table} thead .sticky-row th`)
                    const top = y < this.navHeight ? `${this.navHeight - y}px` : '0'
                    each(cells, cell => {
                        const el = cell as HTMLElement
                        if (el.style.top !== top)
                            el.style.top = top
                    })
                }, 500)  // run every 0.5 seconds
            }
        })
    },
    beforeDestroy() {
        window.clearInterval(this.tableMonitor)
        this.tableMonitor = -1
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-btn",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.collapseId,
              expression: "collapseId"
            }
          ],
          staticClass: "p-0 text-left",
          attrs: { variant: "link" }
        },
        [_vm._v(_vm._s(_vm.postOpDay))]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { id: _vm.collapseId } },
        [_c("surgery-time", { attrs: { stay_id: _vm.stay.id } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }



















import Vue from 'vue'
import utils from 'utils'
import * as commonmark from "commonmark"

export default Vue.extend({
    props: {
        mainNav: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const fullVersion = window.scrawl.sentryRelease
        const tokens = fullVersion.split('@')
        const version = tokens.length ? tokens[tokens.length-1] : '?'

        return {
            version,
            htmlId: `${utils.getUID()}___changelog`,
            changelogVer: null as string | null,
            changelog: '**No information provided**'
        }
    },
    computed: {
        cmReader() {
            return new commonmark.Parser()
        },
        cmWriter() {
            return new commonmark.HtmlRenderer()
        },
        title(): string {
            return this.version
        },
        changelogHtml(): string {
            return this.cmWriter.render(this.cmReader.parse(this.changelog))
        },
    },
    mounted() {
        this.getMarkdown()
    },
    watch: {
        changelogVer() {
            this.getMarkdown()
        }
    },
    methods: {
        getMarkdown(): void {
            let mdFile = 'changelog.md'
            if (this.changelogVer !== null)
                mdFile = `changelog-${this.changelogVer}.md`
            utils.request.get(`static/${mdFile}`, undefined, true)
            .then(res => {
                this.changelog = res.text
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
        }
    }
})

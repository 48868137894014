








import Vue from 'vue'
import MdtPresent from '@shared/stay-inputs/MDTPresent.vue'
import MdtQuestions from './MDTQuestions.vue'
import MdtText from '@text/MDT.vue'

export default Vue.extend({
    components: {
        MdtPresent,
        MdtQuestions,
        MdtText,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
})

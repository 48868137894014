




































































































































import Vue from 'vue'

import { every, filter, forEach, groupBy, map, reject, sortBy, values } from 'lodash-es'

import stays from '@store/stays'
import { anonymisedPatient } from 'models/data/patient'
import { HyperacuteStay } from 'models/dataviz'

import StaysInfo from './StaysInfo.vue'


function median(arr: any[]) {
    arr = arr.slice(0) // create copy
    arr = reject(arr, function(n) { return n === null })
    if (!arr.length) return

    const middle = (arr.length + 1) / 2
    const sorted = sortBy(arr)
    return (sorted.length % 2) ? sorted[middle - 1] : (sorted[middle - 1.5] + sorted[middle - 0.5]) / 2
}


export default Vue.extend({
    components: {
        StaysInfo,
    },
    props: {
        loading: {
            type: Boolean,
        },
        presentationTypes: {
            type: Array as () => string[]
        }
    },
    data() {
        return {
            showRawData: false,
        }
    },
    methods: {
        allInHospitalStrokes(stays: HyperacuteStay[]): boolean {
            const allInHospitalStays = every(stays, (stay) => {
                return (
                    stay.presentation_type &&
                    stay.presentation_type.toLowerCase().indexOf('in hospital') > -1
                )
            })
            if (stays.length && allInHospitalStays) return true
            return false
        },
        listValues(stays: HyperacuteStay[], field: keyof HyperacuteStay) {
            // Show nothing if it's a "Door to ..." field in the "In hospital stroke" column
            if (field.indexOf('door_to_') === 0 && this.allInHospitalStrokes(stays)) return

            const values = map(stays, (stay) => {
                let value = stay[field]
                if (value === null) value = '?'
                if (value === true) value = 'Yes'
                if (value === false) value = 'No'
                return `<span title="${this.patientSummary(stay)}\n${value}">${value}</span>`
            })
            if (values.length) {
                return values.join(', ')
            }
            return '-'
        },
        patientSummary(stay: HyperacuteStay) {
            let patient = stay.patient
            if (this.anonymise) {
                patient = anonymisedPatient(null)
            }
            return `${patient.first_name} ${patient.last_name} (MRN: ${patient.mrn})`
        },
        countTrueValues(stays: HyperacuteStay[], field: string) {
            if (!stays.length) return '-'
            const staysWhereTrue = filter(stays, field)
            return `${staysWhereTrue.length}/${stays.length}`
        },
        asapUsed(stays: HyperacuteStay[]) {
            return this.countTrueValues(stays, 'asap_used')
        },
        callBeforeCT(stays: HyperacuteStay[]) {
            return this.countTrueValues(stays, 'first_contact_made_prior_to_ct')
        },
        medianDoorToTime(stays: HyperacuteStay[], field: string) {
            // Return "NR" for all "Door to ..." values if all the Stays are "In hospital" strokes
            // because "Door to ..." doesn't make sense for them
            if (this.allInHospitalStrokes(stays)) return 'NR' 

            const times = map(stays, field)
            const medianTime = median(times)
            if (medianTime) return medianTime
            return '-'
        },
        doorToAlert(stays: HyperacuteStay[]) {
            return `${this.medianDoorToTime(stays, 'door_to_alert')}`
        },
        doorToCT(stays: HyperacuteStay[]) {
            return `${this.medianDoorToTime(stays, 'door_to_ct')}`
        },
        doorToLysisDecision(stays: HyperacuteStay[]) {
            return `${this.medianDoorToTime(stays, 'door_to_lysis_decision')}`
        },
        doorToECRDecision(stays: HyperacuteStay[]) {
            return `${this.medianDoorToTime(stays, 'door_to_ecr_decision')}`
        },
        doorToNeedle(stays: HyperacuteStay[]) {
            return `${this.medianDoorToTime(stays, 'door_to_needle')}`
        },
        filterLysisStays(stays: HyperacuteStay[]): HyperacuteStay[] {
            return filter(stays, ['is_lysis', true])
        },
    },
    computed: {
        anonymise(): boolean {
            return this.$store.direct.state.user.anonymise
        },
        stays(): HyperacuteStay[] {
            const allStays = values(stays.state.stays) as HyperacuteStay[]
            // Show all stays in telestroke mode
            if (this.telestrokeMode) return allStays

            // Only show Hyperacute stays in non-telestroke mode
            return filter(allStays, ['hyperacute_review', true])
        },
        staysByPresentationType(): {[k: string]: HyperacuteStay[]} {
            return groupBy(this.stays, 'presentation_type')
        },
        staysGroups(): HyperacuteStay[][] {
            let staySubsets: HyperacuteStay[][] = []
            forEach(this.presentationTypes, (presentationType) => {
                staySubsets.push(this.staysByPresentationType[presentationType] || [])
            })
            return staySubsets
        },
        staysLoading(): boolean {
            return stays.state.staysLoading
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
    },
})

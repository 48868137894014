




























import mixins from "vue-typed-mixins"
import utils from 'utils'
import { LayoutGroupExt, PropExtInfo } from "models"
import { AnyPropLookup } from "models/schema"
import { FullSchema } from 'models/schema/stay'
import { DisplayLookup, LayoutLookup } from "models/layout"

import DisplayMixin from "@mixins/Display.vue"
import InputMixin from "@mixins/Input.vue"
import StayMixin from '@mixins/Stay.vue'
import DateTimePicker from '@shared/inputs/DTPickerEnhanced.vue'
import Vision from "./Vision.vue"
import Motor from "./Motor.vue"
import Generic from "@sections/Generic.vue"
import RInput from "@inputs/Input.vue"

const displayInfo: DisplayLookup = {
    higher_centers: [
        {
            field: "consciousness",
            dropdown: true
        },
        {
            field: "age",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "month",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "close_open_eyes",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "make_a_fist",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "language",
            dropdown: true,
            full_width: true
        },
        {
            field: "dysarthria",
            dropdown: true,
            full_width: true
        }
    ],
    sensations: [
        {
            field: "sensory_loss_right",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_loss_left",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_inattention_right",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_inattention_left",
            dropdown: true,
            full_width: true
        }
    ]
}

const layout: LayoutLookup = {
    higher_centers: [
        { fields: [ "consciousness" ] },
        { label: "Orientation & comprehension", fields: ["age", "month", "close_open_eyes", "make_a_fist"], alignment: "stacked" },
        { fields: ["language", "dysarthria"], alignment: "inline" }
    ],
    sensations: [
        { label: "Sensory Exam (Pinprick)", fields: ["sensory_loss_right", "sensory_loss_left"], alignment: "inline" },
        { label: "Sensory Inattenion", fields: ["sensory_inattention_right", "sensory_inattention_left"], alignment: "inline" }
    ]
}

export default mixins(DisplayMixin, InputMixin, StayMixin).extend({
    components: {
        Vision,
        Motor,
        Generic,
        RInput,
        DateTimePicker,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        nihss_id: {
            type: Number,
            required: true,
        },
    },
    data() {
        const uid = utils.getUID()

        let section: keyof FullSchema = 'nihss_set'

        return {
            uid,
            htmlID: `${uid}___${section}`,
            section,
        }
    },
    computed: {
        schema(): AnyPropLookup | undefined {
            return this.getChildSchema(this.section)
        },
        statusMsg(): string {
            return this.schema ? "" : "Schema loading..."
        },
        hcFields(): PropExtInfo[] {
            return this.schema
                ? this.makePropExtInfo_NIHSS(this.schema, displayInfo.higher_centers, this.nihss_id)
                : []
        },
        hcGroups(): LayoutGroupExt[] | undefined {
            return this.makeGroups_NIHSS(this.hcFields, layout.higher_centers, this.nihss_id)
        },
        sensationFields(): PropExtInfo[] {
            return this.schema
                ? this.makePropExtInfo_NIHSS(this.schema, displayInfo.sensations, this.nihss_id)
                : []
        },
        sensationGroups(): LayoutGroupExt[] | undefined {
            return this.makeGroups_NIHSS(this.sensationFields, layout.sensations, this.nihss_id)
        },
        notesPropExtInfo(): PropExtInfo {
            return this.makePropExtInfo_NIHSS(this.schema || {}, ['notes'], this.nihss_id)[0]
        },
        assessedAt: {
            get(): string | null {
                return this.getArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, 'assessed_at')
            },
            set(val: string | null) {
                this.setArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, 'assessed_at', val)
            }
        },
    },
})




















import Vue from 'vue'
import { cloneDeep } from 'lodash-es'
import FlatPickr from 'flatpickr'
import DateTimePicker from '@shared/inputs/DateTimePicker.vue'

export default Vue.extend({
    components: {
        DateTimePicker,
    },
    props: {
        value: {},
        config: {
            type: Object as () => FlatPickr.Options.Options,
            default: () => ({ wrap: true, enableTime: true } as FlatPickr.Options.Options)
        },
        monthPicker: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        finalConfig(): FlatPickr.Options.Options {
            let config = this.config
            if (!this.config.wrap) {
                config = cloneDeep(this.config)
                config.wrap = true
            }
            return config

        }
    }
})

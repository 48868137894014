var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasChoices && _vm.isDropdown
    ? _c("dropdown", {
        attrs: {
          label: _vm.labelText,
          hide_label: _vm.hideLabel,
          items: _vm.choices,
          "text-field": "label",
          "value-field": "value",
          "full-width": _vm.field.full_width,
          "debug-mode": _vm.debugMode,
          "debug-label-text": _vm.debugLabelText,
          horizontal: _vm.isHorizontal,
          helpText: _vm.helpText
        },
        model: {
          value: _vm.fieldAny,
          callback: function($$v) {
            _vm.fieldAny = $$v
          },
          expression: "fieldAny"
        }
      })
    : _c(
        "b-form-group",
        {
          staticClass: "mb-0",
          class: _vm.formGroupClasses,
          attrs: {
            "label-class": "pb-0 mb-0",
            "label-cols-sm": _vm.labelCols,
            id: _vm.labelID,
            "label-for": _vm.labelFor,
            "invalid-feedback": _vm.invalidFeedback,
            state: _vm.isValid
          },
          scopedSlots: _vm._u(
            [
              !_vm.hideLabel
                ? {
                    key: "label",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.labelText) +
                                "\n            "
                            ),
                            _vm.hasIcon
                              ? _c("fnt-a-icon", {
                                  style: _vm.iconStyle,
                                  attrs: { icon: _vm.field.fnt_a_icon }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.field.has_notes
                              ? _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle",
                                        value: _vm.notesID,
                                        expression: "notesID"
                                      }
                                    ],
                                    staticClass: "notes-btn",
                                    attrs: { type: "button" }
                                  },
                                  [
                                    _vm.notes
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "badge badge-primary"
                                          },
                                          [_vm._v(_vm._s(_vm.notes))]
                                        )
                                      : _c("fnt-a-icon", {
                                          attrs: { icon: ["fas", "plus"] }
                                        })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.field.help_text
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  opacity: "0.8",
                                  "font-size": "0.8rem"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.field.help_text) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                : null,
              _vm.helpText
                ? {
                    key: "description",
                    fn: function() {
                      return [
                        _c("p", {
                          staticClass: "mb-0",
                          domProps: { innerHTML: _vm._s(_vm.helpText) }
                        })
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _vm._v(" "),
          _vm.isString
            ? [
                _vm.hasMaxLength
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.fieldAny,
                          expression: "fieldAny"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: _vm.htmlID,
                        type: "text",
                        placeholder: _vm.placeholder
                      },
                      domProps: { value: _vm.fieldAny },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.fieldAny = $event.target.value
                        }
                      }
                    })
                  : _c("text-area", {
                      attrs: {
                        id: _vm.htmlID,
                        placeholder: _vm.placeholder,
                        rows: _vm.rows
                      },
                      model: {
                        value: _vm.fieldAny,
                        callback: function($$v) {
                          _vm.fieldAny = $$v
                        },
                        expression: "fieldAny"
                      }
                    })
              ]
            : _vm.isBool && _vm.isCombined
            ? _c("div", { staticClass: "pretty p-svg p-plain p-bigger my-3" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.fieldAny,
                      expression: "fieldAny"
                    }
                  ],
                  attrs: { type: "checkbox", disabled: _vm.field.disabled },
                  domProps: {
                    value: true,
                    checked: Array.isArray(_vm.fieldAny)
                      ? _vm._i(_vm.fieldAny, true) > -1
                      : _vm.fieldAny
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.fieldAny,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = true,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.fieldAny = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.fieldAny = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.fieldAny = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "state p-info-o" },
                  [
                    _c("fnt-a-icon", {
                      staticClass: "svg",
                      attrs: { icon: ["fal", "check"] }
                    }),
                    _vm._v(" "),
                    _c("label", [_vm._v(_vm._s(_vm.labelText))])
                  ],
                  1
                )
              ])
            : _vm.hasChoices && !_vm.isDropdown
            ? [
                _vm.choicesInline
                  ? _vm._l(_vm.choices, function(choice) {
                      return _c(
                        "div",
                        {
                          key: choice.htmlID,
                          staticClass: "pretty p-svg p-plain p-bigger my-3"
                        },
                        [
                          _vm.isMulti
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.fieldAny,
                                    expression: "fieldAny"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: choice.value,
                                  checked: Array.isArray(_vm.fieldAny)
                                    ? _vm._i(_vm.fieldAny, choice.value) > -1
                                    : _vm.fieldAny
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.fieldAny,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = choice.value,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.fieldAny = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.fieldAny = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.fieldAny = $$c
                                    }
                                  }
                                }
                              })
                            : _c("radio", {
                                attrs: { "target-val": choice.value },
                                model: {
                                  value: _vm.fieldAny,
                                  callback: function($$v) {
                                    _vm.fieldAny = $$v
                                  },
                                  expression: "fieldAny"
                                }
                              }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "state p-info-o" },
                            [
                              _c("fnt-a-icon", {
                                staticClass: "svg",
                                attrs: { icon: ["fal", "check"] }
                              }),
                              _vm._v(" "),
                              _c("label", [_vm._v(_vm._s(choice.label))])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  : _vm._l(_vm.choices, function(choice) {
                      return _c(
                        "div",
                        { key: choice.htmlID, staticClass: "my-3" },
                        [
                          _c(
                            "div",
                            { staticClass: "pretty p-svg p-plain p-bigger" },
                            [
                              _vm.isMulti
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.fieldAny,
                                        expression: "fieldAny"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      value: choice.value,
                                      checked: Array.isArray(_vm.fieldAny)
                                        ? _vm._i(_vm.fieldAny, choice.value) >
                                          -1
                                        : _vm.fieldAny
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.fieldAny,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = choice.value,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.fieldAny = $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.fieldAny = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.fieldAny = $$c
                                        }
                                      }
                                    }
                                  })
                                : _c("radio", {
                                    attrs: { "target-val": choice.value },
                                    model: {
                                      value: _vm.fieldAny,
                                      callback: function($$v) {
                                        _vm.fieldAny = $$v
                                      },
                                      expression: "fieldAny"
                                    }
                                  }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "state p-info-o" },
                                [
                                  _c("fnt-a-icon", {
                                    staticClass: "svg",
                                    attrs: { icon: ["fal", "check"] }
                                  }),
                                  _vm._v(" "),
                                  _c("label", [_vm._v(_vm._s(choice.label))])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    })
              ]
            : _vm.isInteger
            ? _c(
                "b-input-group",
                { attrs: { append: _vm.unitText } },
                [
                  _c("b-form-input", {
                    attrs: {
                      lazy: "",
                      number: "",
                      type: "number",
                      disabled: _vm.field.disabled
                    },
                    model: {
                      value: _vm.fieldAny,
                      callback: function($$v) {
                        _vm.fieldAny = $$v
                      },
                      expression: "fieldAny"
                    }
                  })
                ],
                1
              )
            : _vm.isFloatOrDecimal
            ? _c(
                "b-input-group",
                { attrs: { append: _vm.unitText } },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "number",
                      step: "any",
                      disabled: _vm.field.disabled
                    },
                    model: {
                      value: _vm.fieldDecimal,
                      callback: function($$v) {
                        _vm.fieldDecimal = $$v
                      },
                      expression: "fieldDecimal"
                    }
                  })
                ],
                1
              )
            : _vm.isDate || _vm.isDateTime
            ? _c(
                "div",
                { staticClass: "input-group" },
                [
                  _c("date-time-picker", {
                    attrs: {
                      config: { wrap: true, enableTime: _vm.isDateTime }
                    },
                    model: {
                      value: _vm.fieldAny,
                      callback: function($$v) {
                        _vm.fieldAny = $$v
                      },
                      expression: "fieldAny"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-info",
                        attrs: { type: "button", "data-toggle": "" }
                      },
                      [
                        _c("fnt-a-icon", {
                          attrs: { icon: ["fal", "calendar"] }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-danger",
                        attrs: { type: "button", "data-clear": "" }
                      },
                      [_c("fnt-a-icon", { attrs: { icon: ["fal", "times"] } })],
                      1
                    )
                  ])
                ],
                1
              )
            : !_vm.isUnknown
            ? _c(
                "b-alert",
                {
                  staticClass: "mb-0",
                  attrs: { show: "", variant: "secondary" }
                },
                [_vm._v("[UNIMPLEMENTED]")]
              )
            : _c(
                "b-alert",
                { staticClass: "mb-0", attrs: { show: "", variant: "danger" } },
                [_vm._v("[UNKNOWN]")]
              ),
          _vm._v(" "),
          _vm.field.has_notes
            ? _c(
                "b-collapse",
                { attrs: { id: _vm.notesID } },
                [
                  _c(
                    "b-form-group",
                    { staticClass: "pr-3", attrs: { label: "Notes" } },
                    [
                      _c("b-form-textarea", {
                        attrs: { rows: "3" },
                        model: {
                          value: _vm.notes,
                          callback: function($$v) {
                            _vm.notes = $$v
                          },
                          expression: "notes"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: _vm.notesID,
                          expression: "notesID"
                        }
                      ],
                      staticClass: "btn btn-info btn-sm mb-3",
                      attrs: { type: "button" }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
        [
          _c("b-card-header", [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("h5", { staticClass: "mb-0" }, [_vm._v("Higher Centers")])
            ])
          ]),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "" } },
            [
              _c(
                "b-card-body",
                { staticClass: "pt-0 pb-1" },
                [
                  _c("generic", {
                    attrs: {
                      stay_id: _vm.stay_id,
                      groups: _vm.hcGroups,
                      "override-msg": _vm.statusMsg
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-card",
        { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
        [
          _c("b-card-header", [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("h5", { staticClass: "mb-0" }, [_vm._v("Eyes")])
            ])
          ]),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "" } },
            [
              _c(
                "b-card-body",
                { staticClass: "py-0" },
                [
                  _c("vision", {
                    attrs: { stay_id: _vm.stay_id, section: _vm.section }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-card",
        { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
        [
          _c("b-card-header", [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("h5", { staticClass: "mb-0" }, [_vm._v("Motor")])
            ])
          ]),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "" } },
            [
              _c(
                "b-card-body",
                { staticClass: "py-0" },
                [
                  _c("motor", {
                    attrs: { stay_id: _vm.stay_id, section: _vm.section }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-card",
        { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
        [
          _c("b-card-header", [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("h5", { staticClass: "mb-0" }, [_vm._v("Sensation")])
            ])
          ]),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "" } },
            [
              _c(
                "b-card-body",
                { staticClass: "py-0" },
                [
                  _c("generic", {
                    attrs: {
                      stay_id: _vm.stay_id,
                      groups: _vm.sensationGroups,
                      "override-msg": _vm.statusMsg
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
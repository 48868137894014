






























import Vue from 'vue'
import { filter, includes, map, toInteger, pick, pickBy, each } from 'lodash-es'
import utils from 'utils'
import FilterBase from './FilterBase.vue'
import { Diagnosis } from 'models/med_templates/diagnosis'
import { Speciality } from 'models/med_templates/speciality'


/**
 * This filter component operates a bit different from the others. If 'All' is selected,
 * it explicitly sets the IDs list to the user's assigned hospitals' IDs.
 * This produces the desired default outcomes for 'All':
 * - if a user has assigned hospitals, the query is such that the backend will filter by by those hospitals
 * - if a user has no assigned hospitals, the hospital IDs list is empty, and thus the backend will skip filtering by hospitals anyway
 */
export default Vue.extend({
    components: {
        FilterBase,
    },
    data() {
        return {
            selecting: false,
            selectedDict: {} as { [index: number]: boolean },
        }
    },
    computed: {
        allSpecialities(): Speciality[] {
            return this.$store.direct.state.templates.specialities
        },
        allDiagnoses(): readonly Diagnosis[] {
            return this.$store.direct.getters.templates.allDiagnoses
        },
        visibleSpecialities(): Speciality[] {
            const userSpecialities = this.$store.direct.state.user.filters.specialities
            if (userSpecialities.length === 0)
                return this.allSpecialities
            return filter(this.allSpecialities, sp => includes(userSpecialities, sp.id))
        },
        userDiagnoses(): number[] {
            return this.$store.direct.state.user.filters.diagnoses
        },
        selectedIDs(): number[] {
            return map(pickBy(this.selectedDict, (v, _) => v), (_, id) => toInteger(id))
        },
        selectionsEqual(): boolean {
            return utils.isEqual(this.userDiagnoses, this.selectedIDs)
        },
        selectedDiags(): readonly Diagnosis[] {
            return filter(this.allDiagnoses, d => this.isSelected(d.id))
        },
        selectedDiagTitles(): string[] {
            return map(this.selectedDiags, opt => opt.title)
        },
        selectedText(): string {
            return this.selectedDiagTitles.join(", ") || "[All]"
        },
        displayText(): string {
            const truncateMaxLength = 50
            return this.selectedText.length > truncateMaxLength ? `${this.selectedText.slice(0, truncateMaxLength)}...` : this.selectedText
        },
        hoverText(): string {
            return this.selectedDiagTitles.join('\n') || '[All]'
        },
    },
    watch: {
        userDiagnoses: {
            handler: function(val: any) {
                const userSelection: number[] = val
                if (this.selectionsEqual)
                    return
                const selectedDict: { [index: number]: boolean } = {}
                each(userSelection, id => selectedDict[id] = true)
                this.selectedDict = selectedDict
            },
            immediate: true,
        },
    },
    methods: {
        allSelected(): void {
            this.selectedDict = {}
        },
        toggleSelect(id: number) {
            this.selecting = true
            Vue.set(this.selectedDict, id, !this.isSelected(id))
        },
        isSelected(id: number): boolean {
            return this.selectedDict[id]
        },
        setUserDiagnoses(): void {
            if (this.selectionsEqual)
                return
            this.$store.direct.dispatch.user.updateFilters({ diagnoses: this.selectedIDs })
            this.$emit('update')
        },
        hideHandler(event: any) {
            if (this.selecting)
                event.preventDefault()
            this.selecting = false
        }
    },
})

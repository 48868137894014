



























































import Vue from 'vue'
import { map } from 'lodash-es'
import * as moment from 'moment'
import DateTimePicker from '@shared/inputs/DateTimePicker.vue'
import Dropdown from '@shared/inputs/Dropdown.vue'


const momentDateFmt = 'YYYYMMDD'
type TimeUnit = 'day' | 'week'
type TimeRange = 'default' | 'today' | 'week_to_date' | 'this_week' | 'past_7d'

export default Vue.extend({
    components: {
        DateTimePicker,
        Dropdown,
    },
    props: {
        useTimeUnit: {
            type: Boolean,
            default: true
        },
        endInclusive: {
            type: Boolean,
            default: false
        },
        initialUnit: {
            type: String as () => TimeUnit,
            default: 'day' as TimeUnit
        },
        initialRange: {
            type: String as () => TimeRange,
            required: false,
            default: 'default' as TimeRange
        },
    },
    data() {
        return {
            allTimeUnits: map(['hour', 'day', 'week', 'month', 'year'], unit => {
                return {
                    text: unit,
                    id: unit
                }
            }),
            startDate: '',
            endDate: '',
            timeUnit: 'day',
        }
    },
    computed: {
        startMoment(): moment.Moment | null {
            const dt = moment(this.startDate)
            return dt.isValid() ? dt : null
        },
        endMoment(): moment.Moment | null {
            const dt = moment(this.endDate)
            return dt.isValid() ? dt : null
        },
    },
    watch: {
        startDate: {
            handler: function(val) { this.$emit('start_dt', val) },
            immediate: true
        },
        endDate: {
            handler: function(val) { this.$emit('end_dt', val) },
            immediate: true
        },
        timeUnit: {
            handler: function(val) { this.$emit('time_unit', val) },
            immediate: true
        },
    },
    created() {
        switch (this.initialRange) {
            case 'today':
                this.setToday()
                break
            case 'this_week':
                this.setThisWeek()
                break
            case 'week_to_date':
                this.setWeekToDate()
                break
            case 'past_7d':
                this.setPast7Days()
                break
            default:
                const startMoment = moment().startOf('day')
                const endMoment = this.endInclusive ? startMoment : moment(startMoment).add(1, this.initialUnit)
                this.startDate = startMoment.format(momentDateFmt)
                this.endDate = endMoment.format(momentDateFmt)
        }
    },
    methods: {
        opened() {
            this.$emit('opened')
        },
        closed() {
            this.$emit('closed')
        },
        subtractDay() {
            if (this.startMoment && this.endMoment) {
                this.startDate = moment(this.startMoment).subtract(1, 'day').format(momentDateFmt)
                this.endDate = moment(this.endMoment).subtract(1, 'day').format(momentDateFmt)
            }
        },
        addDay() {
            if (this.startMoment && this.endMoment) {
                this.startDate = moment(this.startMoment).add(1, 'day').format(momentDateFmt)
                this.endDate = moment(this.endMoment).add(1, 'day').format(momentDateFmt)
            }
        },
        subtractWeek() {
            if (this.startMoment && this.endMoment) {
                this.startDate = moment(this.startMoment).subtract(1, 'week').format(momentDateFmt)
                this.endDate = moment(this.endMoment).subtract(1, 'week').format(momentDateFmt)
            }
        },
        addWeek() {
            if (this.startMoment && this.endMoment) {
                this.startDate = moment(this.startMoment).add(1, 'week').format(momentDateFmt)
                this.endDate = moment(this.endMoment).add(1, 'week').format(momentDateFmt)
            }
        },
        setToday() {
            this.startDate = moment().startOf('day').format(momentDateFmt)
            const endDT = moment().startOf('day')
            if (!this.endInclusive) endDT.add(1, 'day')
            this.endDate = endDT.format(momentDateFmt)
        },
        setWeekToDate() {
            this.startDate = moment().startOf('isoWeek').format(momentDateFmt)
            const endDT = moment().startOf('day')
            if (!this.endInclusive) endDT.add(1, 'day')
            this.endDate = endDT.format(momentDateFmt)
        },
        setThisWeek() {
            this.startDate = moment().startOf('isoWeek').format(momentDateFmt)
            const endDT = moment().startOf('isoWeek').add(6, 'days')
            if (!this.endInclusive) endDT.add(1, 'day')
            this.endDate = endDT.format(momentDateFmt)
        },
        setPast7Days() {
            const endDT = moment().startOf('day')
            const startDate = moment(endDT).subtract(6, 'days')
            if (!this.endInclusive) endDT.add(1, 'day')
            this.startDate = startDate.format(momentDateFmt)
            this.endDate = endDT.format(momentDateFmt)
        }
    }
})

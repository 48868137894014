import VueRouter from 'vue-router'

import Reload from 'components/apps/Reload.vue'
import NotFound from '@misc/NotFound.vue'
import Shell from '@standalone/Shell.vue'
import Landing from '@standalone/Landing.vue'
import Assessment from '@standalone/Assessment.vue'


const routes = [
    {
        name: 'home',
        path: '/standalone/',
        component: Landing
    },
    {
        path: '/standalone/app',
        component: Shell,
        children: [
            {
                name: 'asap-tool',
                path: 'asap-*',
                component: Assessment,
                props: true
            }
        ]
    },
    {
        path: '/standalone/*',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/*',
        component: Reload
    },
]

const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
    if (to.name === 'asap-tool' && to.params.pathMatch && /^(.*[A-Z].*)$/.test(to.params.pathMatch))
        next(`/standalone/app/asap-${to.params.pathMatch.toLowerCase()}`)
    else
        next()
})

export default router






















































import mixins from 'vue-typed-mixins'
import NihssMixin from '@mixins/NIHSS.vue'
import { get } from 'lodash-es'
import utils from 'utils'

import stays from '@store/stays'
import { NIHSS } from 'models/data/nihss'
import { Stay } from 'models/data/stay'
import { QuestionCategory } from 'models/med_templates/question'
import { Speciality } from 'models/med_templates/speciality'

import Checkbox from '@shared/inputs/Checkbox.vue'
import Nihss from '@sections/nihss/NIHSS.vue'
import NihssSelect from '@sections/nihss/v2/NIHSSSelect.vue'
import Questions from '@stayinputs/Questions.vue'
import TextArea from "@shared/inputs/TextArea.vue"


export default mixins(NihssMixin).extend({
    components: {
        Checkbox,
        Nihss,
        NihssSelect,
        Questions,
        TextArea
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            QuestionCategory,
            htmlIDs: {
                nihss: `${uid}__ward-nihss`
            },
            altLayout: true,
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        speciality(): Speciality | undefined {
            return stays.getSpeciality(this.stay_id)
        },
        isNeurologyStay(): boolean {
            if (!this.stay) return false
            return this.stay.speciality_title.toLowerCase().trim() === 'neurology'
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        wardExamNotes: {
            get(): string {
                return get(this.stay, 'ward_round.exam_notes', '')
            },
            set(notes: string) {
                stays.extActSetPathValue(this.stay_id, 'ward_round.exam_notes', notes)
            }
        },
        nihssObj(): NIHSS | null | undefined {
            return this.stay?.ward_round_nihss
        },
        nihssComplete(): boolean {
            return this.nihssObj?.complete || false
        },
        nihssScore(): number | string {
            return this.nihssObj?.score || '?'
        },
        nihssV1(): boolean {
            return this.$store.direct.state.session.nihss_version === 'v1'
        },
    }
})























































import mixins from "vue-typed-mixins"
import { every, floor, isNil, map, max, min, reduce, sum, zip } from 'lodash-es'

import utils from 'utils'
import { LayoutGroupExt, PropExtInfo } from "models"
import { NestedProp } from "models/schema"
import { DisplayList, LayoutLookup } from "models/layout"
import { FullSchema } from 'models/schema/stay'

import DisplayMixin from "@mixins/Display.vue"
import InputMixin from "@mixins/Input.vue"
import Generic from "@sections/Generic.vue"
import Motor from "./NIHSSMotor.vue"
import RInput from "@inputs/Input.vue"
import Vision from "./NIHSSVision.vue"

const displayInfo: { [k: string]: DisplayList } = {
    nihss_highercenters: [
        {
            field: "consciousness",
            dropdown: true,
            full_width: true
        },
        {
            field: "language",
            dropdown: true,
            full_width: true
        },
        {
            field: "dysarthria",
            dropdown: true,
            full_width: true
        },
        {
            field: "age",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "month",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "close_open_eyes",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "make_a_fist",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
    ],
    nihss_sensations: [
        {
            field: "sensory_loss_right",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_loss_left",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_inattention_right",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_inattention_left",
            dropdown: true,
            full_width: true
        }
    ]
}

const layout: LayoutLookup = {
    nihss_highercenters: [
        { fields: [ "consciousness", "language", "dysarthria" ] },
        { label: "Orientation & comprehension", fields: ["age", "month", "close_open_eyes", "make_a_fist"], alignment: "stacked" },
    ],
    nihss_sensations: [
        { fields: ["sensory_loss_right", "sensory_loss_left"], alignment: "inline" },
        { fields: ["sensory_inattention_right", "sensory_inattention_left"], alignment: "inline" }
    ]
}

const limbStrength = {
    "NO_DRIFT": 0,
    "MINOR_DRIFT": 1,
    "DRIFT": 2,
    "FALLS_IMMEDIATELY": 3,
    "NO_MOVEMENT": 4
}

const enumScoreMap: any = {
    'consciousness': {
        "ALERT": 0,
        "MINOR_STIMULATION": 1,
        "PAINFUL_STIMULI": 2,
        "UNRESPONSIVE": 3
    },
    'language': {
        "NORMAL": 0,
        "LOSS_OF_FLUENCY": 1,
        "SEVERE_APHASIA": 2,
        "UNABLE_TO_SPEAK": 3
    },
    'dysarthria': {
        "NORMAL": 0,
        "MILD": 1,
        "SEVERE": 2,
    },
    'gaze': {
        "NORMAL": 0,
        "UNABLE_TO_LOOK_RIGHT": 11,
        "UNABLE_TO_LOOK_LEFT": 12,
        "FORCED_DEVIATION_TO_RIGHT": 21,
        "FORCED_DEVIATION_TO_LEFT": 22
    },
    'facialStrength': {
        "NORMAL": 0,
        "MINOR_PARALYSIS": 1,
        "PARTIAL_PARALYSIS": 2,
        "TOTAL_PARALYSIS": 3
    },
    'arm_strength_right': limbStrength,
    'arm_strength_left': limbStrength,
    'leg_strength_right': limbStrength,
    'leg_strength_left': limbStrength,
    'sensoryLoss': {
        "NONE": 0,
        "DULL": 1,
        "SEVERE": 2
    },
    'sensoryInattention': {
        "NONE": 0,
        "INATTENTION": 1
    }
}

export default mixins(DisplayMixin, InputMixin).extend({
    components: {
        Generic,
        Motor,
        RInput,
        Vision,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        prefix: {
            type: String,
            required: false
        }
    },
    data() {
        const uid = utils.getUID()
        const section = this.prefix ? `${this.prefix}_nihss` : "nihss"
        return {
            uid,
            htmlID: `${uid}___${section}`,
            section,
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        schema(): NestedProp | undefined {
            return this.getSectionSchema('nihss' as (keyof FullSchema))  // hack to avoid failing typing: as (keyof)
        },
        statusMsg(): string {
            return this.schema ? "" : "Schema loading..."
        },
        hcFields(): PropExtInfo[] {
            return this.schema
                ? this.generatePropExtInfo(this.section, this.schema.children, displayInfo.nihss_highercenters)
                : []
        },
        hcGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups(this.section, this.hcFields, layout.nihss_highercenters)
        },
        sensationFields(): PropExtInfo[] {
            return this.schema
                ? this.generatePropExtInfo(this.section, this.schema.children, displayInfo.nihss_sensations)
                : []
        },
        sensationGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups(this.section, this.sensationFields, layout.nihss_sensations)
        },
        notesPropExtInfo(): PropExtInfo {
            return this.generatePropExtInfo(this.section, this.schema ? this.schema.children : {}, ["notes"])[0]
        },
        visualFieldsScore(): number {
            const valArray: number[] = this.getFieldVal(this.stay_id, `${this.section}.visual_fields`) || [0,0,0,0,0,0,0,0]

            // All quadrants ticked => Complete blindness
            if (sum(valArray) === 8)
                return 3

            const leftEye = valArray.slice(0,4)
            const rightEye = valArray.slice(4,)
            const eyesEqual = every(zip(leftEye, rightEye), pair => pair[0] === pair[1])

            if (eyesEqual) {
                if (sum(leftEye) === 1) // Quadrantanopia
                    return 1

                // homonymous hemianopia templates
                const rightHH = [1, 0, 1, 0]
                const leftHH = [0, 1, 0, 1]

                const isRightHH = every(zip(leftEye, rightHH), pair => pair[0] === pair[1])
                const isLeftHH = every(zip(leftEye, leftHH), pair => pair[0] === pair[1])

                if (isRightHH || isLeftHH)
                    return 2
            }

            return 0
        },
        visualInattentionScore(): number {
            const valArray: number[] = this.getFieldVal(this.stay_id, `${this.section}.visual_inattention`) || [0,0,0,0,0,0,0,0]
            const leftEye = valArray.slice(0,4)
            const rightEye = valArray.slice(4,)
            const eyesEqual = every(zip(leftEye, rightEye), pair => pair[0] === pair[1])

            if (eyesEqual) {
                if (sum(leftEye) === 1) // Visual inattention
                    return 1

                // inattention templates
                const rightIA = [1, 0, 1, 0]
                const leftIA = [0, 1, 0, 1]

                const isRightIA = every(zip(leftEye, rightIA), pair => pair[0] === pair[1])
                const isLeftIA = every(zip(leftEye, leftIA), pair => pair[0] === pair[1])

                if (isRightIA || isLeftIA)
                    return 1
            }

            return 0
        },
        gazeScore(): number {
            return floor(this.getEnumScore('gaze', 'gaze') / 10)
        },
        facialStrScore(): number {
            const scores = map(['facial_strength_right', 'facial_strength_left'], field => this.getEnumScore('facialStrength', field))
            return max(scores)!
        },
        ataxiaScore(): number {
           // Ataxia score for a given limb is ignored if strength score for that limb is >= 3
            const pairs = [
                ['leg_strength_right', 'ataxia_leg_right'],
                ['leg_strength_left', 'ataxia_leg_left'],
                ['arm_strength_right', 'ataxia_arm_right'],
                ['arm_strength_left', 'ataxia_arm_left'],
            ]

            const localScore = reduce(pairs, (total, pair) => {
                const strField = pair[0], ataxiaField = pair[1]
                const limbScore = this.getEnumScore(strField, strField)
                const ataxiaScore = limbScore < 3 ? this.getBoolScore(ataxiaField) : 0
                return total + ataxiaScore
            }, 0)

           return min([localScore, 2])!
        },
        sensationScore(): number {
            // sensory scores are max of each type's side
            const lossScore = max(map(['sensory_loss_right', 'sensory_loss_left'], field => this.getEnumScore('sensoryLoss', field)))!
            const inattentionScore = max(map(['sensory_inattention_right', 'sensory_inattention_left'], field => this.getEnumScore('sensoryInattention', field)))!
            const localScore = lossScore + inattentionScore
            return min([localScore, 2])!
        },
        score(): number {
            const enumFields = [
                'consciousness',
                'language',
                'dysarthria',
                'arm_strength_right',
                'arm_strength_left',
                'leg_strength_right',
                'leg_strength_left',
            ]
            let enumScores: number = reduce(enumFields, (total, field) => {
                return total + this.getEnumScore(field, field)
            }, 0)

            const questionFields = [
                'age',
                'month',
                'close_open_eyes',
                'make_a_fist',
            ]
            const questionScores = reduce(questionFields, (total, field) => total + this.getBoolScore(field), 0)

            return (enumScores
                + questionScores
                + this.visualFieldsScore
                + this.visualInattentionScore
                + this.gazeScore
                + this.facialStrScore
                + this.ataxiaScore
                + this.sensationScore)
        },
    },
    watch: {
        score: {
            immediate: true,
            handler: function(val: number) {
                this.setFieldVal(this.stay_id, `${this.section}.score`, val)
            }
        }
    },
    methods: {
        getEnumScoreByVal(field: string, enumVal: string): number {
            if (isNil(enumVal))
                return 0
            const mapping = enumScoreMap[field]
            if (!mapping)
                this.$toasted.error(`enum ${field} not defined in score map`)
            const score = mapping ? mapping[enumVal] : undefined
            if (score === undefined)
                this.$toasted.error(`enumVal ${enumVal} not defined in enum ${field}'s score map`)
            return mapping ? (mapping[enumVal] || 0) : 0
        },
        getEnumScore(mapField: string, field: string): number {
            return this.getEnumScoreByVal(mapField, this.getFieldVal(this.stay_id, `${this.section}.${field}`))
        },
        getBoolScore(field: string): number {
            const val: boolean | undefined = this.getFieldVal(this.stay_id, `${this.section}.${field}`) || false
            return val ? 1 : 0
        },
    }
})

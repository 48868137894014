








import mixins from 'vue-typed-mixins'
import InputMixin from '@mixins/Input.vue'

import TextArea from '@shared/inputs/TextArea.vue'


export default mixins(InputMixin).extend({
    components: {
        TextArea,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        dischargeNotes: {
            get(): string {
                return this.getFieldVal(this.stay_id, 'discharge.notes')
            },
            set(notes: string) {
                this.setFieldVal(this.stay_id, 'discharge.notes', notes)
            }
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isTempUser
        ? _c("div", { staticClass: "row mb-2" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "b-btn",
                  {
                    class: {
                      "btn-info": _vm.shouldHide,
                      "btn-outline-info": !_vm.shouldHide
                    },
                    attrs: { variant: "dummy", size: "sm" },
                    on: { click: _vm.toggleHideStays }
                  },
                  [
                    _vm.shouldHide
                      ? _c("span", [_vm._v("Show")])
                      : _c("span", [_vm._v("Hide")])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-btn",
                  {
                    class: {
                      "btn-info": _vm.showSpeciality,
                      "btn-outline-info": !_vm.showSpeciality
                    },
                    attrs: { variant: "dummy", size: "sm" },
                    on: {
                      click: function($event) {
                        _vm.showSpeciality = !_vm.showSpeciality
                      }
                    }
                  },
                  [_vm._v("\n                Specialities\n            ")]
                ),
                _vm._v(" "),
                _vm.canToggleScrawlIds
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "ml-1",
                        class: {
                          "btn-info": _vm.showStayIds,
                          "btn-outline-info": !_vm.showStayIds
                        },
                        attrs: { variant: "dummy", size: "sm" },
                        on: { click: _vm.toggleHomeStayIds }
                      },
                      [_vm._v("\n                Scrawl IDs\n            ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isDeveloper
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm btn-outline-info progress-bar-striped ml-1",
                        on: { click: _vm.toggleShowExtIds }
                      },
                      [_vm._v("\n                Visit IDs\n            ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-none d-md-flex font-weight-bold" }, [
        _c("div", { staticClass: "col-2" }, [
          _c("span", [_vm._v("Hide?")]),
          _vm._v(" "),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v(_vm._s(_vm.locationHeading))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2" }, [_vm._v("Patient")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1" }, [_vm._v(_vm._s(_vm.timeHeading))]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1" }, [_vm._v("Doctor")]),
        _vm._v(" "),
        _vm.triageMode
          ? _c(
              "div",
              {
                class: {
                  "col-2": _vm.someActionsVisible,
                  "col-3": !_vm.someActionsVisible
                }
              },
              [_vm._v("Nurse Review")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            class: {
              "col-2": _vm.triageMode && _vm.someActionsVisible,
              "col-3": _vm.triageMode && !_vm.someActionsVisible,
              "col-4": !_vm.triageMode
            }
          },
          [
            _vm.triageMode
              ? _c("span", [_vm._v("Admission / Review")])
              : _vm.telestrokeMode && !_vm.betaEnabled
              ? _c("span", [_vm._v("Admission")])
              : _c("span", [_vm._v("Diagnosis")])
          ]
        ),
        _vm._v(" "),
        _vm.someActionsVisible
          ? _c("div", { staticClass: "col-2" }, [_vm._v("Actions")])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "b-list-group",
        { attrs: { role: "tablist" } },
        [
          _c("b-overlay", {
            attrs: {
              show: _vm.staysLoading,
              variant: "transparent",
              "no-wrap": ""
            }
          }),
          _vm._v(" "),
          _vm._l(_vm.visibleStays, function(stay) {
            return _c("stay-details", {
              key: stay.id,
              attrs: {
                displayContext: _vm.displayContext,
                stay: stay,
                showExtId: _vm.showExtIds,
                tsScope: _vm.tsScope,
                showSite: _vm.showSite,
                showLocation: _vm.showLocation
              },
              on: { "actions-visible": _vm.updateActionsVisible }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }























import Vue from 'vue'
import { filter, includes, isEqual, map, xor } from 'lodash-es'
import { Doctor } from 'models/data/doctor'

export default Vue.extend({
    props: {
        value: {
            type: Array as () => number[],
            default: () => [],
            required: true,
        }
    },
    data() {
        return {
            // used to store the list of selected doctor IDs while the dropdown is open
            localSelected: [] as number[],
            dropdownVisible: false,
            selecting: false,
        }
    },
    computed: {
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        doctors(): readonly Doctor[] {
            const doctors = this.$store.direct.getters.templates.visibleDoctors
            // Filter by the user's chosen specialities
            const specialityIDs = this.$store.direct.state.user.filters.specialities
            if (specialityIDs.length)
                return filter(doctors, (doctor) => includes(specialityIDs, doctor.speciality))
            return doctors
        },
        selected: {
            get(): number[] {
                return this.value
            },
            set(vals: number[]) {
                this.$emit('input', vals)
            }
        },
        selectedText(): string {
            const selectedIDs = this.dropdownVisible ? this.localSelected : this.selected
            const selected = filter(this.doctors, d => includes(selectedIDs, d.id))
            const lastNames = map(selected, 'last_name')
            return lastNames.join(", ") || "[All]"
        },
    },
    methods: {
        inSelected(doctorID: number) {
            return includes(this.localSelected, doctorID)
        },
        toggleSelect(doctorID: number) {
            this.selecting = true
            this.localSelected = xor(this.localSelected, [doctorID])
        },
        hideHandler(event: any) {
            if (this.selecting) {
                // Don't close the dropdown if we're currently selecting items
                event.preventDefault()
            } else {
                // Update `selected` when closing the menu
                if (!isEqual(this.selected, this.localSelected))
                    this.selected = this.localSelected
                this.dropdownVisible = false
            }
            this.selecting = false
        },
        showHandler(event: any) {
            this.localSelected = this.selected
            this.dropdownVisible = true
        },
    },
})















import mixins from 'vue-typed-mixins'
import InputMixin from '../mixins/Input.vue'
import DateTimePicker from '@shared/inputs/DateTimePicker.vue'


export default mixins(InputMixin).extend({
    components: {
        DateTimePicker,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
    },
    computed: {
        bolusTime: {
            get(): string {
                return this.getFieldVal(this.stay_id, 'thrombolysis.bolus_time')
            },
            set(time: string) {
                this.setFieldVal(this.stay_id, 'thrombolysis.bolus_time', time)
            }
        },
    },
})

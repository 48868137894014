var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canAdmin
    ? _c(
        "b-nav-item-dd",
        { attrs: { text: "Admin" } },
        [
          _vm.isOnlyClientUserAdmin
            ? _c("b-dd-item", { attrs: { to: { name: "client-users" } } }, [
                _vm._v("Users")
              ])
            : _c("b-dd-item", { attrs: { to: { name: "admin" } } }, [
                _vm._v("Manage")
              ]),
          _vm._v(" "),
          _vm.isPowerUser || _vm.isClientUserAdmin
            ? _c(
                "b-dd-item",
                { attrs: { to: { name: "metrics-users-usage-activity" } } },
                [_vm._v("Usage Activity")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isPowerUser
            ? [
                _c("b-dd-divider"),
                _vm._v(" "),
                _c("b-dd-item", { attrs: { href: _vm.backendLink } }, [
                  _vm._v("Backend")
                ]),
                _vm._v(" "),
                _c("b-dd-item", { attrs: { href: _vm.spacesAdminLink } }, [
                  _vm._v("Spaces")
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isDeveloper
            ? [
                _c("b-dd-divider"),
                _vm._v(" "),
                _c("b-dd-item", { attrs: { to: { name: "fnt-a-icons" } } }, [
                  _vm._v("Icons")
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "d-inline-block" },
        [
          _c(
            "b-btn",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.htmlId,
                  expression: "htmlId"
                }
              ],
              attrs: { variant: "info", size: "sm" }
            },
            [_vm._v("Edit Header")]
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                title: "Edit Header",
                size: "lg",
                id: _vm.htmlId,
                "ok-only": ""
              }
            },
            [
              _c("h5", [_vm._v("Instructions")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Any text here will be added to the top of this speciality's print list."
                )
              ]),
              _vm._v(" "),
              _c("h5", [_vm._v("Header")]),
              _vm._v(" "),
              _c("text-area", {
                staticClass: "text-monospace light-ph",
                attrs: { id: _vm.inputHtmlId, rows: 3 },
                model: {
                  value: _vm.localMarkdown,
                  callback: function($$v) {
                    _vm.localMarkdown = $$v
                  },
                  expression: "localMarkdown"
                }
              }),
              _vm._v(" "),
              _c(
                "b-tooltip",
                { attrs: { target: _vm.inputHtmlId, placement: "right" } },
                [
                  _c("p", { staticClass: "text-info text-left" }, [
                    _vm._v(
                      "\n                The header can be formatted as follows:"
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("ul", { staticStyle: { "padding-left": "1rem" } }, [
                      _c("li", [
                        _c("em", [_vm._v("italics")]),
                        _vm._v(" - wrap with asterisks - eg "),
                        _c("span", { staticClass: "text-monospace" }, [
                          _vm._v("*Dept*")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("strong", [_vm._v("bold")]),
                        _vm._v(" - wrap with double asterisks - eg "),
                        _c("span", { staticClass: "text-monospace" }, [
                          _vm._v("**Dept**")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "line break - add a backslash at the end of the line - eg "
                        ),
                        _c("span", { staticClass: "text-monospace" }, [
                          _vm._v("Dept\\")
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-info text-left" }, [
                    _vm._v("\n                Full example:"),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-monospace" }, [
                      _vm._v("Contacts list\\"),
                      _c("br"),
                      _vm._v("*Reception* x11111\\"),
                      _c("br"),
                      _vm._v("**Senior on Call** x22222")
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v("\n                Preview:"),
                    _c("br"),
                    _vm._v("\n                Contacts list"),
                    _c("br"),
                    _c("em", [_vm._v("Reception")]),
                    _vm._v(" x11111"),
                    _c("br"),
                    _c("strong", [_vm._v("Senior on Call")]),
                    _vm._v(" x22222\n            ")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("b-btn", { attrs: { variant: "info", size: "sm" } }, [
                _vm._v("Preview")
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "border rounded pt-3 px-3 my-3",
                domProps: { innerHTML: _vm._s(_vm.markedUp) }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
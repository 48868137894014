






























import Vue from 'vue'
import { BvTableFieldArray } from 'bootstrap-vue'
import DateTimePicker from "@shared/inputs/DateTimePicker.vue"

export default Vue.extend({
    components: {
        DateTimePicker,
    },
    data() {
        const fields: BvTableFieldArray = [
            {
                key: 'date',
                sortable: true,
            },
            'action',
            'record',
            'actions',
        ]
        return {
            fields,
            items: [
                {
                    date: '2021-Aug-20 10:42:12 PM (Fri)',
                    action: 'Login',
                    record: '',
                    actions: [
                    ]
                },
                {
                    date: '2021-Aug-20 10:45:33 PM (Fri)',
                    action: 'Update',
                    record: 'Stay 999 - SMITH, John (MRN: 999123456)',
                    actions: [
                        'Syndrome set to "Stroke"',
                    ]
                },
                {
                    date: '2021-Aug-20 11:28:12 PM (Fri)',
                    action: 'Logout',
                    record: '',
                    actions: [
                    ]
                },
                {
                    date: '2021-Aug-24 1:42:12 PM (Tue)',
                    action: 'Login',
                    record: '',
                    actions: [
                    ]
                },
                {
                    date: '2021-Aug-24 2:07:57 PM (Tue)',
                    action: 'Update',
                    record: 'Stay 999 - SMITH, John (MRN: 999123456)',
                    actions: [
                        '"Physio" set to "Required (need referral)"',
                    ]
                },
                {
                    date: '2021-Aug-24 2:37:44 PM (Tue)',
                    action: 'Create',
                    record: 'Patient 99 - DOE, John (MRN: 999123450)',
                    actions: [
                    ]
                },
                {
                    date: '2021-Aug-24 4:07:57 PM (Tue)',
                    action: 'Logout',
                    record: '',
                    actions: [
                    ]
                },
            ]

        }
    },
    methods: {
        actionsFormatter(value: any, key: string, item: any) {

        },
    },
})

import { isNil } from 'lodash-es'
import { freeTextSubHtmlBreaks } from './helpers'
import { genericQuestionsText } from './question'
import { TextDetails } from './interfaces'
import { QuestionCategory } from "models/med_templates/question"
import stays from '@store/stays'

export function examination({ stay }: TextDetails): string {
    if (!stay || !stay.admissionexam) return ""

    const exam = stay.admissionexam
    const lines = []

    // Vitals
    let vitals = []
    const systolic = exam.blood_pressure_systolic
    const diastolic = exam.blood_pressure_diastolic
    if (systolic && diastolic) {
        vitals.push(`BP ${systolic}/${diastolic}`)
    }

    if (!isNil(exam.heart_rate)) {
        vitals.push(`HR ${exam.heart_rate}`)
    }

    if (!isNil(exam.respiratory_rate)) {
        vitals.push(`RR ${parseFloat(exam.respiratory_rate)}`)
    }

    if (!isNil(exam.oxygen_saturations)) {
        vitals.push(`Sats ${parseFloat(exam.oxygen_saturations)}`)
    }

    if (!isNil(exam.temperature)) {
        vitals.push(`Temp ${parseFloat(exam.temperature)}&#176;C`)
    }

    if (!isNil(exam.blood_sugar_level)) {
        vitals.push(`BSL ${parseFloat(exam.blood_sugar_level)}`)
    }

    if (vitals.length) {
        lines.push("Vitals: " + vitals.join(", "))
    }

    // General notes
    if (exam.general) {
        lines.push(`General: ${freeTextSubHtmlBreaks(exam.general)}`)
    }

    // Cardio notes
    if (exam.cardiovascular) {
        lines.push(`Cardio: ${freeTextSubHtmlBreaks(exam.cardiovascular)}`)
    }

    // Resp notes
    if (exam.respiratory) {
        lines.push(`Resp: ${freeTextSubHtmlBreaks(exam.respiratory)}`)
    }

    // Abdominal notes
    if (exam.abdomen) {
        lines.push(`Abdo: ${freeTextSubHtmlBreaks(exam.abdomen)}`)
    }

    const generalExamText = genericQuestionsText({
        stay,
        category: QuestionCategory.GENERAL_EXAM,
        getQuestionGroupsFrom: stays.getSyndrome(stay.id),
    })
    if (generalExamText) {
        if (lines.length) lines.push("")  // Line break after standard exam questions
        lines.push(generalExamText)
    }

    if (lines.length) {
        return "**On examination**: \\\n" + lines.join("<br/>") + "\n\n"
    }
    return ""
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-center justify-content-center h-100" },
    [
      _vm.staysLoading && !_vm.stays.length
        ? _c("h2", [_vm._m(0)])
        : !_vm.stays.length
        ? _c("h2", [_vm._v("\n        No stays\n    ")])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("em", { staticClass: "loading-dots" }, [
      _vm._v("\n            Loading"),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
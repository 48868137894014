var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay && _vm.stay.location
    ? _c("div", { staticClass: "row" }, [
        _c("p", { staticClass: "mb-0 col-12" }, [
          _vm._v(_vm._s(_vm.locationDesc(_vm.stay)))
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
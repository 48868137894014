



















































































import Vue from "vue"
import * as moment from 'moment'
import utils from 'utils'
import { ASMT_ADM, ASMT_MDT, ASMT_NURSE, ASMT_WARD } from 'routers/constants'

interface StaySearchResult {
    active: boolean
    doctor_name: string
    id: number
    mrn: string
    name: string
    stage: string
}

export default Vue.extend({
    props: {
        useAltText: {
            type: Boolean,
            default: false
        },
        showClearBtn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mrn: '',
            searching: false,
            selectedStay: -1,
            showResults: false,
            stays: [] as StaySearchResult[],
        }
    },
    computed: {
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        placeholderText(): string {
            return this.useAltText ? 'Open stay via MRN' : 'Search for stays by MRN'
        },
    },
    watch: {
        selectedStay(newIndex) {
            if (newIndex === -1) {
                // Return focus to the input if we're at the top of the list (selectedStay === -1)
                (this.$refs['search-input'] as HTMLInputElement).focus()
            } else {
                // Focus on the search result link with index === newIndex
                const className = this.searchResultClass(newIndex)
                const result: HTMLElement | null = this.$el.querySelector(`.${className}`)
                if (result) result.focus()
            }
        },
    },
    methods: {
        formatDate(dateString: string) {
            if (!dateString)
                return '-'
            const date = moment(dateString)
            return date.format('DD-MMM-YYYY')
        },
        search() {
            if (!this.mrn.length) return
            this.searching = true
            return utils
                .request
                .get(`/stay/search/${this.mrn}/`)
                .then(response => {
                    this.stays = response.body.stays
                    this.showResults = true
                    this.searching = false
                    this.selectedStay = -1
                })
        },
        searchResultClass(index: string): string {
            // Generate a class name for each search result that
            // allows us to focus on the next/previous one based on keypresses
            return `stay-search-result-${index}`
        },
        selectNextStay() {
            if (!this.stays.length) return
            this.selectedStay = Math.min(this.selectedStay + 1, this.stays.length - 1)
        },
        selectPreviousStay() {
            if (!this.stays.length) return
            this.selectedStay = Math.max(this.selectedStay - 1, -1)
        },
        stayLink(stay: StaySearchResult) {
            // Generate a link to follow for each search result based on the stay's status
            // (active/discharged, admitted or not)
            let view = ASMT_ADM
            if (!this.$store.direct.state.user.telestroke_mode) {
                if (!stay.active || stay.stage === 'WARD')
                    view = ASMT_WARD
            }
            return { name: view, params: { stay_id: stay.id } }
        },
        clearMRN(): void {
            this.mrn = ''
        },
        delayedClearMRN(): void {
            window.setTimeout(() => {
                this.clearMRN()
            }, 500)
        },
    },
})

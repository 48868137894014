






































































import Vue from 'vue'
import { difference, filter, find, flatMap, forEach, get, includes, map, split } from 'lodash-es'
import utils from 'utils'

import stays from '@store/stays'
import { Medication, MedicationCategory } from 'models/med_templates/medication'

import Checkbox from '@shared/inputs/Checkbox.vue'
import TextArea from "@shared/inputs/TextArea.vue"


export default Vue.extend({
    components: {
        Checkbox,
        TextArea,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        showEditButton: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            htmlIDs: {
                currentMeds: `${uid}__current-meds-update`
            },
            selectedMeds: [] as number[],
            modalOtherMeds: '',
            modalAllergies: ''
        }
    },
    methods: {
        filterMeds(meds: number[]) {
            // Filter all meds in the same category so that only a single 'Taking' value is allowed
            // e.g. if latest ticked med has taking=Contraindicated,
            //      remove all meds where taking is Yes or No
            if (!meds.length) return meds

            const allMeds: Medication[] = flatMap(this.categories, 'medications')
            const latestMedID = meds[meds.length - 1]
            const latestMed = find(allMeds, ['id', latestMedID])

            if (!latestMed) return meds

            const medsToBeRemoveds = filter(allMeds, function(med) {
                return (
                    med.category === latestMed.category &&
                    med.taking != latestMed.taking
                )
            })
            const medsToBeRemovedIDs = map(medsToBeRemoveds, 'id')
            return difference(meds, medsToBeRemovedIDs)
        },
        setSelectedMeds() {
            // Set current values in the modal
            this.selectedMeds = this.stayMeds
            this.modalOtherMeds = this.otherCurrentMeds
            this.modalAllergies = this.allergies
        },
        updateMeds() {
            // Persist all changes made in the modal
            this.stayMeds = this.selectedMeds
            this.otherCurrentMeds = this.modalOtherMeds 
            this.allergies = this.modalAllergies 
        },
    },
    computed: {
        stay() {
            return get(stays.state.stays, this.stay_id)
        },
        staySpeciality() {
            return stays.getSpeciality(this.stay_id)
        },
        staySyndrome() {
            return stays.getSyndrome(this.stay_id)
        },
        stayMeds: {
            get(): number[] {
                return get(this.stay, 'current_meds') || []
            },
            set(meds: number[]) {
                stays.extActSetPathValue(this.stay_id, 'current_meds', this.selectedMeds)
            }
        },
        otherCurrentMeds: {
            get(): string {
                return get(this.stay, 'other_current_meds') || ''
            },
            set(text: string) {
                stays.extActSetPathValue(this.stay_id, 'other_current_meds', text)
            }
        },
        otherCurrentMedsLines(): string[] {
            if (!this.otherCurrentMeds.length) return []
            return split(this.otherCurrentMeds, '\n')
        },
        allergies: {
            get(): string {
                return get(this.stay, 'historical_meds.allergies') || ''
            },
            set(text: string) {
                stays.extActSetPathValue(this.stay_id, 'historical_meds.allergies', text)
            }
        },
        allergiesLines(): string[] {
            if (!this.allergies.length) return []
            return split(this.allergies, '\n')
        },
        modalMedsList: {
            get(): number[] {
                return this.selectedMeds
            },
            set(meds: number[]) {
                this.selectedMeds = this.filterMeds(meds)
            }
        },
        categories(): MedicationCategory[] {
            const allCategories = this.staySpeciality ? this.staySpeciality.medication_categories : []
            const categoryIds = this.staySyndrome ? this.staySyndrome.medication_categories : []
            const categories: MedicationCategory[] = []
            forEach(categoryIds, id => {
                const category = find(allCategories, { id })
                if (category)
                    categories.push(category)
            })
            return categories
        },
        currentMeds(): {title: string, text: string}[] {
            // List of medication categories and medications ticked for current stay
            function getText(med: {title: string, taking: string}) {
                if (med.taking === 'CONTRAINDICATED') {
                    return `Contraindicated (${med.title})`
                }
                return med.title
            }

            let list: {title: string, text: string}[] = []
            const stayMeds = this.stayMeds
            forEach(this.categories, function(category) {
                const ticked = filter(category.medications, function(med) {
                    return includes(stayMeds, med.id)
                })
                if (ticked.length) {
                    list.push({
                        title: category.title,
                        text: map(ticked, getText).join(', ')
                    })
                }
            })
            return list
        }
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isTempUser
    ? _c(
        "div",
        [
          _c("checkbox", {
            staticClass: "mr-1",
            attrs: { title: "Show Discharged", cb_value: true },
            model: {
              value: _vm.cp_showDischarged,
              callback: function($$v) {
                _vm.cp_showDischarged = $$v
              },
              expression: "cp_showDischarged"
            }
          }),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.cp_showDischarged,
                  expression: "cp_showDischarged"
                }
              ],
              staticClass: "mb-0 mr-2",
              attrs: { "label-class": "pb-0", label: "Period" }
            },
            [
              _vm._l(_vm.periods, function(p) {
                return _c("checkbox", {
                  key: p,
                  staticClass: "mb-0",
                  attrs: { title: p, cb_value: p },
                  model: {
                    value: _vm.cp_period,
                    callback: function($$v) {
                      _vm.cp_period = $$v
                    },
                    expression: "cp_period"
                  }
                })
              }),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: _vm.htmlIDs.cpForm,
                      expression: "htmlIDs.cpForm"
                    }
                  ],
                  attrs: { variant: "outline-info", size: "sm" }
                },
                [_vm._v("Custom")]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                { staticClass: "mt-1", attrs: { id: _vm.htmlIDs.cpForm } },
                [
                  _c(
                    "b-form-invalid-feedback",
                    {
                      attrs: {
                        id: _vm.htmlIDs.cpFeedback,
                        state: _vm.customPeriodState
                      }
                    },
                    [
                      _vm._v(
                        "\n                Enter a valid number less than 125.\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          lazy: "",
                          number: "",
                          type: "number",
                          "aria-labelledby": _vm.htmlIDs.cpLabel,
                          "aria-describedby": _vm.htmlIDs.cpFeedback,
                          state: _vm.customPeriodState
                        },
                        model: {
                          value: _vm.custom_period,
                          callback: function($$v) {
                            _vm.custom_period = $$v
                          },
                          expression: "custom_period"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "b-input-group-append",
                        [
                          _c("b-btn", { attrs: { id: _vm.htmlIDs.cpLabel } }, [
                            _vm._v("weeks")
                          ]),
                          _vm._v(" "),
                          _c("b-btn", { attrs: { variant: "info" } }, [
                            _vm._v("Go")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
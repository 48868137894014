var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrawl-stacked" },
    [
      _c(
        "card-lapsible",
        { attrs: { title: "Attending", visible: "" } },
        [
          _c("team", {
            staticClass: "p-0",
            attrs: { stay_id: _vm.stay_id, section: "ward_round" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "card-lapsible",
        { attrs: { title: "Primary Diagnosis & Issues", visible: "" } },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _c("h6", { staticClass: "subsection-title" }, [
                  _vm._v("Primary Issue")
                ]),
                _vm._v(" "),
                _c("primary-issue", { attrs: { stay_id: _vm.stay_id } }),
                _vm._v(" "),
                _c("h6", { staticClass: "subsection-title mt-3" }, [
                  _vm._v("Issues")
                ]),
                _vm._v(" "),
                _c("card-lapsible", {
                  on: {
                    visible: function($event) {
                      _vm.issuesOpened = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _vm.issuesOpened
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "d-inline-block mb-0 text-monospace"
                                },
                                [_vm._v("[close]")]
                              )
                            : _c(
                                "div",
                                { staticClass: "d-inline-block" },
                                _vm._l(_vm.issuesList, function(issue, index) {
                                  return _c(
                                    "p",
                                    { key: index, staticClass: "mb-0" },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(index + 1) +
                                          ". " +
                                          _vm._s(issue) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("issues-overview", {
                            attrs: { stay_id: _vm.stay_id }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm.hasStrokeDiagnosis
                ? _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c("h6", { staticClass: "subsection-title" }, [
                        _vm._v("Stroke Summary")
                      ]),
                      _vm._v(" "),
                      _c("neuro-summary", { attrs: { stay_id: _vm.stay_id } })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "card-lapsible",
        { attrs: { title: "Background" } },
        [
          _c("card-lapsible", {
            on: {
              visible: function($event) {
                _vm.bgOpened = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _vm.bgOpened || !_vm.backgroundHTML
                      ? _c("h5", { staticClass: "mb-0" }, [
                          _vm._v("Past Medical/Surgical History")
                        ])
                      : _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.backgroundHTML) }
                        })
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [_c("background", { attrs: { stay_id: _vm.stay_id } })]
                },
                proxy: true
              }
            ])
          }),
          _vm._v(" "),
          _c("card-lapsible", {
            on: {
              visible: function($event) {
                _vm.medsOpened = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _vm.medsOpened || !_vm.medsHTML
                      ? _c("h5", { staticClass: "mb-0" }, [
                          _vm._v("Medications")
                        ])
                      : _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.medsHTML) }
                        })
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [
                    _c("historical-medications", {
                      attrs: { stay_id: _vm.stay_id }
                    })
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm._v(" "),
          _c("card-lapsible", {
            on: {
              visible: function($event) {
                _vm.socialOpened = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _vm.socialOpened || !_vm.socialHTML
                      ? _c("h5", { staticClass: "mb-0" }, [
                          _vm._v("Social History")
                        ])
                      : _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.socialHTML) }
                        })
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [
                    _c("social-history", { attrs: { stay_id: _vm.stay_id } })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("card-lapsible", { attrs: { title: "Progress", visible: "" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _c("text-area-enhanced", {
                attrs: {
                  stay_id: _vm.stay_id,
                  path: "ward_round.progress",
                  label: "Progress"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "card-lapsible",
        { attrs: { title: "Exam", visible: "" } },
        [_c("ward-exam", { attrs: { stay_id: _vm.stay_id } })],
        1
      ),
      _vm._v(" "),
      _c(
        "card-lapsible",
        { attrs: { title: "Patient Discussion & Education" } },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-8" },
              [
                _c("patient-doctor-discussion", {
                  attrs: { stay_id: _vm.stay_id }
                })
              ],
              1
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("card-lapsible", { attrs: { title: "Plan", visible: "" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-8" },
            [
              _vm.isNurseUser
                ? _c("text-area-enhanced", {
                    attrs: {
                      stay_id: _vm.stay_id,
                      path: "ward_round.nurse_plan",
                      label: "Plan - Nurse"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("text-area-enhanced", {
                attrs: {
                  stay_id: _vm.stay_id,
                  path: "ward_round.plan_notes",
                  label: _vm.planLabel,
                  collapsible: _vm.isNurseUser,
                  visible: _vm.isNurseUser ? false : true
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-4" },
            [
              _vm.isNurseUser
                ? _c("rapid-asmt-diagnosis", {
                    attrs: { stay_id: _vm.stay_id, stacked: "" }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col d-flex flex-row align-items-end" },
        [
          _c("filters", {
            attrs: { useHospitals: true },
            on: { update: _vm.pull }
          }),
          _vm._v(" "),
          _c(
            "show-discharged",
            _vm._b(
              {
                staticClass: "d-flex flex-row align-items-end ml-3",
                on: { update: _vm.pull }
              },
              "show-discharged",
              _vm.discharge,
              false,
              true
            )
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-auto" }, [_c("create-stay-nurse")], 1)
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("stays", {
            attrs: {
              displayContext: "nurse",
              showSite: true,
              showLocation: true
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }




























import Vue from 'vue'
import utils from 'utils'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import NeuroAcuteCareSummary from './NeuroAcuteCareSummary.vue'

export default Vue.extend({
    components: {
        NeuroAcuteCareSummary,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        iconMode: {
            type: Boolean,
            default: true,
        },
        add_mb: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            htmlId: `${utils.getUID()}___nel_acute_care`
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        patientName(): string {
            return this.stay?.acute_care_summary?.name || '?'
        },
    },
})

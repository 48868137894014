















import mixins from 'vue-typed-mixins'
import InputMixin from "@mixins/Input.vue"
import { each, filter, find, includes } from 'lodash-es'
import utils from 'utils'
import text from "utils/text"
import { rapidAsmtDataCapture } from 'utils/text/dataCapture'
import { diagnosisWithSummaries, oneLineRapidAsmtDiagnosisSummary } from 'utils/text/diagnosis'
import stays from "@store/stays"
import { NIHSS } from 'models/data/nihss'
import { Stay } from 'models/data/stay'
import { QuestionGroup } from 'models/med_templates/question'
import { Syndrome } from 'models/med_templates/syndrome'
import Editor from "@shared/inputs/TextEditor.vue"


export default mixins(InputMixin).extend({
    components: {
        Editor
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            confirmId: `${uid}___export_confirm`,
            notes: ''
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        syndrome(): Syndrome | undefined {
            if (!this.stay) return
            return find(this.$store.direct.getters.templates.rapidAsmtSyndromes, ['id', this.stay.rapid_assessment_syndrome])
        },
        questionGroups(): QuestionGroup[] {
            return this.syndrome ? this.syndrome.question_groups : []
        },
        groupTitlesToClear(): string[] {
            return ["today's review"]
        },
        groupsToClear(): QuestionGroup[] {
            return filter(this.questionGroups, group => includes(this.groupTitlesToClear, group.title?.toLowerCase()))
        },
        presentationSummary(): string {
            if (this.stay && this.stay.rapid_assessment_summary)
                return this.stay.rapid_assessment_summary + "\n\n"
            return ""
        },
        nihssV1(): boolean {
            return this.$store.direct.state.session.nihss_version === 'v1'
        },
        textNIHSS(): number {
            return this.$store.direct.state.session.textNIHSS
        },
        textNIHSSObj(): NIHSS | undefined {
            return find(this.stay?.nihss_set, { id: this.textNIHSS })
        },
        markup(): string {
            if (!this.stay)
                return ""

            let markup = '' // "**Progress Note**\n\n"

            const textInfo = {
                stay: this.stay,
                anonymise: this.$store.direct.state.user.anonymise,
            }

            markup += `Patient details: ${text.patientDetails(textInfo)}  \n`
            markup += text.careClassification(textInfo)
            markup += `Length of stay: ${this.stay.length_of_stay} days\n\n`

            const diagnosisText = diagnosisWithSummaries(textInfo, false, true)
            if (diagnosisText)
                markup += '**Working Diagnosis**\n\n' + diagnosisText + '\n\n'

            markup += this.presentationSummary
            markup += rapidAsmtDataCapture(textInfo) + '\n\n'

            if (this.nihssV1)
                markup += text.nihss(
                    textInfo,
                    { nihssKey: 'rapid_asmt_nihss', heading: "Today's review" }
                )
            else
                markup += text.nihss(
                    textInfo,
                    { nihssID: this.textNIHSS, advancedDesc: true, heading: "Today's review" }
                )

            const diagnosisTitle = this.stay.rapid_assessment_diagnosis_title
            markup += `**Current clinical phase - ${diagnosisTitle || '?'}**  \n`

            markup += oneLineRapidAsmtDiagnosisSummary(textInfo) + "  \n"
            markup += text.RAMgmtPlan(textInfo)
            markup += text.fieldText(textInfo, 'rapid_assessment_plan_notes')

            return markup
        },
    },
    methods: {
        confirmComplete(text: string) {
            this.notes = text
            this.showConfirmModal()
        },
        showConfirmModal() {
            this.$bvModal.show(this.confirmId)
        },
        markComplete() {
            // stays.extActSetPathValue(this.stay_id, "rapid_assessment_emr_notes", this.notes)
            this.clearAnswers()
            if (this.nihssV1)
                stays.extActSetPathValue(this.stay_id, 'rapid_asmt_nihss.editable', false)
            else {
                if (this.textNIHSSObj)
                    this.setArrayedObjVal(this.stay_id, 'nihss_set', this.textNIHSS, 'editable', false)
            }
        },
        clearAnswers() {
            each(this.groupsToClear, group => {
                each(group.questions, question => {
                    const existingAnswer = stays.getAnswer(this.stay_id, question.question.id)
                    if (existingAnswer !== null && existingAnswer !== undefined)
                        stays.setAnswer(this.stay_id, question.question.id, null)
                })
            })
        }
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isTempUser
    ? _c(
        "b-form-group",
        {
          staticClass: "mb-0",
          attrs: {
            "label-class": "p-0",
            label: _vm.hospitals.length > 1 ? "Hospitals" : "Hospital"
          }
        },
        [
          _c(
            "b-dd",
            {
              attrs: {
                variant: "primary",
                text: _vm.selectedTextShort,
                title: _vm.selectedText
              },
              on: { show: _vm.showHandler, hide: _vm.hideHandler }
            },
            [
              _c("b-dd-item-btn", { on: { click: _vm.clearSelections } }, [
                _vm._v("[All]")
              ]),
              _vm._v(" "),
              _vm._l(_vm.hospitals, function(hospital) {
                return _c(
                  "b-dd-item-btn",
                  {
                    key: hospital.id,
                    class: { active: _vm.isSelected(hospital.id) },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.toggleSelect(hospital.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(hospital.title) +
                        "\n            "
                    ),
                    _vm.isSelected(hospital.id)
                      ? _c("fnt-a-icon", { attrs: { icon: ["fal", "check"] } })
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
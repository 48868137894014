




















import Vue from "vue"
import utils from 'utils'
import * as clipboard from 'clipboard'


export default Vue.extend({
    data() {
        const uid = utils.getUID()
        return {
            copyBtnHtml: `${uid}___cpy_btn`,
            syncErrorsHtml: `${uid}___sync_errors`,
            clipboardObj: null as null | clipboard,
            clipboardSupported: false,
        }
    },
    computed: {
        visible(): boolean {
            return this.$store.direct.getters.session.hasSyncErrors && this.$store.direct.state.session.syncErrorsVisible
        },
        errorsHtml(): string {
            return this.$store.direct.getters.session.errorHTML
        },
    },
    mounted() {
        this.clipboardSupported = clipboard.isSupported()
        if (this.clipboardSupported) {
            const self = this
            this.clipboardObj = new clipboard('#'+this.copyBtnHtml, {
                target(triggerElem) {
                    return window.document.querySelector(`#${self.syncErrorsHtml} pre code`) || triggerElem
                }
            })
        }
    },
    beforeDestroy() {
        if (this.clipboardObj)
            this.clipboardObj.destroy()
    },
    methods: {
        hide(): void {
            this.$store.direct.commit.session.setSyncErrorsVisible(false)
        },
        clearErrors(): void {
            this.$store.direct.commit.session.clearSyncErrorVars()
        },
    }
})

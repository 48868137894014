
































import mixins from 'vue-typed-mixins'
import InputMixin from '@mixins/Input.vue'
import * as moment from 'moment'
import { ChoiceItem } from 'models/med_templates/choices'

import DateTimePicker from '@shared/inputs/DateTimePicker.vue'
import Dropdown from '@shared/inputs/Dropdown.vue'


export default mixins(InputMixin).extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        careClassification: {
            get(): string {
                return this.getFieldVal(this.stay_id, 'care_classification.classification')
            },
            set(classification: string) {
                const data = {
                    classification: classification,
                    date: moment().format('YYYY-MM-DD')
                }
                this.setFieldVal(this.stay_id, 'care_classification', data)
            }
        },
        careClassificationDate: {
            get(): string {
                return this.getFieldVal(this.stay_id, 'care_classification.date')
            },
            set(date: string) {
                const data = {
                    classification: this.careClassification,
                    date: date
                }
                this.setFieldVal(this.stay_id, 'care_classification', data)
            }
        },
        careClassificationChoices(): ChoiceItem[] {
            return this.$store.direct.state.templates.choices.care_classification
        },
    },
    components: {
        DateTimePicker,
        Dropdown,
    }
})

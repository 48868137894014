var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("questions", {
    staticClass: "mb-3",
    attrs: {
      stay_id: _vm.stay_id,
      category: _vm.QuestionCategory.DISCHARGE_PLAN,
      parentObject: _vm.speciality,
      editVisibleGroups: true,
      defaultGroupIDs: _vm.syndromeDischargePlanIDs,
      addText: "Discharge Plans"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.modalId,
        title: "Session expiring...",
        "ok-only": "",
        "ok-title": "Extend",
        "ok-variant": "info"
      },
      on: { ok: _vm.renewSession }
    },
    [
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "Session will expire in " + _vm._s(_vm.expiresIn) + " seconds. Click "
        ),
        _c("strong", [_vm._v("Extend")]),
        _vm._v(" below to extend session.")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
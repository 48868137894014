





















import Vue from 'vue'
import { clone } from 'lodash-es'
import utils from 'utils'
import CollapseIndicator from '@shared/CollapseIndicator.vue'

export default Vue.extend({
    components: {
        CollapseIndicator,
    },
    props: {
        title: {
            type: String,
            default: '?'
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uid: utils.getUID(),
            visible_: clone(this.visible),
        }
    },
    watch: {
        visible_(val: boolean) {
            this.$emit('visible', val)
        },
    },
})

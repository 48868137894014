






import Vue from 'vue'
import { startsWith } from 'lodash-es'
import { cshake256 } from 'js-sha3'
import utils from 'utils'
import { finalRequestURL } from 'utils/request'

interface ServiceInfo {
    backend: {
        version: string
        commit: string
        url: string
    },
    frontend: {
        version: string
        url: string
        error?: string
    },
    timings: {
        total: string
        hash: string
        git: string
        frontend: string
        regex: string
    }
}

export default Vue.extend({
    data() {
        return {
            infoPath: '/service-info/',
            watcher: -1,
            outOfDate: false,
            /** counter for number of *consecutive* errors */
            errors: 0,
            maxErrors: 5,
        }
    },
    computed: {
        infoUrl(): string {
            let url = finalRequestURL(this.infoPath, false)
            if (!startsWith(url, 'http'))
                url = window.location.origin + url
            return url
        },
        shortHash(): string {
            return cshake256(this.infoUrl, 80, '', '')
        }
    },
    beforeMount() {
        this.doCheck()
        this.watcher = window.setInterval(() => {
            this.doCheck()
        }, 45*60*1000)  // this number should be bigger than backend's logout limit, otherwise will keep account logged in
    },
    beforeDestroy() {
        if (this.watcher > -1)
            window.clearInterval(this.watcher)
    },
    methods: {
        doCheck(): void {
            this.outOfDate = false

            utils.request.get(this.infoPath, { hash: this.shortHash })
            .then(res => {
                const info: ServiceInfo = res.body
                const thisRelease = window.scrawl.sentryRelease.split('@')[1]
                if (info.frontend.version !== '?' && info.frontend.version > thisRelease) {
                    this.outOfDate = true
                    // this.$toasted.error('Please refresh page to load the latest version of Scrawl.', { position: 'top-center', duration: 10000 })
                }
                if (this.errors)
                    this.errors = 0
            })
            .catch(err => {
                this.errors++
                if (this.errors > this.maxErrors)
                    utils.handleRequestError(err)
            })
        }
    }
})

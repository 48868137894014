var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row mb-3" },
    [
      _c(
        "b-btn",
        {
          staticClass: "position-absolute scrwl-r-side-btn px-3",
          attrs: { size: "sm" },
          on: { click: _vm.toggleShowExtras }
        },
        [_vm._v("\n        Extras\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("editor", {
            attrs: {
              height: _vm.editorHeight,
              markup: _vm.markup,
              customHtml: _vm.RN_recentNote,
              stay_id: _vm.stay_id
            },
            on: { "text-copied": _vm.confirmComplete }
          }),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                id: _vm.confirmHtmlId,
                centered: "",
                "hide-header": "",
                "cancel-title": "No",
                "ok-title": _vm.modalOkTitle,
                "ok-variant": "success"
              },
              on: { ok: _vm.markComplete }
            },
            [_vm._t("modalContent")],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showExtras_local,
              expression: "showExtras_local"
            }
          ],
          staticClass: "col-2"
        },
        [
          _vm._t("extras"),
          _vm._v(" "),
          _vm.RN_recentNotes.length
            ? _c("dropdown", {
                staticClass: "mb-0",
                attrs: {
                  label: "Recent notes",
                  useHelpIcon: "",
                  helpText:
                    "These are only available during this session. They will be cleared once you close the tab and/or log out.",
                  items: _vm.RN_recentNotes,
                  textField: "created"
                },
                model: {
                  value: _vm.RN_selected,
                  callback: function($$v) {
                    _vm.RN_selected = $$v
                  },
                  expression: "RN_selected"
                }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
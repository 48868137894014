import * as superagent from "superagent"
import { startsWith } from 'lodash-es'
import VueCookie, { BrowserHandler } from 'vue-universal-cookies'


export const cookie = new VueCookie({ handler: new BrowserHandler() })

/** Directly gets an API route */
function getAPI(route: string): string {
    return window.scrawl.apiBaseURL + route
}

export function finalRequestURL(url: string, directURL: boolean) {
    if (!directURL && !startsWith(url, "/")) {
        console.warn("utils.finalRequestURL: An API route should have the forward slashes on both ends.")
        url = `/${url}`
    }
   return directURL ? url : getAPI(url)
}

export const request = {
    get: function(url: string, query: object = {}, directURL: boolean = false) {
        return superagent
            .get(finalRequestURL(url, directURL))
            .query(query)
            .withCredentials()
    },
    post: function(url: string, directURL: boolean = false, data?: string | object) {
        return superagent
            .post(finalRequestURL(url, directURL))
            .send(data)
            .withCredentials()
            .set('X-CSRFToken', cookie.get(window.scrawl.cookieCSRFKey) || "")
    },
    put: function(url: string, directURL: boolean = false, data?: string | object) {
        return superagent
            .put(finalRequestURL(url, directURL))
            .send(data)
            .withCredentials()
            .set('X-CSRFToken', cookie.get(window.scrawl.cookieCSRFKey) || "")
    },
    patch: function(url: string, directURL: boolean = false, data?: string | object) {
        return superagent
            .patch(finalRequestURL(url, directURL))
            .send(data)
            .withCredentials()
            .set('X-CSRFToken', cookie.get(window.scrawl.cookieCSRFKey) || "")
    },
    delete: function(url: string, directURL: boolean = false) {
        return superagent
            .delete(finalRequestURL(url, directURL))
            .withCredentials()
            .set('X-CSRFToken', cookie.get(window.scrawl.cookieCSRFKey) || "")
    },
    postFile: function(url: string, blob: Blob, filename: string) {
        return superagent
            .post(finalRequestURL(url, false))
            .attach('upload_file', blob, filename)
            .withCredentials()
            .set('X-CSRFToken', cookie.get(window.scrawl.cookieCSRFKey) || "")
    }
}

import { Reason, Outcome } from './assessment'
import { number as dnumber, string as dstring, dict, jsonObject, exact, oneOf, constant, either3, guard } from 'decoders'

type Outcomes = {
    [key in Outcome]: number
}

type ReasonsOutcomes = {
    [key in Reason]: Outcomes
}
interface SiteData {
    by_reason: ReasonsOutcomes,
    total: number
}

export interface UsageData {
    start: string
    end: string
    counts: {
        [key: string]: SiteData
    }
}

const outcomes = exact({
    incomplete: dnumber,
    normal: dnumber,
    hyperacute: dnumber,
})

const reasonsOutcomes = exact({
    unknown: outcomes,
    care: outcomes,
    demo_train: outcomes
})

const siteData = exact({
    by_reason: reasonsOutcomes,
    total: dnumber
})

const usageData = exact({
    start: dstring,
    end: dstring,
    counts: dict(siteData)
})

export const usageDataDecoder = guard(usageData, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _outcomes: Outcomes = guard(outcomes)({}) } catch(e) {}
try { const _reasonsOutcomes: ReasonsOutcomes = guard(reasonsOutcomes)({}) } catch(e) {}
try { const _siteData: SiteData = guard(siteData)({}) } catch(e) {}
try { const _usageData: UsageData = usageDataDecoder({}) } catch(e) {}

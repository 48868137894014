import { concat, find, forEach, get, map, replace } from 'lodash-es'
import * as moment from 'moment'
import { fieldText, freeTextSubHtmlBreaks } from './helpers'
import { outpatientInvestigations } from './investigation'
import { genericQuestionsText } from './question'
import { TextDetails } from './interfaces'
import { Stay } from 'models/data/stay'
import { QuestionCategory } from "models/med_templates/question"
import stays from '@store/stays'
import store from 'store'


/**
 * 
 * @param param0 Returns the text for aimingFor
 */
export function aimingFor({ stay }: TextDetails): string {
    if (!stay) return ""

    const discharge = stay.discharge
    if (!discharge) return ""

    const speciality = stays.getSpeciality(stay.id)
    if (!speciality) return  ""

    const aimingForChoices = speciality.aiming_for_choices || []
    const choice = find(aimingForChoices, ["id", discharge.aiming_for])

    if (choice) return choice.title

    return discharge.other_aiming_for || ""
}

export function aimingForMarkup({ stay, hideTitle }: TextDetails): string {
    const aimingForText = aimingFor({ stay, hideTitle })
    return aimingForText ? `Aiming for: ${aimingForText}\n\n` : ''
}

export function nextSteps({ stay }: TextDetails): string[] {
    if (!stay) return []

    const discharge = stay.discharge

    let nextStepText: string[] = []

    const speciality = stays.getSpeciality(stay.id)
    if (speciality && discharge && discharge.next_steps && discharge.next_steps.length) {
        const nextStepChoices = speciality.next_steps || []
        forEach(discharge.next_steps, function(id) {
            const choice = find(nextStepChoices, ["id", id])
            if (choice) {
                nextStepText.push(choice.title)
            }
        })
    }

    const otherNextSteps = get(discharge, "other_next_steps") || []
    if (otherNextSteps.length) {
        nextStepText = concat(nextStepText, map(otherNextSteps, "title"))
    }

    return nextStepText
}

export function readyWhen({ stay }: TextDetails): string[] {
    if (!stay) return []

    const discharge = stay.discharge

    let readyText: string[] = []

    const speciality = stays.getSpeciality(stay.id)
    if (speciality && discharge && discharge.ready_when && discharge.ready_when.length) {
        const readyWhenChoices = speciality.ready_for_discharge_when || []
        forEach(discharge.ready_when, function(id) {
            const choice = find(readyWhenChoices, ["id", id])
            if (choice) {
                readyText.push(choice.title)
            }
        })
    }

    const otherReadyWhen = get(discharge, "other_ready_when") || []
    if (otherReadyWhen.length) {
        readyText = concat(readyText, map(otherReadyWhen, "title"))
    }

    return readyText
}

function followUpIn({ stay }: TextDetails): string {
    if (!stay) return ""

    const discharge = stay.discharge
    if (!(discharge && discharge.follow_up_time)) return ""

    const speciality = stays.getSpeciality(stay.id)
    if (!speciality) return ""

    const followUpTime = discharge.follow_up_time
    const followUpTimeChoices = speciality.follow_up_times
    const followUpInObject = find(followUpTimeChoices, ["id", followUpTime]) || {}
    return get(followUpInObject, "title") || ""
}

/**
 * 
 * @param param0 
 * @param includePrefix Include "Follow up with " prefix text
 * @param includeInst Include instruction text, if available
 */
export function followUpWith({ stay }: TextDetails, includePrefix: boolean, includeInst: boolean): string {
    if (!stay) return ""

    const discharge = stay.discharge
    if (!(discharge && discharge.follow_up_with)) return ""

    let followUpText = ''
    let followUpWithText = ''
    let followUpInText = ''
    let instructionText = ''

    const followUpOptions = store.direct.state.templates.followUpWith
    const choice = find(followUpOptions, ["id", discharge.follow_up_with])

    if (choice && choice.title) {
        followUpWithText = includePrefix ? `Follow up with ${choice.title}` : choice.title
        if (choice.instruction)
            instructionText = `(${choice.instruction})`
    }
    followUpInText = followUpIn({ stay })

    if (followUpWithText)
        followUpText = followUpWithText
    if (followUpInText)
        followUpText += ` in ${followUpInText}`
    if (instructionText && includeInst)
        followUpText += ` ${instructionText}`

    return followUpText
}

function dischargePlanCYOAText({ stay }: TextDetails): string {
    if (!stay) return ''

    let dischargePlanGroupIDs: number[] = []
    // Any question group IDs stored in `stay.question_groups` (i.e. added by user) are valid
    dischargePlanGroupIDs = dischargePlanGroupIDs.concat(stay.question_groups)

    // Any question groups that are attached to the stay's syndrome by default are valid too
    const syndrome = find(store.direct.getters.templates.allSyndromes, ['id', stay.syndrome])
    if (syndrome && syndrome.discharge_plans) {
        dischargePlanGroupIDs = dischargePlanGroupIDs.concat(syndrome.discharge_plans)
    }

    return genericQuestionsText(
        {
            stay,
            category: QuestionCategory.DISCHARGE_PLAN,
            getQuestionGroupsFrom: stays.getSpeciality(stay.id),
            validQuestionGroupIDs: dischargePlanGroupIDs,
        }
    )
}

export function dischargePlan({stay, hideTitle}: TextDetails): string {
    if (!stay) return ""

    const discharge = stay.discharge
    if (!discharge) return ""

    let lines: string[] = []

    if (discharge.aiming_for) {
        lines.push("Aiming for: " + aimingFor({ stay }))
    }

    if (discharge.estimated_discharge_date) {
        const date = moment(discharge.estimated_discharge_date)
        const formattedDate = date.format("Do MMM YYYY")
        lines.push(`Estimated discharge date: ${formattedDate}`)
    }

    const followUpText = followUpWith({ stay }, true, true)
    if (followUpText) lines.push(followUpText)

    if (discharge.blood_pressure_target) {
        const bpLookup: {[k: string]: string} = {
            NOT_DISCUSSED: "- not discussed",
            LT_130: "< 130 systolic following discharge",
            LT_140: "< 140 systolic following discharge",
            LT_150: "< 150 systolic following discharge",
        }
        lines.push(`Aiming for BP ${bpLookup[discharge.blood_pressure_target]}`)
    }

    if (discharge.driving_plan) {
        const drivingLookup: {[k: string]: string} = {
            NOT_DISCUSSED: "Not discussed",
            DOESNT_DRIVE: "Doesn\"t drive",
            NO_RESTRICTIONS: "No restrictions",
            NO_DRIVING_FOR_2_WEEKS: "No driving for 2 weeks",
            NO_DRIVING_FOR_4_WEEKS: "No driving for 4 weeks",
            NO_DRIVING_FOR_6_MONTHS: "No driving for 6 months",
            NO_DRIVING_UNTIL_REVIEW_BY_SPECIALIST: "No driving until review by specialist",
        }
        lines.push(`Driving: ${drivingLookup[discharge.driving_plan]}`)
    }

    let dischargePlanGroupIDs: number[] = []
    // Selected ones and default syndrome ones
    dischargePlanGroupIDs = dischargePlanGroupIDs.concat(stay.question_groups)

    const syndrome = find(store.direct.getters.templates.allSyndromes, ['id', stay.syndrome])
    if (syndrome && syndrome.discharge_plans) {
        dischargePlanGroupIDs = dischargePlanGroupIDs.concat(syndrome.discharge_plans)
    }

    const dischargeQuestionsText = dischargePlanCYOAText({ stay })
    if (dischargeQuestionsText.length) {
        lines.push('\n\n' + dischargeQuestionsText)
    }

    const outpatientInvsText = outpatientInvestigations({ stay })
    if (outpatientInvsText.length) lines.push(outpatientInvsText)

    if (discharge.notes) {
        lines.push(freeTextSubHtmlBreaks(discharge.notes))
    }

    if (!lines.length) return ""

    if(!hideTitle) lines.unshift("\n**Discharge Plan:**")

    return lines.join('  \n') + "\n\n"
}

export function dischargePlanFinal({stay, hideTitle}: TextDetails): string {
    // Method used for text in the Discharge app's EMR notes
    const text = dischargePlan({ stay, hideTitle })
    return replace(text, "Aiming for", "Discharge destination")
}

export function mdtDischargePlan({ stay }: TextDetails): string {
    let lines: string[] = []

    // Basic discharge plan text
    const text = dischargePlan({ stay })
    if (text.length) lines.push(text.trim())

    const nextStepsText = nextSteps({ stay })
    if (nextStepsText.length) {
        lines.push(`Next steps to progress discharge: ${nextStepsText.join(", ")}`)
    }

    const readyText = readyWhen({ stay })
    if (readyText.length) {
        lines.push(`Ready for discharge when: ${readyText.join(", ")}`)
    }

    if (lines.length) {
        return lines.join("<br/>") + "\n\n"
    }
    return ""
}

function _wardPlan(stay: Stay, planPath: string): string {
    const planText = fieldText({ stay }, `ward_round.${planPath}`)
    return planText.length ? `**Plan**  \n${planText}\n\n` : ''
}

export function doctorWardPlan(stay: Stay): string {
    return _wardPlan(stay, 'plan_notes')
}

export function nurseWardPlan(stay: Stay): string {
    return _wardPlan(stay, 'nurse_plan')
}
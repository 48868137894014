var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("diagnosis-dropdown", {
            attrs: {
              label: _vm.label,
              "full-width": true,
              specialities: _vm.specialities,
              "other-diagnosis-id": _vm.OTHER_DIAGNOSIS_VALUE
            },
            model: {
              value: _vm.diagnosis,
              callback: function($$v) {
                _vm.diagnosis = $$v
              },
              expression: "diagnosis"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col" },
        [
          _vm.showOther && _vm.otherDiagnosisPropExtInfo
            ? _c("r-input", {
                attrs: {
                  stay_id: _vm.stay_id,
                  field: _vm.otherDiagnosisPropExtInfo
                }
              })
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.selectedDiagnosis
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c("diagnosis-help", {
              staticClass: "col-sm-6",
              attrs: { diagnosis: _vm.selectedDiagnosis }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }



















import Vue from 'vue'
import { toString } from 'lodash-es'
import utils from 'utils'

import stays from '@store/stays'

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            default: -1
        },
        modalTitle: {
            type: String,
        },
        text: {
            type: [String, Number],
            required: false
        },
        nilText: {
            type: String,
            required: false,
            default: 'Nil',
        },
        okOnly: {
            type: Boolean,
            default: true,
            required: false
        },
        scrollable: {
            type: Boolean,
            default: true,
            required: false
        },
        btnSize: {
            type: String,
            default: 'md'
        },
        btnVariant: {
            type: String,
            default: 'link'
        },
        size: {
            type: String,
            default: "lg"
        },
        forceMaxH: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            uid: utils.getUID(),
            noFade: window.scrawl.isIE11,
        }
    },
    computed: {
        title(): string {
            if (this.modalTitle)
                return this.modalTitle
            const stay = stays.state.stays[this.stay_id]
            if (!stay)
                return 'Edit'
            return utils.patientName(stay, this.$store.direct.state.user.anonymise)
        },
        validText(): boolean {
            return toString(this.text).length > 0
        },
        btnClasses(): string {
            return this.btnVariant === 'link' ? 'p-0 text-left' : ''
        },
    },
    methods: {
        pauseOverview() {
            this.$store.direct.commit.session.setPauseOverview(true)
        },
        unpauseOverview() {
            this.$store.direct.commit.session.setPauseOverview(false)
        },
        showHandler() {
            this.pauseOverview()
            this.$emit('edit-modal-show')
        },
        hiddenHandler() {
            this.$emit('edit-modal-hidden')
            this.unpauseOverview()
        },
    }
})












































import Vue from 'vue'
import { filter, includes, indexOf, map } from 'lodash-es'
import utils from 'utils'
import stays from '@store/stays'

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            uid,
            modalID: `${uid}___add_issues`,
            addLabel: "Add Issues",
            selected: [] as number[],
            otherLabel: '?',
            otherValue: -99
        }
    },
    computed: {
        issueGroups() {
            let issueGroups = this.$store.direct.getters.templates.issueGroups
            const speciality = stays.getSpeciality(this.stay_id)
            if (speciality) {
                issueGroups = filter(issueGroups, ['speciality', speciality.id])
            }
            // Filter out issue groups with no issues
            return filter(issueGroups, group => (group.issues && group.issues.length))
        },
        otherTicked(): boolean {
            return includes(this.selected, this.otherValue)
        },
    },
    methods: {
        issueHtmlId(id: number) {
            return `${this.uid}___issue_${id}`
        },
        addIssues() {
            if (this.selected.length) {
                Vue.toasted.info(`Adding ${this.selected.length} issues...`)

                if (this.otherTicked) {
                    // Remove 'Other' issue from list
                    const index = indexOf(this.selected, this.otherValue)
                    this.selected.splice(index, 1)
                    stays.extActAddOtherIssue(this.stay_id, [{ title: this.otherLabel }])
                    this.otherLabel = '?'
                }

                if (this.selected.length > 0) {
                    const data = map(this.selected, function(id) {
                        return { issue: id }
                    })
                    stays.extActAddIssues(this.stay_id, data)
                    this.selected = []
                }
            }
        },
        selectOtherTitle(): void {
            if (this.otherLabel === '?') {
                const issueTitleRef: any = this.$refs['otherIssueTitle']
                if (issueTitleRef)
                    issueTitleRef.select()
            }
        }
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPowerUser
    ? _c(
        "div",
        { staticClass: "d-inline-block" },
        [
          _c(
            "button",
            {
              staticClass:
                "btn btn-sm progress-bar-striped bg-danger text-white",
              class: { "mb-1": _vm.add_mb },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.confirmStayDeletion.apply(null, arguments)
                }
              }
            },
            [_vm._v("\n        Delete\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                id: _vm.deleteId,
                title: "Delete stay?",
                centered: "",
                "ok-title": "Yes",
                "ok-variant": "danger"
              },
              on: {
                ok: function($event) {
                  return _vm.deleteStay(_vm.stay)
                }
              }
            },
            [
              _c("p", [
                _vm._v("This will permanently delete stay record for "),
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.patientText) +
                      ", " +
                      _vm._s(_vm.identifierText_c)
                  )
                ]),
                _vm._v(
                  ". The action is irreversible and the data cannot be recovered!"
                )
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("Are you sure?")])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-overlay", {
    attrs: { "no-wrap": "", variant: "transparent", show: _vm.DRL_leaving },
    scopedSlots: _vm._u([
      {
        key: "overlay",
        fn: function() {
          return [
            _c(
              "h3",
              {
                staticClass: "py-2 px-3 border rounded bg-secondary",
                staticStyle: { opacity: "0.8" }
              },
              [
                _c("em", { staticClass: "loading-dots" }, [
                  _vm._v("Saving changes"),
                  _c("span", [_vm._v(".")]),
                  _c("span", [_vm._v(".")]),
                  _c("span", [_vm._v(".")])
                ])
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
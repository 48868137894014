var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          staticClass: "mb-0",
          attrs: {
            label: "Findings",
            "label-cols-sm": "4",
            "label-class": "pt-0"
          }
        },
        _vm._l(_vm.fieldIds.findings, function(fieldId) {
          return _c(
            "b-check",
            {
              key: fieldId,
              staticClass: "scrawl-checkbox",
              attrs: {
                size: "lg",
                value: _vm.fieldToIdMap[fieldId],
                "unchecked-value": null
              },
              model: {
                value: _vm.selection.findings,
                callback: function($$v) {
                  _vm.$set(_vm.selection, "findings", $$v)
                },
                expression: "selection.findings"
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.fieldToTitleMap[fieldId]) +
                  "\n        "
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.isAPL
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Side",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.fieldIds.sides, function(fieldId) {
                  return _c(
                    "b-check",
                    {
                      key: fieldId,
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: _vm.fieldToIdMap[fieldId],
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.selection.sides,
                        callback: function($$v) {
                          _vm.$set(_vm.selection, "sides", $$v)
                        },
                        expression: "selection.sides"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.fieldToTitleMap[fieldId]) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.isAPL
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Site",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.fieldIds.apl_site, function(fieldId) {
                  return _c(
                    "b-check",
                    {
                      key: fieldId,
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: _vm.fieldToIdMap[fieldId],
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.selection.apl_site,
                        callback: function($$v) {
                          _vm.$set(_vm.selection, "apl_site", $$v)
                        },
                        expression: "selection.apl_site"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.fieldToTitleMap[fieldId]) +
                          "\n            "
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.isAPL
            ? _c(
                "b-form-group",
                {
                  staticClass: "mt-3 mb-0",
                  attrs: {
                    label: "Lesion volumes",
                    "label-cols-sm": "4",
                    "label-class": "pt-0"
                  }
                },
                _vm._l(_vm.volumeQuestions, function(question) {
                  return _c("question", {
                    key: question.id,
                    staticClass: "py-2",
                    attrs: {
                      stay_id: _vm.stay_id,
                      question: question,
                      altHelpText: "mLs"
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Additional findings" } },
        [
          _c("b-form-textarea", {
            attrs: { rows: "3" },
            model: {
              value: _vm.notes,
              callback: function($$v) {
                _vm.notes = $$v
              },
              expression: "notes"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
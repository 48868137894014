
import Vue from 'vue'
import { filter, get, includes, isArray, values } from 'lodash-es'
import * as moment from 'moment'

import utils from 'utils'
import stays from '@store/stays'

import { isDoctor, Doctor } from 'models/data/doctor'
import { Stay } from 'models/data/stay'

export default Vue.extend({
    computed: {
        canToggleScrawlIds(): boolean {
            return this.$store.direct.getters.user.canExportData
        },
        anonymise(): boolean {
            return this.$store.direct.state.user.anonymise
        },
        stays(): Stay[] {
            const specialityIds = this.$store.direct.getters.templates.specialityIds
            return filter(values(stays.state.stays), stay => stay.speciality === null || includes(specialityIds, stay.speciality))
        },
        selectedSpecialities() {
            return this.$store.direct.getters.user.stayFilters.specialities
        },
        primaryStays(): Stay[] {
            return filter(this.stays, stay => {
                if (stay.primary !== null)
                    return false
                if (isArray(this.selectedSpecialities))
                    return includes(this.selectedSpecialities, stay.sp)
                return true
            })
        },
        secondaryStays(): Stay[] {
            return filter(this.stays, stay => !includes(this.primaryStays, stay))
        },
        staysLoading(): boolean {
            return stays.state.staysLoading
        },
        shouldHide(): boolean {
            return this.$store.direct.state.user.hide_stays
        },
        showStayIds(): boolean {
            return this.$store.direct.state.user.home_stay_ids
        },
        hideBtnText(): string {
            return this.shouldHide ? 'Show hidden' : 'Hide stays'
        }
    },
    methods: {
        bed(stay: Stay): string | null | undefined {
            return get(stay, 'location.bed')
        },
        finalBed(ward: string | null | undefined, bed: string | null | undefined): string | null | undefined {
            if (this.$store.direct.state.session.ux_location_prune_bed && !!bed) {
                const regex = new RegExp(`^${ward}_`)
                return bed.replace(regex, '')
            }
            return bed
        },
        room(stay: Stay): string | null | undefined {
            return get(stay, 'location.room')
        },
        ward(stay: Stay): string | null | undefined {
            const ward = get(stay, 'location.ward')
            return ward
        },
        altWard(ward: string | null | undefined): string | null | undefined {
            if (!ward) return ward
            return this.$store.direct.getters.templates.wardLabelsDict[ward]
        },
        finalWard(ward: string | null | undefined) {
            if (this.$store.direct.state.session.ux_location_alt_ward)
                return this.altWard(ward) || ward
            return ward
        },
        locationDesc(stay: Stay): string {
            let ward = this.ward(stay)
            let bed = this.bed(stay)
            bed = this.finalBed(ward, bed)
            ward = this.finalWard(ward)

            if (!!ward && !!bed)
                return `${ward} - ${bed}`
            if (!!ward)
                return ward
            if (!!bed)
                return bed
            return ''
        },
        createdAt(stay: Stay): string {
            return moment(stay.created_at).format('DD-MMM-YY HH:mma')
        },
        deleteStay(stay: Stay) {
            stays.actDeleteStay(stay.id)
        },
        diagnosisText(stay: Stay): string {
            return stay.diagnosis_title_ext || ''
        },
        dischargeStay(stay: Stay) {
            stays.actSetPathValue({ id: stay.id, path: 'active', val: false })
        },
        doctorName(stay: Stay): string {
            if (isDoctor(stay.doctor))
                return (stay.doctor as Doctor).last_name
            return ''
        },
        hospitalName(stay: Stay): string {
            return get(stay, 'hospital.title', '')
        },
        inHiddenIDs(stayID: number): boolean {
            const hiddenIDs = this.$store.direct.state.user.hidden_stays
            return includes(hiddenIDs, stayID)
        },
        identifierText(stay: Stay): string {
            return this.showStayIds ? `${stay.patient.mrn} | ${stay.id}` : stay.patient.mrn
        },
        patientName(stay: Stay) {
            return utils.patientName(stay, this.anonymise)
        },
        patientNameAndAge(stay: Stay) {
            return utils.patientNameAndAge(stay, this.anonymise)
        },
        toggleActiveStatus(stay: Stay) {
            stays.actSetPathValue({ id: stay.id, path: 'active', val: !stay.active })
        },
        toggleHide(stayID: number) {
            this.$store.direct.dispatch.user.toggleStayHide(stayID)
        },
        toggleHideStays() {
            this.$store.direct.dispatch.user.toggleHideStays()
        },
        toggleRapidAsmtDischarge(stay: Stay) {
            const val = stay.rapid_asmt_discharge_time === null ? moment().toISOString() : null
            stays.actSetPathValue({ id: stay.id, path: 'rapid_asmt_discharge_time', val })
            setTimeout(() => {
                stays.mutTriggerLoadStays()
            }, this.$store.direct.state.session.changesMaxAge + 1000)
        },
    }
})

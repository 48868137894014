












































import Vue from 'vue'
import { filter, forEach, includes } from 'lodash-es'
import utils from 'utils'
import { SummaryStay } from 'models/dataviz'
import StaysInfoModal from './StaysInfoModal.vue'


export default Vue.extend({
    components: {
        StaysInfoModal,
    },
    props: {
        title: {
            required: false,
            type: String,
        },
        data: {
            type: Object,
            default: null,
        },
        fields: {
            required: true,
            type: Array as () => any[],
        },
        textVersion: {
            type: String,
            required: false,
            default: 'discharge',
            validator: (value) => {
                return ['discharge', 'hyperacute', 'strokeUnitSummary'].indexOf(value) !== -1
            },
        },
        showCurrentMeds: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            uid,
        }
    },
    computed: {
        /** Return an object where full stay objects are keyed by the field key
            e.g. { 'ecr_metrics': [{ id: 1, patient: { ... } }, { id: 2, ...}, ...] }
         */
        staysByField(): {[k: string]: SummaryStay[]} {
            const result: {[k: string]: SummaryStay[]} = {}
            forEach(this.fields, field => {
                const stayIDs = this.data[field.key]
                result[field.key] = filter(this.data.stays, stay => {
                    return includes(stayIDs, stay.id)
                })
            })
            return result
        },
        /** Similar to `staysByField` but applies the `infoFilter` for a field if it exists
            and is keyed by the field index because the same `field.key` can be used for
            different rows that need different filters to be applied
         */
        infoStaysByField(): SummaryStay[][] {
            const result: SummaryStay[][] = []
            forEach(this.fields, field => {
                result.push(filter(this.staysByField[field.key], field.infoFilter))
            })
            return result
        },
    },
    methods: {
        staysInfoModalId(index: number): string {
            return `${this.uid}___${index}-stays-modal`
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay
    ? _c("edit-modal", {
        attrs: { stay_id: _vm.stay.id },
        scopedSlots: _vm._u(
          [
            {
              key: "text",
              fn: function() {
                return _vm._l(_vm.summaryText, function(info) {
                  return _c("p", { key: info.uid, staticClass: "mb-0" }, [
                    _vm._v("\n            " + _vm._s(info.title) + "\n        ")
                  ])
                })
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [_c("all-invs", { attrs: { stay_id: _vm.stay.id } })]
              },
              proxy: true
            }
          ],
          null,
          false,
          1927356631
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
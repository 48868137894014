


























































































import mixins from 'vue-typed-mixins'
import AdvInvMixin from './AdvInvMixin.vue'

interface Selection {
    findings: number | null
    sides: number[]
    ih: number | null
    ai: number | null
    ai_lacune: number | null
    add_findings: number[]
}

export default mixins(AdvInvMixin).extend({
    data() {
        const selection: Selection = {
            findings: null,
            sides: [],
            ih: null,
            ai: null,
            ai_lacune: null,
            add_findings: [],
        }

        return {
            fieldIds: {
                findings: ['normal', 'ih', 'ai', 'tih'],
                sides: ['right', 'left'],
                ih: ['ih_basal', 'ih_lobar', 'ih_brainstem', 'ih_cerebellum'],
                ai: ['ai_lacune', 'ai_anterior', 'ai_posterior', 'ai_brainstem', 'ai_cerebellum'],
                ai_lacune: ['ai_lacune_internal', 'ai_lacune_thalmus', 'ai_lacune_brainstem'],
                add_findings: ['add_ivh', 'add_mid_shift', 'add_hydrocephalus'],
            },
            selection,
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        sideVisible(): boolean {
            switch (this.selection.findings) {
                case this.fieldToIdMap['ih']:
                case this.fieldToIdMap['ai']:
                    return true
                default:
                    return false
            }
        },
        ihVisible(): boolean {
            return this.selection.findings === this.fieldToIdMap['ih']
        },
        aiVisible(): boolean {
            return this.selection.findings === this.fieldToIdMap['ai']
        },
        aiLacuneVisible(): boolean {
            return this.selection.ai === this.fieldToIdMap['ai_lacune']
        },
        localResults(): number[] {
            let results: number[] = []
            results = results.concat(this.selection.sides)
            results = results.concat(this.selection.add_findings)
            if (this.selection.findings) results.push(this.selection.findings)
            if (this.selection.ih) results.push(this.selection.ih)
            if (this.selection.ai) results.push(this.selection.ai)
            if (this.selection.ai_lacune) results.push(this.selection.ai_lacune)
            return results
        },
    },
    watch: {
        'selection.findings'(val: number | null) {
            switch(val) {
                case this.fieldToIdMap['normal']:
                case this.fieldToIdMap['tih']:
                    this.selection.sides = []
                    this.selection.ih = null
                    this.selection.ai = null
                    this.selection.ai_lacune = null
                    break

                case this.fieldToIdMap['ih']:
                    this.selection.ai = null
                    this.selection.ai_lacune = null
                    break

                case this.fieldToIdMap['ai']:
                    this.selection.ih = null
                    break
            }
        },
        'selection.ai'(val: number | null) {
            if (val !== this.fieldToIdMap['ai_lacune'])
                this.selection.ai_lacune = null
        },
    },
    methods: {
        clearResults() {
            this.selection.findings = null
            this.selection.sides = []
            this.selection.ih = null
            this.selection.ai = null
            this.selection.ai_lacune = null
            this.selection.add_findings = []
        }
    }
})

import {
    boolean as dboolean, number as dnumber, string as dstring, dict, json as djson, iso8601,
    constant, either, either3, either4, either5,
    nullable, array, inexact, guard
} from 'decoders'
import { Stage, stageDef } from '../_enums'

export const NIHSS_ADM_DISPLAY = 'Presentation NIHSS'
export const NIHSS_24_72_HRS_TITLE = '24-72 Hrs'
export const NIHSS_24_72_HRS_DISPLAY = 'Acute progress NIHSS (ideally 24-72 hrs)'
export const NIHSS_24_72_HRS_DISPLAY_SHORT = 'Acute progress NIHSS'

type Consciousness = 'ALERT' | 'MINOR_STIMULATION' | 'PAINFUL_STIMULI' | 'UNRESPONSIVE'
type Language = 'NORMAL' | 'LOSS_OF_FLUENCY' | 'SEVERE_APHASIA' | 'UNABLE_TO_SPEAK'
type Dysarthria = 'NORMAL' | 'MILD' | 'SEVERE'
type Gaze = 'NORMAL' | 'UNABLE_TO_LOOK_RIGHT' | 'UNABLE_TO_LOOK_LEFT' | 'FORCED_DEVIATION_TO_RIGHT' | 'FORCED_DEVIATION_TO_LEFT'
type FacialStrength = 'NORMAL' | 'MINOR_PARALYSIS' | 'PARTIAL_PARALYSIS' | 'TOTAL_PARALYSIS'
type ArmStrength = 'NO_DRIFT' | 'MINOR_DRIFT' | 'DRIFT' | 'FALLS_IMMEDIATELY' | 'NO_MOVEMENT'
type LegStrength = 'NO_DRIFT' | 'MINOR_DRIFT' | 'DRIFT' | 'FALLS_IMMEDIATELY' | 'NO_MOVEMENT'
type SensoryLoss = 'NONE' | 'DULL' | 'SEVERE'
type SensoryInattention = 'NONE' | 'INATTENTION'

const ConsciousnessDef = either4(
    constant('ALERT' as 'ALERT'),
    constant('MINOR_STIMULATION' as 'MINOR_STIMULATION'),
    constant('PAINFUL_STIMULI' as 'PAINFUL_STIMULI'),
    constant('UNRESPONSIVE' as 'UNRESPONSIVE'),
)

const LanguageDef = either4(
    constant('NORMAL' as 'NORMAL'),
    constant('LOSS_OF_FLUENCY' as 'LOSS_OF_FLUENCY'),
    constant('SEVERE_APHASIA' as 'SEVERE_APHASIA'),
    constant('UNABLE_TO_SPEAK' as 'UNABLE_TO_SPEAK'),
)

const DysarthriaDef = either3(
    constant('NORMAL' as 'NORMAL'),
    constant('MILD' as 'MILD'),
    constant('SEVERE' as 'SEVERE'),
)

const GazeDef = either5(
    constant('NORMAL' as 'NORMAL'),
    constant('UNABLE_TO_LOOK_RIGHT' as 'UNABLE_TO_LOOK_RIGHT'),
    constant('UNABLE_TO_LOOK_LEFT' as 'UNABLE_TO_LOOK_LEFT'),
    constant('FORCED_DEVIATION_TO_RIGHT' as 'FORCED_DEVIATION_TO_RIGHT'),
    constant('FORCED_DEVIATION_TO_LEFT' as 'FORCED_DEVIATION_TO_LEFT'),
)

const FacialStrengthDef = either4(
    constant('NORMAL' as 'NORMAL'),
    constant('MINOR_PARALYSIS' as 'MINOR_PARALYSIS'),
    constant('PARTIAL_PARALYSIS' as 'PARTIAL_PARALYSIS'),
    constant('TOTAL_PARALYSIS' as 'TOTAL_PARALYSIS'),
)

const ArmStrengthDef = either5(
    constant('NO_DRIFT' as 'NO_DRIFT'),
    constant('MINOR_DRIFT' as 'MINOR_DRIFT'),
    constant('DRIFT' as 'DRIFT'),
    constant('FALLS_IMMEDIATELY' as 'FALLS_IMMEDIATELY'),
    constant('NO_MOVEMENT' as 'NO_MOVEMENT'),
)

const LegStrengthDef = either5(
    constant('NO_DRIFT' as 'NO_DRIFT'),
    constant('MINOR_DRIFT' as 'MINOR_DRIFT'),
    constant('DRIFT' as 'DRIFT'),
    constant('FALLS_IMMEDIATELY' as 'FALLS_IMMEDIATELY'),
    constant('NO_MOVEMENT' as 'NO_MOVEMENT'),
)

const SensoryLossDef = either3(
    constant('NONE' as 'NONE'),
    constant('DULL' as 'DULL'),
    constant('SEVERE' as 'SEVERE'),
)

const SensoryInattentionDef = either(
    constant('NONE' as 'NONE'),
    constant('INATTENTION' as 'INATTENTION'),
)

export interface NIHSS {
    id: number
    editable: boolean
    updated_at: string

    stage: Stage
    ward_round: number | null
    label: number | null
    assessed_at: string | null,

    // read-only fields
    score: number
    questions_completed: number
    /** derived from the number of questions answered */
    complete: boolean

    // data fields
    consciousness: Consciousness | null,
    age: boolean | null,
    month: boolean | null,
    close_open_eyes: boolean | null,
    make_a_fist: boolean | null,
    language: Language | null,
    dysarthria: Dysarthria | null,

    visual_fields_normal: boolean | null,
    visual_fields: number[] | null,
    visual_inattention_normal: boolean | null,
    visual_inattention: number[] | null,
    gaze_normal: boolean | null,
    gaze: Gaze | null,

    strength_right_normal: boolean | null,
    strength_left_normal: boolean | null,
    facial_strength_right: FacialStrength | null,
    facial_strength_left: FacialStrength | null,
    arm_strength_right: ArmStrength | null,
    arm_strength_left: ArmStrength | null,
    leg_strength_right: LegStrength | null,
    leg_strength_left: LegStrength | null,
    mrc: { [k: string]: any }

    ataxia_right_normal: boolean | null,
    ataxia_left_normal: boolean | null,
    ataxia_arm_right: boolean | null,
    ataxia_arm_left: boolean | null,
    ataxia_leg_right: boolean | null,
    ataxia_leg_left: boolean | null,

    sensory_loss_right: SensoryLoss | null,
    sensory_loss_left: SensoryLoss | null,
    sensory_inattention_right: SensoryInattention | null,
    sensory_inattention_left: SensoryInattention | null,

    notes: string | null,
}

export const nihss = inexact({
    id: dnumber,
    editable: dboolean,
    updated_at: dstring,

    stage: stageDef,
    ward_round: nullable(dnumber),
    label: nullable(dnumber),
    assessed_at: nullable(dstring),

    score: dnumber,
    questions_completed: dnumber,
    complete: dboolean,

    consciousness: nullable(ConsciousnessDef),
    age: nullable(dboolean),
    month: nullable(dboolean),
    close_open_eyes: nullable(dboolean),
    make_a_fist: nullable(dboolean),
    language: nullable(LanguageDef),
    dysarthria: nullable(DysarthriaDef),

    visual_fields_normal: nullable(dboolean),
    visual_fields: nullable(array(dnumber)),
    visual_inattention_normal: nullable(dboolean),
    visual_inattention: nullable(array(dnumber)),
    gaze_normal: nullable(dboolean),
    gaze: nullable(GazeDef),

    strength_right_normal: nullable(dboolean),
    strength_left_normal: nullable(dboolean),
    facial_strength_right: nullable(FacialStrengthDef),
    facial_strength_left: nullable(FacialStrengthDef),
    arm_strength_right: nullable(ArmStrengthDef),
    arm_strength_left: nullable(ArmStrengthDef),
    leg_strength_right: nullable(LegStrengthDef),
    leg_strength_left: nullable(LegStrengthDef),
    mrc: dict(djson),

    ataxia_right_normal: nullable(dboolean),
    ataxia_left_normal: nullable(dboolean),
    ataxia_arm_right: nullable(dboolean),
    ataxia_arm_left: nullable(dboolean),
    ataxia_leg_right: nullable(dboolean),
    ataxia_leg_left: nullable(dboolean),

    sensory_loss_right: nullable(SensoryLossDef),
    sensory_loss_left: nullable(SensoryLossDef),
    sensory_inattention_right: nullable(SensoryInattentionDef),
    sensory_inattention_left: nullable(SensoryInattentionDef),

    notes: nullable(dstring),
})


export const nihssDecoder = guard(nihss, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _nihss: NIHSS = guard(nihss)({}) } catch(e) {}

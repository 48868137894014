var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("diagnosis-input", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _c("admit-mgmt-summary", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _c("diagnosis-features", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.showAllIssues
        ? [
            _c("issues-overview", { attrs: { stay_id: _vm.stay_id } }),
            _vm._v(" "),
            _c("hr")
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.telestrokeMode
        ? [
            _c("standard-investigations-list", {
              attrs: { stay_id: _vm.stay_id, hideCompleted: true }
            }),
            _vm._v(" "),
            _c("hr")
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.telestrokeMode
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c("r-input", {
                  attrs: {
                    stay_id: _vm.stay_id,
                    field: _vm.issueTextPropExtInfo
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("hr")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isThrombolysis
        ? [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [_c("thrombolysis", { attrs: { stay_id: _vm.stay_id } })],
                1
              )
            ]),
            _vm._v(" "),
            _c("hr")
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("management-plan", {
        attrs: { stay_id: _vm.stay_id, diagnosis: _vm.diagnosis }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
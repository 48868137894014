var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("text-base", {
    attrs: {
      stay_id: _vm.stay_id,
      markup: _vm.markup,
      modalOkTitle: "Mark Ward Round Complete",
      tag: "ward",
      customMarkComplete: _vm.markWardRoundComplete,
      showExtras: _vm.showExtras
    },
    scopedSlots: _vm._u([
      {
        key: "modalContent",
        fn: function() {
          return [
            _c("p", [
              _vm._v("Do you want to mark the ward round as complete?")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                "\n            The following sections will be cleared:\n            "
              ),
              _c("ul", { staticClass: "mb-0" }, [
                _c("li", [_vm._v("Attending")]),
                _vm._v(" "),
                _c("li", [_vm._v("Progress")]),
                _vm._v(" "),
                _c("li", [_vm._v("Exam")]),
                _vm._v(" "),
                _c("li", [_vm._v("Patient Discussion & Education")])
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "extras",
        fn: function() {
          return [
            _vm.nihssV2
              ? _c("text-nihss-select", { attrs: { stay_id: _vm.stay_id } })
              : _vm._e(),
            _vm._v(" "),
            _c("attending", {
              staticClass: "mb-2",
              attrs: { stay_id: _vm.stay_id, section: "ward_round" }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }


























































































































import mixins from "vue-typed-mixins"
import { get, isFinite, map } from "lodash-es"
import utils from 'utils'

import { PropExtInfo } from "models"
import { PathValue } from "models/payload"
import { PropType } from 'models/schema'
import { freeTextSubHtmlBreaks } from 'utils/text/helpers'

import TextArea from "@shared/inputs/TextArea.vue"
import InputMixin from "../mixins/Input.vue"
import Dropdown from "@shared/inputs/Dropdown.vue"
import DateTimePicker from "@shared/inputs/DateTimePicker.vue"
import Radio from '@shared/inputs/Radio.vue'

interface InputChoice {
    htmlID: string
    label: string
    value: string | boolean | number | null
    hide?: boolean
}

export default mixins(InputMixin).extend({
    components: {
        Dropdown,
        DateTimePicker,
        Radio,
        TextArea
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        field: {
            type: Object as () => PropExtInfo,
            required: true
        },
        placeholder: {
            type: String
        },
        rows: {
            type: Number,
            default: 5
        }
    },
    data() {
        const htmlID = `${utils.getUID()}___${this.field.path}`
        const labelFor = this.field.type === PropType.STR ? htmlID : undefined

        const isString = [PropType.STR, PropType.TEXT].indexOf(this.field.type) !== -1
        const hasMaxLength = this.field.max_length !== undefined
        const hasChoices = [PropType.BOOL, PropType.SINGLE, PropType.MULTI].indexOf(this.field.type) !== -1
        const isFloatOrDecimal = [PropType.FLOAT, PropType.DECIMAL, PropType.NUMBER].indexOf(this.field.type) !== -1
        const isCombined: boolean = this.field.booleanOptions && this.field.booleanOptions.combined !== undefined ? this.field.booleanOptions.combined : false

        let isHorizontal = false
        if (this.field.horizontal !== undefined)
            isHorizontal = this.field.horizontal
        else if (hasChoices && !(this.field.dropdown || isCombined))
            isHorizontal = true

        const labelCols = (isCombined || !isHorizontal) ? false : 4
        const hideLabel = isCombined || this.field.hide_label || false

        const hasIcon = this.field.fnt_a_icon !== undefined
        const iconStyle: { [k:string]: string } = {}
        if (this.field.icon_color) iconStyle['color'] = this.field.icon_color

        let choices: InputChoice[] = []
        if (this.field.type === PropType.BOOL && !isCombined) {
            choices.push({
                htmlID: `${htmlID}_no`,
                label: get(this.field, "booleanOptions.false_label") || "No",
                value: false
            })
            choices.push({
                htmlID: `${htmlID}_yes`,
                label: get(this.field, "booleanOptions.true_label") || "Yes",
                value: true
            })
        }
        else if (this.field.type === PropType.SINGLE || this.field.type === PropType.MULTI) {
            choices = map(this.field.choices || [], function(c) {
                return {
                    htmlID: `${htmlID}_${c.value}`,
                    label: c.display_name,
                    value: c.value,
                    hide: c.hide
                }
            })
        }
        
        const formGroupClasses = hasChoices && !isCombined ? 'hover-effect' : ''
        const choicesInline = this.field.choices_alignment !== "stacked"  // defaults to inline

        return {
            htmlID,
            labelID: `${htmlID}_label`,
            notesID: `${htmlID}_notes`,
            labelCols,
            labelFor,
            hideLabel,
            labelText: this.field.force_label || this.field.label,
            hasIcon,
            iconStyle,
            isCombined,
            isHorizontal,
            isUnknown: this.field.type === PropType.UNKNOWN,
            isString,
            isBool: this.field.type === PropType.BOOL,
            isMulti: this.field.type === PropType.MULTI,
            isInteger: this.field.type === PropType.INT,
            isFloatOrDecimal,
            unitText: this.field.unit || "",
            isDropdown: this.field.dropdown,
            isDate: this.field.type === PropType.DATE,
            isDateTime: [PropType.DATETIME, PropType.TIMESTAMP].indexOf(this.field.type) !== -1,
            hasMaxLength,
            hasChoices,
            choices,
            choicesInline,
            formGroupClasses,
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        debugLabelText(): string {
            return `${this.field.path}: ${typeof this.fieldAny}`
        },
        fieldAny: {
            get(): any {
                return this.getFieldValue()
            },
            set(val: any) {
                if (this.isInteger && val === '')
                    this.setFieldValue(null)
                else
                    this.setFieldValue(val)
            }
        },
        fieldDecimal: {
            get(): any {
                const val = this.getFieldValue()
                if (val === null) return val
                const valNum = parseFloat(val)
                return isFinite(valNum) ? valNum : val
            },
            set(val: any) {
                if (val === '')
                    this.setFieldValue(null)
                else {
                    const valNum = parseFloat(val)
                    if (isFinite(valNum) && valNum !== this.fieldDecimal)
                        this.setFieldValue(valNum)
                }
            }
        },
        notes: {
            get(): string | null {
                return this.field.notes_path ? this.getFieldVal(this.stay_id, this.field.notes_path) : ''
            },
            set(val: any) {
                if (this.field.notes_path)
                    this.setFieldVal(this.stay_id, this.field.notes_path, val)
            }
        },
        error(): PathValue | undefined {
            return this.getError(this.stay_id, this.field.path)
        },
        isValid(): boolean {
            return this.error ? false : true
        },
        invalidFeedback() : any{
            return this.error ? this.error.val : ""
        },
        helpText(): string {
            return this.field.help_text ? freeTextSubHtmlBreaks(this.field.help_text) : ''
        },
    },
    methods: {
        getFieldValue(): any {
            const val = (this.field.obj_id && this.field.nested_path) ?
                this.getArrayedObjVal(this.stay_id, this.field.path, this.field.obj_id, this.field.nested_path)
                : this.getFieldVal(this.stay_id, this.field.path)
            return this.isMulti && val === undefined ? [] : val
        },
        setFieldValue(val: any) {
            if (!this.isValid)
                this.clearError(this.stay_id, this.field.path)
            if (this.field.obj_id && this.field.nested_path)
                this.setArrayedObjVal(this.stay_id, this.field.path, this.field.obj_id, this.field.nested_path, val)
            else
               this.setFieldVal(this.stay_id, this.field.path, val)
        },
    },
})

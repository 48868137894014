var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isTempUser
    ? _c(
        "b-form-group",
        {
          staticClass: "mb-0",
          attrs: { "label-class": "pb-0", label: "Doctor(s)" }
        },
        [
          _c(
            "b-dd",
            {
              attrs: { variant: "primary", text: _vm.selectedText },
              on: { show: _vm.showHandler, hide: _vm.hideHandler }
            },
            _vm._l(_vm.doctors, function(doctor) {
              return _c(
                "b-dd-item-btn",
                {
                  key: doctor.id,
                  class: { active: _vm.inSelected(doctor.id) },
                  on: {
                    click: function($event) {
                      return _vm.toggleSelect(doctor.id)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(doctor.last_name) +
                      "\n            "
                  ),
                  _vm.inSelected(doctor.id)
                    ? _c("fnt-a-icon", { attrs: { icon: ["fal", "check"] } })
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
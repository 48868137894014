import { render, staticRenderFns } from "./AddInvestigations.vue?vue&type=template&id=50960785&"
import script from "./AddInvestigations.vue?vue&type=script&lang=ts&"
export * from "./AddInvestigations.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50960785')) {
      api.createRecord('50960785', component.options)
    } else {
      api.reload('50960785', component.options)
    }
    module.hot.accept("./AddInvestigations.vue?vue&type=template&id=50960785&", function () {
      api.rerender('50960785', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/data-entry/sections/investigations/AddInvestigations.vue"
export default component.exports
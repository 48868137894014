var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dd",
    {
      directives: [
        {
          name: "b-modal",
          rawName: "v-b-modal",
          value: _vm.modalId,
          expression: "modalId"
        }
      ],
      attrs: { size: "sm", split: "", "split-to": _vm.dataEntryRoute },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function() {
            return [
              _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
              _vm.combined.sstay
                ? [
                    _vm.combined.sstay.active
                      ? _c(
                          "span",
                          { class: { "text-success": _vm.oldAcked } },
                          [
                            _c("fnt-a-icon", {
                              staticClass: "svg",
                              attrs: { icon: ["fas", "play-circle"] }
                            })
                          ],
                          1
                        )
                      : _c(
                          "span",
                          { class: { "text-warning": _vm.oldAcked } },
                          [
                            _c("fnt-a-icon", {
                              staticClass: "svg",
                              attrs: { icon: ["fas", "stop-circle"] }
                            })
                          ],
                          1
                        )
                  ]
                : _vm.combined.pstay
                ? _c(
                    "span",
                    { class: { "text-info": _vm.oldAcked } },
                    [
                      _c("fnt-a-icon", {
                        staticClass: "svg",
                        attrs: { icon: ["fal", "user-chart"] }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.oldAcked
                ? _c(
                    "span",
                    { staticClass: "text-danger" },
                    [
                      _c("fnt-a-icon", {
                        staticClass: "svg",
                        attrs: { icon: ["fas", "exclamation"] }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm.combined.sstay && _vm.combined.sstay.primary
        ? _c(
            "b-dd-item",
            [
              _vm._v(
                "\n        Active: " +
                  _vm._s(_vm.combined.sstay.active ? "Yes" : "No")
              ),
              _c("br"),
              _vm._v("\n        Consultation for:"),
              _c("br"),
              _vm._v(" "),
              _c("stay-summary", {
                staticClass: "pl-3",
                attrs: { stay: _vm.refStay }
              }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(
                "\n        Speciality: " +
                  _vm._s(_vm.combined.sstay.speciality_title)
              ),
              _c("br"),
              _vm._v(
                "\n        Started: " +
                  _vm._s(_vm.formatDT(_vm.combined.sstay.created_at))
              ),
              _c("br"),
              _vm._v(
                "\n        Last updated: " +
                  _vm._s(_vm.formatDT(_vm.combined.sstay.updated_at)) +
                  "\n    "
              )
            ],
            1
          )
        : _vm.combined.sstay
        ? _c(
            "b-dd-item",
            [
              _vm._v(
                "\n        Active: " +
                  _vm._s(_vm.combined.sstay.active ? "Yes" : "No")
              ),
              _c("br"),
              _vm._v(" "),
              _c("stay-summary", { attrs: { stay: _vm.refStay } })
            ],
            1
          )
        : _vm.combined.pstay
        ? _c(
            "b-dd-item",
            [_c("stay-summary", { attrs: { stay: _vm.refStay } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isOld && _vm.refStay
        ? _c(
            "b-modal",
            {
              attrs: {
                id: _vm.ackOldModalId,
                size: "lg",
                title: "Warning - old record",
                "ok-title": "I understand",
                "ok-variant": "warning",
                "cancel-variant": "info"
              },
              on: {
                ok: function($event) {
                  _vm.ackOld = true
                }
              }
            },
            [
              _c("p", [
                _c("strong", [
                  _vm._v(
                    "You are interacting with a record admitted more than "
                  ),
                  _c("em", [_vm._v(_vm._s(_vm.admittedSince) + " ago")]),
                  _vm._v(".")
                ])
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "This record will likely have conflicting information with a current patient's visit, such as ward, bed and speciality."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    'Click "Cancel" to choose another stay. Proceed only if you are '
                  ),
                  _c("em", [_vm._v("absolutely sure")]),
                  _vm._v(".")
                ])
              ]),
              _vm._v(" "),
              _c("em", [_vm._v("Note:")]),
              _vm._v(
                ' If you decide to continue, you will need to click "I understand", and then click on the stay again.\n    '
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.combined.pstay
        ? _c(
            "b-modal",
            {
              attrs: {
                id: _vm.consultModalId,
                title: "Consultation",
                "cancel-title": "Close",
                "ok-disabled": !_vm.canConsult(_vm.patientData),
                "ok-title": "Start",
                "ok-variant": "success"
              },
              on: {
                ok: function($event) {
                  return _vm.startConsult(_vm.patientData.patient, _vm.combined)
                }
              }
            },
            [
              _vm.canConsult(_vm.patientData)
                ? _c("p", { staticClass: "px-0" }, [
                    _vm._v(
                      "\n            Name: " +
                        _vm._s(_vm.formatPatient(_vm.patientData.patient))
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            MRN: " +
                        _vm._s(_vm.patientData.patient.mrn)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            Speciality: " +
                        _vm._s(_vm.combined.pstay.speciality_code)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            Admitted: " +
                        _vm._s(_vm.formatDT(_vm.combined.pstay.admission_time))
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            Discharged: " +
                        _vm._s(_vm.formatDT(_vm.combined.pstay.discharge_time))
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            Visit ID: " +
                        _vm._s(_vm.combined.pstay.ext_id) +
                        "\n        "
                    )
                  ])
                : _c("p", [
                    _vm._v(
                      "\n            There is currently an active stay/consultation in Scrawl for " +
                        _vm._s(_vm.formatPatient(_vm.patientData.patient)) +
                        " (MRN: " +
                        _vm._s(_vm.patientData.patient.mrn) +
                        "). Please use that record instead.\n        "
                    )
                  ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }



























import Vue from 'vue'
import utils from 'utils'
import stays from '@store/stays'

import { Stay } from 'models/data/stay'

import Background from '@admission/Background.vue'
import CardLapsible from '@shared/CardLapsible.vue'
import HistoricalMedications from '@admission/HistoricalMedications.vue'
import Hospital from './Hospital.vue'
import PosNegs from '@inputs/syndrome/PositivesNegatives.vue'
import Presentation from '@admission/Presentation.vue'
import SocialHistory from '@admission/SocialHistory.vue'
import Team from './Team.vue'


export default Vue.extend({
    components: {
        Background,
        CardLapsible,
        HistoricalMedications,
        Hospital,
        PosNegs,
        Presentation,
        SocialHistory,
        Team,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            teamHtmlId: `${uid}___adm_team`,
            teamVisible: true,
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        [
          _c(
            "b-dd-item",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.htmlId,
                  expression: "htmlId"
                }
              ]
            },
            [_vm._v("Beta Features")]
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                size: "lg",
                title: "Beta Features",
                id: _vm.htmlId,
                "ok-only": ""
              }
            },
            [
              _c("p", [_vm._v("List of features currently in beta:")]),
              _vm._v(" "),
              _c("ul", [
                _vm.showNurseReview
                  ? _c("li", [
                      _vm._v(
                        "\n                Nurse review data entry view. Accessed through "
                      ),
                      _c("em", [_vm._v("Nurse")]),
                      _vm._v(" link on home page.\n            ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("li", [
                  _vm._v("\n                Added "),
                  _c("em", [_vm._v("Free Text v2")]),
                  _vm._v(
                    " to toggle continuous save on/off for free text boxes.\n            "
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n                The sidebar can now be hidden/shown.\n            "
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v(
                    "\n                EMR notes can now be docked to the right during assessments.\n            "
                  )
                ]),
                _vm._v(" "),
                _vm.telestrokeMode
                  ? _c("li", [
                      _vm._v("\n                Access to "),
                      _c("em", [_vm._v("Review")]),
                      _vm._v(" assessment view.\n            ")
                    ])
                  : _vm._e()
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
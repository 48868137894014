import { find, flatMap, get, isNil } from 'lodash-es'
import { TextDetails } from './interfaces'
import store from 'store'


export function allCapsSnakeToUpperFirst(text: string): string {
    return text[0] + text.slice(1).toLowerCase().replace(/_/g, " ")
}

export function fieldText({ stay }: TextDetails, field: string): string {
    const text = get(stay, field)
    if (isNil(text)) return ""
    return freeTextSubHtmlBreaks(text)
}

/**
 * Replaces newlines with <br>
 * @param val text input from user
 */
export function freeTextSubHtmlBreaks(val: string): string {
    let text = val.replace(/(?:\r\n|\r|\n)/gm, "<br>")
    text = text.replace(/^(\d+)\./, '$1\\.')
    text = text.replace(/^-/, '\\-')
    return text
}

function getAttendeeName(attendeeID: number, attendeeType: string) {
    const teams = store.direct.state.templates.teams
    const attendees = flatMap(teams, attendeeType)
    const attendee = find(attendees, ['id', attendeeID])
    return attendee ? attendee.title : '?'
}

export function getDoctorName(doctorID: number) {
    return getAttendeeName(doctorID, 'doctors')
}

export function getTeamMemberName(memberID: number) {
    return getAttendeeName(memberID, 'members')
}

/**
 * Returns HTML-ready label & notes, that formats notes nicely.
 * A single-line note is presented in brackets.
 * Multi-line notes are presented as indented bullet points.
 * @param label 
 * @param notes 
 */
export function labelWithNotes(label: string, notes: string | null, labelNotesDelimiter?: string) {
    if (notes) {
        if (notes.indexOf('\n') > 0) {
            // Multiline notes
            notes = freeTextSubHtmlBreaks(notes.trim())
            // Add "  -" at the start of each line
            notes = notes.split('<br/>').map(line => '&nbsp;&nbsp;- ' + line).join('<br/>')
            return `${label}<br/>${notes}`
        }
        if (labelNotesDelimiter === undefined)
            labelNotesDelimiter = ' '
        return `${label}${labelNotesDelimiter}(${freeTextSubHtmlBreaks(notes.trim())})`
    }
    return `${label}`
}

/**
 * Returns HTML-ready text & notes, that formats notes nicely.
 * A single-line note is presented inline in brackets.
 * Multi-line notes are presented starting on the next line as indented bullet points.
 * 
 * **Note:** This should be a drop-in replacement for above labelWithNotes. Requires testing.
 */
export function textWithNotes(text: string, notes: string | null, textNotesDelimiter?: string): string {
    if (!notes)
        return text

    const trimmed = notes.trim()
    const notesHtml = freeTextSubHtmlBreaks(trimmed)

    // Multiline notes
    if (trimmed.indexOf('\n') > 0) {
        // Add "  -" at the start of each line
        notes = notesHtml.split('<br/>').map(line => '&nbsp;&nbsp;- ' + line).join('<br/>')
        return `${text}<br/>${notes}`
    }

    if (textNotesDelimiter === undefined)
        textNotesDelimiter = ' '

    return `${text}${textNotesDelimiter}(${notesHtml})`
}

export function replaceBreaks(str: string | null, newBreak?: string): string {
    if (!newBreak) newBreak = '<br/><br/>'
    // Replace line breaks (e.g. \r\n) with HTML breaks
    return str ? str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${newBreak}$2`) : ''
}












import Vue from 'vue'
import stays from '@store/stays'

export default Vue.extend({
    computed: {
        DRL_leaving: {
            get(): boolean {
                return this.$store.direct.state.session.DRL_leaving
            },
            set(leaving: boolean) {
                this.$store.direct.commit.session.DRL_leaving(leaving)
            }
        },
        DRL_debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        DRL_changesMaxAge(): number {
            return this.$store.direct.state.session.changesMaxAge
        },
        DRL_syncMonitorInterval(): number {
            return this.$store.direct.state.session.syncMonitorInterval
        },
        DRL_hasUnsavedChanges(): boolean {
            return stays.state.stayChanged
        },
    },
    beforeRouteLeave(to, from, next) {
        if (!this.DRL_hasUnsavedChanges) {
            next()
            return
        }
        this.DRL_leaving = true
        const pollMS = Math.ceil(this.DRL_syncMonitorInterval * 0.8)
        const maxTries = Math.ceil((this.DRL_changesMaxAge + 1000) / pollMS)
        let numTries = 0
        const timer = setInterval(() => {
            if (this.DRL_hasUnsavedChanges && numTries < maxTries) {
                numTries++
                if (this.DRL_debugMode)
                    this.$toasted.info(`Saving changes (${numTries})`)
                return
            }
            clearInterval(timer)
            this.DRL_leaving = false
            next()
        }, pollMS)
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("r-input", {
        attrs: {
          id: _vm.summaryInputId,
          stay_id: _vm.stay_id,
          field: _vm.summaryPropExtInfo,
          rows: 8,
          "aria-describedby": _vm.helpId
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import Vue from 'vue'
import stays from '@store/stays'
import { FiltersParams } from 'models/filters'

export default Vue.extend({
    data() {
        return {
            discharge: {
                showDischarged: false,
                period: '2w'
            }
        }
    },
    computed: {
        /**
         * *Note:* field `h` is always set to `all` to maintain the original expectations of how params affects filtering
         **/
        params(): Partial<FiltersParams> {
            const stayFilters = this.$store.direct.getters.user.stayFilters

            // Filter stays by discharge criteria
            const dischargeFilters: Partial<FiltersParams> = {
                sd: this.discharge.showDischarged,
                pd: this.discharge.period,
                d: stayFilters.doctors,
                dg: stayFilters.diagnoses,
                sp: stayFilters.specialities,
                spc: stayFilters.spaces,
                spm: 'strict',
                c: stayFilters.cares,
                h: stayFilters.hospitals,
            }

            return dischargeFilters
        },
        paramsStrict(): Partial<FiltersParams> {
            const stayFilters = this.$store.direct.getters.user.stayFiltersStrict

            // Filter stays by discharge criteria
            const dischargeFilters: Partial<FiltersParams> = {
                sd: this.discharge.showDischarged,
                pd: this.discharge.period,
                d: stayFilters.doctors,
                dg: stayFilters.diagnoses,
                dgs: true,
                sp: stayFilters.specialities,
                spc: stayFilters.spaces,
                c: stayFilters.cares,
                h: stayFilters.hospitals,
                hs: true,
            }

            return dischargeFilters
        },
        staysLoading(): boolean {
            return stays.state.staysLoading
        },
        loadStays(): number {
            return stays.state.loadStays
        },
    },
    watch: {
        loadStays() {
            this.pull()
        },
    },
    methods: {
        pull(): void {
            this.$_pull()
        },
        $_pull() {
            throw new Error('StaysFilterMixin.$_pull needs to be implemented by component.')
        }
    }
})

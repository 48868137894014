














import Vue from 'vue'

import { diagnosisFeatures, diagnosisKeyPoints, diagnosisAddNotes } from 'utils/text/diagnosis'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'

import CardLapsible from '@shared/CardLapsible.vue'
import EditDiagnosis from '@inputs/diagnosis/EditDiagnosis.vue'

export default Vue.extend({
    components: {
        CardLapsible,
        EditDiagnosis,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        diagnosis(): string {
            return this.stay?.diagnosis_title_ext || 'Nil'
        },
        keyPoints(): string {
            return diagnosisKeyPoints({ stay: this.stay })
        },
        features(): string {
            return diagnosisFeatures({ stay: this.stay })
        },
        addNotes(): string {
            return diagnosisAddNotes({ stay: this.stay })
        },
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-btn",
    {
      directives: [
        {
          name: "b-modal",
          rawName: "v-b-modal",
          value: _vm.uid,
          expression: "uid"
        }
      ],
      class: _vm.btnClasses,
      attrs: { variant: _vm.btnVariant, size: _vm.btnSize }
    },
    [
      _vm._t("text", function() {
        return [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(_vm._s(_vm.validText ? _vm.text : _vm.nilText))
          ])
        ]
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "content-class": { "scrwl-h-max": _vm.forceMaxH },
            id: _vm.uid,
            title: _vm.title,
            "ok-only": _vm.okOnly,
            size: _vm.size,
            "no-fade": _vm.noFade,
            scrollable: _vm.scrollable,
            "no-enforce-focus": true
          },
          on: {
            show: _vm.showHandler,
            hidden: _vm.hiddenHandler,
            ok: function($event) {
              return _vm.$emit("ok")
            }
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
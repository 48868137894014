import { render, staticRenderFns } from "./WardRound.vue?vue&type=template&id=d572b440&"
import script from "./WardRound.vue?vue&type=script&lang=ts&"
export * from "./WardRound.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d572b440')) {
      api.createRecord('d572b440', component.options)
    } else {
      api.reload('d572b440', component.options)
    }
    module.hot.accept("./WardRound.vue?vue&type=template&id=d572b440&", function () {
      api.rerender('d572b440', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/data-entry/views/ward/WardRound.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("h6", { staticClass: "subsection-title" }, [
          _vm._v(_vm._s(_vm.listTitle))
        ]),
        _vm._v(" "),
        _vm.investigations.length
          ? _c(
              "b-list-group",
              { attrs: { id: _vm.htmlID, role: "tablist" } },
              [
                _c(
                  "transition-group",
                  { attrs: { name: "fade" } },
                  [
                    _vm._l(_vm.investigations, function(info) {
                      return [
                        _c("investigation", {
                          key: _vm.getKey(info.inv),
                          attrs: {
                            "inv-info": info,
                            stay_id: _vm.stay_id,
                            section: "investigations",
                            accordionID: _vm.accordionID
                          }
                        })
                      ]
                    })
                  ],
                  2
                )
              ],
              1
            )
          : _c("p", [
              _c("em", [_vm._v("No " + _vm._s(_vm.listTitle.toLowerCase()))])
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
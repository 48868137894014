var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.opened
    ? _c("fnt-a-icon", {
        staticClass: "scrawl-updown",
        attrs: { icon: ["fas", "chevron-up"] }
      })
    : _c("fnt-a-icon", {
        staticClass: "scrawl-downup",
        attrs: { icon: ["fas", "chevron-down"] }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
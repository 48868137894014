import { forEach, get, includes } from 'lodash-es'
import { freeTextSubHtmlBreaks } from './helpers'
import { mdtDischargePlan } from './plan'
import { TextDetails } from './interfaces'
import { genericQuestionsText } from './question'
import { QuestionCategory } from 'models/med_templates/question'
import stays from '@store/stays'
import store from 'store'

export function mdtPresent({stay}: TextDetails): string {
    if (!stay) return ""

    const presentIDs = get(stay, "mdt.people_present") || []
    if (!presentIDs.length) return ""

    const people = store.direct.state.templates.mdtPeople
    let peoplePresent: string[] = []

    forEach(people, function(person) {
        if (includes(presentIDs, person.id)) {
            peoplePresent.push(person.title)
        }
    })

    if (!peoplePresent.length) return ""

    return "**Present:** " + peoplePresent.join(", ") + "\n\n"
}

export function mdtSummary({stay}: TextDetails): string {
    if (!stay) return ""

    const summary = get(stay, "mdt.summary")

    if (!summary) return ""

    return `**Summary of MDT discussion:**<br/>${freeTextSubHtmlBreaks(summary)}\n\n`
}

export { mdtDischargePlan as mdtDischargePlan }

export function mdtCYOAText({ stay }: TextDetails): string {
    if (!stay) return ''
    const text = genericQuestionsText({
        stay,
        category: QuestionCategory.MDT,
        getQuestionGroupsFrom: stays.getSpeciality(stay.id),
    })
    if (text.length) return text + '\n\n'
    return ''
}














import Vue from 'vue'
import Dropdown from "@shared/inputs/Dropdown.vue"

export default Vue.extend({
    components: {
        Dropdown
    },
    computed: {
        allSites() {
            return this.$store.direct.state.asap.asapMeta.sites
        },
        sites: {
            get(): string[] {
                return this.$store.direct.state.user.asapVizSites
            },
            set(sites: string[]) {
                this.$store.direct.dispatch.user.updatePush({ asapVizSites: sites })
            }
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.allowed
    ? _c(
        "b-tab",
        {
          attrs: { active: _vm.tab === "data-capture", title: "Data Capture" }
        },
        [
          _c("data-capture", { attrs: { stay_id: _vm.stay_id } }),
          _vm._v(" "),
          _c("div", { staticClass: "mb-4" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        _vm._l(_vm.tables, function(ref) {
          var title = ref.title
          var fields = ref.fields
          var textVersion = ref.textVersion
          return _c("data-table", {
            key: title,
            attrs: {
              title: title,
              fields: fields,
              "text-version": textVersion,
              data: _vm.data
            }
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
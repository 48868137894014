








import mixins from "vue-typed-mixins"
import { clone, isArray, isEqual } from "lodash-es"

import utils from 'utils'
import { PropExtInfo } from "models"
import { DisplayInfo } from "models/layout"
import { AnyPropLookup } from "models/schema"
import { FullSchema } from 'models/schema/stay'

import Eye from "./Eye.vue"
import RInput from "@inputs/Input.vue"
import DisplayMixin from "@mixins/Display.vue"
import InputMixin from "@mixins/Input.vue"
import textNihss from 'utils/text/nihss'

export default mixins(DisplayMixin, InputMixin).extend({
    components: {
        Eye,
        RInput,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        nihss_id: {
            type: Number,
            required: true,
        },
        field_name: {
            type: String,
            required: true,
            default: ""
        },
        title: {
            type: String,
            default: "Eye"
        }
    },
    data() {
        let section: keyof FullSchema = 'nihss_set'
        return {
            uid: utils.getUID(),
            section,
            normalField: `${this.field_name}_normal`,
            allNormalArray: [0, 0, 0, 0, 0, 0, 0, 0]
        }
    },
    computed: {
        schema(): AnyPropLookup | undefined {
            return this.getChildSchema(this.section)
        },
        normalPropExtInfo(): PropExtInfo {
            const display: DisplayInfo = {
                field: this.normalField,
                force_label: "Normal",
                booleanOptions: {
                    combined: true
                }
            }
            const propInfoList = this.schema
                ? this.makePropExtInfo_NIHSS(this.schema, [display], this.nihss_id)
                : []
            return propInfoList[0]
        },
        normalVal: {
            get(): boolean | null | undefined {
                return this.getArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, this.normalField)
            },
            set(val: boolean | null) {
                if (!utils.isEqual(this.normalVal, val))
                    this.setArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, this.normalField, val)
            }
        },
        fieldVal: {
            get(): number[] | null | undefined {
                return this.getArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, this.field_name)
            },
            set(val: number[] | null) {
                if (!utils.isEqual(this.fieldVal, val))
                    this.setArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, this.field_name, val)
            }
        },
        textOutput(): string | undefined {
            switch (this.field_name) {
                case "visual_fields":
                    return textNihss.visualFields(this.fieldVal || [])
                case "visual_inattention":
                    return textNihss.visualInattention(this.fieldVal || [])
                default:
                    return
            }
        }
    },
    watch: {
        normalVal(val) {
            if (val)
                this.fieldVal = clone(this.allNormalArray)
        },
        fieldVal(val) {
            if (isArray(val) && !isEqual(val, this.allNormalArray))
                this.normalVal = false
        }
    },
})


import {
    string as dstring, number as dnumber, boolean as dboolean,
    array as darray,
    nullable, optional, exact, inexact, guard
} from 'decoders'

import { Stage, SurgeryStage } from '../_enums'
import { CustomAnswer } from './answer'
import { Doctor } from './doctor'
import { Hospital } from 'models/med_templates/hospital'
import { Investigation } from "./investigation"
import { Issue, OtherIssue } from './issue'
import { Location } from './location'
import { ManagementPlan } from './mgmtplan'
import { 
    AcuteCareSummary, acuteCareSummary,
    NeuroSummary, neuroSummary,
    NeurologyFields, neurologyFields,
    StrokeMeds,
} from './neurology'
import { NIHSS, nihss } from './nihss'
import { Patient } from './patient'
import {
    LivingSituation, LivesWith, Mobility, Dependency, Employment,
    livingSituationDef, livesWithDef, mobilityDef, dependencyDef, employmentDef,
} from 'models/med_templates/social_history'
import { Attendees } from './team'
import { Thrombolysis } from './thrombolysis'
import { SyndromeFeature, SyndromePosNeg } from './syndrome'
import { WardRound } from './ward'

export interface DataCaptureStep {
    id: number
    title: string
    value: boolean | string | number | Date
}

interface CareDays {
    acute: number
    rehab: number
    maintenance: number
    pall: number
}

export interface DischargeMeds {
    correct: boolean | null
    title: string
}

export interface Stay {
    id: number
    /** the primary stay that this stay references */
    primary: number | null
    /** ISO-formatted datetime string */
    admission_time: string
    admission_summary: string | null
    /** ISO-formatted datetime string */
    discharge_time: string
    created_at: string
    active: boolean
    doctor: Doctor | number | null
    patient: Patient
    stage: Stage
    care_classification?: {
        classification: string
        date: string
        label: string
    }
    care_days?: CareDays
    days_in_stroke_unit?: number
    length_of_stay: number
    day: number | null
    day_from_create: number
    good?: boolean | null
    location: Location | null
    hospital: Hospital
    site_only: boolean
    /** speciality id derived from feed speciality, then the one derived from selected syndrome. can be overridden */
    speciality: number | null
    speciality_title: string
    /** the *real* speciality id derived from feed, then syndrome. never overridden */
    sp: number | null
    sp_title: string
    rapid_assessment_diagnosis: number | null
    rapid_assessment_diagnosis_summary: string | null
    rapid_assessment_diagnosis_title: string
    rapid_assessment_other_diagnosis_selected: boolean
    rapid_assessment_other_diagnosis_title: string | null
    rapid_asmt_discharge_time: string | null
    rapid_asmt_only: boolean
    rapid_assessment_plan_notes: string | null
    rapid_assessment_summary: string | null
    rapid_assessment_syndrome: number | null
    syndrome: number | null
    syndrome_features: SyndromeFeature[]
    syndrome_positive_negatives: SyndromePosNeg[]
    surgery_stage: SurgeryStage
    surgery_time: string | null
    surgery_pod: number | null
    dash_hide_qs: number[]
    diagnosis: number | null
    diagnosis_features_text: string | null
    diagnosis_notes: string | null
    diagnosis_summary: string | null
    /** displays the selected diagnosis' base title, or `other_diagnosis_title` if `other_diagnosis_selected` is true */
    diagnosis_title: string | null
    other_diagnosis_title: string | null
    other_diagnosis_selected: boolean
    /** if selected diagnosis has an alt_title, will display that. otherwise is set to the same thing as `diagnosis_title` */
    diagnosis_title_ext: string | null
    investigations: Investigation[]
    baseline_investigations: Investigation[]
    other_investigations: Investigation[]
    issues: Issue[]
    other_issues: OtherIssue[]
    other_issues_text: string | null
    adm_present?: Attendees
    mdt: {
        complete: boolean
        created_at: string
        emr_notes: string | null
        people_present: number[]
        summary: string | null
    }
    admissionexam?: { [k: string]: any }
    data_capture_steps: DataCaptureStep[]
    discharge?: { [k: string]: any }
    management_plan?: ManagementPlan
    presentation?: {
        notes: string | null
        summary: string | null
        last_seen_well: 'UNKNOWN' | 'LT_24_HOURS' | 'WAKE_UP' | 'GT_24_HOURS' | null
        lsw_time: string | null
        [k: string]: any
    }
    thrombolysis?: Thrombolysis
    background?: {
        updated_at: string
        notes: string | null
    }
    historical_meds?: {
        updated_at: string
        other_meds: string | null
        allergies: string | null
    }
    current_meds: number[] | null
    current_meds_updated: string
    other_current_meds: string | null
    social_history: {
        updated_at: string
        living_situation: LivingSituation | null
        lives_with: LivesWith | null
        mobility_status: Mobility | null
        mobility_requires_assistance: boolean | null
        wash_dress_toilet: Dependency | null
        cook_finance_shop: Dependency | null
        driving: boolean | null
        employment: Employment | null
        notes: string | null
    }
    triage?: { [k: string]: any }
    _answers: { [k: string]: CustomAnswer }
    admission_nihss?: NIHSS
    ward_round?: WardRound
    ward_round_nihss?: NIHSS | null
    rapid_asmt_nihss?: NIHSS | null
    nihss_set: NIHSS[]
    neurology_fields?: NeurologyFields
    /** acute care summary, for neurology */
    acute_care_summary?: AcuteCareSummary | null
    /** neurology summary, for dashboard */
    neuro_summary?: NeuroSummary | null
    discharge_medications?: DischargeMeds | null
    question_groups: number[]
    stroke_medications: StrokeMeds | null
    print_text: string | null
}

const stay = inexact({
    id: dnumber,
    active: dboolean,
    acute_care_summary: optional(nullable(acuteCareSummary)),
    neuro_summary: optional(nullable(neuroSummary)),
    neurology_fields: optional(neurologyFields),
    admission_nihss: optional(nihss),
    discharge_medications: optional(nullable(exact({
        correct: nullable(dboolean),
        title: dstring
    }))),
    care_days: optional(exact({
        acute: dnumber,
        rehab: dnumber,
        maintenance: dnumber,
        pall: dnumber,
    })),
    day: nullable(dnumber),
    day_from_create: dnumber,
    diagnosis_title: nullable(dstring),
    other_diagnosis_title: nullable(dstring),
    other_diagnosis_selected: dboolean,
    diagnosis_title_ext: nullable(dstring),
    mdt: inexact({
        complete: dboolean,
        created_at: dstring,
        emr_notes: nullable(dstring),
        people_present: darray(dnumber),
        summary: nullable(dstring),
    }),
    nihss_set: darray(nihss),
    primary: nullable(dnumber),
    rapid_asmt_discharge_time: nullable(dstring),
    rapid_asmt_only: dboolean,
})

export const stayDecoder = guard(stay, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _stay: Partial<Stay> = guard(stay)({}) } catch(e) {}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group-item",
    { staticClass: "condensed", attrs: { role: "tabpanel" } },
    [
      _vm._v("\n    " + _vm._s(_vm.name) + ":\n    "),
      _vm.formatted
        ? _c("pre", [_vm._v(_vm._s(_vm.json))])
        : _c("span", [_vm._v(_vm._s(_vm.json))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }











import Vue from 'vue'
import utils from 'utils'

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        stay_type: {
            type: String as () => 'pstay' | 'stay',
            required: true
        },
        add_mb: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        canUseSandbox(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        visible(): boolean {
            return this.canUseSandbox && this.$store.direct.state.session.ux_sandbox_to !== ''
        },
    },
    watch: {
        visible: {
            immediate: true,
            handler: function(val: boolean) {
                this.$emit('sandbox-btn-visible', val)
            }
        }
    },
    methods: {
        transferToSandbox() {
            utils.request.post(`/feeds/transfer_out/${this.stay_type}/${this.stay_id}/`)
            .then(res => {
                const targetUrl: string = res.body.frontend_url
                if (targetUrl)
                    window.location.href = targetUrl
                else
                    this.$toasted.info('Stay info transferred to sandbox. You will need to visit that instance manually.')
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
        },
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group-item",
    {
      class: { "r-clickable": _vm.performed, collapsed: _vm.opened },
      attrs: {
        id: _vm.toggleId,
        role: "tab",
        "aria-controls": _vm.toggleTargetId,
        "aria-expanded": _vm.opened ? "true" : "false"
      },
      on: { click: _vm.handleExpand }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col" },
          _vm._l(_vm.choices, function(choice) {
            return _c(
              "div",
              { key: choice.id, staticClass: "pretty p-svg p-plain p-bigger" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.performed,
                      expression: "performed"
                    }
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: choice.value,
                    checked: _vm._q(_vm.performed, choice.value)
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    },
                    change: function($event) {
                      _vm.performed = choice.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "state p-info-o" },
                  [
                    _c("fnt-a-icon", {
                      staticClass: "svg",
                      attrs: { icon: ["fal", "check"] }
                    }),
                    _vm._v(" "),
                    _c("label", [_vm._v(_vm._s(choice.label))])
                  ],
                  1
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _vm._v(_vm._s(_vm.invInfo.schema.title))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("span", { staticStyle: { "white-space": "pre-wrap" } }, [
            _vm._v(_vm._s(_vm.resultsText))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("textarea", { attrs: { id: _vm.cHtmlID } }),
      _vm._v(" "),
      _vm.debugMode
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("h5", [_vm._v("Markdown")]),
              _vm._v(" "),
              _c("pre", [_vm._v(_vm._s(_vm.finalMarkup))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("h5", [_vm._v("HTML (browser)")]),
              _vm._v(" "),
              _c("pre", [_vm._v(_vm._s(_vm.finalHTML))])
            ]),
            _vm._v(" "),
            _vm._m(0)
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.printGuideHtmlId,
            title: "Print Help",
            size: "lg",
            "ok-only": "",
            scrollable: ""
          }
        },
        [
          _c("h5", [_vm._v("Print headers & footers")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "To avoid having page headers and footers in the final output:"
            )
          ]),
          _c("p"),
          _c("h6", [_c("u", [_vm._v("Edge, Chrome, Firefox")])]),
          _vm._v(" "),
          _c("p", [
            _vm._v("After clicking "),
            _c("strong", [_vm._v("Print")]),
            _vm._v(", expand "),
            _c("em", [_vm._v("More settings")]),
            _vm._v(" and make sure "),
            _c("em", [_vm._v("Headers and footers")]),
            _vm._v(" is unchecked.")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-start justify-content-center mb-5"
            },
            [
              _vm.isIE11
                ? [
                    _c("div", { staticClass: "img-30 mr-5" }, [
                      _c("img", {
                        attrs: {
                          src: "static/images/expand-more-settings.jpg",
                          alt: "Expand 'More settings'"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "img-30" }, [
                      _c("img", {
                        attrs: {
                          src: "static/images/uncheck-headers-footers.jpg",
                          alt: "Uncheck 'Headers and footers'"
                        }
                      })
                    ])
                  ]
                : [
                    _c("img", {
                      staticClass: "img-30 mr-5",
                      attrs: {
                        src: "static/images/expand-more-settings.jpg",
                        alt: "Expand 'More settings'"
                      }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "img-30",
                      attrs: {
                        src: "static/images/uncheck-headers-footers.jpg",
                        alt: "Uncheck 'Headers and footers'"
                      }
                    })
                  ]
            ],
            2
          ),
          _vm._v(" "),
          _c("h6", [_c("u", [_vm._v("Internet Explorer 11")])]),
          _vm._v(" "),
          _c("p", [
            _c("em", [_vm._v("Note:")]),
            _vm._v(
              " If print output is not appearing as expected, please try printing in one of the above browsers instead."
            )
          ]),
          _vm._v(" "),
          _c("p", [
            _c("em", [_vm._v("Before")]),
            _vm._v(" clicking "),
            _c("strong", [_vm._v("Print")]),
            _vm._v(", open the browser's "),
            _c("em", [_vm._v("Tools")]),
            _vm._v(" menu in the top right. Navigate to "),
            _c("em", [_vm._v("Print")]),
            _vm._v(" > "),
            _c("em", [_vm._v("Print Setup")]),
            _vm._v(". Make sure all "),
            _c("em", [_vm._v("Headers")]),
            _vm._v(" and "),
            _c("em", [_vm._v("Footers")]),
            _vm._v(" are empty.")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-start justify-content-center mb-5"
            },
            [
              _vm.isIE11
                ? [
                    _c("div", { staticClass: "img-30 mr-5" }, [
                      _c("img", {
                        attrs: {
                          src: "static/images/ie11-page-setup.jpg",
                          alt: "Expand 'More settings'"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "img-30" }, [
                      _c("img", {
                        attrs: {
                          src: "static/images/ie11-headers-footers-empty.jpg",
                          alt: "Uncheck 'Headers and footers'"
                        }
                      })
                    ])
                  ]
                : [
                    _c("img", {
                      staticClass: "img-45 mr-5",
                      attrs: {
                        src: "static/images/ie11-page-setup.jpg",
                        alt: "Expand 'More settings'"
                      }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "img-45",
                      attrs: {
                        src: "static/images/ie11-headers-footers-empty.jpg",
                        alt: "Uncheck 'Headers and footers'"
                      }
                    })
                  ]
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6" }, [
      _c("h5", [_vm._v("HTML (backend)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
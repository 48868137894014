import { Item, OrderedItem, itemDef, orderedItemDef } from "../base"
import { InvestigationStatus, InvestigationStatus_values } from 'models/med_templates/enums'
import { array, string as dstring, number as dnumber, boolean as dboolean, exact, oneOf, guard } from 'decoders'


export interface InvLabel extends OrderedItem {
    status: InvestigationStatus
}

export interface InvestigationResult extends Item {
    field_id: string
}

export interface Investigation extends OrderedItem {
    group: number
    results: InvestigationResult[]
    enabled: boolean
}

export interface InvestigationGroup extends OrderedItem {
    investigations: Investigation[]
    speciality: number
}

export const invLabel = exact({
    ...orderedItemDef,
    status: oneOf(InvestigationStatus_values)
})

export const invResult = exact({
    ...itemDef,
    field_id: dstring,
})

export const investigation = exact({
    ...orderedItemDef,
    group: dnumber,
    results: array(invResult),
    enabled: dboolean,
})

export const invGroup = exact({
    ...orderedItemDef,
    investigations: array(investigation),
    speciality: dnumber,
})

export const invGroups = array(invGroup)

export const invGroupsDecoder = guard(invGroups, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _invLabel: InvLabel = guard(invLabel)({}) } catch(e) {}
try { const _invResult: InvestigationResult = guard(invResult)({}) } catch(e) {}
try { const _investigation: Investigation = guard(investigation)({}) } catch(e) {}
try { const _invGroup: InvestigationGroup = guard(invGroup)({}) } catch(e) {}

// Advanced investigations field definitions

export const NonContrastCTBrain_v2 = {
    findings: ['normal', 'aih', 'ai'],
    sides: ['right', 'left'],
    aih_main: ['aih_main_subarach', 'aih_main_subdural', 'aih_main_int_cereb'],
    aih_ic: ['aih_ic_basal', 'aih_ic_lobar', 'aih_ic_brainstem', 'aih_ic_cerebellum'],
    aih_add: ['aih_add_subarach', 'aih_add_subdural', 'aih_add_int_cereb', 'aih_add_int_vent'],
    ai: ['ai_cortical', 'ai_subcortical', 'ai_brainstem', 'ai_cerebellum'],
}

export const CTPerfusion_v2 = {
    findings: ['normal', 'apl', 'unclear'],
    sides: ['right', 'left'],
    apl_sites: ['apl_anterior', 'apl_middle', 'apl_posterior', 'apl_vert'],
    quality: ['qual_good', 'qual_limited'],
}

export const CTAngiography_v2 = {
    right_artery: ['no_sten_r', 'maj_sten_r', 'all_oc_r'],
    left_artery: ['no_sten_l', 'maj_sten_l', 'all_oc_l'],
    io: ['io_no', 'io_yes'],
    sia: ['sia_no', 'sia_yes'],
    io_sides: ['io_right', 'io_left', 'io_basilar'],
    io_sites: ['io_intra_car', 'io_m1_mid_ca', 'io_m2_mid_ca', 'io_dist_mca', 'io_ant_ca', 'io_post_ca', 'io_vca'],
    sia_sides: ['sia_right', 'sia_left', 'sia_basilar'],
    sia_sites: ['sia_intra_car', 'sia_m1_mid_ca', 'sia_m2_mid_ca', 'sia_dist_mca', 'sia_ant_ca', 'sia_post_ca', 'sia_vca']
}

export const MRIBrain_v2 = {
    findings: ['normal', 'aih', 'ai'],
    sides: ['right', 'left'],
    aih_main: ['aih_main_subarach', 'aih_main_subdural', 'aih_main_int_cereb'],
    aih_ic: ['aih_ic_basal', 'aih_ic_lobar', 'aih_ic_brainstem', 'aih_ic_cerebellum'],
    aih_add: ['aih_add_subarach', 'aih_add_subdural', 'aih_add_int_cereb', 'aih_add_int_vent'],
    ai: ['ai_cortical', 'ai_subcortical', 'ai_brainstem', 'ai_cerebellum'],
}


































































import mixins from 'vue-typed-mixins'
import StaysMixin from '../mixins/Stays.vue'
import { each, filter, find, includes, map, orderBy, uniqBy } from 'lodash-es'
import { Stay } from 'models/data/stay'
import { FilterBy, TSScope } from 'models/filters'
import { StayType } from 'models/meta'
import StayDetails from './Stay.vue'

export default mixins(StaysMixin).extend({
    components: {
        StayDetails,
    },
    props: {
        stayType: {
            type: String as () => StayType,
            default: 'primary' as StayType
        },
        displayContext: {
            type: String as () => FilterBy,
            default: () => 'doc' as FilterBy
        },
        tsScope: {
            type: String as () => TSScope,
            default: TSScope.Telestroke
        },
        showSite: {
            type: Boolean,
            default: false,
        },
        showLocation: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showExtIds: false,
            actionsVisible: {} as { [key: number]: boolean },
        }
    },
    computed: {
        betaEnabled(): boolean {
            return this.$store.direct.getters.user.betaEnabled
        },
        fullMode(): boolean {
            return this.$store.direct.getters.user.fullMode
        },
        triageMode(): boolean {
            return this.$store.direct.state.user.triage_mode
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        showSiteAndLocation(): boolean {
            return this.showSite && this.showLocation
        },
        showSpeciality: {
            get(): boolean {
                return this.$store.direct.state.session.homeShowSpeciality
            },
            set() {
                this.$store.direct.commit.session.toggleHomeShowSpeciality()
            }
        },
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        isDeveloper(): boolean {
            return this.$store.direct.getters.user.isDeveloper
        },
        showPrintHeader(): boolean {
            return this.stayType === 'primary'
        },
        locationHeading(): string {
            if (this.showSiteAndLocation)
                return 'Site/Location'
            if (this.showSite)
                return 'Site'
            return 'Location'
        },
        timeHeading(): string {
            return this.telestrokeMode && this.tsScope === TSScope.Telestroke ? 'Created at' : 'LoS (days)'
        },
        manySpecialities(): boolean {
            return this.$store.direct.state.user.filters.specialities.length > 1
        },
        filteredStays(): Stay[] {
            let stays = this.stays

            switch (this.stayType) {
                case 'primary':
                    stays = this.primaryStays
                    break
                case 'secondary':
                    stays = this.secondaryStays
                    break
            }

            if (this.telestrokeMode) {
                const hospitals = this.$store.direct.state.user.filters.hospitals
                if (hospitals.length) {
                    stays = filter(this.stays, stay => {
                        if (!stay.hospital) return true
                        const hospitalId = stay.hospital.id
                        return includes(hospitals, hospitalId)
                    })
                }
            }

            return stays
        },
        orderedStays(): Stay[] {
            const stays = this.filteredStays
            let sortBy = ['location.ward', 'location.room', 'location.bed']
            let sortOrder: 'asc' | 'desc' = 'asc'
            if (this.telestrokeMode) {
                if (this.tsScope === TSScope.Telestroke) {
                    sortBy = ['created_at']
                    sortOrder = 'desc'
                }
            }
            return orderBy(stays, sortBy, sortOrder)
        },
        advOrderedStays(): Stay[] {
            const orderedStays = this.orderedStays
            let reorderedStays: Stay[] = []
            const specialityIds = this.$store.direct.getters.templates.specialityIds

            each(specialityIds, spId => {
                const speciality = this.$store.direct.getters.templates.speciality(spId)
                if (speciality) {
                    const locations: string[] = speciality.location_order.split(',')
                    each(locations, locDef => {
                        const wardBed = locDef.trim().split('-')
                        if (wardBed.length === 1) {
                            const ward = wardBed[0]
                            const stays: Stay[] = filter(orderedStays, stay => (!!stay.location && stay.location.ward === ward))
                            reorderedStays.push(...stays)
                        }
                        else if (wardBed.length === 2) {
                            const ward = wardBed[0], bed = wardBed[1]
                            const stay: Stay | undefined = find(orderedStays, stay => (!!stay.location && stay.location.ward === ward && stay.location.bed === bed))
                            if (stay)
                                reorderedStays.push(stay)
                        }
                    })
                }
            })

            if (reorderedStays.length === 0)
                return orderedStays

            const reorderedIds = map(reorderedStays, 'id')
            const otherStays = filter(orderedStays, stay => !includes(reorderedIds, stay.id))
            reorderedStays.push(...otherStays)
            reorderedStays = uniqBy(reorderedStays, 'id')

            return reorderedStays
        },
        visibleStays(): Stay[] {
            let visibles: Stay[]

            if (this.shouldHide) {
                const hiddenIDs = this.$store.direct.state.user.hidden_stays
                visibles = filter(this.advOrderedStays, stay => !includes(hiddenIDs, stay.id))
            }
            else
                visibles = this.advOrderedStays

            this.$emit('visible-stays', visibles)
            return visibles
        },
        /** A list of the visible stay IDs to pass to the print list */
        stayIDs(): number[] {
            const stayIDs = map(this.visibleStays, stay => stay.id)
            return stayIDs
        },
        someActionsVisible(): boolean {
            let visible = false
            each(this.visibleStays, stay => {
                if (this.actionsVisible[stay.id]) {
                    visible = true
                    return false  // break iteration early
                }
                return true
            })
            return visible
        },
    },
    methods: {
        toggleHomeStayIds() {
            this.$store.direct.dispatch.user.toggleHomeStayIds()
        },
        toggleShowExtIds() {
            this.showExtIds = !this.showExtIds
        },
        updateActionsVisible(event: any) {
            this.$set(this.actionsVisible, event.stay_id, event.visible)
        }
    },
})

import { filter, find, forEach, includes, map } from 'lodash-es'
import { TextDetails } from './interfaces'
import { Stay } from 'models/data/stay'
import { Diagnosis } from 'models/med_templates/diagnosis'
import store from 'store'


function mgmtPlanText(stay: Stay, diagnosis: Diagnosis): string {
    if (!stay.management_plan) return ''

    const steps = stay.management_plan.steps
    const plan = diagnosis.management_plan

    const lines: string[] = []

    forEach(plan, function(section) {
        const checkedSteps = filter(section.steps, function(step) {
            return includes(steps, step.id)
        })

        if (checkedSteps.length) {
            const title = section.title
            const stepsText = map(checkedSteps, function(step) { return step.title })
            const text = `**${title}**<br/>${stepsText.join("<br/>")}`
            lines.push(text)
        }
    })

    if (lines.length)
        return lines.join("\n\n") + "\n\n"

    return ""
}

export function managementPlan({ stay }: TextDetails): string {
    if (!stay) return ''

    const diagnosis = find(store.direct.getters.templates.allDiagnoses, ["id", stay.diagnosis])
    if (!diagnosis) return ""

    return mgmtPlanText(stay, diagnosis)
}

export function RAMgmtPlan({ stay }: TextDetails): string {
    if (!stay) return ""

    const syndrome = find(store.direct.getters.templates.rapidAsmtSyndromes, ["id", stay.rapid_assessment_syndrome])
    if (!syndrome) return ""

    const diagnosis = find(syndrome.diagnoses, ["id", stay.rapid_assessment_diagnosis])
    if (!diagnosis) return ""

    return mgmtPlanText(stay, diagnosis)
}

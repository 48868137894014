








































































import mixins from 'vue-typed-mixins'
import DisplayMixin from '@mixins/Display.vue'
import NihssMixin from '@mixins/NIHSS.vue'
import { filter, find, forEach, includes, isNil } from 'lodash-es'
import utils from 'utils'

import stays from '@store/stays'
import { NIHSS } from 'models/data/nihss'
import { Stay } from 'models/data/stay'
import { OrderedQuestion, QuestionCategory, QuestionGroup, QuestionGroupContainer } from 'models/med_templates/question'

import AddQuestionGroups from './AddQuestionGroups.vue'
import Question from './Question.vue'
import Nihss from '@sections/nihss/NIHSS.vue'
import NihssSelect from '@sections/nihss/v2/NIHSSSelect.vue'

export default mixins(DisplayMixin, NihssMixin).extend({
    components: {
        AddQuestionGroups,
        Question,
        Nihss,
        NihssSelect,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        category: {
            type: String as () => QuestionCategory,
            required: true,
        },
        /** if false, groups are always visible. if true, user can choose which groups to show. */
        editVisibleGroups: {
            type: Boolean,
            default: false,
        },
        defaultGroupIDs: {
            type: Array as () => number[],
        },
        parentObject: {
            type: Object as () => QuestionGroupContainer
        },
        isRapidAssessment: {
            type: Boolean,
            default: false,
            required: false,
        },
        questionClass: {
            type: String,
            default: '',
            required: false,
        },
        /** optional text for describing the section */
        addText: {
            type: String,
            default: 'sections',
        },
        inline: {
            type: Boolean,
            default: true,
        },
        getAnswer: {
            type: Function,
            required: false,
        },
        setAnswer: {
            type: Function,
            required: false,
        },
        firstItemTopBorder: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            nihssModalId: `${uid}___nihss`,
        }
    },
    computed: {
        nihssV1(): boolean {
            return this.$store.direct.state.session.nihss_version === 'v1'
        },
        modalID(): string {
            return [this.stay_id, this.category].join('-')
        },
        specialityQuestionGroups(): QuestionGroup[] {
            const speciality = stays.getSpeciality(this.stay_id)
            if (!speciality) return []
            return filter(speciality.question_groups, (group) => {
                return group.category === this.category && this.defaultGroupIDs.indexOf(group.id) === -1
            })
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        isNeurologyStay(): boolean {
            if (!this.stay) return false
            return this.stay.speciality_title.toLowerCase().trim() === 'neurology'
        },
        smallGroupTitle(): boolean {
            return this.category == QuestionCategory.WARD_EXAM
        },
        questionGroups(): QuestionGroup[] {
            if (!this.parentObject) return []
            const questionGroups = filter(
                this.parentObject.question_groups,
                { category: this.category }
            )

            if (this.editVisibleGroups) {
                if (!this.stay) return []

                // Get default groups in the order specified (if they exist)
                let defaultGroups: QuestionGroup[] = []
                forEach(this.defaultGroupIDs, (id: number) => {
                    const group = find(questionGroups, { id })
                    if (group) defaultGroups.push(group)
                })

                // Get non-default QuestionGroups associated with a stay
                const stayQuestionGroupIDs = this.stay.question_groups
                const stayQuestionGroups = filter(questionGroups, (group) => {
                    return !includes(this.defaultGroupIDs, group.id) && includes(stayQuestionGroupIDs, group.id)
                })

                return defaultGroups.concat(stayQuestionGroups)
            }
            return questionGroups
        },
        rapidAsmtNIHSS(): NIHSS | null | undefined {
            return this.stay?.rapid_asmt_nihss
        },
        rapidAsmtNihssComplete(): boolean {
            return this.rapidAsmtNIHSS?.complete || false
        },
        rapidAsmtNihssScore(): number | string {
            return this.rapidAsmtNIHSS?.score || '?'
        },
    },
    methods: {
        showQuestion(question: OrderedQuestion): boolean {
            const showQuestionID = question.show_if
            if (isNil(showQuestionID)) return true
            let answer: any
            if (this.getAnswer) {
                answer = this.getAnswer(this.stay_id, showQuestionID)
                if (answer)
                    answer = answer.answer
            } else {
                answer = stays.getAnswer(this.stay_id, showQuestionID)
            }
            return answer
        },
        showNIHSS(group: QuestionGroup): boolean {
            return this.isRapidAssessment && group.title?.toLowerCase() === "today's review"
        },
    },
})






























































































import mixins from 'vue-typed-mixins'
import { NonContrastCTBrain_v2 } from 'models/med_templates/investigation'
import AdvInvMixin from './AdvInvMixin.vue'

interface Selection {
    findings: number | null
    sides: number[]
    aih_main: number | null
    aih_ic: number | null
    aih_add: number[]
    ai: number[]
}

export default mixins(AdvInvMixin).extend({
    data() {
        const selection: Selection = {
            findings: null,
            sides: [],
            aih_main: null,
            aih_ic: null,
            aih_add: [],
            ai: [],
        }

        return {
            fieldIds: NonContrastCTBrain_v2,
            selection,
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        /** forcing type on selection, since AdvInvMixin's selection as object confuses typing system */
        selectionT(): Selection {
            return this.selection
        },
        sideVisible(): boolean {
            switch (this.selectionT.findings) {
                case this.fieldToIdMap['aih']:
                case this.fieldToIdMap['ai']:
                    return true
                default:
                    return false
            }
        },
        aihVisible(): boolean {
            return this.selectionT.findings === this.fieldToIdMap['aih']
        },
        intraCerebralVisible(): boolean {
            return this.selectionT.aih_main === this.fieldToIdMap['aih_main_int_cereb']
        },
        aiVisible(): boolean {
            return this.selectionT.findings === this.fieldToIdMap['ai']
        },
        localResults(): number[] {
            let results: number[] = []
            if (this.selectionT.findings) results.push(this.selectionT.findings)
            results = results.concat(this.selectionT.sides)
            if (this.selectionT.aih_main) results.push(this.selectionT.aih_main)
            if (this.selectionT.aih_ic) results.push(this.selectionT.aih_ic)
            results = results.concat(this.selectionT.aih_add)
            results = results.concat(this.selectionT.ai)
            return results
        },
    },
    watch: {
        'selection.findings'(val: number | null) {
            switch(val) {
                case this.fieldToIdMap['normal']:
                    this.selectionT.sides = []
                    this.selectionT.aih_main = null
                    this.selectionT.aih_ic = null
                    this.selectionT.aih_add = []
                    this.selectionT.ai = []
                    break

                case this.fieldToIdMap['aih']:
                    this.selectionT.ai = []
                    break

                case this.fieldToIdMap['ai']:
                    this.selectionT.aih_main = null
                    this.selectionT.aih_ic = null
                    this.selectionT.aih_add = []
                    break
            }
        },
        'selection.aih_main'(val: number | null) {
            if (val !== this.fieldToIdMap['aih_main_int_cereb'])
                if (this.selectionT.aih_ic)
                    this.selectionT.aih_ic = null
        },
    },
    methods: {
        clearResults() {
            // clear order is important - first clear values that are not watched
            this.selectionT.sides = []
            this.selectionT.aih_ic = null
            this.selectionT.aih_main = null
            this.selectionT.aih_add = []
            this.selectionT.ai = []
            this.selectionT.findings = null
        }
    }
})








import Vue from "vue"
import stays from "@store/stays"

export default Vue.extend({
    computed: {
        btnText() {
            return stays.state.monitor ? "Unmonitor" : "Monitor"
        },
        btnVariant(): string {
            return stays.state.monitor ? "success" : "secondary"
        }
    },
    methods: {
        toggleMonitor() {
            stays.mutMonitor(!stays.state.monitor)
        }
    },
})



































import Vue from "vue"

import AdmissionText from "@text/Admission.vue"
import Baselines from "../sections/investigations/Baselines.vue"
import ClinicHistory from "../sections/ClinicHistory.vue"
import DataCaptureTab from "./DataCaptureTab.vue"
import Examination from '../sections/Examination.vue'
import Generic from "../sections/Generic.vue"
import Management from "../sections/Management.vue"

export default Vue.extend({
    components: {
        AdmissionText,
        Baselines,
        ClinicHistory,
        DataCaptureTab,
        Examination,
        Generic,
        Management,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        tab: {
            type: String,
            default: ''
        },
        notesDocked: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
    },
    mounted() {
        this.$emit('show-notes-btn')
    },
})

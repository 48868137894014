


























import Vue from 'vue'
import stays from "@store/stays"
import { InvSection, BaseInvInfo } from "models/data/investigation"

export default Vue.extend({
    props: {
        accordionID: String,
        toggleTargetId: {
            type: String,
            required: true
        },
        opened: {
            type: Boolean,
            required: true
        },
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String as () => InvSection,
            required: true
        },
        invInfo: {
            type: Object as () => BaseInvInfo,
            required: true
        },
        resultsText: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            toggleId: `${this.toggleTargetId}_toggle`,
            choiceHtmlBase: `${this.toggleTargetId}_done`,
            choices: [
                { id: 'no', value: false, label: 'No' },
                { id: 'yes', value: true, label: 'Yes' }
            ],
            performed: this.invInfo.inv && this.invInfo.inv.performed !== undefined ? this.invInfo.inv.performed : null,
        }
    },
    methods: {
        toggleCollapse() {
            this.$root.$emit('bv::toggle::collapse', this.toggleTargetId)
        },
        handleExpand() {
            if (this.performed) this.toggleCollapse()
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        hasInv(): boolean {
            return this.invInfo.inv !== undefined
        },
        isToggleDisabled(): boolean {
            return !this.hasInv || !this.performed
        }
    },
    watch: {
        performed(val: boolean | null) {
            if (!this.hasInv)
               stays.extActAddInv(this.stay_id, InvSection.BASELINE, { investigation: this.invInfo.schema.id, performed: val })
            else
                stays.extActUpdateInv(this.stay_id, this.invInfo.inv!, this.section,
                    { performed: val })

            if (val && !this.opened) {
                this.toggleCollapse()
            }
            if (!val && this.opened) {
                this.toggleCollapse()
            }
        }
    }
})

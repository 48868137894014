




import Vue from 'vue'
import stays from '@store/stays'

export default Vue.extend({
    data() {
        return {
            componentKey: 0
        }
    },
    watch: {
        $route: {
            handler: function(to, from) {
                this.componentKey++
            }
        }
    },
    beforeCreate() {
        stays.mutSetUpdateURL('/asap/asmt_data/')
        // stays.mutMonitor(false)
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { class: _vm.colClass },
      [
        _c(
          "b-form-group",
          { attrs: { label: "Clinical Phase", "label-class": "pb-0" } },
          [
            _c(
              "b-dd",
              {
                staticClass: "scrawl-scrollable-dropdown w-100",
                attrs: {
                  variant: "primary",
                  "toggle-class": "text-truncate",
                  text: _vm.selectedText
                }
              },
              [
                _vm._l(_vm.syndromes, function(syndrome) {
                  return syndrome.diagnoses.length
                    ? [
                        _c("b-dd-text", { key: "syn_" + syndrome.id }, [
                          _c("em", [_vm._v(_vm._s(syndrome.title))])
                        ]),
                        _vm._v(" "),
                        _vm._l(syndrome.diagnoses, function(diagnosis) {
                          return _c(
                            "b-dd-item-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !diagnosis.hide ||
                                    _vm.isSelected(diagnosis.id),
                                  expression:
                                    "!diagnosis.hide || isSelected(diagnosis.id)"
                                }
                              ],
                              key: "dg_" + diagnosis.id,
                              staticClass: "ml-2",
                              class: { active: _vm.isSelected(diagnosis.id) },
                              on: {
                                click: function($event) {
                                  return _vm.toggleSelect(diagnosis.id)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    diagnosis.alt_title || diagnosis.title
                                  ) +
                                  "\n                        "
                              ),
                              _vm.isSelected(diagnosis.id)
                                ? _c("fnt-a-icon", {
                                    attrs: { icon: ["fal", "check"] }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      ]
                    : _vm._e()
                }),
                _vm._v(" "),
                _c("b-dd-divider"),
                _vm._v(" "),
                _c(
                  "b-dd-item-btn",
                  {
                    class: { active: _vm.isSelected(_vm.otherDiagnosis.id) },
                    on: {
                      click: function($event) {
                        return _vm.toggleSelect(_vm.otherDiagnosis.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.otherDiagnosis.title) +
                        "\n                    "
                    ),
                    _vm.isSelected(_vm.otherDiagnosis.id)
                      ? _c("fnt-a-icon", { attrs: { icon: ["fal", "check"] } })
                      : _vm._e()
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: _vm.colClass },
      [
        _vm.otherDiagnosisSelected
          ? _c(
              "b-form-group",
              {
                attrs: { label: "Other diagnosis title", "label-class": "p-0" }
              },
              [
                _c("b-input", {
                  attrs: { stay_id: _vm.stay_id },
                  model: {
                    value: _vm.otherDiagnosisTitle,
                    callback: function($$v) {
                      _vm.otherDiagnosisTitle = $$v
                    },
                    expression: "otherDiagnosisTitle"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }





























import mixins from 'vue-typed-mixins'

import stays from "@store/stays"
import { InvSection, InvInfo } from "models/data/investigation"

import Checkbox from '@shared/inputs/Checkbox.vue'
import InputMixin from '@mixins/Input.vue'

export default mixins(InputMixin).extend({
    components: {
        Checkbox
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String as () => InvSection,
            required: true
        },
        invInfo: {
            type: Object as () => InvInfo,
            required: true
        },
    },
    computed: {
        results: {
            get(): number[] {
                return this.invInfo.inv.results
            },
            set(val: number[]) {
                stays.extActUpdateInv(this.stay_id, this.invInfo.inv, this.section, { results: val })
            }
        },
        notes: {
            get(): string {
                return this.invInfo.inv.notes || ""
            },
            set(val: string) {
                stays.extActUpdateInv(this.stay_id, this.invInfo.inv, this.section,
                    { notes: val })
            }
        }
    }
})




























import Vue from 'vue'

import stays from '@store/stays'
import { Stay } from 'models/data/stay'

import Admission from 'components/data-entry/views/Admission.vue'
import StaySummary from './StaySummary.vue'
import Ward from 'components/data-entry/views/Ward.vue'

export default Vue.extend({
    components: {
        Admission,
        StaySummary,
        Ward,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            prevRoute: null,
        }
    },
    computed: {
        editComponent() {
            if (this.$store.direct.state.user.telestroke_mode)
                return Admission
            return Ward
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id] 
        },
        ready(): boolean {
            return this.stay !== undefined
        },
    },
    beforeRouteEnter(to, from, next) {
        // Store the previous route so we can navigate back when finished editing
        next((vm: any) => {
            vm.prevRoute = from
        })
    },
    beforeCreate() {
        stays.mutClearAll()
    },
    created() {
        stays.actPullStay(this.stay_id)
    },
    beforeDestroy() {
        stays.mutClearStay(this.stay_id)
    },
})

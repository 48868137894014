





































import Vue from 'vue'
import { debounce } from 'lodash-es'
import utils from 'utils'
import CollapseIndicator from '@shared/CollapseIndicator.vue'

export default Vue.extend({
    components: {
        CollapseIndicator,
    },
    props: {
        autofocus: {
            type: Boolean,
            default: false
        },
        collapsible: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: true
        },
        value: {
            type: String,
        },
        id: {
            type: String
        },
        nonUniqueId: {
            type: String
        },
        label: {
            type: String,
            required: false,
        },
        rows: {
            type: [String, Number],
            required: false,
            default: 5
        },
        placeholder: {
            type: String,
            required: false
        },
        maxlength: {
            type: [String, Number],
            required: false
        }
    },
    data() {
        return {
            uid: utils.getUID(),
            localValue: this.value,
            localUpdated: false,
            visible_: !!this.visible,
        }
    },
    computed: {
        continuousSave(): boolean {
            return this.$store.direct.getters.user.betaEnabled && this.$store.direct.state.user.textAreaContSave
        },
        htmlId(): string {
            if (this.id)
                return this.id
            if (this.nonUniqueId)
                return `${this.uid}__${this.nonUniqueId}`
            return this.uid
        },
        collapseId(): string {
            return `${this.htmlId}__collapse`
        },
        state(): boolean | null {
            if (!this.maxlength || !this.localValue) return null
            const length = Number(this.maxlength)
            return (this.localValue.length < length) ? null : false
        },
        // The debounced function needs to be a `computed` property to work properly
        debouncedEmitInput: function(): any {
            return debounce(this.emitInput, 500, { trailing: true })
        },
    },
    watch: {
        value(val: string) {
            if (!this.localUpdated && val !== this.localValue)
                this.localValue = val
        },
    },
    methods: {
        handleBlur(e: Event) {
            if (e.target) {
                const el = (<HTMLInputElement>e.target)
                this.emitInput(el.value)
            }
        },
        handleInput_tracked(val: string) {
            if (this.continuousSave) {
                this.localUpdated = true
                this.debouncedEmitInput(val)
            }
        },
        emitInput(val: string) {
            this.localUpdated = false
            this.$emit('input', val)
        },
    }
})



















import Vue from 'vue'
import { SummaryStay } from 'models/dataviz'
import StayInfo from './StayInfo.vue'

export default Vue.extend({
    components: {
        StayInfo,
    },
    props: {
        modalId: {
            type: String,
            required: true
        },
        field: {},
        data: {
            type: Object,
            default: null,
        },
        infoStays: {
            type: Array as () => SummaryStay[],
            required: true
        },
        textVersion: {
            type: String,
            required: false,
            default: 'discharge',
            validator: (value) => {
                return ['discharge', 'hyperacute', 'strokeUnitSummary'].indexOf(value) !== -1
            },
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "button",
        {
          staticClass: "btn btn-sm progress-bar-striped bg-primary text-white",
          class: { "mb-1": _vm.add_mb },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.transferToSandbox.apply(null, arguments)
            }
          }
        },
        [_vm._v("\n    Sandbox\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }











import Vue from 'vue'
import * as Sentry from '@sentry/vue'

import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import CareClassification from '@ward/CareClassification.vue'
import EditModal from '../EditModal.vue'

export default Vue.extend({
    components: {
        CareClassification,
        EditModal,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        hasCC(): boolean {
            if (!this.stay)
                return false

            if (!this.stay.care_classification) {
                Sentry.captureMessage(`Stay ${this.stay.id} has no care_classification`, Sentry.Severity.Warning)
                return false
            }

            return true
        },
        label(): string {
            return (this.hasCC) ? this.stay!.care_classification!.label : '?'
        }
    }
})

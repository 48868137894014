






import Vue from 'vue'
import { trimEnd } from 'lodash-es'
import { socialHistory } from 'utils/text/clinic_history'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import EditModal from '../EditModal.vue'
import SocialHistory from '@admission/SocialHistory.vue'

export default Vue.extend({
    components: {
        EditModal,
        SocialHistory,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        /** return only the first line */
        summary(): string {
            return socialHistory({ stay: this.stay }, { topLineOnly: true })
        }
    }
})

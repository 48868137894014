
import Vue from 'vue'
import * as moment from 'moment'
import { find } from 'lodash-es'
import utils from 'utils'

interface RecentNote {
    id: string
    created: string
    notes: string
}

export default Vue.extend({
    data() {
        return {
            RN_tag: 'def',
            RN_recentNotes: [] as RecentNote[],
            RN_selected: '',
        }
    },
    computed: {
        RN_recentNote(): string {
            return find(this.RN_recentNotes, { id: this.RN_selected, })?.notes || ''
        },
    },
    methods: {
        RN_addRecentNote(stay_id: number, notes: string): void {
            if (!notes)
                return
            let index = 0
            while (window.sessionStorage.getItem(`s_${stay_id}_${this.RN_tag}_text_${index}_notes`) !== null)
                index++
            const created = moment()
            const created_s = created.toISOString(true)
            const tagBase = `s_${stay_id}_${this.RN_tag}_text_${index}`
            window.sessionStorage.setItem(`${tagBase}_notes`, notes)
            window.sessionStorage.setItem(`${tagBase}_created`, created_s)
            this.RN_recentNotes.push({
                id: tagBase,
                created: utils.niceDateShort(created_s),
                notes,
            })
        },
        RN_refreshRecentNotes(stay_id: number): void {
            if (this.RN_recentNotes.length)
                this.RN_recentNotes.splice(0, this.RN_recentNotes.length)
            let index = 0
            let notes: string | null = null
            let created: string | null = null
            while (true) {
                const tagBase = `s_${stay_id}_${this.RN_tag}_text_${index}`
                notes = window.sessionStorage.getItem(`${tagBase}_notes`)
                created = window.sessionStorage.getItem(`${tagBase}_created`)
                if (notes === null)
                    break
                this.RN_recentNotes.push({
                    id: tagBase,
                    created: utils.niceDateShort(created!),
                    notes,
                })
                index++
            }
        }
    }
})















































































import mixins from 'vue-typed-mixins'
import { debounce, map, range, sum } from 'lodash-es'
import * as moment from 'moment'
import { ChartDataSets } from 'chart.js'
import utils from 'utils'
import { safeDecoding } from 'utils/store'
import { TSData, NonTSData, nonTSDataDecoder, tsDataDecoder } from 'models/dataviz'
import NurseCharts from './NurseCharts.vue'
import StrokeTotals from '../StrokeTotals.vue'
import TelestrokeCharts from '../TelestrokeCharts.vue'
import UpdateChartMixin from '../UpdateChartMixin.vue'

const DATE_FORMAT = 'YYYY-MM'

export default mixins(UpdateChartMixin).extend({
    components: {
        NurseCharts,
        StrokeTotals,
        TelestrokeCharts,
    },
    props: {
        start: {
            type: String,
            required: false,
            default: function() {
                return moment().startOf('month').format(DATE_FORMAT)
            },
        },
        end: {
            type: String,
            required: false,
            default: function() {
                return moment().add(1, 'month').startOf('month').format(DATE_FORMAT)
            },
        },
    },
    data() {
        const lineStyles = {
            borderColor: '#4472c4',
            backgroundColor: '#a5a5a5',
            lineTension: 0.1,
            fill: false,
        }

        const percentageLineOptions = {
            legend: { display: false },
            scales: {
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: '%',
                        },
                        ticks: {
                            min: 0,
                            max: 100,
                            stepSize: 20,
                        },
                    },
                ]
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem: { xLabel: string, yLabel: string }) => {
                        return `${tooltipItem.yLabel}%`
                    }
                },
            },
        }

        return {
            lineStyles,
            percentageLineOptions,
            initialYear: 2019,
            year: moment().get('year'),
            data: null as TSData | NonTSData | null,
            charts: {} as {[k: string]: Chart},
            sum,
            dtnMedianAltStyle: true,
            dtnMedianStyleInfoHtmlID: `${utils.getUID()}___dtn_toggle_info`,
        }
    },
    computed: {
        // The debounced function needs to be a `computed` property to work properly
        debouncedFetchData: function(): any {
            return debounce(this.fetchData, 300, { leading: false, trailing: true })
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        showNurseReview(): boolean {
            return this.$store.direct.state.session.ux_nurse_review
        },
        hospitals(): number[] {
            return this.$store.direct.state.user.filters.hospitals
        },
        years(): number[] {
            return range(this.initialYear, moment().get('year') + 1)
        },
        nonTSData(): NonTSData | undefined {
            return this.telestrokeMode ? undefined : (this.data as NonTSData)
        },
        dtnMedianStyleToggleTitle(): string {
            return this.dtnMedianAltStyle ? 'Overall' : 'Monthly'
        },
    },
    watch: {
        hospitals: function(newVal, oldVal) {
            if (utils.isEqual(newVal, oldVal)) return
            this.debouncedFetchData()
        },
        year() {
            this.debouncedFetchData()
        },
        start() {
            this.debouncedFetchData()
        },
        end() {
            this.debouncedFetchData()
        },
        telestrokeMode() {
            this.pullInitialYear()
        },
        data() {
            this.$nextTick(() => { this.renderCharts() })
        },
        dtnMedianAltStyle() {
            this.renderDoorToNeedleChart()
        },
    },
    created() {
       this.fetchData()
    },
    methods: {
        pullInitialYear() {
            return utils.request.get('/stroke-chart-data/initial-year/')
            .then(res => {
                this.initialYear = res.body.year || 2019
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
        },
        fetchData() {
            if (!this.$store.direct.getters.session.loggedIn)
                return Promise.resolve()

            return utils
                .request
                .get(`/stroke-chart-data/v2/${this.start}/${this.end}/`, { h: this.hospitals })
                .then(res => {
                    if (this.telestrokeMode)
                        this.data = safeDecoding(res.body, tsDataDecoder, 'StrokeCharts.fetchData telestroke')
                    else
                        this.data = safeDecoding(res.body, nonTSDataDecoder, 'StrokeCharts.fetchData full')
                })
                .catch(err => {
                    utils.handleRequestError(err)
                })
        },
        linearRegression(data: {x: moment.Moment, y: number}[]): {slope: number, intercept: number}{
            // Calculate the slope and y intercept for a line of best fit
            //   slope = (n * sumXY - sumX * sumY) / (n*sumXX - sumX * sumX)
            //   intercept = (sumY - slope * sumX)/n
            // and the predicted y value for a give x value is
            //   y = slope * x + intercept
            const n = data.length
            let sumX = 0
            let sumY = 0
            let sumXY = 0
            let sumXX = 0
            let sumYY = 0

            for (var i = 0; i < data.length; i++) {
                const x = data[i].x.valueOf()
                const y = data[i].y
                sumX += x
                sumY += y
                sumXY += x*y
                sumXX += x*x
                sumYY += y*y
            } 

            const slope = (n*sumXY - sumX*sumY) / (n*sumXX - sumX*sumX)
            const intercept = (sumY - slope*sumX) / n
            return { slope, intercept }
        },
        renderCharts(): void {
            if (!this.data) return
            this.renderDoorToNeedleChart()
            if (!this.telestrokeMode) this.renderNonTelestrokeCharts()
        },
        renderDoorToNeedleChart(): void {
            const doorToNeedleData = map(
                this.data!.door_to_needle_times,
                dataPoint => ({ x: moment(dataPoint.date), y: dataPoint.time_in_minutes })
            )

            /*
            const { slope, intercept } = this.linearRegression(doorToNeedleData)
            const trendLineData = map(doorToNeedleData, ({ x }) => ({ x, y: round(slope*x.valueOf() + intercept, 1) }))
            */
            const doorToNeedleMedians = map(
                this.data!.door_to_needle_medians,
                medianInfo => ({ x: moment(medianInfo.x), y: medianInfo.y })
            )
            // allows us to draw a single straight line representing the overall median DTN time
            const dtnMedian = this.data!.door_to_needle_median || 0
            const combinedMedian = map(
                this.data!.door_to_needle_times,
                info => ({ x: moment(info.date), y: dtnMedian })
            )

            const medianDataSet: ChartDataSets = {
                type: 'line',
                label: 'Median',
                data: combinedMedian,
                borderColor: '#ffa62b',
                backgroundColor: '#ffa62b',
                pointRadius: 0,
                lineTension: 0.1,
                fill: false,
            }

            if (!this.dtnMedianAltStyle) {
                medianDataSet.label = 'Median (monthly)'
                medianDataSet.data = doorToNeedleMedians
                medianDataSet.pointRadius = 3
            }

            this.updateChart({
                title: 'doorToNeedleChart',
                chartType: 'scatter',
                data: {
                    labels: this.data!.months,
                    datasets: [
                        {
                            label: 'Times',
                            // Convert the raw data into the x/y format Chart.js expects
                            data: doorToNeedleData,
                            ...this.lineStyles,
                        },
                        medianDataSet,
                    ],
                },
                options: {
                    legend: { display: false },
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem: { xLabel: string, yLabel: string }) => {
                                // Date is formatted as a datetime e.g. "Mar 12, 2020, 12:00am"
                                // so we strip out the time bit
                                const date = tooltipItem.xLabel.split(', 12')[0]
                                const timeInMinutes = tooltipItem.yLabel
                                return ` ${timeInMinutes} minutes (${date})`
                            }
                        },
                    },
                    scales: {
                        xAxes: [
                            {
                                type: 'time',
                                time: {
                                    displayFormats: {
                                        month: "MMM [']YY"
                                    },
                                    unit: 'month',
                                    parser: "MMM [']YY",
                                },
                            },
                        ],
                        yAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Minutes',
                                },
                                ticks: {
                                    min: 0,
                                    suggestedMax: 10,
                                    stepSize: 20,
                                },
                            },
                        ]
                    },
                }
            })
        },
        renderNonTelestrokeCharts(): void {
            if (!this.nonTSData) return
            const data = this.nonTSData

            this.updateChart({
                title: 'strokeUnitAccessChart',
                chartType: 'line',
                data: {
                    labels: data.months,
                    datasets: [
                        {
                            label: 'Percentage',
                            data: data.stroke_unit_access,
                            ...this.lineStyles,
                        },
                    ],
                },
                options: this.percentageLineOptions,
            })

            // Discharged on correct meds
            this.updateChart({
                title: 'dischargedOnCorrectMedsChart',
                chartType: 'line',
                data: {
                    labels: data.months,
                    datasets: [
                        {
                            label: 'Percentage',
                            data: data.discharged_on_correct_meds,
                            ...this.lineStyles,
                        },
                    ],
                },
                options: this.percentageLineOptions,
            })
        }
    },
})


















import mixins from "vue-typed-mixins"
import { isFinite } from "lodash-es"

import { PropExtInfo, LayoutGroupExt } from 'models'
import { Stay } from 'models/data/stay'
import { DisplayList, LayoutGroup } from "models/layout"
import { NestedProp } from "models/schema"
import { FullSchema } from 'models/schema/stay'

import stays from "@store/stays"

import DisplayMixin from "../mixins/Display.vue"
import InputMixin from "../mixins/Input.vue"
import Generic from "../sections/Generic.vue"

const displayInfo: DisplayList = [
    {
        field: "bolus",
        force_label: "tPA bolus in mg",
        unit: "mg",
        disabled: true
    },
    {
        field: "bolus_over_60_minutes",
        force_label: "tPA infusion over 60 minutes in mg",
        unit: "mg",
        disabled: true
    },
    "bolus_time",
]

export default mixins(DisplayMixin, InputMixin).extend({
    components: {
        Generic,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        stacked: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        const section: keyof FullSchema = 'thrombolysis'
        return {
            section
        }
    },
    computed: {
        schema(): NestedProp | undefined {
            return this.getSectionSchema(this.section)
        },
        fields(): PropExtInfo[] {
            if (this.schema)
                return this.generatePropExtInfo(this.section, this.schema.children, displayInfo)
            return []
        },
        groups(): LayoutGroupExt[] | undefined {
            const layout: LayoutGroup[] = [
                {
                    fields: ['bolus', 'bolus_over_60_minutes', 'bolus_time'],
                    field_col: this.stacked ? 6 : 4,
                    alignment: this.stacked ? 'stacked' : 'inline',
                    field_top_spacing: true,
                    group_class: 'align-items-end',
                }
            ]
            return this.generateGroups(this.section, this.fields, layout)
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        weight: {
            get(): any {
                if (!(this.stay && this.stay.thrombolysis)) return null

                const val = this.stay.thrombolysis.weight
                if (val === null) return val

                const valNum = parseFloat(val as any) // parseFloat can accept 
                return isFinite(valNum) ? valNum : val
            },
            set(val: any) {
                this.setFieldVal(this.stay_id, "thrombolysis.weight", val)
            }
        },
    },
    methods: {
        updateBolusFields(e: any) {
            // Set values for bolus fields when weight is updated
            if (!(this.stay && this.stay.thrombolysis && this.stay.thrombolysis.weight)) return

            const weight = Math.min(this.stay.thrombolysis.weight, 100)

            function round(num: number): number {
                return Math.round(num * 10) / 10
            }

            const bolus = round(weight*0.09)
            this.setFieldVal(this.stay_id, "thrombolysis.bolus", bolus)

            const bolus_over_60_minutes = round(weight * 0.81)
            this.setFieldVal(this.stay_id, "thrombolysis.bolus_over_60_minutes", bolus_over_60_minutes)
        }
    },
})










import Vue from "vue"
import stringify from "fast-safe-stringify"

export default Vue.extend({
    props: ["name", "val"],
    computed: {
        json(): string {
            if (typeof this.val === "string")
                return this.val
            return stringify.stable(this.val, undefined, 2)
        },
        formatted(): boolean {
            return this.json.length > 15
        }
    }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Audit Trail (" + _vm._s(_vm.auditTrail.stay) + ")")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "pretty p-switch p-fill" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.hideEmpty,
                expression: "hideEmpty"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.hideEmpty)
                ? _vm._i(_vm.hideEmpty, null) > -1
                : _vm.hideEmpty
            },
            on: {
              change: function($event) {
                var $$a = _vm.hideEmpty,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.hideEmpty = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.hideEmpty = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.hideEmpty = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _vm._m(1)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pretty p-switch p-fill" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.reverse,
                expression: "reverse"
              }
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.reverse)
                ? _vm._i(_vm.reverse, null) > -1
                : _vm.reverse
            },
            on: {
              change: function($event) {
                var $$a = _vm.reverse,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.reverse = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.reverse = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.reverse = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _vm._m(2)
        ]),
        _vm._v(" "),
        _c("hr")
      ]),
      _vm._v(" "),
      _vm._l(_vm.auditTrail.history, function(record) {
        return _c(
          "div",
          { key: record.model + "_" + record.history_id, staticClass: "row" },
          [
            _vm.recordVisible(record)
              ? [
                  _c("div", { staticClass: "col-md-2" }, [
                    _vm._v(_vm._s(_vm.niceDate(record.history_date)))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-1" }, [
                    _vm._v(_vm._s(record.user))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3" }, [
                    _c("pre", [
                      _vm._v(
                        _vm._s(_vm.prettyPrint(_vm.historyMeta(record), 60))
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("pre", [_vm._v(_vm._s(_vm.prettyPrint(record.changes)))])
                  ])
                ]
              : _c("div", { staticClass: "col" }, [_c("span", [_vm._v("...")])])
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-1" }, [
      _c("em", [_vm._v("Note:")]),
      _vm._v(" Times are shown in your browser's local time\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-success" }, [
      _c("label", [_vm._v("Hide Empty")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-success" }, [
      _c("label", [_vm._v("Latest first")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import * as moment from 'moment'
import { concat, find, forEach, get, intersection, map, sumBy, values } from 'lodash-es'
import utils from 'utils'
import { freeTextSubHtmlBreaks } from './helpers'
import { formatQuestionAnswerAndNotes } from './question'
import { TextDetails } from './interfaces'
import { MedicationCategory } from 'models/med_templates/medication'
import { Question, QuestionCategory } from "models/med_templates/question"
import stays from '@store/stays'
import store from 'store'


function currentMedications({stay, title, hideTitle}: TextDetails): string {
    if (!stay) return ""

    const speciality = stays.getSpeciality(stay.id)
    if (!speciality) return ''

    const allCategories = speciality.medication_categories
    let medCategories: MedicationCategory[] = speciality.medication_categories

    const syndrome = stays.getSyndrome(stay.id)
    if (syndrome && syndrome.medication_categories) {
        medCategories = []
        forEach(syndrome.medication_categories, id => {
            const category = find(allCategories, { id })
            if (category)
                medCategories.push(category)
        })        
    }
    if (!medCategories.length) return ""

    const stayMeds = stay.current_meds || []

    let lines: string[] = []

    forEach(medCategories, function(category) {
        const medIDs = map(category.medications, "id")
        const ticked = intersection(medIDs, stayMeds)
        const medLines: string[] = []

        forEach(ticked, function(medID) {
            const med = find(category.medications, ["id", medID])
            if (med) medLines.push(med.title)
        })
        
        if (medLines.length) {
            const medsText = medLines.join(", ")
            lines.push(medsText)
        }
    })

    if (stay.other_current_meds) {
        lines.push(freeTextSubHtmlBreaks(stay.other_current_meds))
    }

    if (lines.length === 0) {
        lines.push("*Unknown*")
    }
    if (!hideTitle) {
        title = title || "Key current medications include"
        lines.unshift(`**${title}:**`)
    }
    return lines.join("  \n") + "\n\n"
}

export function wardRoundsMedications({stay, title, hideTitle}: TextDetails): string {
    if (!stay) return ""

    // Only return text if the current_meds have been changed since this ward round was created
    if (!stay.ward_round) return ""
    const wardRoundCreated = stay.ward_round.created_at
    const noChangeThisWardRound = (
        stay.current_meds_updated &&
        stay.current_meds_updated < wardRoundCreated
    )
    if (noChangeThisWardRound) return ""

    return currentMedications({ stay, title, hideTitle })
}

export function historicalMedications(
    { stay, title }: TextDetails,
    { updatedAfter = '' }: { updatedAfter?: string } = {}
): string {
    if (!stay) return ""

    const syndrome = find(store.direct.getters.templates.allSyndromes, ['id', stay.syndrome])

    let medQuestions: Question[] = []

    if (syndrome)
        forEach(syndrome.question_groups, group => {
            if (group.category === QuestionCategory.HISTORICAL_MEDS)
                medQuestions = concat(medQuestions, map(group.questions, 'question'))
        })

    const updatedAfter_m = moment(updatedAfter)
    const checkDate = updatedAfter_m.isValid()

    let recent = false

    const answers = {
        taking: [] as string[],
        notTaking: [] as string[],
        notTakingWithNotes: [] as string[]
    }

    forEach(medQuestions, question => {
        const res = formatQuestionAnswerAndNotes(
            stay.id, question,
            undefined, undefined,
            true
        )

        if (!res)
            return

        if (checkDate) {
            const qUpdated_m = moment(res.updated_at)
            if (qUpdated_m.isValid()) {
                if (qUpdated_m.isSameOrAfter(updatedAfter_m))
                    recent = true
            }
            else {
                utils.messageSentry(`Stay ${stay.id} med question.updated_at is invalid. (q_id: ${question.id}, value: ${qUpdated_m.creationData().input})`)
            }
        }

        if (res.booleanLike && !res.positive) {
            if (res.hasNotes)
                answers.notTakingWithNotes.push(res.text)
            else
                answers.notTaking.push(res.text)
        }
        else
            answers.taking.push(res.text)
    })

    if (checkDate) {
        const updated_m = moment(stay.historical_meds?.updated_at)
        if (updated_m.isValid()) {
            if (updated_m.isSameOrAfter(updatedAfter_m))
                recent = true
        }
        else {
            utils.messageSentry(`Stay ${stay.id} hist_meds.updated_at is invalid. (value: ${updated_m.creationData().input})`)
        }
    }

    if (checkDate && !recent)
        return ''

    let lines: string[] = []

    if (answers.taking.length) {
        lines = lines.concat(answers.taking)
    }

    if (stay.historical_meds && stay.historical_meds.other_meds) {
        lines.push(`Other medications:<br>${freeTextSubHtmlBreaks(stay.historical_meds.other_meds)}`)
    }

    if (lines.length && (answers.notTakingWithNotes.length || answers.notTaking.length)) {
        // Push an empty line to add a line break before negatives
        lines.push("")
    }

    if (answers.notTakingWithNotes.length) {
        const deniesText = map(answers.notTakingWithNotes, line => `Denies taking ${line}`)
        lines = lines.concat(deniesText)
    }

    if (answers.notTaking.length) {
        const notTakingText = `Denies taking:  \n${answers.notTaking.join(" / ")}`
        lines.push(notTakingText)
    }

    if (stay.historical_meds && stay.historical_meds.allergies) {
        if (lines.length)
            lines.push('')
        lines.push(`Allergies:  \n${freeTextSubHtmlBreaks(stay.historical_meds.allergies)}`)
    }

    if (!lines.length)
        return ''

    const text = lines.join('  \n')
    const sectionTitle = title || "Relevant current medications include"
    return `**${sectionTitle}:**  \n${text}\n\n`
    }

export function updatedHistoricalMedications({ stay }: TextDetails): string {
    if (!(stay && stay.ward_round)) return ""
    const wardRoundCreated = stay.ward_round.created_at
    const title = "Relevant medications prior to admission"
    return historicalMedications({ stay, title }, { updatedAfter: wardRoundCreated })
}










import Vue from 'vue'
import Questions from '@stayinputs/Questions.vue'

import stays from '@store/stays'
import { QuestionCategory } from 'models/med_templates/question'
import { Diagnosis } from 'models/med_templates/diagnosis'
import { diagnosisFeatures } from 'utils/text/diagnosis'



export default Vue.extend({
    components: {
        Questions,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            QuestionCategory,
        }
    },
    computed: {
        diagnosis(): Diagnosis | undefined {
            return stays.getDiagnosis(this.stay_id)
        },
        showDashboardToggle(): boolean {
            return this.$store.direct.state.session.onDashboard
                && (this.$store.direct.state.user.is_senior || this.$store.direct.getters.user.isPowerUser)
        },
    },
    // we provide this here so that the toggle is only shown in descendant Question components (which inject this variable)
    provide(): { showDashboardToggle: boolean } {
        return {
            showDashboardToggle: this.showDashboardToggle
        }
    },
})

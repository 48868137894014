









import Vue from "vue"
import stays from '@store/stays'

export default Vue.extend({
    computed: {
        extrasMode: {
            get(): boolean {
                return this.$store.direct.state.user.extras_mode
            },
            set(val: boolean) {
                this.$store.direct.dispatch.user.toggleExtrasMode()
                stays.mutTriggerLoadStays()
            }
        }
    }
})

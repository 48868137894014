var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.validStayID
    ? _c(
        "b-modal",
        {
          attrs: {
            id: _vm.modalId,
            title: "Edit Stay",
            size: "xl",
            scrollable: "",
            "ok-only": "",
            "no-enforce-focus": true
          },
          on: {
            hidden: function($event) {
              return _vm.$emit("hidden", $event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "row pb-5" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.ready
                  ? _c(
                      "div",
                      { staticClass: "col pt-2" },
                      [
                        _c("stay-summary", { attrs: { stay_id: _vm.stay_id } }),
                        _vm._v(" "),
                        _c(_vm.editComponent, {
                          tag: "component",
                          staticClass: "mt-2",
                          attrs: { stay_id: _vm.stay_id }
                        })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "col pt-2" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center h-100"
                        },
                        [
                          _c("h1", [
                            _c("em", { staticClass: "loading-dots" }, [
                              _vm._v("Loading"),
                              _c("span", [_vm._v(".")]),
                              _c("span", [_vm._v(".")]),
                              _c("span", [_vm._v(".")])
                            ])
                          ])
                        ]
                      )
                    ])
              ])
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.speciality
    ? _c("div", { staticClass: "row mt-2" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("h3", [_vm._v("Find")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "b-form",
              {
                attrs: { inline: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.findAll.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-input", {
                      attrs: {
                        id: _vm.htmlIDs.search,
                        placeholder: "MRN",
                        autofocus: ""
                      },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "b-input-group-append",
                      [
                        _c("b-btn", { attrs: { type: "submit" } }, [
                          _vm._v("Search")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("b-table", {
              attrs: {
                striped: "",
                hover: "",
                fixed: "",
                fields: _vm.fields,
                items: _vm.items,
                busy: !_vm.ready
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "head(stays_raw)",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          { staticClass: "progress-bar-striped px-2" },
                          [_vm._v("JSON [superuser]")]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(stays_raw)",
                    fn: function(data) {
                      return [
                        _c(
                          "b-btn",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: "p_" + data.item.patient.id + "_data",
                                expression: "`p_${data.item.patient.id}_data`"
                              }
                            ],
                            attrs: { size: "sm" }
                          },
                          [_vm._v("Show/Hide")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-collapse",
                          {
                            attrs: { id: "p_" + data.item.patient.id + "_data" }
                          },
                          [_c("pre", [_vm._v(_vm._s(data.item.stays))])]
                        )
                      ]
                    }
                  },
                  {
                    key: "head(all_stays)",
                    fn: function() {
                      return [
                        _c("span", [
                          _vm._v("Stays "),
                          _c("span", { staticClass: "font-weight-normal" }, [
                            _vm._v("[" + _vm._s(_vm.orderingText) + "]")
                          ])
                        ]),
                        _vm._v(" "),
                        false
                          ? _c(
                              "b-btn",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "py-0 ml-1",
                                attrs: { size: "sm", title: "Flip ordering" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.ascOrdering = !_vm.ascOrdering
                                  }
                                }
                              },
                              [
                                _c("fnt-a-icon", {
                                  attrs: { icon: ["far", "repeat-alt"] }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "cell(all_stays)",
                    fn: function(data) {
                      return [
                        data.item.olds.length > 0
                          ? _c(
                              "b-btn",
                              {
                                staticClass: "text-monospace",
                                class: {
                                  "d-inline": _vm.expanded[data.item.patient.id]
                                },
                                attrs: { variant: "link", size: "sm" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleExpanded(
                                      data.item.patient.id
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.showHideText(data.item.patient.id)
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "scrawl-grouped",
                            class: {
                              "d-inline": !_vm.expanded[data.item.patient.id]
                            }
                          },
                          [
                            _vm.expanded[data.item.patient.id]
                              ? [
                                  _vm._l(data.item.olds, function(combined) {
                                    return [
                                      _c("stay-btn", {
                                        attrs: {
                                          patientData: data.item,
                                          combined: combined,
                                          isOld: ""
                                        },
                                        on: {
                                          "start-consult": function($event) {
                                            return _vm.startConsultByEvent(
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  })
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(data.item.news, function(combined) {
                              return [
                                _c("stay-btn", {
                                  attrs: {
                                    patientData: data.item,
                                    combined: combined
                                  },
                                  on: {
                                    "start-consult": function($event) {
                                      return _vm.startConsultByEvent($event)
                                    }
                                  }
                                })
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2202657676
              )
            })
          ],
          1
        )
      ])
    : _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-center h-100 mt-5"
        },
        [_vm._m(0)]
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("em", [
        _vm._v(
          "Please select a speciality on the home page to access this functionality."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
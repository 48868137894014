









import Vue from 'vue'
import { clone } from 'lodash-es'
import { Speciality } from 'models/med_templates/speciality'
import FilterBase from './FilterBase.vue'


export default Vue.extend({
    components: {
        FilterBase,
    },
    computed: {
        specialities(): Speciality[] {
            return this.$store.direct.state.templates.specialities
        },
        selectedSpecialities: {
            get(): number[] {
                return this.$store.direct.state.user.filters.specialities
            },
            set(specialityIDs: number[]) {
                if (specialityIDs.length > 1) {
                    specialityIDs = clone(specialityIDs)
                    specialityIDs = [specialityIDs.pop()!]
                }
                this.$store.direct.dispatch.user.updateFilters({specialities: specialityIDs})
                this.$emit('update')
            }
        },
        closeOnSelect(): boolean {
            return this.selectedSpecialities.length < 2
        }
    },
})

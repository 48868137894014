























import Vue from "vue"
import HelpModal from '@misc/HelpModal.vue'
import Changelog from '@misc/Changelog.vue'
import ChangesIndicator from '@misc/ChangesIndicator.vue'

export default Vue.extend({
    components: {
        Changelog,
        ChangesIndicator,
        HelpModal,
    },
    data() {
        return {
            documentTitle: document.title + ' - ASAP Tool',
            version: window.scrawl.sentryRelease,
            helpModalId: '?',
        }
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        false
          ? _c(
              "div",
              { staticClass: "col-12 text-center" },
              [
                _c(
                  "b-btn-group",
                  { staticClass: "mb-2" },
                  _vm._l(_vm.years, function(yr) {
                    return _c(
                      "b-btn",
                      {
                        key: yr,
                        class: { active: _vm.year === yr },
                        on: {
                          click: function($event) {
                            _vm.year = yr
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(yr) +
                            "\n                "
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.telestrokeMode
          ? _c("div", { staticClass: "col-12 text-center" }, [
              _c("h5", [
                _vm._v("Sites Active: "),
                _vm.data
                  ? _c("span", [_vm._v(_vm._s(_vm.data.total_sites))])
                  : _c("span", [_vm._v("?")])
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.data
            ? _c("stroke-totals", { attrs: { data: _vm.data } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.showNurseReview
        ? _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("nurse-charts", {
                attrs: { strokeData: _vm.data, start: _vm.start, end: _vm.end }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.telestrokeMode
        ? _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("b-overlay", {
                  attrs: {
                    show: !_vm.data,
                    variant: "transparent",
                    "no-wrap": ""
                  }
                }),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "text-center mt-2 mb-0",
                    staticStyle: { "font-size": "1.5rem" }
                  },
                  [_vm._v("\n                Stroke unit access\n            ")]
                ),
                _vm._v(" "),
                _vm.data
                  ? _c("p", { staticClass: "text-center mt-0 mb-2" }, [
                      _vm._v(
                        "\n                Year to date: " +
                          _vm._s(_vm.data.stroke_unit_access_percentage) +
                          "%\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("canvas", {
                  ref: "strokeUnitAccessChart",
                  staticClass: "mt-2 mb-4"
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-12 col-lg-6" },
          [
            _c("b-overlay", {
              attrs: { show: !_vm.data, variant: "transparent", "no-wrap": "" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("span"),
              _vm._v(" "),
              _c("h4", [_vm._v("Door to Needle times")]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("b-check", {
                    staticClass: "custom-control-inline mr-0",
                    attrs: { switch: "" },
                    model: {
                      value: _vm.dtnMedianAltStyle,
                      callback: function($$v) {
                        _vm.dtnMedianAltStyle = $$v
                      },
                      expression: "dtnMedianAltStyle"
                    }
                  }),
                  _vm._v(" "),
                  _c("fnt-a-icon", {
                    attrs: {
                      id: _vm.dtnMedianStyleInfoHtmlID,
                      icon: ["fal", "info-circle"]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "b-tooltip",
                    { attrs: { target: _vm.dtnMedianStyleInfoHtmlID } },
                    [
                      _vm._v(
                        "Toggles the median line between the overall median time and the monthly median. (Currently: " +
                          _vm._s(_vm.dtnMedianStyleToggleTitle) +
                          ")"
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.data
              ? _c("p", { staticClass: "text-center mt-0 mb-2" }, [
                  _vm._v(
                    "\n                Median: " +
                      _vm._s(_vm.data.door_to_needle_median || "?") +
                      " minutes\n            "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("canvas", { ref: "doorToNeedleChart", staticClass: "mt-2 mb-4" })
          ],
          1
        ),
        _vm._v(" "),
        !_vm.telestrokeMode
          ? _c(
              "div",
              { staticClass: "col-12 col-lg-6" },
              [
                _c("b-overlay", {
                  attrs: {
                    show: !_vm.data,
                    variant: "transparent",
                    "no-wrap": ""
                  }
                }),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "text-center mt-2 mb-0",
                    staticStyle: { "font-size": "1.5rem" }
                  },
                  [
                    _vm._v(
                      "\n                Discharged on correct meds\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.data
                  ? _c("p", { staticClass: "text-center mt-0 mb-2" }, [
                      _vm._v(
                        "\n                Year to date: " +
                          _vm._s(
                            _vm.data.discharged_on_correct_meds_percentage
                          ) +
                          "%\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("canvas", {
                  ref: "dischargedOnCorrectMedsChart",
                  staticClass: "mt-2 mb-4"
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.telestrokeMode && _vm.data
        ? _c("telestroke-charts", { attrs: { data: _vm.data } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
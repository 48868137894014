




























































































import mixins from 'vue-typed-mixins'
import { each, find, toNumber } from 'lodash-es'
import utils from 'utils'
import stays from '@store/stays'

import { InvSection } from "models/data/investigation"
import { Question as QuestionSchema } from 'models/med_templates/question'
import { CTPerfusion_v2 } from 'models/med_templates/investigation'

import AdvInvMixin from './AdvInvMixin.vue'
import Question from '@stayinputs/Question.vue'

interface Selection {
    findings: number | null
    sides: number[]
    apl_sites: number[],
    quality: number | null,
}

export default mixins(AdvInvMixin).extend({
    components: {
        Question
    },
    data() {
        const selection: Selection = {
            findings: null,
            sides: [],
            apl_sites: [],
            quality: null,
        }

        const uid = utils.getUID()
        return {
            htmlIds: {
                core: `${uid}___core_local`,
                pen: `${uid}___pen_local`,
            },
            fieldIds: CTPerfusion_v2,
            questionIds: ['ctp_lesion_core_mls', 'ctp_lesion_pen_mls'],
            selection,
        }
    },
    computed: {
        isBaselineInv(): boolean {
            return this.section === InvSection.BASELINE
        },
        /** forcing type on selection, since AdvInvMixin's selection as object confuses typing system */
        selectionT(): Selection {
            return this.selection
        },
        volumeQuestions(): QuestionSchema[] {
            const questions: QuestionSchema[] = []
            each(this.questionIds, qId => {
                const q = find(this.$store.direct.state.templates.custom_qs, {help_text: qId})
                if (q) questions.push(q)
            })
            return questions
        },
        volumeCore: {
            get(): number | null | undefined {
                return this.invInfo.inv?.extra_data.core
            },
            set(val: string) {
                let val_n = val === '' ? NaN : toNumber(val)
                let val_f = isFinite(val_n) ? val_n : null
                stays.extActUpdateInv(this.stay_id, this.invInfo.inv!, this.section, { extra_data: { core: val_f, pen: this.volumePenumbra } })
            }
        },
        volumePenumbra: {
            get(): number | null | undefined {
                return this.invInfo.inv?.extra_data.pen
            },
            set(val: string) {
                let val_n = val === '' ? NaN : toNumber(val)
                let val_f = isFinite(val_n) ? val_n : null
                stays.extActUpdateInv(this.stay_id, this.invInfo.inv!, this.section, { extra_data: { core: this.volumeCore, pen: val_f } })
            }
        },
        isUninterpretable(): boolean {
            return this.selection.findings == this.fieldToIdMap['unclear']
        },
        isAPL(): boolean {
            return this.selection.findings === this.fieldToIdMap['apl']
        },
        localResults(): number[] {
            let results: number[] = []
            if (this.selectionT.findings) results.push(this.selectionT.findings)
            results = results.concat(this.selectionT.sides)
            results = results.concat(this.selectionT.apl_sites)
            if (this.selectionT.quality) results.push(this.selectionT.quality)
            return results
        },
    },
    watch: {
        'selection.findings'(val: any) {
            if (val === this.fieldSchemaLookup['normal'].id) {
                this.selectionT.sides = []
                this.selectionT.apl_sites = []
            }
            else if (val === this.fieldSchemaLookup['unclear'].id) {
                this.selectionT.sides = []
                this.selectionT.apl_sites = []
                this.clearNotes()
            }
        },
    }
})

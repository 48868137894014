var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isTempUser
    ? _c(
        "div",
        {
          staticClass: "position-relative",
          on: {
            keyup: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                ) {
                  return null
                }
                return _vm.selectNextStay.apply(null, arguments)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                ) {
                  return null
                }
                return _vm.selectPreviousStay.apply(null, arguments)
              }
            ]
          }
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showResults,
                expression: "showResults"
              }
            ],
            staticClass: "click-to-hide-target",
            on: {
              click: function($event) {
                _vm.showResults = false
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "m-0" },
            [
              _c(
                "b-input-group",
                { staticClass: "z-100" },
                [
                  _c("b-form-input", {
                    ref: "search-input",
                    staticClass: "border-0",
                    staticStyle: { "box-shadow": "none" },
                    attrs: {
                      placeholder: _vm.placeholderText,
                      debounce: "500",
                      trim: ""
                    },
                    on: {
                      update: _vm.search,
                      focus: function($event) {
                        _vm.showResults = true
                      },
                      blur: _vm.delayedClearMRN
                    },
                    model: {
                      value: _vm.mrn,
                      callback: function($$v) {
                        _vm.mrn = $$v
                      },
                      expression: "mrn"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-input-group-text",
                        { staticClass: "bg-white border-0" },
                        [
                          _c("fnt-a-icon", {
                            attrs: { icon: ["fal", "search"] }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showClearBtn
                        ? _c(
                            "b-btn",
                            {
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.clearMRN.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c("fnt-a-icon", {
                                staticClass: "svg text-danger",
                                attrs: { icon: ["fal", "times"] }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showResults && _vm.mrn.length
            ? _c(
                "b-list-group",
                { staticClass: "position-absolute w-100 z-100 mt-1" },
                [
                  _vm.searching
                    ? _c("b-list-group-item", [
                        _vm._v("\n            Searching...\n        ")
                      ])
                    : _vm.stays.length
                    ? _vm._l(_vm.stays, function(stay, index) {
                        return _c(
                          "b-list-group-item",
                          {
                            key: stay.id,
                            class: _vm.searchResultClass(index),
                            attrs: { to: _vm.stayLink(stay) },
                            on: {
                              focus: function($event) {
                                _vm.selectedStay = index
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              [
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(stay.name) +
                                      "\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "ml-4 mb-0" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(stay.mrn) +
                                      "\n                "
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "mb-0 text-secondary text-nowrap"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(stay.doctor_name) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "mb-0 text-secondary text-nowrap"
                              },
                              [
                                stay.active
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                    Admitted: " +
                                          _vm._s(
                                            _vm.formatDate(
                                              stay.admission_time ||
                                                stay.created_at
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !stay.active
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                    Discharged: " +
                                          _vm._s(
                                            _vm.formatDate(stay.discharge_time)
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      })
                    : _c("b-list-group-item", [
                        _vm._v("\n            No stays found\n        ")
                      ])
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
























import mixins from 'vue-typed-mixins'
import { find, filter, reject } from 'lodash-es'
import stays from '@store/stays'

import { PropExtInfo } from 'models'
import { Stay } from 'models/data/stay'
import { FullSchema } from "models/schema/stay"
import { Diagnosis } from 'models/med_templates/diagnosis'
import { Syndrome } from 'models/med_templates/syndrome'
import { SpecialityBasic } from 'models/med_templates/speciality'

import DiagnosisDropdown from './DiagnosisDropdown.vue'
import DiagnosisHelp from './DiagnosisHelp.vue'
import DisplayMixin from '@mixins/Display.vue'
import InputMixin from '@mixins/Input.vue'
import RInput from '../Input.vue'


export default mixins(DisplayMixin, InputMixin).extend({
    components: {
        DiagnosisDropdown,
        DiagnosisHelp,
        RInput
    },
    props: {
        label: {
            type: String,
            required: false,
            default: "Working Diagnosis"
        },
        stay_id: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
           // A dummy ID value for 'Other diagnosis', anything that won't conflict with an actual diagnosis ID should work
           OTHER_DIAGNOSIS_VALUE: -999
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        staySyndrome(): Syndrome | null {
            if (this.stay)
                return find(this.$store.direct.getters.templates.allSyndromes, ['id', this.stay.syndrome]) || null
            return null
        },
        specialities(): SpecialityBasic[] {
            if (!this.stay)
                return []

            const allSpecialities = this.$store.direct.state.templates.specialities

            if (!this.stay.speciality && !this.staySyndrome)
                return allSpecialities

            let specialities: SpecialityBasic[] = []

            if (this.stay.speciality) {
                specialities = filter(allSpecialities, ['id', this.stay.speciality])
                if (this.staySyndrome && this.stay.diagnosis) {
                    if (this.staySyndrome.speciality !== this.stay.speciality) {
                        const syndromeSpeciality = find(allSpecialities, ['id', this.staySyndrome.speciality])
                        if (syndromeSpeciality) {
                            const syndrome = find(syndromeSpeciality.syndromes, ['id', this.staySyndrome.id])
                            if (syndrome) {
                                const diagnosis = find(syndrome.diagnoses, ['id', this.stay.diagnosis])
                                if (diagnosis) {
                                    specialities.unshift({
                                        id: syndromeSpeciality.id,
                                        title: syndromeSpeciality.title,
                                        syndromes: [
                                            {
                                                id: syndrome.id,
                                                title: syndrome.title,
                                                diagnoses: [diagnosis]
                                            }
                                        ]
                                    })
                                }
                                else {
                                    // TODO report error - diagnosis template not found
                                }
                            }
                            else {
                                // TODO report error - sydrome template not found
                            }
                        }
                        else {
                            // TODO report error - speciality template not found
                        }
                    }
                }
            }
            else {  // ! This shouldn't happen (since stay.speciality is a property based on feed and then syndrome), put in checks and remove later
                const speciality = find(allSpecialities, ['id', this.staySyndrome!.speciality])
                if (speciality) {
                    const top = filter(speciality.syndromes, ['id', this.staySyndrome!.id])
                    const rest = reject(speciality.syndromes, ['id', this.staySyndrome!.id])
                    const syndromes = top.concat(rest)
                    specialities = [{ id: speciality.id, title: speciality.title, syndromes }]
                }
                else {
                    // TODO report error - speciality template not found
                }
            }

            return specialities
        },
        selectedDiagnosis(): Diagnosis | undefined {
            return find(this.$store.direct.getters.templates.allDiagnoses, ['id', this.diagnosis])
        },
        computedSchema(): FullSchema | null {
            return this.$store.direct.state.stays_v2.fullSchema
        },
        otherDiagnosisPropExtInfo(): PropExtInfo | null {
            const info = this.generatePropExtInfoFromSchema('', this.computedSchema, ['other_diagnosis_title'])
            return info ? info[0] : info
        },
        showOther(): boolean {
            return this.diagnosis === this.OTHER_DIAGNOSIS_VALUE && this.otherDiagnosisSelected
        },
        diagnosis: {
            get(): number | null {
                const diagnosisID = this.getFieldVal(this.stay_id, "diagnosis")
                if (diagnosisID === null && this.otherDiagnosisSelected) return this.OTHER_DIAGNOSIS_VALUE
                return diagnosisID
            },
            set(diagnosisID: number | null) {
                if (diagnosisID === this.OTHER_DIAGNOSIS_VALUE) {
                    this.setFieldVal(this.stay_id, 'diagnosis', null)
                    this.otherDiagnosisSelected = true
                }
                else {
                    this.otherDiagnosisSelected = false
                    this.setFieldVal(this.stay_id, 'other_diagnosis_title', null)
                    this.setFieldVal(this.stay_id, 'diagnosis', diagnosisID)
                }
            }
        },
        otherDiagnosisSelected: {
            get(): boolean {
                return this.stay ? this.stay.other_diagnosis_selected : false
            },
            set(val: boolean) {
                this.setFieldVal(this.stay_id, 'other_diagnosis_selected', val)
            }
        }
    },
    watch: {
        'selectedDiagnosis': {
            immediate: true,
            handler: function() {
                if (this.stay && this.selectedDiagnosis)
                    stays.extActUpdateSyndrome(this.stay_id, this.selectedDiagnosis.syndrome)
            }
        }
    },
})

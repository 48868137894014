/**
 * A module for storing data for the admin interface.
 */

import { defineModule, localActionContext, localGetterContext } from "direct-vuex"
import Vue from 'vue'
import utils from 'utils'
import { each, find, findIndex, get } from 'lodash-es'
import { safeDecoding } from 'utils/store'
import { QuestionTypeExt } from 'models/uxadmin'
import { Speciality, simpleSpecialityDecoder, specialityDecoder } from 'models/uxadmin/speciality'
import { Question, QuestionChoice } from 'models/uxadmin/question'

export interface QuestionMeta {
    type: QuestionTypeExt[]
}

interface QChoicesInfo {
    questionId: number
    choices: QuestionChoice[]
}

export interface AdminState {
    /** stores the templates etc for various types of objects */
    metadata: {
        question: QuestionMeta
    }
    records: {
        speciality: Speciality | null
    }
}

const initState: AdminState = {
    metadata: {
        question: {
            type: []
        }
    },
    records: {
        speciality: null
    }
}

const adminModule = defineModule({
    namespaced: true,
    state: (): AdminState => {
        return initState
    },
    getters: {
    },
    mutations: {
        setMetadata(state, val) {
            // TODO run this through a decoder
            state.metadata = val
        },
        setSpeciality(state, speciality: Speciality) {
            state.records.speciality = safeDecoding(speciality, specialityDecoder, 'uxadmin.setSpeciality')
        },
        updateSpeciality(state, data: Partial<Speciality>) {
            if (!state.records.speciality) return

            const simpleSpeciality = safeDecoding(data, simpleSpecialityDecoder, 'uxadmin.updateSpeciality')
            each(simpleSpeciality, (val, key) => {
                if (!utils.isEqual(get(state.records.speciality, key), val))
                    Vue.set(state.records.speciality!, key, val)
            })
        },
        updateSpecialityQuestion(state, question: Question) {
            const speciality = state.records.speciality
            if (speciality && speciality.id === question.speciality) {
                const index = findIndex(speciality.questions, ['id', question.id])
                if (index > -1)
                    Vue.set(speciality.questions, index, question)
                else
                    speciality.questions.push(question)
            }
        },
        removeSpecialityQuestion(state, question: Partial<Question>) {
            const speciality = state.records.speciality
            if (speciality && speciality.id === question.speciality) {
                const index = findIndex(speciality.questions, ['id', question.id])
                if (index > -1)
                    speciality.questions.splice(index, 1)
            }
        },
        updateQChoicesOrder(state, { questionId, choices }: QChoicesInfo): void {
            const speciality = state.records.speciality
            if (!speciality) return
            const question = find(speciality.questions, ['id', questionId])
            if (!question) return
            each(choices, (choice, index) => {
                choice.order = index
            })
            Vue.set(question, 'choices', choices)
        },
    },
})

export default adminModule

const moduleGetterContext = (args: [any, any, any, any]) => localGetterContext(args, adminModule)
const moduleActionContext = (context: any) => localActionContext(context, adminModule)

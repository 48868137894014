


















































import Vue from "vue"
import { get, includes, isObject } from "lodash-es"

import stays from "@store/stays"

import { Stay } from 'models/data/stay'
import { Diagnosis, MgmtPlan } from "models/med_templates/diagnosis"

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        diagnosis: {
            validator: (val: Diagnosis | undefined) => {
                return val === undefined || isObject(val)
            },
            required: true,
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        managementPlan(): MgmtPlan {
            return this.diagnosis?.management_plan || []
        },
        hasPlan(): boolean {
            return this.managementPlan.length > 0
        },
        selectedSteps: {
            get(): number[] {
                return this.stay?.management_plan?.steps || []
            },
            set(val: number[]) {
                stays.extActSetPathValue(this.stay_id, "management_plan.steps", val)
            }
        },
    },
    methods: {
        isSelected(stepID: number) {
            return includes(this.selectedSteps, stepID)
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table",
    _vm._b(
      {
        staticClass: "border-bottom",
        attrs: {
          id: _vm.htmlIDs.table,
          hover: "",
          striped: "",
          "no-sort-reset": "",
          "thead-tr-class": "bg-primary sticky-row",
          busy: _vm.busy
        },
        scopedSlots: _vm._u(
          [
            {
              key: "table-busy",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center my-2" },
                    [
                      _c("b-spinner", { staticClass: "align-middle" }),
                      _vm._v(" "),
                      _c("strong", { staticClass: "loading-dots" }, [
                        _vm._v("Loading"),
                        _c("span", [_vm._v(".")]),
                        _c("span", [_vm._v(".")]),
                        _c("span", [_vm._v(".")])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            _vm._l(_vm.$scopedSlots, function(_, name) {
              return {
                key: name,
                fn: function(slotProps) {
                  return [_vm._t(name, null, null, slotProps)]
                }
              }
            })
          ],
          null,
          true
        )
      },
      "b-table",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }













import mixins from 'vue-typed-mixins'
import { map, values } from 'lodash-es'

import stays from '@store/stays'
import { Stay } from 'models/data/stay'

import DelayRouteLeaveMixin from '@shared/DelayRouteLeaveMixin.vue'
import StaysFilterMixin from '@shared/StaysFilterMixin.vue'
import SurgeryMixin from '@shared/SurgeryMixin.vue'
import OverviewTable from './OrthoOverview.vue'
import Print from '@home/Print.vue'
import StayFilters from "@shared/StayFilters.vue"

export default mixins(DelayRouteLeaveMixin, StaysFilterMixin, SurgeryMixin).extend({
    components: {
        OverviewTable,
        Print,
        StayFilters,
    },
    beforeCreate() {
        stays.mutClearStays()
    },
    created() {
        this.pull()
    },
    methods: {
        $_pull() {
            return stays.actPullStays({
                url: '/dashboard/overview/',
                params: this.params,
            })
        },
    },
    computed: {
        stays(): Stay[] {
            if (!stays.state.stays) return []
            return values(stays.state.stays)
        },
        stay_ids(): number[] {
            return map(this.stays, s => s.id)
        },
    },
})

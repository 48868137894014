var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-2" }, [
    !_vm.hideBtns
      ? _c("div", { staticClass: "row mb-2" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "b-overlay",
                { attrs: { variant: "transparent", show: _vm.loading } },
                [
                  _c(
                    "b-btn",
                    {
                      attrs: {
                        pressed:
                          !!_vm.nihssId &&
                          !!_vm.day0Nihss &&
                          _vm.day0Nihss.id === _vm.nihssId,
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.selectDay0.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.day0Nihss
                              ? _vm.day0Nihss.descScore
                              : _vm.NIHSS_ADM_DISPLAY
                          ) +
                          "\n                    "
                      ),
                      _vm.day0Nihss
                        ? _c(
                            "span",
                            {
                              class: _vm.nihssCompleteColorClass(_vm.day0Nihss)
                            },
                            [
                              _c("fnt-a-icon", {
                                attrs: {
                                  icon: _vm.nihssCompleteIcon(_vm.day0Nihss)
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.nihssLabels, function(label) {
                    return _c(
                      "b-btn",
                      {
                        key: "label_" + label.id,
                        attrs: {
                          pressed:
                            !!_vm.nihssId &&
                            !!_vm.labeledNihssDict[label.id] &&
                            _vm.labeledNihssDict[label.id].id === _vm.nihssId,
                          disabled: _vm.loading
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.selectLabeled(label.id)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.nihssDescAndScoreByLabel(label.id) ||
                                _vm.nihssTitleToDisplay(label.title)
                            ) +
                            "\n                    "
                        ),
                        _vm.labeledNihssDict[label.id]
                          ? _c(
                              "span",
                              {
                                class: _vm.nihssCompleteColorClass(
                                  _vm.labeledNihssDict[label.id]
                                )
                              },
                              [
                                _c("fnt-a-icon", {
                                  attrs: {
                                    icon: _vm.nihssCompleteIcon(
                                      _vm.labeledNihssDict[label.id]
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.lastEditableNihss
                    ? _c(
                        "b-btn",
                        {
                          attrs: {
                            pressed:
                              !!_vm.nihssId &&
                              _vm.lastEditableNihss.id === _vm.nihssId,
                            disabled: _vm.loading
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.selectManual(_vm.lastEditableNihss.id)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.lastEditableNihss.descScore) +
                              "\n                    "
                          ),
                          _c(
                            "span",
                            {
                              class: _vm.nihssCompleteColorClass(
                                _vm.lastEditableNihss
                              )
                            },
                            [
                              _c("fnt-a-icon", {
                                attrs: {
                                  icon: _vm.nihssCompleteIcon(
                                    _vm.lastEditableNihss
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      attrs: { variant: "info", disabled: _vm.loading },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.checkAddNihss.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("+")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: _vm.askAddNihssHtmlId,
                        title: "Add NIHSS?",
                        "cancel-title": "Go back",
                        "cancel-variant": "info",
                        "ok-title": "Yes"
                      },
                      on: { ok: _vm.addNihss }
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "Are you sure you wish to add a NIHSS? There are incomplete labeled NIHSS(s):\n                        "
                        ),
                        _c(
                          "ul",
                          _vm._l(_vm.incompleteLabels, function(item) {
                            return _c("li", { key: item.id }, [
                              _vm._v(_vm._s(item.title))
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.debugMode && _vm.isPowerUser
                    ? _c("dropdown", {
                        staticClass: "mt-1",
                        attrs: {
                          ddClasses: "scrawl-scrollable-dropdown",
                          ddToggleClasses: "text-white progress-bar-striped",
                          variant: "dummy",
                          items: _vm.unlabeledNihss,
                          textField: "desc",
                          chooseText: "[ Other ]",
                          value: _vm.manualNihssID
                        },
                        on: {
                          input: function($event) {
                            return _vm.selectManual($event)
                          }
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm.debugMode && _vm.nihss
          ? _c("pre", [_vm._v(_vm._s(_vm.nihssMeta))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm.nihss
              ? _c("h5", [_vm._v(_vm._s(_vm.nihss.desc))])
              : _vm.loading
              ? _c("h5", { staticClass: "mt-3" }, [_vm._m(0)])
              : _c("h5", { staticClass: "mt-3" }, [
                  _c("em", [_vm._v("Please select a record.")])
                ])
          ]),
          _vm._v(" "),
          _vm.nihss
            ? _c("div", { staticClass: "col" }, [
                _c(
                  "p",
                  {
                    staticClass: "text-monospace bg-primary p-3 border",
                    staticStyle: { position: "absolute", right: "1.5em" }
                  },
                  [
                    _vm._v(
                      "\n                        Score: " +
                        _vm._s(_vm.nihss.score)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                        Completed: " +
                        _vm._s(_vm.nihss.complete)
                    ),
                    _c("br"),
                    _vm._v(" "),
                    !_vm.nihss.complete
                      ? _c("span", [
                          _vm._v(
                            "Questions: " +
                              _vm._s(_vm.nihss.questions_completed) +
                              "/24"
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "b-overlay",
            { attrs: { variant: "transparent", show: _vm.loading } },
            [
              _vm.nihss
                ? _c("nihss", {
                    key: _vm.nihssRefresh,
                    attrs: { stay_id: _vm.stay_id, nihss_id: _vm.nihssId }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("em", { staticClass: "loading-dots" }, [
      _vm._v("Loading"),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
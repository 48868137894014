var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", [
    _c("div", { staticClass: "pretty p-svg p-plain p-bigger" }, [
      _c("input", {
        attrs: { type: "checkbox" },
        domProps: { value: _vm.cb_value, checked: _vm.state },
        on: { change: _vm.onChange }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "state p-info-o" },
        [
          _c("fnt-a-icon", {
            staticClass: "svg",
            attrs: { icon: ["fal", "check"] }
          }),
          _vm._v(" "),
          _c("label", [_vm._v(_vm._s(_vm.title))])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
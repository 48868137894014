









import Vue from 'vue'
import stays from '@store/stays'

export default Vue.extend({
    props: {
        showPendingOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasUnsavedChanges(): boolean  {
            return stays.state.stayChanged
        },
    },
})

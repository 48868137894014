var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h6", { staticClass: "subsection-title" }, [
        _vm._v(_vm._s(_vm.titleText))
      ]),
      _vm._v(" "),
      _vm.issues.length
        ? _c(
            "b-list-group",
            { attrs: { id: _vm.htmlID, role: "tablist" } },
            [
              _c(
                "draggable",
                {
                  attrs: { handle: ".drag-handle" },
                  model: {
                    value: _vm.issues,
                    callback: function($$v) {
                      _vm.issues = $$v
                    },
                    expression: "issues"
                  }
                },
                [
                  _c(
                    "transition-group",
                    { attrs: { name: "fade" } },
                    _vm._l(_vm.issues, function(issue) {
                      return _c("issue-item", {
                        key: _vm.issueKey(issue),
                        attrs: { stay_id: _vm.stay_id, issue: issue }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("p", { staticClass: "m-0" }, [
            _c("em", [_vm._v(_vm._s(_vm.emptyText))])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { map } from 'lodash-es'
import { enumKeys } from 'models/_helpers'


export enum InvestigationStatus {
    REQUIRED = "REQUIRED",
    CHASE = "CHASE",
    ORDERED = "ORDERED",
    DONE = "DONE",
    OUTPATIENT = "OUTPATIENT",
}

const InvestigationStatus_keys = enumKeys(InvestigationStatus)
export const InvestigationStatus_values = map(InvestigationStatus_keys, k => InvestigationStatus[k])


export enum QuestionCategory {
    BACKGROUND = 'BACKGROUND',
    HISTORICAL_MEDS = 'HISTORICAL_MEDS',
    DATA_CAPTURE = 'DATA_CAPTURE',
    POSITIVES_NEGATIVES = 'POSITIVES_NEGATIVES',
    GENERAL_EXAM = 'GENERAL_EXAM',
    SOCIAL_HISTORY = 'SOCIAL_HISTORY',
    DISCHARGE_PLAN = 'DISCHARGE_PLAN',
    DIAGNOSIS_FEATURE = 'DIAGNOSIS_FEATURE',
    MDT = 'MDT',
    ISSUE_RESULT = 'ISSUE_RESULT',
    RAPID_ASMT_DC = 'RAPID_ASMT_DC',
    WARD_EXAM = 'WARD_EXAM',
    CUSTOM = 'CUSTOM',
    ASAP_PATHWAY = 'ASAP_PATHWAY',
}

const QuestionCategory_keys = enumKeys(QuestionCategory)
export const QuestionCategory_values = map(QuestionCategory_keys, k => QuestionCategory[k])


export enum QuestionType {
    BOOLEAN = 'boolean',
    /** single answer */
    CHOICE = 'choice',
    /** multiple answers */
    LIST = 'list',
    NUMBER = 'number',
    TEXT = 'text',
    LONG_TEXT = 'long_text',
    TIMESTAMP = 'timestamp',
    DATE = 'date',
}

const QuestionType_keys = enumKeys(QuestionType)
export const QuestionType_values = map(QuestionType_keys, k => QuestionType[k])


export enum TakingMedication {
    NO = 'NO',
    YES = 'YES',
    CI = 'CONTRAINDICATED'
}

const TakingMedication_keys = enumKeys(TakingMedication)
export const TakingMedication_values = map(TakingMedication_keys, k => TakingMedication[k])

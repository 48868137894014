import { map } from 'lodash-es'
import { string as dstring, boolean as dboolean, constant, either, either3, exact, guard } from 'decoders'
import { Location } from "vue-router"
import { enumKeys } from 'models/_helpers'

export interface LinkInfo {
    label: string
    to: string | Location
}

export type FeedLogic = 'NO_FEED' | 'NEUROLOGY' | 'CRITERIA'

export type FeatureVersion = 'v1' | 'v2'

export enum ViewMode {
    normal = '',
    rapid_asmt = 'rapid_asmt',
}

export const ViewMode_keys = enumKeys(ViewMode)
export const ViewMode_values = map(ViewMode_keys, k => ViewMode[k])

export type StayType = 'primary' | 'secondary'

export interface Settings {
    has_pas_feed: boolean
    feed_logic: FeedLogic
    invs_version: FeatureVersion
    nihss_version: FeatureVersion
    ux_sandbox_to: string
    ux_sandbox_from: string
    ux_signature: boolean
    ux_abf: boolean
    ux_asap_viz: boolean
    ux_asap_export: boolean
    ux_consult: boolean
    ux_consults_simple: boolean
    ux_home_all_links: boolean
    ux_location_alt_ward: boolean
    ux_location_prune_bed: boolean
    ux_login_pg_msg: string
    ux_nihss_bold: boolean
    ux_nurse_review: boolean
    ux_ts_mgmt_issues: boolean
}

const feedLogic = either3(
    constant('NO_FEED' as 'NO_FEED'),
    constant('NEUROLOGY' as 'NEUROLOGY'),
    constant('CRITERIA' as 'CRITERIA'),
)

const featureVersion = either(
    constant('v1' as 'v1'),
    constant('v2' as 'v2'),
)

const settings = exact({
    has_pas_feed: dboolean,
    feed_logic: feedLogic,
    invs_version: featureVersion,
    nihss_version: featureVersion,
    ux_sandbox_to: dstring,
    ux_sandbox_from: dstring,
    ux_signature: dboolean,
    ux_abf: dboolean,
    ux_asap_viz: dboolean,
    ux_asap_export: dboolean,
    ux_consult: dboolean,
    ux_consults_simple: dboolean,
    ux_home_all_links: dboolean,
    ux_location_alt_ward: dboolean,
    ux_location_prune_bed: dboolean,
    ux_login_pg_msg: dstring,
    ux_nihss_bold: dboolean,
    ux_nurse_review: dboolean,
    ux_ts_mgmt_issues: dboolean,
})

export const settingsDecoder = guard(settings, { style: 'simple' })

try { const _settings: Settings = settingsDecoder({}) } catch(e) {}

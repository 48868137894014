/**
 * All functionality that also calls a store module is located here.
 */

import { safeDecoding as _safeDecoding } from './decoders'
import { Guard } from 'decoders'
import store from 'store'


export function safeDecoding<T>(data: any, decoder: Guard<T>, callerDesc?: string): T {
    return _safeDecoding(data, decoder, callerDesc, store.direct.state.user.typeCheckJSON)
}

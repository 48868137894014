var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    { attrs: { label: _vm.title } },
    [
      _c("r-input", {
        attrs: { stay_id: _vm.stay_id, field: _vm.normalPropExtInfo }
      }),
      _vm._v(" "),
      _c("eye", {
        attrs: {
          stay_id: _vm.stay_id,
          section: _vm.section,
          field_name: _vm.field_name
        }
      }),
      _vm._v(" "),
      _vm.textOutput
        ? _c("p", { staticClass: "mt-3" }, [_vm._v(_vm._s(_vm.textOutput))])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
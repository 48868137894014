import { string as dstring, number as dnumber, iso8601, array as darray, guard, either } from 'decoders'

export type AnswerType =
    boolean  // boolean
    | string  // text, long_text, timestamp, date
    | number  // number, choice
    | number[]  // list
    | null

export interface CustomAnswer {
    answer: any  // AnswerType
    notes: string | null
    updated_at: string
}

const choiceListAns = either(dnumber, darray(dnumber))

export const choiceListAnsDecoder = guard(choiceListAns, { style: 'simple' })
export const datetimeAnsDecoder = guard(iso8601, { style: 'simple' })
export const strAnsDecoder = guard(dstring, { style: 'simple' })

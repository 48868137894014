




import Vue from 'vue'

import FollowUpDetails from './discharge/FollowUpDetails.vue'


export default Vue.extend({
    components: {
        FollowUpDetails,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.showAssessment && !_vm.showReasonCard
        ? _c("div", { key: "loading", staticClass: "mt-5" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          key: "reason_prompt",
          staticClass: "mb-2",
          attrs: { visible: _vm.showReasonCard }
        },
        [
          _c(
            "b-card",
            {
              staticClass: "mb-1 pt-0",
              staticStyle: { "z-index": "50" },
              style: { width: _vm.ready ? "50%" : undefined },
              attrs: {
                "no-body": "",
                id: _vm.htmlIDs.reason,
                "border-variant": _vm.reasonVariant
              }
            },
            [
              _c("b-card-header", [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("h5", { staticClass: "mb-0" }, [
                    _vm._v("Reason for accessing ASAP tool")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "b-card-body",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "mb-0" },
                    _vm._l(_vm.allReasons, function(label, val) {
                      return _c(
                        "b-radio",
                        {
                          key: val,
                          staticClass: "scrawl-radio",
                          attrs: { size: "lg", inline: "", value: val },
                          model: {
                            value: _vm.reason,
                            callback: function($$v) {
                              _vm.reason = $$v
                            },
                            expression: "reason"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(label) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                { attrs: { visible: !_vm.showAssessment } },
                [
                  _c("b-card-footer", [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("em", [
                        _vm._v("ASAP tool will load once reason is confirmed.")
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.ready
            ? _c(
                "b-collapse",
                { key: "asmt", attrs: { visible: "" } },
                [
                  _vm.meta.downloadSiteSchema
                    ? _c("h3", [_vm._v(_vm._s(_vm.siteTitle))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.collectMRN
                    ? _c(
                        "b-card",
                        { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
                        [
                          _c("b-card-header", [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("h5", { staticClass: "mb-0" }, [
                                  _vm._v("Patient Details")
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-card-body",
                            { staticClass: "py-0" },
                            [
                              _c("generic", {
                                attrs: {
                                  stay_id: _vm.stay_id,
                                  groups: _vm.patientGroups
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value: _vm.htmlIDs.presentation,
                              expression: "htmlIDs.presentation"
                            }
                          ],
                          staticClass: "r-clickable"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("h5", { staticClass: "mb-0" }, [
                                _vm._v("Presentation")
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: { id: _vm.htmlIDs.presentation, visible: "" }
                        },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "py-0" },
                            [
                              _c("generic", {
                                attrs: {
                                  stay_id: _vm.stay_id,
                                  groups: _vm.presentationGroups
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value: _vm.htmlIDs.premorbid,
                              expression: "htmlIDs.premorbid"
                            }
                          ],
                          staticClass: "r-clickable"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("h5", { staticClass: "mb-0" }, [
                                _vm._v("Premorbid Functions")
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { attrs: { id: _vm.htmlIDs.premorbid, visible: "" } },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "py-0" },
                            [
                              _c("generic", {
                                attrs: {
                                  stay_id: _vm.stay_id,
                                  groups: _vm.socialHistoryGroups
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("nihss", { attrs: { stay_id: _vm.stay_id } }),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
                    [
                      _c("b-card-header", [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("h5", { staticClass: "mb-0" }, [
                              _vm._v("NIHSS Score - " + _vm._s(_vm.nihssScore))
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value: _vm.htmlIDs.plan,
                              expression: "htmlIDs.plan"
                            }
                          ],
                          staticClass: "r-clickable"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("h5", { staticClass: "mb-0" }, [
                                _vm._v("Plan")
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { attrs: { id: _vm.htmlIDs.plan, visible: "" } },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c("h5", [_vm._v(_vm._s(_vm.assessmentLabel))]),
                              _vm._v(" "),
                              _vm._l(_vm.planChoices, function(choice) {
                                return _c(
                                  "div",
                                  { key: choice.value },
                                  [
                                    _c("checkbox", {
                                      attrs: {
                                        title: choice.label,
                                        cb_value: choice.value
                                      },
                                      model: {
                                        value: _vm.plan,
                                        callback: function($$v) {
                                          _vm.plan = $$v
                                        },
                                        expression: "plan"
                                      }
                                    })
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.outcomeNormal && _vm.hasPathways
                    ? _c(
                        "b-card",
                        { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-card-header",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: _vm.htmlIDs.pathways,
                                  expression: "htmlIDs.pathways"
                                }
                              ],
                              staticClass: "r-clickable"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("h5", { staticClass: "mb-0" }, [
                                    _vm._v("Pathways "),
                                    !_vm.pathwayChosen
                                      ? _c("span", [_vm._v("[choose one]")])
                                      : _vm._e()
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-collapse",
                            {
                              attrs: { id: _vm.htmlIDs.pathways, visible: "" }
                            },
                            [
                              _c(
                                "b-card-body",
                                [
                                  _c(
                                    "b-tabs",
                                    {
                                      on: {
                                        "activate-tab": _vm.setCurrentPathwayIdx
                                      }
                                    },
                                    [
                                      _c("b-tab", {
                                        attrs: {
                                          title: ">",
                                          "title-item-class": {
                                            "d-none": _vm.pathwayChosen
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm._l(_vm.pathways, function(pathway) {
                                        return _c(
                                          "b-tab",
                                          {
                                            key: pathway.id,
                                            attrs: { title: pathway.title }
                                          },
                                          [
                                            _c("questions", {
                                              attrs: {
                                                stay_id: _vm.stay_id,
                                                category:
                                                  _vm.QuestionCategory
                                                    .ASAP_PATHWAY,
                                                parentObject: pathway
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.outcomeNormal && _vm.showReferralAdvisory
                    ? _c(
                        "b-card",
                        { staticClass: "mb-1 pt-0", attrs: { "no-body": "" } },
                        [
                          _c("b-card-header", [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("h5", { staticClass: "mb-0" }, [
                                  _vm._v("Consider referral if...")
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("b-card-body", [
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                        Sudden onset severe deficit (particularly hemiparesis) that has fully recovered by time of arrival to ED\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("h6", { staticClass: "my-2" }, [_vm._v("OR")]),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                        Severe deficit in a previously independent patient where time of onset is as yet unknown.\n                    "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("b-card-footer", [
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                        If new symptoms arise or there is deterioration, the pathway can be repeated."
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                        This is a guide to support clinical decision making.\n                    "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("editor", { attrs: { markup: _vm.textMarkup } })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      false && _vm.ready
        ? _c(
            "b-alert",
            {
              staticClass:
                "position-fixed fixed-bottom m-0 rounded-0 text-center",
              staticStyle: { "z-index": "2000", opacity: "80%" },
              attrs: { variant: "warning" },
              model: {
                value: _vm.reasonDemo,
                callback: function($$v) {
                  _vm.reasonDemo = $$v
                },
                expression: "reasonDemo"
              }
            },
            [_vm._v("\n        Demonstration & education purposes\n    ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-center h-100" },
      [
        _c("h1", [
          _c("em", { staticClass: "loading-dots" }, [
            _vm._v("Loading"),
            _c("span", [_vm._v(".")]),
            _c("span", [_vm._v(".")]),
            _c("span", [_vm._v(".")])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
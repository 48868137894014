











import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            countdownSecs: 5,
            timer: null as number | null,
        }
    },
    watch: {
        countdownSecs(val: number) {
            if (val <= 0)
                this.$router.push({ name: 'home' })
        }
    },
    mounted() {
        this.timer = window.setInterval(() => {
            this.countdownSecs--
        }, 1000)
    },
    beforeDestroy() {
        if (this.timer !== null)
            window.clearInterval(this.timer)
    },
})

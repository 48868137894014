var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentMeds.length || _vm.otherCurrentMedsLines.length
        ? _c(
            "ul",
            { staticClass: "scrwl-meds-list" },
            [
              _vm._l(_vm.currentMeds, function(ref) {
                var title = ref.title
                var text = ref.text
                return _c("li", { key: "current-meds-" + title }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(title) +
                      ": " +
                      _vm._s(text) +
                      "\n        "
                  )
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.otherCurrentMedsLines, function(line) {
                return _c("li", { key: line }, [
                  _vm._v("\n            " + _vm._s(line) + "\n        ")
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.allergiesLines.length
        ? _c("div", { staticClass: "my-3" }, [
            _c("h6", { staticClass: "subsection-title" }, [
              _vm._v("Allergies")
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "scrwl-meds-list" },
              _vm._l(_vm.allergiesLines, function(line) {
                return _c("li", { key: "allergy-" + line }, [
                  _vm._v("\n                " + _vm._s(line) + "\n            ")
                ])
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !(
        _vm.currentMeds.length ||
        _vm.otherCurrentMedsLines.length ||
        _vm.allergiesLines.length
      )
        ? _c("div", [_vm._v("\n        No current medications selected\n    ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.showEditButton
        ? _c(
            "b-btn",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.htmlIDs.currentMeds,
                  expression: "htmlIDs.currentMeds"
                }
              ],
              staticClass: "mt-2",
              attrs: { size: "sm", variant: "info" }
            },
            [_vm._v("\n        Edit Medications\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.htmlIDs.currentMeds,
            title: "Update Current Medications",
            "ok-title": "Update",
            "ok-variant": "info",
            scrollable: ""
          },
          on: { ok: _vm.updateMeds, show: _vm.setSelectedMeds }
        },
        [
          _vm._l(_vm.categories, function(category) {
            return _c(
              "div",
              { key: category.id, staticClass: "mb-4" },
              [
                _c("h5", [_vm._v(_vm._s(category.title))]),
                _vm._v(" "),
                _vm._l(category.medications, function(ref) {
                  var id = ref.id
                  var title = ref.title
                  return _c(
                    "div",
                    { key: id },
                    [
                      _c("checkbox", {
                        attrs: { title: title, cb_value: id },
                        model: {
                          value: _vm.modalMedsList,
                          callback: function($$v) {
                            _vm.modalMedsList = $$v
                          },
                          expression: "modalMedsList"
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            )
          }),
          _vm._v(" "),
          _c("text-area", {
            attrs: {
              "non-unique-id": "current-meds-other",
              label: "Other medications"
            },
            model: {
              value: _vm.modalOtherMeds,
              callback: function($$v) {
                _vm.modalOtherMeds = $$v
              },
              expression: "modalOtherMeds"
            }
          }),
          _vm._v(" "),
          _c("text-area", {
            attrs: {
              "non-unique-id": "current-meds-allergies",
              label: "Allergies"
            },
            model: {
              value: _vm.modalAllergies,
              callback: function($$v) {
                _vm.modalAllergies = $$v
              },
              expression: "modalAllergies"
            }
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
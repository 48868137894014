




















import Vue from 'vue'
import utils from 'utils'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
    },
    data() {
        const uid = utils.getUID()
        const htmlBase = `${uid}___patient_`
        return {
            htmlIds: {
                modal: `${htmlBase}details_modal`,
                firstName: `${htmlBase}fname`,
                lastName: `${htmlBase}lname`,
                mrn: `${htmlBase}mrn`,
                approxAge: `${htmlBase}approx_age`,
            },
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        mrn: {
            get(): string { return this.stay ? this.stay.patient.mrn : '?' },
            set(val: string) {
                stays.extActSetPathValue(this.stay_id, 'patient.mrn', val)
            }
        },
        firstName: {
            get(): string { return this.stay ? (this.stay.patient.first_name || '?') : '?' },
            set(val: string) {
                stays.extActSetPathValue(this.stay_id, 'patient.first_name', val)
            }
        },
        lastName: {
            get(): string { return this.stay ? (this.stay.patient.last_name || '?') : '?' },
            set(val: string) {
                stays.extActSetPathValue(this.stay_id, 'patient.last_name', val)
            }
        },
        dob(): string | null {
            return this.stay ? this.stay.patient.dob : null
        },
        approxAge: {
            get(): number | null { return this.stay ? this.stay.patient.approx_age : null },
            set(val: number) {
                console.log('approxAge', val)
                stays.extActSetPathValue(this.stay_id, 'patient.approx_age', val || null)
            }
        }
    },
    mounted() {
        this.$emit('patient-modal-id', this.htmlIds.modal)
    }
})











import Vue from 'vue'
import * as moment from 'moment'
import { StayBase } from 'models/paddock'

export default Vue.extend({
    props: {
        stay: {
            type: Object as () => StayBase,
            required: true
        }
    },
    methods: {
        formatDT(dt_str?: string) {
            if (!dt_str)
                return '-'
            const m = moment(dt_str)
            return m.format("h:mma Do MMM YYYY")
        },
    }
})

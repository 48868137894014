






































import mixins from 'vue-typed-mixins'
import { array, string as dstring, number as dnumber, nullable, inexact, guard } from 'decoders'
import { safeDecodingStrict } from 'utils/decoders'
import store from 'store'
import UpdateChartMixin from './UpdateChartMixin.vue'

type null_number = number | null

interface Data {
    months: string[]
    door_to_alert_times: null_number[]
    door_to_ct_times: null_number[]
    door_to_lysis_decision_times: null_number[]
    door_to_ecr_decision_times: null_number[]
}

const dataDef = inexact({
    months: array(dstring),
    door_to_alert_times: array(nullable(dnumber)),
    door_to_ct_times: array(nullable(dnumber)),
    door_to_lysis_decision_times: array(nullable(dnumber)),
    door_to_ecr_decision_times: array(nullable(dnumber)),
})

const dataDecoder = guard(dataDef, { style: 'simple' })

export default mixins(UpdateChartMixin).extend({
    props: {
        data: {
            type: Object as () => Data,
            required: true,
            validator: function(val): boolean {
                if (store.direct.state.user.typeCheckJSON) {
                    const res = safeDecodingStrict(val, dataDecoder, 'TelestrokeCharts data validator')
                    return res !== undefined
                }
                return true
            }
        },
    },
    data() {
        const lineStyles = {
            borderColor: '#4472c4',
            backgroundColor: '#a5a5a5',
            lineTension: 0.1,
            fill: false,
        }

        const doorToLineOptions = {
            legend: { display: false },
            scales: {
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: 'Minutes',
                        },
                        ticks: {
                            beginAtZero: true,
                            stepSize: 10,
                            suggestedMin: 0,
                            suggestedMax: 60,
                        },
                    },
                ]
            },
        }

        return {
            lineStyles,
            doorToLineOptions,
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
    },
    watch: {
        data: {
            handler: function() {
                this.$nextTick(() => { this.renderCharts() })
            },
            immediate: true
        }
    },
    methods: {
        renderCharts(): void {
            // Door to Alert times
            this.updateChart({
                title: 'doorToAlertChart',
                chartType: 'line',
                data: {
                    labels: this.data.months,
                    datasets: [
                        {
                            label: 'Median',
                            data: this.data.door_to_alert_times,
                            ...this.lineStyles,
                        },
                    ],
                },
                options: this.doorToLineOptions,
            })

            // Door to CT times
            this.updateChart({
                title: 'doorToCTChart',
                chartType: 'line',
                data: {
                    labels: this.data.months,
                    datasets: [
                        {
                            label: 'Median',
                            data: this.data.door_to_ct_times,
                            ...this.lineStyles,
                        },
                    ],
                },
                options: this.doorToLineOptions,
            })

            this.updateChart({
                title: 'doorToLysisDecisionChart',
                chartType: 'line',
                data: {
                    labels: this.data.months,
                    datasets: [
                        {
                            label: 'Median',
                            data: this.data.door_to_lysis_decision_times,
                            ...this.lineStyles,
                        },
                    ],
                },
                options: this.doorToLineOptions,
            })

            this.updateChart({
                title: 'doorToECRDecisionChart',
                chartType: 'line',
                data: {
                    labels: this.data.months,
                    datasets: [
                        {
                            label: 'Median',
                            data: this.data.door_to_ecr_decision_times,
                            ...this.lineStyles,
                        },
                    ],
                },
                options: this.doorToLineOptions,
            })
        },
    },
})
























import Vue from 'vue'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { diagnosisFeatures, diagnosisKeyPoints } from 'utils/text/diagnosis'
import DiagnosisOverview from './DiagnosisOverview.vue'
import EditModal from '../EditModal.vue'


export default Vue.extend({
    components: {
        DiagnosisOverview,
        EditModal,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            /** override to force full diagnosis features text to be shown */
            forceShowFeatures: false,
        }
    },
    computed: {
        onlyOrtho(): boolean {
            return this.$store.direct.getters.user.onlyOrtho
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        diagnosis(): string {
            return this.stay?.diagnosis_title_ext || '?'
        },
        keyPoints(): string {
            return diagnosisKeyPoints({ stay: this.stay })
        },
        forceShowVisible(): boolean {
            return this.$store.direct.state.session.onDashboard ?
                (this.stay ? this.stay.dash_hide_qs.length > 0 : false)
                : false
        },
        forceShowText(): string {
            return this.forceShowFeatures ? 'Less Text' : 'Expand Text'
        },
        /** features text can only be hidden in dashboard view, and this can be overridden by *forceShowFeatures* */
        canHideFeatures(): boolean {
            return this.$store.direct.state.session.onDashboard ?
                (this.forceShowFeatures ? false : true) 
                : false
        },
        features(): string {
            return diagnosisFeatures({ stay: this.stay }, this.canHideFeatures)
        },
        extraHtml(): boolean {
            return !!this.keyPoints || !!this.features
        },
    },
    watch: {
        features: {
            handler: function(val: string) {
                if (!this.stay) return
                if (this.stay.diagnosis_features_text !== val)
                    stays.extActSetPathValue(this.stay_id, 'diagnosis_features_text', val)
            },
            immediate: true
        }
    }
})















import Vue from 'vue'
import { concat, filter, map } from 'lodash-es'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import AllInvs from '@sections/investigations/AllInvestigations.vue'
import EditModal from '../EditModal.vue'


export default Vue.extend({
    components: {
        AllInvs,
        EditModal,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        summaryText(): { uid: string, title: string }[] {
            if (!this.stay) return []
            const allInvestigations = concat(this.stay.investigations, this.stay.other_investigations)
            return map(filter(allInvestigations, 'outstanding'), inv => {
                const invType = inv.other ? 'other' : 'basic'
                return { uid: `inv-${invType}-${inv.id}`, title: inv.title }
            })

        },
    },
})

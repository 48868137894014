













import Vue from 'vue'
import FilterCares from './FilterCareTypes.vue'
import FilterDiagnoses from './FilterDiagnoses.vue'
import FilterDoctors from './FilterDoctors.vue'
import FilterHospitals from './FilterHospitals.vue'
import FilterSpaces from './FilterSpaces.vue'
import FilterSpecialities from './FilterSpecialities.vue'


export default Vue.extend({
    props: {
        useCares: {
            type: Boolean,
            default: true,
        },
        useDoctors: {
            type: Boolean,
            default: true,
        },
        useHospitals: {
            type: Boolean,
            default: false,
        },
        useSpaces: {
            type: Boolean,
            default: true,
        },
        useSpecialities: {
            type: Boolean,
            default: true,
        },
        useDiagnoses: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        FilterCares,
        FilterDiagnoses,
        FilterDoctors,
        FilterHospitals,
        FilterSpaces,
        FilterSpecialities,
    },
    computed: {
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        hasAdvancedFilters(): boolean {
            return this.$store.direct.getters.user.hasAdvancedFilters
        },
    },
    methods: {
        update() {
            this.$emit('update')
        },
    },
})













import Vue from 'vue'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { Patient, anonymisedPatient } from 'models/data/patient'

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        anonymise(): boolean {
            return this.$store.direct.state.user.anonymise
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        patient(): Patient | null {
            if (!this.stay || !this.stay.patient)
                return null

            return this.anonymise ? anonymisedPatient(this.stay.patient) : this.stay.patient
        }
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 d-flex justify-content-center" }, [
      _c("p", [
        _c("em", [
          _vm._v(
            "Redirecting back home " + _vm._s(_vm.countdownSecs) + " seconds..."
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-12 d-flex justify-content-center mt-5" },
      [_c("h3", [_vm._v("The link requested was not found.")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
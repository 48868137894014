var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.invInfo.schema.results.length
        ? _c(
            "b-form-group",
            { attrs: { "label-cols-sm": "4", label: "Results" } },
            _vm._l(_vm.invInfo.schema.results, function(res) {
              return _c(
                "div",
                { key: res.id },
                [
                  _c(
                    "b-check",
                    {
                      staticClass: "scrawl-checkbox",
                      attrs: {
                        size: "lg",
                        value: res.id,
                        "unchecked-value": null
                      },
                      model: {
                        value: _vm.results,
                        callback: function($$v) {
                          _vm.results = $$v
                        },
                        expression: "results"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(res.title) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { "label-cols-sm": "4", label: "Report Text / Comments" } },
        [
          _c("b-form-textarea", {
            attrs: { rows: "4" },
            model: {
              value: _vm.notes,
              callback: function($$v) {
                _vm.notes = $$v
              },
              expression: "notes"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
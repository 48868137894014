









import Vue from "vue"

export default Vue.extend({
    computed: {
        debugMode: {
            get(): boolean {
                return this.$store.direct.state.user.debug_mode
            },
            set(val: boolean) {
                this.$store.direct.dispatch.user.toggleDebugMode()
            }
        }
    }
})

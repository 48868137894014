



















import mixins from "vue-typed-mixins"
import { Hospital } from "models/med_templates/hospital"
import InputMixin from "../mixins/Input.vue"
import Radio from "@shared/inputs/Radio.vue"

export default mixins(InputMixin).extend({
    components: {
        Radio
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        hospitals(): Hospital[] {
            return this.$store.direct.state.templates.hospitals
        },
        stayHospital: {
            get(): number {
                return this.getFieldVal(this.stay_id, "hospital.id")
            },
            set(val: number) {
                if (!val)
                    this.setFieldVal(this.stay_id, 'hospital', null)
                else
                    this.setFieldVal(this.stay_id, "hospital.id", val)
            }
        }
    },
})

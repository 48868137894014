import { string as dstring, boolean as dboolean, exact } from 'decoders'

export interface Location {
    ward: string
    room: string
    bed: string
    is_stroke_bed: boolean
}

export const locationDef = exact({
    ward: dstring,
    room: dstring,
    bed: dstring,
    is_stroke_bed: dboolean
})




























import mixins from 'vue-typed-mixins'
import utils from 'utils'
import SurgeryMixin from '@shared/SurgeryMixin.vue'

export default mixins(SurgeryMixin).extend({
    data() {
        return {
            htmlId: `${utils.getUID()}___beta_features`
        }
    },
    computed: {
        show(): boolean {
            return this.$store.direct.getters.user.betaEnabled
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        showNurseReview(): boolean {
            return this.$store.direct.state.session.ux_nurse_review
        },
    },
})



















import Vue from 'vue'
import { BvTableFieldArray } from 'bootstrap-vue'

export default Vue.extend({
    data() {
        const fields: BvTableFieldArray = [
            {
                key: 'date',
                sortable: true,
            },
            'user',
            'actions',
        ]
        return {
            fields,
            items: [
                {
                    date: '2021-Aug-30 12:38:27 PM (Mon)',
                    user: '[system]',
                    actions: [
                        'Discharged on 2021-Aug-30 12:38:27 PM (Mon)',
                    ]
                },
                {
                    date: '2021-Aug-24 2:07:57 PM (Tue)',
                    user: '600123456 - EXAMPLE, User',
                    actions: [
                        '"Physio" set to "Required (need referral)"',
                    ]
                },
                {
                    date: '2021-Aug-24 9:19:56 AM (Tue)',
                    user: '600123450 - EXAMPLE2, User',
                    actions: [
                        'Ward Exam "Observations" set to "Observation chart reviewed", "Afebrile over last 24 hours"',
                        'Ward Progress notes set to "Awaiting progress. More progress needed"'
                    ]
                },
                {
                    date: '2021-Aug-20 10:45:33 PM (Fri)',
                    user: '600123456 - EXAMPLE, User',
                    actions: [
                        'Syndrome set to "Stroke"',
                    ]
                },
                {
                    date: '2021-Aug-19 4:26:19 PM (Thu)',
                    user: '[system]',
                    actions: [
                        'Record created.',
                        'Admitted on 2021-Aug-19 4:25:00 PM (Thu)'
                    ]
                },
            ]
        }
    },
    methods: {
        actionsFormatter(value: any, key: string, item: any) {

        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("editor", {
    attrs: { markup: _vm.markup, stay_id: _vm.stay_id, useSignature: false },
    on: { "text-copied": _vm.updateDischargeEMR }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
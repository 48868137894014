var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-2" }, [
    _c("div", { staticClass: "row mb-2" }, [
      _c(
        "div",
        { staticClass: "col d-flex" },
        [
          _vm.isDeveloper
            ? _c(
                "b-btn",
                {
                  staticClass: "mr-1",
                  attrs: { to: { name: "admin-specialities" } }
                },
                [_vm._v("Specialities")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isOnlyClientUserAdmin
            ? [
                _vm.isClientUserAdmin || _vm.isPowerUser
                  ? _c("b-btn", { attrs: { to: { name: "client-users" } } }, [
                      _vm._v("Users")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isPowerUser
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "ml-1",
                        attrs: { to: { name: "admin-users" } }
                      },
                      [_vm._v("Users (Adv)")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isPowerUser
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "ml-1",
                        attrs: { to: { name: "admin-groups" } }
                      },
                      [_vm._v("Groups")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAdminTeams
                  ? _c("dropdown", {
                      staticClass: "d-inline ml-1 mb-0",
                      attrs: { items: _vm.specialities, textField: "title" },
                      model: {
                        value: _vm.speciality,
                        callback: function($$v) {
                          _vm.speciality = $$v
                        },
                        expression: "speciality"
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          !!_vm.speciality
            ? [
                _vm.isDeveloper
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "ml-1",
                        attrs: { to: { name: "admin-apw" } }
                      },
                      [_vm._v("ASAP Pathways")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canAdminTeams
                  ? [
                      _c(
                        "b-btn",
                        {
                          staticClass: "ml-1",
                          attrs: { to: { name: "admin-team" } }
                        },
                        [_vm._v("Teams")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-btn",
                        {
                          staticClass: "ml-1",
                          attrs: { to: { name: "admin-ctm" } }
                        },
                        [_vm._v("Team Members")]
                      ),
                      _vm._v(" "),
                      _vm.isPowerUser
                        ? _c(
                            "b-btn",
                            {
                              staticClass: "ml-1",
                              attrs: { to: { name: "admin-tdoc" } }
                            },
                            [_vm._v("Team Doctors")]
                          )
                        : _vm._e()
                    ]
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_c("router-view")], 1)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
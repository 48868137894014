


















































































import mixins from 'vue-typed-mixins'
import NihssMixin from '@mixins/NIHSS.vue'
import StayMixin from '@mixins/Stay.vue'
import { each, find, includes, map, max, reject, sortBy } from 'lodash-es'
import utils from 'utils'

import stays from '@store/stays'
import { Item } from 'models/base'
import { NIHSS as NIHSS_i, NIHSS_ADM_DISPLAY } from 'models/data/nihss'
import { nihssLabelText, nihssDesc, nihssDescAndScore, nihssTitleToDisplay } from 'utils/text/nihss'
import { ASMT_ADM } from 'routers/constants'

import Dropdown from '@shared/inputs/Dropdown.vue'
import Nihss from './NIHSS.vue'


interface NIHSS_i_Ext extends NIHSS_i {
    desc: string
    descScore: string
}

export default mixins(StayMixin, NihssMixin).extend({
    components: {
        Dropdown,
        Nihss,
    },
    props: {
        stay_id: {
            type: Number,
            required: true,
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            askAddNihssHtmlId: `${uid}___add_nihss_modal`,
            nihssLabelId: -1,
            manualNihssID: -1,
            nihssAdded: false,
            nihssRefresh: 0,
            loading: false,
            NIHSS_ADM_DISPLAY,
            nihssTitleToDisplay,
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        inAdmission(): boolean {
            return this.$route.name === ASMT_ADM
        },
        hideBtns(): boolean {
            return this.telestrokeMode && this.inAdmission
        },
        nihssLabels(): Item[] {
            return this.$store.direct.getters.templates.speciality(this.stay?.speciality)?.nihss_labels || []
        },
        labelIds(): number[] {
            return map(this.nihssLabels, item => item.id)
        },
        /** all NIHSS, ordered by assessed_at/updated_at */
        allNihss(): NIHSS_i_Ext[] {
            const nihss_set = this.stay?.nihss_set || []
            const ext_set = map(nihss_set, nihss => ({ desc: nihssDesc(nihss), descScore: nihssDescAndScore(nihss), ...nihss }))
            return sortBy(ext_set, nihss => (nihss.assessed_at || nihss.updated_at))
        },
        day0Nihss(): NIHSS_i_Ext | undefined {
            return find(this.allNihss, nihss => nihss.stage === 'ADMISSION')
        },
        /** dictionary of NIHSS indexed by label ID, for NIHSS with label IDs */
        labeledNihssDict(): { [index: number]: NIHSS_i_Ext } {
            const dict: { [index: number]: NIHSS_i_Ext } = {}
            each(this.allNihss, nihss => {
                if (nihss.label)
                    dict[nihss.label] = nihss
            })
            return dict
        },
        unlabeledNihss(): NIHSS_i_Ext[] {
            return reject(this.allNihss, nihss => (includes(this.labelIds, nihss.label) || nihss.stage === 'ADMISSION'))
        },
        incompleteLabels() {
            const incompletes: Item[] = []
            each(this.nihssLabels, item => {
                const nihss = this.labeledNihssDict[item.id]
                if (nihss && nihss.complete)
                    return
                incompletes.push(item)
            })
            return incompletes
        },
        /** the last/most recent unlabeled NIHSS, and it also needs to be editable. */
        lastEditableNihss(): NIHSS_i_Ext | undefined {
            let lastNihss: NIHSS_i_Ext | undefined
            if (this.unlabeledNihss.length > 0)
                lastNihss = this.unlabeledNihss[this.unlabeledNihss.length - 1]
            return lastNihss?.editable ? lastNihss : undefined
        },
        labelSelected(): boolean {
            return this.nihssLabelId !== -1
        },
        selectedLabel(): Item | undefined {
            return find(this.nihssLabels, { id: this.nihssLabelId })
        },
        nihss(): NIHSS_i_Ext | undefined {
            let nihss: NIHSS_i_Ext | undefined
            if (this.manualNihssID !== -1)
                nihss = find(this.allNihss, { id: this.manualNihssID })
            else
                nihss = find(this.allNihss, { label: this.nihssLabelId })
            return nihss
        },
        nihssId(): number | undefined {
            return this.nihss?.id
        },
        nihssMeta(): any {
            if (this.nihss) {
                const nihss = this.nihss
                return {
                    id: nihss.id,
                    label: nihssLabelText(nihss.label),
                    stage: nihss.stage,
                    assessed_at: nihss.assessed_at,
                }
            }
        },
        showCreate(): boolean {
            return !this.nihss && this.labelSelected
        },
        createText(): string {
            return `Edit ${this.selectedLabel?.title || '?'}`
        },
    },
    watch: {
        inAdmission: {
            handler: function(val: boolean) {
                if (val)
                    this.$nextTick(() => { this.selectDay0() })
            },
            immediate: true
        },
        unlabeledNihss() {
            // automatically select the newly-added NIHSS
            if (this.nihssAdded) {
                this.nihssAdded = false
                const latestId = max(map(this.unlabeledNihss, nihss => nihss.id)) || -1
                this.selectManual(latestId)
            }
        },
        nihss() {
            if (this.loading)
                this.loading = false
        },
    },
    methods: {
        nihssByLabel(labelId: number | null): NIHSS_i_Ext | undefined {
            return find(this.allNihss, ['label', labelId])
        },
        nihssDescAndScore,
        nihssDescAndScoreByLabel(labelId: number): string {
            const nihss = this.nihssByLabel(labelId)
            return nihss?.descScore || ''
        },
        selectLabeled(labelId: number) {
            if (labelId !== this.nihssLabelId) {
                this.nihssRefresh++
                this.manualNihssID = -1
                this.nihssLabelId = labelId
            }
            if (!this.labeledNihssDict[labelId])
                this.addLabeledNihss(labelId)
        },
        selectManual(nihssId: number) {
            if (nihssId !== this.manualNihssID) {
                this.nihssRefresh++
                this.nihssLabelId = -1
                this.manualNihssID = nihssId
            }
        },
        selectDay0(): void {
            if (this.day0Nihss)
                this.selectManual(this.day0Nihss.id)
        },
        addLabeledNihss(labelId: number) {
            this.loading = true
            stays.extActAddNihssByLabel(this.stay_id, labelId)
        },
        checkAddNihss() {
            if (this.incompleteLabels.length === 0)
                this.addNihss()
            else
                this.$root.$emit('bv::show::modal', this.askAddNihssHtmlId)
        },
        addNihss() {
            this.loading = true
            this.nihssAdded = true
            stays.extActAddNihss(this.stay_id)
        },
    }
})

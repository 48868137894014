var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.fullMode
      ? _c(
          "div",
          [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: _vm.collapseId,
                    expression: "collapseId"
                  }
                ],
                staticClass: "w-100",
                attrs: { variant: "info", size: "sm" },
                on: {
                  click: function($event) {
                    _vm.filtersVisible = !_vm.filtersVisible
                  }
                }
              },
              [
                _vm._v("\n            Edit filters\n            "),
                _vm.filtersVisible
                  ? _c("fnt-a-icon", { attrs: { icon: ["fas", "chevron-up"] } })
                  : _c("fnt-a-icon", {
                      attrs: { icon: ["fas", "chevron-down"] }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-collapse",
              { staticClass: "mt-2 pl-1 pr-1", attrs: { id: _vm.collapseId } },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column" },
                  [
                    _c("stay-filters", {
                      staticClass: "flex-column",
                      on: { update: _vm.pull }
                    }),
                    _vm._v(" "),
                    _c(
                      "show-discharged",
                      _vm._b(
                        {
                          staticClass: "mt-2 d-flex flex-column",
                          on: { update: _vm.pull }
                        },
                        "show-discharged",
                        _vm.discharge,
                        false,
                        true
                      )
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c("hr", { staticClass: "my-3" })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("loading", {
          attrs: { staysLoading: _vm.staysLoading, stays: _vm.stays }
        }),
        _vm._v(" "),
        _c("stays", { attrs: { viewMode: _vm.viewMode, stays: _vm.stays } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
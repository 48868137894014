var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay
    ? _c("edit-modal", {
        attrs: { stay_id: _vm.stay_id, size: "xl" },
        scopedSlots: _vm._u(
          [
            {
              key: "text",
              fn: function() {
                return [
                  _c(
                    "h4",
                    [
                      _vm.indicatorsOn && !_vm.summaryOK
                        ? _c(
                            "fnt-a-layers",
                            [
                              _c("fnt-a-icon", {
                                attrs: { icon: ["fal", "notes-medical"] }
                              }),
                              _vm._v(" "),
                              _c("fnt-a-icon", {
                                staticClass: "text-danger",
                                attrs: {
                                  transform: "shrink-4 right-9 down-7",
                                  icon: ["fas", "times-circle"]
                                }
                              })
                            ],
                            1
                          )
                        : _c("fnt-a-icon", {
                            attrs: { icon: ["fal", "notes-medical"] }
                          })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [
                  _vm.summary
                    ? _c("table", { staticClass: "table" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", { staticClass: "w-40 text-right" }, [
                              _vm._v("Presentation mRS")
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.summary.modified_rankin
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.summary.modified_rankin) +
                                          "\n                        "
                                      )
                                    ])
                                  : _c(
                                      "em",
                                      {
                                        class: {
                                          "text-warning": _vm.indicatorsOn
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                            No data\n                        "
                                        )
                                      ]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "edit-modal",
                                  {
                                    staticClass: "d-inline-block",
                                    attrs: {
                                      text: "Edit",
                                      modalTitle: "Presentation mRS",
                                      btnSize: "sm",
                                      btnVariant: "info"
                                    }
                                  },
                                  [
                                    _vm.presMrsQ
                                      ? _c("question", {
                                          attrs: {
                                            stay_id: _vm.stay_id,
                                            question: _vm.presMrsQ
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticClass: "w-40 text-right" }, [
                              _vm._v("Onset to door")
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.summary.onset_to_door
                                  ? _c("span", { staticClass: "mb-0" }, [
                                      _vm._v(_vm._s(_vm.summary.onset_to_door))
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.summary.lsw_time
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "mb-0",
                                        class: {
                                          "text-warning": _vm.indicatorsOn
                                        }
                                      },
                                      [
                                        _c("em", [
                                          _vm._v("Last seen well time")
                                        ]),
                                        _vm._v(" missing")
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.summary.admission_time
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "mb-0",
                                        class: {
                                          "text-warning": _vm.indicatorsOn
                                        }
                                      },
                                      [
                                        _c("em", [
                                          _vm._v("Triage time (as per EMR)")
                                        ]),
                                        _vm._v(" missing")
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "edit-modal",
                                  {
                                    staticClass: "d-inline-block",
                                    attrs: {
                                      text: "Edit",
                                      modalTitle: "Onset to door",
                                      btnSize: "sm",
                                      btnVariant: "info"
                                    }
                                  },
                                  [
                                    _c("last-seen-well", {
                                      attrs: {
                                        stay_id: _vm.stay_id,
                                        forceShow: true
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("admission-time", {
                                      attrs: {
                                        stay_id: _vm.stay_id,
                                        label: "Triage time (as per EMR)"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticClass: "w-40 text-right" }, [
                              _vm._v("Hyperacute")
                            ]),
                            _vm._v(" "),
                            _vm.acuteSummary
                              ? _c(
                                  "td",
                                  [
                                    _c("neuro-acute-care-summary", {
                                      staticClass: "d-inline",
                                      attrs: {
                                        stay_id: _vm.stay_id,
                                        iconMode: false
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c("td", [_vm._v("Not hyperacute case")])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticClass: "w-40 text-right" }, [
                              _vm._v(_vm._s(_vm.NIHSS_ADM_DISPLAY))
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                !_vm.admNIHSS
                                  ? _c("em", [
                                      _vm._v(
                                        "No " +
                                          _vm._s(_vm.NIHSS_ADM_DISPLAY) +
                                          " data"
                                      )
                                    ])
                                  : [
                                      _vm.admNIHSS.complete
                                        ? _c("span", [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(_vm.admNIHSS.score) +
                                                "\n                            "
                                            )
                                          ])
                                        : _c("em", [_vm._v("Incomplete")]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "r-clickable" },
                                        [
                                          _c("fnt-a-icon", {
                                            directives: [
                                              {
                                                name: "b-toggle",
                                                rawName: "v-b-toggle",
                                                value: _vm.admNihssID,
                                                expression: "admNihssID"
                                              }
                                            ],
                                            attrs: {
                                              icon: ["fal", "info-circle"]
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-collapse",
                                        { attrs: { id: _vm.admNihssID } },
                                        [
                                          _c("p", { staticClass: "mb-0" }, [
                                            _vm._v(
                                              "\n                                    Score: " +
                                                _vm._s(_vm.admNIHSS.score)
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                    Questions completed: " +
                                                _vm._s(
                                                  _vm.admNIHSS
                                                    .questions_completed
                                                ) +
                                                "\n                                "
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                              ],
                              2
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticClass: "w-40 text-right" }, [
                              _vm._v(_vm._s(_vm.NIHSS_24_72_HRS_DISPLAY_SHORT))
                            ]),
                            _vm._v(" "),
                            _vm.hasNihss24To72Hrs
                              ? _c(
                                  "td",
                                  [
                                    _vm.nihss24To72Hrs.complete
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(_vm.nihss24To72Hrs.score) +
                                              "\n                        "
                                          )
                                        ])
                                      : _c(
                                          "em",
                                          {
                                            class: {
                                              "text-warning": _vm.indicatorsOn
                                            }
                                          },
                                          [_vm._v("Incomplete")]
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "r-clickable" },
                                      [
                                        _c("fnt-a-icon", {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName: "v-b-toggle",
                                              value: _vm.nihss24To72HrsID,
                                              expression: "nihss24To72HrsID"
                                            }
                                          ],
                                          attrs: {
                                            icon: ["fal", "info-circle"]
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-collapse",
                                      { attrs: { id: _vm.nihss24To72HrsID } },
                                      [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            "\n                                Score: " +
                                              _vm._s(_vm.nihss24To72Hrs.score)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                Questions completed: " +
                                              _vm._s(
                                                _vm.nihss24To72Hrs
                                                  .questions_completed
                                              ) +
                                              "\n                            "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "td",
                                  {
                                    class: { "text-warning": _vm.indicatorsOn }
                                  },
                                  [
                                    _c("em", [
                                      _vm._v(
                                        "No " +
                                          _vm._s(
                                            _vm.NIHSS_24_72_HRS_DISPLAY_SHORT
                                          ) +
                                          " data"
                                      )
                                    ])
                                  ]
                                )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticClass: "w-40 text-right" }),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "b-btn",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal",
                                        value: _vm.nihssModalID,
                                        expression: "nihssModalID"
                                      }
                                    ],
                                    attrs: { variant: "info", size: "sm" }
                                  },
                                  [_vm._v("NIHSS")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-modal",
                                  {
                                    attrs: {
                                      id: _vm.nihssModalID,
                                      size: "xl",
                                      "ok-only": "",
                                      title: "NIHSS"
                                    }
                                  },
                                  [
                                    _c("nihss-select", {
                                      attrs: { stay_id: _vm.stay_id }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", { staticClass: "w-40 text-right" }, [
                              _vm._v("Medications")
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _vm.dischargeMeds
                                  ? _c("span", [
                                      _vm.dischargeMeds.correct === null
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.dischargeMeds.title)
                                            )
                                          ])
                                        : _c("span", [
                                            _vm.dischargeMeds.correct
                                              ? _c("span", [_vm._v("Correct")])
                                              : _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "text-warning":
                                                        _vm.indicatorsOn
                                                    }
                                                  },
                                                  [_vm._v("Incorrect")]
                                                )
                                          ])
                                    ])
                                  : _c(
                                      "span",
                                      {
                                        class: {
                                          "text-warning": _vm.indicatorsOn
                                        }
                                      },
                                      [_c("em", [_vm._v("No data")])]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "edit-modal",
                                  {
                                    staticClass: "d-inline-block",
                                    attrs: {
                                      text: "Edit",
                                      modalTitle: "Medications",
                                      btnSize: "sm",
                                      btnVariant: "info"
                                    }
                                  },
                                  [
                                    _c("h6", [_vm._v("Summary")]),
                                    _vm._v(" "),
                                    _vm.dischargeMedsSummaryHTML
                                      ? _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.dischargeMedsSummaryHTML
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("hr"),
                                    _vm._v(" "),
                                    _c("current-medications", {
                                      attrs: { stay_id: _vm.stay_id }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    : _c("h5", [
                        _c("em", [_vm._v("Stroke summary unavailable.")])
                      ]),
                  _vm._v(" "),
                  _vm.strokeMechanism
                    ? _c("issue", {
                        attrs: {
                          stay_id: _vm.stay_id,
                          issue: _vm.strokeMechanism
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          4209216248
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
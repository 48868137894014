var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("admission-exam", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _vm.isNeurologyStay
        ? [
            _c("hr"),
            _vm._v(" "),
            _c("h6", { staticClass: "subsection-title" }, [
              _vm._v("\n            NIHSS\n        ")
            ]),
            _vm._v(" "),
            _c("nihss-select", { attrs: { stay_id: _vm.stay_id } })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("generic", {
        attrs: {
          stay_id: _vm.stay_id,
          groups: _vm.groups,
          "override-msg": _vm.errorMsg
        }
      }),
      _vm._v(" "),
      _c("questions", {
        staticClass: "mt-4",
        attrs: {
          stay_id: _vm.stay_id,
          category: _vm.QuestionCategory.GENERAL_EXAM,
          parentObject: _vm.syndrome
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }




















import Vue from 'vue'
import text from 'utils/text'
import { diagnosisSummary } from 'utils/text/diagnosis'
import { mdtCYOAText, mdtDischargePlan, mdtPresent, mdtSummary } from 'utils/text/mdt'
import stays from '@store/stays'
import TextBase from '../TextBase.vue'


export default Vue.extend({
    components: {
        TextBase,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: false
        },
    },
    computed: {
        markup(): string {
            const stay = stays.state.stays[this.stay_id]
            if (!stay) return ""

            let markup = '**MDT**\n\n'

            const textDetails = { stay, anonymise: this.$store.direct.state.user.anonymise }

            markup += '**Patient details:** ' + text.patientDetails(textDetails) + '\n\n'
            markup += '**Care Classification:** ' + text.careClassification({ ...textDetails, hideTitle: true }) + '\n\n'
            markup += mdtPresent(textDetails)
            markup += text.primaryDiagnosisWithKeyPoints(textDetails)
            markup += diagnosisSummary(textDetails) + '\n\n'
            markup += text.activeIssuesSummary(textDetails)
            markup += text.resolvedIssuesList(textDetails, true)
            markup += mdtCYOAText(textDetails)
            markup += mdtDischargePlan(textDetails)
            markup += mdtSummary(textDetails)

            return markup
        },
    },
    methods: {
        markMDTComplete() {
            stays.extActSetPathValue(this.stay_id, 'mdt', { complete: true })
        },
    },
})

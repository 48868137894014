var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c(
        "div",
        { staticClass: "mr-2" },
        [
          _c("label", { staticClass: "mb-0" }, [
            _vm._v("\n            Start\n        ")
          ]),
          _vm._v(" "),
          _c("date-time-picker", {
            staticClass: "w-100",
            attrs: {
              placeholder: "Select start month",
              config: { altFormat: "d M Y", dateFormat: "Y-m-d" }
            },
            model: {
              value: _vm.startDate,
              callback: function($$v) {
                _vm.startDate = $$v
              },
              expression: "startDate"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mr-2" },
        [
          _c("label", { staticClass: "mb-0" }, [
            _vm._v("\n            End \n            "),
            _c(
              "span",
              { attrs: { title: "(includes the provided month)" } },
              [
                _c("fnt-a-icon", {
                  staticClass: "r-clickable",
                  attrs: { icon: ["fal", "info-circle"] }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("date-time-picker", {
            staticClass: "w-100",
            attrs: {
              placeholder: "Select end month",
              config: { altFormat: "d M Y", dateFormat: "Y-m-d" }
            },
            model: {
              value: _vm.endDate,
              callback: function($$v) {
                _vm.endDate = $$v
              },
              expression: "endDate"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { staticClass: "mb-0 ml-1" },
        [
          _c("label", { staticClass: "mb-0" }, [_vm._v("Quick select")]),
          _vm._v(" "),
          _c(
            "b-dd",
            { staticClass: "d-block", attrs: { text: "select..." } },
            _vm._l(_vm.jumps, function(item) {
              return _c(
                "b-dd-item",
                {
                  key: item.title,
                  on: {
                    click: function($event) {
                      return _vm.setCustomRange(item.range)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(item.title) + "\n            "
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("b-form-group", { staticClass: "mb-0 ml-1" }, [
        _c("label", { staticClass: "mb-0" }, [_vm._v("Shift")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c(
            "div",
            { staticClass: "input-group-prepend" },
            [
              _c(
                "b-btn",
                {
                  attrs: { title: "month" },
                  on: {
                    click: function($event) {
                      return _vm.shiftByMonths(-1)
                    }
                  }
                },
                [
                  _c("fnt-a-icon", {
                    attrs: { icon: ["fas", "chevron-double-left"] }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { title: "week" },
                  on: {
                    click: function($event) {
                      return _vm.shiftByWeeks(-1)
                    }
                  }
                },
                [
                  _c("fnt-a-icon", { attrs: { icon: ["fas", "chevron-left"] } })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { title: "week" },
                  on: {
                    click: function($event) {
                      return _vm.shiftByWeeks(1)
                    }
                  }
                },
                [
                  _c("fnt-a-icon", {
                    attrs: { icon: ["fas", "chevron-right"] }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  attrs: { title: "month" },
                  on: {
                    click: function($event) {
                      return _vm.shiftByMonths(1)
                    }
                  }
                },
                [
                  _c("fnt-a-icon", {
                    attrs: { icon: ["fas", "chevron-double-right"] }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }














import Vue from 'vue'
import stays from '@store/stays'
import { QuestionGroup } from 'models/med_templates/question'
import Checkbox from '@shared/inputs/Checkbox.vue'

export default Vue.extend({
    components: {
        Checkbox,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        questionGroups: {
            type: Array as () => QuestionGroup[],
            required: true,
        },
    },
    computed: {
        stayQuestionGroups: {
            get(): number[] {
                return stays.state.stays[this.stay_id].question_groups
            },
            set(questionGroupIDs: number[]) {
                stays.extActSetPathValue(this.stay_id, 'question_groups', questionGroupIDs)
            },
        },
    },
})

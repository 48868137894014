









import Vue from 'vue'
import utils from 'utils'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import SurgeryTime from '@stayinputs/SurgeryTime.vue'


export default Vue.extend({
    components: {
        SurgeryTime,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            uid: utils.getUID(),
            collapseId: `${utils.getUID()}___s${this.stay_id}_surgery`,
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        postOpDay(): string {
            if (this.stay && this.stay.surgery_pod !== null)
                return `${this.stay.surgery_pod}`
            return '-'
        },
    }
})








































import mixins from 'vue-typed-mixins'
import { filter, findIndex } from 'lodash-es'
import stays from '@store/stays'
import { OtherNextStep } from 'models/data/discharge'
import InputMixin from '@mixins/Input.vue'
import Checkbox from '@shared/inputs/Checkbox.vue'

export default mixins(InputMixin).extend({
    components: {
        Checkbox,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    methods: {
        addOtherStep() {
            stays.actAddOtherNextStep(this.stay_id)
        },
        deleteOtherStep(step: OtherNextStep) {
            const stepIndex = findIndex(this.otherNextSteps, ['id', step.id])
            const steps = this.otherNextSteps.slice(0)
            steps[stepIndex].deleted = true
            this.otherNextSteps = steps
        },
        updateOtherStep(step: OtherNextStep) {
            const stepIndex = findIndex(this.otherNextSteps, ['id', step.id])
            const steps = this.otherNextSteps.slice(0)
            steps[stepIndex] = step
            this.otherNextSteps = steps
        }
    },
    computed: {
        nextSteps: {
            get(): string[] {
                return this.getFieldVal(this.stay_id, 'discharge.next_steps') || []
            },
            set(choices: string[]) {
                this.setFieldVal(this.stay_id, 'discharge.next_steps', choices)
            }
        },
        otherNextSteps: {
            get(): OtherNextStep[] {
                const steps = this.getFieldVal(this.stay_id, 'discharge.other_next_steps') || []
                return filter(steps, ['deleted', false])
            },
            set(steps: OtherNextStep[]) {
                this.setFieldVal(this.stay_id, 'discharge.other_next_steps', steps)
            }
        },
        nextStepsChoices() {
            const speciality = stays.getSpeciality(this.stay_id)
            if (speciality) return speciality.next_steps
            return []
        }
    }
})

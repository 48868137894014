import {
    string as dstring,
    number as dnumber, 
    boolean as dboolean,
    nullable, array, exact, guard
} from 'decoders'
import { Item, item } from '../base'
import { InvLabel, invLabel } from './investigation'
import { MedicationCategory, medCategories } from './medication'
import { Person, person } from './team'
import { QuestionGroup, questionGroup } from './question'
import { Stage, stageDef } from '../_enums'
import { Syndrome, syndrome } from './syndrome'


export interface Speciality extends Item {
    admission_summary_placeholder: string | null
    auto_gen: boolean
    code: string
    aiming_for_choices: Item[]
    follow_up_times: Item[]
    has_surgery: boolean
    investigation_status_labels: InvLabel[]
    location_order: string
    medication_categories: MedicationCategory[]
    next_steps: Item[]
    nihss_labels: Item[]
    people_present: Person[]
    print_header: string
    question_groups: QuestionGroup[]
    ready_for_discharge_when: Item[]
    stage: Stage
    syndromes: Syndrome[]
    rapid_asmt_syndromes: Syndrome[]
}

export type Specialities = Speciality[]

const speciality = exact({
    id: dnumber,
    title: dstring,
    admission_summary_placeholder: nullable(dstring),
    auto_gen: dboolean,
    code: dstring,
    aiming_for_choices: array(item),
    follow_up_times: array(item),
    has_surgery: dboolean,
    investigation_status_labels: array(invLabel),
    location_order: dstring,
    medication_categories: medCategories,
    next_steps: array(item),
    nihss_labels: array(item),
    people_present: array(person),
    print_header: dstring,
    question_groups: array(questionGroup),
    ready_for_discharge_when: array(item),
    stage: stageDef,
    syndromes: array(syndrome),
    rapid_asmt_syndromes: array(syndrome),
})

const specialityDecoder = guard(speciality, { style: 'simple' })
export const specialitiesDecoder = guard(array(speciality), { style: 'simple' })

try { const _speciality: Speciality = specialityDecoder({}) } catch(e) {}


export interface DiagnosisBasic extends Item {}

interface SyndromeBasic extends Item {
    diagnoses: DiagnosisBasic[]
}
export interface SpecialityBasic extends Item {
    syndromes: SyndromeBasic[]
}

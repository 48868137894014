






















import Vue from 'vue'
import utils from 'utils'
import { Stay } from 'models/data/stay'
import { InvInfo, Investigation as InvData } from 'models/data/investigation'
import Investigation from './Investigation.vue'

export default Vue.extend({
    components: {
        Investigation
    },
    props: {
        investigations: {
            type: Array as () => InvInfo[],
            required: true
        },
        stay: {
            type: Object as () => Stay | undefined,
            required: true
        },
        title: {
            type: String
        }
    },
    data() {
        const htmlID = `${utils.getUID()}___inv_list`
        return {
            htmlID,
            accordionID: `${htmlID}__accordion`
        }
    },
    computed: {
        listTitle(): string {
            return this.title || 'Investigations'
        },
        stay_id(): number {
            return this.stay ? this.stay.id : -1
        }
    },
    methods: {
        getKey(inv: InvData): string {
            return inv.other ? `other-${inv.id}` : `${inv.id}`
        }
    }
})

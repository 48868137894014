var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isTempUser
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "mb-3",
                  attrs: { variant: "outline-info", size: "sm" },
                  on: { click: _vm.toggleHideStays }
                },
                [_vm._v(_vm._s(_vm.hideBtnText))]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "table-responsive" }, [
      _c("table", { staticClass: "table table-hover" }, [
        _c("thead", [
          _c("tr", [
            _vm.debugMode
              ? _c("th", { attrs: { scope: "col" } }, [_vm._v("#")])
              : _vm._e(),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Hide")]),
            _vm._v(" "),
            _c("th", { attrs: { scope: "col" } }, [_vm._v("Patient")])
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.filteredStays, function(stay) {
            return _c(
              "tr",
              {
                key: stay.id,
                staticClass: "r-clickable",
                class: {
                  "selected-stay": _vm.selectedStay(stay.id),
                  "stay-inactive": !stay.active
                },
                on: {
                  click: function($event) {
                    return _vm.loadStay(stay.id)
                  }
                }
              },
              [
                _vm.debugMode
                  ? _c("td", { attrs: { scope: "row" } }, [
                      _vm._v(_vm._s(stay.id))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("td", { attrs: { scope: "row" } }, [
                  _c("span", { staticClass: "align-middle" }, [
                    _c(
                      "div",
                      { staticClass: "pretty p-svg p-plain p-bigger" },
                      [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          domProps: { checked: _vm.inHiddenIds(stay.id) },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.toggleHide(stay.id)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "state p-info-o" },
                          [
                            _c("fnt-a-icon", {
                              staticClass: "svg",
                              attrs: { icon: ["fal", "check"] }
                            }),
                            _vm._v(" "),
                            _c("label")
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.patientName(stay)))])
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      attrs: { "label-for": _vm.htmlId, "label-class": "pb-0 m-0" },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function() {
            return [
              _vm.collapsible
                ? [
                    _vm.visible_
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: _vm.collapseId,
                                expression: "collapseId"
                              }
                            ],
                            staticClass: "r-clickable"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.label) +
                                "\n                "
                            ),
                            _c("collapse-indicator", {
                              staticClass: "ml-1",
                              attrs: { opened: _vm.visible_ }
                            })
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: _vm.collapseId,
                                expression: "collapseId"
                              }
                            ],
                            staticClass:
                              "border rounded px-2 py-1 w-100 r-clickable"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.label) +
                                "\n                "
                            ),
                            _c("collapse-indicator", {
                              staticClass: "ml-1",
                              attrs: { opened: _vm.visible_ }
                            })
                          ],
                          1
                        )
                  ]
                : _c("span", [_vm._v(_vm._s(_vm.label))])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { id: _vm.collapseId },
          model: {
            value: _vm.visible_,
            callback: function($$v) {
              _vm.visible_ = $$v
            },
            expression: "visible_"
          }
        },
        [
          _c("b-form-textarea", {
            class: { saving: _vm.localUpdated },
            attrs: {
              id: _vm.htmlId,
              rows: _vm.rows,
              maxlength: _vm.maxlength,
              placeholder: _vm.placeholder,
              value: _vm.localValue,
              state: _vm.state,
              "aria-describedby": _vm.htmlId + "_invalid-feedback",
              autofocus: _vm.autofocus
            },
            on: { blur: _vm.handleBlur, input: _vm.handleInput_tracked }
          }),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            { attrs: { id: _vm.htmlId + "_invalid-feedback" } },
            [
              _vm._v(
                "\n        There is a maximum limit of " +
                  _vm._s(_vm.maxlength) +
                  " characters.\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
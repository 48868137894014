var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mt-2" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("h4", [_vm._v("Baseline Investigations")]),
        _vm._v(" "),
        _vm.baselines.length
          ? _c(
              "b-list-group",
              { attrs: { id: _vm.htmlID, role: "tablist" } },
              [
                _vm._l(_vm.baselines, function(info) {
                  return [
                    _c("investigation", {
                      key: "baseline__" + info.schema.id,
                      attrs: {
                        "inv-info": info,
                        stay_id: _vm.stay_id,
                        section: "baseline_investigations",
                        accordionID: _vm.accordionID
                      }
                    })
                  ]
                })
              ],
              2
            )
          : _c("p", [_c("em", [_vm._v("No baseline investigations")])])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.loggedIn
      ? _c("div", { staticClass: "col" }, [_vm._m(0)])
      : _c(
          "div",
          { staticClass: "col" },
          [
            _vm.brandImgURL
              ? _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "mx-auto my-2" }, [
                    _c("img", {
                      staticClass: "rounded",
                      staticStyle: { "max-width": "400px" },
                      attrs: { src: _vm.brandImgURL, alt: "brand image" }
                    })
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("h3", [_vm._v("Login")]),
            _vm._v(" "),
            _c(
              "b-form",
              {
                attrs: { autocomplete: "new-password" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.login.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "Username", "label-for": "usrnam" } },
                  [
                    _c("b-input", {
                      attrs: {
                        name: "usrnam",
                        type: "text",
                        autocomplete: "new-password",
                        autofocus: ""
                      },
                      model: {
                        value: _vm.username,
                        callback: function($$v) {
                          _vm.username = $$v
                        },
                        expression: "username"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-input", {
                  staticStyle: { display: "none" },
                  attrs: { type: "password" }
                }),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { label: "Password", "label-for": "password" } },
                  [
                    _c("b-input", {
                      attrs: {
                        name: "password",
                        type: "password",
                        autocomplete: "new-password"
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password = $$v
                        },
                        expression: "password"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-btn", { attrs: { variant: "info", type: "submit" } }, [
                  _vm._v("Login")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _vm.customMsg
              ? _c("div", {
                  staticClass: "mt-4",
                  domProps: { innerHTML: _vm._s(_vm.customMsg) }
                })
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-center mt-5" },
      [
        _c("h3", [
          _c("em", { staticClass: "loading-dots" }, [
            _vm._v("\n                    Setting up application "),
            _c("span", [_vm._v(".")]),
            _c("span", [_vm._v(".")]),
            _c("span", [_vm._v(".")])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
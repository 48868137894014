/**
 * A module for storing business-logic templates
 */
import { assign, clone, each, filter, find, flatten, flatMap, includes, keyBy, map } from 'lodash-es'
import { defineModule } from "direct-vuex"
import { moduleGetterContext as rootModuleGetterContext, moduleActionContext as rootModuleActionContext } from 'store'
import utils from 'utils'

import { Item } from 'models/base'
import { AsapPathway, asapPathwaysDecoder } from 'models/med_templates/asap'
import { ChoicesGroup, ChoiceItem, choicesGroupsDecoder } from 'models/med_templates/choices'
import { Doctor, doctorsDecoder } from 'models/data/doctor'
import { FollowUpWith, followUpWithsDecoder } from "models/med_templates/discharge"
import { Hospital, hospitalsDecoder } from 'models/med_templates/hospital'
import { Investigation, InvestigationGroup, invGroupsDecoder } from 'models/med_templates/investigation'
import { IssueGroups, issueGroupsDecoder, Issue } from 'models/med_templates/issue'
import { AltWardLabel, altWardLabelsDecoder } from 'models/med_templates/location'
import { Question, QuestionGroupContainer, questionsDecoder } from 'models/med_templates/question'
import { Space, spacesDecoder } from "models/med_templates/space"
import { Speciality, specialitiesDecoder } from 'models/med_templates/speciality'
import { Syndromes, syndromesDecoder } from 'models/med_templates/syndrome'
import { MDTPerson, mdtPeopleDecoder, Team, teamsDecoder, TeamDoctor, TeamMember } from 'models/med_templates/team'
import {
    AllChoices, allChoicesDecoder,
    DoctorExt, SpecialityDoctors,
} from 'models/store/templates'
import { safeDecoding } from 'utils/store'
import { Diagnosis } from 'models/med_templates/diagnosis'


export interface TemplatesState {
    asapPathways: AsapPathway[]
    choices: AllChoices
    custom_qs: Question[]
    doctors: Doctor[]
    followUpWith: FollowUpWith[]
    grouped_choices: {
        nbm: ChoicesGroup[]
    }
    hospitals: Hospital[]
    invGroups: InvestigationGroup[]
    issueGroups: IssueGroups
    mdtPeople: MDTPerson[]
    spaces: Space[]
    specialities: Speciality[]
    teams: Team[]
    wardLabels: AltWardLabel[]

    // neurology
    strokeDiagnoses: string[]
}

// NOTE this eventually should be a function returning
// a new initState each time, to allow multiple instances
// of the same module to be initialised
const initState: TemplatesState = {
    asapPathways: [],
    choices: {
        care_classification: [],
        nbm: [],
        ward_round_exam: [],
    },
    custom_qs: [],
    doctors: [],
    followUpWith: [],
    grouped_choices: {
        nbm: [],
    },
    hospitals: [],
    invGroups: [],
    issueGroups: [],
    mdtPeople: [],
    spaces: [],
    specialities: [],
    teams: [],
    wardLabels: [],

    strokeDiagnoses: [],
}

const templatesModule = defineModule({
    namespaced: true,
    state: (): TemplatesState => {
        return initState
    },
    getters: {
        /** uses the user's filter selection plus doctor.show flag to determine whether a doctor is shown */
        visibleDoctors(...args): Doctor[] {
            const { state, rootState } = moduleGetterContext(args)
            return filter(state.doctors, doctor => {
                if (doctor.show) return true
                return includes(rootState.user.filters.doctors, doctor.id)
            })
        },
        doctorsDict(...args): { [index: number]: Doctor } {
            const { state } = moduleGetterContext(args)
            return keyBy(state.doctors, 'id')
        },
        doctor(...args) {
            const { getters } = moduleGetterContext(args)
            return function getDoctor(id: number): Doctor | undefined {
                return getters.doctorsDict[id]
            }
        },
        groupedDoctors(...args): SpecialityDoctors[] {
            const { state, getters } = moduleGetterContext(args)
            return map(state.specialities, speciality => {
                const doctors: DoctorExt[] = map(
                    filter(getters.visibleDoctors, {speciality: speciality.id}),
                    doctor => assign(
                        { hoverText: `${doctor.last_name}, ${doctor.first_name} - (${doctor.ext_ids.join(', ')})` },
                        doctor
                    ))
                return {
                    id: speciality.id,
                    title: speciality.title,
                    doctors,
                }
            })
        },
        atLeastOneDoctor(...args): boolean {
            const { getters } = moduleGetterContext(args)
            let atLeastOne = false
            each(getters.groupedDoctors, group => {
                if (group.doctors.length) {
                    atLeastOne = true
                    return false  // break loop
                }
                return
            })
            return atLeastOne            
        },
        goodNBM(...args): ChoiceItem[] | undefined {
            const { state } = moduleGetterContext(args)
            const goodNBM = find(state.grouped_choices.nbm, ['label', 'Pass'])
            return goodNBM ? goodNBM.choices : undefined
        },
        goodNBMValues(...args): (string | number)[] {
            const { getters } = moduleGetterContext(args)
            const goodNBM = getters.goodNBM
            return goodNBM ? map(goodNBM, val => val.value) : []
        },
        /** This parses out investigations that have not been enabled */
        invGroups(...args): InvestigationGroup[] {
            const { state } = moduleGetterContext(args)
            const enabledOnly: InvestigationGroup[] = []
            each(state.invGroups, invGroup => {
                const newGroup = clone(invGroup)
                newGroup.investigations = filter(newGroup.investigations, 'enabled')
                enabledOnly.push(newGroup)
            })
            return enabledOnly
        },
        /** This returns all investigations, including disabled ones */
        allInvs(...args): Investigation[] {
            const { state } = moduleGetterContext(args)
            return flatMap(state.invGroups, 'investigations')
        },
        /** This parses out issues that have not been enabled */
        issueGroups(...args): IssueGroups {
            const { state } = moduleGetterContext(args)
            const enabledOnly: IssueGroups = []
            each(state.issueGroups, issueGroup => {
                const newGroup = clone(issueGroup)
                newGroup.issues = filter(newGroup.issues, 'enabled')
                enabledOnly.push(newGroup)
            })
            return enabledOnly
        },
        /** This returns all issues, including disabled ones */
        allIssues(...args): Issue[] {
            const { state } = moduleGetterContext(args)
            return flatMap(state.issueGroups, 'issues')
        },
        mdtPeopleDict(...args): { [index: number]: MDTPerson } {
            const { state } = moduleGetterContext(args)
            return keyBy(state.mdtPeople, 'id')
        },
        mdtPeopleBySpeciality(...args): { [index: number]: MDTPerson[] } {
            const { state } = moduleGetterContext(args)
            const dict: { [index: number]: MDTPerson[] } = {}
            each(state.mdtPeople, person => {
                if (!(person.speciality in dict))
                    dict[person.speciality] = []
                dict[person.speciality].push(person)
            })
            return dict
        },
        allNihssLabels(...args): Item[] {
            const { state } = moduleGetterContext(args)
            return flatMap(state.specialities, sp => sp.nihss_labels)
        },
        /**
         * Returns a dictionary in the form of { "q_<question_id>": question }
         * @param args 
         */
        allQuestions(...args): { [index: string]: Question } {
            const { state } = moduleGetterContext(args)
            const questions: { [index: string]: Question } = {}

            const syndromes = flatten(map(state.specialities, 'syndromes'))
            const diagnoses = flatten(map(syndromes, 'diagnoses'))
            const issues = flatten(map(state.issueGroups, 'issues'))

            const parents = flatten<QuestionGroupContainer>([state.specialities, syndromes, diagnoses, issues])

            each(parents, parent => {
                each(parent.question_groups, group => {
                    each(group.questions, question => {
                        questions[`q_${question.question.id}`] = question.question
                    })
                })
            })

            return questions
        },
        speciality(...args) {
            const { state } = moduleGetterContext(args)
            return function getSpeciality(id?: number | null): Speciality | undefined {
                return find(state.specialities, ['id', id])
            }
        },
        specialityIds(...args): number[] {
            const { state } = moduleGetterContext(args)
            return map(state.specialities, sp => sp.id)
        },
        specialityFromTitle(...args) {
            const { state } = moduleGetterContext(args)
            return function getSpeciality(title: string): Speciality | undefined {
                return find(state.specialities, ['title', title])
            }
        },
        allSyndromes(...args): Syndromes {
            const { state } = moduleGetterContext(args)
            return flatMap(state.specialities, sp => sp.syndromes)
        },
        rapidAsmtSyndromes(...args): Syndromes {
            const { state } = moduleGetterContext(args)
            return flatMap(state.specialities, sp => sp.rapid_asmt_syndromes)
        },
        allDiagnoses(...args): Diagnosis[] {
            const { getters } = moduleGetterContext(args)
            return flatMap(getters.allSyndromes, syn => syn.diagnoses)
        },
        allTeamDoctors(...args): { [index: string]: TeamDoctor } {
            const { state } = moduleGetterContext(args)
            const doctors: { [index: string]: TeamDoctor } = {}
            each(state.teams, team => {
                each(team.doctors, doctor => {
                    doctors[doctor.id] = doctor
                })
            })
            return doctors
        },
        allTeamMembers(...args): { [index: string]: TeamMember } {
            const { state } = moduleGetterContext(args)
            const members: { [index: string]: TeamMember } = {}
            each(state.teams, team => {
                each(team.members, member => {
                    members[member.id] = member
                })
            })
            return members
        },
        wardLabelsDict(...args): { [index: string]: string } {
            const { state } = moduleGetterContext(args)
            const dict: { [index: string]: string } = {}
            each(state.wardLabels, labelInfo => {
                dict[labelInfo.label] = labelInfo.alt_label
            })
            return dict
        },
    },
    mutations: {
        setAllChoices(state, data) {
            state.choices = safeDecoding(data, allChoicesDecoder, 'store.templates.setAllChoices')
        },
        setAsapPathways(state, data) {
            state.asapPathways = safeDecoding(data, asapPathwaysDecoder, 'store.templates.setAsapPathways')
        },
        setCustomQs(state, data) {
            state.custom_qs = safeDecoding(data, questionsDecoder, 'store.templates.questionsDecoder')
        },
        setDoctors(state, data) {
            state.doctors = safeDecoding(data, doctorsDecoder, 'store.templates.setDoctors')
        },
        setFollowUpWiths(state, data) {
            state.followUpWith = safeDecoding(data, followUpWithsDecoder, 'store.templates.setFollowUpWiths')
        },
        setHospitals(state, data) {
            state.hospitals = safeDecoding(data, hospitalsDecoder, 'store.templates.setHospitals')
        },
        setInvGroups(state, data) {
            state.invGroups = safeDecoding(data, invGroupsDecoder, 'store.templates.setInvGroups')
        },
        setIssueGroups(state, data) {
            state.issueGroups = safeDecoding(data, issueGroupsDecoder, 'store.templates.setIssueGroups')
        },
        setMDTPeople(state, data) {
            state.mdtPeople = safeDecoding(data, mdtPeopleDecoder, 'store.templates.setMDTPeople')
        },
        setNBM(state, data) {
            state.grouped_choices.nbm = safeDecoding(data, choicesGroupsDecoder, 'store.templates.setNBM')
        },
        setSpaces(state, data) {
            state.spaces = safeDecoding(data, spacesDecoder, 'store.templates.setSpaces')
        },
        setSpecialities(state, data) {
            state.specialities = safeDecoding(data, specialitiesDecoder, 'store.templates.setSpeciality')
        },
        setSpecialityPrintHeader(state, data) {
            const info: { id: number, print_header: string } = data
            const speciality = find(state.specialities, { id: info.id })
            if (speciality)
                speciality.print_header = info.print_header
        },
        setTeams(state, data) {
            state.teams = safeDecoding(data, teamsDecoder, 'store.templates.setTeams')
        },
        setWardLabels(state, data) {
            state.wardLabels = safeDecoding(data, altWardLabelsDecoder, 'store.templates.setAltWardLabels')
        },
        setStrokeDiagnoses(state, data) {
            state.strokeDiagnoses = data
        },
    },
    actions: {
        pullAsapInitData(context) {
            const { commit } = moduleActionContext(context)
            return utils.request
            .get('/asap_init_data/')
            .then(res => {
                commit.setAsapPathways(res.body.asap_pathways)
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
        },
    }
})

export default templatesModule

const moduleGetterContext = (args: [any, any, any, any]) => rootModuleGetterContext(args, templatesModule)
const moduleActionContext = (context: any) => rootModuleActionContext(context, templatesModule)

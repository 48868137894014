










import Vue from 'vue'
import { filter, includes, map } from 'lodash-es'
import FilterBase from './FilterBase.vue'
import { Hospital } from 'models/med_templates/hospital'


/**
 * This filter component operates a bit different from the others. If 'All' is selected,
 * it explicitly sets the IDs list to the user's assigned hospitals' IDs.
 * This produces the desired default outcomes for 'All':
 * - if a user has assigned hospitals, the query is such that the backend will filter by by those hospitals
 * - if a user has no assigned hospitals, the hospital IDs list is empty, and thus the backend will skip filtering by hospitals anyway
 */
export default Vue.extend({
    components: {
        FilterBase,
    },
    data() {
        return {
            selectedIDs: [] as number[]
        }
    },
    computed: {
        allHospitals(): Hospital[] {
            return this.$store.direct.state.templates.hospitals
        },
        userHospitalIDs(): number[] {
            return this.$store.direct.state.user.hospitals
        },
        /** Current user's assigned hospitals */
        userHospitals(): readonly Hospital[] {
            return this.$store.direct.getters.user.hospitals
        },
        hospitals: {
            get(): number[] {
                return this.$store.direct.state.user.filters.hospitals
            },
            set(hIDs: number[]) {
                this.$store.direct.dispatch.user.updateFilters({ hospitals: hIDs })
                this.$emit('update')
            }
        },
        selectedText(): string {
            const selected = filter(this.allHospitals, d => includes(this.selectedIDs, d.id))
            const labels = map(selected, opt => opt.title)
            return labels.join(", ") || "[None]"
        },
    },
})

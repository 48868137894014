var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPowerUser
    ? _c(
        "div",
        [
          _c("h3", [_vm._v("Font Awesome Icons")]),
          _vm._v(" "),
          _vm._l(_vm.icons, function(icon) {
            return _c(
              "p",
              { key: icon[0] + "-" + icon[1] },
              [
                _c("fnt-a-icon", { staticClass: "svg", attrs: { icon: icon } }),
                _vm._v(
                  "\n        " + _vm._s(icon[0] + "-" + icon[1]) + "\n    "
                )
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.uid,
              expression: "uid"
            }
          ],
          staticClass: "d-flex align-items-start r-clickable"
        },
        [
          _c(
            "div",
            [
              _vm._t("title", function() {
                return [
                  _c("h5", { staticClass: "d-inline-block mb-0" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.title) +
                        "\n                "
                    )
                  ])
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("collapse-indicator", {
            staticClass: "ml-2 mt-1",
            attrs: { opened: _vm.visible_ }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { id: _vm.uid },
          model: {
            value: _vm.visible_,
            callback: function($$v) {
              _vm.visible_ = $$v
            },
            expression: "visible_"
          }
        },
        [_c("b-card-body", [_vm._t("default")], 2)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }


















































import Vue from 'vue'
import { find } from 'lodash-es'
import { Hyperacutes } from 'models/store/user'
import { Speciality } from 'models/med_templates/speciality'
import { QuestionChoice } from 'models/med_templates/question'
import DateTimePicker from '@shared/inputs/DateTimePicker.vue'
import Dropdown from '@shared/inputs/Dropdown.vue'

export default Vue.extend({
    components: {
        DateTimePicker,
        Dropdown,
    },
    data() {
        return {
            loaded: false,
        }
    },
    computed: {
        neurology(): Speciality | undefined {
            return this.$store.direct.getters.templates.specialityFromTitle('Neurology')
        },
        allDoctors(): QuestionChoice[] {
            if (!this.neurology) return []
            const syndrome = find(this.neurology.syndromes, ['title', 'Stroke'])
            if (!syndrome) return []
            const group = find(syndrome.question_groups, ['title', 'Hyperacute review'])
            if (!group) return []
            const doctorsQ = find(group.questions, ['question.title', 'Doctors involved'])
            if (!doctorsQ) return []
            return doctorsQ.question.choices
        },
        filters(): Hyperacutes {
            return this.$store.direct.state.user.breakdowns.hyperacutes
        },
        start: {
            get(): string | null { return this.filters.start },
            set(val: string | null) {
                this.$store.direct.dispatch.user.updateHyperacutes({ start: val })
            }
        },
        end: {
            get(): string | null { return this.filters.end },
            set(val: string | null) {
                this.$store.direct.dispatch.user.updateHyperacutes({ end: val })
            }
        },
        doctors: {
            get(): number[] { return this.filters.doctors },
            set(val: number[]) {
                this.$store.direct.dispatch.user.updateHyperacutes({ doctors: val })
            }
        },
        filtersOK(): boolean {
            return !!this.start && !!this.end
        },
    },
})

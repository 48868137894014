/**
 * Eventual replacement for stays module. Based on direct-vuex
 */

import { defineModule, localActionContext, localGetterContext } from "direct-vuex"
import { safeDecoding } from 'utils/store'
import { request } from 'utils/request'
import { FullSchema, fullSchemaDecoder } from 'models/schema/stay'


export interface StaysState {
    fullSchema: FullSchema | null
}

// NOTE this eventually should be a function returning
// a new initState each time, to allow multiple instances
// of the same module to be initialised
const initState: StaysState = {
    fullSchema: null
}

const staysModule = defineModule({
    namespaced: true,
    state: (): StaysState => {
        return initState
    },
    mutations: {
        fullSchema(state, val: FullSchema) {
            state.fullSchema = val
        },
    },
    actions: {
        pullFullSchema(context) {
            const { commit } = moduleActionContext(context)
            return request.get('/stay/full/generic_schema/')
            .then(res => {
                commit.fullSchema(safeDecoding(res.body, fullSchemaDecoder, 'stays.pullFullSchema'))
            })
        },
    }
})

export default staysModule

const moduleGetterContext = (args: [any, any, any, any]) => localGetterContext(args, staysModule)
const moduleActionContext = (context: any) => localActionContext(context, staysModule)

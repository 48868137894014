var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-center" },
      [
        _c("h3", { staticClass: "text-center" }, [
          _vm._v("Breakdown by Presentation Type")
        ]),
        _vm._v(" "),
        _c(
          "b-button",
          {
            staticClass: "ml-3",
            attrs: { variant: "info", size: "sm" },
            on: {
              click: function($event) {
                _vm.showRawData = !_vm.showRawData
              }
            }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.showRawData ? "Hide raw data" : "Show raw data") +
                "\n        "
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("table", { staticClass: "table table-hover text-center mt-2" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th"),
            _vm._v(" "),
            _vm._l(_vm.presentationTypes, function(type) {
              return _c("th", { key: type }, [
                _vm._v("\n                " + _vm._s(type) + "\n            ")
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.loading || _vm.staysLoading
        ? _c("tbody", [
            _c("tr", [
              _c(
                "td",
                { attrs: { colspan: 2 + _vm.presentationTypes.length } },
                [_vm._m(0)]
              )
            ])
          ])
        : _c("tbody", [
            _c(
              "tr",
              [
                _c("td", [_vm._v("ASAP used")]),
                _vm._v(" "),
                _vm._l(_vm.staysGroups, function(stays) {
                  return _c(
                    "td",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.asapUsed(stays)) +
                          "\n                    "
                      ),
                      _c("stays-info", {
                        attrs: {
                          field: "asap_used",
                          title: "ASAP used",
                          stays: stays
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.showRawData
              ? _c(
                  "tr",
                  [
                    _c("td"),
                    _vm._v(" "),
                    _vm._l(_vm.staysGroups, function(stays) {
                      return _c("td", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.listValues(stays, "asap_used")
                            )
                          }
                        })
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "tr",
              [
                _c("td", [_vm._v("Call before CT")]),
                _vm._v(" "),
                _vm._l(_vm.staysGroups, function(stays) {
                  return _c(
                    "td",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.callBeforeCT(stays)) +
                          "\n                    "
                      ),
                      _c("stays-info", {
                        attrs: {
                          field: "first_contact_made_prior_to_ct",
                          title: "Call before CT",
                          stays: stays
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.showRawData
              ? _c(
                  "tr",
                  [
                    _c("td"),
                    _vm._v(" "),
                    _vm._l(_vm.staysGroups, function(stays) {
                      return _c("td", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.listValues(
                                stays,
                                "first_contact_made_prior_to_ct"
                              )
                            )
                          }
                        })
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "tr",
              [
                _c("td", [_vm._v("Door to Alert")]),
                _vm._v(" "),
                _vm._l(_vm.staysGroups, function(stays) {
                  return _c(
                    "td",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.doorToAlert(stays)) +
                          "\n                    "
                      ),
                      _c("stays-info", {
                        attrs: {
                          field: "door_to_alert",
                          title: "Door to Alert",
                          stays: stays
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.showRawData
              ? _c(
                  "tr",
                  [
                    _c("td"),
                    _vm._v(" "),
                    _vm._l(_vm.staysGroups, function(stays) {
                      return _c("td", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.listValues(stays, "door_to_alert")
                            )
                          }
                        })
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "tr",
              [
                _c("td", [_vm._v("Door to CT")]),
                _vm._v(" "),
                _vm._l(_vm.staysGroups, function(stays) {
                  return _c(
                    "td",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.doorToCT(stays)) +
                          "\n                    "
                      ),
                      _c("stays-info", {
                        attrs: {
                          field: "door_to_ct",
                          title: "Door to CT",
                          stays: stays
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.showRawData
              ? _c(
                  "tr",
                  [
                    _c("td"),
                    _vm._v(" "),
                    _vm._l(_vm.staysGroups, function(stays) {
                      return _c("td", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.listValues(stays, "door_to_ct")
                            )
                          }
                        })
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "tr",
              [
                _c("td", [_vm._v("Door to Lysis Decision")]),
                _vm._v(" "),
                _vm._l(_vm.staysGroups, function(stays) {
                  return _c(
                    "td",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.doorToLysisDecision(stays)) +
                          "\n                    "
                      ),
                      _c("stays-info", {
                        attrs: {
                          field: "door_to_lysis_decision",
                          title: "Door to Lysis Decision",
                          stays: stays
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.showRawData
              ? _c(
                  "tr",
                  [
                    _c("td"),
                    _vm._v(" "),
                    _vm._l(_vm.staysGroups, function(stays) {
                      return _c("td", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.listValues(stays, "door_to_lysis_decision")
                            )
                          }
                        })
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "tr",
              [
                _c("td", [_vm._v("Door to ECR Decision")]),
                _vm._v(" "),
                _vm._l(_vm.staysGroups, function(stays) {
                  return _c(
                    "td",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.doorToECRDecision(stays)) +
                          "\n                    "
                      ),
                      _c("stays-info", {
                        attrs: {
                          field: "door_to_ecr_decision",
                          title: "Door to ECR Decision",
                          stays: stays
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.showRawData
              ? _c(
                  "tr",
                  [
                    _c("td"),
                    _vm._v(" "),
                    _vm._l(_vm.staysGroups, function(stays) {
                      return _c("td", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.listValues(stays, "door_to_ecr_decision")
                            )
                          }
                        })
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "tr",
              [
                _c("td", [_vm._v("Door to Needle")]),
                _vm._v(" "),
                _vm._l(_vm.staysGroups, function(stays) {
                  return _c(
                    "td",
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.doorToNeedle(_vm.filterLysisStays(stays))
                          ) +
                          "\n                    "
                      ),
                      _c("stays-info", {
                        attrs: {
                          field: "door_to_needle",
                          title: "Door to Needle",
                          stays: _vm.filterLysisStays(stays)
                        }
                      })
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.showRawData
              ? _c(
                  "tr",
                  [
                    _c("td"),
                    _vm._v(" "),
                    _vm._l(_vm.staysGroups, function(stays) {
                      return _c("td", [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.listValues(
                                _vm.filterLysisStays(stays),
                                "door_to_needle"
                              )
                            )
                          }
                        })
                      ])
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("em", { staticClass: "loading-dots" }, [
        _vm._v("\n                        Loading"),
        _c("span", [_vm._v(".")]),
        _c("span", [_vm._v(".")]),
        _c("span", [_vm._v(".")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
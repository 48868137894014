var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSpaces
    ? _c("filter-base", {
        attrs: { options: _vm.spaces, label: "Spaces" },
        model: {
          value: _vm.selectedSpaces,
          callback: function($$v) {
            _vm.selectedSpaces = $$v
          },
          expression: "selectedSpaces"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
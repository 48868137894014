var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay && _vm.stay.acute_care_summary
    ? _c(
        "div",
        [
          _vm.iconMode
            ? _c(
                "span",
                {
                  staticClass: "r-hoverable",
                  attrs: { title: "Acute Care Summary" }
                },
                [
                  _c("fnt-a-icon", {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal",
                        value: _vm.htmlId,
                        expression: "htmlId"
                      }
                    ],
                    staticClass: "r-clickable fa-lg",
                    attrs: { icon: ["fal", "file-alt"] },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  })
                ],
                1
              )
            : _c(
                "b-btn",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal",
                      value: _vm.htmlId,
                      expression: "htmlId"
                    }
                  ],
                  class: { "mb-1": _vm.add_mb },
                  attrs: {
                    size: "sm",
                    variant: "info",
                    title: "Acute Care Summary"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                    }
                  }
                },
                [_vm._v("\n        Summary\n    ")]
              ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                id: _vm.htmlId,
                size: "xl",
                title: _vm.patientName,
                scrollable: "",
                okOnly: ""
              }
            },
            [
              _c("neuro-acute-care-summary", {
                attrs: { stay_id: _vm.stay_id }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
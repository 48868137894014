







import Vue from 'vue'
import LastSeenWell from '@admission/LastSeenWell.vue'
import PresentingComplaint from '@inputs/PresentingComplaint.vue'

export default Vue.extend({
    components: {
        LastSeenWell,
        PresentingComplaint,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.modalId,
        title:
          _vm.field.infoTitle ||
          _vm.field.title + ": " + _vm.data[_vm.field.key].length + " stays",
        size: "lg",
        scrollable: "",
        okOnly: ""
      }
    },
    [
      _c(
        "b-list-group",
        { attrs: { role: "tablist" } },
        _vm._l(_vm.infoStays, function(stay) {
          return _c("stay-info", {
            key: stay.id,
            attrs: {
              stay: stay,
              field: _vm.field,
              textVersion: _vm.textVersion
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
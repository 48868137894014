var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("print-base", {
    attrs: {
      stay_ids: _vm.stay_ids,
      urlSuffix: "print-pdf",
      labelBase: "PDF",
      versions: [2],
      defaultVer: 2
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
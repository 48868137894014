import { constant, either4 } from 'decoders'

/** Describe the stage of a stay */
export type Stage = 'UNKNOWN' | 'ADMISSION' | 'WARD' | 'NURSE_REVIEW'

export const stageDef = either4(
    constant('UNKNOWN' as 'UNKNOWN'),
    constant('ADMISSION' as 'ADMISSION'),
    constant('WARD' as 'WARD'),
    constant('NURSE_REVIEW' as 'NURSE_REVIEW')
)

export type SurgeryStage = 'UNKNOWN' | 'NA' | 'PRE_OP' | 'OP'

export const surgeryStageDef = either4(
    constant('UNKNOWN' as 'UNKNOWN'),
    constant('NA' as 'NA'),
    constant('PRE_OP' as 'PRE_OP'),
    constant('OP' as 'OP'),
)

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    [
      _c(
        "b-navbar",
        {
          attrs: {
            toggleable: "md",
            fixed: "top",
            type: "dark",
            variant: "dark"
          }
        },
        [
          _c("b-navbar-toggle", { attrs: { target: "nav_collapse" } }),
          _vm._v(" "),
          _c(
            "b-navbar-brand",
            { attrs: { to: { name: "home" }, title: _vm.version } },
            [
              _c("img", {
                attrs: {
                  id: "brand-image",
                  src: "static/images/scrawl-ribbon.png",
                  alt: _vm.documentTitle
                }
              }),
              _vm._v(
                "\n            " + _vm._s(_vm.documentTitle) + "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { "is-nav": "", id: "nav_collapse" } },
            [
              _c(
                "b-navbar-nav",
                [
                  _c("b-nav-item", { attrs: { to: { path: "/" } } }, [
                    _vm._v("Full")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("changes-indicator", { attrs: { showPendingOnly: "" } }),
              _vm._v(" "),
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-item",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal",
                          value: _vm.helpModalId,
                          expression: "helpModalId"
                        }
                      ]
                    },
                    [_vm._v("Help")]
                  ),
                  _vm._v(" "),
                  _c("changelog", { attrs: { mainNav: true } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("help-modal", {
        on: {
          "support-modal-id": function($event) {
            _vm.helpModalId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import Vue from "vue"
import { find, get } from "lodash-es"
import stays from "@store/stays"
import { PathValue } from "models/payload"

export default Vue.extend({
    methods: {
        getFieldVal(stay_id: number, path: string): any | undefined {
            return get(stays.state.stays[stay_id] || {}, path, undefined)
        },
        getArrayedObjVal(stay_id: number, path: string, obj_id: number, nested_path: string): any | undefined {
            const stay = stays.state.stays[stay_id]
            if (!stay) return undefined
            const list = get(stay, path, [])
            const obj = find(list, { id: obj_id })
            if (obj === undefined) return undefined
            return obj[nested_path]
        },
        setFieldVal(stay_id: number, path: string, val: any) {
            stays.actSetPathValue({ id: stay_id, path: path, val: val })
        },
        setArrayedObjVal(stay_id: number, path: string, obj_id: number, nested_path: string, value: any): void {
            const values: { [index: string]: any } = { id: obj_id }
            values[nested_path] = value
            stays.actSetListedObj({
                id: stay_id,
                path,
                obj_id,
                values,
            })
        },
        getError(stay_id: number, path: string): PathValue | undefined {
            const errors: PathValue[] | undefined = stays.state.errors[stay_id]
            return errors ? find(errors, ["path", path]) : undefined
        },
        clearError(stay_id: number, path: string) {
            stays.mutClearError({ id: stay_id, path: path, val: "" })
        }
    }
})

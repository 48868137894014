var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v("\n    Speciality: " + _vm._s(_vm.stay.speciality_title)),
    _c("br"),
    _vm._v("\n    Admitted: " + _vm._s(_vm.formatDT(_vm.stay.admission_time))),
    _c("br"),
    _vm._v(
      "\n    Discharged: " + _vm._s(_vm.formatDT(_vm.stay.discharge_time))
    ),
    _c("br"),
    _vm._v("\n    Visit ID: " + _vm._s(_vm.stay.ext_id) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
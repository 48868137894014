









import Vue from 'vue'

import AdmissionSummary from './AdmissionSummary.vue'
import CareClassification from './CareClassification.vue'
import DischargeText from '@text/Discharge.vue'
import TextAreaEnhanced from '@inputs/TextAreaEnhanced.vue'


export default Vue.extend({
    components: {
        AdmissionSummary,
        CareClassification,
        DischargeText,
        TextAreaEnhanced,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
})

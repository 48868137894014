






























































import Vue from 'vue'
import mixins from 'vue-typed-mixins'
import stays from '@store/stays'
import AdvInvMixin from './AdvInvMixin.vue'

interface Selection {
    right_artery: number | null
    left_artery: number | null
    intra_occ: number | null
    side: number | null
    sites: number[]
}

export default mixins(AdvInvMixin).extend({
    data() {
        const selection: Selection = {
            right_artery: null,
            left_artery: null,
            intra_occ: null,
            side: null,
            sites: []
        }

        return {
            fieldIds: {
                right_artery: ['no_sten_r', 'maj_sten_r', 'all_oc_r'],
                left_artery: ['no_sten_l', 'maj_sten_l', 'all_oc_l'],
                intra_occ: ['normal', 'io'],
                side: ['right', 'left', 'basilar'],
                sites: ['car_t', 'm1_mid_ca', 'm2_mid_ca', 'dist_mid_ca', 'ant_ca', 'post_ca']
            },
            selection,
        }
    },
    computed: {
        isIntraOcclusion(): boolean {
            return this.selection.intra_occ === this.fieldToIdMap['io']
        },
        localResults(): number[] {
            let data: number[] = []
            for (let x of [this.selection.left_artery, this.selection.right_artery, this.selection.intra_occ, this.selection.side])
                if (x) data.push(x)
            data = data.concat(this.selection.sites)
            return data
        },
    },
    watch: {
        'selection.intra_occ'(val: number | null) {
            if (val === this.fieldToIdMap['normal']) {
                this.selection.side = null
                this.selection.sites = []
            }
        },
    }
})


















































































import mixins from "vue-typed-mixins"
import InputMixin from "@mixins/Input.vue"
import { find, get, map } from "lodash-es"

import stays from "@store/stays"
import { Stay } from 'models/data/stay'
import { Diagnosis } from "models/med_templates/diagnosis"
import { QuestionCategory } from 'models/med_templates/question'
import { Syndrome } from "models/med_templates/syndrome"

import DataCapture from "@inputs/DataCapture.vue"
import DiagnosisHelp from '@inputs/diagnosis/DiagnosisHelp.vue'
import DiagnosisSummary from "@inputs/diagnosis/DiagnosisSummary.vue"
import Dropdown from "@shared/inputs/Dropdown.vue"
import MainDiagnosis from '@inputs/diagnosis/Diagnosis.vue'
import ManagementPlanSteps from "@inputs/diagnosis/ManagementPlanSteps.vue"
import Questions from '@stayinputs/Questions.vue'
import RapidAssessmentText from "./RapidAssessmentText.vue"
import TextArea from "@shared/inputs/TextArea.vue"
import TextNihssSelect from 'components/text/NIHSSSelect.vue'

export default mixins(InputMixin).extend({
    components: {
        DataCapture,
        DiagnosisHelp,
        DiagnosisSummary,
        Dropdown,
        MainDiagnosis,
        ManagementPlanSteps,
        Questions,
        RapidAssessmentText,
        TextArea,
        TextNihssSelect,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            QuestionCategory,
            OTHER_DIAGNOSIS_VALUE: -999
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        syndrome(): Syndrome | undefined {
            if (!this.stay) return
            return find(this.$store.direct.getters.templates.rapidAsmtSyndromes, ['id', this.stay.rapid_assessment_syndrome])
        },
        diagnosisID: {
            get(): number | null {
                const diagnosisID = this.getFieldVal(this.stay_id, "rapid_assessment_diagnosis")
                if (diagnosisID === null && this.otherDiagnosisSelected) {
                    return this.OTHER_DIAGNOSIS_VALUE
                }
                return diagnosisID
            },
            set(diagnosisID: number | null) {
                if (diagnosisID === this.OTHER_DIAGNOSIS_VALUE) {
                    this.setFieldVal(this.stay_id, 'rapid_assessment_diagnosis', null)
                    this.otherDiagnosisSelected = true
                } else {
                    this.otherDiagnosisSelected = false
                    this.setFieldVal(this.stay_id, 'rapid_assessment_other_diagnosis_title', null)
                    this.setFieldVal(this.stay_id, 'rapid_assessment_diagnosis', diagnosisID)
                }
            }
        },
        otherDiagnosisSelected: {
            get(): boolean {
                return this.getFieldVal(this.stay_id, "rapid_assessment_other_diagnosis_selected") || false
            },
            set(val: boolean) {
                this.setFieldVal(this.stay_id, 'rapid_assessment_other_diagnosis_selected', val)
            }
        },
        otherDiagnosisTitle: {
            get(): string | null {
                return this.getFieldVal(this.stay_id, "rapid_assessment_other_diagnosis_title")
            },
            set(title: string) {
                this.setFieldVal(this.stay_id, 'rapid_assessment_other_diagnosis_title', title)
            }
        },
        showOtherDiagnosis(): boolean {
            return this.otherDiagnosisSelected
        },
        diagnoses(): any {
            // The list of diagnoses to show in the dropdown component
            if (this.rapidAssessmentSyndrome && this.rapidAssessmentSyndrome.diagnoses) {
                return map(this.rapidAssessmentSyndrome.diagnoses, d => ({ id: d.id, text: d.title }))
            }
            return []
        },
        rapidAssessmentDiagnosis(): Diagnosis | undefined {
            const stay = this.stay
            if (
                stay &&
                stay.rapid_assessment_diagnosis &&
                this.rapidAssessmentSyndrome &&
                this.rapidAssessmentSyndrome.diagnoses
            ) {
                return find(this.rapidAssessmentSyndrome.diagnoses, ["id", this.diagnosisID])
            }
            return
        },
        rapidAssessmentPlanNotes: {
            get(): string {
                return get(this.stay, "rapid_assessment_plan_notes") || ""
            },
            set(val: string) {
                this.setFieldVal(this.stay_id, "rapid_assessment_plan_notes", val)
            }
        },
        rapidAssessmentSyndrome(): Syndrome | undefined {
            return find(this.$store.direct.getters.templates.rapidAsmtSyndromes, ["id", this.stay?.rapid_assessment_syndrome])
        },
        nihssV2(): boolean {
            return this.$store.direct.state.session.nihss_version === 'v2'
        },
    },
})

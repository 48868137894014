









import Vue from 'vue'

import DiagnosisFeatures from '@inputs/diagnosis/DiagnosisFeatures.vue'
import DiagnosisInput from '@inputs/diagnosis/Diagnosis.vue'
import DiagnosisNotes from '@inputs/diagnosis/DiagnosisNotes.vue'
import DiagnosisSummary from '@inputs/diagnosis/DiagnosisSummary.vue'

export default Vue.extend({
    components: {
        DiagnosisFeatures,
        DiagnosisInput,
        DiagnosisNotes,
        DiagnosisSummary,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
    },
})





























































































































import mixins from 'vue-typed-mixins'
import { each, filter, find, includes, map, orderBy, uniqBy } from 'lodash-es'

import utils from 'utils'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { FilterMode, TSScope, TSScope_items } from 'models/filters'

import DelayRouteLeaveMixin from '@shared/DelayRouteLeaveMixin.vue'
import StaysFilterMixin from '@shared/StaysFilterMixin.vue'

import CreateStay from "../CreateStay.vue"
import CreateStayTs from '../v3/CreateStay.vue'
import Dropdown from '@shared/inputs/Dropdown.vue'
import FilterDoctors from '@shared/FilterDoctors.vue'
import StayFilters from "@shared/StayFilters.vue"
import HospitalFilter from '../telestroke/HospitalFilter.vue'
import Print from '@home/Print.vue'
import SearchStays from "../SearchStays.vue"
import ShowDischarged from '@shared/ShowDischarged.vue'
import SpacesFilter from '@shared/FilterSpaces.vue'
import Stays from './Stays.vue'

export default mixins(DelayRouteLeaveMixin, StaysFilterMixin).extend({
    components: {
        CreateStay,
        CreateStayTs,
        Dropdown,
        FilterDoctors,
        HospitalFilter,
        Print,
        SearchStays,
        ShowDischarged,
        SpacesFilter,
        Stays,
        StayFilters,
    },
    data() {
        const mode: FilterMode = window.scrawl.defaultFilterMode === 'seen' ? 'seen' : 'strict'
        const tsScope_local: TSScope = TSScope.Telestroke
        return {
            TSScope_items,
            normalStays: [] as Stay[],
            consultStays: [] as Stay[],
            mode,
            tsScope_local, 
        }
    },
    computed: {
        betaEnabled(): boolean {
            return this.$store.direct.getters.user.betaEnabled
        },
        consultsEnabled(): boolean {
            return this.$store.direct.state.session.ux_consult
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        isTSAdvUser(): boolean {
            return this.$store.direct.getters.user.isTSAdvUser
        },
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        specialities(): number[] {
            return this.$store.direct.state.user.filters.specialities
        },
        onlyOneSpeciality(): boolean {
            return this.specialities.length === 1
        },
        visibleStays(): Stay[] {
            const visibles = this.normalStays.concat(this.consultStays)
            const sortBy = this.telestrokeMode ? ['created_at'] : ['location.ward', 'location.room', 'location.bed']
            const sortOrder = this.telestrokeMode ? 'desc' : 'asc'
            return orderBy(visibles, sortBy, sortOrder)
        },
        advOrderedStays(): Stay[] {
            const orderedStays = this.visibleStays
            let reorderedStays: Stay[] = []
            const specialityIds = this.$store.direct.getters.templates.specialityIds

            each(specialityIds, spId => {
                const speciality = this.$store.direct.getters.templates.speciality(spId)
                if (speciality) {
                    const locations: string[] = speciality.location_order.split(',')
                    each(locations, locDef => {
                        const wardBed = locDef.trim().split('-')
                        if (wardBed.length === 1) {
                            const ward = wardBed[0]
                            const stays: Stay[] = filter(orderedStays, stay => (!!stay.location && stay.location.ward === ward))
                            reorderedStays.push(...stays)
                        }
                        else if (wardBed.length === 2) {
                            const ward = wardBed[0], bed = wardBed[1]
                            const stay: Stay | undefined = find(orderedStays, stay => (!!stay.location && stay.location.ward === ward && stay.location.bed === bed))
                            if (stay)
                                reorderedStays.push(stay)
                        }
                    })
                }
            })

            if (reorderedStays.length === 0)
                return orderedStays

            const reorderedIds = map(reorderedStays, 'id')
            const otherStays = filter(orderedStays, stay => !includes(reorderedIds, stay.id))
            reorderedStays.push(...otherStays)
            reorderedStays = uniqBy(reorderedStays, 'id')

            return reorderedStays
        },
        orderedStaysIDs(): number[] {
            return map(this.advOrderedStays, stay => stay.id)
        },
        feedOn(): boolean {
            return this.$store.direct.getters.session.feedOn
        },
        tsScope(): TSScope {
            if (this.isTSAdvUser)
                return this.isTSSiteUser ? TSScope.Hospitals : this.tsScope_local
            return TSScope.Telestroke
        }
    },
    watch: {
        mode(val: FilterMode) {
            this.pull()
        },
        tsScope(val: any) {
            this.pull()
        },
    },
    mounted() {
        // to eliminate "No Stays" flashing on load due to debounced pull
        this.$_pull()
    },
    beforeDestroy() {
        stays.mutClearAll()
    },
    methods: {
        $_pull(): Promise<any> {
            const params = this.params
            params.spm = this.mode
            params.tsc = this.tsScope
            params.t = 'doc'
            return stays.actPullStays({ url: '/stay/simple/', params })
            .catch(err => {
                utils.handleRequestError(err)
            })
        },
    }
})

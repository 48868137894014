

































import Vue from "vue"
import { each, find } from "lodash-es"
import { Diagnosis } from 'models/med_templates/diagnosis'
import { Speciality } from 'models/med_templates/speciality'


export default Vue.extend({
    props: {
        debugMode: {
            type: Boolean,
            default: false
        },
        value: {},
        /** display label & dropdown inline instead of stacked */
        horizontal: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        hide_label: {
            type: Boolean,
            default: false
        },
        specialities: {
            type: Array as () => Speciality[],
            default: []
        },
        otherDiagnosisId: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            selected: this.value as any
        }
    },
    computed: {
        labelCols(): boolean | number {
            return this.horizontal ? 4 : false
        },
        extraClasses(): string {
            return this.fullWidth ? "w-100" : ""
        },
        toggleClasses(): string {
            return `text-truncate ${this.extraClasses}`.trim()
        },
        diagnoses(): Diagnosis[] {
            const items: Diagnosis[] = []
            each(this.specialities, speciality => {
                each(speciality.syndromes, syndrome => {
                    each(syndrome.diagnoses, diagnosis => {
                        items.push(diagnosis)
                    })
                })
            })
            return items
        },
        otherDiagnosis() {
            return {
                id: this.otherDiagnosisId,
                title: '[ Other Diagnosis ]'
            }
        },
        selectedItem(): Diagnosis | undefined {
            return find(this.diagnoses, ['id', this.selected])
        },
        selectedText(): string {
            if (this.selectedItem)
                return this.selectedItem.alt_title || this.selectedItem.title
            if (this.selected === this.otherDiagnosis.id)
                return this.otherDiagnosis.title
            return '[ Choose diagnosis ]'
        }
    },
    watch: {
        value(newVal) {
            this.selected = newVal
        },
        selected() {
            this.$emit('input', this.selected)
        }
    },
    methods: {
        isSelected(val: any): boolean {
            return this.selected === val
        },
        toggleSelect(val: any, event: Event) {
            this.selected = this.isSelected(val) ? null : val
        }
    }
})

















import Vue from 'vue'
import mixins from "vue-typed-mixins"
import { each, find, first, map, keyBy, reduce, isArray, get, has } from 'lodash-es'

import { LayoutGroupExt, PropExtInfo } from "models"
import { DisplayLookup, LayoutLookup } from "models/layout"
import { NestedProp, PropChoice } from "models/schema"
import { FullSchema } from 'models/schema/stay'

import DisplayMixin from "@mixins/Display.vue"
import InputMixin from "@mixins/Input.vue"
import Generic from "@sections/Generic.vue"

const displayInfo: DisplayLookup = {
    all: [
        {
            field: "motor_right_normal",
            booleanOptions: {
                combined: true
            }
        },
        {
            field: "motor_left_normal",
            booleanOptions: {
                combined: true
            }
        },
        {
            field: "facial_strength_right",
            dropdown: true,
            full_width: true,
        },
        {
            field: "facial_strength_left",
            dropdown: true,
            full_width: true,
        },
        {
            field: "arm_strength_right",
            dropdown: true,
            full_width: true,
        },
        {
            field: "arm_strength_left",
            dropdown: true,
            full_width: true,
        },
        {
            field: "leg_strength_right",
            dropdown: true,
            full_width: true,
        },
        {
            field: "leg_strength_left",
            dropdown: true,
            full_width: true,
        },
        {
            field: "ataxia_arm_right",
            dropdown: true,
            full_width: true,
            booleanOptions: {
                false_label: "0: No ataxia",
                true_label: "1: Ataxia"
            }
        },
        {
            field: "ataxia_arm_left",
            dropdown: true,
            full_width: true,
            booleanOptions: {
                false_label: "0: No ataxia",
                true_label: "1: Ataxia"
            }
        },
        {
            field: "ataxia_leg_right",
            dropdown: true,
            full_width: true,
            booleanOptions: {
                false_label: "0: No ataxia",
                true_label: "1: Ataxia"
            }
        },
        {
            field: "ataxia_leg_left",
            dropdown: true,
            full_width: true,
            booleanOptions: {
                false_label: "0: No ataxia",
                true_label: "1: Ataxia"
            }
        }
    ]
}

const layouts: LayoutLookup = {
    all: [
        { fields: [ "motor_right_normal", "motor_left_normal" ], alignment: "inline", field_col: 6 },
        { fields: [ "facial_strength_right", "facial_strength_left" ], alignment: "inline", field_col: 6 },
        { fields: [ "arm_strength_right", "arm_strength_left" ], alignment: "inline", field_col: 6 },
        { fields: [ "leg_strength_right", "leg_strength_left" ], alignment: "inline", field_col: 6 },
        { fields: [ "ataxia_arm_right", "ataxia_arm_left" ], alignment: "inline", field_col: 6 },
        { fields: [ "ataxia_leg_right", "ataxia_leg_left" ], alignment: "inline", field_col: 6 },

    ]
}

type Link = [string, string[]]

const thisComponent = mixins(DisplayMixin, InputMixin).extend({
    components: {
        Generic,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String as () => keyof FullSchema,
            required: true
        },
        showMrc: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        schema(): NestedProp | undefined {
            return this.getSectionSchema(this.section)
        },
        statusMsg(): string {
            return this.schema ? "" : "Schema loading..."
        },
        allFields(): PropExtInfo[] {
            return this.schema
                ? this.generatePropExtInfo(this.section, this.schema.children, displayInfo.all)
                : []
        },
        strengthGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups(this.section, this.allFields, layouts.all)
        },
        ataxiaGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups(this.section, this.allFields, layouts.ataxia)
        },
        /** NOTE: this gets replaced in beforeCreate, but we declare it here to accommodate Typescript prop check */
        links(): Link[] {
            return []
        }
    },
    beforeCreate() {
        const sides = ['right', 'left']
        const fields = ['facial_strength', 'arm_strength', 'leg_strength', 'ataxia_arm', 'ataxia_leg']

        const props: string[] = []
        const links: Link[] = []

        each(sides, side => {
            const src = `motor_${side}_normal`
            const targets = map(fields, base => { return `${base}_${side}` })
            links.push([src, targets])

            props.push(src, ...targets)
        })

        const self = this
        each(props, prop => {
            const computed: any = {
                get() {
                    return this.getFieldVal(this.stay_id, `${this.section}.${prop}`)
                }
            }
            self.$options.computed![prop] = computed
        })

        this.$options.computed!['links'] = {
            get() { return links }
        }
    },
    created() {
        this.generateWatchers()
    },
    methods: {
        getPropAndFirstChoice(fieldName: string): [PropExtInfo, PropChoice | undefined] {
            const propInfo = find(this.allFields, { name: fieldName })
            const choice = first(propInfo!.choices)
            return [propInfo!, choice]
        },

        generateWatchers() {
            each(this.links, link => {
                const normal = link[0]
                const targets = link[1]

                this.$watch(normal, function(val: any, oldVal: any) {
                    if (val) {
                        each(targets, target => {
                            const res = this.getPropAndFirstChoice(target)
                            const propInfo = res[0],
                                choice = res[1]
                            this.setFieldVal(this.stay_id, propInfo.path, choice ? choice.value : false)
                        })
                    }
                })

                each(targets, target => {
                    this.$watch(target, function(val: any, oldVal: any) {
                        const res = this.getPropAndFirstChoice(target)
                        const propInfo = res[0],
                              choice = res[1]
                        const normalPropInfo = find(this.allFields, { name: normal })
                        if (!((choice && choice.value === val) || !val))
                            this.setFieldVal(this.stay_id, normalPropInfo!.path, false)
                    })
                })
            })
        }
    },
})

export default thisComponent

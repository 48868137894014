









import Vue from "vue"

export default Vue.extend({
    computed: {
        triageMode: {
            get() {
                return this.$store.direct.state.user.triage_mode
            },
            set(val: boolean) {
                this.$store.direct.dispatch.user.toggleTriageMode()
            }
        }
    }
})

import { render, staticRenderFns } from "./AlternateLoginModal.vue?vue&type=template&id=642f11ab&"
import script from "./AlternateLoginModal.vue?vue&type=script&lang=ts&"
export * from "./AlternateLoginModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('642f11ab')) {
      api.createRecord('642f11ab', component.options)
    } else {
      api.reload('642f11ab', component.options)
    }
    module.hot.accept("./AlternateLoginModal.vue?vue&type=template&id=642f11ab&", function () {
      api.rerender('642f11ab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/misc/AlternateLoginModal.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("text-area", {
    attrs: {
      "non-unique-id": _vm.nonUniqueID,
      label: _vm.enhancedLabel,
      collapsible: _vm.collapsible,
      visible: _vm.visible
    },
    model: {
      value: _vm.field,
      callback: function($$v) {
        _vm.field = $$v
      },
      expression: "field"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
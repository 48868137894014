



























import mixins from 'vue-typed-mixins'
import StaysMixin from '@home/mixins/Stays.vue'
import { assign } from 'lodash-es'
import * as clipboard from 'clipboard'
import utils from 'utils'
import { Stay } from 'models/data/stay'

export default mixins(StaysMixin).extend({
    props: {
        stay: {
            type: Object as () => Stay,
            required: true
        },
        add_mb: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            modalId: `${uid}___tempuser_modal`,
            copyBtnId: `${uid}___tempuser_cp`,
            tempUser: {
                stay_id: -1,
                username: '',
                password: '',
                token: '',
                msg: ''
            },
            clipboardObj: null as any,
        }
    },
    computed: {
        patientText(): string {
            return this.patientName(this.stay)
        },
        copyBtnLabel(): string {
            return this.tempUser.token ? 'Copy URL' : 'Copy info'
        },
        loginURL(): string {
            let url = document.URL.replace(location.hash, '')
            url = this.tempUser.token ?
                `${url}?token=${encodeURI(this.tempUser.token)}`
                : url
            url = this.tempUser.token ? `${url}&` : `${url}?`
            url += 'next=/home'
            return url
        },
        tempUserText(): string {
            let text = `${this.loginURL}\n`
            if (!this.tempUser.token) {
                text += `Username: ${this.tempUser.username}\n`
                text += `Password: ${this.tempUser.password}\n`
            }
            return text
        },
    },
    mounted() {
        this.clipboardObj = new clipboard(`#${this.copyBtnId}`, {
            target(triggerElem) {
                return triggerElem.previousElementSibling || triggerElem
            }
        })
    },
    beforeDestroy() {
        if (this.clipboardObj)
            this.clipboardObj.destroy()
    },
    methods: {
        resetTempUser() {
            this.tempUser.stay_id = -1
            this.tempUser.username = ''
            this.tempUser.password = ''
            this.tempUser.token = ''
            this.tempUser.msg = ''
        },
        getTempUser() {
            this.resetTempUser()
            utils.request.get(`/stay/single-user/${this.stay.id}/`)
            .then(res => {
                // TODO typecheck
                assign(this.tempUser, res.body)
                this.$root.$emit('bv::show::modal', this.modalId)
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
        },
    }
})

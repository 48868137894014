var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pretty p-switch p-fill" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.debugMode,
          expression: "debugMode"
        }
      ],
      attrs: { type: "checkbox" },
      domProps: {
        checked: Array.isArray(_vm.debugMode)
          ? _vm._i(_vm.debugMode, null) > -1
          : _vm.debugMode
      },
      on: {
        change: function($event) {
          var $$a = _vm.debugMode,
            $$el = $event.target,
            $$c = $$el.checked ? true : false
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v)
            if ($$el.checked) {
              $$i < 0 && (_vm.debugMode = $$a.concat([$$v]))
            } else {
              $$i > -1 &&
                (_vm.debugMode = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
            }
          } else {
            _vm.debugMode = $$c
          }
        }
      }
    }),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state p-warning" }, [
      _c("label", [_vm._v("Debug")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-group" },
    [
      _c("date-time-picker", {
        attrs: { config: _vm.finalConfig, value: _vm.value },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-group-append" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-info",
            attrs: { type: "button", title: "Open/Close", "data-toggle": "" }
          },
          [_c("fnt-a-icon", { attrs: { icon: ["fal", "calendar"] } })],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-outline-danger",
            attrs: { type: "button", title: "Clear", "data-clear": "" }
          },
          [_c("fnt-a-icon", { attrs: { icon: ["fal", "times"] } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
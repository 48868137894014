var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mt-2" }, [
    _c(
      "div",
      { staticClass: "offset-3 col-9" },
      [
        _c("dropdown", {
          staticClass: "d-inline mr-2",
          attrs: {
            label: "Sites",
            items: _vm.sitesList,
            multi: "",
            textField: "title",
            addNullItem: "",
            nullItemValue: "all_sites",
            nullItemText: "[All]",
            closeOnSelect: false,
            truncateText: ""
          },
          on: {
            "selection-done": function($event) {
              _vm.sitesSelectDone++
            }
          },
          model: {
            value: _vm.selectedSites,
            callback: function($$v) {
              _vm.selectedSites = $$v
            },
            expression: "selectedSites"
          }
        }),
        _vm._v(" "),
        _vm.loading
          ? _c("em", { staticClass: "loading-dots" }, [
              _vm._v("Loading"),
              _c("span", [_vm._v(".")]),
              _c("span", [_vm._v(".")]),
              _c("span", [_vm._v(".")])
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("date-range-controls", {
          attrs: { endInclusive: "" },
          on: {
            start_dt: function($event) {
              _vm.startDate = $event
            },
            end_dt: function($event) {
              _vm.endDate = $event
            },
            time_unit: function($event) {
              _vm.timeUnit = $event
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 d-flex justify-content-center" }, [
      _c("div", { staticClass: "chart-container" }, [
        _c("canvas", { ref: "asapUsage", staticClass: "mt-2 mb-4" })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }



























import mixins from 'vue-typed-mixins'
import InputMixin from "@mixins/Input.vue"
import { find, isFinite } from 'lodash-es'

import stays from '@store/stays'
import { NIHSS } from 'models/data/nihss'
import { Stay } from 'models/data/stay'

import text from 'utils/text'
import { diagnosisWithSummaries } from 'utils/text/diagnosis'
import { updatedBackground, socialHistory } from 'utils/text/clinic_history'
import { wardIssues } from 'utils/text/issue'
import { updatedHistoricalMedications } from 'utils/text/meds'
import { nihss, wardRoundNihss } from 'utils/text/nihss'
import { doctorWardPlan, nurseWardPlan } from 'utils/text/plan'
import * as ward from 'utils/text/ward'
import { attending } from 'utils/text/team'

import Attending from '@shared/stay-inputs/Attending.vue'
import TextBase from '../TextBase.vue'
import TextNihssSelect from 'components/text/NIHSSSelect.vue'

export default mixins(InputMixin).extend({
    components: {
        Attending,
        TextBase,
        TextNihssSelect,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        showExtras: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        showSurgery(): boolean {
            if (!this.stay) return false
            const syndrome = find(this.$store.direct.getters.templates.allSyndromes, ['id', this.stay.syndrome])
            if (!syndrome) return false
            const specialityId = syndrome.speciality
            if (!specialityId) return false
            const speciality = find(this.$store.direct.state.templates.specialities, { id: specialityId })
            if (!speciality) return false
            return speciality.has_surgery || false
        },
        nihssV1(): boolean {
            return this.$store.direct.state.session.nihss_version === 'v1'
        },
        nihssV2(): boolean {
            return this.$store.direct.state.session.nihss_version === 'v2'
        },
        textNIHSS(): number {
            return this.$store.direct.state.session.textNIHSS
        },
        textNIHSSObj(): NIHSS | undefined {
            return find(this.stay?.nihss_set, { id: this.textNIHSS })
        },
        markup(): string {
            if (!(this.stay && this.stay.ward_round))
                return ''

            const isNurse = this.$store.direct.getters.user.isNurseUser
            const stay = this.stay
            const textDetails = { stay, anonymise: this.$store.direct.state.user.anonymise }

            let markup = '**Ward Round**  \n'
            markup += attending(textDetails, 'ward') + '\n\n'

            markup += `${text.patientDetails(textDetails)}  \n`

            let day = '?'
            if (isFinite(stay.day))
                day = `${stay.day}`
            else if (isFinite(stay.day_from_create))
                day = `${stay.day_from_create}`
            // markup += `Length of stay: ${stay.length_of_stay} days  \n`
            markup += `Day ${day}  \n`

            if (this.showSurgery) 
                markup += text.podText({ stay, hideTitle: false }) + '  \n'

            markup += '\n\n'

            markup += isNurse ? `${diagnosisWithSummaries(textDetails, true, true)}\n\n` : wardIssues(textDetails)
            markup += ward.wardProgress(textDetails)

            markup += updatedBackground(textDetails)
            markup += updatedHistoricalMedications(textDetails)
            markup += socialHistory(textDetails, { updatedAfter: this.stay.ward_round.created_at })

            markup += ward.wardExam(textDetails)
            if (this.nihssV1)
                markup += wardRoundNihss(textDetails)
            else
                markup += nihss(
                    textDetails,
                    { nihssID: this.textNIHSS, advancedDesc: true, heading: 'Exam' }
                )
            markup += ward.discussed(textDetails)
            markup += text.newInvestigationResults(textDetails)
            markup += ward.wardRoundsMedications(textDetails)
            markup += text.outstandingInvestigations(textDetails)
            markup += isNurse ? nurseWardPlan(stay) : doctorWardPlan(stay)

            return markup
        },
    },
    methods: {
        markWardRoundComplete() {
            // stays.extActSetPathValue(this.stay_id, "stage", "WARD")
            stays.extActSetPathValue(this.stay_id, 'ward_round.complete', true)
            // stays.extActSetPathValue(this.stay_id, 'ward_round.emr_notes', this.notes)
            if (!this.nihssV1) {
                if (this.textNIHSSObj)
                    this.setArrayedObjVal(this.stay_id, 'nihss_set', this.textNIHSS, 'editable', false)
            }
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("delay-route-leave-overlay"),
      _vm._v(" "),
      _c("top-bar"),
      _vm._v(" "),
      _c(
        "b-container",
        {
          staticClass: "position-relative",
          attrs: { id: "main-container", fluid: _vm.isFluid }
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: _vm.showErrorMsg,
                variant: "danger",
                dismissible: ""
              },
              on: { dismissed: _vm.clearErrorMsg }
            },
            [_c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.errorMsg))])]
          ),
          _vm._v(" "),
          _c("session-monitor"),
          _vm._v(" "),
          _c("version-checker"),
          _vm._v(" "),
          _c("alternate-login-modal"),
          _vm._v(" "),
          _vm.debugMode ? _c("current-stay") : _vm._e(),
          _vm._v(" "),
          _c("sync-errors"),
          _vm._v(" "),
          _vm.loginStatusUnknown
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center mt-5"
                },
                [
                  _c("h2", [
                    _c("em", { staticClass: "loading-dots" }, [
                      _vm._v("\n                    Connecting to server "),
                      _c("span", [_vm._v(".")]),
                      _c("span", [_vm._v(".")]),
                      _c("span", [_vm._v(".")])
                    ])
                  ])
                ]
              )
            : _vm.noService
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column align-items-center justify-content-center mt-5"
                },
                [
                  _c("h3", [
                    _vm._v(
                      "Service currently under maintenance. Please try again later."
                    )
                  ]),
                  _vm._v(" "),
                  _vm.knocking
                    ? _c("h5", { staticClass: "loading-dots" }, [
                        _vm._v("Checking "),
                        _c("span", [_vm._v(".")]),
                        _c("span", [_vm._v(".")]),
                        _c("span", [_vm._v(".")])
                      ])
                    : _c(
                        "h5",
                        [
                          _c("b-link", { on: { click: _vm.renewSession } }, [
                            _vm._v("Click here")
                          ]),
                          _vm._v(" to try to connect to service now.")
                        ],
                        1
                      )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.routingToASAP
            ? _c("router-view")
            : _vm.loggedIn && !_vm.ready
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center mt-5"
                },
                [
                  _c("h3", [
                    _c("em", { staticClass: "loading-dots" }, [
                      _vm._v("\n                    Setting up application "),
                      _c("span", [_vm._v(".")]),
                      _c("span", [_vm._v(".")]),
                      _c("span", [_vm._v(".")])
                    ])
                  ])
                ]
              )
            : !_vm.noService
            ? _c("router-view")
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
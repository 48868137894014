import { exact, guard } from 'decoders'
import {
    BaseProp,
    FiniteStringProp,
    ChoiceProp,
    ListProp,
    NestedProp,
    HasChildProp,
    baseProp, finiteStringProp, choiceProp, listProp, nestedProp, hasChildProp,
    nestedPropDef,
} from '.'


export interface FullSchema {
    id: BaseProp
    patient: NestedProp  // TODO strictly define patient prop
    diagnosis_title: BaseProp
    diagnosis_title_ext: BaseProp
    rapid_assessment_diagnosis_title: BaseProp
    speciality: BaseProp
    speciality_title: BaseProp
    hospital: NestedProp
    length_of_stay: BaseProp
    _answers: BaseProp
    acute_care_summary: BaseProp,
    admissionexam: NestedProp
    admission_nihss: NestedProp
    adm_present: NestedProp
    background: NestedProp
    baseline_investigations: HasChildProp
    care_classification: NestedProp & {
        children: {
            id: BaseProp
            classification: ChoiceProp
            date: BaseProp
            label: BaseProp
        }
    }
    care_days: BaseProp
    current_meds: HasChildProp
    current_meds_updated: BaseProp
    dash_hide_qs: ListProp
    discharge: NestedProp & {
        children: {
            aiming_for: BaseProp
            blood_pressure_target: ChoiceProp
            driving_plan: ChoiceProp
            estimated_discharge_date: BaseProp
            follow_up_in: ChoiceProp
            follow_up_time: BaseProp
            follow_up_with: BaseProp
            meds_changed: BaseProp
            next_steps: BaseProp
            ready_when: BaseProp
            other_aiming_for: FiniteStringProp
            other_next_steps: HasChildProp
            other_ready_when: HasChildProp
            notes: BaseProp
            emr_notes: BaseProp
        }
    }
    historical_meds: NestedProp
    investigations: HasChildProp
    issues: HasChildProp
    is_stroke: BaseProp
    location: NestedProp
    management_plan: NestedProp
    mdt: NestedProp
    nihss_set: HasChildProp
    other_investigations: HasChildProp
    other_issues: HasChildProp
    presentation: NestedProp
    primary: BaseProp,
    rapid_asmt_nihss: NestedProp
    social_history: NestedProp
    stroke_medications: BaseProp
    syndrome_features: HasChildProp
    surgery_stage: ChoiceProp
    surgery_time: BaseProp
    surgery_pod: BaseProp
    thrombolysis: NestedProp
    triage: NestedProp
    ward_round: NestedProp
    ward_round_nihss: NestedProp
    created_at: BaseProp
    updated_at: BaseProp
    ext_id: FiniteStringProp
    ext_ids: ListProp
    active: BaseProp
    admission_time: BaseProp
    discharge_time: BaseProp
    stage: ChoiceProp
    diagnosis_summary: BaseProp
    diagnosis_notes: BaseProp
    diagnosis_features_text: BaseProp
    other_diagnosis_title: FiniteStringProp
    other_diagnosis_selected: BaseProp
    other_current_meds: FiniteStringProp
    admission_summary: BaseProp
    other_issues_text: BaseProp
    admission_emr_notes: BaseProp
    doctor: BaseProp
    feed_speciality: BaseProp
    syndrome: BaseProp
    diagnosis: BaseProp
    question_groups: BaseProp
    rapid_assessment_syndrome: BaseProp
    rapid_assessment_diagnosis: BaseProp
    rapid_assessment_diagnosis_summary: BaseProp
    rapid_assessment_other_diagnosis_title: FiniteStringProp
    rapid_assessment_other_diagnosis_selected: BaseProp
    rapid_asmt_discharge_time: BaseProp
    rapid_asmt_only: BaseProp
    rapid_assessment_summary: BaseProp
    rapid_assessment_plan_notes: BaseProp
    rapid_assessment_emr_notes: BaseProp
    print_text: BaseProp
}

const fullSchema = exact({
    id: baseProp,
    patient: nestedProp,
    diagnosis_title: baseProp,
    diagnosis_title_ext: baseProp,
    rapid_assessment_diagnosis_title: baseProp,
    speciality: baseProp,
    speciality_title: baseProp,
    hospital: nestedProp,
    length_of_stay: baseProp,
    _answers: baseProp,
    acute_care_summary: baseProp,
    admissionexam: nestedProp,
    admission_nihss: nestedProp,
    adm_present: nestedProp,
    background: nestedProp,
    baseline_investigations: hasChildProp,
    care_classification: exact({
        ...nestedPropDef,
        children: exact({
            id: baseProp,
            classification: choiceProp,
            date: baseProp,
            label: baseProp
        })
    }),
    care_days: baseProp,
    current_meds: hasChildProp,
    current_meds_updated: baseProp,
    dash_hide_qs: listProp,
    day: baseProp,
    day_from_create: baseProp,
    discharge: exact({
        ...nestedPropDef,
        children: exact({
            aiming_for: baseProp,
            blood_pressure_target: choiceProp,
            driving_plan: choiceProp,
            estimated_discharge_date: baseProp,
            follow_up_in: choiceProp,
            follow_up_time: baseProp,
            follow_up_with: baseProp,
            meds_changed: baseProp,
            next_steps: baseProp,
            ready_when: baseProp,
            other_aiming_for: finiteStringProp,
            notes: baseProp,
            emr_notes: baseProp,
            other_next_steps: hasChildProp,
            other_ready_when: hasChildProp,
        })
    }),
    historical_meds: nestedProp,
    investigations: hasChildProp,
    issues: hasChildProp,
    is_stroke: baseProp,
    location: nestedProp,
    management_plan: nestedProp,
    mdt: nestedProp,
    nihss_set: hasChildProp,
    other_investigations: hasChildProp,
    other_issues: hasChildProp,
    presentation: nestedProp,
    primary: baseProp,
    rapid_asmt_nihss: nestedProp,
    social_history: nestedProp,
    stroke_medications: baseProp,
    syndrome_features: hasChildProp,
    thrombolysis: nestedProp,
    triage: nestedProp,
    ward_round: nestedProp,
    ward_round_nihss: nestedProp,
    created_at: baseProp,
    updated_at: baseProp,
    ext_id: finiteStringProp,
    ext_ids: listProp,
    active: baseProp,
    admission_time: baseProp,
    discharge_time: baseProp,
    stage: choiceProp,
    diagnosis_summary: baseProp,
    diagnosis_notes: baseProp,
    diagnosis_features_text: baseProp,
    other_diagnosis_title: finiteStringProp,
    other_diagnosis_selected: baseProp,
    other_current_meds: finiteStringProp,
    admission_summary: baseProp,
    other_issues_text: baseProp,
    admission_emr_notes: baseProp,
    doctor: baseProp,
    feed_speciality: baseProp,
    surgery_stage: choiceProp,
    surgery_time: baseProp,
    surgery_pod: baseProp,
    syndrome: baseProp,
    diagnosis: baseProp,
    question_groups: baseProp,
    rapid_assessment_syndrome: baseProp,
    rapid_assessment_diagnosis: baseProp,
    rapid_assessment_diagnosis_summary: baseProp,
    rapid_assessment_other_diagnosis_title: finiteStringProp,
    rapid_assessment_other_diagnosis_selected: baseProp,
    rapid_asmt_discharge_time: baseProp,
    rapid_asmt_only: baseProp,
    rapid_assessment_summary: baseProp,
    rapid_assessment_plan_notes: baseProp,
    rapid_assessment_emr_notes: baseProp,
    print_text: baseProp,
})

export const fullSchemaDecoder = guard(fullSchema, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _fullSchema: FullSchema = fullSchemaDecoder({}) } catch(e) {}

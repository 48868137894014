var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mt-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col d-flex" },
        [
          _c(
            "div",
            { staticClass: "mr-2" },
            [
              _c("label", { staticClass: "mb-0" }, [
                _vm._v("\n                    Start\n                ")
              ]),
              _vm._v(" "),
              _c("date-time-picker", {
                staticClass: "w-100",
                attrs: {
                  placeholder: "Select start date",
                  config: { altFormat: "d M Y", dateFormat: "Y-m-d" }
                },
                model: {
                  value: _vm.start,
                  callback: function($$v) {
                    _vm.start = $$v
                  },
                  expression: "start"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mr-3" },
            [
              _c("label", { staticClass: "mb-0" }, [
                _vm._v("\n                    End\n                ")
              ]),
              _vm._v(" "),
              _c("date-time-picker", {
                staticClass: "w-100",
                attrs: {
                  placeholder: "Select end date",
                  config: { altFormat: "d M Y", dateFormat: "Y-m-d" }
                },
                model: {
                  value: _vm.end,
                  callback: function($$v) {
                    _vm.end = $$v
                  },
                  expression: "end"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("dropdown", {
            staticClass: "mr-3",
            attrs: {
              label: "Doctors involved",
              items: _vm.allDoctors,
              textField: "title",
              multi: "",
              closeOnSelect: false,
              addClearAll: ""
            },
            model: {
              value: _vm.doctors,
              callback: function($$v) {
                _vm.doctors = $$v
              },
              expression: "doctors"
            }
          }),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Load",
                "label-class": "pb-0",
                disabled: !_vm.filtersOK
              }
            },
            [
              _c(
                "b-btn",
                { attrs: { active: _vm.filtersOK, disabled: !_vm.filtersOK } },
                [_vm._v("Go")]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h4", [_vm._v("Hyperacute Reviews")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
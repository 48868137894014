var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-base", {
    staticClass: "mt-2",
    class: { "showing-details": _vm.expandedStayID },
    attrs: {
      fields: _vm.fields,
      items: _vm.detailStays,
      sortBy: "location",
      "sort-null-last": "",
      "primary-key": "id"
    },
    scopedSlots: _vm._u([
      {
        key: "cell(location)",
        fn: function(data) {
          return [_c("location", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(patient)",
        fn: function(data) {
          return [
            _c("patient", { attrs: { stay_id: data.item.id } }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "mt-1",
                attrs: { size: "sm", variant: "outline-info" },
                on: {
                  click: function($event) {
                    return _vm.toggleMDT(data)
                  }
                }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(data.detailsShowing ? "Hide" : "Show") +
                    " MDT\n        "
                )
              ]
            )
          ]
        }
      },
      {
        key: "cell(los_abf)",
        fn: function(data) {
          return [
            _c(
              "p",
              [
                _vm._v(_vm._s(data.item.length_of_stay) + "\n            "),
                _vm.showABF
                  ? [
                      _c("br"),
                      _vm._v(
                        "\n                (" +
                          _vm._s(_vm.abfScore(data.item)) +
                          ")\n            "
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        }
      },
      {
        key: "cell(diagnosis)",
        fn: function(data) {
          return [
            _c("diagnosis-and-summary", { attrs: { stay_id: data.item.id } })
          ]
        }
      },
      {
        key: "cell(surgery)",
        fn: function(data) {
          return [_c("surgery-time", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(issues)",
        fn: function(data) {
          return [_c("issues", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(social)",
        fn: function(data) {
          return [_c("social-history", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(aiming_for)",
        fn: function(data) {
          return [_c("aiming-for", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(follow_up_plan)",
        fn: function(data) {
          return [_c("follow-up", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(print_notes)",
        fn: function(data) {
          return [_c("print-notes", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(print_notes_display)",
        fn: function(data) {
          return [
            _c("print-notes-display", { attrs: { stay_id: data.item.id } })
          ]
        }
      },
      {
        key: "row-details",
        fn: function(data) {
          return [
            _c("mdt-questions", {
              staticClass: "mt-2 mb-5",
              attrs: { stay_id: data.item.id }
            })
          ]
        }
      },
      {
        key: "head(los_abf)",
        fn: function(data) {
          return [
            _vm.showABF
              ? _c("span", [
                  _vm._v("LoS "),
                  _c(
                    "span",
                    {
                      staticClass: "text-nowrap",
                      attrs: {
                        title:
                          "This displayed score is the combined total for all stays below."
                      }
                    },
                    [_vm._v("(ABF: " + _vm._s(_vm.abfTotal) + ")")]
                  )
                ])
              : _c("span", [_vm._v("LoS")])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

















import mixins from "vue-typed-mixins"

import text from 'utils/text'
import { positivesAndNegatives } from 'utils/text/admission'
import { background, socialHistory } from 'utils/text/clinic_history'
import { examination } from 'utils/text/exam'
import { historicalMedications } from 'utils/text/meds'
import { admissionNihss } from 'utils/text/nihss'
import { aimingForMarkup } from 'utils/text/plan'
import { attending } from 'utils/text/team'
import stays from "@store/stays"
import { Stay } from 'models/data/stay'

import DisplayMixin from "@mixins/Display.vue"
import RecentNotesMixin from '../RecentNotesMixin.vue'
import Attending from '@shared/stay-inputs/Attending.vue'
import TextBase from '../TextBase.vue'

export default mixins(DisplayMixin, RecentNotesMixin).extend({
    components: {
        Attending,
        TextBase,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        showExtras: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        markup(): string {
            if (!this.stay)
                return ""

            const textDetails = {
                stay: this.stay,
                anonymise: this.$store.direct.state.user.anonymise,
            }

            let markup = ""

            let title = "Acute Assessment and Management Plan"
            if (this.telestrokeMode)
                title = `Telestroke ${title}`

            markup = `**${title}**  \n`
            markup += attending(textDetails, 'adm') + '\n\n'

            markup += text.patientDetailsWithHospital(textDetails)
            markup += text.presentingComplaint(textDetails)
            markup += positivesAndNegatives(textDetails)
            markup += '\n' + background(textDetails)
            markup += historicalMedications(textDetails)
            markup += socialHistory(textDetails)
            markup += examination(textDetails)
            markup += admissionNihss(
                textDetails,
                { advancedDesc: true }
            )
            markup += text.baselineInvestigations(textDetails)
            markup += text.diagnosis(textDetails)

            // Issues
            if (this.telestrokeMode) {
                markup += text.activeIssuesSummary(textDetails)
                markup += text.resolvedIssuesList(textDetails, true)
                // phase out once all active stays are using issues above
                markup += text.otherIssues(textDetails)
            }
            else {
                markup += text.activeIssuesSummary(textDetails)
                markup += text.resolvedIssuesList(textDetails, false)
            }

            markup += aimingForMarkup(textDetails)
            markup += text.thrombolysis(textDetails)
            markup += text.managementPlan(textDetails)

            const planNotes = text.fieldText(textDetails, "management_plan.notes")
            if (planNotes.length) markup += planNotes + "\n\n"

            markup += text.admissionInvestigationsSummary(textDetails)

            return markup
        },
    },
    methods: {
        markAdmissionComplete() {
            stays.extActSetPathValue(this.stay_id, "stage", "WARD")
            // stays.extActSetPathValue(this.stay_id, "admission_emr_notes", this.notes)
            stays.extActSetPathValue(this.stay_id, 'admission_nihss.editable', false)
            stays.mutTriggerLoadStays()
        },
    },
})

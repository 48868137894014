















































































import Vue from 'vue'
import { clone, cloneDeep, findIndex, forEach, reduce } from 'lodash-es'
import { getABFScore } from 'utils/abf'
import { BvTableFieldArray } from 'bootstrap-vue'

import { Stay } from 'models/data/stay'
import { Location as LocationModel } from 'models/data/location'

import MdtQuestions from 'components/data-entry/views/MDTQuestions.vue'

import AimingFor from './columns/AimingFor.vue'
import DiagnosisAndSummary from './columns/DiagnosisAndSummary.vue'
import FollowUp from './columns/FollowUp.vue'
import Issues from './columns/Issues.vue'
import Location from './columns/Location.vue'
import Patient from './columns/Patient.vue'
import PrintNotes from './columns/AddPrintNotes.vue'
import PrintNotesDisplay from './columns/PrintNotesDisplay.vue'
import ReadyWhen from './columns/ReadyWhen.vue'
import SocialHistory from './columns/SocialHistory.vue'
import SurgeryTime from './columns/SurgeryTime.vue'

import TableBase from './TableBase.vue'


interface DetailStay extends Stay {
    _showDetails?: boolean
}

export default Vue.extend({
    components: {
        AimingFor,
        DiagnosisAndSummary,
        FollowUp,
        Issues,
        Location,
        MdtQuestions,
        Patient,
        PrintNotes,
        PrintNotesDisplay,
        ReadyWhen,
        SocialHistory,
        SurgeryTime,
        TableBase,
    },
    props: {
        stays: {
            type: Array as () => Stay[]
        },
        showSurgery: {
            type: Boolean,
            default: false
        },
    },
    data() {
        const allFields: BvTableFieldArray = [
            {
                key: 'location',
                formatter: (location: LocationModel) => {
                    if (location) return `${location.ward} - ${location.bed}`
                    return null
                },
                sortable: true,
                sortByFormatted: true,
            },
            {
                key: 'patient'
            },
            {
                key: 'los_abf',
                sortable: true
            },
            {
                key: 'diagnosis',
                label: 'Diagnosis'
            },
            {
                key: 'surgery',
                label: 'Surgery POD'
            },
            {
                key: 'issues',
                label: 'Other Issues'
            },
            {
                key: 'social'
            },
            {
                key: 'aiming_for'
            },
            {
                key: 'follow_up_plan'
            },
            {
                key: 'print_notes_display',
                label: 'Add. Print Notes'
            },
            {
                key: 'print_notes',
                label: ''
            },
        ]

        return {
            expandedStayID: null,
            allFields,
            localStays: [] as DetailStay[],
        }
    },
    computed: {
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        pauseOverview(): boolean {
            return this.$store.direct.state.session.pauseOverview
        },
        fields(): BvTableFieldArray {
            const fields = clone(this.allFields)

            if (!this.showSurgery) {
                const index = findIndex(fields, ['key', 'surgery'])
                if (index > -1)
                    fields.splice(index, 1)
            }

            return fields
        },
        detailStays(): DetailStay[] {
            // Add a `_showDetails` field to every stay, set to `false` if the stay's details aren't visible.
            // We need the stay objects to have this value so we can hide all open details rows later.
            // If the `_showDetails` field doesn't exist on the object already, the reactivity doesn't work.
            let detailStays: DetailStay[] = this.pauseOverview ? this.localStays : cloneDeep(this.stays)
            forEach(detailStays, (stay) => {
                if (stay.id === this.expandedStayID) {
                    // This details of this stay are currently visible
                    stay._showDetails = true
                } else {
                    stay._showDetails = false
                }
            })
            return detailStays
        },
        showABF(): boolean {
            return this.$store.direct.state.session.ux_abf
        },
        staysABFScores(): { [index: number]: number } {
            const scores: { [index: number]: number } = {}
            forEach(this.detailStays, stay => {
                scores[stay.id] = getABFScore(stay.id)
            })
            return scores
        },
        abfTotal(): number {
            return reduce(this.staysABFScores, (sum, n) => sum + n, 0)
        },
    },
    watch: {
        pauseOverview: {
            handler: function(val: boolean) {
                if (val)
                    this.localStays = this.stays
            },
            immediate: true,
        }
    },
    methods: {
        hideAllDetails() {
            // Collapse all details rows
            forEach(this.detailStays, (stay) => {
                stay._showDetails = false
            })
        },
        toggleMDT(data: any) {
            if (!data.detailsShowing) {
                // We're about to show a stay's MDT section, hide all the others
                this.hideAllDetails()
            }
            data.toggleDetails()

            // Keep track of whether or not a details view is being shown so we style
            // the non-expanded rows
            this.expandedStayID = !data.detailsShowing && data.item.id
        },
        abfScore(stay: Stay): number {
            // return getABFScore(stay.id)
            return this.staysABFScores[stay.id]
        },
    },
})

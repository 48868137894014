










import Vue from 'vue'

export default Vue.extend({
    computed: {
        DRL_leaving(): boolean {
            return this.$store.direct.state.session.DRL_leaving
        },
    },
})

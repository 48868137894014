var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.stay
        ? _c("edit-modal", {
            attrs: { stay_id: _vm.stay.id, forceMaxH: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function() {
                    return [
                      _c("p", { class: { "mb-0": !_vm.extraHtml } }, [
                        _vm._v(_vm._s(_vm.diagnosis))
                      ]),
                      _vm._v(" "),
                      _vm.keyPoints
                        ? _c("div", {
                            staticClass: "mb-3",
                            domProps: { innerHTML: _vm._s(_vm.keyPoints) }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.features
                        ? _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.features) }
                          })
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("diagnosis-overview", { attrs: { stay: _vm.stay } })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3007045317
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.forceShowVisible
        ? _c(
            "b-btn",
            {
              staticClass: "mt-3 mb-1",
              attrs: { size: "sm", variant: "outline-info" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.forceShowFeatures = !_vm.forceShowFeatures
                }
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.forceShowText) + "\n    ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
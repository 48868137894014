
































































































import mixins from 'vue-typed-mixins'
import { CTAngiography_v2 } from 'models/med_templates/investigation'
import AdvInvMixin from './AdvInvMixin.vue'

interface Selection {
    right_artery: number | null
    left_artery: number | null
    io: number | null
    sia: number | null
    io_sides: number[]
    io_sites: number[]
    sia_sides: number[]
    sia_sites: number[]
}

export default mixins(AdvInvMixin).extend({
    data() {
        const selection: Selection = {
            right_artery: null,
            left_artery: null,
            io: null,
            sia: null,
            io_sides: [],
            io_sites: [],
            sia_sides: [],
            sia_sites: [],
        }

        return {
            fieldIds: CTAngiography_v2,
            selection,
        }
    },
    computed: {
        selectionT(): Selection {
            return this.selection
        },
        isOcclusion(): boolean {
            return this.selectionT.io === this.fieldToIdMap['io_yes']
        },
        isAtheroma(): boolean {
            return this.selectionT.sia === this.fieldToIdMap['sia_yes']
        },
        localResults(): number[] {
            let data: number[] = []
            for (let x of [this.selectionT.left_artery, this.selectionT.right_artery, this.selectionT.io, this.selectionT.sia])
                if (x) data.push(x)
            data = data.concat(this.selectionT.io_sides)
            data = data.concat(this.selectionT.io_sites)
            data = data.concat(this.selectionT.sia_sides)
            data = data.concat(this.selectionT.sia_sites)
            return data
        },
    },
    watch: {
        'selection.io'(val: number | null) {
            if (!this.isOcclusion)
                this.clearIO()
        },
        'selection.sia'(val: number | null) {
            if (!this.isAtheroma)
                this.clearSIA()
        }
    },
    methods: {
        clearIO(): void {
            this.selectionT.io_sides = []
            this.selectionT.io_sites = []
        },
        clearSIA(): void {
            this.selectionT.sia_sides = []
            this.selectionT.sia_sites = []
        },
    }
})





























import Vue from 'vue'

import stays from '@store/stays'
import { Stay } from 'models/data/stay'

import Admission from 'components/data-entry/views/Admission.vue'
import RapidAsmt from 'components/rapid-assessment/RapidAssessment.vue'
import StaySummary from 'components/data-entry/StaySummary.vue'
import Ward from 'components/data-entry/views/Ward.vue'

export default Vue.extend({
    components: {
        Admission,
        RapidAsmt,
        StaySummary,
        Ward,
    },
    props: {
        modalId: {
            type: String,
            required: true
        },
        stay_id: {
            type: Number,
            required: true
        },
    },
    computed: {
        validStayID(): boolean {
            return this.stay_id !== -1
        },
        editComponent() {
            if (this.$store.direct.state.user.telestroke_mode)
                return Admission
            const mode = this.$store.direct.state.session.dataVizEditMode[this.stay_id]
            switch(mode) {
                case 'rapid_asmt':
                    return RapidAsmt
                default:
                    if (this.stay?.stage === 'ADMISSION')
                        return Admission
                    return Ward
            }
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id] 
        },
        ready(): boolean {
            return this.stay !== undefined
        },
    },
})

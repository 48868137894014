var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("management-plan-steps", {
        attrs: { stay_id: _vm.stay_id, diagnosis: _vm.diagnosis }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c("r-input", {
              attrs: { stay_id: _vm.stay_id, field: _vm.planNotesPropExtInfo }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
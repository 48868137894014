




























import Vue from 'vue'
import utils from 'utils'

export default Vue.extend({
    data() {
        return {
            htmlId: utils.getUID(),
            altLoginLimit: 5,
            ackUsername: '',
            confirmState: null as boolean | null,
        }
    },
    computed: {
        username(): string {
            return this.$store.direct.state.user.username
        },
        altUsername(): string {
            return this.$store.direct.state.user.alt_username
        },
        isAlt(): boolean {
            return this.$store.direct.state.user.is_alt
        },
        remainingAltLogins(): number {
            return this.altLoginLimit - this.$store.direct.state.user.alt_logins
        },
        maxedLogins(): boolean {
            return this.$store.direct.state.user.alt_logins >= this.altLoginLimit
        },
    },
    watch: {
        ackUsername(val: string) {
            if (this.confirmState !== null)
                this.confirmState = null
        }
    },
    methods: {
        disableAltUsername() {
            utils.request.post('/account/disable-alt/')
            .then(res => {})
            .catch(err => {
                utils.handleRequestError(err)
            })
        },
        checkAndClose() {
            this.confirmState = this.ackUsername === this.$store.direct.state.user.username
            if (this.confirmState) {
                this.disableAltUsername()
                this.$bvModal.hide(this.htmlId)
            }
        },
    },
})

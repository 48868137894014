import './polyfills/polyfill'
import "./polyfills/function.name"
import "promise-polyfill/src/polyfill"
import * as smoothscroll from 'smoothscroll-polyfill'
import * as Bowser from 'bowser'

import Vue from "vue"
import VueRouter from "vue-router"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"
import BootstrapVue from "bootstrap-vue"
import "bootstrap-vue/dist/bootstrap-vue.css"
import 'pretty-checkbox/dist/pretty-checkbox.min.css'
import "../static/css/site.css"
import ToastedPlugin from "vue-toasted"

import store from "./store"
import utils from 'utils'
import Rounds from "@apps/Rounds.vue"
import Standalone from '@apps/Standalone.vue'
import fullRouter from 'routers/full'
import standaloneRouter from 'routers/standalone'

// const Standalone = () => import ('@apps/Standalone.vue')
// import Dummy from "@apps/Dummy.vue"

import { startsWith } from 'lodash-es'
import 'fontawesome'

const browser = Bowser.getParser(window.navigator.userAgent)
// console.log(browser.getBrowser())
const isBelowIE11 = browser.satisfies({
    windows: {
        'internet explorer': '<11'
    }
})

if (isBelowIE11)
    alert('Sorry, we do not support your browser. Try IE11, Edge, Chrome, Firefox, Safari.')
else
    run()

function run() {

    if (window.scrawl.sentryActive === 'true') { // yes, this is matching against a string. just ... accept it.
        let apiHostname = window.location.hostname

        // use defined apiBaseURL if it's not a relative URL 
        const apiURL = window.scrawl.apiBaseURL
        if (startsWith(apiURL, 'http'))
            apiHostname = apiURL.split('//')[1]

        // remove port if present
        const hostTokens = apiHostname.split(':')
        if (hostTokens.length > 1)
        apiHostname = hostTokens[0]

        let tracesSampleRate = 0.4
        try { tracesSampleRate = parseFloat(window.scrawl.sentrySampleRate) } catch(e) {}

        Sentry.init({
            dsn: window.scrawl.sentryDSN,
            release: window.scrawl.sentryRelease,
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(fullRouter),
                    tracingOrigins: [apiHostname],
                }),
            ],
            tracesSampleRate,
            logErrors: true,
        })    
    }

    smoothscroll.polyfill()
    Vue.use(VueRouter)
    Vue.use(BootstrapVue)
    Vue.use(ToastedPlugin, { position: 'bottom-right', duration: 2500 })

    // Add autofocus directive
    Vue.directive('focus', {
        inserted: function (el, binding, vnode) {
            Vue.nextTick(function() {
                el.focus()
            })
        }
    })

    const mode = startsWith(location.hash, '#/standalone') ? 'standalone' : 'full'

    utils.init()
    .then(function() {
        store.direct.commit.session.setBrowser(browser.getResult())
        return mode === 'full' ? utils.ensureCookie() : Promise.resolve()
    })
    .then(function() {
        if (mode === 'standalone') {
            new Vue({
                el: '#app',
                store,
                router: standaloneRouter,
                render: h => h(Standalone)
            })
        }
        else if (mode === 'full') {
            new Vue({
                el: "#app",
                store,
                router: fullRouter,
                computed: {
                    hasUnsavedChanges(): boolean {
                        return store.state.stays.stayChanged
                    }
                },
                beforeMount() {
                    window.addEventListener('beforeunload', ev => {
                        if (this.hasUnsavedChanges) {
                            ev.preventDefault()
                            const msg = 'There are unsaved changes pending...'
                            ev.returnValue = msg
                            return msg
                        }
                        return
                    })
                },
                // we use a render function so that we can just use the Vue runtime build (without template compiler)
                render: h => h(Rounds)
                // render: h => h(Dummy)
            })
        }
    })
}















import mixins from "vue-typed-mixins"
import { find, get } from "lodash-es"

import DisplayMixin from "@mixins/Display.vue"
import RInput from "@inputs/Input.vue"

import utils from "utils"

import { PropExtInfo } from "models"
import { Stay } from 'models/data/stay'

import stays from "@store/stays"

export default mixins(DisplayMixin).extend({
    components: {
        RInput,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            summaryInputId: `${uid}___summary_input`,
            helpId: `${uid}___summary_guide`,
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        summaryPropExtInfo(): PropExtInfo {
            const displayInfo = {
                field: "summary",
                force_label: "Presenting complaint"
            }

            const presentationSchema = this.getSectionSchema("presentation")
            const schemaChildren = get(presentationSchema, 'children', {})
            return this.generatePropExtInfo("presentation", schemaChildren, [displayInfo])[0]
        },
        summaryPlaceholderText(): string | null {
            const syndromeID = this.stay ? this.stay.syndrome : -1
            const selectedSyndrome = find(this.$store.direct.getters.templates.allSyndromes, [ 'id', syndromeID])
            return get(selectedSyndrome, 'placeholder_txt', '')
        },
    },
})












import Vue from 'vue'
import utils from 'utils'
export default Vue.extend({
    data() {
        const uid = utils.getUID()
        return {
            uid,
            htmlID: `${uid}___mrc`
        }
    }
})

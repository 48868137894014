var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        { attrs: { label: "Issue", "label-class": "p-0" } },
        [
          _c(
            "b-dropdown",
            {
              attrs: {
                "menu-class": "issues-list",
                text: _vm.issue.title || _vm.issueTitle,
                boundary: "window"
              }
            },
            [
              _vm._l(_vm.issueGroups, function(ref) {
                var id = ref.id
                var title = ref.title
                var issues = ref.issues
                return _c(
                  "b-dropdown-group",
                  { key: id, attrs: { header: title } },
                  [
                    _vm._l(issues, function(ref) {
                      var id = ref.id
                      var title = ref.title
                      return _c(
                        "b-dropdown-item",
                        {
                          key: id,
                          on: {
                            click: function($event) {
                              return _vm.changeIssue(id, title)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(title) +
                              "\n                "
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("b-dropdown-divider")
                  ],
                  2
                )
              }),
              _vm._v(" "),
              !_vm.issue.other
                ? [
                    _c(
                      "b-dropdown-item",
                      { on: { click: _vm.changeToOtherIssue } },
                      [
                        _vm.issue.other
                          ? _c("span", [_vm._v(" " + _vm._s(_vm.issueTitle))])
                          : _c("span", [_vm._v("Other")])
                      ]
                    ),
                    _vm._v(" "),
                    _c("b-dropdown-divider")
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.issue.other
        ? _c(
            "b-form-group",
            { attrs: { label: "Title", "label-class": "p-0" } },
            [
              _c("b-form-input", {
                ref: "otherIssueTitle",
                attrs: { placeholder: "[title]" },
                on: { focus: _vm.selectOtherTitle },
                model: {
                  value: _vm.issueTitle,
                  callback: function($$v) {
                    _vm.issueTitle = $$v
                  },
                  expression: "issueTitle"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("questions", {
        attrs: {
          category: _vm.QuestionCategory.ISSUE_RESULT,
          parentObject: _vm.issueSchema,
          stay_id: _vm.stay_id,
          showNotes: false,
          inline: false,
          setAnswer: _vm.setAnswer,
          getAnswer: _vm.getAnswer
        }
      }),
      _vm._v(" "),
      _c("text-area", {
        attrs: {
          id: _vm.htmlID + "_summary",
          label: "Key Points Summary",
          rows: "4",
          maxlength: "700"
        },
        model: {
          value: _vm.summary,
          callback: function($$v) {
            _vm.summary = $$v
          },
          expression: "summary"
        }
      }),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Diagnosed", "label-class": "p-0" } },
        [
          _c(
            "div",
            {
              staticClass: "input-group",
              staticStyle: { "max-width": "20rem" }
            },
            [
              _c("date-time-picker", {
                attrs: { config: { wrap: true, enableTime: true } },
                on: {
                  "flatpickr-date-format": function($event) {
                    _vm.dtPickerText = $event
                  }
                },
                model: {
                  value: _vm.diagnosedAt,
                  callback: function($$v) {
                    _vm.diagnosedAt = $$v
                  },
                  expression: "diagnosedAt"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-info",
                    attrs: { type: "button", "data-toggle": "" }
                  },
                  [_c("fnt-a-icon", { attrs: { icon: ["fal", "calendar"] } })],
                  1
                )
              ])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "b-btn",
            {
              attrs: { variant: _vm.isActive ? "success" : "info" },
              on: { click: _vm.toggleStatus }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.isActive ? "Resolve" : "Reactivate") +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.htmlID + "__delete",
                  expression: "`${htmlID}__delete`"
                }
              ],
              staticClass: "text-danger",
              attrs: { variant: "link", size: "sm" }
            },
            [_vm._v("\n            Delete\n        ")]
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                id: _vm.htmlID + "__delete",
                centered: "",
                "hide-header": true,
                "ok-title": "Delete",
                "ok-variant": "danger"
              },
              on: { ok: _vm.deleteIssue }
            },
            [
              _vm._v(
                '\n            Are you sure you want to delete "' +
                  _vm._s(_vm.issue.title) +
                  '"?\n        '
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }






















import mixins from 'vue-typed-mixins'
import { filter, find, forEach } from 'lodash-es'
import stays from '@store/stays'

import { Stay } from 'models/data/stay'
import { InvInfo } from 'models/data/investigation'
import { Investigation } from 'models/med_templates/investigation'

import AddInvestigations from './AddInvestigations.vue'
import DisplayMixin from '@mixins/Display.vue'
import InvestigationList from './InvestigationList.vue'
import RInput from '@inputs/Input.vue'

export default mixins(DisplayMixin).extend({
    components: {
        AddInvestigations,
        InvestigationList,
        RInput,
    },
    props: {
        hideActive: {
            type: Boolean,
            default: false,
            required: false,
        },
        hideAdd: {
            type: Boolean,
            default: false,
            required: false,
        },
        hideCompleted: {
            type: Boolean,
            default: false,
            required: false,
        },
        stay_id: {
            type: Number,
            required: true
        }
    },
    methods: {
        investigationIsOpen(investigationID: number): boolean {
            return investigationID === this.$store.direct.state.session.openInvID
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        activeInvestigations(): InvInfo[] {
            const self = this
            return filter(this.allStayInvestigations, function(investigation) {
                // If an investigation is marked done but is open that means it has just been changed to done
                // (existing completed investigations don't get marked open) so we leave it in the active
                // investigation list until it's closed to avoid it jumping around while being edited
                return !investigation.inv.done || self.investigationIsOpen(investigation.inv.id)
            })
        },
        completedInvestigations(): InvInfo[] {
            const self = this
            return filter(this.allStayInvestigations, function(investigation) {
                return investigation.inv.done && !self.investigationIsOpen(investigation.inv.id)
            })
        },
        allStayInvestigations(): InvInfo[] {
            if (!this.stay) return []
            if (!this.stay.investigations || !this.stay.other_investigations) return []
            if (!(this.stay.investigations.length + this.stay.other_investigations.length)) return []

            let list: InvInfo[] = []

            const allInvs = this.$store.direct.getters.templates.allInvs
            const stayInvestigations = stays.getInvestigations(this.stay_id)

            forEach(stayInvestigations, function(inv) {
                if (inv.other) {
                    // Generic 'Other' schema
                    const schema: Investigation = {
                        id: -1,
                        results: [],
                        title: inv.title,
                        enabled: true,
                        order: -1,
                        group: -1,
                    }
                    const combined: InvInfo = { inv, schema }
                    list.push(combined)
                } else {
                    const baseInv = find(allInvs, ['id', inv.investigation])
                    if (baseInv) {
                        const combined: InvInfo = { inv, schema: baseInv }
                        list.push(combined)
                    }
                }
            })

            return list
        }
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("text-area", {
    attrs: {
      "non-unique-id": "mgmt-plan-summary-" + _vm.stay_id,
      label: "Admission Management Notes",
      placeholder: _vm.summaryPlaceholderText,
      maxlength: "700"
    },
    model: {
      value: _vm.summary,
      callback: function($$v) {
        _vm.summary = $$v
      },
      expression: "summary"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
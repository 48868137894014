








































































































































































import mixins from 'vue-typed-mixins'
import { assign, each, endsWith, every, filter, find, get, includes, isEqual, isNil, keys, map, toInteger, some } from 'lodash-es'
import * as moment from 'moment'
import utils from 'utils'
import { safeDecoding } from 'utils/store'
import text from 'utils/text'
import { genericQuestionsText } from 'utils/text/question'

import stays from '@store/stays'
import { PropExtInfo, LayoutGroupExt } from 'models'
import { PropChoice, NestedProp } from 'models/schema'
import { FullSchema } from 'models/schema/stay'
import { DisplayList, LayoutGroup, LayoutLookup } from 'models/layout'
import { Assessment, Outcome, Reason, assessmentDecoder } from 'models/asap/assessment'
import { AsapPathway } from 'models/med_templates/asap'
import { QuestionCategory } from 'models/med_templates/question'
import DisplayMixin from '@mixins/Display.vue'
import InputMixin from "@mixins/Input.vue"
import Checkbox from '@shared/inputs/Checkbox.vue'
import Editor from '@shared/inputs/TextEditor.vue'
import Generic from '@sections/Generic.vue'
import Nihss from '@standalone/NIHSS.vue'
import Questions from '@stayinputs/Questions.vue'


interface ChkLstItem {
    label: string
    value: number
}

type Checklist = ChkLstItem[]

interface SchemaExt extends FullSchema {
    _meta?: {
        title: string
    }
    /** an ISO-formatted datetime string */
    version: string
    /** an ISO-formatted datetime string */
    site_version?: string
    nihss: NestedProp
    checklist_normal: Checklist
    checklist_hyperacute: Checklist
}

interface DisplayInfoDict {
    patient: DisplayList,
    presentation: DisplayList
    social_history: DisplayList
}

const displayInfo: DisplayInfoDict = {
    patient: [
        {
            field: 'mrn',
        }
    ],
    presentation: [
        {
            field: 'last_seen_well',
            dropdown: true,
            full_width: true
        },
        {
            field: 'summary',
            force_label: 'Summary of events',
        }
    ],
    social_history: [
        {
            field: 'living_situation',
            dropdown: true,
            full_width: true,
        },
        {
            field: 'mobility_status',
            dropdown: true,
            full_width: true,
        },
        {
            field: 'wash_dress_toilet',
            dropdown: true,
            full_width: true,
        },
    ]
}

interface LayoutLookupExt extends LayoutLookup {
    patient: LayoutGroup[]
    presentation: LayoutGroup[]
    social_history: LayoutGroup[]
}

const layouts: LayoutLookupExt = {
    patient: [
        { fields: [ 'mrn' ] }
    ],
    presentation: [
        { fields: [ 'last_seen_well', 'summary' ], alignment: 'inline' }
    ],
    social_history: [
        { fields: ['living_situation', 'mobility_status', 'wash_dress_toilet'], alignment: 'inline', field_col: 4 }
    ],
}

const enum UsageEvent {
    OPENED = 'opened',
    COMPLETED = 'completed',
}

export default mixins(DisplayMixin, InputMixin).extend({
    components: {
        Checkbox,
        Editor,
        Generic,
        Nihss,
        Questions,
    },
    props: {
        pathMatch: {
            type: String,
            default: ''
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            uid,
            startedAt: new Date(),
            meta: {
                baseSchemaVersion: '?',
                siteSchemaVersion: '?',
                downloadSiteSchema: true,
            },
            htmlIDs: {
                reason: `${uid}___reason`,
                presentation: `${uid}___presentation`,
                premorbid: `${uid}___premorbid`,
                plan: `${uid}___plan`,
                pathways: `${uid}___pathways`,
            },
            stay_id: -999,
            schema: null as (SchemaExt | null),
            displayInfo,
            allReasons: {
                care: 'Patient assessment',
                demo_train: 'Demonstration & education purposes',
            },
            askReason: false,
            reason: 'unknown' as Reason,
            reasonConfirmed: false,

            cardMonitor: -1,
            QuestionCategory,
            currentPathwayIdx: -1,
        }
    },
    computed: {
        schemaLoaded(): boolean {
            return this.schema !== null
        },
        ready(): boolean {
            return this.schemaLoaded && this.stay !== undefined
        },
        reasonUnknown(): boolean {
            return this.reason === 'unknown'
        },
        reasonDemo(): boolean {
            return this.reason === 'demo_train'
        },
        reasonVariant(): string | null {
            return this.reasonUnknown ? null : (this.reasonDemo ? 'warning' : 'success')
        },
        hasReason(): boolean {
            if (this.askReason)
                return !this.reasonUnknown && this.reasonConfirmed
            return true
        },
        showAssessment(): boolean {
            return this.ready && this.hasReason
        },
        showReasonCard(): boolean {
            return this.schemaLoaded && this.askReason
        },
        siteTitle(): string {
            return (this.schema && this.schema._meta) ? this.schema._meta.title : '[Site]'
        },
        stay(): any | undefined {
            return stays.state.stays[this.stay_id]
        },
        collectMRN(): boolean {
            return this.$store.direct.state.asap.asapMeta.mrn
        },
        patientSchema(): NestedProp | undefined {
            return this.schema ? this.schema.patient as NestedProp : undefined
        },
        patientFields(): PropExtInfo[] {
            if (this.patientSchema)
                return this.generatePropExtInfo('patient', this.patientSchema.children, this.displayInfo.patient)
            return []
        },
        patientGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups('patient', this.patientFields, layouts.patient)
        },
        presentationSchema(): NestedProp | undefined {
            return this.schema ? this.schema.presentation : undefined
        },
        presentationFields(): PropExtInfo[] {
            if (this.presentationSchema)
                return this.generatePropExtInfo('presentation',
                    this.presentationSchema.children, this.displayInfo.presentation)
            return []
        },
        presentationGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups('presentation', this.presentationFields, layouts.presentation)
        },
        socialHistorySchema(): NestedProp | undefined {
            return this.schema ? this.schema.social_history : undefined
        },
        socialHistoryFields(): PropExtInfo[] {
            if (this.socialHistorySchema)
                return this.generatePropExtInfo('social_history',
                    this.socialHistorySchema.children, this.displayInfo.social_history)
            return []
        },
        socialHistoryGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups('social_history', this.socialHistoryFields, layouts.social_history)
        },
        basicFieldsCompleted(): boolean {
            const fields = [
                "presentation.last_seen_well",
                "social_history.living_situation",
                "social_history.mobility_status",
                "social_history.wash_dress_toilet",
            ]
            return every(fields, field => get(this.stay, field))
        },
        nihssPaths(): string[] {
            if (!this.schema) return []
            const fields = filter(keys(this.schema.nihss.children), fieldName => !endsWith(fieldName, '_normal'))
            return map(fields, fieldName => 'nihss.'+fieldName)
        },
        nihssComplete(): boolean {
            return every(this.nihssPaths, path => !isNil(this.getFieldVal(this.stay_id, path)))
        },
        nihssUntouched(): boolean {
            return !this.nihssComplete && !some(this.nihssPaths, path => !isNil(this.getFieldVal(this.stay_id, path)))
        },
        nihssScore(): number {
            return this.getFieldVal(this.stay_id, 'nihss.score') || 0
        },
        nihssQuadrantanopia(): boolean {
            const visualFields = this.stay?.nihss?.visual_fields || []
            return (
                isEqual(visualFields, [1,0,0,0,1,0,0,0]) ||
                isEqual(visualFields, [0,1,0,0,0,1,0,0]) ||
                isEqual(visualFields, [0,0,1,0,0,0,1,0]) ||
                isEqual(visualFields, [0,0,0,1,0,0,0,1])
            )
        },
        nihssHemianopia(): boolean {
            const visualFields = this.stay?.nihss?.visual_fields || []
            return (
                isEqual(visualFields, [1,0,1,0,1,0,1,0]) ||
                isEqual(visualFields, [0,1,0,1,0,1,0,1])
            )
        },
        matchesStroke(): boolean {
            const lsw = this.getFieldVal(this.stay_id, 'presentation.last_seen_well')
            const lt4Hrs = lsw === 1
            const between4To24Hrs = lsw === 2
            const wakeUp = lsw === 4
            const mobility = this.getFieldVal(this.stay_id, 'social_history.mobility_status')
            const mobilityNoAssistance = !isNil(mobility) && mobility !== 'ASSISTANCE_NEEDED'
            const wdtIndependent = this.getFieldVal(this.stay_id, 'social_history.wash_dress_toilet') === 'INDEPENDENT'
            const wdtDependent = this.getFieldVal(this.stay_id, 'social_history.wash_dress_toilet') === 'DEPENDENT'
            const livingIndependent = includes(['INDEPENDENT', 'RETIRE_VILLAGE'], this.getFieldVal(this.stay_id, 'social_history.living_situation'))

            // criteria 1:
            // <4.5 hours,
            // doesn’t need assistance from others to mobilise,
            // independent with washing/dressing/toileting,
            // NIHSS > 1 or visual fields has quadrantanopia
            const criteria1 = every([
                lt4Hrs,
                mobilityNoAssistance,
                wdtIndependent,
                (this.nihssScore > 1 || this.nihssQuadrantanopia)
            ])

            // criteria 2:
            // <4.5 hours,
            // living independently,
            // doesn’t need assistance from others to mobilise,
            // dependent with washing/dressing/toileting
            // NIHSS > 1 or visual fields has quadrantanopia
            // ! obsolete. to be removed - RC 02/18/22
            /*
            const criteria2 = every([
                lt4Hrs,
                livingIndependent,
                mobilityNoAssistance,
                wdtDependent,
                (this.nihssScore > 1 || this.nihssQuadrantanopia)
            ])
            */

            // criteria 3:
            // 4.5-24 hours or wake up
            // living independently,
            // doesn’t need assistance from others to mobilise,
            // Independent with washing dressing toileting
            // NIHSS >= 4 or NIHSS > 1 from hemianopia
            const criteria3 = every([
                (between4To24Hrs || wakeUp),
                livingIndependent,
                mobilityNoAssistance,
                wdtIndependent,
                (this.nihssScore >= 4 || this.nihssHemianopia)
            ])

            // hacking through this because RC can't be bothered right now
            const enabled = this.$store.direct.state.asap.asapMeta.criteria
            return (
                (criteria1 && includes(enabled, 1)) ||
                // (criteria2 && includes(enabled, 2)) ||
                (criteria3 && includes(enabled, 3))
            )
        },
        outcome(): Outcome {
            if (!this.basicFieldsCompleted)
                return Outcome.INCOMPLETE
            
            if (this.matchesStroke)
                return Outcome.HYPERACUTE

            if (this.nihssComplete)
                return Outcome.NORMAL

            return Outcome.INCOMPLETE
        },
        outcomeNormal(): boolean {
            return this.outcome === Outcome.NORMAL
        },
        showReferralAdvisory(): boolean {
            return this.$store.direct.state.asap.asapMeta.referral_advisory
        },
        assessmentLabel(): string {
            switch (this.outcome) {
                case Outcome.INCOMPLETE:
                    return 'INCOMPLETE ASSESSMENT'
                case Outcome.NORMAL:
                    return 'Not a candidate for hyperacute stroke assessment'
                case Outcome.HYPERACUTE:
                    return 'Candidate for hyperacute stroke assessment'
                default:
                    return '?'
            }
        },
        planChoices(): any[] {
            if (!this.schema) return []
            switch (this.outcome) {
                case Outcome.INCOMPLETE:
                    return []
                case Outcome.NORMAL:
                    return this.schema.checklist_normal
                case Outcome.HYPERACUTE:
                    return this.schema.checklist_hyperacute
                default:
                    return []
            }
        },
        plan: {
            get(): number[] {
                return this.getFieldVal(this.stay_id, 'plan') || []
            },
            set(choices: number[]) {
                this.setFieldVal(this.stay_id, 'plan', choices)
            }
        },
        planText(): string[] {
            return map(this.plan, id => {
                const choiceInfo = find(this.planChoices, { value: id })
                return choiceInfo ? choiceInfo.label : '?'
            })
        },
        pathways(): AsapPathway[] {
            const allPathways = this.$store.direct.state.templates.asapPathways
            const targetPathways = this.$store.direct.state.asap.asapMeta.asap_pathways
            if (targetPathways === 'all')
                return allPathways
            return filter(allPathways, pathway => includes(targetPathways, pathway.id))
        },
        hasPathways(): boolean {
            return this.pathways.length > 0
        },
        pathwayChosen(): boolean {
            return this.currentPathwayIdx !== -1
        },
        currentPathway(): AsapPathway | undefined {
            return this.$store.direct.state.templates.asapPathways[this.currentPathwayIdx]
        },
        textMarkup(): string {
            if (!this.stay || !this.schema)
                return '**_No stay or schema data_**'

            let markup = ''

            if (this.reasonDemo)
                markup += '**_Demonstration & education purposes_**\n\n'

            if (this.outcome === Outcome.INCOMPLETE)
                markup += '**_INCOMPLETE ASSESSMENT_**\n\n'

            markup += '**ASAP Tool**\n\n'

            const mrn = get(this.stay, 'patient.mrn')
            if (mrn)
                markup += `Patient details: MRN: ${mrn}\n\n`

            markup += '**Presentation:**  \n'
            const lswChoiceText = this.getSelectedChoiceLabel('presentation', 'last_seen_well')
            if (lswChoiceText)
                markup += `Last seen well: ${lswChoiceText}`
            else
                markup += '_Last seen well not filled in_'
            markup += '\n\n'

            markup += '**Summary of events:**  \n'
            let summaryText = this.getFieldVal(this.stay_id, 'presentation.summary')
            if (summaryText)
                summaryText = text.freeTextSubHtmlBreaks(summaryText)
            else
                summaryText = '_No events summary_'
            markup += summaryText
            markup += '\n\n'

            markup += '**Premorbid Functions:**  \n'
            const premorbidFields = ['living_situation', 'mobility_status']
            let premorbidText = ''
            const choiceTexts: string[] = []
            each(premorbidFields, field => {
                const choiceText = this.getSelectedChoiceLabel('social_history', field)
                if (choiceText)
                    choiceTexts.push(choiceText)
            })
            const wdtChoiceText = this.getSelectedChoiceLabel('social_history', 'wash_dress_toilet')
            if (wdtChoiceText)
                choiceTexts.push(`${wdtChoiceText} with washing, dressing, toileting`)
            premorbidText = choiceTexts.join('  \n')
            if (premorbidText)
                markup += premorbidText
            else
                markup += '_No events summary_'
            markup += '\n\n'

            if (!this.nihssUntouched) {
                const nihssText = text.nihss(
                    { stay: this.stay },
                    {
                        nihssKey: "nihss",
                        heading: 'Exam',
                        gazeCombined: true,
                    }
                )
                markup += nihssText + '\n\n'
            }

            markup += '**Plan:**  \n'
            switch (this.outcome) {
                case Outcome.INCOMPLETE:
                    markup += '_No plan selected_'
                    break
                default:
                    markup += `${this.assessmentLabel}  \n`
                    markup += this.planText.join('  \n')
                    break
            }
            markup += '\n\n'

            if (this.outcomeNormal && this.currentPathway) {
                markup += `**${this.currentPathway.title}**  \n`
                markup += genericQuestionsText({
                    stay: this.stay,
                    category: QuestionCategory.ASAP_PATHWAY,
                    getQuestionGroupsFrom: this.currentPathway
                })
            }
            return markup
        }
    },
    watch: {
        outcome(val: Outcome) {
            if (val !== Outcome.INCOMPLETE)
                this.sendAssessmentCompleted(val)
        },
        reason(val: Reason, old: Reason) {
            if (old === Reason.unknown)
                this.confirmReason()
            else
                this.update()
        },
        hasReason(val: boolean) {
            this.startedAt = new Date()
        },
    },
    created() {
        let schema: SchemaExt
        let siteSchema: SchemaExt | null = null

        let metaLoad: Promise<void>
        if (this.$store.direct.state.asap.asapMetaLoaded)
            metaLoad = Promise.resolve()
        else
            metaLoad = this.$store.direct.dispatch.asap.pullASAPMeta()

        metaLoad
        .then(res => {
            this.askReason = window.scrawl.asapAskReason === 'true'
                || this.$store.direct.state.asap.asapMeta.ask_reason
            const schemaName = this.$store.direct.state.asap.asapMeta.schema
            return utils.request.get(`static/asap/${schemaName}.json`, undefined, true)
        })
        .then(res => {
            schema = res.body
            this.meta.baseSchemaVersion = schema.version

            const routeName = this.pathMatch
            this.meta.downloadSiteSchema = this.$route.query.site_dl !== 'false'
            if (routeName !== '' && this.meta.downloadSiteSchema)
                return utils.request.get(`static/asap/schema_${routeName}.json`, undefined, true)
            else
                return Promise.resolve(null)
        })
        .then(res => {
            if (res) {
                siteSchema = res.body
                this.meta.siteSchemaVersion = siteSchema!.version
            }
            return this.$store.direct.dispatch.templates.pullAsapInitData()
        })
        .then(res => {
            
        })
        .catch(err => {
            utils.handleRequestError(err)
        })
        .then(res => {
            if (siteSchema) {
                siteSchema.site_version = siteSchema.version
                siteSchema.version = schema.version
                assign(schema, siteSchema)
            }

            this.schema = schema

            if (!this.askReason)
                this.sendOpened()
        })
    },
    mounted() {
        return
        this.$nextTick(() => {
            const rootElem = window.getComputedStyle(document.documentElement, null)
            let fontSize = '16'
            if (rootElem && rootElem.fontSize)
                fontSize = rootElem.fontSize.replace('px', '')
            const navHeight = toInteger(fontSize) * 3.5  // bootstrap-slate's navbar height is 3.5rem

            const card = document.querySelector(`#${this.htmlIDs.reason}`)
            const el = card as HTMLElement

            /** stores the number to set the reason card flush to the navbar when sticky */
            // let flushAdjustment = 0
            /**  */
            /** store the number to set the card's style.left value to when sticky */
            let targetLeft = 0

            if (this.cardMonitor === -1) {
                this.cardMonitor = window.setInterval(() => {
                    if (card) {
                        // disabled for now - keeping code just for reference
                        /*
                        if (flushAdjustment === 0) {
                            const cardTop = card.getBoundingClientRect().top
                            if (cardTop > navHeight)
                                flushAdjustment = cardTop - navHeight
                        }
                        */
                        if (targetLeft === 0) {
                            const cardLeft = card.getBoundingClientRect().left
                            if (cardLeft !== 0)
                                targetLeft = -(cardLeft - 20)  // 20 is an arbitrary margin
                        }

                        const scrollY = -document.querySelector('body')!.getBoundingClientRect().top

                        // const top = scrollY > 0 ? `${scrollY-flushAdjustment}px` : `${scrollY}px`
                        const top = `${scrollY}px`
                        if (el.style.top !== top)
                            el.style.top = top

                        const left = scrollY > 0 ? `${targetLeft}px` : '0'
                        if (el.style.left !== left)
                            el.style.left = left
                    }
                }, 500)  // run every 0.5 seconds
            }
        })
    },
    beforeDestroy() {
        window.clearInterval(this.cardMonitor)
        this.cardMonitor = -1
    },
    methods: {
        getSelectedChoiceLabel(section: keyof SchemaExt, field: string): string {
            const choices: PropChoice[] = get(this.schema, `${section}.children.${field}.choices`)
            const choiceInfo = find(choices, { value: this.getFieldVal(this.stay_id, `${section}.${field}`)})
            return choiceInfo ? (choiceInfo.display_name || '') : ''
        },
        confirmReason() {
            if (!this.reasonConfirmed) {
                this.reasonConfirmed = true
                this.sendOpened()
            }
        },
        setCurrentPathwayIdx(tabIndex: number): void {
            this.currentPathwayIdx = tabIndex - 1
        },
        sendUsage(event: UsageEvent, reason?: Reason, assessOutcome?: Outcome) {
            const data = {
                meta: {
                    at: moment().toISOString(),
                    ...this.$store.direct.state.session.browser,
                },
                details: {
                    sessionId: this.uid,
                    app: 'asap_tool',
                    site: this.pathMatch,
                    version: {
                        base: this.meta.baseSchemaVersion,
                        site: this.meta.siteSchemaVersion,
                    },
                    event,
                    reason,
                    outcome: assessOutcome
                }
            }
            utils.request.post('/metrics/ux/', false, data)
            .catch(err => {
                utils.handleRequestError(err, false)
            })
        },
        sendOpened() {
            this.sendUsage(UsageEvent.OPENED, this.reason, undefined)
            this.create()
        },
        sendAssessmentCompleted(outcome: Outcome) {
            this.sendUsage(UsageEvent.COMPLETED, undefined, outcome)
            this.update()
        },
        create(): void {
            const stay = {
                active: true,
                nihss: {
                    score: 0,
                    visual_fields: [0,0,0,0,0,0,0,0],
                    visual_inattention: [0,0,0,0,0,0,0,0]
                },
                _answers: {},
            } as any

            const data: Assessment = {
                id: -1,
                uid: this.uid,
                data: stay,
                outcome: this.outcome,
                reason: this.reason,
                site: this.pathMatch,
                meta: {
                    browser: this.$store.direct.state.session.browser,
                    schemas: {
                        base: {
                            name: this.$store.direct.state.asap.asapMeta.schema,
                            version: this.meta.baseSchemaVersion,
                        },
                        site: {
                            name: this.pathMatch,
                            version: this.meta.siteSchemaVersion,
                            downloaded: this.meta.downloadSiteSchema,
                        },
                    },
                }
            }

            utils.request.post('/asap/asmt/', false, data)
            .then(res => {
                let assessment: Assessment = safeDecoding(
                    res.body, assessmentDecoder,
                    'Assessment.create',
                )
                this.stay_id = assessment.id
                this.$store.direct.commit.stays_v2.fullSchema(this.schema!)

                stay.id = this.stay_id
                stays.mutAddStay(stay)
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
        },
        update(): void {
            const data: Partial<Assessment> = {
                outcome: this.outcome,
                reason: this.reason,
            }
            utils.request.patch(`/asap/asmt/${this.uid}/`, false, data)
            .then(res => {})
            .catch(err => { utils.handleRequestError(err) })
        },
    },
})

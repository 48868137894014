
















































































import Vue from 'vue'
import { filter, find, get, includes, isEqual, map } from "lodash-es"
import {
    ctAngiography_v1, ctAngiography_v2,
    ctPerfusion_v1, ctPerfusion_v2,
    nonContrastCTBrain_v1, nonContrastCTBrain_v2,
    mriBrain_v2,
} from 'utils/text/investigation'

import utils from 'utils'
import stays from '@store/stays'

import { InvSection, InvInfo } from 'models/data/investigation'
import { InvChanges } from 'models/payload'
import { InvestigationStatus } from "models/med_templates/enums"

import BaselineToggle from './BaselineToggle.vue'
import Dropdown from "@shared/inputs/Dropdown.vue"
import BasicInvestigation from './BasicInvestigation.vue'

import NonContrastCTBrain_v1 from './advanced/NonContrastCTBrain_v1.vue'
import CTPerfusion_v1 from './advanced/CTPerfusion_v1.vue'
import CTAngiography_v1 from './advanced/CTAngiography_v1.vue'

import MRIBrain_v2 from './advanced/MriBrain_v2.vue'
import NonContrastCTBrain_v2 from './advanced/NonContrastCTBrain_v2.vue'
import CTPerfusion_v2 from './advanced/CTPerfusion_v2.vue'
import CTAngiography_v2 from './advanced/CTAngiography_v2.vue'

export default Vue.extend({
    components: {
        BaselineToggle,
        BasicInvestigation,
        Dropdown,
    },
    props: {
        accordionID: String,
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String as () => InvSection,
            required: true
        },
        invInfo: {
            type: Object as () => InvInfo,
            required: true
        }
    },
    data() {
        const htmlID = `${utils.getUID()}___bl_inv_${this.invInfo.schema.id}`

        return {
            htmlID,
            opened: false
        }
    },
    computed: {
        invsVersion(): string {
            return this.$store.direct.state.session.invs_version
        },
        invsV2(): boolean {
            return this.invsVersion === 'v2'
        },
        isBaseline(): boolean {
            return this.section === InvSection.BASELINE
        },
        isNonConCTBrain_v1(): boolean {
            return this.invTitle === (this.invsV2 ? 'Non-Contrast CT Brain v1' : 'Non-Contrast CT Brain')
        },
        isCTPerfusion_v1(): boolean {
            return this.invTitle === (this.invsV2 ? 'CT Perfusion v1' : 'CT Perfusion')
        },
        isCTAngiography_v1(): boolean {
            return this.invTitle === (this.invsV2 ? 'CT Angiography (Aortic arch to COW) v1' : 'CT Angiography (Aortic arch to COW)')
        },
        isNonConCTBrain_v2(): boolean {
            return this.invTitle === (this.invsV2 ? 'Non-Contrast CT Brain' : 'Non-Contrast CT Brain v2')
        },
        isCTPerfusion_v2(): boolean {
            return this.invTitle === (this.invsV2 ? 'CT Perfusion' : 'CT Perfusion v2')
        },
        isCTAngiography_v2(): boolean {
            return this.invTitle === (this.invsV2 ? 'CT Angiography (Aortic arch to COW)' : 'CT Angiography (Aortic arch to COW) v2')
        },
        isMRIBrain_v2(): boolean {
            return this.invTitle === (this.invsV2 ? 'MRI Brain' : 'MRI Brain v2')
        },
        investigationComponent(): any {
            if (this.isNonConCTBrain_v1) return NonContrastCTBrain_v1
            if (this.isCTPerfusion_v1) return CTPerfusion_v1
            if (this.isCTAngiography_v1) return CTAngiography_v1
            if (this.isNonConCTBrain_v2) return NonContrastCTBrain_v2
            if (this.isCTPerfusion_v2) return CTPerfusion_v2
            if (this.isCTAngiography_v2) return CTAngiography_v2
            if (this.isMRIBrain_v2) return MRIBrain_v2
            return BasicInvestigation
        },
        invTitle: {
            get(): string {
                return this.invInfo.schema.title
            },
            set(val: string) {
                stays.extActUpdateInv(this.stay_id, this.invInfo.inv, this.section, { title: val })
            }
        },
        notes(): string {
            return get(this.invInfo, 'inv.notes') || ""
        },
        notesSummary(): string {
            const inv = this.invInfo.inv
            const schema = this.invInfo.schema

            if (this.isCTAngiography_v1) return inv ? ctAngiography_v1(inv, schema) : ''
            if (this.isNonConCTBrain_v1) return inv ? nonContrastCTBrain_v1(inv, schema) : ''
            if (this.isCTPerfusion_v1) return inv ? ctPerfusion_v1(inv, schema) : ''
            if (this.isNonConCTBrain_v2) return inv ? nonContrastCTBrain_v2(inv, schema) : ''
            if (this.isCTPerfusion_v2) return inv ? ctPerfusion_v2(inv, schema, this.stay_id) : ''
            if (this.isCTAngiography_v2) return inv ? ctAngiography_v2(inv, schema, 'text') : ''
            if (this.isMRIBrain_v2) return inv ? mriBrain_v2(inv, schema) : ''

            const stayResultIDs = get(this.invInfo, 'inv.results') || []
            if (stayResultIDs.length) {
                // Return concatenated result titles as the summary
                const invResults = this.invInfo.schema.results
                const stayResults = filter(invResults, function(result) {
                    return includes(stayResultIDs, result.id)
                })
                return map(stayResults, 'title').join(', ')
            }
            // Return the first line of the notes
            return this.notes.split(/(?:\r\n|\r|\n)/gm)[0]
        },
        statuses() {
            const speciality = stays.getSpeciality(this.stay_id)
            if (speciality) return speciality.investigation_status_labels
            return []
        },
        status: {
            get(): number {
                return this.invInfo.inv.status
            },
            set(status: number) {
                this.updateInvestigation({ status })
            }
        },
        statusText(): string {
            const schema = find(this.statuses, ['id', this.status])
            return schema ? schema.title : "?"
        },
        results() {
            if (!this.isBaseline) {
                // We `watch` this value and mark the investigation complete if new results are added
                return this.invInfo.inv.results
            }
            return []
        }
    },
    watch: {
        results(newResults, oldResults) {
            // Mark the investigation complete if a new result is ticked
            if (newResults.length && !isEqual(newResults, oldResults)) {
                this.markComplete()
            }
        }
    },
    methods: {
        deleteInv() {
            if (!this.invInfo.inv) return
            this.updateInvestigation({ deleted: true })
        },
        markComplete() {
            if (this.invInfo.inv.done) return
            const completeStatus = find(this.statuses, ['status', InvestigationStatus.DONE])
            if (completeStatus) {
                this.status = completeStatus.id
            }
        },
        hidden() {
            this.opened = false
            if (!this.isBaseline)
                this.$store.direct.commit.session.clearOpenInvID()
        },
        shown() {
            this.opened = true
            // We store which (non-baseline, active) investigation is open to avoid
            // moving it between lists while a user is editing the results. 
            // Ticking results changes the status to complete.
            if (!this.isBaseline && !this.invInfo.inv.done)
                this.$store.direct.commit.session.setOpenInvID(this.invInfo.inv.id)
        },
        updateInvestigation(data: InvChanges) {
            stays.extActUpdateInv(
                this.stay_id, this.invInfo.inv, this.section, data
            )
        }
    },
})

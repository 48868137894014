var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { on: { submit: _vm.changePassword } },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Current password" } },
        [
          _c("b-form-input", {
            attrs: {
              type: "password",
              required: "",
              placeholder: "Current password"
            },
            model: {
              value: _vm.form.old_password,
              callback: function($$v) {
                _vm.$set(_vm.form, "old_password", $$v)
              },
              expression: "form.old_password"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "New password" } },
        [
          _c("b-form-input", {
            attrs: {
              type: "password",
              required: "",
              placeholder: "New password"
            },
            model: {
              value: _vm.form.new_password,
              callback: function($$v) {
                _vm.$set(_vm.form, "new_password", $$v)
              },
              expression: "form.new_password"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { label: "Confirm new password" } },
        [
          _c("b-form-input", {
            attrs: {
              type: "password",
              state: _vm.form.confirm_password.length
                ? _vm.form.confirm_password === _vm.form.new_password
                : null,
              required: "",
              placeholder: "Confirm new password"
            },
            model: {
              value: _vm.form.confirm_password,
              callback: function($$v) {
                _vm.$set(_vm.form, "confirm_password", $$v)
              },
              expression: "form.confirm_password"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "float-right",
          attrs: { type: "submit", variant: "primary" }
        },
        [_vm._v("Change Password")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
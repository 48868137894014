
















import mixins from "vue-typed-mixins"
import { isObject } from "lodash-es"

import { PropExtInfo } from "models"
import { NestedProp } from "models/schema"
import { Diagnosis } from "models/med_templates/diagnosis"

import ManagementPlanSteps from "@inputs/diagnosis/ManagementPlanSteps.vue"
import RInput from "@inputs/Input.vue"

import DisplayMixin from "@mixins/Display.vue"

export default mixins(DisplayMixin).extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        diagnosis: {
            validator: (val: Diagnosis | undefined) => {
                return val === undefined || isObject(val)
            },
            required: true
        }
    },
    computed: {
        mgmtPlanSchema(): NestedProp | undefined {
            return this.getSectionSchema("management_plan")
        },
        planNotesPropExtInfo(): PropExtInfo {
            return this.generatePropExtInfo(
                "management_plan",
                this.mgmtPlanSchema ? this.mgmtPlanSchema.children : {},
                [{field: "notes", force_label: "Additional plan notes"}]
            )[0]
        },
    },
    components: {
        ManagementPlanSteps,
        RInput,
    }
})

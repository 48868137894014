









import Vue from 'vue'
import * as moment from 'moment'
import { throttle, toInteger } from 'lodash-es'
import utils from 'utils'

export default Vue.extend({
    data() {
        return {
            modalId: `${utils.getUID()}___sesh_warn`,
            expiryWatcher: -1,
            extendWatcher: -1,
            expiresIn: NaN,
            extendSession: false,
            warnExpiry: false,
        }
    },
    computed: {
        loggedIn(): boolean {
            return this.$store.direct.getters.session.loggedIn
        },
        $_interacted(): any {
            return throttle(this.$_extend, 15000)
        }
    },
    watch: {
        loggedIn: {
            immediate: true,
            handler: function(loggedIn) {
                if (loggedIn)
                    this.startMonitor()
                else
                    this.stopMonitor()
            }
        },
        warnExpiry(val) {
            if (val)
                this.$root.$emit('bv::show::modal', this.modalId)
        },
    },
    destroyed() {
        this.stopMonitor()
    },
    methods: {
        $_extend(): void {
            this.extendSession = true
        },
        renewSession() {
            this.$store.direct.dispatch.session.snooze()
            .then(res => {
                this.$toasted.info('Session extended.')
            })
        },
        startMonitor() {
            if (this.expiryWatcher === -1) {
                this.expiryWatcher = window.setInterval(() => {
                    const sessionAgeSecs = toInteger(utils.cookie.get(`${window.scrawl.cookieIDBase}__session_age`))
                    const warningSecs = toInteger(sessionAgeSecs * 0.20) || 30
                    const sessionExpiryS = utils.cookie.get(`${window.scrawl.cookieIDBase}__session_expiry`) || ''
                    const sessionExpiry = moment(sessionExpiryS)
                    // 10s window for UX to auto-logout, or user to manually extend session
                    this.expiresIn = sessionExpiry.diff(moment(), 'seconds') - 10

                    if (this.expiresIn > warningSecs) {
                        this.warnExpiry = false
                    }
                    else if (this.expiresIn <= warningSecs && this.expiresIn > 0) {
                        this.warnExpiry = true
                    }
                    else {
                        this.warnExpiry = false
                        this.$store.direct.dispatch.session.logout()
                        this.stopMonitor()
                    }
                }, 1000)
            }

            if (this.extendWatcher === -1) {
                this.extendWatcher = window.setInterval(() => {
                    if (this.extendSession) {
                        this.extendSession = false
                        this.$store.direct.dispatch.session.snooze()
                    }
                }, 60000) // 1min x 60s x 1000ms
            }

            document.addEventListener('mousedown', this.$_interacted)
            document.addEventListener('keydown', this.$_interacted)
            document.addEventListener('touchmove', this.$_interacted)
        },
        stopMonitor() {
            window.clearInterval(this.expiryWatcher)
            this.warnExpiry = false
            this.expiryWatcher = -1
            window.clearInterval(this.extendWatcher)
            this.extendWatcher = -1
            this.$root.$emit('bv::hide::modal', this.modalId)
            document.removeEventListener('mousedown', this.$_interacted)
            document.removeEventListener('keydown', this.$_interacted)
            document.removeEventListener('touchmove', this.$_interacted)
        }
    }
})

import { string as dstring, number as dnumber, array as darray, nullable, exact, guard } from 'decoders'
import { Item, itemDef } from "../base"


export interface FollowUpWith extends Item {
    instruction: string | null
    order: number
    speciality: number
}

const followUpWith = exact({
    ...itemDef,
    instruction: nullable(dstring),
    order: dnumber,
    speciality: dnumber,
})

export const followUpWithsDecoder = guard(darray(followUpWith), { style: 'simple' })

try { const _followUpWiths: FollowUpWith[] = followUpWithsDecoder({}) } catch(e) {}

import { render, staticRenderFns } from "./DischargePlans.vue?vue&type=template&id=765aa137&"
import script from "./DischargePlans.vue?vue&type=script&lang=ts&"
export * from "./DischargePlans.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('765aa137')) {
      api.createRecord('765aa137', component.options)
    } else {
      api.reload('765aa137', component.options)
    }
    module.hot.accept("./DischargePlans.vue?vue&type=template&id=765aa137&", function () {
      api.rerender('765aa137', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/data-entry/views/ward/discharge/DischargePlans.vue"
export default component.exports
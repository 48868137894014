































import mixins from 'vue-typed-mixins'
import StayMixin from '@mixins/Stay.vue'
import InputMixin from '@mixins/Input.vue'
import { clone, map } from 'lodash-es'
import utils from 'utils'
import { MDTPerson } from 'models/med_templates/team'

export default mixins(StayMixin, InputMixin).extend({
    props: {
        autoFill: {
            type: Boolean,
            default: true
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            editHtmlId: `${uid}___mdt`,
        }
    },
    computed: {
        mdtPresentDesc(): string {
            return map(this.stay?.mdt.people_present || [], id => this.$store.direct.getters.templates.mdtPeopleDict[id].title).join(', ')
        },
        specialities(): number[] {
            return this.$store.direct.state.user.filters.specialities
        },
        canMDT(): boolean {
            return this.specialities.length === 1
        },
        mdtPeople(): MDTPerson[] {
            if (!this.canMDT)
                return []
            return this.$store.direct.getters.templates.mdtPeopleBySpeciality[this.specialities[0]] || []
        },
        mdtPresent: {
            get(): number[] {
                return this.stay?.mdt.people_present || []
            },
            set(ids: number[]) {
                this.setFieldVal(this.stay_id, 'mdt.people_present', ids)
            }
        },
        sessionMDT(): number[] {
            return this.$store.direct.state.session.mdtPresent
        },
    },
    watch: {
        sessionMDT() {
            // this.useSessionMDT(true)
        },
    },
    mounted() {
        /*
        if (this.autoFill)
            this.useSessionMDT()
        */
    },
    methods: {
        useSessionMDT(force: boolean = false) {
            if (!this.stay)
                return
            const sessionMDT = this.$store.direct.state.session.mdtPresent
            if (sessionMDT.length === 0 && !force)  // do not use an empty MDT selection
                return
            if (!utils.isEqual(this.stay.mdt, sessionMDT))
                this.setFieldVal(this.stay_id, 'mdt.people_present', clone(sessionMDT))
        }
    }
})

import {
    string as dstring,
    number as dnumber,
    array,
    exact, guard
} from 'decoders'

export interface ChoiceItem {
    /** the verbose description of the enum member */
    label: string
    /** the name of the enum member, used as the value */
    value: string
    /** the Pythonic value of the enum member */
    id: number
}

export interface ChoicesGroup {
    label: string
    choices: ChoiceItem[]
}

const choiceItem = exact({
    label: dstring,
    value: dstring,
    id: dnumber,
})

export const choiceItems = array(choiceItem)

const choicesGroup = exact({
    label: dstring,
    choices: array(choiceItem)
})

const choicesGroups = array(choicesGroup)

export const choiceItemsDecoder = guard(choiceItems, { style: 'simple' })
export const choicesGroupsDecoder = guard(choicesGroups, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _choiceItems: ChoiceItem[] = choiceItemsDecoder({}) } catch(e) {}
try { const _choicesGroups: ChoicesGroup[] = choicesGroupsDecoder({}) } catch(e) {}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-form", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-4 mb-0" },
          [
            _c("label", [
              _vm._v("\n                Team present\n            ")
            ]),
            _vm._v(" "),
            _c(
              "b-btn",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: _vm.editHtmlId,
                    expression: "editHtmlId"
                  }
                ],
                staticClass: "d-inline-block",
                attrs: { variant: "info", size: "sm" }
              },
              [_vm._v("Edit")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-8" }, [
          _c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.mdtPresentDesc))])
        ])
      ]),
      _vm._v(" "),
      _c(
        "b-collapse",
        { staticClass: "mt-2", attrs: { id: _vm.editHtmlId } },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c("b-card-body", [
                _c(
                  "div",
                  { staticClass: "scrwl-grouped" },
                  _vm._l(_vm.mdtPeople, function(person) {
                    return _c(
                      "b-check",
                      {
                        key: person.id,
                        staticClass: "scrawl-checkbox d-inline-block mb-1",
                        attrs: { size: "lg", value: person.id },
                        model: {
                          value: _vm.mdtPresent,
                          callback: function($$v) {
                            _vm.mdtPresent = $$v
                          },
                          expression: "mdtPresent"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(person.title) +
                            "\n                    "
                        )
                      ]
                    )
                  }),
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
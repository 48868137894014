var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "debug-chatbox" },
        [
          _c(
            "b-card",
            {
              attrs: { "no-body": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _vm._v(
                              "\n                Sync Errors\n                "
                            ),
                            _c("fnt-a-icon", {
                              staticClass: "svg ml-auto r-clickable",
                              attrs: { icon: ["fal", "times"] },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.hide.apply(null, arguments)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3268261942
              )
            },
            [
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content mx-2 mb-2" },
                [
                  _c("p", [
                    _vm._v(
                      "Detailed sync errors are listed here, with the latest errors at the top. If you need to contact support regarding an issue, you should copy these errors to provide more context to the technical team."
                    )
                  ]),
                  _vm._v(" "),
                  _vm.clipboardSupported
                    ? _c(
                        "b-btn",
                        {
                          attrs: {
                            id: _vm.copyBtnHtml,
                            variant: "info",
                            size: "sm"
                          }
                        },
                        [_vm._v("Copy")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "warning", size: "sm" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.clearErrors.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Clear")]
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", {
                    attrs: { id: _vm.syncErrorsHtml },
                    domProps: { innerHTML: _vm._s(_vm.errorsHtml) }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
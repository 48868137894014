








































import mixins from 'vue-typed-mixins'
import { cloneDeep, find, filter, includes, map } from 'lodash-es'

import stays from '@store/stays'

import InputMixin from '../mixins/Input.vue'
import Dropdown from '@shared/inputs/Dropdown.vue'
import { Team, TeamDoctor, TeamMember } from 'models/med_templates/team'

export default mixins(InputMixin).extend({
    components: {
        Dropdown
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            doctorsPath: `${this.section}.doctors_present`,
            membersPath: `${this.section}.members_present`,
            peoplePath: `${this.section}.people_present`,
            teamPath: `${this.section}.team`,
            teamUpdated: false,
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        doctorsDebugText(): string {
            return `[${this.doctorsPath}: ${this.doctorsPresent}]`
        },
        membersDebugText(): string {
            return `[${this.membersPath}: ${this.teamPresent}]`
        },
        peopleDebugText(): string {
            return `[${this.peoplePath}: ${this.peoplePresent}]`
        },
        people() {
            const speciality = stays.getSpeciality(this.stay_id)
            if (speciality) return speciality.people_present
            return []
        },
        teams(): Team[] {
            let teams = this.$store.state.templates.teams

            // Filter teams by speciality
            const speciality = stays.getSpeciality(this.stay_id)
            if (speciality) {
                teams = filter(teams, ['speciality', speciality.id])
            }

            teams = filter(teams, team => (team.active || this.teamID === team.id))
            teams = map(teams, team => {
                let t = team
                if (!t.active) {
                    t = cloneDeep(t)
                    t.title += ' [inactive]'
                }
                return t
            })

            return teams
        },
        team(): Team | undefined {
            return find(this.teams, ['id', this.teamID])
        },
        teamID: {
            get(): number | null | undefined {

                return this.getFieldVal(this.stay_id, this.teamPath)
                // Use the user's preferred team if no team is associated with the stay
                // return selectedTeam || user.state.team
            },
            set(teamID: number | null) {
                // Update the user's preference
                if (this.$store.direct.state.user.team !== teamID)
                    this.$store.direct.dispatch.user.updatePush({ team: teamID })
                // Update the stay's team
                this.setFieldVal(this.stay_id, this.teamPath, teamID)
            }
        },
        doctors(): TeamDoctor[] {
            let doctors = this.team ? this.team.doctors : []
            doctors = filter(doctors, doctor => (doctor.active || includes(this.doctorsPresent, doctor.id)))
            doctors = map(doctors, doctor => {
                let d = doctor
                if (!d.active) {
                    d = cloneDeep(d)
                    d.title += ' [inactive]'
                }
                return d
            })
            return doctors
        },
        teamMembers(): TeamMember[] {
            let members = this.team ? this.team.members : []
            members = filter(members, member => (member.active || includes(this.teamPresent, member.id)))
            members = map(members, inst => {
                let o = inst
                if (!o.active) {
                    o = cloneDeep(o)
                    o.title += ' [inactive]'
                }
                return o
            })
            return members
        },
        doctorsPresent: {
            get(): number[] {
                return this.getFieldVal(this.stay_id, this.doctorsPath)
            },
            set(val: number[]) {
                this.setFieldVal(this.stay_id, this.doctorsPath, val)
            }
        },
        teamPresent: {
            get(): number[] {
                return this.getFieldVal(this.stay_id, this.membersPath)
            },
            set(val: number[]) {
                this.setFieldVal(this.stay_id, this.membersPath, val)
            }
        },
        peoplePresent: {
            get(): number[] {
                return this.getFieldVal(this.stay_id, this.peoplePath)
            },
            set(val: number[]) {
                this.setFieldVal(this.stay_id, this.peoplePath, val)
            }
        }
    },
    watch: {
        teamID: {
            handler: function(val: number | null | undefined) {
                if (!this.teamUpdated) {
                    if (val === null && this.$store.direct.state.user.team !== null)
                        this.teamID = this.$store.direct.state.user.team
                    this.teamUpdated
                }
            },
            immediate: true
        }
    },
})
























import Vue from 'vue'
import { map } from 'lodash-es'
import utils from 'utils'
import { Item } from 'models/base'

// TODO warn if trying to print while in Anonymise mode
export default Vue.extend({
    props: {
        stay_ids: {
            type: Array,
            required: true
        },
        urlSuffix: {
            type: String,
            required: true
        },
        labelBase: {
            type: String,
            default: 'Print'
        },
        buttonClasses: {
            type: String,
            default: '',
        },
        versions: {
            type: Array as () => Number[],
            default: () => [1]
        },
    },
    computed: {
        csrfToken(): string {
            return utils.cookie.get(window.scrawl.cookieCSRFKey) || ''
        },
        specialityId(): number | undefined {
            return this.$store.direct.getters.user.speciality?.id || -1
        },
        onlyOrtho(): boolean {
            return this.$store.direct.getters.user.onlyOrtho
        },
        addHeader(): boolean {
            return this.$store.direct.state.user.print_header
        },
        multiVersions(): boolean {
            return this.versions.length > 1
        },
        versionItems(): Item[] {
            return map(this.versions, v => ({ title: `${v}`, id: Number(v) }))
        },
        printListURL(): string {
            return `${window.scrawl.apiBaseURL}/${this.urlSuffix}/`
        },
        printLabel(): string {
            return this.onlyOrtho ? `${this.labelBase} (Ortho)` : this.labelBase
        },
    },
    methods: {
        finalLabel(version: string): string {
            return this.multiVersions ? `${this.printLabel} v${version}` : this.printLabel
        },
    }
})

import { map } from 'lodash-es'
import { FilterParam } from 'models/store/user'
import { enumKeys } from 'models/_helpers'

export type FilterBy = 'doc' | 'nurse'
export type FilterMode = 'seen' | 'strict'
export type TSMode = 'basic' | 'adv'

export enum TSScope {
    Telestroke = 'ts',
    Hospitals = 'site',
    // All = 'any'
}
export const TSScope_keys = enumKeys(TSScope)
export const TSScope_values = map(TSScope_keys, k => TSScope[k])
export const TSScope_items = map(TSScope_keys, k => ({ value: TSScope[k], text: k }))

export interface FiltersParams {
    /** type to filter by */
    t: FilterBy
    /** show discharged */
    sd: boolean
    /** period. only relevant if sd is true */
    pd: string
    /** doctors */
    d: FilterParam
    /** diagnoses */
    dg: FilterParam
    /** diagnoses strict - exact match or return empty */
    dgs: boolean
    /** specialities */
    sp: FilterParam
    /** spaces (wards) */
    spc: FilterParam
    /** speciality mode. 'seen' will include stays from other specialities that have been seen. */
    spm: FilterMode
    /** care classifications */
    c: FilterParam
    /** hospitals (sites) */
    h: FilterParam
    /** hospitals - strict */
    hs: boolean
}

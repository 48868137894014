var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("text-base", {
    attrs: {
      stay_id: _vm.stay_id,
      markup: _vm.markup,
      editorHeight: _vm.height,
      modalOkTitle: "Mark MDT Complete",
      tag: "mdt",
      customMarkComplete: _vm.markMDTComplete
    },
    scopedSlots: _vm._u([
      {
        key: "modalContent",
        fn: function() {
          return [
            _c("p", [_vm._v("Do you want to mark the MDT as complete?")]),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(
                "\n            The following sections will be cleared:\n            "
              ),
              _c("ul", { staticClass: "mb-0" }, [
                _c("li", [_vm._v("Team Present")])
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c("table", { staticClass: "table table-hover" }, [
        _c(
          "tbody",
          _vm._l(_vm.fields, function(field, index) {
            return _c("tr", { key: index }, [
              _c("td", { staticClass: "text-right w-50" }, [
                _vm._v(_vm._s(field.title))
              ]),
              _vm._v(" "),
              _vm.data[field.key] === undefined
                ? _c("td", [_vm._m(0, true)])
                : _c(
                    "td",
                    { staticClass: "w-50" },
                    [
                      field.calculation
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                field.calculation(
                                  _vm.staysByField[field.key],
                                  _vm.data.stays
                                )
                              )
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.data[field.key].length) +
                                "\n                "
                            )
                          ]),
                      _vm._v(" "),
                      field.extra
                        ? _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  field.extra(_vm.staysByField[field.key])
                                ) +
                                "\n                "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.infoStaysByField[index].length
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-2",
                              attrs: { title: "Show stay details" }
                            },
                            [
                              _c("fnt-a-icon", {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal",
                                    value: _vm.staysInfoModalId(index),
                                    expression: "staysInfoModalId(index)"
                                  }
                                ],
                                staticClass: "r-clickable",
                                attrs: { icon: ["fal", "info-circle"] }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("stays-info-modal", {
                        attrs: {
                          modalId: _vm.staysInfoModalId(index),
                          field: field,
                          data: _vm.data,
                          infoStays: _vm.infoStaysByField[index],
                          textVersion: _vm.textVersion
                        }
                      })
                    ],
                    1
                  )
            ])
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("em", [_c("strong", [_vm._v("Data missing")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isBaseline
        ? _c("baseline-toggle", {
            attrs: {
              accordionID: _vm.accordionID,
              "toggle-target-id": _vm.htmlID,
              opened: _vm.opened,
              stay_id: _vm.stay_id,
              section: _vm.section,
              "inv-info": _vm.invInfo,
              "results-text": _vm.notesSummary
            }
          })
        : _c(
            "b-list-group-item",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: _vm.htmlID,
                  expression: "htmlID"
                }
              ],
              staticClass: "r-clickable",
              attrs: { role: "tab" }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v(_vm._s(_vm.invTitle))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v(_vm._s(_vm.statusText))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "span",
                    {
                      staticClass: "align-middle",
                      staticStyle: { "white-space": "pre-wrap" }
                    },
                    [_vm._v(_vm._s(_vm.notesSummary))]
                  )
                ])
              ])
            ]
          ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: {
            id: _vm.htmlID,
            accordion: _vm.accordionID,
            role: "tabpanel"
          },
          on: { shown: _vm.shown, hidden: _vm.hidden }
        },
        [
          _vm.invInfo.inv
            ? _c(
                "div",
                { staticClass: "card-body" },
                [
                  _vm.invInfo.inv.other
                    ? _c(
                        "b-form-group",
                        { attrs: { "label-cols-sm": "4", label: "Title" } },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: "[title]" },
                            model: {
                              value: _vm.invTitle,
                              callback: function($$v) {
                                _vm.invTitle = $$v
                              },
                              expression: "invTitle"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isBaseline
                    ? _c("dropdown", {
                        attrs: {
                          horizontal: "",
                          label: "Status",
                          items: _vm.statuses,
                          "text-field": "title",
                          "value-field": "id"
                        },
                        model: {
                          value: _vm.status,
                          callback: function($$v) {
                            _vm.status = $$v
                          },
                          expression: "status"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(_vm.investigationComponent, {
                    tag: "component",
                    attrs: {
                      stay_id: _vm.stay_id,
                      "parent-html-id": _vm.htmlID,
                      "inv-info": _vm.invInfo,
                      section: _vm.section
                    }
                  }),
                  _vm._v(" "),
                  !_vm.isBaseline
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-btn",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: _vm.invInfo.inv.id + "__delete",
                                  expression: "`${invInfo.inv.id}__delete`"
                                }
                              ],
                              staticClass: "text-danger",
                              attrs: { variant: "link", size: "sm" }
                            },
                            [
                              _vm._v(
                                "\n                    Delete\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-modal",
                            {
                              attrs: {
                                id: _vm.invInfo.inv.id + "__delete",
                                centered: "",
                                "hide-header": true,
                                "ok-title": "Delete",
                                "ok-variant": "danger"
                              },
                              on: { ok: _vm.deleteInv }
                            },
                            [
                              _vm._v(
                                '\n                    Are you sure you want to delete "' +
                                  _vm._s(_vm.invInfo.schema.title) +
                                  '"?\n                '
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
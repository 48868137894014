var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("generic", {
        attrs: {
          stay_id: _vm.stay_id,
          groups: _vm.presentationGroups,
          "override-msg": _vm.presentationMsg
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
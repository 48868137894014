var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("b-overlay", {
          attrs: { "no-wrap": "", variant: "transparent", show: _vm.updating },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function() {
                return [
                  _c(
                    "h3",
                    {
                      staticClass: "py-2 px-3 mb-5 border rounded bg-secondary",
                      staticStyle: { opacity: "0.8" }
                    },
                    [
                      _c("em", { staticClass: "loading-dots" }, [
                        _vm._v("Redirecting"),
                        _c("span", [_vm._v(".")]),
                        _c("span", [_vm._v(".")]),
                        _c("span", [_vm._v(".")])
                      ])
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        }),
        _vm._v(" "),
        _c("h3", [
          _vm._v("\n            Syndrome Selection\n            "),
          _vm.debugMode
            ? _c("span", { staticClass: "text-monospace" }, [
                _vm._v("\n                [Current: "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.staySyndrome ? _vm.staySyndrome.title : "None")
                  )
                ]),
                _vm._v("]\n            ")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "fade" } },
          [
            _c(
              "b-card-group",
              { staticClass: "row" },
              [
                _vm._l(_vm.syndromes, function(syndrome) {
                  return _c(
                    "div",
                    { key: syndrome.id, staticClass: "col-6 mb-4" },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "syndrome-card",
                          class: {
                            "border border-success selected": _vm.tabSelected(
                              syndrome.id
                            )
                          },
                          attrs: {
                            "header-bg-variant": _vm.tabSelected(syndrome.id)
                              ? "success"
                              : ""
                          },
                          on: {
                            click: function($event) {
                              _vm.staySyndromeID = syndrome.id
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _c(
                                      "h4",
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(syndrome.title))
                                        ]),
                                        _vm._v(" "),
                                        _vm.tabSelected(syndrome.id)
                                          ? _c("fnt-a-icon", {
                                              staticClass: "svg float-right",
                                              attrs: {
                                                icon: ["fal", "check"],
                                                size: "lg"
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("p", { staticClass: "card-text" }, [
                            syndrome.features.length
                              ? _c(
                                  "ul",
                                  { staticClass: "pl-2" },
                                  _vm._l(syndrome.features, function(feature) {
                                    return _c("li", { key: feature.id }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(feature.title) +
                                          "\n                                "
                                      )
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.staySyndrome
                  ? _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "b-btn",
                          {
                            attrs: { variant: "success" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goToNextView.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Next")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            attrs: {
              id: _vm.routeModalId,
              size: "xl",
              centered: "",
              title: "Assessment Route",
              visible: _vm.routeModalVisible,
              "ok-only": "",
              "ok-disabled": "",
              "ok-title": "Select One"
            }
          },
          [
            _c("b-card-group", { staticClass: "row px-3" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c(
                    "b-card",
                    {
                      staticClass:
                        "scrwl-hover-border-info py-4 text-center r-clickable",
                      on: { click: _vm.goToAdmission }
                    },
                    [
                      _c("h4", [_vm._v("Admission")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(
                          "\n                            Thorough initial assessment\n                        "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c(
                    "b-card",
                    {
                      staticClass:
                        "scrwl-hover-border-info py-4 text-center r-clickable",
                      on: { click: _vm.goToWard }
                    },
                    [
                      _c("h4", [_vm._v("Review")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "card-text" }, [
                        _vm._v(
                          "\n                            Targeted review of patient\n                        "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core"

// importing from the top-level fontawesome file results in the entire
// set of icons being added
import { faBug } from '@fortawesome/free-solid-svg-icons/faBug'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faExclamation as fasExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faStopCircle } from '@fortawesome/free-solid-svg-icons/faStopCircle'

import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft'
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleRight'
import { faTimesCircle as faTimesCircleSolid } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'

import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons/faArrowToBottom'
import { faArrowToRight } from '@fortawesome/pro-regular-svg-icons/faArrowToRight'
import { faArrowsAltV } from '@fortawesome/pro-regular-svg-icons/faArrowsAltV'
import { faRepeatAlt } from '@fortawesome/pro-regular-svg-icons/faRepeatAlt'

import { faAngleDoubleDown } from '@fortawesome/pro-light-svg-icons/faAngleDoubleDown'
import { faAngleDoubleUp } from '@fortawesome/pro-light-svg-icons/faAngleDoubleUp'

import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import { faExclamation } from '@fortawesome/pro-light-svg-icons/faExclamation'
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt'
import { faFilesMedical } from '@fortawesome/pro-light-svg-icons/faFilesMedical'
import { faHeartbeat } from '@fortawesome/pro-light-svg-icons/faHeartbeat'
import { faHospitalSymbol } from '@fortawesome/pro-light-svg-icons/faHospitalSymbol'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faLungs } from '@fortawesome/pro-light-svg-icons/faLungs'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
import { faNotesMedical } from '@fortawesome/pro-light-svg-icons/faNotesMedical'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt'
import { faThermometer } from '@fortawesome/pro-light-svg-icons/faThermometer'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faUserChart } from '@fortawesome/pro-light-svg-icons/faUserChart'


Vue.component('fnt-a-icon', FontAwesomeIcon)
Vue.component('fnt-a-layers', FontAwesomeLayers)

library.add(
    faAngleDoubleDown,
    faAngleDoubleUp,
    faArrowsAltV,
    faArrowToBottom,
    faArrowToRight,
    faBug,
    faCalendar,
    faCheck,
    faCheckCircle,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faEdit,
    faExclamation,
    fasExclamation,
    faFileAlt,
    faFilesMedical,
    faHeartbeat,
    faHospitalSymbol,
    faInfoCircle,
    faLungs,
    faMinus,
    faNotesMedical,
    faPlayCircle,
    faPlus,
    faQuestionCircle,
    faRepeatAlt,
    faSearch,
    faStopCircle,
    faSyncAlt,
    faSyncAlt,
    faThermometer,
    faTimes,
    faTimesCircle,
    faTimesCircleSolid,
    faUserChart,
)

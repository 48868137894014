



import Vue from 'vue'
export default Vue.extend({
    created() {
        // allows transition between separate routers, such as full and ASAP
        location.reload()
    }
})

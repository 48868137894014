var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.nextStepsChoices, function(choice) {
        return _c("checkbox", {
          key: choice.id,
          staticClass: "d-block",
          attrs: { title: choice.title, cb_value: choice.id },
          model: {
            value: _vm.nextSteps,
            callback: function($$v) {
              _vm.nextSteps = $$v
            },
            expression: "nextSteps"
          }
        })
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._l(_vm.otherNextSteps, function(step) {
            return _c(
              "div",
              { key: "other_next_" + step.id, staticClass: "d-flex mb-2" },
              [
                _c("b-form-input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  staticClass: "w-50",
                  attrs: { placeholder: "[title]" },
                  on: {
                    blur: function($event) {
                      return _vm.updateOtherStep(step)
                    }
                  },
                  model: {
                    value: step.title,
                    callback: function($$v) {
                      _vm.$set(step, "title", $$v)
                    },
                    expression: "step.title"
                  }
                }),
                _vm._v(" "),
                _c(
                  "b-btn",
                  {
                    staticClass: "border-0 text-danger",
                    staticStyle: { "background-color": "initial" },
                    attrs: { variant: "outline-danger" },
                    on: {
                      click: function($event) {
                        return _vm.deleteOtherStep(step)
                      }
                    }
                  },
                  [_vm._v("\n                ×\n            ")]
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "b-btn",
            { attrs: { size: "sm" }, on: { click: _vm.addOtherStep } },
            [_vm._v("+ Add")]
          )
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "col-form-label" }, [
      _c("strong", [_vm._v("Next Steps")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "col-form-label" }, [
      _c("strong", [_vm._v("Other")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
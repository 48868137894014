var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.prefix
    ? _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Higher Centers" } },
                    [
                      _c("generic", {
                        attrs: {
                          stay_id: _vm.stay_id,
                          groups: _vm.hcGroups,
                          "override-msg": _vm.statusMsg
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Vision" } },
                    [
                      _c("vision", {
                        attrs: { stay_id: _vm.stay_id, section: _vm.section }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Motor" } },
                    [
                      _c("motor", {
                        attrs: {
                          "parent-html-id": _vm.htmlID,
                          stay_id: _vm.stay_id,
                          section: _vm.section
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Sensation" } },
                    [
                      _c("generic", {
                        attrs: {
                          stay_id: _vm.stay_id,
                          groups: _vm.sensationGroups,
                          "override-msg": _vm.statusMsg
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.nihss
            ? _c("div", [
                _c(
                  "p",
                  {
                    staticClass: "text-monospace bg-primary p-3 border",
                    staticStyle: { position: "absolute", right: "1.5em" }
                  },
                  [
                    _vm._v("\n            Score: " + _vm._s(_vm.nihss.score)),
                    _c("br"),
                    _vm._v(
                      "\n            Completed: " + _vm._s(_vm.nihss.complete)
                    ),
                    _c("br"),
                    _vm._v(" "),
                    !_vm.nihss.complete
                      ? _c("span", [
                          _vm._v(
                            "Questions: " +
                              _vm._s(_vm.nihss.questions_completed) +
                              "/24"
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("r-input", {
                attrs: { stay_id: _vm.stay_id, field: _vm.notesPropExtInfo }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay
    ? _c(
        "edit-modal",
        {
          attrs: {
            stay_id: _vm.stay.id,
            text: _vm.summaryText,
            scrollable: false
          }
        },
        [
          _c("follow-up-details", {
            attrs: { stay_id: _vm.stay.id, showInstructions: false }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { string as dstring, number as dnumber, boolean as dboolean, array as darray, nullable, exact, guard } from 'decoders'

export interface Doctor {
    id: number
    ext_id: string
    ext_ids: string[]
    first_name: string
    last_name: string
    show: boolean
    speciality: number
}

export function isDoctor(obj: Doctor | number | null): obj is Doctor {
    if (obj === null)
        return false
    return (<Doctor>obj).id !== undefined
}

export const doctor = exact({
    id: dnumber,
    ext_id: dstring,
    ext_ids: darray(dstring),
    first_name: dstring,
    last_name: dstring,
    show: dboolean,
    speciality: dnumber,
})

export const doctorDecoder = guard(doctor, { style: 'simple' })
export const doctorsDecoder = guard(darray(doctor), { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _doctor: Doctor = doctorDecoder({}) } catch(e) {}
try { const _doctors: Doctor[] = doctorsDecoder({}) } catch(e) {}

import { Item, ItemExt, OrderedItem, itemDef, itemExtDef, itemExt, orderedItem } from "../base"
import { number as dnumber, array as darray, exact, guard } from 'decoders'

export interface TeamDoctor extends ItemExt {}
export interface TeamMember extends ItemExt {}
export interface Person extends OrderedItem{}

export interface Team extends ItemExt {
    speciality: number
    doctors: TeamDoctor[]
    members: TeamMember[]
}

export interface MDTPerson extends Item {
    order: number
    speciality: number
}

export const teamDoctor = itemExt
export const teamMember = itemExt
export const person = orderedItem

export const team = exact({
    ...itemExtDef,
    speciality: dnumber,
    doctors: darray(teamDoctor),
    members: darray(teamMember),
})

export const mdtPerson = exact({
    ...itemDef,
    order: dnumber,
    speciality: dnumber,
})

export const teamDecoder = guard(team, { style: 'simple' })
export const teamsDecoder = guard(darray(team), { style: 'simple' })
export const mdtPeopleDecoder = guard(darray(mdtPerson), { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _teamDoctor: TeamDoctor = teamDecoder({}) } catch(e) {}
try { const _teamMember: TeamMember = teamDecoder({}) } catch(e) {}
try { const _team: Team = teamDecoder({}) } catch(e) {}
try { const _teams: Team[] = teamsDecoder({}) } catch(e) {}
try { const _mdtPeople: MDTPerson[] = mdtPeopleDecoder({}) } catch(e) {}

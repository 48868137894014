/**
 * A module for storing settings for the current session.
 */

import { defineModule, localActionContext, localGetterContext } from "direct-vuex"
import utils from 'utils'
import { safeDecoding } from 'utils/store'
import { Meta as ASAPMeta, metaDecoder } from 'models/asap/meta'
import { assign } from 'lodash-es'


export interface SessionState {
    asapMeta: ASAPMeta
    asapMetaLoaded: boolean
}

const initState: SessionState = {
    asapMeta: {
        sites: [],
        schema: 'schema',
        ask_reason: false,
        mrn: false,
        criteria: [1, 2, 3],
        referral_advisory: false,
        asap_pathways: [],
    },
    asapMetaLoaded: false,
}

const sessionModule = defineModule({
    namespaced: true,
    state: (): SessionState => {
        return initState
    },
    getters: {
    },
    mutations: {
        asapMeta(state, meta: Partial<ASAPMeta>) {
            assign(state.asapMeta, meta)
        },
        asapMetaLoaded(state, loaded: boolean) {
            state.asapMetaLoaded = loaded
        },
    },
    actions: {
        pullASAPMeta(context, force?: boolean) {
            if (force === undefined) force = false
            const { commit, state } = moduleActionContext(context)
            if (force || !state.asapMetaLoaded) {
                return utils.request
                .get('static/asap/meta.json', undefined, true)
                .then(res => {
                    commit.asapMeta(safeDecoding(res.body, metaDecoder, 'asap.pullASAPMeta'))
                })
                .catch(err => {
                    utils.handleRequestError(err)
                })
                .then(res => {
                    return utils.request.get(`static/asap/meta-${window.scrawl.instance}.json`, undefined, true)
                })
                .then(res => {
                    commit.asapMeta(safeDecoding(res.body, metaDecoder, 'asap.pullASAPMeta/partial'))
                    if (state.asapMeta.ask_reason)
                        window.scrawl.asapAskReason = 'true'
                })
                .catch(err => {
                    if (err.status === 404) return
                    else utils.handleRequestError(err)
                })    
                .then(res => {
                    commit.asapMetaLoaded(true)
                })    
            }
            return Promise.resolve()
        },
    },
})

export default sessionModule

const moduleGetterContext = (args: [any, any, any, any]) => localGetterContext(args, sessionModule)
const moduleActionContext = (context: any) => localActionContext(context, sessionModule)

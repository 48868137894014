var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card-lapsible", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _c("p", { staticClass: "mb-0 h5" }, [
              _vm._v(_vm._s(_vm.diagnosis))
            ]),
            _vm._v(" "),
            _vm.keyPoints
              ? _c("div", {
                  staticClass: "mt-3",
                  domProps: { innerHTML: _vm._s(_vm.keyPoints) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.features
              ? _c("div", {
                  staticClass: "mt-3",
                  domProps: { innerHTML: _vm._s(_vm.features) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.addNotes
              ? _c("div", {
                  staticClass: "mt-3",
                  domProps: { innerHTML: _vm._s(_vm.addNotes) }
                })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [_c("edit-diagnosis", { attrs: { stay_id: _vm.stay_id } })]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }














import Vue, { PropType } from 'vue'
import { VizData } from 'models/dataviz'
import DataTable from './DataTable.vue'

export default Vue.extend({
    components: {
        DataTable,
    },
    props: {
        data: {
            type: Object as PropType<VizData | null>,
        },
        tables: {
            required: true,
        },
    }
})

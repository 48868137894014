var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("questions", {
    attrs: {
      stay_id: _vm.stay_id,
      category: _vm.QuestionCategory.DIAGNOSIS_FEATURE,
      parentObject: _vm.diagnosis
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { number as dnumber, string as dstring, exact, array, guard } from 'decoders'

export interface AltWardLabel {
    label: string
    alt_label: string
}

const altWardLabel = exact({
    id: dnumber,
    label: dstring,
    alt_label: dstring,
})

export const altWardLabelsDecoder = guard(array(altWardLabel), { style: 'simple' })

try { const _hospitals: AltWardLabel[] = altWardLabelsDecoder({}) } catch(e) {}

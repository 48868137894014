var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Sites")]),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center h-100"
            },
            [_vm._m(0)]
          )
        : _c(
            "b-list-group",
            _vm._l(_vm.sites, function(site) {
              return _vm.siteEnabled(site)
                ? _c(
                    "b-list-group-item",
                    { key: site.id, attrs: { to: _vm.generateUrl(site) } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(site.title) + "\n            "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "float-right",
                          attrs: { href: _vm.generateAltUrl(site) }
                        },
                        [_vm._v("[alt link]")]
                      )
                    ]
                  )
                : _vm._e()
            }),
            1
          )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", [
      _c("em", { staticClass: "loading-dots" }, [
        _vm._v("Loading"),
        _c("span", [_vm._v(".")]),
        _c("span", [_vm._v(".")]),
        _c("span", [_vm._v(".")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }






















































































import Vue from "vue"
import { filter, includes, inRange } from 'lodash-es'
import utils from 'utils'
import stays from '@store/stays'
import { Doctor } from 'models/data/doctor'
import { Hospital } from 'models/med_templates/hospital'
import Dropdown from "@shared/inputs/Dropdown.vue"

function emptyData() {
    return {
        ext_id: null,
        patient: {
            mrn: null,
            first_name: '',
            last_name: '',
            approx_age: null
        },
        doctor: 0,
        site_only: false,
        hospital: null,
    }
}

export default Vue.extend({
    components: {
        Dropdown
    },
    data() {
        const uid = utils.getUID()
        const htmlID = `${uid}___new_stay`

        return {
            uid,
            htmlIDs: {
                createModal: `${uid}___create_stay`,
                multipleModal: `${uid}___multi_stays`,
                main: htmlID,
                visit: `${htmlID}_visit`,
                mrn: `${htmlID}_mrn`,
                fname: `${htmlID}_fname`,
                lname: `${htmlID}_lname`,
                age: `${htmlID}_age`,
                doctor: `${htmlID}_doctor`
            },
            confirmMultiple: null as boolean | null,
            data: emptyData(),
            submitting: false,
            errors: [] as string[]
        }
    },
    computed: {
        isAllowed(): boolean {
            return !this.$store.direct.state.user.is_temp && this.$store.direct.getters.user.canCreateStays
        },
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        isDeveloper(): boolean {
            return this.$store.direct.getters.user.isDeveloper
        },
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        formJSON(): string {
            return JSON.stringify(this.data, undefined, 2)
        },
        allDoctors(): readonly Doctor[] {
            return this.$store.direct.getters.templates.visibleDoctors
        },
        doctorDDLabel(): string {
            return this.data.site_only ? 'Doctor (optional)' : 'Doctor'
        },
        hospitals(): Hospital[] {
            const allHospitals = this.$store.direct.state.templates.hospitals
            if (this.isTSSiteUser)
                return filter(allHospitals, h => includes(this.$store.direct.state.user.hospitals, h.id))
            return allHospitals
        },
    },
    watch: {
        'data.site_only'(val: any) {
            if (this.isTSSiteUser && !val) {
                this.$nextTick(() => {
                    this.data.site_only = true
                })
                return
            }
            if (!val)
                this.data.hospital = null
        },
        isTSSiteUser: {
            handler: function(val: any) {
                if (val)
                    this.data.site_only = true
            },
            immediate: true
        },
    },
    methods: {
        formError(field: string): boolean {
            return includes(this.errors, field)
        },
        focusFirstInput() {
            const firstInput = this.$el.querySelector('input')
            if(firstInput) firstInput.focus()
        },
        hideModal() {
            this.$bvModal.hide(this.htmlIDs.createModal)
        },
        resetForm() {
            this.data = emptyData()
            this.errors = []
            this.confirmMultiple = null
        },
        setConfirmMultiple(val: boolean) {
            console.log('setting confirmMultiple to', val)
            this.confirmMultiple = val
        },
        checkForm() {
            this.errors = []

            const { mrn, approx_age } = this.data.patient

            if (approx_age) {
                if (!inRange(parseInt(approx_age || '0'), 200))
                    this.errors.push('approx_age')
            }

            if (!mrn)
                this.errors.push('mrn')

            if (!this.data.site_only && !this.data.doctor)
                this.errors.push('doctor')

            if (this.data.site_only && !this.data.hospital)
                this.errors.push('hospital')

            return utils.request.get(`/stay/actives/?mrn=${mrn}`)
            .then(res => {
                if (res.body.count > 0) {
                    const promise = new Promise((resolve, reject) => {
                        const checker = window.setInterval(() => {
                            if (this.confirmMultiple === null)
                                return
                            window.clearInterval(checker)
                            if (this.confirmMultiple)
                                resolve()
                            else
                                reject()
                        })
                    })
                    this.$bvModal.show(this.htmlIDs.multipleModal)
                    return promise
                }
                else
                    return Promise.resolve()
            })
        },
        createStay(event: Event) {
            event.preventDefault()
            if (this.submitting) return

            this.checkForm()
            .then(res => {
                if (this.errors.length !== 0)
                    return Promise.reject()
                this.submitting = true
                return utils.request.post('/stay/create/', false, this.data)
            })
            .then(res => {
                if (res.status === 201) {
                    stays.mutTriggerLoadStays()
                    this.hideModal()
                    this.resetForm()
                }
                else
                    Promise.reject(res)
            })
            .catch(err => {
                if (err)
                    utils.handleRequestError(err)
            })
            .then(res => {
                this.submitting = false
                this.confirmMultiple = null
            })
        }
    }
})

























import Vue from 'vue'

import { get } from 'lodash-es'

import stays from '@store/stays'

import Checkbox from '@shared/inputs/Checkbox.vue'
import TextArea from '@shared/inputs/TextArea.vue'
import { PropChoice } from 'models/schema'
import { Stay } from 'models/data/stay'


export default Vue.extend({
    components: {
        Checkbox,
        TextArea
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        checklistChoices(): PropChoice[] {
            const checklistSchema = get(
                this.$store.direct.state.stays_v2.fullSchema,
                'ward_round.children.discussion_checklist.child',
                {}
            )
            return get(checklistSchema, 'choices', [])
        },
        discussed: {
            get(): string[] {
                return get(this.stay, 'ward_round.discussion_checklist') || []
            },
            set(choices: string[]) {
                stays.extActSetPathValue(this.stay_id, 'ward_round.discussion_checklist', choices)
            }
        },
        notes: {
            get(): string {
                return get(this.stay, 'ward_round.patient_doctor_notes') || ''
            },
            set(notes: string) {
                stays.extActSetPathValue(this.stay_id, 'ward_round.patient_doctor_notes', notes)
            }
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
    }
})

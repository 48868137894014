


















import Vue from 'vue'
import utils from 'utils'
import stays from '@store/stays'
import { replaceBreaks } from 'utils/text/helpers'
import TextArea from '@shared/inputs/TextArea.vue'


export default Vue.extend({
    components: {
        TextArea,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            htmlId: `${utils.getUID()}___adm_summary`,
        }
    },
    computed: {
        stay() {
            return stays.state.stays[this.stay_id]
        },
        admissionSummary: {
            get(): string | null {
                if (!this.stay) return null
                return this.stay.admission_summary || ''
            },
            set(summary: string) {
                stays.extActSetPathValue(this.stay_id, 'admission_summary', summary)
            }
        },
        placeholderText(): string | undefined {
            const speciality = stays.getSpeciality(this.stay_id)
            if (!speciality) return
            return speciality.admission_summary_placeholder || undefined
        }
    },
    methods: {
        replaceBreaks,
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.telestrokeMode
    ? _c("div", [
        _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col" }, [
            !_vm.isTempUser
              ? _c(
                  "div",
                  { staticClass: "d-flex flex-row align-items-end" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex scrawl-grouped" },
                      [
                        _c("hospital-filter", { on: { update: _vm.pull } }),
                        _vm._v(" "),
                        _c("filter-doctors", { on: { update: _vm.pull } }),
                        _vm._v(" "),
                        _vm.isTSAdvUser && !_vm.isTSSiteUser
                          ? _c("dropdown", {
                              staticClass: "mb-0",
                              attrs: {
                                label: "Scope",
                                items: _vm.TSScope_items,
                                valueField: "value"
                              },
                              model: {
                                value: _vm.tsScope_local,
                                callback: function($$v) {
                                  _vm.tsScope_local = $$v
                                },
                                expression: "tsScope_local"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "show-discharged",
                      _vm._b(
                        {
                          staticClass: "d-flex flex-row align-items-end ml-3",
                          on: { update: _vm.pull }
                        },
                        "show-discharged",
                        _vm.discharge,
                        false,
                        true
                      )
                    ),
                    _vm._v(" "),
                    _c("print", {
                      staticClass: "ml-2",
                      attrs: {
                        stay_ids: _vm.orderedStaysIDs,
                        label: "Print",
                        showLabel: true
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-end ml-auto" },
                      [
                        _c("search-stays"),
                        _vm._v(" "),
                        _vm.isTSAdvUser
                          ? _c("create-stay-ts", { staticClass: "ml-2" })
                          : _c("create-stay", { staticClass: "ml-2" })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("stays", {
                attrs: {
                  showSite: _vm.telestrokeMode,
                  showLocation: !_vm.telestrokeMode,
                  tsScope: _vm.tsScope
                },
                on: {
                  "visible-stays": function($event) {
                    _vm.normalStays = $event
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [_c("create-stay", { staticClass: "float-right mt-2" })],
            1
          )
        ]),
        _vm._v(" "),
        _vm.consultsEnabled
          ? _c("div", { staticClass: "row mb-3" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("h3", [_vm._v("Consultations")]),
                  _vm._v(" "),
                  _c("stays", {
                    attrs: { stayType: "secondary" },
                    on: {
                      "visible-stays": function($event) {
                        _vm.consultStays = $event
                      }
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    : _c(
        "div",
        [
          _c("div", { staticClass: "row mt-2" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-row align-items-end" },
                [
                  _c("stay-filters", { on: { update: _vm.pull } }),
                  _vm._v(" "),
                  _vm.onlyOneSpeciality
                    ? [
                        _c(
                          "show-discharged",
                          _vm._b(
                            {
                              staticClass:
                                "d-flex flex-row align-items-end ml-3",
                              on: { update: _vm.pull }
                            },
                            "show-discharged",
                            _vm.discharge,
                            false,
                            true
                          )
                        ),
                        _vm._v(" "),
                        _vm.isPowerUser
                          ? _c(
                              "b-check",
                              {
                                staticClass:
                                  "scrawl-checkbox progress-bar-striped mb-1 mr-2",
                                attrs: {
                                  switch: "",
                                  value: "seen",
                                  "unchecked-value": "strict"
                                },
                                model: {
                                  value: _vm.mode,
                                  callback: function($$v) {
                                    _vm.mode = $$v
                                  },
                                  expression: "mode"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.mode) +
                                    "\n                    "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.feedOn
                          ? [
                              _vm.betaEnabled
                                ? _c(
                                    "b-dd",
                                    {
                                      attrs: {
                                        split: "",
                                        "split-to": {
                                          name: "paddock-search-v3"
                                        },
                                        text: "Find v3"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-dd-item",
                                        {
                                          attrs: {
                                            to: { name: "paddock-search-v2" }
                                          }
                                        },
                                        [_vm._v("Find v2")]
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "b-dd",
                                    {
                                      attrs: {
                                        split: "",
                                        "split-to": {
                                          name: "paddock-search-v2"
                                        },
                                        text: "Find"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-dd-item",
                                        {
                                          attrs: {
                                            to: { name: "paddock-search-v3" }
                                          }
                                        },
                                        [_vm._v("Find v3")]
                                      )
                                    ],
                                    1
                                  )
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("print", {
                          staticClass: "ml-2",
                          attrs: {
                            stay_ids: _vm.orderedStaysIDs,
                            label: "Print",
                            showLabel: true
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-end ml-auto" },
                          [_c("create-stay", { staticClass: "ml-2" })],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.onlyOneSpeciality
            ? [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("stays", {
                        attrs: {
                          showSite: _vm.telestrokeMode,
                          showLocation: !_vm.telestrokeMode
                        },
                        on: {
                          "visible-stays": function($event) {
                            _vm.normalStays = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [_c("create-stay", { staticClass: "float-right mt-2" })],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.consultsEnabled
                  ? _c("div", { staticClass: "row mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("h3", [_vm._v("Consultations")]),
                          _vm._v(" "),
                          _c("stays", {
                            attrs: { stayType: "secondary" },
                            on: {
                              "visible-stays": function($event) {
                                _vm.consultStays = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center h-100 mt-5"
                },
                [_vm._m(0)]
              )
        ],
        2
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("em", [_vm._v("Please select a speciality to use this page.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }


































import Vue from 'vue'
import utils from 'utils'
import * as commonmark from "commonmark"

export default Vue.extend({
    data() {
        return {
            username: "",
            password: ""
        }
    },
    computed: {
        cmReader() {
            return new commonmark.Parser()
        },
        cmWriter() {
            return new commonmark.HtmlRenderer()
        },
        loggedIn() {
            return this.$store.direct.getters.session.loggedIn
        },
        customMsg(): string {
            return this.cmWriter.render(this.cmReader.parse(this.$store.direct.state.session.ux_login_pg_msg))
        },
        brandImgURL(): string {
            const hasBranding = !!window.scrawl.customBrandImg && window.scrawl.customBrandImg !== 'SCRAWL_BRAND_IMG'
            return hasBranding ? `static/images/${window.scrawl.customBrandImg}` : ''
        },
    },
    watch: {
        loggedIn: {
            handler: function(loggedIn) {
                if (!loggedIn) {
                    this.$store.direct.commit.user.resetProfile()
                    this.$store.direct.commit.session.clearSyncErrorVars()
                }
            },
            immediate: true
        }
    },
    created() {
        utils.request.get('/login_msg/')
        .then(res => {
            this.$store.direct.commit.session.setLoginPageMsg(res.body.ux_login_pg_msg)
        })
    },
    methods: {
        login() {
            this.$store.direct.dispatch.session.login({ username: this.username, password: this.password })
            .then(res => {
                this.password = ""
            })
        }
    },
})







import Vue from 'vue'
export default Vue.extend({
    props: {
        opened: {
            type: Boolean,
            default: false
        },
    },
})

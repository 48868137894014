




import Vue from 'vue'
import { Chart } from 'chart.js'

export default Vue.extend({
    created() {
        Chart.defaults.global.defaultFontColor = 'white'
        Chart.defaults.global.defaultFontSize = 14
    }
})

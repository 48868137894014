


















import Vue from 'vue'
import { get } from 'lodash-es'

import stays from "@store/stays"

import { QuestionCategory } from 'models/med_templates/question'
import { Syndrome } from 'models/med_templates/syndrome'

import Questions from '@stayinputs/Questions.vue'
import TextArea from "@shared/inputs/TextArea.vue"


export default Vue.extend({
    components: {
        Questions,
        TextArea,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            QuestionCategory,
        }
    },
    computed: {
        syndrome(): Syndrome | undefined {
            return stays.getSyndrome(this.stay_id)
        },
        notes: {
            get(): string {
                const stay = stays.state.stays[this.stay_id]
                return get(stay, 'background.notes') || ''
            },
            set(notes: string) {
                stays.extActSetPathValue(this.stay_id, 'background.notes', notes)
            }
        }
    },
})

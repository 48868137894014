






















import Vue from "vue"
import { cloneDeep, get } from "lodash-es"

import utils from 'utils'
import stays from "@store/stays"

import { PropType } from "models/schema"
import { Stay } from 'models/data/stay'

export default Vue.extend({
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        section: {
            type: String,
            required: true
        },
        field_name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            uid: utils.getUID(),
            field: {
                uid: utils.getUID(),
                path: `${this.section}.${this.field_name}`,
                name: this.field_name,
                type: PropType.MULTI,
                required: false,
                read_only: false,
                label: ""
            },
            sides: [
                {
                    label: 'Right',
                    halves: [[0,1],[2,3]]
                },
                {
                    label: 'Left',
                    halves: [[4,5],[6,7]]
                }
            ]
        }
    },
    computed: {
        stay(): Stay {
            return stays.state.stays[this.stay_id]
        },
        debugMode() {
            return this.$store.direct.state.user.debug_mode
        },
        debugLabelText(): string {
            return `${this.field.path}: ${this.fieldEye}`
        },
        fieldEye(): number[] {
            return get(this.stay, this.field.path)
        }
    },
    methods: {
        quadrantClass(index: number): string {
            const mapping: { [k: number]: string } = {
                0: "upper-left",
                1: "upper-right",
                2: "lower-left",
                3: "lower-right"
            }
            return mapping[index % 4]
        },
        quadrantValue(index: number) {
            const fieldVal = this.fieldEye
            if (fieldVal) {
                const val = fieldVal[index]
                return val
            }
            return
        },
        toggleQuadrant(index: number) {
            let fieldVal = cloneDeep(this.fieldEye)
            if (!fieldVal || fieldVal.length !== 8)
                fieldVal = [0, 0, 0, 0, 0, 0, 0, 0]
            fieldVal[index] = fieldVal[index] ? 0 : 1  // flip it
            stays.extActSetPathValue(this.stay_id, this.field.path, fieldVal)
        }

    }
})


var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dropdown", {
    attrs: {
      items: _vm.allSites,
      textField: "title",
      label: "Sites",
      truncateText: "",
      multi: "",
      closeOnSelect: false,
      chooseText: "[All]",
      addClearAll: "",
      clearAllText: "All"
    },
    model: {
      value: _vm.sites,
      callback: function($$v) {
        _vm.sites = $$v
      },
      expression: "sites"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }























import mixins from "vue-typed-mixins"
import { cloneDeep } from "lodash-es"
import utils from 'utils'
import InputMixin from "@mixins/Input.vue"
import StayMixin from '@mixins/Stay.vue'

export default mixins(InputMixin, StayMixin).extend({
    props: {
        nihss_id: {
            type: Number,
            required: true,
        },
        field_name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            uid: utils.getUID(),
            sides: [
                {
                    label: 'Right',
                    halves: [[0,1],[2,3]]
                },
                {
                    label: 'Left',
                    halves: [[4,5],[6,7]]
                }
            ]
        }
    },
    computed: {
        debugMode() {
            return this.$store.direct.state.user.debug_mode
        },
        debugLabelText(): string {
            return `nihss_id: ${this.nihss_id} ${this.fieldEye}`
        },
        fieldEye(): number[] {
            return this.getArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, this.field_name) || []
        }
    },
    methods: {
        quadrantClass(index: number): string {
            const mapping: { [k: number]: string } = {
                0: "upper-left",
                1: "upper-right",
                2: "lower-left",
                3: "lower-right"
            }
            return mapping[index % 4]
        },
        quadrantValue(index: number) {
            const fieldVal = this.fieldEye
            if (fieldVal) {
                const val = fieldVal[index]
                return val
            }
            return
        },
        toggleQuadrant(index: number) {
            let fieldVal = cloneDeep(this.fieldEye)
            if (!fieldVal || fieldVal.length !== 8)
                fieldVal = [0, 0, 0, 0, 0, 0, 0, 0]
            fieldVal[index] = fieldVal[index] ? 0 : 1  // flip it
            this.setArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, this.field_name, fieldVal)
        },
    }
})


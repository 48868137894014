import { boolean as dboolean, number as dnumber, string as dstring, array as darray, exact, guard } from 'decoders'
import { Item, itemDef, OrderedItem, orderedItemDef } from "../base"
import { QuestionGroup, questionGroup } from './question'

export enum IssueStatus {
    ACTIVE = "ACTIVE",
    RESOLVED = "RESOLVED"
}

export const IssueLabels = [
    {
        id: "ACTIVE",
        title: "Active"
    },
    {   id: "RESOLVED",
        title: "Resolved"
    }
]

export interface Result extends Item {}

export interface Issue extends Item {
    question_groups: QuestionGroup[]
    abf: boolean
    enabled: boolean
    help_text: string
    link: string
}

export interface IssueGroup extends OrderedItem {
    speciality: number
    issues: Issue[]
}

export type IssueGroups = IssueGroup[]

const issue = exact({
    ...itemDef,
    question_groups: darray(questionGroup),
    abf: dboolean,
    enabled: dboolean,
    help_text: dstring,
    link: dstring,
})

const issueGroup = exact({
    ...orderedItemDef,
    speciality: dnumber,
    issues: darray(issue),
})

const issueGroups = darray(issueGroup)

export const issueGroupsDecoder = guard(issueGroups, { style: 'simple' })

try { const _issue: Item = guard(issue)({}) } catch(e) {}
try { const _issueGroups: IssueGroups = issueGroupsDecoder({}) } catch(e) {}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("editor", {
        attrs: { markup: _vm.markup, stay_id: _vm.stay_id },
        on: { "text-copied": _vm.confirmComplete }
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.confirmId,
            centered: "",
            "cancel-title": "No",
            "ok-title": "Clear sections",
            "ok-variant": "success"
          },
          on: { ok: _vm.markComplete }
        },
        [
          _vm._v(
            "\n        This will clear the following section(s): " +
              _vm._s(_vm.groupTitlesToClear.join(", ")) +
              ". Proceed?\n    "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }














import Vue from 'vue'
import DateTimePicker from '@shared/inputs/DateTimePicker.vue'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'


export default Vue.extend({
    components: {
        DateTimePicker,
    },
    props: {
        label: {
            type: String,
            required: false,
            default: 'Admission time',
        },
        stay_id: {
            type: Number,
            required: true
        },
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        admissionTime: {
            get(): string | Date | null {
                const val = this.stay ? this.stay.admission_time : null
                return val
            },
            set(val: Date) {
                stays.actSetPathValue({ id: this.stay_id, path: 'admission_time', val })
            }
        }
    },
})















































































































































import Vue from 'vue'
import { find, flatMap, includes, map } from 'lodash-es'
import utils from 'utils'
import stays from '@store/stays'
import { Item } from 'models/base'
import { Stay } from 'models/data/stay'
import { Issue as IssueModel, OtherIssue } from 'models/data/issue'
import { NeuroSummary, AcuteCareSummary } from 'models/data/neurology'
import { NIHSS, NIHSS_ADM_DISPLAY, NIHSS_24_72_HRS_TITLE, NIHSS_24_72_HRS_DISPLAY_SHORT } from 'models/data/nihss'
import { DischargeMeds } from 'models/data/stay'
import { Question as Question_i } from 'models/med_templates/question'

import AdmissionTime from '@stayinputs/AdmissionTime.vue'
import EditModal from '../EditModal.vue'
import CurrentMedications from '@ward/CurrentMedications.vue'
import Issue from '@sections/issues/Issue.vue'
import LastSeenWell from '@admission/LastSeenWell.vue'
import NeuroAcuteCareSummary from 'components/data-entry/views/NeuroAcuteCareSummaryModal.vue'
import NihssSelect from '@sections/nihss/v2/NIHSSSelect.vue'
import Question from '@stayinputs/Question.vue'

export default Vue.extend({
    components: {
        AdmissionTime,
        EditModal,
        CurrentMedications,
        Issue,
        LastSeenWell,
        NeuroAcuteCareSummary,
        NihssSelect,
        Question,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            presMrsID: `${uid}___pres_mrs`,
            admNihssID: `${uid}___adm_nihss`,
            nihss24To72HrsID: `${uid}___24_72hr_nihss`,
            strokeMechID: `${uid}___s_mech`,
            nihssModalID: `${uid}___nihss_popup`,
            NIHSS_ADM_DISPLAY,
            NIHSS_24_72_HRS_DISPLAY_SHORT,
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        hasStrokeDiagnosis(): boolean {
            return includes(this.$store.direct.state.templates.strokeDiagnoses, this.stay?.diagnosis_title)
        },
        indicatorsOn(): boolean {
            return this.hasStrokeDiagnosis
        },
        summary(): NeuroSummary | null | undefined {
            return this.stay?.neuro_summary
        },
        acuteSummary(): AcuteCareSummary | null | undefined {
            return this.stay?.acute_care_summary
        },
        presMrsQ(): Question_i | undefined {
            const sp = this.$store.direct.getters.templates.speciality(this.stay?.speciality)
            const questions = flatMap(sp?.syndromes || [], syn => flatMap(syn.question_groups, grp => map(grp.questions, oq => oq.question)))
            return find(questions, ['label', 'nel_soc_hist_modified_rankin'])
        },
        admNIHSS(): NIHSS | undefined {
            return this.stay?.admission_nihss
        },
        nihssLabels(): Item[] {
            return this.$store.direct.getters.templates.speciality(this.stay?.speciality)?.nihss_labels || []
        },
        nihss24To72Hrs(): NIHSS | undefined {
            const label = find(this.nihssLabels, { title: NIHSS_24_72_HRS_TITLE })
            if (!label) return undefined
            const nihss = find(this.stay?.nihss_set || [], nihss => nihss.label === label.id)
            return nihss
        },
        hasNihss24To72Hrs(): boolean {
            return !!this.nihss24To72Hrs
        },
        dischargeMeds(): DischargeMeds | null | undefined {
            return this.stay?.discharge_medications
        },
        dischargeMedsSummaryHTML(): string {
            if (this.dischargeMeds)
                return this.dischargeMeds.title.replace(/\n/g, '<br>')
            return ''
        },
        strokeMechanism(): IssueModel | OtherIssue | undefined {
            const issues = stays.getAllIssues(this.stay_id)
            return find(issues, ['title', 'Stroke mechanism'])
        },
        summaryOK(): boolean {
            if (!this.summary) return false
            const ok = (
                this.summary.modified_rankin !== null &&
                this.summary.admission_time !== null &&
                !!this.nihss24To72Hrs?.complete &&
                (this.dischargeMeds?.correct === null || !!this.dischargeMeds?.correct)
            )
            return ok
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay
    ? _c("edit-modal", {
        attrs: { stay_id: _vm.stay.id, size: _vm.size },
        scopedSlots: _vm._u(
          [
            {
              key: "text",
              fn: function() {
                return _vm._l(_vm.issuesList, function(issue, index) {
                  return _c("p", { key: index, staticClass: "mb-0" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(index + 1) +
                        ". " +
                        _vm._s(issue) +
                        "\n        "
                    )
                  ])
                })
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [
                  _c("issues-overview", { attrs: { stay_id: _vm.stay_id } })
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          3411050856
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
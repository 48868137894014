var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-alert",
    {
      staticClass: "d-flex justify-content-center",
      attrs: { show: _vm.outOfDate, variant: "danger" }
    },
    [
      _c("p", { staticClass: "mb-0" }, [
        _vm._v("Please refresh page to load the latest version of Scrawl.")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }




































































import mixins from 'vue-typed-mixins'
import { array, string as dstring, number as dnumber, inexact, guard } from 'decoders'
import { cloneDeep, flatten, isInteger, isNil, map, merge, round, sum } from 'lodash-es'
import { safeDecodingStrict } from 'utils/decoders'
import store from 'store'
import { ChartDataSets, ChartOptions } from 'chart.js'
import UpdateChartMixin from './UpdateChartMixin.vue'

interface Data {
    months: string[]
    ischaemic_stroke: number[]
    ich: number[]
    other: number[]
    lysed_only: number[]
    ecr_only: number[]
    lysed_and_ecr: number[]
}

const dataDef = inexact({
    months: array(dstring),
    ischaemic_stroke: array(dnumber),
    ich: array(dnumber),
    other: array(dnumber),
    lysed_only: array(dnumber),
    ecr_only: array(dnumber),
    lysed_and_ecr: array(dnumber),
})

const dataDecoder = guard(dataDef, { style: 'simple' })

export default mixins(UpdateChartMixin).extend({
    props: {
        data: {
            type: Object as () => Data,
            required: true,
            validator: function(val: Data): boolean {
                if (store.direct.state.user.typeCheckJSON) {
                    const res = safeDecodingStrict(val, dataDecoder, 'StrokeTotals data validator')
                    return res !== undefined
                }
                return true
            }
        },
    },
    data() {
        const barStyling = {
            minBarLength: 2,
        }

        const yTicks = {
            beginAtZero: true,
            callback: function(value: number) {
                if (isInteger(value)) { return value }
                return ''
            },
            stepSize: 1,
            min: 0,
        }

        const yAxes = [
            {
                scaleLabel: {
                    display: true,
                    fontColor: '#ccc',
                    labelString: 'Stays',
                },
                ticks: yTicks,
            },
        ]

        const options: ChartOptions = {
            legend: {
                display: true,
                position: 'bottom',
            },
            scales: {
                yAxes,
            },
        }

        return {
            barStyling,
            options,
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        totalCases(): number {
            if (!this.data) return 0
            if (this.telestrokeMode) {
                return (
                    sum(this.data.ischaemic_stroke) +
                    sum(this.data.ich) +
                    sum(this.data.other)
                )
            } else {
                // Don't include "Other" in total for non-telestroke
                // because it's not shown in the graph
                return (
                    sum(this.data.ischaemic_stroke) +
                    sum(this.data.ich)
                )
            }
        },
        ichTotal(): number{
            if (!this.data) return 0
            return sum(this.data.ich)
        },
        ischaemicStrokeTotal(): number{
            return sum(this.data.ischaemic_stroke)
        },
        lysedTotal(): number{
            return sum(this.data.lysed_only) + sum(this.data.lysed_and_ecr)
        },
        ecrTotal(): number{
            return sum(this.data.ecr_only) + sum(this.data.lysed_and_ecr)
        },
        reperfusionTotal(): number {
            return (
                sum(this.data.lysed_only) +
                sum(this.data.ecr_only) +
                sum(this.data.lysed_and_ecr)
            )
        },
        datasets(): ChartDataSets[] {
            let datasets = [
                {
                    label: 'Ischaemic strokes',
                    data: this.data.ischaemic_stroke,
                    borderColor: '#4472c4',
                    backgroundColor: '#4472c4',
                    pointBorderWidth: 0,
                    ...this.barStyling,
                },
                {
                    label: 'Intracerebral haemorrhage',
                    data: this.data.ich,
                    borderColor: '#ed7d31',
                    backgroundColor: '#ed7d31',
                    ...this.barStyling,
                },
            ]

            // Only show "Other" diagnoses in telestroke mode
            if (this.telestrokeMode) {
                datasets.push({
                    label: 'Other',
                    data: this.data.other,
                    borderColor: '#a5a5a5',
                    backgroundColor: '#a5a5a5',
                    ...this.barStyling,
                })
            }

            return datasets
        },
    },
    watch: {
        datasets: {
            handler: function() {
                this.$nextTick(() => { this.renderCharts() })
            },
            immediate: true
        },
    },
    methods: {
        percentage(num1: number, num2: number): string {
            if (isNil(num2)) return '-'
            if (isNil(num1)) return '0%'
            if (num1 === 0 && num2 === 0) return '-'
            const rawPercentage = (num1*100)/num2
            return `${round(rawPercentage, 1)}%`
        },
        renderCharts(): void {
            const datasetArrays: number[][] = map(this.datasets, 'data') as unknown as number[][]
            const strokeStepMax = this.calcChartStepMax(flatten(datasetArrays))
            this.updateChart({
                title: 'strokeChart',
                chartType: 'bar',
                data: {
                    labels: this.data.months,
                    datasets: this.datasets,
                },
                options: merge(cloneDeep(this.options), { scales: { yAxes: [{ ticks: { stepSize: strokeStepMax.stepSize, max: strokeStepMax.yMax }}]}}),
            })

            // Update reperfusion chart
            const flattenedReperfusion = flatten([this.data.lysed_only, this.data.ecr_only, this.data.lysed_and_ecr])
            const reperfusionStepMax = this.calcChartStepMax(flattenedReperfusion)
            this.updateChart({
                title: 'reperfusionChart',
                chartType: 'bar',
                data: {
                    labels: this.data.months,
                    datasets: [
                        {
                            label: 'Lysed only',
                            data: this.data.lysed_only,
                            borderColor: '#ed7d31',
                            backgroundColor: '#ed7d31',
                            ...this.barStyling,
                        },
                        {
                            label: 'ECR only',
                            data: this.data.ecr_only,
                            borderColor: '#ffc000',
                            backgroundColor: '#ffc000',
                            ...this.barStyling,
                        },
                        {
                            label: 'Lysed + ECR',
                            data: this.data.lysed_and_ecr,
                            borderColor: '#a5a5a5',
                            backgroundColor: '#a5a5a5',
                            ...this.barStyling,
                        },
                    ],
                },
                options: merge(cloneDeep(this.options), { scales: { yAxes: [{ ticks: { stepSize: reperfusionStepMax.stepSize, max: reperfusionStepMax.yMax }}]}}),
            })
        }
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("questions", {
        attrs: {
          stay_id: _vm.stay_id,
          category: _vm.QuestionCategory.BACKGROUND,
          parentObject: _vm.syndrome
        }
      }),
      _vm._v(" "),
      _c("text-area", {
        staticClass: "mt-3",
        attrs: { "non-unique-id": "background-notes", label: "", rows: "5" },
        model: {
          value: _vm.notes,
          callback: function($$v) {
            _vm.notes = $$v
          },
          expression: "notes"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }










































import Vue from 'vue'
import { isEmpty, sortBy } from 'lodash-es'
import * as jsonstringify from 'json-stringify-pretty-compact'
import * as moment from 'moment'
import utils from 'utils'
import { AuditTrail, HistoryRecord } from 'models/data/history'
import Checkbox from '@shared/inputs/Checkbox.vue'


export default Vue.extend({
    components: {
        Checkbox,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            auditTrail: {
                stay: '?',
                history: []
            } as AuditTrail,
            hideEmpty: true,
            reverse: true,
        }
    },
    watch: {
        reverse(val: boolean) {
            this.auditTrail.history.reverse()
        },
        stay_id: {
            handler(val: number) {
                this.pull()
            },
            immediate: true
        }
    },
    methods: {
        pull() {
            utils.request.get(`/stay/audit_trail/${this.stay_id}/`)
            .then(res => {
                const data: AuditTrail = res.body
                data.history = sortBy(data.history, h => moment(h.history_date))
                this.auditTrail = data
                if (this.reverse)
                    this.auditTrail.history.reverse()
            })
            .catch(utils.handleRequestError)
        },
        niceDate(isoDate: string): string {
            return utils.niceDate(isoDate)
        },
        historyMeta(record: HistoryRecord) {
            return {
                model: record.model,
                id: record.id,
                history_id: record.history_id
            }
        },
        recordVisible(record: HistoryRecord): boolean {
            if (this.hideEmpty)
                return record.changes !== null && !isEmpty(record.changes)
            return true
        },
        prettyPrint(obj: any, maxLength?: number): string {
            return jsonstringify(obj, { maxLength })
        }
    }
})











































import mixins from "vue-typed-mixins"
import { find } from "lodash-es"

import utils from 'utils'
import stays from "@store/stays"

import { PropExtInfo } from 'models'
import { Stay } from 'models/data/stay'
import { NestedProp, PropType } from "models/schema"
import { Diagnosis } from "models/med_templates/diagnosis"

import DisplayMixin from "../mixins/Display.vue"

import AdmitMgmtSummary from '@inputs/diagnosis/AdmMgmtSummary.vue'
import DiagnosisFeatures from '@inputs/diagnosis/DiagnosisFeatures.vue'
import DiagnosisInput from '@inputs/diagnosis/Diagnosis.vue'
import RInput from '@inputs/Input.vue'
import ManagementPlan from '@inputs/diagnosis/ManagementPlan.vue'

import IssuesOverview from './issues/IssuesOverview.vue'
import StandardInvestigationsList from '../sections/investigations/StandardInvestigationsList.vue'
import Thrombolysis from '@sections/Thrombolysis.vue'

export default mixins(DisplayMixin).extend({
    components: {
        AdmitMgmtSummary,
        DiagnosisFeatures,
        DiagnosisInput,
        IssuesOverview,
        ManagementPlan,
        RInput,
        StandardInvestigationsList,
        Thrombolysis,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            htmlID: `${utils.getUID()}__management-discharge-${this.stay_id}`,
            summaryPlaceholderText: 'Summary of major symptoms and signs on presentation\nResults of key investigations\nBig picture comment on pre-admission level of function and goals of current care'
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        showAllIssues(): boolean {
            return this.telestrokeMode ? this.$store.direct.state.session.ux_ts_mgmt_issues : true
        },
        triageSchema(): NestedProp | undefined {
            return this.getSectionSchema("triage")
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        diagnosis(): Diagnosis | undefined {
            return find(this.$store.direct.getters.templates.allDiagnoses, ['id', this.stay ? this.stay.diagnosis : -1])
        },
        issueTextPropExtInfo(): PropExtInfo {
            return {
                field: 'other_issues_text',
                label: 'Other issues',
                name: 'other_issues_text',
                path: 'other_issues_text',
                required: false,
                read_only: false,
                type: PropType.TEXT,
                uid: utils.getUID(),
            }
        },
        isThrombolysis(): boolean {
            return stays.isThrombolysis(this.stay_id)
        },
    },
})








import Vue from 'vue'
import { forEach } from 'lodash-es'

export default Vue.extend({
    computed: {
        options() {
            const choices: {value: number|null, text: string}[] = [
                { value: null, text: 'Choose a speciality...' }
            ]
            forEach(this.$store.direct.state.templates.specialities, (speciality) => {
                choices.push({value: speciality.id, text: speciality.title})
            })
            return choices
        },
        userSpeciality: {
            get(): number | null {
                return this.$store.direct.state.user.speciality
            },
            set(val: number) {
                this.$store.direct.dispatch.user.updatePush({speciality: val})
            }
        }
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.debugMode
    ? _c(
        "b-nav-item-dropdown",
        { attrs: { text: "Info", right: "" } },
        [
          _c("b-dd-item", [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Property")]),
                  _vm._v(" "),
                  _c("th", { attrs: { scope: "col" } }, [_vm._v("Value")])
                ])
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("changesMaxAge")]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number.lazy",
                          value: _vm.changesMaxAge,
                          expression: "changesMaxAge",
                          modifiers: { number: true, lazy: true }
                        }
                      ],
                      staticClass: "py-1 px-3 inline-input",
                      attrs: { type: "number" },
                      domProps: { value: _vm.changesMaxAge },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        },
                        change: function($event) {
                          _vm.changesMaxAge = _vm._n($event.target.value)
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v("\n                        ms\n                    ")
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("syncMonitorInterval")]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number.lazy",
                          value: _vm.syncMonitorInterval,
                          expression: "syncMonitorInterval",
                          modifiers: { number: true, lazy: true }
                        }
                      ],
                      staticClass: "py-1 px-3 inline-input",
                      attrs: { type: "number" },
                      domProps: { value: _vm.syncMonitorInterval },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        },
                        change: function($event) {
                          _vm.syncMonitorInterval = _vm._n($event.target.value)
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v("\n                        ms\n                    ")
                  ])
                ])
              ])
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { sample } from 'lodash-es'
import { string as dstring, number as dnumber, nullable, constant, either3, inexact } from 'decoders'

export interface Patient {
    mrn: string
    first_name: string | null
    last_name: string | null
    gender: "UNKNOWN" | "FEMALE" | "MALE"
    /** an ISO-formatted date string */
    dob: string | null
    age: number
    approx_age: number | null
}

export const patientDef = inexact({
    mrn: dstring,
    first_name: nullable(dstring),
    last_name: nullable(dstring),
    gender: either3(
        constant('UNKNOWN' as 'UNKNOWN'),
        constant('FEMALE' as 'FEMALE'),
        constant('MALE' as 'MALE'),
    ),
    dob: nullable(dstring),
    age: dnumber,
    approx_age: nullable(dnumber),
})

export function isPatient(obj: number | Patient | undefined): obj is Patient {
    if (obj === undefined) return false
    return (<Patient>obj).mrn !== undefined
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz'
const alphabetU = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export function anonymisedPatient(p: Patient | null): Patient {
    return {
        mrn: `${Math.floor(Math.random() * 1000000)}x`,
        first_name: sample(alphabet) || null,
        last_name: sample(alphabetU) || null,
        gender: "UNKNOWN",
        dob: null,
        age: 999,
        approx_age: 999,
    }
}

export function dummyPatient(): Patient {
    return {
        mrn: "?",
        first_name: "?",
        last_name: "?",
        gender: "UNKNOWN",
        dob: null,
        age: 999,
        approx_age: 999,
    }
}

import {
    string as dstring, number as dnumber, iso8601,
    nullable, exact, guard,
} from 'decoders'


export interface AcuteCareSummary {
    name: string
    age: number | null

    adm_mgmt_notes: string | null
    asap_documented: string | null
    admission_time: Date | null
    clinic_follow_up_notes: string | null
    consult_time: Date | null
    contact_before_ct: string | null
    diagnosis: string | null
    doctors_involved: string | null
    door_to_alert: string
    door_to_ct: string
    door_to_ecr_decision: string
    door_to_lysis_decision: string
    door_to_needle: string
    follow_up_nihss: string | null
    follow_up_diagnosis_notes: string | null
    location: string | null
    lsw_time: Date | null
    lsw_to_consult: string | null
    modified_rankin: string | null
    possible_isch_stroke: string | null
    present_type: string | null
    process_comments_doctor: string | null
    process_comments_site: string | null
    raw_alert: string | null
    raw_ct: string | null
    raw_ecr_decision: string | null
    raw_lysis_decision: string | null
    raw_needle_time: string | null
    reason_not_thromb: string | null
    reason_not_ecr: string | null
    summary_not_lysed: string | null
}

export interface NeuroSummary {
    modified_rankin: string | null
    lsw_time: string | null
    admission_time: string | null
    onset_to_door: string | null   
}

export interface NeurologyFields {
    seventy_two_hour_nihss_score: number | null
    nbm: string | null
    next_out_of_stroke_unit: number | null
}

export interface StrokeMeds {
    correct: boolean | null
    title: string
}

export const acuteCareSummary = exact({
    name: dstring,
    age: nullable(dnumber),

    adm_mgmt_notes: nullable(dstring),
    asap_documented: nullable(dstring),
    admission_time: nullable(iso8601),
    clinic_follow_up_notes: nullable(dstring),
    consult_time: nullable(iso8601),
    contact_before_ct: nullable(dstring),
    diagnosis: nullable(dstring),
    doctors_involved: nullable(dstring),
    door_to_alert: dstring,
    door_to_ct: dstring,
    door_to_ecr_decision: dstring,
    door_to_lysis_decision: dstring,
    door_to_needle: dstring,
    follow_up_nihss: nullable(dstring),
    follow_up_diagnosis_notes: nullable(dstring),
    location: nullable(dstring),
    lsw_time: nullable(iso8601),
    lsw_to_consult: nullable(dstring),
    modified_rankin: nullable(dstring),
    possible_isch_stroke: nullable(dstring),
    present_type: nullable(dstring),
    process_comments_doctor: nullable(dstring),
    process_comments_site: nullable(dstring),
    raw_alert: nullable(dstring),
    raw_ct: nullable(dstring),
    raw_ecr_decision: nullable(dstring),
    raw_lysis_decision: nullable(dstring),
    raw_needle_time: nullable(dstring),
    reason_not_thromb: nullable(dstring),
    reason_not_ecr: nullable(dstring),
    summary_not_lysed: nullable(dstring),
})

export const neuroSummary = exact({
    modified_rankin: nullable(dstring),
    lsw_time: nullable(dstring),
    admission_time: nullable(dstring),
    onset_to_door: nullable(dstring),
})

export const neurologyFields = exact({
    seventy_two_hour_nihss_score: nullable(dnumber),
    nbm: nullable(dstring),
    next_out_of_stroke_unit: nullable(dnumber),
})

// sanity checks that decoders/guards are not missing properties
try { const _acuteCareSummary: AcuteCareSummary = guard(acuteCareSummary)({}) } catch(e) {}
try { const _neuroSummary: NeuroSummary = guard(neuroSummary)({}) } catch(e) {}
try { const _neurologyFields: NeurologyFields = guard(neurologyFields)({}) } catch(e) {}

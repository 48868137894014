






































































import Vue from 'vue'
import { get } from 'lodash-es'
import utils from 'utils'
import { HyperacuteStay } from 'models/dataviz'
import { anonymisedPatient } from 'models/data/patient'

import StayTextSummary from './StayTextSummary.vue'


export default Vue.extend({
    components: {
        StayTextSummary,
    },
    props: {
        stays: {
           type: Array, 
        },
        field: {
            type: String
        },
        title: {
            type: String
        }
    },
    data() {
        return {
            uid: utils.getUID(),
            showModal: false
        }
    },
    computed: {
        anonymise(): boolean {
            return this.$store.direct.state.user.anonymise
        },
    },
    methods: {
        fieldValue(stay: HyperacuteStay) {
            const value = get(stay, this.field)

            if (value === null) return '?'
            if (value === true) return 'Yes'
            if (value === false) return 'No'
            return value
        },
        patientSummary(stay: HyperacuteStay) {
            let patient = stay.patient
            if (this.anonymise) {
                patient = anonymisedPatient(null)
            }
            return `${patient.first_name} ${patient.last_name} (MRN: ${patient.mrn})`
        },
        summaryId(stay_id: number): string {
            return `${this.uid}___summary_${stay_id}`
        },
    },
})

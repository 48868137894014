

















import Vue from 'vue'
import { filter } from 'lodash-es'
import stays from '@store/stays'
import { SurgeryStage } from 'models/_enums'
import { Stay } from 'models/data/stay'
import { FullSchema } from 'models/schema/stay'
import DtPickerEnhanced from '@shared/inputs/DTPickerEnhanced.vue'

export default Vue.extend({
    components: {
        DtPickerEnhanced,
    },
    props: {
        stay_id: {
            type: Number,
            required: true,
        },
    },
    computed: {
        fullSchema(): FullSchema | null {
            return this.$store.direct.state.stays_v2.fullSchema
        },
        allStages(): any {
            if (!this.fullSchema) return []
            return filter(this.fullSchema.surgery_stage.choices, choice => choice.value !== 'UNKNOWN')
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        surgeryTime: {
            get(): string | null {
                return this.stay ? this.stay.surgery_time : null
            },
            set(val: string | null) {
                if (this.stay?.surgery_time !== val)
                    stays.actSetPathValue({ id: this.stay_id, path: 'surgery_time', val })
            }
        },
        surgeryStage: {
            get():SurgeryStage | undefined {
                return this.stay?.surgery_stage
            },
            set(val: SurgeryStage) {
                if (this.stay?.surgery_stage !== val)
                    stays.actSetPathValue({ id: this.stay_id, path: 'surgery_stage', val })
            }
        },
    },
    watch: {
        surgeryTime(val: string | null) {
            if (!val && this.surgeryStage === 'OP')
                this.surgeryStage = 'UNKNOWN'
            else
                this.surgeryStage = 'OP'
        },
    }
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPowerUser
    ? _c(
        "span",
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.uid,
                  expression: "uid"
                }
              ],
              staticClass:
                "btn btn-sm progress-bar-striped bg-primary text-white",
              class: { "mb-1": _vm.add_mb },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [_vm._v("\n        DX History\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                id: _vm.uid,
                title: "Diagnosis History",
                size: "lg",
                "ok-only": ""
              },
              on: {
                show: function($event) {
                  _vm.visible = true
                },
                hide: function($event) {
                  _vm.visible = true
                }
              }
            },
            [
              _vm.visible
                ? _c("diagnosis-history", {
                    staticClass: "mx-2",
                    attrs: { stay_id: _vm.stay_id, embedded: true }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
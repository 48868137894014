var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      false
        ? _vm._l(_vm.readyWhenChoices, function(choice) {
            return _c("checkbox", {
              key: choice.id,
              staticClass: "d-block",
              attrs: { title: choice.title, cb_value: choice.id },
              model: {
                value: _vm.readyWhen,
                callback: function($$v) {
                  _vm.readyWhen = $$v
                },
                expression: "readyWhen"
              }
            })
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.readyWhenChoices, function(choice) {
        return _c(
          "b-check",
          {
            key: _vm.updated + "_" + choice.id,
            staticClass: "scrawl-checkbox",
            attrs: { size: "lg", value: choice.id },
            model: {
              value: _vm.readyWhen,
              callback: function($$v) {
                _vm.readyWhen = $$v
              },
              expression: "readyWhen"
            }
          },
          [_vm._v("\n        " + _vm._s(choice.title) + "\n    ")]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._l(_vm.otherReadyWhen, function(step) {
            return _c(
              "div",
              {
                key: "other_ready_when_" + step.id,
                staticClass: "d-flex mb-2"
              },
              [
                _c("b-form-input", {
                  directives: [{ name: "focus", rawName: "v-focus" }],
                  staticClass: "w-50",
                  attrs: { placeholder: "[title]" },
                  on: {
                    blur: function($event) {
                      return _vm.updateOtherReadyWhen(step)
                    }
                  },
                  model: {
                    value: step.title,
                    callback: function($$v) {
                      _vm.$set(step, "title", $$v)
                    },
                    expression: "step.title"
                  }
                }),
                _vm._v(" "),
                _c(
                  "b-btn",
                  {
                    staticClass: "border-0 text-danger",
                    staticStyle: { "background-color": "initial" },
                    attrs: { variant: "outline-danger" },
                    on: {
                      click: function($event) {
                        return _vm.deleteOtherReadyWhen(step)
                      }
                    }
                  },
                  [_vm._v("\n                ×\n            ")]
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          !_vm.otherReadyWhen.length
            ? _c(
                "b-btn",
                { attrs: { size: "sm" }, on: { click: _vm.addOtherReadyWhen } },
                [_vm._v("+ Add")]
              )
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "col-form-label" }, [
      _c("strong", [_vm._v("Discharge Once (select one)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "col-form-label" }, [
      _c("strong", [_vm._v("Other")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }

import Vue from 'vue'
import { NIHSS } from 'models/data/nihss'

export default Vue.extend({
    methods: {
        nihssCompleteIcon(nihss: NIHSS) {
            return nihss.complete ? ['fal', 'check-circle'] : ['fal', 'times-circle']
        },
        nihssCompleteColorClass(nihss: NIHSS) {
            return nihss.complete ? 'text-success' : 'text-warning'
        },
    }
})

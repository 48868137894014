var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card-text", [
        _vm.textVersion === "hyperacute"
          ? _c(
              "div",
              [
                _c("neuro-acute-care-summary", {
                  staticClass: "ml-2",
                  attrs: { stay_id: _vm.stay_id }
                })
              ],
              1
            )
          : _vm.textVersion === "strokeUnitSummary"
          ? _c("div", [
              _c(
                "table",
                { staticClass: "table" },
                _vm._l(_vm.strokeUnitSummary, function(ref) {
                  var title = ref.title
                  var value = ref.value
                  return _c("tr", [
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(title))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(value))])
                  ])
                }),
                0
              )
            ])
          : _c("div", { domProps: { innerHTML: _vm._s(_vm.dischargeText) } })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }















































import Vue from "vue"
import { filter, includes, indexOf, map } from "lodash-es"

import utils from 'utils'
import stays from "@store/stays"

import { InvSection } from "models/data/investigation"
import { InvestigationGroup as InvestigationGroupSchema } from "models/med_templates/investigation"

import Checkbox from '@shared/inputs/Checkbox.vue'

export default Vue.extend({
    components: {
        Checkbox,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            modalID: `${utils.getUID()}___inv_list`,
            addLabel: "Add Investigations",
            selected: [] as number[],
            otherLabel: '',
            otherValue: -99,
        }
    },
    methods: {
        addInvestigations() {
            if (this.selected.length) {
                Vue.toasted.info(`Adding ${this.selected.length} investigations...`)

                if (this.otherTicked) {
                    // Remove 'Other' from list
                    const index = indexOf(this.selected, this.otherValue)
                    this.selected.splice(index, 1)
                    stays.extActAddOtherInvestigation(this.stay_id, { title: this.otherLabel })
                    this.otherLabel = ''
                }

                if (this.selected.length > 0) {
                    const data = map(this.selected, function(id) {
                        return { investigation: id }
                    })
                    stays.extActAddInv(this.stay_id, InvSection.ACTIVES, data)
                    this.selected = []
                }
            }
        }
    },
    computed: {
        investigationGroups(): readonly InvestigationGroupSchema[] {
            const groups = this.$store.direct.getters.templates.invGroups
            const speciality = stays.getSpeciality(this.stay_id)
            if (speciality)
                return filter(groups, ['speciality', speciality.id])
            return groups
        },
        otherTicked(): boolean {
            return includes(this.selected, this.otherValue)
        }
    },
})

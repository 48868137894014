












































































































import Vue from 'vue'
import { includes, map } from 'lodash-es'
import * as commonmark from 'commonmark'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { background, socialHistory } from 'utils/text/clinic_history'
import { historicalMedications } from 'utils/text/meds'

import Background from '@admission/Background.vue'
import CardLapsible from '@shared/CardLapsible.vue'
import CollapseIndicator from '@shared/CollapseIndicator.vue'
import HistoricalMedications from '@admission/HistoricalMedications.vue'
import IssuesOverview from '@sections/issues/IssuesOverview.vue'
import NeuroSummary from 'components/dashboards/columns/NeuroSummary.vue'
import PatientDoctorDiscussion from './PatientDoctorDiscussion.vue'
import PrimaryIssue from './PrimaryIssue.vue'
import RapidAsmtDiagnosis from '@inputs/RapidAsmtDiagnosis.vue'
import SocialHistory from '@admission/SocialHistory.vue'
import Team from '@sections/Team.vue'
import TextAreaEnhanced from '@inputs/TextAreaEnhanced.vue'
import WardExam from './WardExam.vue'

export default Vue.extend({
    components: {
        Background,
        CardLapsible,
        CollapseIndicator,
        HistoricalMedications,
        IssuesOverview,
        NeuroSummary,
        PatientDoctorDiscussion,
        PrimaryIssue,
        RapidAsmtDiagnosis,
        SocialHistory,
        Team,
        TextAreaEnhanced,
        WardExam
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            issuesOpened: false,
            bgOpened: false,
            medsOpened: false,
            socialOpened: false,
        }
    },
    computed: {
        isNurseUser(): boolean {
            return this.$store.direct.getters.user.isNurseUser
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        hasStrokeDiagnosis(): boolean {
            return includes(this.$store.direct.state.templates.strokeDiagnoses, this.stay?.diagnosis_title)
        },
        issuesList(): string [] {
            const activeIssues = stays.getActiveIssues(this.stay_id)
            return map(activeIssues, 'title')
        },
        planLabel(): string {
            return this.isNurseUser ? 'Plan - Doctor' : 'Plan'
        },
        cmReader() {
            return new commonmark.Parser()
        },
        cmWriter() {
            return new commonmark.HtmlRenderer()
        },
        backgroundHTML(): string {
            const bgMarkup = background({ stay: this.stay })
            return this.cmWriter.render(this.cmReader.parse(bgMarkup))
        },
        medsHTML(): string {
            const title = "Relevant medications prior to admission"
            const medsMarkup = historicalMedications({ stay: this.stay, title })
            return this.cmWriter.render(this.cmReader.parse(medsMarkup))
        },
        socialHTML(): string {
            const socialMarkup = socialHistory({ stay: this.stay })
            return this.cmWriter.render(this.cmReader.parse(socialMarkup))
        }
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-collapse",
        {
          model: {
            value: _vm.descOpen,
            callback: function($$v) {
              _vm.descOpen = $$v
            },
            expression: "descOpen"
          }
        },
        [
          _c(
            "b-card",
            { staticClass: "mt-2", attrs: { "no-body": "" } },
            [
              _c("b-card-body", { staticClass: "py-2" }, [
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v("Team present: " + _vm._s(_vm.teamPresentDesc))
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "mt-2",
          attrs: { id: _vm.mdtHtmlId },
          model: {
            value: _vm.mdtOpened,
            callback: function($$v) {
              _vm.mdtOpened = $$v
            },
            expression: "mdtOpened"
          }
        },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c(
                "b-card-body",
                [
                  _vm.canMDT
                    ? [
                        _c(
                          "label",
                          [
                            _vm._v(
                              "\n                        Team present\n                        "
                            ),
                            _c("fnt-a-icon", {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: _vm.mdtHelpHtmlId,
                                  expression: "mdtHelpHtmlId"
                                }
                              ],
                              attrs: { icon: ["fal", "question-circle"] }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "scrwl-grouped" },
                          _vm._l(_vm.mdtPeople, function(person) {
                            return _c(
                              "b-check",
                              {
                                key: person.id,
                                staticClass:
                                  "scrawl-checkbox d-inline-block mb-1",
                                attrs: { size: "lg", value: person.id },
                                model: {
                                  value: _vm.mdtPresent,
                                  callback: function($$v) {
                                    _vm.mdtPresent = $$v
                                  },
                                  expression: "mdtPresent"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(person.title) +
                                    "\n                        "
                                )
                              ]
                            )
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              description:
                                "This applies the team selection to all visible stays."
                            }
                          },
                          [
                            _c(
                              "b-btn",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.setStaysMDTPresent.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v("Apply")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("b-collapse", { attrs: { id: _vm.mdtHelpHtmlId } }, [
                          _c("div", { staticClass: "d-flex mt-3" }, [
                            _c("p", { staticClass: "mb-0" }, [
                              _c("em", [_vm._v("Instructions:")])
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "ml-2 mb-0" }, [
                              _vm._v(
                                "\n                                This list updates any subsequently opened MDTs, unless the list is empty. If there is an opened MDT, it directly affects that record."
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                Each MDT also has its own controls for updating team present.\n                            "
                              )
                            ])
                          ])
                        ])
                      ]
                    : _c("p", { staticClass: "mb-0" }, [
                        _c("em", [
                          _vm._v(
                            "Please select a speciality to access MDT functionality"
                          )
                        ])
                      ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
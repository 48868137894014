














































import Vue from "vue"
import { startsWith, isString } from "lodash-es"

import utils from "utils"
import { LoginState } from "models/store/session"
import SessionMonitor from 'utils/Session.vue'
import VersionChecker from 'utils/CheckVersion.vue'
import AlternateLoginModal from '@misc/AlternateLoginModal.vue'

import DelayRouteLeaveOverlay from '@shared/DelayRouteLeaveOverlay.vue'
import TopBar from "@misc/TopBar.vue"
import SyncErrors from '@misc/SyncErrors.vue'
import CurrentStay from "components/debug/CurrentStay.vue"

export default Vue.extend({
    components: {
        AlternateLoginModal,
        DelayRouteLeaveOverlay,
        CurrentStay,
        SessionMonitor,
        SyncErrors,
        TopBar,
        VersionChecker,
    },
    data() {
        return {
            isFluid: true,
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        loginStatus(): LoginState {
            return this.$store.direct.state.session.loginStatus
        },
        loginStatusUnknown(): boolean {
            return this.loginStatus === "unknown"
        },
        loggedIn(): boolean {
            return this.loginStatus === 'loggedin'
        },
        errorMsg(): string {
            return this.$store.direct.state.session.errorMsg
        },
        showErrorMsg(): boolean {
            return !!this.errorMsg
        },
        noService(): boolean {
            return this.loginStatus === 'no_service'
        },
        routingToASAP(): boolean {
            return startsWith(this.$route.path, '/standalone')
        },
        ready(): boolean {
            return this.$store.direct.state.session.ready
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        knocking(): boolean {
            return this.$store.direct.state.session.knocking
        },
    },
    watch: {
        loginStatus(a, b) {
            // if the handler parameters are directly typed, this.$store's intellisense no longer works
            // we do this as a workaround to maintain types ... not sure if worth it.
            const val: LoginState = a, old: LoginState = b
            if (old === 'no_service')
                return

            if (this.$store.direct.getters.session.loggedIn) {
                utils.loggedinInit()
                .then(() => {
                    // unhide on actual manual login
                    if (old === 'loggedout' && val === 'loggedin') {
                        this.$store.direct.dispatch.user.resetHideStays()
                        this.$store.direct.dispatch.user.clearHospitals()

                        if (this.$route.name === 'login') {
                            const next: string = isString(this.$route.query.next) ? this.$route.query.next : '/'
                            this.$router.push(next)
                        }
                    }
                })
                .catch(err => {
                    utils.handleRequestError(err)
                })
            }
            else if (old === 'unknown')
                return
            else if (this.$route.name !== 'login')
                this.$router.push({ name: 'login' })
        },
        $route: {
            immediate: true,
            handler: function(to, from) {
                if (this.$route.name === "login") this.isFluid = false
                else this.isFluid = true
            }
        }
    },
    mounted() {
        utils.hashChangeIE11(this)
    },
    methods: {
        renewSession(): void {
            this.$store.direct.dispatch.session.knock()
        },
        clearErrorMsg(): void {
            this.$store.direct.commit.session.clearErrorMsg()
        },
    }
})

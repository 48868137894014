


























import mixins from 'vue-typed-mixins'
import utils from 'utils'
import stays from '@store/stays'
import CreateStayNurse from "../CreateStayNurse.vue"
import Filters from '@shared/StayFilters.vue'
import ShowDischarged from '@shared/ShowDischarged.vue'
import Stays from './Stays.vue'
import DelayRouteLeaveMixin from '@shared/DelayRouteLeaveMixin.vue'
import StaysFilterMixin from '@shared/StaysFilterMixin.vue'

/**
 * display rules:
 * - should only display from selected hospitals. if no hospitals selected, display from all user-assigned hospitals.
 * 
 * - by default, only active stays are displayed
 * 
 * - a stay is a nurse-only stay if it has no doctor assigned
 * - by default, a nurse-only stay is considered active if rapid_asmt_discharge_time is null
 * - by default, a nurse-only stay is considered discharged if rapid asmt_discharge_time is assigned
 * 
 * Note - the below is academic, since we now have two separate active/inactive markers. The two active states operate independently.
 * - a stay is a full stay if it has a doctor assigned
 * - a full stay is active if active is true
 * - a full stay is considered inactive if discharge_time is assigned
 * - a full stay that is inactive, uses discharge_time as cutoff, or updated_at if discharge_time is null
 */
export default mixins(DelayRouteLeaveMixin, StaysFilterMixin).extend({
    components: {
        CreateStayNurse,
        Filters,
        ShowDischarged,
        Stays,
    },
    mounted() {
        this.$_pull()
    },
    beforeDestroy() {
        stays.mutClearAll()
    },
    methods: {
        $_pull(): Promise<any> {
            const params = this.paramsStrict
            params.t = 'nurse'
            return stays
                .actPullStays({ url: '/stay/simple/', params })
                .catch(err => {
                    utils.handleRequestError(err)
                })
        },
    }
})



































import Vue from 'vue'
import utils from 'utils'
import * as commonmark from "commonmark"
import Checkbox from '@shared/inputs/Checkbox.vue'
import TextArea from '@shared/inputs/TextArea.vue'

export default Vue.extend({
    components: {
        Checkbox,
        TextArea,
    },
    data() {
        const uid = utils.getUID()
        const htmlId = `${uid}___user_sig`
        return {
            uid,
            htmlId,
            inputHtmlId: `${htmlId}_input`
        }
    },
    computed: {
        show(): boolean {
            return this.$store.direct.state.session.ux_signature
        },
        appendSig: {
            get(): boolean {
                return this.$store.direct.state.user.appendSig
            },
            set(val: boolean) {
                this.$store.direct.dispatch.user.toggleAppendSig()
            }
        },
        signature: {
            get(): string {
                return this.$store.direct.state.user.signature
            },
            set(val: string) {
                this.$store.direct.dispatch.user.updatePush({ signature: val })
            }
        },
        placeholderText(): string {
            return "*LastName*, FirstName\\\n**Provider#** 234567"
        },
        cmReader() {
            return new commonmark.Parser()
        },
        cmWriter() {
            return new commonmark.HtmlRenderer()
        },
        markedUp(): string {
            return this.cmWriter.render(this.cmReader.parse(this.signature))
        },
    },
})







































import mixins from "vue-typed-mixins"
import utils from 'utils'
import DisplayMixin from "@mixins/Display.vue"
import RecentNotesMixin from './RecentNotesMixin.vue'
import Dropdown from '@shared/inputs/Dropdown.vue'
import Editor from "@shared/inputs/TextEditor.vue"

export default mixins(DisplayMixin, RecentNotesMixin).extend({
    components: {
        Dropdown,
        Editor,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        markup: {
            type: String,
            required: true
        },
        doConfirm: {
            type: Boolean,
            default: true
        },
        modalOkTitle: {
            type: String,
            default: 'OK'
        },
        tag: {
            type: String,
            default: 'rn'
        },
        customMarkComplete: {
            type: Function,
            default: () => (() => {})
        },
        editorHeight: {
            type: Number,
            required: false
        },
        showExtras: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            confirmHtmlId: `${uid}___conf_modal`,
            notes: '',
            RN_tag: this.tag,
            showExtras_local: this.showExtras,
        }
    },
    mounted() {
        this.RN_refreshRecentNotes(this.stay_id)
    },
    methods: {
        confirmComplete(text: string) {
            if (this.RN_selected)
                return
            this.notes = text
            if (this.doConfirm)
                this.$root.$emit('bv::show::modal', this.confirmHtmlId)
        },
        markComplete() {
            this.customMarkComplete()
            this.RN_addRecentNote(this.stay_id, this.notes)
        },
        toggleShowExtras(): void {
            this.showExtras_local = !this.showExtras_local
        },
    },
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        [
          _c(
            "b-tab",
            { attrs: { title: "Clinical Review" } },
            [
              _c("main-diagnosis", {
                staticClass: "mt-2",
                attrs: { stay_id: _vm.stay_id }
              }),
              _vm._v(" "),
              _c("data-capture", {
                attrs: { stay_id: _vm.stay_id, isRapidAssessment: true }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("hr"),
                  _vm._v(" "),
                  _c("label", [_vm._v("Clinical Phase")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("dropdown", {
                        staticClass: "col-6",
                        attrs: {
                          items: _vm.diagnoses,
                          "full-width": true,
                          "choose-text": "[ Choose diagnosis ]",
                          "add-null-item": "",
                          "null-item-text": "[ Other diagnosis ]",
                          "null-item-value": _vm.OTHER_DIAGNOSIS_VALUE
                        },
                        model: {
                          value: _vm.diagnosisID,
                          callback: function($$v) {
                            _vm.diagnosisID = $$v
                          },
                          expression: "diagnosisID"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showOtherDiagnosis
                    ? _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-6",
                              attrs: {
                                label: "Other diagnosis title",
                                "label-class": "p-0"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: { stay_id: _vm.stay_id },
                                model: {
                                  value: _vm.otherDiagnosisTitle,
                                  callback: function($$v) {
                                    _vm.otherDiagnosisTitle = $$v
                                  },
                                  expression: "otherDiagnosisTitle"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.rapidAssessmentDiagnosis
                    ? _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("diagnosis-help", {
                            staticClass: "col-sm-6",
                            attrs: { diagnosis: _vm.rapidAssessmentDiagnosis }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("diagnosis-summary", {
                    attrs: { stay_id: _vm.stay_id, isRapidAssessment: true }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("management-plan-steps", {
                    attrs: {
                      stay_id: _vm.stay_id,
                      diagnosis: _vm.rapidAssessmentDiagnosis
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    [
                      _c("label", [
                        _vm._v(
                          "\n                        Additional plan notes\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("text-area", {
                        attrs: {
                          "non-unique-id": "rapid-assessment-plan-notes",
                          rows: "8"
                        },
                        model: {
                          value: _vm.rapidAssessmentPlanNotes,
                          callback: function($$v) {
                            _vm.rapidAssessmentPlanNotes = $$v
                          },
                          expression: "rapidAssessmentPlanNotes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c("hr"),
                  _vm._v(" "),
                  _c("h4", [_vm._v("EMR")]),
                  _vm._v(" "),
                  _vm.nihssV2
                    ? _c("text-nihss-select", {
                        attrs: { stay_id: _vm.stay_id }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("rapid-assessment-text", {
                    attrs: { stay_id: _vm.stay_id }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Data Capture" } },
            [
              _c("questions", {
                attrs: {
                  stay_id: _vm.stay_id,
                  category: _vm.QuestionCategory.RAPID_ASMT_DC,
                  parentObject: _vm.syndrome,
                  firstItemTopBorder: false
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
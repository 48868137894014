































import Vue from 'vue'
import {  } from 'lodash-es'
import utils from 'utils'
import Checkbox from '@shared/inputs/Checkbox.vue'

export default Vue.extend({
    components: {
        Checkbox
    },
    props: {
        showDischarged: {
            type: Boolean,
            default: false
        },
        periods: {
            type: Array as () => string[],
            default: () => ['2w', '4w', '8w']
        },
        period: {
            type: String,
            default: '2w'
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            htmlIDs: {
                cpForm: `${uid}___form`,
                cpLabel: `${uid}___label`,
                cpInput: `${uid}___input`,
                cpFeedback: `${uid}___fb`,
            },
            showCustom: false,
            customPeriod: '',
            customPeriodState: null as boolean | null,
        }
    },
    computed: {
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        cp_showDischarged: {
            get(): boolean {
                return this.showDischarged
            },
            set(val: boolean) {
                this.$emit('update:showDischarged', val)
                this.$emit('update')
            }
        },
        cp_period: {
            get(): string {
                return this.period
            },
            set(val: string) {
                this.$emit('update:period', val)
                this.$emit('update')
            }
        },
        custom_period: {
            get(): string {
                return this.customPeriod
            },
            set(val: string) {
                this.customPeriodState = null
                try {
                    const intVal = parseInt(val)
                    if (isFinite(intVal) && intVal < 125) {
                        this.$emit('update:period', val+'w')
                        this.$emit('update')
                    }
                    else {
                        this.customPeriodState = false
                    }
                }
                catch(err) {
                    this.customPeriodState = false
                }
            }
        },
    }
})

import { Stay } from 'models/data/stay'
import {
    string as dstring, number as dnumber, boolean as dboolean,
    array, nullable, optional,
    exact, inexact, guard
} from 'decoders'


export interface SummaryStay {
    id: number
    active: boolean
    days_in_stroke_unit: number
    notes: string
    patient_summary: string
    triage_to_ct: number | null
    ct_to_decision: number | null
    decision_to_needle: number | null
    door_to_lysis_decision: number | null
    door_to_ecr_decision: number | null
    door_to_needle: number | null
    triage_to_leaving_ed: string | null
    asap_documented: boolean
    triage_to_alert: number | null
    neurology_reg_present: boolean
    first_contact_made_prior_to_ct: boolean
    on_correct_meds: boolean
    first_contact_from: string
    transferred_back: boolean | null
    palliative: boolean
    neurology_fields: {
        seventy_two_hour_nihss_score?: number
        nbm: string | null
        next_out_of_stroke_unit: number
    }
}

const summaryStay = exact({
    id: dnumber,
    active: dboolean,
    days_in_stroke_unit: dnumber,
    notes: dstring,
    patient_summary: dstring,
    triage_to_ct: nullable(dnumber),
    ct_to_decision: nullable(dnumber),
    decision_to_needle: nullable(dnumber),
    door_to_lysis_decision: nullable(dnumber),
    door_to_ecr_decision: nullable(dnumber),
    door_to_needle: nullable(dnumber),
    triage_to_leaving_ed: nullable(dstring),
    asap_documented: dboolean,
    triage_to_alert: nullable(dnumber),
    neurology_reg_present: dboolean,
    first_contact_made_prior_to_ct: dboolean,
    on_correct_meds: dboolean,
    first_contact_from: dstring,
    transferred_back: nullable(dboolean),
    palliative: dboolean,
    neurology_fields: exact({
        seventy_two_hour_nihss_score: optional(dnumber),
        nbm: nullable(dstring),
        next_out_of_stroke_unit: dnumber,
    }),
})


export interface HyperacuteStay extends Stay {
    presentation_type: string
    is_lysis: boolean
}


// TODO incomplete interface
export interface VizData {
    all_stays: number[]
    nel_nr_assessed_by_nurse_no: number[]
    nel_nr_assessed_by_nurse_yes: number[]
    presentation_types: string[]
    stays: SummaryStay[]
}

const vizData = inexact({
    all_stays: array(dnumber),
    nel_nr_assessed_by_nurse_no: array(dnumber),
    nel_nr_assessed_by_nurse_yes: array(dnumber),
    presentation_types: array(dstring),
    stays: array(summaryStay),
})

export const vizDataDecoder = guard(vizData, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _vizData: VizData = vizDataDecoder({}) } catch(e) {}


////////////////////////////
// Stroke charts

type PctArray = (number | null)[]

interface Meta {
    year: string | null
    start: string | null
    end: string | null
    months_info: {
        desc: string
        month_s: string
        mid_month_s: string
    }[]
}

export interface StrokeData {
    _filter: {
        admission_time__gte: string
        admission_time__lt: string
    }
    _meta: Meta
    months: string[]
    door_to_needle_times: {
        date: string
        date_s: string
        time_in_minutes: number
    }[]
    door_to_needle_medians: { x: string, y: number }[]
    door_to_needle_median: number | null
    total_sites: number
    ischaemic_stroke: number[]
    ich: number[]
    other: number[]
    ecr_only: number[]
    lysed_only: number[]
    lysed_and_ecr: number[]
}

export interface TSData extends StrokeData {
}

export interface NonTSData extends StrokeData {
    stroke_unit_access: PctArray
    stroke_unit_access_percentage: number
    discharged_on_correct_meds: PctArray
    discharged_on_correct_meds_percentage: number
}

const pctArrayDef = array(nullable(dnumber))

const meta = exact({
    year: nullable(dstring),
    start: nullable(dstring),
    end: nullable(dstring),
    months_info: array(exact({
        desc: dstring,
        month_s: dstring,
        mid_month_s: dstring,
    }))
})

const dataDef = {
    _filter: exact({
        admission_time__gte: dstring,
        admission_time__lt: dstring,
    }),
    _meta: meta,
    months: array(dstring),
    door_to_needle_times: array(exact({
        date: dstring,
        date_s: dstring,
        time_in_minutes: dnumber
    })),
    door_to_needle_medians: array(exact({
        x: dstring,
        y: dnumber,
    })),
    door_to_needle_median: nullable(dnumber),
    total_sites: dnumber,
    ischaemic_stroke: array(dnumber),
    ich: array(dnumber),
    other: array(dnumber),
    ecr_only: array(dnumber),
    lysed_only: array(dnumber),
    lysed_and_ecr: array(dnumber),
}

const tsDataDef = {
    ...dataDef
}

const nonTSDataDef = {
    ...dataDef,
    stroke_unit_access: pctArrayDef,
    stroke_unit_access_percentage: dnumber,
    discharged_on_correct_meds: pctArrayDef,
    discharged_on_correct_meds_percentage: dnumber
}

const data = exact(dataDef)
const tsData = inexact(tsDataDef)
const nonTSData = exact(nonTSDataDef)

export const tsDataDecoder = guard(tsData, { style: 'simple' })
export const nonTSDataDecoder = guard(nonTSData, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _meta: Meta = guard(meta)({}) } catch(e) {}
try { const _data: StrokeData = guard(data)({}) } catch(e) {}
try { const _tsData: TSData = tsDataDecoder({}) } catch(e) {}
try { const _nonTSData: NonTSData = nonTSDataDecoder({}) } catch(e) {}









































import Vue from 'vue'

export default Vue.extend({
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        changesMaxAge: {
            get(): number {
                return this.$store.direct.state.session.changesMaxAge
            },
            set(age: number) {
                this.$store.direct.commit.session.changesMaxAge(age)
            }
        },
        syncMonitorInterval: {
            get(): number {
                return this.$store.direct.state.session.syncMonitorInterval
            },
            set(interval: number) {
                this.$store.direct.commit.session.syncMonitorInterval(interval)
            }
        },
    },
})

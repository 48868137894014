var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.overrideMsg
      ? _c("div", { staticClass: "col" }, [
          _c("p", { staticClass: "my-2" }, [
            _c("em", [_vm._v(_vm._s(_vm.overrideMsg))])
          ])
        ])
      : _vm.isGrouped
      ? _c(
          "div",
          { staticClass: "col" },
          _vm._l(_vm.groups, function(group) {
            return _c(
              "div",
              {
                key: group.uid,
                staticClass: "row",
                class: _vm.groupClasses(group)
              },
              [
                group.label
                  ? _c(
                      "b-col",
                      {
                        staticClass: "d-flex align-items-center",
                        attrs: { cols: group.label_col || 12 }
                      },
                      [
                        _c(
                          "h4",
                          { staticClass: "py-0 mb-3", class: _vm.labelClass },
                          [_vm._v(_vm._s(group.label))]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.inlined(group.alignment)
                  ? _vm._l(group.fields, function(field) {
                      return _c(
                        "b-col",
                        { key: field.uid, attrs: { cols: group.field_col } },
                        [
                          _c("r-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showField(field),
                                expression: "showField(field)"
                              }
                            ],
                            class: _vm.showFieldClass(field),
                            attrs: { stay_id: _vm.stay_id, field: field }
                          })
                        ],
                        1
                      )
                    })
                  : _vm.stacked(group.alignment)
                  ? _c(
                      "b-col",
                      { attrs: { sm: group.field_col } },
                      _vm._l(group.fields, function(field) {
                        return _c("r-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showField(field),
                              expression: "showField(field)"
                            }
                          ],
                          key: field.uid,
                          class: [
                            _vm.fieldClasses(group),
                            _vm.showFieldClass(field)
                          ],
                          attrs: { stay_id: _vm.stay_id, field: field }
                        })
                      }),
                      1
                    )
                  : _vm._l(group.fields, function(field) {
                      return _c(
                        "div",
                        {
                          key: field.uid,
                          staticClass: "col col-sm-6 col-md-4 mb-3"
                        },
                        [
                          _c("r-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showField(field),
                                expression: "showField(field)"
                              }
                            ],
                            class: _vm.showFieldClass(field),
                            attrs: { stay_id: _vm.stay_id, field: field }
                          })
                        ],
                        1
                      )
                    }),
                _vm._v(" "),
                _vm.addGroupDivider
                  ? _c("div", { staticClass: "col-12" }, [_c("hr")])
                  : _vm._e()
              ],
              2
            )
          }),
          0
        )
      : _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "transition-group",
              { attrs: { name: "fade", tag: "div" } },
              _vm._l(_vm.fields, function(field) {
                return _c("r-input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showField(field),
                      expression: "showField(field)"
                    }
                  ],
                  key: field.uid,
                  class: _vm.showFieldClass(field),
                  attrs: { stay_id: _vm.stay_id, field: field }
                })
              }),
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-form",
    { staticClass: "ml-2 mr-1" },
    [
      _c("b-form-select", {
        attrs: { options: _vm.options, size: "sm" },
        model: {
          value: _vm.userSpeciality,
          callback: function($$v) {
            _vm.userSpeciality = $$v
          },
          expression: "userSpeciality"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
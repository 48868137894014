import { find, each, reduce } from 'lodash-es'
import stays from '@store/stays'
import store from 'store'
import { CustomAnswer } from 'models/data/answer'
import { Issue } from 'models/med_templates/issue'
import { QuestionType } from 'models/med_templates/enums'

export function getABFScore(stay_id: number): number {
    const stayIssues = stays.getAllIssues(stay_id)
    const allIssues: readonly Issue[] = store.direct.getters.templates.allIssues
    const allQuestions = store.direct.getters.templates.allQuestions

    let score = 0

    function addQuestionsABF(answers: { [k: string]: CustomAnswer }) {
        each(answers, (ans_info, q_id) => {
            const val = ans_info.answer
            if (val) {
                const question = allQuestions[q_id]
                if (!question)
                    return
                if (question.abf)
                    score++
                if (question.type === QuestionType.CHOICE) {
                    const choice = find(question.choices, ['id', val])
                    if (choice && choice.abf)
                        score++
                }
                else if (question.type === QuestionType.LIST) {
                    if (Array.isArray(val)) {
                        each(val, choiceId => {
                            const choice = find(question.choices, ['id', choiceId])
                            if (choice && choice.abf)
                                score++
                        })
                    }    
                }
            }
        })
    }

    each(stayIssues, issue => {
        if (issue.issue !== undefined) {
            const issueTemplate = find(allIssues, ['id', issue.issue])
            if (issueTemplate && issueTemplate.abf)
                score++
            addQuestionsABF(issue.answers)
        }
    })
    
    const stay = stays.state.stays[stay_id]
    if (stay)
        addQuestionsABF(stay._answers)

    return score
}
































import Vue from 'vue'
import * as moment from 'moment'
import utils from 'utils'
import { debounce, find, flatMap, floor, map, padStart } from 'lodash-es'
import { Cancelable } from 'lodash'
import { DataSet } from 'vis-data/peer/umd'
import { DataItem, DataGroup, Timeline, TimelineOptions } from 'vis-timeline/peer/umd'
import "vis-timeline/styles/vis-timeline-graph2d.css"
import DateRangeControls from './DateRangeControls.vue'

interface UsageBlock {
    start: string
    end: string
    duration_desc: string
}

interface UserUsage {
    user: string
    names: string
    total_secs: number
    total_desc: string
    blocks: UsageBlock[]
}

interface UsageInfo {
    start: string
    end: string
    usage: UserUsage[]
}

export default Vue.extend({
    components: {
        DateRangeControls,
    },
    data() {
        const items = new DataSet<DataItem>()
        const groups = new DataSet<DataGroup>()

        return {
            startDate: '',
            endDate: '',
            users: [] as string[],
            user: '',
            loading: false,
            usageData: {
                start: '',
                end: '',
                usage: []
            } as UsageInfo,
            visItems: items,
            visGroups: groups,
            visTimeline: null as Timeline | null,
            /** display users with no activity on timeline */
            showAll: false,
        }
    },
    computed: {
        debouncedPull(): (() => void) & Cancelable {
            return debounce(this.pull, 1000, {trailing: true})
        },
    },
    watch: {
        usageData(data) {
            this.updateVisTimeline()
        },
        startDate(val, old) {
            if (val !== old) this.debouncedPull()
        },
        endDate(val, old) {
            if (val !== old) this.debouncedPull()
        },
        showAll() {
            this.updateVisGroups()
        },
    },
    mounted() {
        const options: TimelineOptions = {
            editable: false,
            selectable: false,
            stack: false,
            orientation: {
                axis: 'both'
            },
        }
        this.visTimeline = new Timeline(this.$refs.vis_timeline as HTMLElement, this.visItems, this.visGroups, options)
        this.visItems.on('add', (event) => {
            if (this.visTimeline)
                this.visTimeline.fit()
        })
    },
    beforeDestroy() {
        if (this.visTimeline)
            this.visTimeline.destroy()
    },
    methods: {
        opened() {
            this.debouncedPull.cancel()
        },
        closed() {
            this.debouncedPull()
        },
        pull(): void {
            if (this.loading) return
            this.loading = true

            let url: string
            if (this.startDate && this.endDate)
                url = `/metrics/general/users/${this.startDate}/${this.endDate}`
            else
                url = '/metrics/general/users/'

            utils.request.get(url)
            .then(res => {
                // TODO write encoder to confirm shape of json
                this.usageData = res.body
                this.users = map(this.usageData.usage, 'user')
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
            .then(res => {
                this.loading = false
            })
        },
        formattedDuration(duration: moment.Duration): string {
            const mins = `${duration.minutes()}`
            return `${floor(duration.asHours())}:${padStart(mins, 2, '0')}`
        },
        updateVisGroups(): void {
            const data = this.usageData
            const groups: DataGroup[] = map(data.usage, userUsage => {
                const desc = userUsage.names || userUsage.user
                const duration = moment.duration(userUsage.total_secs*1000)
                const mins = `${duration.minutes()}`
                return {
                    id: userUsage.user,
                    content: `${desc} (${this.formattedDuration(duration)})`,
                    title: userUsage.total_desc,
                    visible: this.showAll || userUsage.blocks.length > 0,
                }
            })
            this.visGroups.clear()
            this.visGroups.update(groups)
        },
        updateVisTimeline(): void {
            if (!this.visTimeline) return
            const data = this.usageData
            this.visTimeline.setOptions({ start: data.start, end: data.end })
            this.updateVisGroups()
            const items = flatMap(data.usage, (userUsage) => {
                return map(userUsage.blocks, (block, i) => {
                    const duration = moment.duration(moment(block.end).diff(moment(block.start)))
                    return {
                        id: `${userUsage.user}_${i}`,
                        group: userUsage.user,
                        content: this.formattedDuration(duration),
                        start: block.start,
                        end: block.end,
                        title: block.duration_desc,
                    }
                })
            })
            this.visItems.clear()
            this.visItems.update(items)
        },
    }
})
